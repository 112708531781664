import { authConstants, schoolConstants, organizationConstants } from "_constants";

const initialState = {
	isFetching: false,
	createSchoolSuccess: false,
	updateSchoolSuccess: false,
	school: {},
	imageCache: "",
	navigationObject: {
		root: {
			level: "",
			district: "",
			gender: "",
			schoolNet: "",
			name: "",
			withSubscriptionOnly: false,
			sortingKey: "currentYearRank",
			sortingDirection: "ASC",
			schoolList: [],
			summary: [],
			numOfPage: 1,
			currentPage: 1,
		},
		dashboard: {
			level: "",
			district: "",
			gender: "",
			schoolList: [],
			summary: [],
			numOfPage: 1,
			currentPage: 1,
		},
		organization: {
			schoolList: [],
			sortingKey: "",
			sortingDirection: "",
			numOfPage: 1,
			currentPage: 1,
			totalItems: 0,
		},
	},
};

const schoolReducer = (state = initialState, action) => {
	switch (action.type) {
		case authConstants.type.UNAUTHORIZED_ERROR: {
			let navigationObject = initialState.navigationObject;
			if (typeof state.navigationObject?.root != "undefined") {
				navigationObject = {
					...state.navigationObject,
					root: { ...state.navigationObject?.root, currentPage: 1 },
				};
			}
			if (typeof state.navigationObject?.dashboard != "undefined") {
				navigationObject = {
					...state.navigationObject,
					dashboard: { ...state.navigationObject?.dashboard, currentPage: 1 },
				};
			}
			if (typeof state.navigationObject?.organization != "undefined") {
				navigationObject = {
					...state.navigationObject,
					organization: { ...state.navigationObject?.organization, currentPage: 1 },
				};
			}
			return {
				...state,
				navigationObject: navigationObject,
			};
		}
		case schoolConstants.type.FETCH_SCHOOL_LIST_REQUEST: {
			return {
				...state,
				isFetching: true,
			};
		}

		case schoolConstants.type.FETCH_SCHOOL_LIST_FAILURE: {
			return {
				...state,
				isFetching: false,
			};
		}
		case schoolConstants.type.FETCH_SCHOOL_LIST_SUCCESS: {
			if (action.navigationKey) {
				let navigationObject = {
					level: action.level ?? "",
					district: action.district ?? "",
					gender: action.gender ?? "",
					schoolNet: action.schoolNet ?? "",
					name: action.name ?? "",
					numOfPage: action.totalPage,
					currentPage: action.currentPage ?? 1,
					schoolList: action.schoolList,
					summary: action.summary,
					withSubscriptionOnly: action.withSubscriptionOnly ?? false,
				};
				if (action.navigationKey === "root" || action.navigationKey === "organization") {
					navigationObject = {
						...navigationObject,
						sortingKey: action.sortingKey || "currentYearRank",
						sortingDirection: action.sortingDirection || "ASC",
					};
				}
				return {
					...state,
					isFetching: false,
					navigationObject: {
						...state.navigationObject,
						[`${action.navigationKey}`]: {
							...navigationObject,
						},
					},
				};
			} else {
				return state;
			}
		}
		case schoolConstants.type.FETCH_SCHOOL_REQUEST: {
			return {
				...state,
				isFetching: true,
				school: {},
			};
		}

		case schoolConstants.type.FETCH_SCHOOL_FAILURE: {
			return {
				...state,
				isFetching: false,
			};
		}
		case schoolConstants.type.FETCH_SCHOOL_SUCCESS: {
			return {
				...state,
				isFetching: false,
				school: action.school,
			};
		}
		case schoolConstants.type.CREATE_SCHOOL_REQUEST: {
			return {
				...state,
				isFetching: true,
				createSchoolSuccess: false,
			};
		}
		case schoolConstants.type.CREATE_SCHOOL_FAILURE: {
			return {
				...state,
				isFetching: false,
				createSchoolSuccess: false,
			};
		}
		case schoolConstants.type.CREATE_SCHOOL_SUCCESS: {
			return {
				...state,
				isFetching: false,
				createSchoolSuccess: true,
			};
		}
		case schoolConstants.type.UPDATE_SCHOOL_REQUEST: {
			return {
				...state,
				isFetching: true,
				updateSchoolSuccess: false,
			};
		}
		case schoolConstants.type.UPDATE_SCHOOL_FAILURE: {
			return {
				...state,
				isFetching: false,
				updateSchoolSuccess: false,
			};
		}
		case schoolConstants.type.UPDATE_SCHOOL_SUCCESS: {
			return {
				...state,
				isFetching: false,
				updateSchoolSuccess: true,
			};
		}
		case schoolConstants.type.RESET_SCHOOL_SUCCESS: {
			return {
				...state,
				createSchoolSuccess: false,
				updateSchoolSuccess: false,
				imageCache: action.filePath || "",
			};
		}
		case schoolConstants.type.CLEAR_SCHOOL_BADGE_CACHE: {
			return {
				...state,
				imageCache: "",
			};
		}
		case organizationConstants.type.FETCH_ORGANIZATION_SUCCESS: {
			if (action.includeSubsidiary) {
				return {
					...state,
					navigationObject: {
						...state.navigationObject,
						organization: {
							...action.navigationObject?.school,
						},
					},
				};
			} else {
				return state;
			}
		}
		default: {
			return state;
		}
	}
};

export default schoolReducer;
