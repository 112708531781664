import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { TextField, WarningButton } from "components/general";
import { toast } from "react-toastify";
import { useKey } from "_util/hook";

export const AuthContainer = (props) => {
	const { handleLogin, setIsForgetPw } = props;

	const [formData, setFormData] = useState({ username: "", password: "" });

	const enterPress = useKey("enter");

	useEffect(() => {
		if (enterPress) {
			handleOnClick();
		}
	}, [enterPress]);

	const handleChange = ({ key, value }) => {
		setFormData({ ...formData, [key]: value });
	};
	const handleOnClick = () => {
		if (!formData.username) {
			toast.error("請填寫用户名稱");
			return;
		}
		if (!formData.password) {
			toast.error("請填寫密碼");
			return;
		}
		handleLogin(formData);
	};

	const handleForgetPassword = () => {
		setIsForgetPw(true);
		// history.push(generalConstants.PATH.FORGET_PASSWORD);
	};

	return (
		<Container>
			<LeftContainer></LeftContainer>
			<RightContainer>
				<RightInnerContainer>
					<div style={{ flex: 1 }}>
						<TitleText>Kids On Track</TitleText>
						<TitleText>後台管理系統</TitleText>
					</div>
					<VerticalLine />
					<div style={{ flex: 2 }}>
						<TextFieldLabel>用户名稱</TextFieldLabel>
						<TextField
							placeholder="用户名稱"
							style={{ backgroundColor: "white" }}
							value={formData.username}
							name="username"
							handleChange={handleChange}
							autoComplete={"username"}
						/>
						<div style={{ marginTop: "20px", marginBottom: "20px" }} />
						<TextFieldLabel>密碼</TextFieldLabel>
						<TextField
							placeholder="密碼"
							style={{ backgroundColor: "white" }}
							value={formData.password}
							name="password"
							handleChange={handleChange}
							autoComplete={"current-password"}
							type="password"
						/>
					</div>
					<div style={{ flex: 2 }}>
						<ForgetPassword>
							<ForgetPasswordText onClick={handleForgetPassword}>忘記密碼?</ForgetPasswordText>
						</ForgetPassword>
						<WarningButton style={{ marginTop: "20px", width: "50%" }} label={"登入"} handleOnClick={handleOnClick} />
					</div>
				</RightInnerContainer>
			</RightContainer>
		</Container>
	);
};

AuthContainer.propTypes = {
	handleLogin: PropTypes.func,
	setIsForgetPw: PropTypes.func,
};

const Container = styled.div`
	height: 60%;
	width: 50%;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	z-index: 10;
	display: flex;
	flex-direction: row;
	filter: drop-shadow(0px 0px 20px rgba(0, 0, 0));
`;

const LeftContainer = styled.div`
	flex: 2;
	background-color: white;
	border-top-left-radius: 10px;
	border-bottom-left-radius: 10px;
`;

const RightContainer = styled.div`
	flex: 3;
	background-color: #333399;
	border-top-right-radius: 10px;
	border-bottom-right-radius: 10px;
	overflow: auto;
	display: flex;
	align-items: center;
	justify-content: center;
`;

const RightInnerContainer = styled.div`
	width: 80%;
	display: flex;
	flex-direction: column;
	height: 90%;
`;

const TitleText = styled.div`
	color: white;
	font-size: 26px;
	line-height: 1.5;
	font-weight: bold;
`;

const VerticalLine = styled.div`
	width: 100%;
	border-bottom: 1px solid white;
	/* margin-top: 20px; */
	margin-bottom: 20px;
`;

const TextFieldLabel = styled.div`
	color: white;
	font-size: 16px;
	line-height: 1.5;
	font-weight: bold;
	margin-bottom: 10px;
`;

const ForgetPassword = styled.div`
	margin-top: 10px;
	text-align: right;
	width: 100%;
`;
const ForgetPasswordText = styled.span`
	color: white;
	font-size: 16px;
	line-height: 1.5;
	font-weight: bold;
	cursor: pointer;
	text-decoration: underline;
`;
