import { dashboardConstants, schoolConstants, generalConstants } from "_constants";
import { schoolService } from "services";
import { toast } from "react-toastify";
import { history } from "_util/_helper";
import _ from "lodash";

export const schoolAction = {
	getSchoolList,
	getSchoolByKey,
	createSchool,
	updateSchool,
};

function getSchoolList(form, navigationKey) {
	return (dispatch) => {
		dispatch(request());
		schoolService.getSchoolList(form).then(
			(response) => {
				dispatch(success(response));
			},
			(error) => {
				toast.error(error.error);
				dispatch(failure());
			}
		);
	};

	function request() {
		return { type: schoolConstants.type.FETCH_SCHOOL_LIST_REQUEST };
	}
	function failure() {
		return { type: schoolConstants.type.FETCH_SCHOOL_LIST_FAILURE };
	}
	function success(response) {
		const { totalItems, items, summary } = response;
		if (navigationKey === "subscription") {
			return {
				type: dashboardConstants.type.DASHBOARD_SET_SCHOOL_BY_SUBSCRIPTION_LIST,
				schoolList: items,
				level: form.level,
			};
		} else if (navigationKey === "news") {
			return {
				type: dashboardConstants.type.DASHBOARD_SET_SCHOOL_BY_NEWS_LIST,
				schoolList: items,
				level: form.level,
			};
		}
		let result = {
			navigationKey,
			type: schoolConstants.type.FETCH_SCHOOL_LIST_SUCCESS,
			schoolList: items,
			totalPage: Math.ceil(totalItems / form.limit),
			currentPage: form.page,
			summary: summary,
		};
		let sortingKey = "lastUpdateTime",
			sortingDirection = "DESC";
		const sortingArray = form?.sort?.split(":");
		if (sortingArray && sortingArray.length === 2 && sortingArray[0] && sortingArray[1]) {
			sortingKey = sortingArray[0];
			sortingDirection = sortingArray[1] === "DESC" ? "DESC" : "ASC";
			result = { ...result, sortingKey, sortingDirection };
		}
		if (form.level) {
			result = { ...result, level: form.level };
		}
		if (form.district) {
			result = { ...result, district: form.district };
		}
		if (form.gender) {
			result = { ...result, gender: form.gender };
		}
		if (form.schoolNet) {
			result = { ...result, schoolNet: form.schoolNet };
		}
		if (form.name) {
			result = { ...result, name: form.name };
		}
		if (form.withSubscriptionOnly) {
			result = { ...result, withSubscriptionOnly: form.withSubscriptionOnly };
		}
		return result;
	}
}

function getSchoolByKey(key, data) {
	return (dispatch) => {
		dispatch(request());
		schoolService.getSchoolByKey(key, data).then(
			(response) => {
				dispatch(success(response));
			},
			(error) => {
				toast.error(error.error);
				dispatch(failure());
				if (error.error?.status !== 401) {
					history.push(generalConstants.PATH.SCHOOL_MANAGEMENT);
				}
			}
		);
	};

	function request() {
		return { type: schoolConstants.type.FETCH_SCHOOL_REQUEST };
	}
	function failure() {
		return { type: schoolConstants.type.FETCH_SCHOOL_FAILURE };
	}
	function success(school) {
		if (!_.isEmpty(data)) {
			const { totalItems, items } = school.messages;
			let result = {
				navigationKey: "school",
				type: schoolConstants.type.FETCH_SCHOOL_SUCCESS,
				school,
				totalNum: totalItems,
				totalPage: Math.ceil(totalItems / data.limit),
				currentPage: data.page,
				msgList: items,
			};
			let sortingKey = "lastUpdateTime",
				sortingDirection = "DESC";
			const sortingArray = data?.sort?.split(":");
			if (sortingArray && sortingArray.length === 2 && sortingArray[0] && sortingArray[1]) {
				sortingKey = sortingArray[0];
				sortingDirection = sortingArray[1] === "DESC" ? "DESC" : "ASC";
				result = { ...result, sortingKey, sortingDirection };
			}
			return result;
		} else {
			return { type: schoolConstants.type.FETCH_SCHOOL_SUCCESS, school };
		}
	}
}

function createSchool(data) {
	return (dispatch) => {
		dispatch(request());
		schoolService.createSchool(data).then(
			() => {
				dispatch(success());
			},
			(error) => {
				toast.error(error.error);
				dispatch(failure());
			}
		);
	};

	function request() {
		return { type: schoolConstants.type.CREATE_SCHOOL_REQUEST };
	}
	function failure() {
		return { type: schoolConstants.type.CREATE_SCHOOL_FAILURE };
	}
	function success() {
		return { type: schoolConstants.type.CREATE_SCHOOL_SUCCESS };
	}
}

function updateSchool(data) {
	return (dispatch) => {
		dispatch(request());
		schoolService.updateSchool(data).then(
			() => {
				dispatch(success());
			},
			(error) => {
				toast.error(error.error);
				dispatch(failure());
			}
		);
	};

	function request() {
		return { type: schoolConstants.type.UPDATE_SCHOOL_REQUEST };
	}
	function failure() {
		return { type: schoolConstants.type.UPDATE_SCHOOL_FAILURE };
	}
	function success() {
		return { type: schoolConstants.type.UPDATE_SCHOOL_SUCCESS };
	}
}
