export const authConstants = {
	type: {
		LOGIN_SUCCESS: "LOGIN_SUCCESS",
		LOGOUT: "LOGOUT",
		UNAUTHORIZED_ERROR: "UNAUTHORIZED_ERROR",
		CHANGE_PASSWORD_REQUEST: "CHANGE_PASSWORD_REQUEST",
		CHANGE_PASSWORD_SUCCESS: "CHANGE_PASSWORD_SUCCESS",
		CHANGE_PASSWORD_FAIL: "CHANGE_PASSWORD_FAIL",
		CHANGE_PASSWORD_INIT: "CHANGE_PASSWORD_INIT",
	},
};
