export const dashboardConstants = {
	type: {
		DASHBOARD_SET_SCHOOL_BY_SUBSCRIPTION_LIST: "DASHBOARD_SET_SCHOOL_BY_SUBSCRIPTION_LIST",
		DASHBOARD_SET_SCHOOL_BY_NEWS_LIST: "DASHBOARD_SET_SCHOOL_BY_NEWS_LIST",

		DASHBOARD_SET_MESSAGE_FILTER: "DASHBOARD_SET_MESSAGE_FILTER",
	},
	MSG_TABLE_COLUMN: [
		{ key: "title", value: "訊息標題", searchable: true },
		{ key: "scheduleTime", value: "發送時間", searchable: false },
	],
	SCHOOL_TABLE_COLUMN: [{ key: "name", value: "名稱", searchable: true }],
	SCHOOL_BY_RANK_TABLE_COLUMN: [
		{ key: "rank", value: "排名", searchable: false },
		{ key: "name", value: "名稱", searchable: true },
	],
	SCHOOL_BY_NEWS_TABLE_COLUMN: [
		{ key: "rank", value: "排名", searchable: false },
		{ key: "name", value: "名稱", searchable: true },
		{ key: "numMsg", value: "訊息量", searchable: false },
	],
};
