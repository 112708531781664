import React from "react";
import PropTypes from "prop-types";
import SvgIcon from "@material-ui/core/SvgIcon";

export const ExcelIcon = (props) => {
	return (
		<SvgIcon {...props}>
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 61.61 62.45" fill={props.fill}>
				<defs></defs>
				<g id="Layer_2" data-name="Layer 2">
					<g id="Layer_1-2" data-name="Layer 1">
						<g id="Group_2385" data-name="Group 2385">
							<g id="Group_2363" data-name="Group 2363">
								<g id="Group_2362" data-name="Group 2362">
									<path
										id="Path_776"
										data-name="Path 776"
										d="M34.37,31.22a1.4,1.4,0,0,0-.51-1.1L25.27,23a1.43,1.43,0,0,0-2,.19,1.38,1.38,0,0,0-.33.91v4.3H1.43A1.43,1.43,0,0,0,0,29.79v2.87a1.43,1.43,0,0,0,1.42,1.43h21.5v4.3a1.44,1.44,0,0,0,1.43,1.43,1.39,1.39,0,0,0,.91-.34l8.6-7.16A1.41,1.41,0,0,0,34.37,31.22Z"
									/>
								</g>
							</g>
							<g id="Group_2365" data-name="Group 2365">
								<g id="Group_2364" data-name="Group 2364">
									<path
										id="Path_777"
										data-name="Path 777"
										d="M60.77,29.2,32.41.84a2.86,2.86,0,0,0-4,0L8.1,21.1l4.05,4L30.38,6.91,54.69,31.22,30.38,55.53,12.15,37.3,8.1,41.35,28.36,61.61a2.86,2.86,0,0,0,4,0h0L60.77,33.25a2.86,2.86,0,0,0,0-4.05Z"
									/>
								</g>
							</g>
						</g>
					</g>
				</g>
			</svg>
		</SvgIcon>
	);
};
ExcelIcon.propTypes = {
	fill: PropTypes.string,
};
