/* eslint-disable react/prop-types */
import React from "react";
import { useSelector } from "react-redux";
import { Route, Redirect } from "react-router-dom";

export const PrivateRoute = ({ component: Component, ...rest }) => {
	const { user, loggedIn } = useSelector(({ auth }) => ({
		user: auth.user,
		loggedIn: auth.loggedIn,
	}));

	return (
		<Route
			{...rest}
			render={(props) =>
				user && loggedIn ? <Component {...props} /> : <Redirect to={{ pathname: "/", state: { from: props.location } }} />
			}
		/>
	);
};
