import React, { useState } from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";

import ProgressiveRejectDebouncer from "_util/debouncers/ProgressiveRejectDebouncer";

export const SubmitButton = (props) => {
	const { label, handleOnClick, style, active, buttonIcon } = props;

	const [debouncer] = useState(() => new ProgressiveRejectDebouncer(1.0 * 1000));

	return (
		<Button
			variant="contained"
			style={{
				padding: "8px 64px",
				backgroundColor: active ? "#333399" : "#eeeeee",
				borderColor: "#333399",
				color: active ? "white" : "#585858",
				fill: active ? "white" : "#585858",
				borderRadius: 40,
				textAlign: "center",
				whiteSpace: "nowrap",
				...style,
			}}
			onClick={() => {
				if (!debouncer.token()) return;
				if (!!handleOnClick && !!active) {
					handleOnClick();
				}
			}}
			startIcon={buttonIcon}
		>
			{label}
		</Button>
	);
};

SubmitButton.propTypes = {
	label: PropTypes.string,
	handleOnClick: PropTypes.func,
	style: PropTypes.object,
	active: PropTypes.bool,
	buttonIcon: PropTypes.object,
};

SubmitButton.defaultProps = {
	active: true,
};
