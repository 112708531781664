import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import moment from "moment";
import isEqual from "react-fast-compare";

import { ArrowDropDown, ArrowDropUp } from "@material-ui/icons";

import { history } from "_util/_helper";
import { reportConstants, generalConstants, statusConstants } from "_constants";
import { reportAction } from "actions";
import { Status, Pagination, RefreshButton } from "components/general";
import { StandardTextField } from "components/general/standard";

import { AdminMenubar, AdminUserInfo, AdminSectionbar, AdminFooter } from "components/admin";
import {
	PageContainer,
	LeftContainer,
	RightContainer,
	ContentContainer,
	SearchBar,
	SearchBarLeft,
	SearchBarRight,
	PaginationContainer,
	TableContainer,
	TableRow,
	TableTh,
	THButton,
	TableTd,
} from "components/layout";

import ProgressiveRejectDebouncer from "_util/debouncers/ProgressiveRejectDebouncer";

const DEFAULT_PAGE = 1;
export const ReportManagementPage = () => {
	const { navigationObject } = useSelector(({ report }) => ({ navigationObject: report.navigationObject?.root }), isEqual);
	const { reportList } = useSelector(({ report }) => ({ reportList: report.navigationObject?.root.reportList }));
	const { currentPage } = useSelector(({ report }) => ({ currentPage: report.navigationObject?.root.currentPage }));
	const { totalItems } = useSelector(({ report }) => ({ totalItems: report.navigationObject?.root.totalItems }));
	const { numOfPage } = useSelector(({ report }) => ({ numOfPage: report.navigationObject?.root.numOfPage }));

	const [searchWord, setSearchWord] = useState(navigationObject?.searchWord || "");
	const [sortingKey, setSortingKey] = useState(navigationObject?.sortingKey || "status");
	const [sortingDirection, setSortingDirection] = useState(navigationObject?.sortingDirection || "DESC");

	const [page, setPage] = useState(currentPage || DEFAULT_PAGE);

	const dispatch = useDispatch();
	const timeoutRef = useRef(null);
	const [textDebouncer] = useState(() => new ProgressiveRejectDebouncer(0.7 * 1000));
	const [debouncer] = useState(() => new ProgressiveRejectDebouncer(1 * 1000));

	const handleSorting = (sortKey) => {
		if (!debouncer.token()) {
			return;
		}
		let direction = "ASC";
		if (sortingKey === sortKey && sortingDirection === "ASC") {
			direction = "DESC";
		}
		setSortingKey(sortKey);
		setSortingDirection(direction);
		dispatch(
			reportAction.fetchReport({
				searchWord,
				page: DEFAULT_PAGE,
				sort: `${sortKey}:${direction}`,
			})
		);
	};

	const handlePageChange = (pageNum) => {
		if (!debouncer.token() || currentPage === pageNum) {
			return;
		}
		dispatch(
			reportAction.fetchReport({
				searchWord,
				page: pageNum,
				sort: `${sortingKey}:${sortingDirection}`,
			})
		);
		setPage(pageNum);
	};

	function resetTimeout() {
		if (timeoutRef.current) {
			clearTimeout(timeoutRef.current);
		}
	}

	const handleChange = ({ key, value }) => {
		var targetWord = searchWord;
		if (key === "searchWord") {
			setSearchWord(value);
			targetWord = value;
		}
		if (!textDebouncer.token()) {
			resetTimeout();
			timeoutRef.current = setTimeout(() => {
				handleSearch(targetWord);
			}, 1000);
			return;
		}
		resetTimeout();
		handleSearch(targetWord);
	};

	const handleSearch = (value) => {
		dispatch(
			reportAction.fetchReport({
				searchWord: value,
				page: DEFAULT_PAGE,
				sort: `${sortingKey}:${sortingDirection}`,
			})
		);
	};

	useEffect(() => {
		dispatch(
			reportAction.fetchReport({
				searchWord,
				page,
				sort: `${sortingKey}:${sortingDirection}`,
			})
		);
	}, [dispatch]);

	const handleRefreshFilter = () => {
		if (searchWord === "" && sortingKey === "status" && sortingDirection === "DESC" && currentPage === DEFAULT_PAGE) {
			return;
		}
		setSearchWord("");
		setSortingKey("status");
		setSortingDirection("DESC");
		dispatch(
			reportAction.fetchReport({
				searchWord: "",
				page: DEFAULT_PAGE,
				sort: `status:DESC`,
			})
		);
	};

	const renderTH = () => {
		return reportConstants.TABLE_COLUMN.map((column) => {
			return (
				<TableTh key={`${column.key}_table_header`} style={{ width: column.width }}>
					<THButton key={`${column.key}_table_header`} type="button" onClick={() => handleSorting(column.key)}>
						<div style={{ textAlign: column.key === "status" ? "center" : "auto" }}>
							{column.value}
							{sortingKey === column.key ? (
								sortingDirection === "ASC" ? (
									<ArrowDropUp fontSize={"small"} style={{ verticalAlign: "middle" }} />
								) : (
									<ArrowDropDown fontSize={"small"} style={{ verticalAlign: "middle" }} />
								)
							) : null}
						</div>
					</THButton>
				</TableTh>
			);
		});
	};
	const renderTableHeader = renderTH();

	const getTitle = (title) => {
		const maxLength = 50;
		try {
			if (title.length === 0) return "[沒有內容]";
			let tail = title.length > maxLength ? "..." : "";
			return title.substr(0, maxLength) + tail;
		} catch (e) {
			return "[沒有內容]";
		}
	};

	const getStatus = (item) => {
		let status = "REPORT_" + item.status;

		if (item.deleted) status = "REPORT_DELETED";
		if (status === "REPORT_COMPLETED" && item.blocked) status = "REPORT_BLOCKED";

		let statusList = statusConstants.status;
		let thekey = "REPORT_PENDING";

		for (let k in statusList) {
			if (k === status) {
				thekey = k;
			}
		}
		return statusConstants.status[thekey].key;
	};

	const HandleRowOnClick = (item) => {
		history.push({
			pathname: generalConstants.PATH.REPORT_TARGET,
			state: {
				reportKey: item.key,
				targetType: item.targetType,
				batchTime: item.batchTime ?? "",
			},
		});
	};

	const renderTD = () => {
		if (reportList?.length > 0) {
			return reportList?.map((report, index) => {
				const tempObj = {};
				reportConstants.TABLE_COLUMN.forEach((column) => {
					tempObj[column.key] = report[column.key];
				});

				return (
					<TableRow
						key={`${report.key}_${index}_tr`}
						onClick={() => {
							HandleRowOnClick(report);
						}}
					>
						{Object.keys(tempObj).map((columnName, index) => {
							if (columnName === "status") {
								return (
									<TableTd key={`${report.key}_${columnName}_${index}_td`} style={{ verticalAlign: "middle" }}>
										<Status status={getStatus(report)} />
									</TableTd>
								);
							} else if (columnName === "targetType") {
								return (
									<TableTd key={`${report.key}_${columnName}_${index}_td`}>
										{reportConstants.Translate[columnName][tempObj[columnName]]}
									</TableTd>
								);
							} else if (columnName === "text") {
								return (
									<TableTd key={`${report.key}_${columnName}_${index}_td`}>
										<TitleText>{report.key ? getTitle(tempObj[columnName]) : "[帖子已經不存在]"}</TitleText>
									</TableTd>
								);
							} else if (columnName === "reportDate" || columnName === "time" || columnName === "handleDate") {
								return (
									<TableTd key={`${report.key}_${columnName}_${index}_td`}>
										{tempObj[columnName] ? moment(tempObj[columnName]).format("YYYY年MM月DD日 hh:mmA") : "-"}
									</TableTd>
								);
							}
							return <TableTd key={`${report.key}_${columnName}_${index}_td`}>{tempObj[columnName]}</TableTd>;
						})}
					</TableRow>
				);
			});
		}
	};
	const renderTableCell = renderTD();

	return (
		<>
			<PageContainer>
				<LeftContainer>
					<AdminMenubar section={generalConstants.NAV_TAB.REPORT_MANAGEMENT.key} />
				</LeftContainer>
				<RightContainer>
					<AdminUserInfo />
					<AdminSectionbar
						title={generalConstants.NAV_TAB.REPORT_MANAGEMENT.key}
						sublabel={`舉報數量: ${totalItems}`}
						locationPathArray={[
							{ title: "主頁面", isAction: true, target: generalConstants.PATH.DASHBOARD },
							{
								title: generalConstants.TAB_NAME[generalConstants.NAV_TAB.REPORT_MANAGEMENT.key],
								isAction: false,
								target: "",
							},
						]}
					/>
					<ContentContainer>
						<SearchBar>
							<SearchBarLeft>
								<RowInBlock marginBottom="0px">
									<Title style={{ fontSize: "13px", lineHeight: "27px" }}>舉報搜尋</Title>
									<div style={{ marginLeft: "10px", width: "40%" }}>
										<StandardTextField
											placeholder="關鍵字"
											value={searchWord}
											name="searchWord"
											handleChange={handleChange}
											style={{ borderTopRightRadius: "0px", borderBottomRightRadius: "0px" }}
										/>
									</div>
									<RefreshButton style={{ marginLeft: "5px" }} handleOnClick={handleRefreshFilter} label="重置選項"></RefreshButton>
								</RowInBlock>
							</SearchBarLeft>
							<SearchBarRight />
						</SearchBar>
						<TableContainer>
							<table style={{ width: "100%", borderCollapse: "collapse", tableLayout: "fixed" }}>
								<thead>
									<tr>{renderTableHeader}</tr>
								</thead>
								<tbody>{renderTableCell}</tbody>
							</table>
						</TableContainer>
						<PaginationContainer>
							<Pagination count={numOfPage} page={currentPage} handlePageChange={handlePageChange} />
						</PaginationContainer>
					</ContentContainer>
					<AdminFooter></AdminFooter>
				</RightContainer>
			</PageContainer>
		</>
	);
};

const TitleText = styled.span`
	color: #0085b5;
	text-decoration: underline;
	max-height: 70px;
	display: block;
	overflow: hidden;
	text-overflow: ellipsis;
`;

const RowInBlock = styled.div`
	display: flex;
	margin-bottom: ${(props) => props.marginBottom || "10px"};
	flex-direction: row;
`;

const Title = styled.div`
	font-size: 1.3em;
	color: black;
	font-weight: bold;
	margin-right: 10px;
`;
