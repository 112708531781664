import { learningCoursesConstants } from "_constants";
import { learningCoursesService } from "services";
import { toast } from "react-toastify";

export const learningCoursesAction = {
	createLearningCourse,
	updateLearningCourse,
	getLearningCourses,
	getLearningCourseByKey,
	deleteLearningCourse,
};

function deleteLearningCourse(data) {
	return (dispatch) => {
		dispatch(request());
		learningCoursesService.deleteLearningCourse(data).then(
			() => {
				dispatch(success());
			},
			(error) => {
				toast.error(error.error);
				dispatch(failure());
			}
		);
	};

	function request() {
		return { type: learningCoursesConstants.type.DELETE_LEARNING_COURSES_REQUEST };
	}
	function failure() {
		return { type: learningCoursesConstants.type.DELETE_LEARNING_COURSES_FAILURE };
	}
	function success() {
		return { type: learningCoursesConstants.type.DELETE_LEARNING_COURSES_SUCCESS };
	}
}

function createLearningCourse(data) {
	return (dispatch) => {
		dispatch(request());
		learningCoursesService.createLearningCourse(data).then(
			() => {
				dispatch(success());
			},
			(error) => {
				toast.error(error.error);
				dispatch(failure());
			}
		);
	};

	function request() {
		return { type: learningCoursesConstants.type.CREATE_LEARNING_COURSES_REQUEST };
	}
	function failure() {
		return { type: learningCoursesConstants.type.CREATE_LEARNING_COURSES_FAILURE };
	}
	function success() {
		return { type: learningCoursesConstants.type.CREATE_LEARNING_COURSES_SUCCESS };
	}
}

function updateLearningCourse(data) {
	return (dispatch) => {
		dispatch(request());
		learningCoursesService.updateLearningCourse(data).then(
			() => {
				dispatch(success());
			},
			(error) => {
				toast.error(error.error);
				dispatch(failure());
			}
		);
	};

	function request() {
		return { type: learningCoursesConstants.type.UPDATE_LEARNING_COURSES_REQUEST };
	}
	function failure() {
		return { type: learningCoursesConstants.type.UPDATE_LEARNING_COURSES_FAILURE };
	}
	function success() {
		return { type: learningCoursesConstants.type.UPDATE_LEARNING_COURSES_SUCCESS };
	}
}

function getLearningCourses(data, navigationKey) {
	return (dispatch) => {
		dispatch(request());
		learningCoursesService.getLearningCourses(data).then(
			(response) => {
				dispatch(success(response, data));
			},
			(error) => {
				toast.error(error.error);
				dispatch(failure());
			}
		);
	};

	function request() {
		return { type: learningCoursesConstants.type.FETCH_LEARNING_COURSES_LIST_REQUEST };
	}
	function failure() {
		return { type: learningCoursesConstants.type.FETCH_LEARNING_COURSES_LIST_FAILURE };
	}
	function success(response, inputData) {
		const { totalItems, items } = response;
		let sortingKey = "name",
			sortingDirection = "ASC";
		const sortingArray = inputData?.sort?.split(":");
		if (sortingArray && sortingArray.length === 2 && sortingArray[0] && sortingArray[1]) {
			sortingKey = sortingArray[0];
			sortingDirection = sortingArray[1] === "DESC" ? "DESC" : "ASC";
		}

		return {
			type: learningCoursesConstants.type.FETCH_LEARNING_COURSES_LIST_SUCCESS,
			navigationKey,
			totalNum: totalItems,
			totalPage: Math.ceil(totalItems / data.l),
			currentPage: data.p,
			learningCoursesList: items,
			sortingDirection,
			sortingKey,
			searchKey: inputData.search || "",
			district: inputData.district || "",
			category: inputData.category || "",
		};
	}
}

function getLearningCourseByKey(key) {
	return (dispatch) => {
		dispatch(request());
		learningCoursesService.getLearningCourseByKey(key).then(
			(response) => {
				dispatch(success(response));
			},
			(error) => {
				toast.error(error.error);
				dispatch(failure());
			}
		);
	};

	function request() {
		return { type: learningCoursesConstants.type.FETCH_COURSES_REQUEST };
	}
	function failure() {
		return { type: learningCoursesConstants.type.FETCH_COURSES_FAILURE };
	}
	function success(course) {
		return {
			type: learningCoursesConstants.type.FETCH_COURSES_SUCCESS,
			course,
		};
	}
}
