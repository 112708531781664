import { authConstants, learningCentreConstants, organizationConstants } from "_constants";

const initialState = {
	isFetching: false,
	deleteInProgress: false,
	isFetchError: false,
	centre: {},
	createLearningCentreSuccess: false,
	updateLearningCentreSuccess: false,
	deleteLearningCentreSuccess: false,
	navigationObject: {
		organization: {
			searchKey: "",
			district: "",
			category: "",
			sortingKey: "name",
			sortingDirection: "ASC",
			learningCentreList: [],
			numOfPage: 1,
			currentPage: 1,
			totalItems: 0,
		},
		root: {
			searchKey: "",
			district: "",
			category: "",
			sortingKey: "name",
			sortingDirection: "ASC",
			learningCentreList: [],
			numOfPage: 1,
			currentPage: 1,
			totalItems: 0,
		},
	},
};

const learningCentreReducer = (state = initialState, action) => {
	switch (action.type) {
		case authConstants.type.UNAUTHORIZED_ERROR: {
			let navigationObject = initialState.navigationObject;
			if (typeof state.navigationObject?.organization != "undefined") {
				navigationObject = {
					...state.navigationObject,
					organization: { ...state.navigationObject?.organization, currentPage: 1 },
				};
			}
			if (typeof state.navigationObject?.root != "undefined") {
				navigationObject = {
					...state.navigationObject,
					root: { ...state.navigationObject?.root, currentPage: 1 },
				};
			}
			return {
				...state,
				navigationObject: navigationObject,
			};
		}
		case learningCentreConstants.type.FETCH_LEARNING_CENTRE_LIST_REQUEST: {
			return {
				...state,
				isFetching: true,
				isFetchError: false,
			};
		}
		case learningCentreConstants.type.FETCH_LEARNING_CENTRE_LIST_FAILURE: {
			return {
				...state,
				isFetching: false,
				isFetchError: true,
			};
		}
		case learningCentreConstants.type.FETCH_LEARNING_CENTRE_LIST_SUCCESS: {
			const navigationObject = {
				searchKey: action.searchKey,
				district: action.district,
				category: action.category,
				sortingKey: action.sortingKey,
				sortingDirection: action.sortingDirection,
				totalItems: action.totalNum,
				numOfPage: action.totalPage,
				currentPage: action.currentPage,
				learningCentreList: action.learningCentreList,
			};
			if (action.isOrganizationDetail) {
				return {
					...state,
					isFetching: false,
					isFetchError: false,
					navigationObject: {
						...state.navigationObject,
						organization: {
							...navigationObject,
						},
					},
				};
			} else {
				return {
					...state,
					isFetching: false,
					isFetchError: false,
					navigationObject: {
						...state.navigationObject,
						root: {
							...navigationObject,
						},
					},
				};
			}
		}
		case organizationConstants.type.FETCH_ORGANIZATION_SUCCESS: {
			if (action.includeSubsidiary) {
				return {
					...state,
					isFetching: false,
					isFetchError: false,
					navigationObject: {
						...state.navigationObject,
						organization: {
							...action.navigationObject?.center,
						},
					},
				};
			} else {
				return state;
			}
		}
		case learningCentreConstants.type.FETCH_CENTRE_REQUEST: {
			return {
				...state,
				isFetching: true,
				isFetchError: false,
				centre: {},
			};
		}
		case learningCentreConstants.type.FETCH_CENTRE_FAILURE: {
			return {
				...state,
				isFetching: false,
				isFetchError: true,
			};
		}
		case learningCentreConstants.type.FETCH_CENTRE_SUCCESS: {
			return {
				...state,
				isFetching: false,
				isFetchError: false,
				centre: action.centre,
			};
		}
		case learningCentreConstants.type.CREATE_LEARNING_CENTRE_REQUEST: {
			return {
				...state,
				isFetching: true,
				createLearningCentreSuccess: false,
			};
		}
		case learningCentreConstants.type.CREATE_LEARNING_CENTRE_FAILURE: {
			return {
				...state,
				isFetching: false,
				createLearningCentreSuccess: false,
			};
		}
		case learningCentreConstants.type.CREATE_LEARNING_CENTRE_SUCCESS: {
			return {
				...state,
				isFetching: false,
				createLearningCentreSuccess: true,
			};
		}
		case learningCentreConstants.type.UPDATE_LEARNING_CENTRE_REQUEST: {
			return {
				...state,
				isFetching: true,
				updateLearningCentreSuccess: false,
			};
		}
		case learningCentreConstants.type.UPDATE_LEARNING_CENTRE_FAILURE: {
			return {
				...state,
				isFetching: false,
				updateLearningCentreSuccess: false,
			};
		}
		case learningCentreConstants.type.UPDATE_LEARNING_CENTRE_SUCCESS: {
			return {
				...state,
				isFetching: false,
				updateLearningCentreSuccess: true,
			};
		}
		case learningCentreConstants.type.DELETE_LEARNING_CENTRE_REQUEST: {
			return {
				...state,
				deleteInProgress: true,
				deleteLearningCentreSuccess: false,
			};
		}
		case learningCentreConstants.type.DELETE_LEARNING_CENTRE_FAILURE: {
			return {
				...state,
				deleteInProgress: false,
				deleteLearningCentreSuccess: false,
			};
		}
		case learningCentreConstants.type.DELETE_LEARNING_CENTRE_SUCCESS: {
			return {
				...state,
				deleteInProgress: false,
				deleteLearningCentreSuccess: true,
			};
		}
		case learningCentreConstants.type.RESET_LEARNING_CENTRE_SUCCESS: {
			return {
				...state,
				createLearningCentreSuccess: false,
				updateLearningCentreSuccess: false,
				deleteLearningCentreSuccess: false,
				isFetchError: false,
			};
		}
		default: {
			return state;
		}
	}
};

export default learningCentreReducer;
