import React, { useState } from "react";
import PropTypes from "prop-types";
import FormControl from "@material-ui/core/FormControl";
import { default as MuiSelect } from "@material-ui/core/Select";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import MenuItem from "@material-ui/core/MenuItem";

export const ApprovalSelect = (props) => {
	const { name, options, value, handleChange, displayEmpty, emptyText } = props;
	const color = {
		PENDING: "#333333",
		APPROVED: "#333333",
		REJECTED: "#ffffff",
	};

	const backgroundColor = {
		PENDING: "#ffd95c",
		APPROVED: "#5cff82",
		REJECTED: "#ff5c5c",
	};

	const [selectColor, setSelectColor] = useState(color["PENDING"]);
	const [selectBackgroundColor, setSelectBackgroundColor] = useState(backgroundColor["PENDING"]);

	const renderItem = () => {
		if (!!options && options.length > 0) {
			return options.map((option, index) => {
				return (
					<MenuItem key={`${name}_select_${index}`} value={option.key} style={{ fontSize: 13 }}>
						{option.value}
					</MenuItem>
				);
			});
		}
	};

	const handleLocalChange = ({ key, value }) => {
		if (handleChange) {
			setSelectColor(color[value]);
			setSelectBackgroundColor(backgroundColor[value]);
			handleChange({ key, value });
		}
	};

	const renderItems = renderItem();

	return (
		<FormControl variant="outlined" fullWidth>
			<MuiSelect
				margin="dense"
				style={{ color: selectColor, backgroundColor: selectBackgroundColor }}
				inputProps={{ style: { fontSize: 13 } }}
				id={`${name}_select`}
				onChange={(event) => {
					handleLocalChange({ key: event.target.name, value: event.target.value });
				}}
				name={name}
				value={value}
				input={<OutlinedInput style={{ fontSize: 13 }} labelWidth={0} />}
				displayEmpty={displayEmpty}
			>
				{!!displayEmpty && (
					<MenuItem value={""} style={{ fontSize: 13, height: "36px" }}>
						{emptyText ? emptyText : ""}
					</MenuItem>
				)}
				{renderItems}
			</MuiSelect>
		</FormControl>
	);
};

ApprovalSelect.propTypes = {
	name: PropTypes.string,
	options: PropTypes.array,
	value: PropTypes.string,
	handleChange: PropTypes.func,
	displayEmpty: PropTypes.bool,
	emptyText: PropTypes.string,
};

ApprovalSelect.default = {
	displayEmpty: false,
};
