export default class ProgressiveRejectDebouncer {
	constructor(...freezeTimes) {
		this.nextTime = Date.now();

		this.freezeTimes = freezeTimes;
		this.nextFreezeTimeIndex = 0;
	}

	token() {
		const currTime = Date.now();
		if (currTime >= this.nextTime) {
			this.nextTime = currTime + this.freezeTimes[this.nextFreezeTimeIndex];

			this.nextFreezeTimeIndex = Math.min(this.nextFreezeTimeIndex + 1, this.freezeTimes.length - 1);

			return true;
		} else {
			return false;
		}
	}
}
