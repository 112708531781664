import { adminConstants, authConstants, generalConstants } from "_constants";
import { adminService } from "services";
import { toast } from "react-toastify";
import { history } from "_util/_helper";

export const adminAction = {
	setPassword,
	login,
	getAdminList,
	getAdmin,
	createAdmin,
	updateAdmin,
	resendActivationEmail,
	forgetPassword,
};

function setPassword(form) {
	return (dispatch) => {
		dispatch(request());
		adminService.setPassword(form).then(
			() => {
				dispatch(success());
				dispatch(reset());
			},
			(error) => {
				toast.error(error.error);
				dispatch(failure());
			}
		);
	};

	function request() {
		return { type: adminConstants.type.SET_PASSWORD_REQUEST };
	}
	function failure() {
		return { type: adminConstants.type.SET_PASSWORD_FAILURE };
	}
	function success() {
		return {
			type: adminConstants.type.SET_PASSWORD_SUCCESS,
		};
	}
	function reset() {
		return {
			type: adminConstants.type.RESET_PASSWORD_SUCCESS,
		};
	}
}

function login(form) {
	return (dispatch) => {
		dispatch(request());
		adminService.login(form).then(
			(response) => {
				dispatch(success());
				dispatch(login(response));
			},
			(error) => {
				toast.error(error.error);
				dispatch(failure());
			}
		);
	};

	function request() {
		return { type: adminConstants.type.LOGIN_REQUEST };
	}
	function failure() {
		return { type: adminConstants.type.LOGIN_FAILURE };
	}
	function success() {
		return {
			type: adminConstants.type.LOGIN_SUCCESS,
		};
	}
	function login(user) {
		return {
			type: authConstants.type.LOGIN_SUCCESS,
			user,
		};
	}
}

function getAdminList(navigationKey) {
	return (dispatch) => {
		dispatch(request());
		adminService.getAdminList().then(
			(response) => {
				dispatch(success(response));
			},
			(error) => {
				toast.error(error.error);
				dispatch(failure());
			}
		);
	};

	function request() {
		return { type: adminConstants.type.FETCH_ADMIN_LIST_REQUEST };
	}
	function failure() {
		return { type: adminConstants.type.FETCH_ADMIN_LIST_FAILURE };
	}
	function success(adminList) {
		return {
			type: adminConstants.type.FETCH_ADMIN_LIST_SUCCESS,
			navigationKey,
			adminList,
		};
	}
}

function getAdmin(key) {
	return (dispatch) => {
		dispatch(request());
		adminService.getAdmin(key).then(
			(response) => {
				dispatch(success(response));
			},
			(error) => {
				toast.error(error.error);
				dispatch(failure());
				if (error.error?.status !== 401) {
					history.push(generalConstants.PATH.ADMIN_MANAGEMENT);
				}
			}
		);
	};

	function request() {
		return { type: adminConstants.type.FETCH_ADMIN_REQUEST };
	}
	function failure() {
		return { type: adminConstants.type.FETCH_ADMIN_FAILURE };
	}
	function success(admin) {
		return {
			type: adminConstants.type.FETCH_ADMIN_SUCCESS,
			admin,
		};
	}
}

function createAdmin(form) {
	return (dispatch) => {
		dispatch(request());
		adminService.createAdmin(form).then(
			() => {
				dispatch(success());
			},
			(error) => {
				toast.error(error.error);
				dispatch(failure());
			}
		);
	};

	function request() {
		return { type: adminConstants.type.CREATE_ADMIN_REQUEST };
	}
	function failure() {
		return { type: adminConstants.type.CREATE_ADMIN_FAILURE };
	}
	function success() {
		return {
			type: adminConstants.type.CREATE_ADMIN_SUCCESS,
		};
	}
}

function updateAdmin(form) {
	return (dispatch) => {
		dispatch(request());
		adminService.updateAdmin(form).then(
			() => {
				dispatch(success());
			},
			(error) => {
				toast.error(error.error);
				dispatch(failure());
			}
		);
	};

	function request() {
		return { type: adminConstants.type.UPDATE_ADMIN_REQUEST };
	}
	function failure() {
		return { type: adminConstants.type.UPDATE_ADMIN_FAILURE };
	}
	function success() {
		return { type: adminConstants.type.UPDATE_ADMIN_SUCCESS };
	}
}
function resendActivationEmail(key) {
	adminService.resendActivationEmail({ key }).then(
		() => {
			toast.success("已重發認證電郵");
		},
		(error) => {
			toast.error(error.error);
		}
	);
}

async function forgetPassword(form) {
	return adminService.forgetPassword(form).then(
		() => {
			toast.success("已發出重置密碼電郵");
			return true;
		},
		(error) => {
			toast.error(error.error);
			return false;
		}
	);
}
