const PasswordValidator = require("password-validator");

const minPwdLen = process.env.REACT_APP_MINIMUM_PASSWORD_LENGTH ? parseInt(process.env.REACT_APP_MINIMUM_PASSWORD_LENGTH, 10) : 8;
const maxPwdLen = process.env.REACT_APP_MAXIMUM_PASSWORD_LENGTH ? parseInt(process.env.REACT_APP_MAXIMUM_PASSWORD_LENGTH, 10) : 20;
const passwordValidator = new PasswordValidator();
passwordValidator
	.is()
	.min(minPwdLen)
	.is()
	.max(maxPwdLen)
	.has()
	.uppercase()
	.has()
	.lowercase()
	.has()
	.digits()
	.not(/[^0-9a-zA-Z~`!@#$%^&*]+/);
export const validatePassword = (password, oldPassword) => {
	const contains = passwordValidator.validate(password, { list: true });
	let message = "";
	let messageStack = [];
	if (contains && contains.length > 0) {
		if (oldPassword) {
			message += "新";
		}
		message += "密碼";
		if (contains.includes("min") || contains.includes("max")) {
			messageStack.push(
				`長度最少${process.env.REACT_APP_MINIMUM_PASSWORD_LENGTH}位,最多${process.env.REACT_APP_MAXIMUM_PASSWORD_LENGTH}位`
			);
		}
		if (contains.includes("lowercase") || contains.includes("uppercase") || contains.includes("digits")) {
			messageStack.push("最少含有1個大寫字母,1個小寫字母,1個數字");
		}
		if (contains.includes("not")) {
			messageStack.push("可用符號 ! @ # $ % ^ & * ");
		}
		message += messageStack.join(",");
	}
	if (message) {
		return message;
	}

	if (oldPassword && password === oldPassword) {
		return "不能使用現有密碼";
	}
	return message;
};
