import React, { useState, useEffect } from "react";
import { Prompt, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { statusConstants, generalConstants, parentConstants } from "_constants";
import { parentAction } from "actions";
import _ from "lodash";
import { history, AvatarHelper } from "_util/_helper";
import { SubmitButton, CancelButton, WarningButton, Loading, AlertDialog, Select } from "components/general";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import moment from "moment";
import AppleIcon from "@material-ui/icons/Apple";
import FacebookIcon from "@material-ui/icons/Facebook";
import EmailIcon from "@material-ui/icons/Email";
import { ReactComponent as GoogleIcon } from "resources/img/google.svg";

import { AdminMenubar, AdminUserInfo, AdminSectionbar, AdminFooter } from "components/admin";
import { PageContainer, LeftContainer, RightContainer, ContentContainer } from "components/layout";

const FROM_MISSION = "mission";
export const EditParentPage = () => {
	const { isFetching, submitParentSuccess, parent, loggedIn } = useSelector(({ parent, auth }) => ({
		isFetching: parent.isFetching,
		submitParentSuccess: parent.submitParentSuccess,
		parent: parent.parent,
		loggedIn: auth.loggedIn,
	}));

	const location = useLocation();
	const [prevPage, setPrevPage] = useState(location?.state?.from);
	const [key, setParentKey] = useState(location?.state?.parentKey);

	useEffect(() => {
		setPrevPage(location?.state?.from);
		setParentKey(location?.state?.parentKey);
	}, [location?.state]);

	const initForm = {
		key: "",
		status: parentConstants.DATA.status.UNACTIVATE.key,
		email: "",
		nickname: "",
		photoUrl: "",
		is_verified: "false",
		hasSocialAccount: false,
		contactEmail: "",
		phone: "",
		desc: "",
		accountType: parentConstants.DATA.accountType.PERSONAL.key,
		website: "",
		address: "",
		birthday: new Date(),
		occupation: "",
		sex: parentConstants.DATA.sex.MALE.key,
		businessCategory: "",
		is_private: true,
	};

	const [formData, setFormData] = useState(initForm);
	const [isLoading, setIsLoading] = useState(false);
	const [isChanged, setIsChanged] = useState(false);
	const [isSaved, setIsSaved] = useState(false);
	const [isResend, setIsResend] = useState(false);
	const [isVerifyAlertShow, setIsVerifyAlertShow] = useState(false);

	const dispatch = useDispatch();

	useEffect(() => {
		if (key) {
			dispatch(parentAction.getParentByKey(key));
		}
	}, [dispatch, key]);

	useEffect(() => {
		const data = {
			key: parent._key,
			status: parent.status,
			email: parent.email,
			nickname: parent.nickname ? parent.nickname : "",
			photoUrl: parent.photoUrl,
			is_verified: parent.is_verified ? "true" : "false",
			hasSocialAccount: parent.hasSocialAccount,
			contactEmail: parent.contactEmail,
			phone: parent.phone,
			desc: parent.desc,
			accountType: parent.accountType,
			website: parent.website,
			address: parent.address,
			birthday: parent.birthday,
			occupation: parent.occupation,
			sex: parent.sex,
			businessCategory: parent.businessCategory,
			is_private: parent.is_private,
			coinBalance: parent.coinBalance || 0,
			completedMission: parent.completedMission || 0,
			deleted: parent.deleted,
		};
		setFormData(data);
	}, [parent]);

	const handleChange = ({ key, value }) => {
		setFormData({ ...formData, [key]: value });
		setIsChanged(true);
	};

	const handleBack = () => {
		history.push(prevPage === FROM_MISSION ? generalConstants.PATH.MISSION_MANAGEMENT : generalConstants.PATH.PARENT_MANAGEMENT);
	};

	useEffect(() => {
		setIsLoading(isFetching);
	}, [isFetching]);

	useEffect(() => {
		if (submitParentSuccess) {
			if (!isSaved) {
				setIsSaved(true);
				return;
			}
			dispatch({ type: parentConstants.type.RESET_PARENT_SUCCESS });
			toast.success("已成功更新");
			handleReplace();
		}
	}, [submitParentSuccess, isSaved, dispatch]);

	const handleReplace = () => {
		history.replace(prevPage === FROM_MISSION ? generalConstants.PATH.MISSION_MANAGEMENT : generalConstants.PATH.PARENT_MANAGEMENT);
	};

	const displaySignInMethod = (input) => {
		if (input) {
			if (input.localeCompare(parentConstants.signInMethod.FACEBOOK) === 0) {
				return <FacebookIcon size={24} htmlColor="black" />;
			} else if (input.localeCompare(parentConstants.signInMethod.GOOGLE) === 0) {
				return <GoogleIcon style={{ width: 18, height: 18 }} fill="black" />;
			} else if (input.localeCompare(parentConstants.signInMethod.APPLE) === 0) {
				return <AppleIcon size={24} htmlColor="black" />;
			} else if (input.localeCompare(parentConstants.signInMethod.EMAIL) === 0) {
				return <EmailIcon size={24} htmlColor="black" />;
			} else {
				return;
			}
		} else {
			return;
		}
	};

	const displaySubscriptionStore = (input) => {
		if (input) {
			if (input.localeCompare(parentConstants.VIP_SUBSCRIPTION_STORE.GOOGLE) === 0) {
				return <GoogleIcon style={{ width: 12, height: 12 }} fill="white" />;
			} else if (input.localeCompare(parentConstants.VIP_SUBSCRIPTION_STORE.APPLE) === 0) {
				return <AppleIcon style={{ width: 15, height: 15, marginTop: 2 }} htmlColor="white" />;
			} else {
				return;
			}
		} else {
			return;
		}
	};

	const handleSubmit = () => {
		const data = {
			key: formData.key,
			is_verified: formData.is_verified === "true" ? true : false,
		};
		if (formData.coinBalance) {
			delete formData.coinBalance;
		}
		if (formData.completedMission) {
			delete formData.completedMission;
		}
		dispatch(parentAction.updateParent(data));
	};

	const handleResendEmail = () => {
		parentAction.resendActivationEmail(parent._key);
		setIsResend(true);
	};

	const handleCloseVerifyAlert = () => {
		setIsVerifyAlertShow(false);
	};

	const getStatus = (item) => {
		const status = "PARENT_" + item.status;

		const statusList = statusConstants.status;
		let thekey = "PARENT_ACTIVE";

		for (let k in statusList) {
			if (k === status) {
				thekey = k;
			}
		}
		return item.deleted ? statusConstants.status.PARENT_DELETED.key : statusConstants.status[thekey].key;
	};

	const getPrivateStatus = (item) => {
		return item.is_private ? parentConstants.DATA.privateStatus.PRIVATE.key : parentConstants.DATA.privateStatus.PUBLIC.key;
	};

	const getSocialStatus = (item) => {
		const hasSocialAccount = item.hasSocialAccount;
		const is_verified = item.is_verified;

		if (hasSocialAccount) {
			return is_verified ? statusConstants.status.SOCIAL_NETWORK_VERIFIED.key : statusConstants.status.SOCIAL_NETWORK_UNVERIFIED.key;
		} else {
			return statusConstants.status.SOCIAL_NETWORK_NONE.key;
		}
	};

	const getAccountType = (item) => {
		try {
			const thekey = item.accountType;
			return parentConstants.DATA.accountType[thekey].label;
		} catch (e) {
			return "";
		}
	};

	const getSex = (item) => {
		try {
			const thekey = _.upperCase(item.sex);
			return parentConstants.DATA.sex[thekey].label;
		} catch (e) {
			return "";
		}
	};

	const getText = (text) => {
		return text ? text.replace(/\n/g, "<br />") : "";
	};

	const getLocationPath = () => {
		const pathArray = [];

		pathArray.push({ title: "主頁面", isAction: true, target: generalConstants.PATH.DASHBOARD });
		if (prevPage === FROM_MISSION) {
			pathArray.push({
				title: generalConstants.TAB_NAME[generalConstants.NAV_TAB.MISSION_MANAGEMENT.key],
				isAction: true,
				target: generalConstants.PATH.MISSION_MANAGEMENT,
			});
		} else {
			pathArray.push({
				title: generalConstants.TAB_NAME[generalConstants.NAV_TAB.PARENT_MANAGEMENT.key],
				isAction: true,
				target: generalConstants.PATH.PARENT_MANAGEMENT,
			});
		}
		pathArray.push({
			title: generalConstants.TAB_NAME[generalConstants.NAV_TAB.EDIT_PARENT.key],
			isAction: false,
			target: "",
		});
		return pathArray;
	};

	const [isHovering, setIsHovering] = useState(false);

	const handleMouseOver = () => {
		setIsHovering(true);
	};

	const handleMouseOut = () => {
		setIsHovering(false);
	};

	const getVipSubscriptionPlanName = (plan) => {
		var planName = "";
		if (plan.includes(parentConstants.VIP_SUBSCRIPTION_DURATION.ONE_YEAR)) {
			planName = parentConstants.VIP_SUBSCRIPTION_PLAN_NAME.ONE_YEAR;
		} else if (plan.includes(parentConstants.VIP_SUBSCRIPTION_DURATION.THREE_MONTH)) {
			planName = parentConstants.VIP_SUBSCRIPTION_PLAN_NAME.THREE_MONTH;
		} else if (plan.includes(parentConstants.VIP_SUBSCRIPTION_DURATION.ONE_MONTH)) {
			planName = parentConstants.VIP_SUBSCRIPTION_PLAN_NAME.ONE_MONTH;
		}
		return planName;
	};

	const getVipSubscriptionStatusMessage = () => {
		var vipSubscriptionStatusMessage = "";
		if (parent.vipSubscriptionEffectiveDate && parent.vipSubscriptionStatus === parentConstants.VIP_STATUS.ACTIVE) {
			vipSubscriptionStatusMessage = `VIP已於${moment(parent.vipSubscriptionEffectiveDate).format("YYYY年M月DD日 hh:mmA")}生效`;
		} else if (parent.vipSubscriptionStatus === parentConstants.VIP_STATUS.CANCELLED) {
			if (parent.vipSubscriptionEffectiveDate) {
				vipSubscriptionStatusMessage = `VIP已於${moment(parent.vipSubscriptionEffectiveDate).format("YYYY年M月DD日 hh:mmA")}生效`;
			}
			if (parent.vipSubscriptionExpiryDate) {
				vipSubscriptionStatusMessage += `\nVIP將於${moment(parent.vipSubscriptionExpiryDate).format("YYYY年M月DD日 hh:mmA")}到期`;
			}
		} else if (parent.vipSubscriptionStatus === parentConstants.VIP_STATUS.EXPIRED) {
			if (parent.vipSubscriptionEffectiveDate) {
				vipSubscriptionStatusMessage = `VIP曾於${moment(parent.vipSubscriptionEffectiveDate).format("YYYY年M月DD日 hh:mmA")}生效`;
			}
			if (parent.vipSubscriptionExpiryDate) {
				vipSubscriptionStatusMessage += `\nVIP已於${moment(parent.vipSubscriptionExpiryDate).format("YYYY年M月DD日 hh:mmA")}到期`;
			}
		}
		return vipSubscriptionStatusMessage;
	};

	if (!key) {
		return null;
	}
	return (
		<>
			{!!isLoading && <Loading />}
			<Prompt when={isChanged && !isSaved && loggedIn} message="你有未儲存的變更，確定不儲存下離開？" />
			<AlertDialog active={isVerifyAlertShow} handleOnClose={handleCloseVerifyAlert} handleOnYes={handleSubmit} title={"確定提交？"} />
			<PageContainer>
				<LeftContainer>
					<AdminMenubar
						section={
							prevPage === FROM_MISSION ? generalConstants.NAV_TAB.MISSION_MANAGEMENT.key : generalConstants.NAV_TAB.PARENT_MANAGEMENT.key
						}
					/>
				</LeftContainer>
				<RightContainer>
					<AdminUserInfo />
					<AdminSectionbar title={generalConstants.NAV_TAB.EDIT_PARENT.key} locationPathArray={getLocationPath()} />
					<ContentContainer>
						<RowInBlock>
							<ContentLeft>
								<Title>家長資訊</Title>
								{parent.vipSubscriptionStatus && (
									<Block>
										<VipIconView onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
											<VipText
												style={{
													backgroundColor:
														parent.vipSubscriptionStatus === parentConstants.VIP_STATUS.ACTIVE ||
														parent.vipSubscriptionStatus === parentConstants.VIP_STATUS.CANCELLED
															? "#c8ae63"
															: "rgb(153,153,153)",
													color:
														parent.vipSubscriptionStatus === parentConstants.VIP_STATUS.ACTIVE ||
														parent.vipSubscriptionStatus === parentConstants.VIP_STATUS.CANCELLED
															? "#000"
															: "#fff",
												}}
											>
												VIP
												{isHovering && !_.isEmpty(getVipSubscriptionStatusMessage()) && (
													<Tooltiptext>
														<div
															style={{
																display: "flex",
																flexDirection: "row",
																alignItems: "center",
																justifyContent: "center",
															}}
														>
															{getVipSubscriptionPlanName(parent.vipSubscriptionPlanId)}
															<div style={{ marginLeft: "5px" }}>{displaySubscriptionStore(parent.vipSubscriptionStore)}</div>
														</div>
														{getVipSubscriptionStatusMessage()}
													</Tooltiptext>
												)}
											</VipText>
										</VipIconView>
									</Block>
								)}
								<Block>
									<TextFieldLabel>狀態</TextFieldLabel>
									<TextFieldValue>{statusConstants.status[getStatus(formData)].label}</TextFieldValue>
								</Block>
								<Block>
									<TextFieldLabel>用戶名稱</TextFieldLabel>
									<TextFieldValue>{parent.nickname}</TextFieldValue>
								</Block>
								{parent.status === statusConstants.status.PARENT_UNACTIVATE.key && (
									<Block style={{ width: "90%" }} marginTop={"10px"}>
										<WarningButton label={"重發認證電郵"} handleOnClick={handleResendEmail} active={!isResend} />
									</Block>
								)}
								<Block>
									<TextFieldLabel>電郵</TextFieldLabel>
									<BlockGroup style={{ alignItems: "center" }}>
										<TextFieldValue>{formData.email}</TextFieldValue>
										<div style={{ marginLeft: "5px" }}>{displaySignInMethod(parent.signInMethod)}</div>
									</BlockGroup>
								</Block>

								<Block>
									<TextFieldLabel>開戶時間</TextFieldLabel>
									<TextFieldValue>{parent.createdDate ? moment(parent.createdDate).format("YYYY年MM月DD日 hh:mmA") : ""}</TextFieldValue>
								</Block>
								<Block>
									<TextFieldLabel>最後登入時間</TextFieldLabel>
									<TextFieldValue>
										{parent.lastLoginDateTime ? moment(parent.lastLoginDateTime).format("YYYY年M月DD日 hh:mmA") : ""}
									</TextFieldValue>
								</Block>
								<Block>
									<TextFieldLabel>最後更新時間</TextFieldLabel>
									<TextFieldValue>{parent.updateDate ? moment(parent.updateDate).format("YYYY年MM月DD日 hh:mmA") : ""}</TextFieldValue>
								</Block>
							</ContentLeft>

							<ContentRight>
								<Title>童行網資訊</Title>

								<InnerContainer>
									<InnerLeft>
										<Block>
											<TextFieldLabel>頭像</TextFieldLabel>
											{!_.isEmpty(formData.photoUrl) && (
												<Avatar showBadge={false} src={AvatarHelper(formData.photoUrl)} size={45} type={"parent"} />
											)}
										</Block>
									</InnerLeft>
									<InnerRight>
										<BlockGroup>
											<Block flex="0 0 150px">
												<TextFieldLabel>狀態</TextFieldLabel>
												{parent.hasSocialAccount && !parent.deleted ? (
													<Select
														name={"is_verified"}
														options={parentConstants.OPTION.isVerified}
														value={formData.is_verified}
														handleChange={handleChange}
													/>
												) : (
													<TextFieldValue>
														{statusConstants.status[parent.deleted ? getSocialStatus(parent) : getSocialStatus(formData)].label}
													</TextFieldValue>
												)}
											</Block>
											<Block></Block>
										</BlockGroup>
										<BlockGroup>
											<Block flex="0 0 150px">
												<TextFieldLabel>帳戶類型</TextFieldLabel>
												<TextFieldValue>{getAccountType(formData) || "未填寫"}</TextFieldValue>
											</Block>
											<Block flex="0 0 150px">
												<TextFieldLabel>私隱設定</TextFieldLabel>
												<TextFieldValue>{parentConstants.DATA.privateStatus[getPrivateStatus(formData)].label}</TextFieldValue>
											</Block>
										</BlockGroup>
										<BlockGroup>
											{parent.accountType === parentConstants.DATA.accountType.PERSONAL.key && (
												<Block flex="0 0 150px">
													<TextFieldLabel>出生日期</TextFieldLabel>
													<TextFieldValue>
														{formData.birthday ? moment(formData.birthday).format("YYYY年MM月DD日") : "未填寫"}
													</TextFieldValue>
												</Block>
											)}
											{parent.accountType === parentConstants.DATA.accountType.PERSONAL.key && (
												<Block flex="0 0 150px">
													<TextFieldLabel>性別</TextFieldLabel>
													<TextFieldValue>{getSex(formData) || "未填寫"}</TextFieldValue>
												</Block>
											)}
											{parent.accountType === parentConstants.DATA.accountType.PERSONAL.key && (
												<Block flex="0 0 150px">
													<TextFieldLabel>職業</TextFieldLabel>
													<TextFieldValue>{formData.occupation || "未填寫"}</TextFieldValue>
												</Block>
											)}
										</BlockGroup>
										<Block>
											<TextFieldLabel>簡介</TextFieldLabel>
											<TextFieldValue dangerouslySetInnerHTML={{ __html: getText(formData.desc) || "未填寫" }} />
										</Block>
										<Block>
											<TextFieldLabel>地址</TextFieldLabel>
											<TextFieldValue dangerouslySetInnerHTML={{ __html: getText(formData.address) || "未填寫" }} />
										</Block>
										<BlockGroup>
											<Block flex="0 0 150px">
												<TextFieldLabel>電話</TextFieldLabel>
												<TextFieldValue>{formData.phone || "未填寫"}</TextFieldValue>
											</Block>
											<Block flex="0 0 150px">
												<TextFieldLabel>聯絡電郵</TextFieldLabel>
												<TextFieldValue>{formData.contactEmail || "未填寫"}</TextFieldValue>
											</Block>

											<Block flex="0 0 150px">
												<TextFieldLabel>網址</TextFieldLabel>
												<TextFieldValue>{formData.website || "未填寫"}</TextFieldValue>
											</Block>

											{parent.accountType === parentConstants.DATA.accountType.CORPORATE.key && (
												<Block flex="0 0 150px">
													<TextFieldLabel>機構類型</TextFieldLabel>
													<TextFieldValue>{formData.businessCategory || "未填寫"}</TextFieldValue>
												</Block>
											)}
										</BlockGroup>

										<BlockGroup>
											<Block flex="0 0 150px">
												<TextFieldLabel>KOT幣</TextFieldLabel>
												<Link
													style={{ textDecoration: "none" }}
													to={{
														pathname: `${generalConstants.PATH.MISSION_HISTORY}`,
														state: { parentKey: key, from: prevPage },
													}}
												>
													<LinkTitleValue>{formData.coinBalance || "0"}</LinkTitleValue>
												</Link>
											</Block>
											<Block flex="0 0 150px">
												<TextFieldLabel>完成任務次數</TextFieldLabel>
												<TextFieldValue>{formData.completedMission || "0"}</TextFieldValue>
											</Block>
										</BlockGroup>
									</InnerRight>
								</InnerContainer>
							</ContentRight>
						</RowInBlock>
						<RowInBlock marginTop={"10px"} style={{ justifyContent: "flex-end" }}>
							<CancelButton
								label={!formData.hasSocialAccount || parent.deleted ? "返回" : "取消"}
								style={{ marginRight: "15px" }}
								handleOnClick={handleBack}
							/>
							{formData.hasSocialAccount && !parent.deleted && (
								<SubmitButton
									label={"確認"}
									disabled={false}
									style={{ marginRight: "15px" }}
									handleOnClick={() => {
										setIsVerifyAlertShow();
									}}
									active={isChanged}
								/>
							)}
						</RowInBlock>
					</ContentContainer>
					<AdminFooter></AdminFooter>
				</RightContainer>
			</PageContainer>
		</>
	);
};

const ContentLeft = styled.div`
	flex: 0 0 250px;
	border-right: 1px solid #dddddd;
	min-height: 400px;
`;

const ContentRight = styled.div`
	flex: 1;
	padding-left: 20px;
`;

const InnerContainer = styled.div`
	display: flex;
	flex-direction: row;
`;

const InnerLeft = styled.div`
	flex: 0 0 120px;
`;

const InnerRight = styled.div`
	flex: 1;
	padding-left: 20px;
`;

const BlockGroup = styled.div`
	display: flex;
	flex-direction: row;
`;

const Block = styled.div`
	padding: 10px;
	flex: ${(props) => props.flex || 1};
`;

const RowInBlock = styled.div`
	display: flex;
	margin-bottom: ${(props) => props.marginBottom || "10px"};
	flex-direction: row;
`;

const TextFieldLabel = styled.div`
	color: #000000;
	font-size: 13px;
	line-height: 1.5;
	font-weight: bold;
	margin-bottom: 7px;
`;

const Title = styled.div`
	font-weight: bold;
	margin: 0 0 12px 4px;
	font-size: 14px;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.43;
	letter-spacing: normal;
	text-align: left;
	color: #333399;
	width: 100%;
`;

const TextFieldValue = styled.div`
	font-size: 13px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.46;
	letter-spacing: normal;
	text-align: left;
	color: #333333;
`;

const LinkTitleValue = styled.div`
	text-decoration: underline;
	display: block;
	overflow: hidden;
	text-overflow: ellipsis;
	font-size: 13px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.46;
	letter-spacing: normal;
	text-align: left;
	color: #0085b5;
`;

const Avatar = styled.img`
	border-radius: 50%;
	width: 80px;
	height: 80px;
	border: #aaaaaa 1px solid;
`;

const VipIconView = styled.div`
	display: flex;
	width: 32px;
	flex-direction: row;
	align-items: center;
	justify-content: center;
`;

const VipText = styled.div`
	font-size: 12;
	font-weight: bold;
	color: #333333;
	background-color: #c8ae63;
	padding-left: 5px;
	padding-right: 5px;
`;

const Tooltiptext = styled.span`
	width: 300px;
	background-color: rgba(0, 0, 0, 0.6);
	color: #fff;
	text-align: center;
	margin-top: 10px;
	padding: 5px 0;
	border-radius: 6px;
	position: absolute;
	z-index: 1;
	white-space: pre-line;
`;
