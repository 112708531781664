import React, { useState, useEffect, useCallback, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";

import { adminConstants, generalConstants } from "_constants";
import { adminAction } from "actions";
import _ from "lodash";
import moment from "moment";
import { history } from "_util/_helper";
import isEqual from "react-fast-compare";

import { useSortableData } from "_util/hook";

import ProgressiveRejectDebouncer from "_util/debouncers/ProgressiveRejectDebouncer";

import { Status, RefreshButton } from "components/general";
import { StandardSelect, StandardTextField } from "components/general/standard";
import { ArrowDropDown, ArrowDropUp } from "@material-ui/icons";
import { AdminMenubar, AdminUserInfo, AdminSectionbar, AdminFooter } from "components/admin";
import {
	PageContainer,
	LeftContainer,
	RightContainer,
	ContentContainer,
	SearchBar,
	SearchBarLeft,
	SearchBarRight,
	TableContainer,
	TableRow,
	TableTh,
	THButton,
	TableTd,
} from "components/layout";

export const AdminManagementPage = () => {
	const { navigationObject } = useSelector(({ admin }) => ({ navigationObject: admin.navigationObject?.root }), isEqual);
	const { adminList } = useSelector(({ admin }) => ({ adminList: admin.navigationObject?.root?.adminList }));

	const [displayList, setDisplayList] = useState(adminList || []);

	const [searchKey, setSearchKey] = useState(navigationObject?.searchKey || adminConstants.TABLE_COLUMN[1].key);
	const [searchWord, setSearchWord] = useState(navigationObject?.searchWord || "");

	const dispatch = useDispatch();
	const timeoutRef = useRef(null);
	const [textDebouncer] = useState(() => new ProgressiveRejectDebouncer(0.7 * 1000));

	const { items, requestSort, sortConfig } = useSortableData(displayList);

	useEffect(() => {
		setDisplayList(adminList || []);
		if (searchWord != "") {
			handleSearch(searchKey, searchWord);
		}
	}, [adminList]);

	const handleCreateAdmin = () => {
		history.push(generalConstants.PATH.CREATE_ADMIN);
	};

	const HandleRowOnClick = (item) => {
		history.push({ pathname: generalConstants.PATH.ADMIN_INFO, state: { adminKey: item._key } });
	};

	function resetTimeout() {
		if (timeoutRef.current) {
			clearTimeout(timeoutRef.current);
		}
	}

	const handleChange = ({ key, value }) => {
		var targetKey = searchKey;
		var targetWord = searchWord;
		if (key === "searchKey") {
			setSearchKey(value);
			targetKey = value;
		} else if (key === "searchWord") {
			setSearchWord(value);
			targetWord = value;
		}
		if (key === "searchKey" && _.isEmpty(searchWord)) {
			return;
		}
		if (!textDebouncer.token()) {
			resetTimeout();
			timeoutRef.current = setTimeout(() => {
				handleSearch(targetKey, targetWord);
			}, 1000);
			return;
		}
		resetTimeout();
		handleSearch(targetKey, targetWord);
	};

	const handleSearch = useCallback(
		(key, value) => {
			const result = _.filter(adminList, function (o) {
				if (o[key]) {
					return o[key].toLowerCase().includes(value.toLowerCase());
				}
			});
			setDisplayList(result);
		},
		[adminList]
	);

	const handleRefreshFilter = async () => {
		setSearchKey(adminConstants.TABLE_COLUMN[1].key);
		setSearchWord("");
		setDisplayList(adminList);
	};

	useEffect(() => {
		dispatch(adminAction.getAdminList("root"));
	}, [dispatch]);

	const renderTH = () => {
		return adminConstants.TABLE_COLUMN.map((column) => {
			return (
				<TableTh key={`${column.key}_table_header`} style={{ width: column.width }}>
					<THButton
						key={`${column.key}_table_header`}
						type="button"
						onClick={() => requestSort(column.key, column.key === "lastLoginTime" ? false : true)}
					>
						<div style={{ textAlign: column.key === "status" ? "center" : "auto" }}>
							{column.value}
							{sortConfig && sortConfig.key === column.key ? (
								sortConfig.direction === "ascending" ? (
									<ArrowDropUp fontSize={"small"} style={{ verticalAlign: "middle" }} />
								) : (
									<ArrowDropDown fontSize={"small"} style={{ verticalAlign: "middle" }} />
								)
							) : null}
						</div>
					</THButton>
				</TableTh>
			);
		});
	};
	const renderTableHeader = renderTH();

	const renderTD = () => {
		if (items.length > 0) {
			return items.map((item, index) => {
				const tempObj = {};
				adminConstants.TABLE_COLUMN.forEach((column) => {
					tempObj[column.key] = item[column.key];
				});

				return (
					<TableRow
						key={`${item._id}_${index}_tr`}
						onClick={() => {
							HandleRowOnClick(item);
							dispatch({ type: adminConstants.type.UPDATE_SEARCH_KEY_AND_WORD, searchKey, searchWord, navigationKey: "root" });
						}}
					>
						{Object.keys(tempObj).map((columnName, index) => {
							if (columnName === "status") {
								return (
									<TableTd key={`${item._id}_${columnName}_${index}_td`} style={{ verticalAlign: "middle" }}>
										<Status status={tempObj[columnName]} />
									</TableTd>
								);
							} else if (columnName === "lastLoginTime" || columnName === "createdDate") {
								return (
									<TableTd key={`${item._id}_${columnName}_${index}_td`}>
										{tempObj[columnName] ? moment(tempObj[columnName]).format("YYYY年M月DD日 hh:mmA") : "-"}
									</TableTd>
								);
							}
							return <TableTd key={`${item._id}_${columnName}_${index}_td`}>{tempObj[columnName]}</TableTd>;
						})}
					</TableRow>
				);
			});
		}
	};
	const renderTableCell = renderTD();

	return (
		<>
			<PageContainer>
				<LeftContainer>
					<AdminMenubar section={generalConstants.NAV_TAB.ADMIN_MANAGEMENT.key} />
				</LeftContainer>
				<RightContainer>
					<AdminUserInfo />
					<AdminSectionbar
						title={generalConstants.NAV_TAB.ADMIN_MANAGEMENT.key}
						sublabel={`管理員數量: ${items.length}`}
						buttonLabel={"新增管理員"}
						handleOnClick={handleCreateAdmin}
						locationPathArray={[
							{ title: "主頁面", isAction: true, target: generalConstants.PATH.DASHBOARD },
							{
								title: generalConstants.TAB_NAME[generalConstants.NAV_TAB.ADMIN_MANAGEMENT.key],
								isAction: false,
								target: "",
							},
						]}
					/>
					<ContentContainer>
						<SearchBar>
							<SearchBarLeft>
								<RowInBlock marginBottom="0px">
									<Title style={{ fontSize: "13px", lineHeight: "27px" }}>管理員搜尋</Title>
									<div style={{ marginLeft: "10px", width: "20%" }}>
										<StandardSelect
											name={"searchKey"}
											options={_.filter(adminConstants.TABLE_COLUMN, { searchable: true })}
											value={searchKey}
											handleChange={handleChange}
										/>
									</div>
									<div style={{ marginLeft: "10px", width: "40%" }}>
										<StandardTextField
											placeholder="關鍵字"
											value={searchWord}
											name="searchWord"
											handleChange={handleChange}
											style={{ borderTopRightRadius: "0px", borderBottomRightRadius: "0px" }}
										/>
									</div>
									<RefreshButton style={{ marginLeft: "5px" }} handleOnClick={handleRefreshFilter} label="重置選項"></RefreshButton>
								</RowInBlock>
							</SearchBarLeft>
							<SearchBarRight>
								<RowInBlock marginBottom="0px" style={{ float: "right" }}>
									<span style={{ fontSize: "13px", lineHeight: "27px" }}>管理員數量: {displayList.length}</span>
								</RowInBlock>
							</SearchBarRight>
						</SearchBar>
						<TableContainer>
							<table style={{ width: "100%", borderCollapse: "collapse", tableLayout: "fixed" }}>
								<thead>
									<tr>{renderTableHeader}</tr>
								</thead>
								<tbody>{renderTableCell}</tbody>
							</table>
						</TableContainer>
					</ContentContainer>
					<AdminFooter></AdminFooter>
				</RightContainer>
			</PageContainer>
		</>
	);
};

const RowInBlock = styled.div`
	display: flex;
	margin-bottom: ${(props) => props.marginBottom || "10px"};
	flex-direction: row;
`;

const Title = styled.div`
	font-size: 1.3em;
	color: black;
	font-weight: bold;
	margin-right: 10px;
`;
