import { authConstants, socialNetworkConstants } from "_constants";

const initialState = {
	isFetching: false,
	reviewPostSuccess: false,
	deletePostSuccess: false,
	post: {},
	navigationObject: {
		root: {
			searchWord: "",
			sortingKey: "status",
			sortingDirection: "desc",
			numOfPage: 1,
			currentPage: 1,
			totalItems: 0,
			postList: [],
			reviewedFilter: null,
			notReviewFilter: null,
		},
	},
};

const socialNetworkReducer = (state = initialState, action) => {
	switch (action.type) {
		case authConstants.type.UNAUTHORIZED_ERROR: {
			return { ...state, currentPage: 1 };
		}
		case socialNetworkConstants.type.RESET_POST_SUCCESS: {
			return {
				...state,
				reviewPostSuccess: false,
				deletePostSuccess: false,
			};
		}
		case socialNetworkConstants.type.FETCH_POST_LIST_REQUEST: {
			return {
				...state,
				isFetching: true,
			};
		}
		case socialNetworkConstants.type.FETCH_POST_LIST_FAILURE: {
			return {
				...state,
				isFetching: false,
			};
		}
		case socialNetworkConstants.type.FETCH_POST_LIST_SUCCESS: {
			return {
				...state,
				isFetching: false,
				navigationObject: {
					root: {
						postList: action.postList,
						totalItems: action.totalNum,
						numOfPage: action.totalPage,
						currentPage: action.currentPage,
						searchWord: action.searchWord,
						sortingKey: action.sortingKey,
						sortingDirection: action.sortingDirection,
						reviewedFilter: action.reviewedFilter,
						notReviewFilter: action.notReviewFilter,
					},
				},
			};
		}
		case socialNetworkConstants.type.FETCH_POST_TARGET_REQUEST: {
			return {
				...state,
				isFetching: true,
				post: {},
			};
		}
		case socialNetworkConstants.type.FETCH_POST_TARGET_FAILURE: {
			return {
				...state,
				isFetching: false,
			};
		}
		case socialNetworkConstants.type.FETCH_POST_TARGET_SUCCESS: {
			return {
				...state,
				isFetching: false,
				post: action.post,
			};
		}
		case socialNetworkConstants.type.REVIEW_POST_REQUEST: {
			return {
				...state,
				isFetching: true,
				reviewPostSuccess: false,
			};
		}
		case socialNetworkConstants.type.REVIEW_POST_FAILURE: {
			return {
				...state,
				isFetching: false,
				reviewPostSuccess: false,
			};
		}
		case socialNetworkConstants.type.REVIEW_POST_SUCCESS: {
			return {
				...state,
				isFetching: false,
				reviewPostSuccess: true,
			};
		}
		case socialNetworkConstants.type.DELETE_POST_REQUEST: {
			return {
				...state,
				isFetching: true,
				deletePostSuccess: false,
			};
		}
		case socialNetworkConstants.type.DELETE_POST_FAILURE: {
			return {
				...state,
				isFetching: false,
				deletePostSuccess: false,
			};
		}
		case socialNetworkConstants.type.DELETE_POST_SUCCESS: {
			return {
				...state,
				isFetching: false,
				deletePostSuccess: true,
			};
		}
		default: {
			return state;
		}
	}
};

export default socialNetworkReducer;
