import axios from "axios";

export const missionService = {
	createMission,
	updateMission,
	getMissionList,
	getMissionByKey,
	deleteMission,
};

const httpOptions = {
	headers: {
		"Content-Type": "application/json",
	},
	withCredentials: true,
};

async function createMission(data) {
	return axios({
		url: `${process.env.REACT_APP_BACKEND_URL}/mission/`,
		...httpOptions,
		headers: {
			...httpOptions.headers,
			enctype: "multipart/form-data",
		},
		method: "POST",
		data: data,
	})
		.then(handleResponse)
		.catch(handleError);
}

async function updateMission(data) {
	return axios({
		url: `${process.env.REACT_APP_BACKEND_URL}/mission/`,
		...httpOptions,
		headers: {
			...httpOptions.headers,
			enctype: "multipart/form-data",
		},
		method: "PUT",
		data: data,
	})
		.then(handleResponse)
		.catch(handleError);
}

async function getMissionList(data) {
	const formData = data ? { ...data } : undefined;
	return axios({
		url: `${process.env.REACT_APP_BACKEND_URL}/mission/`,
		...httpOptions,
		method: "GET",
		params: formData,
	})
		.then(handleResponse)
		.catch(handleError);
}

async function getMissionByKey(key) {
	return axios({
		url: `${process.env.REACT_APP_BACKEND_URL}/mission/${key}`,
		...httpOptions,
		method: "GET",
	})
		.then(handleResponse)
		.catch(handleError);
}

async function deleteMission(data) {
	return axios({
		url: `${process.env.REACT_APP_BACKEND_URL}/mission`,
		...httpOptions,
		method: "DELETE",
		data,
	})
		.then(handleResponse)
		.catch(handleError);
}

function handleError(err) {
	const msg = (err.response && err.response.data.message) || err.message || err;
	throw { error: msg };
}

function handleResponse(res) {
	return res.data;
}
