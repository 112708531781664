import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";

import { generalConstants, socialNetworkConstants, statusConstants } from "_constants";
import { socialNetworkAction } from "actions";
import _ from "lodash";
import moment from "moment";
import isEqual from "react-fast-compare";

import { history } from "_util/_helper";

import { Pagination, RefreshButton, Status, SquareCheckbox } from "components/general";
import { StandardSelect, StandardTextField } from "components/general/standard";

import { ArrowDropDown, ArrowDropUp } from "@material-ui/icons";

import { AdminMenubar, AdminUserInfo, AdminSectionbar, AdminFooter } from "components/admin";
import {
	PageContainer,
	LeftContainer,
	RightContainer,
	ContentContainer,
	SearchBar,
	SearchBarLeft,
	SearchBarRight,
	PaginationContainer,
	TableContainer,
	TableRow,
	TableTh,
	THButton,
	TableTd,
} from "components/layout";

import ProgressiveRejectDebouncer from "_util/debouncers/ProgressiveRejectDebouncer";

const DEFAULT_PAGE = 1;
export const PostManagementPage = () => {
	const { navigationObject } = useSelector(({ socialNetwork }) => ({ navigationObject: socialNetwork.navigationObject?.root }), isEqual);
	const { postList } = useSelector(({ socialNetwork }) => ({ postList: socialNetwork.navigationObject?.root.postList }));
	const { currentPage } = useSelector(({ socialNetwork }) => ({ currentPage: socialNetwork.navigationObject?.root.currentPage }));
	const { totalItems } = useSelector(({ socialNetwork }) => ({ totalItems: socialNetwork.navigationObject?.root.totalItems }));
	const { numOfPage } = useSelector(({ socialNetwork }) => ({ numOfPage: socialNetwork.navigationObject?.root.numOfPage }));

	const [searchKey, setSearchKey] = useState(socialNetworkConstants.TABLE_COLUMN[1].key);
	const [searchWord, setSearchWord] = useState(navigationObject?.searchWord || "");
	const [sortingKey, setSortingKey] = useState(navigationObject?.sortingKey || "time");
	const [sortingDirection, setSortingDirection] = useState(navigationObject?.sortingDirection || "DESC");
	const reviewedFilter = navigationObject?.reviewedFilter;
	const notReviewFilter = navigationObject?.notReviewFilter;
	const [page, setPage] = useState(currentPage || DEFAULT_PAGE);

	const dispatch = useDispatch();
	const timeoutRef = useRef(null);
	const [textDebouncer] = useState(() => new ProgressiveRejectDebouncer(0.7 * 1000));
	const [debouncer] = useState(() => new ProgressiveRejectDebouncer(1 * 1000));

	const handleSorting = (sortKey) => {
		if (!debouncer.token()) {
			return;
		}
		let direction = "ASC";
		if (sortingKey === sortKey && sortingDirection === "ASC") {
			direction = "DESC";
		}
		setSortingKey(sortKey);
		setSortingDirection(direction);
		let request = {
			p: DEFAULT_PAGE,
			sort: `${sortKey}:${direction}`,
			searchKey,
			searchWord,
		};
		if (reviewedFilter) {
			request = { ...request, reviewed: reviewedFilter };
		}
		if (notReviewFilter) {
			request = { ...request, notReview: notReviewFilter };
		}
		dispatch(socialNetworkAction.getPosts(request));
	};

	const handlePageChange = (pageNum) => {
		if (!debouncer.token() || currentPage === pageNum) {
			return;
		}
		let request = {
			p: pageNum,
			sort: `${sortingKey}:${sortingDirection}`,
			searchKey,
			searchWord,
		};
		if (reviewedFilter) {
			request = { ...request, reviewed: reviewedFilter };
		}
		if (notReviewFilter) {
			request = { ...request, notReview: notReviewFilter };
		}
		dispatch(socialNetworkAction.getPosts(request));
		setPage(pageNum);
	};

	function resetTimeout() {
		if (timeoutRef.current) {
			clearTimeout(timeoutRef.current);
		}
	}

	const handleChange = ({ key, value }) => {
		var targetKey = searchKey;
		var targetWord = searchWord;
		if (key === "searchKey") {
			setSearchKey(value);
			targetKey = value;
		}
		if (key === "searchWord") {
			setSearchWord(value);
			targetWord = value;
		}
		if (key === "searchKey" && _.isEmpty(searchWord)) {
			return;
		}
		if (!textDebouncer.token()) {
			resetTimeout();
			timeoutRef.current = setTimeout(() => {
				handleSearch(targetKey, targetWord);
			}, 1000);
			return;
		}
		resetTimeout();
		handleSearch(targetKey, targetWord);
	};

	const handleSearch = (key, value) => {
		let request = {
			p: DEFAULT_PAGE,
			sort: `${sortingKey}:${sortingDirection}`,
			searchKey: key,
			searchWord: value,
		};
		if (reviewedFilter) {
			request = { ...request, reviewed: reviewedFilter };
		}
		if (notReviewFilter) {
			request = { ...request, notReview: notReviewFilter };
		}
		dispatch(socialNetworkAction.getPosts(request));
	};

	useEffect(() => {
		let request = {
			p: page,
			sort: `${sortingKey}:${sortingDirection}`,
			searchKey,
			searchWord,
		};
		if (reviewedFilter) {
			request = { ...request, reviewed: reviewedFilter };
		}
		if (notReviewFilter) {
			request = { ...request, notReview: notReviewFilter };
		}
		dispatch(socialNetworkAction.getPosts(request));
	}, [dispatch]);

	const handleOnChangeCheckbox = ({ key }) => {
		if (!debouncer.token()) {
			return;
		}
		var request = {
			p: DEFAULT_PAGE,
			sort: `${sortingKey}:${sortingDirection}`,
			searchKey,
			searchWord,
		};
		if (key === "reviewed") {
			if (!reviewedFilter) {
				request = { ...request, reviewed: true };
			}
			if (notReviewFilter) {
				request = { ...request, notReview: notReviewFilter };
			}
		} else if (key === "notReview") {
			if (!notReviewFilter) {
				request = { ...request, notReview: true };
			}
			if (reviewedFilter) {
				request = { ...request, reviewed: reviewedFilter };
			}
		}
		setPage(DEFAULT_PAGE);
		dispatch(socialNetworkAction.getPosts(request));
	};

	const handleRefreshFilter = () => {
		if (
			searchWord === "" &&
			sortingKey === "time" &&
			sortingDirection === "DESC" &&
			currentPage === DEFAULT_PAGE &&
			!notReviewFilter &&
			!reviewedFilter
		) {
			return;
		}
		setSearchKey(socialNetworkConstants.TABLE_COLUMN[1].key);
		setSearchWord("");
		setSortingKey("time");
		setSortingDirection("DESC");
		dispatch(
			socialNetworkAction.getPosts({
				p: DEFAULT_PAGE,
				sort: `time:DESC`,
				searchKey: socialNetworkConstants.TABLE_COLUMN[1].key,
				searchWord: "",
			})
		);
	};

	const renderTH = () => {
		return socialNetworkConstants.TABLE_COLUMN.map((column) => {
			return (
				<TableTh key={`${column.key}_table_header`} width={column.width}>
					<THButton key={`${column.key}_table_header`} type="button" onClick={() => handleSorting(column.key)}>
						<div style={{ textAlign: column.key === "status" ? "center" : "auto" }}>
							{column.value}
							{sortingKey === column.key ? (
								sortingDirection === "ASC" ? (
									<ArrowDropUp fontSize={"small"} style={{ verticalAlign: "middle" }} />
								) : (
									<ArrowDropDown fontSize={"small"} style={{ verticalAlign: "middle" }} />
								)
							) : null}
						</div>
					</THButton>
				</TableTh>
			);
		});
	};
	const renderTableHeader = renderTH();

	const getTitle = (title) => {
		const maxLength = 50;
		try {
			if (title.length === 0) return "[沒有內容]";
			let tail = title.length > maxLength ? "..." : "";
			return title.substr(0, maxLength) + tail;
		} catch (e) {
			return "[沒有內容]";
		}
	};

	const HandleRowOnClick = (item) => {
		history.push({
			pathname: generalConstants.PATH.POST_DETAIL,
			state: {
				postKey: item.key,
			},
		});
	};

	const getStatus = (item) => {
		let thekey = item.deleted ? "POST_DELETED" : item.reviewed ? "POST_REVIEWED" : "POST_NO_REVIEW";
		return statusConstants.status[thekey].key;
	};

	const renderTD = () => {
		if (postList?.length > 0) {
			return postList?.map((post, index) => {
				const tempObj = {};
				socialNetworkConstants.TABLE_COLUMN.forEach((column) => {
					tempObj[column.key] = post[column.key];
				});
				return (
					<TableRow
						key={`${post.key}_${index}_tr`}
						onClick={() => {
							HandleRowOnClick(post);
						}}
					>
						{Object.keys(tempObj).map((columnName, index) => {
							if (columnName === "status") {
								return (
									<TableTd key={`${post.key}_${columnName}_${index}_td`} style={{ verticalAlign: "middle" }}>
										<Status style={{ margin: "0 auto 0 0" }} status={getStatus(post)} />
									</TableTd>
								);
							} else if (columnName === "text") {
								return (
									<TableTd key={`${post.key}_${columnName}_${index}_td`}>
										<TitleText>{post.key ? getTitle(tempObj[columnName]) : "[帖子已經不存在]"}</TitleText>
									</TableTd>
								);
							} else if (columnName === "actor" || columnName === "target") {
								return (
									<TableTd key={`${post.key}_${columnName}_${index}_td`}>
										<TitleText>{tempObj[columnName].nickname}</TitleText>
									</TableTd>
								);
							} else if (columnName === "time") {
								return (
									<TableTd key={`${post.key}_${columnName}_${index}_td`}>
										{tempObj[columnName] ? moment(tempObj[columnName]).format("YYYY年MM月DD日 hh:mmA") : "-"}
									</TableTd>
								);
							}
							return <TableTd key={`${post.key}_${columnName}_${index}_td`}>{tempObj[columnName]}</TableTd>;
						})}
					</TableRow>
				);
			});
		}
	};
	const renderTableCell = renderTD();

	return (
		<>
			<PageContainer>
				<LeftContainer>
					<AdminMenubar section={generalConstants.NAV_TAB.POST_MANAGEMENT.key} />
				</LeftContainer>
				<RightContainer>
					<AdminUserInfo />
					<AdminSectionbar
						title={generalConstants.NAV_TAB.POST_MANAGEMENT.key}
						sublabel={`帖子數量: ${totalItems}`}
						locationPathArray={[
							{ title: "主頁面", isAction: true, target: generalConstants.PATH.DASHBOARD },
							{
								title: generalConstants.TAB_NAME[generalConstants.NAV_TAB.POST_MANAGEMENT.key],
								isAction: false,
								target: "",
							},
						]}
					/>
					<ContentContainer>
						<SearchBar>
							<SearchBarLeft style={{ flex: 2 }}>
								<RowInBlock marginBottom="0px">
									<Title style={{ fontSize: "13px", lineHeight: "27px" }}>帖子搜尋</Title>
									<div style={{ marginLeft: "10px", width: "20%" }}>
										<StandardSelect
											name={"searchKey"}
											options={_.filter(socialNetworkConstants.TABLE_COLUMN, { searchable: true })}
											value={searchKey}
											handleChange={handleChange}
										/>
									</div>
									<div style={{ marginLeft: "10px", width: "40%" }}>
										<StandardTextField
											placeholder="關鍵字"
											value={searchWord}
											name="searchWord"
											handleChange={handleChange}
											style={{ borderTopRightRadius: "0px", borderBottomRightRadius: "0px" }}
										/>
									</div>
									<RefreshButton style={{ marginLeft: "5px" }} handleOnClick={handleRefreshFilter} label="重置選項"></RefreshButton>
								</RowInBlock>
							</SearchBarLeft>
							<SearchBarRight style={{ flex: 3 }}>
								<RowInBlock marginBottom="0px">
									<Title style={{ fontSize: "13px", lineHeight: "27px" }}>帖子狀態</Title>
									<div style={{ color: "#33333", backgroundColor: "#ffd95c", paddingTop: 0, paddingBottom: 0, paddingLeft: 5 }}>
										<SquareCheckbox
											key={`not_review_checkbox`}
											name={"notReview"}
											label={socialNetworkConstants.postFilterLabel.NOTREVIEW}
											checked={!notReviewFilter}
											handleChange={handleOnChangeCheckbox}
										/>
									</div>
									<div style={{ color: "#33333", backgroundColor: "#5cff82", paddingTop: 0, paddingBottom: 0, paddingLeft: 5 }}>
										<SquareCheckbox
											key={`reviewed_checkbox`}
											name={"reviewed"}
											label={socialNetworkConstants.postFilterLabel.REVIEWED}
											checked={!reviewedFilter}
											handleChange={handleOnChangeCheckbox}
										/>
									</div>
								</RowInBlock>
							</SearchBarRight>
						</SearchBar>
						<TableContainer>
							<table style={{ width: "100%", borderCollapse: "collapse", tableLayout: "fixed" }}>
								<thead>
									<tr>{renderTableHeader}</tr>
								</thead>
								<tbody>{renderTableCell}</tbody>
							</table>
						</TableContainer>
						<PaginationContainer>
							<Pagination count={numOfPage} page={currentPage} handlePageChange={handlePageChange} />
						</PaginationContainer>
					</ContentContainer>
					<AdminFooter></AdminFooter>
				</RightContainer>
			</PageContainer>
		</>
	);
};

const TitleText = styled.span`
	color: #0085b5;
	text-decoration: underline;
	max-height: 70px;
	display: block;
	overflow: hidden;
	text-overflow: ellipsis;
`;

const RowInBlock = styled.div`
	display: flex;
	margin-bottom: ${(props) => props.marginBottom || "10px"};
	flex-direction: row;
`;

const Title = styled.div`
	font-size: 1.3em;
	color: black;
	font-weight: bold;
	margin-right: 10px;
`;
