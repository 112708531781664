import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { WarningButton } from "./WarningButton";

export const SimplePopupMessage = (props) => {
	const { message, handleOnClick } = props;

	return (
		<Container>
			<InnerContainer>
				<Message style={{ flex: 3 }}>
					<span>{message}</span>
				</Message>
				<Block>
					<WarningButton style={{ width: "30%" }} label={"返回"} handleOnClick={handleOnClick} />
				</Block>
			</InnerContainer>
		</Container>
	);
};

SimplePopupMessage.propTypes = {
	message: PropTypes.string,
	handleOnClick: PropTypes.func,
};

const Container = styled.div`
	height: 60%;
	width: 50%;
	background-color: white;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	z-index: 10;
`;

const InnerContainer = styled.div`
	width: 80%;
	height: 80%;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	display: flex;
	flex-direction: column;
	justify-content: center; /* align horizontal */
	align-items: center;
`;

const Message = styled.div`
	width: 100%;
	color: black;
	font-size: 26px;
	line-height: 1.5;
	font-weight: bold;
	display: flex;
	flex-direction: column;
	justify-content: center; /* align horizontal */
	align-items: center;
`;

const Block = styled.div`
	flex: 1;
	margin-top: 20px;
	width: 100%;
	display: flex;
	align-items: center;
	flex-direction: column;
`;
