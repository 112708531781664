export const missionConstants = {
	type: {
		RESET_MISSION_SUCCESS: "RESET_MISSION_SUCCESS",

		CREATE_MISSION_REQUEST: "CREATE_MISSION_REQUEST",
		CREATE_MISSION_SUCCESS: "CREATE_MISSION_SUCCESS",
		CREATE_MISSION_FAILURE: "CREATE_MISSION_FAILURE",

		UPDATE_MISSION_REQUEST: "UPDATE_MISSION_REQUEST",
		UPDATE_MISSION_SUCCESS: "UPDATE_MISSION_SUCCESS",
		UPDATE_MISSION_FAILURE: "UPDATE_MISSION_FAILURE",

		FETCH_MISSION_REQUEST: "FETCH_MISSION_REQUEST",
		FETCH_MISSION_SUCCESS: "FETCH_MISSION_SUCCESS",
		FETCH_MISSION_FAILURE: "FETCH_MISSION_FAILURE",

		FETCH_MISSION_LIST_REQUEST: "FETCH_MISSION_LIST_REQUEST",
		FETCH_MISSION_LIST_SUCCESS: "FETCH_MISSION_LIST_SUCCESS",
		FETCH_MISSION_LIST_FAILURE: "FETCH_MISSION_LIST_FAILURE",

		DELETE_MISSION_REQUEST: "DELETE_MISSION_REQUEST",
		DELETE_MISSION_FAILURE: "DELETE_MISSION_FAILURE",
		DELETE_MISSION_SUCCESS: "DELETE_MISSION_SUCCESS",
	},
	TABLE_COLUMN: [
		{ key: "status", value: "狀態", searchable: false, width: "5%" },
		{ key: "category", value: "類型", searchable: false, width: "5%" },
		{ key: "description", value: "內容", searchable: false, width: "20%" },
		{ key: "coin", value: "報酬 (KOT幣)", searchable: false, width: "10%" },
		{ key: "completedCount", value: "完成次數", searchable: false, width: "5%" },
		{ key: "lastUpdateTime", value: "最後更新時間", searchable: false, width: "10%" },
	],
	RANK_TABLE_COLUMN: [
		{ key: "rank", value: "排名", searchable: false },
		{ key: "description", value: "名稱", searchable: false },
		{ key: "completedCount", value: "完成次數", searchable: false },
	],
	PARENT_RANK_TABLE_COLUMN: [
		{ key: "rank", value: "排名", searchable: false },
		{ key: "nickname", value: "名稱", searchable: false },
		{ key: "completedMission", value: "完成次數", searchable: false },
	],
	recurrence: {
		ALL: { key: "ALL" },
		DAILY: { key: "DAILY", label: "每日重置" },
		NONE: { key: "NONE", label: "一次性" },
	},
	category: {
		POST: { key: "POST", label: "帖子", link: "activity/" },
		COURSE: { key: "COURSE", label: "課程", link: "course/" },
		LEARNINGCENTER: { key: "LEARNINGCENTER", label: "教育中心", link: "learningCenter/" },
		CHILD: { key: "CHILD", label: "子女帳戶" },
		PROFILE: { key: "PROFILE", label: "個人帳戶" },
		PUSH: { key: "PUSH", label: "通知" },
		SCHOOL: { key: "SCHOOL", label: "學校", link: "school/" },
		USER: { key: "USER", label: "用戶" },
		SOCIALNETWORK: { key: "SOCIALNETWORK", label: "童行網" },
		SOCIALEVENT: { key: "SOCIALEVENT", label: "活動", link: "socialEvent/" },
		MISSION: { key: "MISSION", label: "任務" },
	},
	action: {
		ADD: "ADD",
		BOOKMARK: "BOOKMARK",
		CHECKIN: "CHECKIN",
		COMMENT: "COMMENT",
		ENABLE: "ENABLE",
		FOLLOW: "FOLLOW",
		JOIN: "JOIN",
		LIKE: "LIKE",
		READ: "READ",
		SEARCH: "SEARCH",
		SHARE: "SHARE",
		SUBSCRIBE: "SUBSCRIBE",
		TRACK: "TRACK",
		UPDATE: "UPDATE",
	},
	status: {
		OPEN: { key: "OPEN", label: "正常" },
		SUSPEND: { key: "SUSPEND", label: "暫停" },
		CLOSE: { key: "CLOSE", label: "結束" },
	},
	OPTION: {
		foreignIdMission: ["POST", "COURSE", "LEARNINGCENTER", "SCHOOL", "SOCIALEVENT", "USER"],
		category: [
			{ key: "POST", value: "帖子" },
			{ key: "COURSE", value: "課程" },
			{ key: "LEARNINGCENTER", value: "教育中心" },
			{ key: "CHILD", value: "子女帳戶" },
			{ key: "PROFILE", value: "個人帳戶" },
			{ key: "PUSH", value: "通知" },
			{ key: "SCHOOL", value: "學校" },
			{ key: "USER", value: "用戶" },
			{ key: "SOCIALNETWORK", value: "童行網" },
			{ key: "SOCIALEVENT", value: "活動" },
			{ key: "MISSION", value: "任務" },
		],
		action: {
			POST: [
				{ key: "READ", value: "瀏覽" },
				{ key: "SEARCH", value: "搜尋" },
				{ key: "LIKE", value: "讚好" },
				{ key: "COMMENT", value: "留言" },
				{ key: "SHARE", value: "分享" },
				{ key: "ADD", value: "發佈" },
			],
			SOCIALEVENT: [
				{ key: "READ", value: "瀏覽" },
				{ key: "SEARCH", value: "搜尋" },
				{ key: "SHARE", value: "分享" },
				{ key: "TRACK", value: "添加到日曆" },
				{ key: "JOIN", value: "參加" },
			],
			COURSE: [
				{ key: "READ", value: "瀏覽" },
				{ key: "SEARCH", value: "搜尋" },
				{ key: "SHARE", value: "分享" },
				{ key: "BOOKMARK", value: "收藏" },
			],
			LEARNINGCENTER: [
				{ key: "READ", value: "瀏覽" },
				{ key: "SHARE", value: "分享" },
			],
			SCHOOL: [
				{ key: "READ", value: "瀏覽" },
				{ key: "SHARE", value: "分享" },
				{ key: "SUBSCRIBE", value: "訂閱" },
			],
			CHILD: [{ key: "UPDATE", value: "更新" }],
			PROFILE: [{ key: "UPDATE", value: "更新" }],
			USER: [{ key: "FOLLOW", value: "追蹤" }],
			PUSH: [{ key: "ENABLE", value: "允許" }],
			SOCIALNETWORK: [{ key: "JOIN", value: "加入" }],
			MISSION: [{ key: "CHECKIN", value: "簽到" }],
		},
		status: [
			{ key: "OPEN", value: "正常" },
			{ key: "SUSPEND", value: "暫停" },
			{ key: "CLOSE", value: "結束" },
		],
		recurrence: [
			{ key: "DAILY", value: "每日重置" },
			{ key: "NONE", value: "一次性" },
		],
	},
};
