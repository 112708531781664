import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { history } from "_util/_helper";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { ExcelIcon } from "./ExcelIcon";
import Grid from "@material-ui/core/Grid";
import { generalConstants, messageConstants } from "_constants";
import * as XLSX from "xlsx/xlsx.mjs";
import InputFiles from "react-input-files";
import xDate from "xdate";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

export const ExcelImportDialog = (props) => {
	const { handleOnClose, active, goImportMsg } = props;
	const [upload, setUpload] = useState(active);
	/*const HEADER = ["訊息類別 (1: 學校資訊 / 2: 一般訊息)",
		"資訊分類 (1: 學校 / 2: 教育局)",
		"學校編號",
		"學校",
		"設定訊息發送時間 (1: 即時 / 2: 預定)",
		"發送日期 (YYYYMMDD)",
		"發送時間 (HHMM)",
		"標題類別 (1: 重要日子 / 2: 入學申請 / 3: 活動 / 4: 通告)",
		"標題",
		"內容",
		"開始日期 (YYYYMMDD)",
		"開始時間 (HHMM)",
		"結束日期 (YYYYMMDD)",
		"結束時間 (HHMM)",
		"全日 (Y/N)",
		"連結"];*/
	const HEADER = [
		"TYPE",
		"INFOTYPE",
		"SCHOOLID",
		"SCHOOLNAME",
		"SCHEDULETYPE",
		"SCHEDULEDATE",
		"SCHEDULETIME",
		"CATEGORY",
		"TITLE",
		"DETAIL",
		"STARTDATE",
		"STARTTIME",
		"ENDDATE",
		"ENDTIME",
		"FULLDAY",
		"LINK",
	];
	const MSGFIELD = [
		"type",
		"infoType",
		"schoolId",
		"schoolName",
		"scheduleType",
		"xx5",
		"scheduleTime",
		"category",
		"title",
		"detail",
		"xx10",
		"startTime",
		"xx12",
		"endTime",
		"fullDay",
		"url",
	];
	const dispatch = useDispatch();

	const converData = (data) => {
		let result = [];
		if (!data || data.length < 2) {
			throw new Error("無法成功導入任何數據");
		}
		for (let y = 1; y < data.length; y++) {
			let resultData = {};
			resultData["num"] = y;
			resultData["type"] = "NEWS";
			resultData["infoType"] = "SCHOOL";
			for (let x = 1; x < HEADER.length; x++) {
				let startdatestr;
				let starthrstr;
				let enddatestr;
				let endhrstr;
				switch (x) {
					case 1: {
						if (data[y][HEADER[x]] === 1 || data[y][HEADER[x]] === "1" || data[y][HEADER[x]] === 2 || data[y][HEADER[x]] === "2") {
							//
						} else {
							resultData["msg"] = resultData["msg"]
								? resultData["msg"].concat(", 資訊分類 (1: 學校 / 2: 教育局)")
								: "資訊分類 (1: 學校 / 2: 教育局)";
						}
						break;
					}
					case 2: {
						if (data[y][HEADER[1]] === 1 || data[y][HEADER[1]] === "1") {
							resultData[MSGFIELD[x]] = `school/${data[y][HEADER[x]]}`;
						} else if (data[y][HEADER[1]] === 2 || data[y][HEADER[1]] === "2") {
							resultData[MSGFIELD[x]] = "school/00000000000";
						} else {
							resultData["msg"] = resultData["msg"] ? resultData["msg"].concat(", 學校編號") : "學校編號";
						}
						break;
					}
					case 3: {
						resultData[MSGFIELD[x]] = data[y][HEADER[x]];
						break;
					}
					case 4: {
						if (data[y][HEADER[4]] === 1 || data[y][HEADER[4]] === "1") {
							resultData[MSGFIELD[x]] = messageConstants.DATA.scheduleType.IMMEDIATELY;
						} else if (data[y][HEADER[4]] === 2 || data[y][HEADER[4]] === "2") {
							resultData[MSGFIELD[x]] = messageConstants.DATA.scheduleType.SCHEDULED;
						} else {
							resultData["msg"] = resultData["msg"]
								? resultData["msg"].concat(", 設定訊息發送時間 (1: 即時 / 2: 預定)")
								: "設定訊息發送時間 (1: 即時 / 2: 預定)";
						}
						break;
					}
					case 6: {
						if (data[y][HEADER[4]] === 1 || data[y][HEADER[4]] === "1") {
							resultData[MSGFIELD[x]] = new xDate().toISOString();
						} else if (data[y][HEADER[4]] === 2 || data[y][HEADER[4]] === "2") {
							try {
								let year;
								let month;
								let day;
								let hour;
								let minute;
								let tmp;
								if (data[y][HEADER[5]]) {
									tmp = `${data[y][HEADER[5]]}`;
									if (tmp.length === 8) {
										year = parseInt(tmp.substring(0, 4));
										month = parseInt(tmp.substring(4, 6));
										day = parseInt(tmp.substring(6, 8));
									} else {
										throw new Error("Not accepted date");
									}
								} else {
									throw new Error("Not accepted date");
								}
								if (data[y][HEADER[6]]) {
									tmp = `${data[y][HEADER[6]]}`;
									if (tmp.length === 4) {
										hour = parseInt(tmp.substring(0, 2));
										minute = parseInt(tmp.substring(2, 4));
									} else {
										throw new Error("Not accepted time");
									}
								} else {
									throw new Error("Not accepted time");
								}
								resultData[MSGFIELD[x]] = new xDate(year, month - 1, day, hour, minute, 0, 0).toISOString();
							} catch (error) {
								resultData["msg"] = resultData["msg"]
									? resultData["msg"].concat(", 發送日期 (YYYYMMDD) 發送時間 (HHMM)")
									: "發送日期 (YYYYMMDD) 發送時間 (HHMM)";
							}
						}
						break;
					}
					case 7: {
						if (data[y][HEADER[x]] === 1 || data[y][HEADER[x]] === "1") {
							resultData[MSGFIELD[x]] = "IMPORTANT";
						} else if (data[y][HEADER[x]] === 2 || data[y][HEADER[x]] === "2") {
							resultData[MSGFIELD[x]] = "REGISTRATION";
						} else if (data[y][HEADER[x]] === 3 || data[y][HEADER[x]] === "3") {
							resultData[MSGFIELD[x]] = "EVENT";
						} else if (data[y][HEADER[x]] === 4 || data[y][HEADER[x]] === "4") {
							resultData[MSGFIELD[x]] = "NOTICE";
						}
						break;
					}
					case 8: {
						resultData[MSGFIELD[x]] = data[y][HEADER[x]];
						if (!data[y][HEADER[x]]) {
							resultData["msg"] = resultData["msg"] ? resultData["msg"].concat(", 標題") : "標題";
						}
						break;
					}
					case 9: {
						resultData[MSGFIELD[x]] = data[y][HEADER[x]];
						break;
					}
					case 11: {
						startdatestr = `${data[y][HEADER[10]]}`.trim();
						if (typeof data[y][HEADER[10]] !== "undefined" && data[y][HEADER[14]] && data[y][HEADER[14]] === "Y") {
							if (startdatestr && startdatestr.trim().length > 0) {
								try {
									let year;
									let month;
									let day;
									let hour = 0;
									let minute = 0;
									if (startdatestr && startdatestr.length === 8) {
										year = parseInt(startdatestr.substring(0, 4));
										month = parseInt(startdatestr.substring(4, 6));
										day = parseInt(startdatestr.substring(6, 8));
									}
									resultData[MSGFIELD[x]] = new xDate(year, month - 1, day, hour, minute, 0, 0).toISOString();
								} catch (error) {
									resultData["msg"] = resultData["msg"]
										? resultData["msg"].concat(", 開始日期 (YYYYMMDD) 開始時間 (HHMM)")
										: "開始日期 (YYYYMMDD) 開始時間 (HHMM)";
								}
							}
							break;
						} else {
							starthrstr = `${data[y][HEADER[11]]}`.trim();
							if (startdatestr && typeof data[y][HEADER[11]] !== "undefined" && startdatestr.length > 0 && starthrstr.length > 0) {
								try {
									let year;
									let month;
									let day;
									let hour;
									let minute;
									if (startdatestr && startdatestr.length === 8) {
										year = parseInt(startdatestr.substring(0, 4));
										month = parseInt(startdatestr.substring(4, 6));
										day = parseInt(startdatestr.substring(6, 8));
									}
									if (starthrstr && starthrstr.length === 4) {
										hour = parseInt(starthrstr.substring(0, 2));
										minute = parseInt(starthrstr.substring(2, 4));
									}
									resultData[MSGFIELD[x]] = new xDate(year, month - 1, day, hour, minute, 0, 0).toISOString();
								} catch (error) {
									resultData["msg"] = resultData["msg"]
										? resultData["msg"].concat(", 開始日期 (YYYYMMDD) 開始時間 (HHMM)")
										: "開始日期 (YYYYMMDD) 開始時間 (HHMM)";
								}
							}
							break;
						}
					}
					case 13: {
						enddatestr = `${data[y][HEADER[12]]}`.trim();
						if (typeof data[y][HEADER[14]] !== "undefined" && data[y][HEADER[14]] === "Y") {
							if (enddatestr && enddatestr.length > 0) {
								try {
									let year;
									let month;
									let day;
									let hour = 23;
									let minute = 59;
									if (enddatestr && enddatestr.length === 8) {
										year = parseInt(enddatestr.substring(0, 4));
										month = parseInt(enddatestr.substring(4, 6));
										day = parseInt(enddatestr.substring(6, 8));
									}
									resultData[MSGFIELD[x]] = new xDate(year, month - 1, day, hour, minute, 59, 999).toISOString();
								} catch (error) {
									resultData["msg"] = resultData["msg"]
										? resultData["msg"].concat(", 結束日期 (YYYYMMDD) 結束時間 (HHMM)")
										: "結束日期 (YYYYMMDD) 結束時間 (HHMM)";
								}
							}
						} else {
							endhrstr = `${data[y][HEADER[13]]}`.trim();
							if (enddatestr && typeof data[y][HEADER[13]] !== "undefined" && enddatestr.length > 0 && endhrstr.length > 0) {
								try {
									let year;
									let month;
									let day;
									let hour;
									let minute;
									if (enddatestr && enddatestr.length === 8) {
										year = parseInt(enddatestr.substring(0, 4));
										month = parseInt(enddatestr.substring(4, 6));
										day = parseInt(enddatestr.substring(6, 8));
									}
									if (endhrstr && endhrstr.length === 4) {
										hour = parseInt(endhrstr.substring(0, 2));
										minute = parseInt(endhrstr.substring(2, 4));
									}
									resultData[MSGFIELD[x]] = new xDate(year, month - 1, day, hour, minute, 0, 0).toISOString();
								} catch (error) {
									resultData["msg"] = resultData["msg"]
										? resultData["msg"].concat(", 結束日期 (YYYYMMDD) 結束時間 (HHMM)")
										: "結束日期 (YYYYMMDD) 結束時間 (HHMM)";
								}
							}
						}
						let startstr = `${resultData[MSGFIELD[11]]}`.trim();
						let endstr = `${resultData[MSGFIELD[13]]}`.trim();
						if (typeof resultData[MSGFIELD[11]] !== "undefined" || typeof resultData[MSGFIELD[13]] !== "undefined") {
							if (!startstr || !endstr) {
								resultData["msg"] = resultData["msg"]
									? resultData["msg"].concat(", 開始日期時間和結束日期時間需要一起輸入")
									: "開始日期時間和結束日期時間需要一起輸入";
							}
							if (startstr && endstr) {
								let newStartTime = xDate(startstr, true);
								let newEndTime = xDate(endstr, true);
								if (!(newStartTime instanceof xDate && !isNaN(newStartTime))) {
									resultData["msg"] = resultData["msg"] ? resultData["msg"].concat(", 開始日期時間") : "開始日期時間";
									break;
								}

								if (!(newEndTime instanceof xDate && !isNaN(newEndTime))) {
									resultData["msg"] = resultData["msg"] ? resultData["msg"].concat(", 結束日期時間") : "結束日期時間";
									break;
								}
								const diffDays = newStartTime.diffDays(newEndTime);
								if (diffDays < 0) {
									resultData["msg"] = resultData["msg"]
										? resultData["msg"].concat(", 結束日期時間比較開始日期時間更早")
										: "結束日期時間比較開始日期時間更早";
									break;
								}
								resultData["eventType"] =
									`${data[y][HEADER[10]]}`.localeCompare(`${data[y][HEADER[12]]}`) === 0
										? messageConstants.DATA.eventType.SINGLE
										: messageConstants.DATA.eventType.PERIOD;
							}
						}
						break;
					}
					case 14: {
						if (resultData[MSGFIELD[11]]) {
							if (data[y][HEADER[x]] && data[y][HEADER[x]] === "Y") {
								resultData[MSGFIELD[x]] = true;
							} else if (data[y][HEADER[x]] && data[y][HEADER[x]] === "N") {
								resultData[MSGFIELD[x]] = false;
							} else {
								resultData["msg"] = resultData["msg"] ? resultData["msg"].concat(", 全日 (Y/N)") : "全日 (Y/N)";
							}
						}
						break;
					}
					case 15: {
						resultData[MSGFIELD[x]] = data[y][HEADER[x]];
						break;
					}
					default:
						break;
				}
			}
			result.push(resultData);
		}
		return result;
	};

	const handleUpload = (files) => {
		setUpload(true);

		const fileReader = new FileReader();
		for (let index = 0; index < files.length; index++) {
			fileReader.name = files[index].name;
			if (files[index].size >= process.env.REACT_APP_FILESIZE_LIMIT * 1024 * 1024) {
				toast.error(`Upload File ${files[index].name} exceed limitsize ${process.env.REACT_APP_FILESIZE_LIMIT}MB`);
				return;
			}
		}
		fileReader.onload = (event) => {
			try {
				const validExts = [".xlsx", ".xls"];
				const fileExt = event.target.name;

				if (fileExt == null) {
					throw new Error("文件為空");
				}

				const fileExtlastof = fileExt.substring(fileExt.lastIndexOf("."));
				if (validExts.indexOf(fileExtlastof) === -1) {
					throw new Error("檔案類型錯誤");
				}
				const { result } = event.target;
				const workbook = XLSX.read(result, { type: "binary" });
				let data = [];
				for (const sheet in workbook.Sheets) {
					if (Object.prototype.hasOwnProperty.call(workbook.Sheets, sheet)) {
						data = data.concat(converData(XLSX.utils.sheet_to_json(workbook.Sheets[sheet])));
						break;
					}
				}
				dispatch({ type: messageConstants.type.IMPORT_MSG_LIST, importMsgList: data });
			} catch (e) {
				toast.error(e.message);
				return;
			}
		};
		fileReader.readAsBinaryString(files[0]);
	};

	useEffect(() => {
		if (upload) {
			setUpload(false);
			handleOnClose();
			if (goImportMsg) {
				history.push(generalConstants.PATH.IMPORT_MSG);
			}
		}
	}, [upload]);

	return (
		<Dialog open={active} onClose={handleOnClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
			<DialogTitle id="customized-dialog-title" onClose={handleOnClose}>
				<IconButton style={{ position: "absolute", top: 0, right: 0 }} onClick={handleOnClose}>
					<CloseIcon />
				</IconButton>
			</DialogTitle>
			<DialogContent>
				<Grid container justify="center" style={{ flexGrow: 1 }}>
					<ExcelIcon fill="#177040" style={{ width: "50px", height: "50px" }} />
				</Grid>
			</DialogContent>
			<DialogContent>
				<DialogContentText style={{ fontSize: 22, fontWeight: "bold" }}>{"請選擇要導入的文件"}</DialogContentText>
			</DialogContent>
			<DialogContent>
				<Grid container justify="center" style={{ flexGrow: 1 }}>
					<DialogContentText style={{ fontSize: 16 }}>
						只支持xls或xlsx格式
						<br />
						上載檔案為上限{process.env.REACT_APP_FILESIZE_LIMIT}MB
					</DialogContentText>
				</Grid>
			</DialogContent>
			<Grid container justify="center" style={{ flexGrow: 1 }}>
				<InputFiles accept=".xlsx, .xls" onChange={handleUpload}>
					<Button
						variant="contained"
						style={{
							padding: "11px 34px",
							borderRadius: 40,
							textTransform: "none",
							backgroundColor: active ? "#177040" : "#eeeeee",
							borderColor: "#177040",
							color: "white",
						}}
					>
						{"上傳文件"}
					</Button>
				</InputFiles>
			</Grid>
			<DialogActions></DialogActions>
		</Dialog>
	);
};

ExcelImportDialog.propTypes = {
	handleOnClose: PropTypes.func,
	active: PropTypes.bool,
	goImportMsg: PropTypes.bool,
};

ExcelImportDialog.defaultProps = {
	active: true,
	goImportMsg: true,
};
