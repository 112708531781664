import React, { useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Router, Switch, Route, Redirect } from "react-router-dom";
import { toast } from "react-toastify";
import {
	DashboardPage,
	NewMsgPage,
	MsgManagementPage,
	LoginPage,
	VerifyPage,
	AdminManagementPage,
	CreateAdminPage,
	ChangePasswordPage,
	EditAdminPage,
	SchoolManagementPage,
	SchoolDetailScreen,
	EditSchoolPage,
	EditMsgPage,
	ImportMsgPage,
	ReadOnlyMsgPage,
	ReportManagementPage,
	ReportTargetPage,
	ParentManagementPage,
	EditParentPage,
	LearningCentreManagementPage,
	OrganizationManagementPage,
	LearningCentreDetailScreen,
	CourseManagementPage,
	CourseDetailScreen,
	EditCentrePage,
	EditCoursePage,
	DataScrapPage,
	DataScrapDetailScreen,
	MsgConfirmPage,
	OrganizationDetailScreen,
	EditOrganizationPage,
	MissionManagementPage,
	MissionDetailScreen,
	EditMissionPage,
	MissionHistoryPage,
	PostManagementPage,
	PostDetailPage,
} from "./Page";

import { PrivateRoute } from "components/general";
import { generalConstants } from "_constants";

import { authAction } from "actions";
import axios from "axios";
import { history } from "_util/_helper";
import _ from "lodash";

function App() {
	const { user, loggedIn } = useSelector(({ auth }) => ({
		user: auth.user,
		loggedIn: auth.loggedIn,
	}));

	const dispatch = useDispatch();

	const onTokenExpired = useCallback(() => {
		dispatch(authAction.kickout());
	}, [dispatch]);

	const throttleOnTokenExpired = useCallback(_.throttle(onTokenExpired, 1000, { trailing: false }), []);

	useEffect(() => {
		axios.interceptors.response.use(
			(response) => {
				return response;
			},
			(error) => {
				if (error.response?.status === 401) {
					throttleOnTokenExpired();
				}
				return Promise.reject(error);
			}
		);
	}, [onTokenExpired, user]);

	useEffect(() => {
		return history.listen((location, action) => {
			if (action === "POP" && !loggedIn && location.pathname !== generalConstants.PATH.LOGIN) {
				history.push(generalConstants.PATH.LOGIN);
				toast.error("連接逾時，請重新登入");
			}
		});
	}, [loggedIn]);

	return (
		<div className="App">
			<Router history={history} forceRefresh={true}>
				<Switch>
					<Route exact path="/" render={() => (!!loggedIn && user ? <Redirect to={generalConstants.PATH.DASHBOARD} /> : <LoginPage />)} />
					<Route path="/verify/:token">
						<VerifyPage />
					</Route>
					<PrivateRoute path={generalConstants.PATH.CHANGE_PASSWORD} component={ChangePasswordPage} />
					<PrivateRoute path={generalConstants.PATH.DASHBOARD} component={DashboardPage} />

					<PrivateRoute path={generalConstants.PATH.MSG_MANAGEMENT} component={MsgManagementPage} />
					<PrivateRoute path={generalConstants.PATH.NEW_MSG} component={NewMsgPage} />
					<PrivateRoute path={generalConstants.PATH.EDIT_MSG} component={EditMsgPage} />
					<PrivateRoute path={generalConstants.PATH.READ_MSG} component={ReadOnlyMsgPage} />
					<PrivateRoute path={generalConstants.PATH.IMPORT_MSG} component={ImportMsgPage} />
					<PrivateRoute path={generalConstants.PATH.MSG_CONFIRM} component={MsgConfirmPage} />

					<PrivateRoute path={generalConstants.PATH.ADMIN_MANAGEMENT} component={AdminManagementPage} />
					<PrivateRoute path={generalConstants.PATH.CREATE_ADMIN} component={CreateAdminPage} />
					<PrivateRoute path={generalConstants.PATH.ADMIN_INFO} component={EditAdminPage} />

					<PrivateRoute path={generalConstants.PATH.SCHOOL_MANAGEMENT} component={SchoolManagementPage} />
					<PrivateRoute path={generalConstants.PATH.CREATE_SCHOOL} component={EditSchoolPage} />
					<PrivateRoute path={generalConstants.PATH.EDIT_SCHOOL} component={EditSchoolPage} />
					<PrivateRoute exact path={generalConstants.PATH.SCHOOL_DETAIL} component={SchoolDetailScreen} />
					<PrivateRoute path={`${generalConstants.PATH.SCHOOL_DETAIL}/message/add`} component={NewMsgPage} />
					<PrivateRoute path={`${generalConstants.PATH.SCHOOL_DETAIL}/message/edit`} component={EditMsgPage} />
					<PrivateRoute path={`${generalConstants.PATH.SCHOOL_DETAIL}/message/confirm`} component={MsgConfirmPage} />

					<PrivateRoute path={generalConstants.PATH.REPORT_MANAGEMENT} component={ReportManagementPage} />
					<PrivateRoute path={generalConstants.PATH.REPORT_TARGET} component={ReportTargetPage} />

					<PrivateRoute path={generalConstants.PATH.POST_MANAGEMENT} component={PostManagementPage} />
					<PrivateRoute path={generalConstants.PATH.POST_DETAIL} component={PostDetailPage} />

					<PrivateRoute path={generalConstants.PATH.PARENT_MANAGEMENT} component={ParentManagementPage} />
					<PrivateRoute exact path={generalConstants.PATH.MISSION_HISTORY} component={MissionHistoryPage} />
					<PrivateRoute path={generalConstants.PATH.EDIT_PARENT} component={EditParentPage} />

					<PrivateRoute path={generalConstants.PATH.ORGANIZATION_MANAGEMENT} component={OrganizationManagementPage} />
					<PrivateRoute exact path={generalConstants.PATH.ORGANIZATION} component={OrganizationDetailScreen} />
					<PrivateRoute path={`${generalConstants.PATH.ORGANIZATION}/edit`} component={EditOrganizationPage} />
					<PrivateRoute path={`${generalConstants.PATH.ORGANIZATION}/newschool`} component={EditSchoolPage} />
					<PrivateRoute exact path={`${generalConstants.PATH.ORGANIZATION}/school`} component={SchoolDetailScreen} />
					<PrivateRoute path={`${generalConstants.PATH.ORGANIZATION}/school/edit`} component={EditSchoolPage} />
					<PrivateRoute path={`${generalConstants.PATH.ORGANIZATION}/school/message/add`} component={NewMsgPage} />
					<PrivateRoute path={`${generalConstants.PATH.ORGANIZATION}/school/message/edit`} component={EditMsgPage} />
					<PrivateRoute path={`${generalConstants.PATH.ORGANIZATION}/school/message/confirm`} component={MsgConfirmPage} />
					<PrivateRoute exact path={`${generalConstants.PATH.ORGANIZATION}/learningCenter`} component={LearningCentreDetailScreen} />
					<PrivateRoute path={`${generalConstants.PATH.ORGANIZATION}/learningCenter/add`} component={EditCentrePage} />
					<PrivateRoute path={`${generalConstants.PATH.ORGANIZATION}/learningCenter/edit`} component={EditCentrePage} />
					<PrivateRoute exact path={`${generalConstants.PATH.ORGANIZATION}/learningCenter/course`} component={CourseDetailScreen} />
					<PrivateRoute path={`${generalConstants.PATH.ORGANIZATION}/learningCenter/course/add`} component={EditCoursePage} />
					<PrivateRoute path={`${generalConstants.PATH.ORGANIZATION}/learningCenter/course/edit`} component={EditCoursePage} />

					<PrivateRoute path={generalConstants.PATH.LEARNING_CENTRE_MANAGEMENT} component={LearningCentreManagementPage} />
					<PrivateRoute exact path={generalConstants.PATH.LEARNING_CENTER} component={LearningCentreDetailScreen} />
					<PrivateRoute path={`${generalConstants.PATH.LEARNING_CENTER}/add`} component={EditCentrePage} />
					<PrivateRoute path={`${generalConstants.PATH.LEARNING_CENTER}/edit`} component={EditCentrePage} />
					<PrivateRoute exact path={`${generalConstants.PATH.LEARNING_CENTER}/course`} component={CourseDetailScreen} />
					<PrivateRoute path={`${generalConstants.PATH.LEARNING_CENTER}/course/add`} component={EditCoursePage} />
					<PrivateRoute path={`${generalConstants.PATH.LEARNING_CENTER}/course/edit`} component={EditCoursePage} />

					<PrivateRoute path={generalConstants.PATH.LEARNING_COURSE_MANAGEMENT} component={CourseManagementPage} />
					<PrivateRoute exact path={generalConstants.PATH.LEARNING_COURSE} component={CourseDetailScreen} />
					<PrivateRoute path={`${generalConstants.PATH.LEARNING_COURSE}/add`} component={EditCoursePage} />
					<PrivateRoute path={`${generalConstants.PATH.LEARNING_COURSE}/edit`} component={EditCoursePage} />

					<PrivateRoute path={generalConstants.PATH.DATA_SCRAP} component={DataScrapPage} />
					<PrivateRoute exact path={generalConstants.PATH.DATA_SCRAP_DETAIL} component={DataScrapDetailScreen} />
					<PrivateRoute path={`${generalConstants.PATH.DATA_SCRAP_DETAIL}/message/add`} component={NewMsgPage} />
					<PrivateRoute path={`${generalConstants.PATH.DATA_SCRAP_DETAIL}/message/confirm`} component={MsgConfirmPage} />

					<PrivateRoute path={generalConstants.PATH.MISSION_MANAGEMENT} component={MissionManagementPage} />
					<PrivateRoute exact path={generalConstants.PATH.MISSION} component={MissionDetailScreen} />
					<PrivateRoute path={`${generalConstants.PATH.MISSION}/add`} component={EditMissionPage} />
					<PrivateRoute path={`${generalConstants.PATH.MISSION}/edit`} component={EditMissionPage} />
					<Route path="*">
						<h1>404</h1>
					</Route>
				</Switch>
			</Router>
		</div>
	);
}

export default App;
