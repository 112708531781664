import React, { useState, useEffect, useRef } from "react";
import { Prompt } from "react-router-dom";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { templateAction } from "actions";

import { templateConstants, messageConstants } from "_constants";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";
import { StandardTextField } from "components/general/standard";
import { Select, TextField, Pagination, SubmitButton, AlertDialog, RefreshButton } from "components/general";
import { TableRow, TableTh, THButton, TableTd } from "components/layout";

import ProgressiveRejectDebouncer from "_util/debouncers/ProgressiveRejectDebouncer";

import { toast } from "react-toastify";
import { ArrowDropDown, ArrowDropUp } from "@material-ui/icons";
import ArrowLeftIcon from "@material-ui/icons/ArrowLeft";
import BinIcon from "resources/img/bin.svg";
import { ReactComponent as OrganizationIcon } from "resources/img/organization.svg";

const DEFAULT_PAGE = 1;
const DEFAULT_LIMIT = 5;
export const TemplateImportDialog = (props) => {
	const { handleOnClose, handleOnImport, active, variant } = props;

	const { isFetching, templateList, currentPage, totalItems, numOfPage, template, updateTemplateSuccess, deleteTemplateSuccess, loggedIn } =
		useSelector(({ template, auth }) => ({
			isFetching: template.isFetching,
			templateList: template.templateList,
			currentPage: template.currentPage,
			totalItems: template.totalItems,
			numOfPage: template.numOfPage,
			template: template.template,
			updateTemplateSuccess: template.updateTemplateSuccess,
			deleteTemplateSuccess: template.deleteTemplateSuccess,
			loggedIn: auth.loggedIn,
		}));

	const useStyles = makeStyles({
		scrollPaper: {
			alignItems: "baseline", // default center
		},
	});
	const classes = useStyles();

	const [isDetailMode, setIsDetailMode] = useState(false);
	const [isChanged, setIsChanged] = useState(false);
	const [isDeleteAlertShow, setIsDeleteAlertShow] = useState(false);
	const [isSaveAlertShow, setIsSaveAlertShow] = useState(false);

	const [deleteTemplate, setDeleteTemplate] = useState(null);
	const [searchWord, setSearchWord] = useState("");
	const [sortingKey, setSortingKey] = useState("name");
	const [sortingDirection, setSortingDirection] = useState("ASC");

	const dispatch = useDispatch();
	const timeoutRef = useRef(null);
	const [textDebouncer] = useState(() => new ProgressiveRejectDebouncer(0.7 * 1000));
	const [debouncer] = useState(() => new ProgressiveRejectDebouncer(1 * 1000));

	const initForm = {
		type: messageConstants.OPTION.type[0].key, //its template type, e.g. adminmsg, etc, not msg type
		category: "",
		title: "",
		detail: "",
	};

	const [key, setKey] = useState(null);
	const [formData, setFormData] = useState(initForm);

	const handleChange = ({ key, value }) => {
		setIsChanged(true);
		setFormData({ ...formData, [key]: value });
	};

	const handleClose = () => {
		if (isChanged) {
			if (!window.confirm("你有未儲存的變更，確定不儲存下離開？")) return;
		}
		handleOnClose();
	};

	const handlePageChange = (pageNum) => {
		if (!debouncer.token() || currentPage === pageNum) {
			return;
		}
		dispatch(
			templateAction.fetchTemplate({
				search: searchWord,
				page: pageNum,
				limit: DEFAULT_LIMIT,
				sort: `${sortingKey}:${sortingDirection}`,
			})
		);
	};

	function resetTimeout() {
		if (timeoutRef.current) {
			clearTimeout(timeoutRef.current);
		}
	}

	const handleSearchChange = ({ key, value }) => {
		if (key === "searchWord") {
			setSearchWord(value);
		}
		if (!textDebouncer.token()) {
			resetTimeout();
			timeoutRef.current = setTimeout(() => {
				dispatch(
					templateAction.fetchTemplate({
						search: key === "searchWord" ? value : searchWord,
						page: DEFAULT_PAGE,
						limit: DEFAULT_LIMIT,
						sort: `${sortingKey}:${sortingDirection}`,
					})
				);
			}, 1000);
			return;
		}
		resetTimeout();
		dispatch(
			templateAction.fetchTemplate({
				search: key === "searchWord" ? value : searchWord,
				page: DEFAULT_PAGE,
				limit: DEFAULT_LIMIT,
				sort: `${sortingKey}:${sortingDirection}`,
			})
		);
	};

	const handleSorting = (key) => {
		if (!debouncer.token()) {
			return;
		}
		let direction = "ASC";
		if (sortingKey === key && sortingDirection === "ASC") {
			direction = "DESC";
		}
		setSortingKey(key);
		setSortingDirection(direction);
		dispatch(
			templateAction.fetchTemplate({
				search: searchWord,
				page: DEFAULT_PAGE,
				limit: DEFAULT_LIMIT,
				sort: `${key}:${direction}`,
			})
		);
	};

	useEffect(() => {
		if (active) {
			setIsDetailMode(false);
			dispatch(
				templateAction.fetchTemplate({
					search: searchWord,
					page: currentPage,
					limit: DEFAULT_LIMIT,
					sort: `${sortingKey}:${sortingDirection}`,
				})
			);
		}
	}, [active]);

	useEffect(() => {
		if (isDetailMode && key) dispatch(templateAction.getTemplateByKey(key));
	}, [isDetailMode]);

	const handleOnBack = () => {
		if (isChanged) {
			if (!window.confirm("你有未儲存的變更，確定不儲存下離開？")) return;
		}
		setIsDetailMode(false);
	};

	const handleCloseDeleteAlert = () => {
		setIsDeleteAlertShow(false);
	};

	const handleCloseSaveAlert = () => {
		setIsSaveAlertShow(false);
	};

	useEffect(() => {
		if (!_.isEmpty(template)) {
			setFormData({
				...formData,
				type: template.type,
				title: template.title,
				category: template.category,
				detail: template.detail,
			});
			setIsChanged(false);
		}
	}, [template]);

	useEffect(() => {
		if (updateTemplateSuccess) {
			dispatch({ type: templateConstants.type.RESET_TEMPLATE_SUCCESS });
			toast.success("成功更新模板");
			setIsChanged(false);
			dispatch(templateAction.getTemplateByKey(key));
		}
	}, [updateTemplateSuccess, dispatch]);

	useEffect(() => {
		if (deleteTemplateSuccess) {
			dispatch({ type: templateConstants.type.RESET_TEMPLATE_SUCCESS });
			toast.success("模板已移除");
			setIsDeleteAlertShow(false);
			dispatch(
				templateAction.fetchTemplate({
					search: searchWord,
					page: currentPage,
					limit: DEFAULT_LIMIT,
					sort: `${sortingKey}:${sortingDirection}`,
				})
			);
		}
	}, [deleteTemplateSuccess]);

	const handleDeleteClick = (item) => {
		setDeleteTemplate(item);
		setIsDeleteAlertShow(true);
	};

	const handleDelete = () => {
		if (!deleteTemplate) return;

		const data = {
			key: deleteTemplate._key,
			rev: deleteTemplate._rev,
		};

		dispatch(templateAction.deleteTemplate(data));
	};

	const handleImport = () => {
		if (isChanged) {
			setIsSaveAlertShow(true);
		} else {
			doImport();
		}
	};

	const doImport = () => {
		setIsSaveAlertShow(false);
		const data = {
			type: formData.type,
			category: formData.category,
			title: formData.title,
			detail: formData.detail,
		};
		handleOnImport(data);
	};

	const handleSubmit = () => {
		if (!template._key) {
			toast.error("沒有key");
			return;
		}

		if (!template._rev) {
			toast.error("沒有rev");
			return;
		}

		if (!formData.type) {
			toast.error("請填寫訊息類別");
			return;
		}

		if (!formData.title) {
			toast.error("請填寫標題");
			return;
		}

		const data = {
			key: template._key,
			rev: template._rev,
			type: formData.type,
			category: formData.category,
			title: formData.title,
			detail: formData.detail,
		};

		dispatch(templateAction.updateTemplate(data));
	};

	const HandleRowOnClick = (item) => {
		setKey(item._key);
		setIsDetailMode(true);
	};

	const handleRefreshFilter = async () => {
		if (searchWord === "" && currentPage === DEFAULT_PAGE && sortingKey === "name" && sortingDirection === "ASC") {
			return;
		}
		setSearchWord("");
		setSortingKey("name");
		setSortingDirection("ASC");
		dispatch(
			templateAction.fetchTemplate({
				search: "",
				page: DEFAULT_PAGE,
				limit: DEFAULT_LIMIT,
				sort: `name:ASC`,
			})
		);
	};

	const renderTH = () => {
		return templateConstants.TABLE_COLUMN.map((column) => {
			if (column.key === "status") {
				return null;
			}
			return (
				<TableTh key={`${column.key}_table_header`} colSpan={2} style={{ padding: "9px 0", borderBottom: "0" }}>
					<THButton
						key={`${column.key}_table_header`}
						type="button"
						onClick={() => handleSorting(column.key)}
						style={{ padding: "9px 0", fontSize: "14px" }}
					>
						{column.value}
						{sortingKey === column.key ? (
							sortingDirection === "ASC" ? (
								<ArrowDropUp fontSize={"small"} style={{ verticalAlign: "middle" }} />
							) : (
								<ArrowDropDown fontSize={"small"} style={{ verticalAlign: "middle" }} />
							)
						) : null}
					</THButton>
				</TableTh>
			);
		});
	};
	const renderTableHeader = renderTH();

	const renderTD = () => {
		if (templateList?.length > 0) {
			return templateList?.map((msg, index) => {
				const tempObj = {};
				templateConstants.TABLE_COLUMN.forEach((column) => {
					tempObj[column.key] = msg[column.key];
				});

				return (
					<TableRow key={`${msg._id}_${index}_tr`}>
						{Object.keys(tempObj).map((columnName, index) => {
							if (columnName === "title") {
								return (
									<TableTd key={`${msg._id}_${columnName}_${index}_td`} style={{ padding: "9px 0" }}>
										<TitleText>{tempObj[columnName]}</TitleText>
									</TableTd>
								);
							}
							if (columnName === "name") {
								return (
									<TableTd
										key={`${msg._id}_${columnName}_${index}_td`}
										style={{ display: "flex", alignItems: "center" }}
										onClick={() => {
											HandleRowOnClick(msg);
										}}
									>
										<TitleText style={{ padding: "9px 0" }}>{tempObj[columnName]}</TitleText>
										{(msg?.lastUpdateBy?.startsWith("organization/") || msg?.createdBy?.startsWith("organization/")) && (
											<OrganizationIcon style={{ marginLeft: 5, marginRight: 5, width: 15, height: 19 }} fill={"#333399"} />
										)}
									</TableTd>
								);
							}
							if (columnName === "status") {
								return (
									<TableTd key={`${msg._id}_${columnName}_${index}_td`} style={{ padding: "9px 0" }}>
										{variant === templateConstants.DATA.variant.NORMAL && (
											<IconButton
												aria-label="刪除"
												onClick={() => {
													handleDeleteClick(msg);
												}}
												style={{ float: "right", padding: "5px" }}
											>
												<img alt="" src={BinIcon} />
											</IconButton>
										)}
									</TableTd>
								);
							}
							return (
								<TableTd key={`${msg._id}_${columnName}_${index}_td`} style={{ padding: "9px 0" }}>
									{tempObj[columnName]}
								</TableTd>
							);
						})}
					</TableRow>
				);
			});
		}
	};
	const renderTableCell = renderTD();

	return (
		<>
			<Prompt when={isChanged && loggedIn} message="你有未儲存的變更，確定不儲存下離開？" />
			<AlertDialog active={isDeleteAlertShow} handleOnClose={handleCloseDeleteAlert} handleOnYes={handleDelete} title={"確定移除模板？"} />
			<AlertDialog
				active={isSaveAlertShow}
				handleOnClose={handleCloseSaveAlert}
				handleOnYes={doImport}
				title={"模板有未儲存變更，要繼續？（你也可先「否」取消，更新模板後再次導入。）"}
			/>

			<Dialog
				classes={{ scrollPaper: classes.scrollPaper }}
				fullWidth={true}
				maxWidth="sm"
				open={active}
				onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-DESCription"
			>
				<DialogTitle id="customized-dialog-title" onClose={handleClose}>
					導入訊息模板
					<SubTitle style={{ position: "absolute", top: 15, left: 110 }}>( {`模板數量: ${totalItems}`} )</SubTitle>
					<IconButton style={{ position: "absolute", top: 5, right: 0 }} onClick={handleClose}>
						<CloseIcon />
					</IconButton>
				</DialogTitle>

				{!isDetailMode && (
					<DialogContent>
						<Subtitle>搜尋模板</Subtitle>
						<RowInBlock>
							<div style={{ width: "85%" }}>
								<StandardTextField
									placeholder="關鍵字"
									value={searchWord}
									name="searchWord"
									handleChange={handleSearchChange}
									style={{
										borderTopRightRadius: "0px",
										borderBottomRightRadius: "0px",
									}}
								/>
							</div>
							<RefreshButton style={{ marginLeft: "5px" }} handleOnClick={handleRefreshFilter} label="重置選項"></RefreshButton>
						</RowInBlock>
						<OverflowScroll>
							<table style={{ width: "100%", borderCollapse: "collapse" }}>
								<thead>
									<tr>{renderTableHeader}</tr>
								</thead>
								<tbody>{renderTableCell}</tbody>
							</table>
						</OverflowScroll>
						<PaginationContainer>
							<Pagination count={numOfPage} page={currentPage} handlePageChange={handlePageChange} />
						</PaginationContainer>
					</DialogContent>
				)}
				{isDetailMode && (
					<DialogContent>
						<Block>
							{variant === templateConstants.DATA.variant.NORMAL && (
								<>
									<Subtitle>標題</Subtitle>
									<RowInBlock>
										<div style={{ flex: 2, marginRight: "10px" }}>
											<Select
												displayEmpty
												name={"category"}
												options={messageConstants.OPTION.category}
												value={formData.category}
												handleChange={handleChange}
											/>
										</div>
										<div style={{ flex: 7 }}>
											<TextField placeholder="標題" value={formData.title} name="title" handleChange={handleChange} />
										</div>
									</RowInBlock>
								</>
							)}
							<Subtitle>內容</Subtitle>
							<TextField placeholder="內容" multiline rows={6} value={formData.detail} name="detail" handleChange={handleChange} />
						</Block>
					</DialogContent>
				)}
				<DialogActions>
					{isDetailMode && (
						<Button onClick={handleOnBack}>
							<ArrowLeftIcon />
							返回模板列表
						</Button>
					)}
					<div style={{ flex: "1 0 0" }} />
					{isDetailMode && <SubmitButton label={"導入"} handleOnClick={handleImport} />}
					{isDetailMode && variant === templateConstants.DATA.variant.NORMAL && (
						<SubmitButton active={!isFetching} label={"更新模板"} handleOnClick={handleSubmit} />
					)}
				</DialogActions>
			</Dialog>
		</>
	);
};

const Block = styled.div`
	margin-top: ${(props) => props.marginTop || "10px"};
	flex-direction: column;
`;

const RowInBlock = styled.div`
	display: flex;
	margin-bottom: ${(props) => props.marginBottom || "10px"};
	flex-direction: row;
`;

const Subtitle = styled.div`
	color: black;
	margin-bottom: ${(props) => props.marginBottom || "10px"};

	padding: 5px 0;
	font-size: 14px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.43;
	letter-spacing: normal;
	text-align: left;
	color: #000000;
`;

const TitleText = styled.span`
	color: #0085b5;
	text-decoration: underline;
`;

const PaginationContainer = styled.div`
	float: right;
	padding-top: 20px;
	padding-bottom: 20px;
	height: 100%;
`;

const OverflowScroll = styled.div`
	max-height: 300px;
	overflow-y: scroll;
`;

const SubTitle = styled.div`
	margin: 10px 10px 6px 10px;
	font-size: 11px;
	font-weight: 500;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.55;
	letter-spacing: normal;
	text-align: left;
	color: #666666;
`;

TemplateImportDialog.propTypes = {
	handleOnClose: PropTypes.func,
	handleOnImport: PropTypes.func,
	active: PropTypes.bool,
	goImportMsg: PropTypes.bool,
	variant: PropTypes.string,
};

TemplateImportDialog.defaultProps = {
	active: true,
	goImportMsg: true,
	variant: templateConstants.DATA.variant.NORMAL,
};
