import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";

import { organizationConstants, generalConstants, statusConstants } from "_constants";
import { organizationAction } from "actions";
import moment from "moment";

import { history } from "_util/_helper";
import { ArrowDropDown, ArrowDropUp } from "@material-ui/icons";
import { ReactComponent as RemarkIcon } from "resources/img/remark.svg";

import { OrganizationStatus, Pagination, RefreshButton, SquareCheckbox, Status } from "components/general";

import ProgressiveRejectDebouncer from "_util/debouncers/ProgressiveRejectDebouncer";

import { StandardTextField } from "components/general/standard";
import { AdminMenubar, AdminUserInfo, AdminSectionbar, AdminFooter } from "components/admin";
import {
	PageContainer,
	LeftContainer,
	RightContainer,
	ContentContainer,
	SearchBar,
	SearchBarLeft,
	PaginationContainer,
	TableContainer,
	TableRow,
	TableTh,
	THButton,
	TableTd,
} from "components/layout";
import isEqual from "react-fast-compare";

const DEFAULT_LIMIT = 10;
const DEFAULT_PAGE = 1;
export const OrganizationManagementPage = () => {
	const { organizations } = useSelector(
		({ organization }) => ({
			organizations: organization.navigationObject?.root?.organizationList,
		}),
		isEqual
	);

	const totalItem = useSelector(({ organization }) => organization.navigationObject?.root?.totalItem);
	const currentPage = useSelector(({ organization }) => organization.navigationObject?.root?.currentPage);
	const numOfPage = useSelector(({ organization }) => organization.navigationObject?.root?.numOfPage);
	const { navigationObject } = useSelector(
		({ organization }) => ({
			navigationObject: organization.navigationObject?.root,
		}),
		isEqual
	);
	const [pendingStatus, setPendingStatus] = useState(navigationObject?.status?.includes(organizationConstants.approvalStatus.PENDING));
	const [approvedStatus, setApprovedStatus] = useState(navigationObject?.status?.includes(organizationConstants.approvalStatus.APPROVED));
	const [rejectedStatus, setRejectedStatus] = useState(navigationObject?.status?.includes(organizationConstants.approvalStatus.REJECTED));

	const [searchWord, setSearchWord] = useState(navigationObject?.searchWord || "");
	const [sortingKey, setSortingKey] = useState(navigationObject?.sortingKey || "createdTime");
	const [sortingDirection, setSortingDirection] = useState(navigationObject?.sortingDirection || "DESC");
	const [status, setStatus] = useState(navigationObject?.status);

	const dispatch = useDispatch();
	const timeoutRef = useRef(null);
	const [textDebouncer] = useState(() => new ProgressiveRejectDebouncer(0.7 * 1000));
	const [debouncer] = useState(() => new ProgressiveRejectDebouncer(1 * 1000));

	useEffect(() => {
		let statusArray = [];
		if (pendingStatus || approvedStatus || rejectedStatus) {
			if (pendingStatus) {
				statusArray.push(organizationConstants.approvalStatus.PENDING);
			}
			if (approvedStatus) {
				statusArray.push(organizationConstants.approvalStatus.APPROVED);
			}
			if (rejectedStatus) {
				statusArray.push(organizationConstants.approvalStatus.REJECTED);
			}
		}
		setStatus(statusArray);
		dispatch(
			organizationAction.getOrganizations({
				search: searchWord || "",
				status: statusArray,
				l: DEFAULT_LIMIT,
				p: currentPage,
				sort: `${sortingKey}:${sortingDirection}`,
			})
		);
	}, [dispatch]);

	const handleSorting = (key) => {
		if (!debouncer.token()) {
			return;
		}
		let direction = "DESC";
		if (sortingKey === key && sortingDirection === "DESC") {
			direction = "ASC";
		}
		setSortingKey(key);
		setSortingDirection(direction);
		dispatch(
			organizationAction.getOrganizations({
				search: searchWord || "",
				status: status || [],
				l: DEFAULT_LIMIT,
				p: DEFAULT_PAGE,
				sort: `${key}:${direction}`,
			})
		);
	};

	const handleSearch = (value) => {
		dispatch(
			organizationAction.getOrganizations({
				search: value || "",
				status: status || [],
				l: DEFAULT_LIMIT,
				p: DEFAULT_PAGE,
				sort: `${sortingKey}:${sortingDirection}`,
			})
		);
	};

	const handleOnChangeCheckbox = ({ key }) => {
		if (!debouncer.token()) {
			return;
		}
		if (key === "pendingStatus") {
			setPendingStatus((prev) => !prev);
		} else if (key === "approvedStatus") {
			setApprovedStatus((prev) => !prev);
		} else if (key === "rejectedStatus") {
			setRejectedStatus((prev) => !prev);
		}
		let statusArray = [];
		if (!pendingStatus || !approvedStatus || !rejectedStatus) {
			if ((key === "pendingStatus" && !pendingStatus) || (key != "pendingStatus" && pendingStatus)) {
				statusArray.push(organizationConstants.approvalStatus.PENDING);
			}
			if ((key === "approvedStatus" && !approvedStatus) || (key != "approvedStatus" && approvedStatus)) {
				statusArray.push(organizationConstants.approvalStatus.APPROVED);
			}
			if ((key === "rejectedStatus" && !rejectedStatus) || (key != "rejectedStatus" && rejectedStatus)) {
				statusArray.push(organizationConstants.approvalStatus.REJECTED);
			}
		}
		setStatus(statusArray);
		dispatch(
			organizationAction.getOrganizations({
				search: searchWord || "",
				status: statusArray,
				l: DEFAULT_LIMIT,
				p: DEFAULT_PAGE,
				sort: `${sortingKey}:${sortingDirection}`,
			})
		);
	};

	const handlePageChange = (pageNum) => {
		if (!debouncer.token() || currentPage === pageNum) {
			return;
		}
		dispatch(
			organizationAction.getOrganizations({
				search: searchWord || "",
				status: status || [],
				l: DEFAULT_LIMIT,
				p: pageNum,
				sort: `${sortingKey}:${sortingDirection}`,
			})
		);
	};

	const handleRefreshFilter = () => {
		if (
			searchWord === "" &&
			!pendingStatus &&
			!approvedStatus &&
			!rejectedStatus &&
			status.length === 0 &&
			sortingKey === "createdTime" &&
			sortingDirection === "DESC" &&
			currentPage === DEFAULT_PAGE
		) {
			return;
		}
		setSearchWord("");
		setPendingStatus(false);
		setApprovedStatus(false);
		setRejectedStatus(false);
		setStatus([]);
		setSortingKey("createdTime");
		setSortingDirection("DESC");
		dispatch({ type: organizationConstants.type.RESET_ALL_ORGANIZATION_LIST });
		dispatch(
			organizationAction.getOrganizations({
				search: "",
				status: [],
				l: DEFAULT_LIMIT,
				p: DEFAULT_PAGE,
				sort: `createdTime:DESC`,
			})
		);
	};

	function resetTimeout() {
		if (timeoutRef.current) {
			clearTimeout(timeoutRef.current);
		}
	}

	const handleSelectChange = ({ key, value }) => {
		if (key === "searchWord") {
			setSearchWord(value);
		}
		if (!textDebouncer.token()) {
			resetTimeout();
			timeoutRef.current = setTimeout(() => {
				handleSearch(value);
			}, 1000);
			return;
		}
		resetTimeout();
		handleSearch(value);
	};

	const HandleRowOnClick = (item) => {
		history.push({
			pathname: `${generalConstants.PATH.ORGANIZATION}`,
			state: { organizationKey: item._key },
		});
	};

	const getSocialStatus = (item) => {
		const hasSocialAccount = item.hasSocialAccount;
		const is_verified = item.is_verified;

		if (hasSocialAccount) {
			return is_verified ? statusConstants.status.SOCIAL_NETWORK_VERIFIED.key : statusConstants.status.SOCIAL_NETWORK_UNVERIFIED.key;
		} else {
			return statusConstants.status.SOCIAL_NETWORK_NONE.key;
		}
	};

	const renderTH = () => {
		return organizationConstants.TABLE_COLUMN.map((column) => {
			return (
				<TableTh key={`${column.key}_table_header`} width={column.width}>
					<THButton key={`${column.key}_table_header`} type="button" onClick={() => handleSorting(column.key)}>
						<div
							style={{
								textAlign:
									column.key === "status" || column.key === "remark" || column.key === "rejectReason" || column.key === "hasSocialAccount"
										? "center"
										: "auto",
							}}
						>
							{column.value}
							{sortingKey === column.key ? (
								sortingDirection === "ASC" ? (
									<ArrowDropUp fontSize={"small"} style={{ verticalAlign: "middle" }} />
								) : (
									<ArrowDropDown fontSize={"small"} style={{ verticalAlign: "middle" }} />
								)
							) : null}
						</div>
					</THButton>
				</TableTh>
			);
		});
	};
	const renderTableHeader = renderTH();

	const renderTD = () => {
		if (organizations && organizations.length > 0) {
			return organizations.map((item, index) => {
				const tempObj = {};
				organizationConstants.TABLE_COLUMN.forEach((column) => {
					tempObj[column.key] = item[column.key];
				});

				return (
					<TableRow
						key={`${item._id}_${index}_tr`}
						onClick={() => {
							HandleRowOnClick(item);
						}}
					>
						{Object.keys(tempObj).map((columnName, index) => {
							if (columnName === "status") {
								return (
									<TableTd key={`${item._id}_${columnName}_${index}_td`} style={{ verticalAlign: "middle" }}>
										<OrganizationStatus item={item} />
									</TableTd>
								);
							} else if (columnName === "type") {
								return (
									<TableTd key={`${item._id}_${columnName}_${index}_td`} style={{ verticalAlign: "middle" }}>
										{organizationConstants.organizationType[item.type]}
									</TableTd>
								);
							} else if (columnName === "approvalTime" || columnName === "createdTime") {
								let dateStr = "-";
								if (tempObj[columnName]) {
									let dateParseResult = moment(tempObj[columnName]).format("YYYY年M月DD日 hh:mmA");
									if (dateParseResult !== "Invalid date") {
										dateStr = dateParseResult;
									}
								}
								return <TableTd key={`${item._id}_${columnName}_${index}_td`}>{dateStr}</TableTd>;
							} else if (columnName === "remark") {
								return (
									<TableTd key={`${item._id}_${columnName}_${index}_td`} style={{ verticalAlign: "middle" }}>
										<RemarkDiv>{tempObj[columnName] ? <RemarkIcon fill={"#04b2b9"} /> : <div />}</RemarkDiv>
									</TableTd>
								);
							} else if (columnName === "rejectReason") {
								return (
									<TableTd key={`${item._id}_${columnName}_${index}_td`} style={{ verticalAlign: "middle" }}>
										<RemarkDiv>{tempObj[columnName] ? <RemarkIcon fill={"#d04949"} /> : <div />}</RemarkDiv>
									</TableTd>
								);
							} else if (columnName === "hasSocialAccount") {
								return (
									<TableTd key={`${item._id}_${columnName}_${index}_td`} style={{ verticalAlign: "middle" }}>
										<Status status={getSocialStatus(item)} />
									</TableTd>
								);
							}
							return <TableTd key={`${item._id}_${columnName}_${index}_td`}>{tempObj[columnName] || "-"}</TableTd>;
						})}
					</TableRow>
				);
			});
		}
	};
	const renderTableCell = renderTD();

	return (
		<>
			<PageContainer>
				<LeftContainer>
					<AdminMenubar section={generalConstants.NAV_TAB.ORGANIZATION_MANAGEMENT.key} />
				</LeftContainer>
				<RightContainer>
					<AdminUserInfo />
					<AdminSectionbar
						title={generalConstants.NAV_TAB.ORGANIZATION_MANAGEMENT.key}
						sublabel={`機構數量: ${totalItem}`}
						locationPathArray={[
							{ title: "主頁面", isAction: true, target: generalConstants.PATH.DASHBOARD },
							{ title: generalConstants.TAB_NAME[generalConstants.NAV_TAB.ORGANIZATION_MANAGEMENT.key], isAction: false, target: "" },
						]}
					/>
					<ContentContainer>
						<SearchBar>
							<SearchBarLeft>
								<RowInBlock marginBottom="0px">
									<Title style={{ fontSize: "13px", lineHeight: "27px" }}>機構搜尋</Title>
									<div style={{ marginLeft: "10px", width: "20%" }}>
										<StandardTextField
											placeholder="關鍵字"
											value={searchWord}
											name="searchWord"
											handleChange={handleSelectChange}
											style={{ borderTopRightRadius: "0px", borderBottomRightRadius: "0px", minWidth: 200 }}
										/>
									</div>
									<RefreshButton style={{ marginLeft: "5px" }} handleOnClick={handleRefreshFilter} label="重置選項"></RefreshButton>
									<SerchBarRight>
										<RowInBlock marginBottom="0px">
											<Title style={{ fontSize: "13px", lineHeight: "27px" }}>批核狀態</Title>
											<div style={{ color: "#33333", backgroundColor: "#ffd95c", paddingTop: 0, paddingBottom: 0, paddingLeft: 5 }}>
												<SquareCheckbox
													key={`pendingStatus_checkbox`}
													name={"pendingStatus"}
													label={`${organizationConstants.approvalStatusLabel.PENDING}`}
													checked={!pendingStatus}
													handleChange={handleOnChangeCheckbox}
												/>
											</div>
											<div style={{ color: "#33333", backgroundColor: "#5cff82", paddingTop: 0, paddingBottom: 0, paddingLeft: 5 }}>
												<SquareCheckbox
													key={`approvedStatus_checkbox`}
													name={"approvedStatus"}
													label={`${organizationConstants.approvalStatusLabel.APPROVED}`}
													checked={!approvedStatus}
													handleChange={handleOnChangeCheckbox}
												/>
											</div>
											<div style={{ color: "#ffffff", backgroundColor: "#ff5c5c", paddingTop: 0, paddingBottom: 0, paddingLeft: 5 }}>
												<SquareCheckbox
													key={`rejectedStatus_checkbox`}
													name={"rejectedStatus"}
													label={`${organizationConstants.approvalStatusLabel.REJECTED}`}
													checked={!rejectedStatus}
													handleChange={handleOnChangeCheckbox}
												/>
											</div>
										</RowInBlock>
									</SerchBarRight>
								</RowInBlock>
							</SearchBarLeft>
						</SearchBar>
						<TableContainer>
							<table style={{ width: "100%", borderCollapse: "collapse", tableLayout: "fixed" }}>
								<thead>
									<tr>{renderTableHeader}</tr>
								</thead>
								<tbody>{renderTableCell}</tbody>
							</table>
						</TableContainer>
						<PaginationContainer>
							<Pagination count={numOfPage} page={currentPage} handlePageChange={handlePageChange} />
						</PaginationContainer>
					</ContentContainer>
					<AdminFooter></AdminFooter>
				</RightContainer>
			</PageContainer>
		</>
	);
};

const RowInBlock = styled.div`
	display: flex;
	margin-bottom: ${(props) => props.marginBottom || "10px"};
	flex-direction: row;
`;

const Title = styled.div`
	font-size: 1.3em;
	color: black;
	font-weight: bold;
	margin-right: 10px;
`;

const SerchBarRight = styled.div`
	padding-left: 10px;
	margin-left: 10px;
	border-left: 1px solid #a8a8a8;
`;

const RemarkDiv = styled.div`
	width: 30%;
	margin-left: auto;
	margin-right: auto;
`;
