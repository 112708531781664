import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import isEqual from "react-fast-compare";
import moment from "moment";

import { history } from "_util/_helper";
import { AdminMenubar, AdminUserInfo, AdminSectionbar, AdminFooter } from "components/admin";
import { Status, Pagination } from "components/general";
import {
	PageContainer,
	LeftContainer,
	RightContainer,
	ContentContainer,
	PaginationContainer,
	TableContainer,
	TableRow,
	TableTh,
	THButton,
	TableTd,
} from "components/layout";

import { missionConstants, generalConstants, statusConstants } from "_constants";
import { missionAction, parentAction } from "actions";

import { ArrowDropDown, ArrowDropUp } from "@material-ui/icons";

import { ReactComponent as CourseIcon } from "resources/img/course_icon.svg";

import ProgressiveRejectDebouncer from "_util/debouncers/ProgressiveRejectDebouncer";

const DEFAULT_PAGE = 1;
const DEFAULT_LIMIT = 10;
const RANK_LIMIT = 5;
const FROM_MISSION = "mission";
export const MissionManagementPage = () => {
	const dispatch = useDispatch();

	const { navigationObject } = useSelector(({ mission }) => ({ navigationObject: mission.navigationObject?.root }), isEqual);
	const { missionList } = useSelector(({ mission }) => ({ missionList: mission.navigationObject?.root?.missionList }), isEqual);

	const { rankList } = useSelector(({ mission }) => ({ rankList: mission.rankList }), isEqual);
	const { parentRankList } = useSelector(({ parent }) => ({ parentRankList: parent.parentRankList }), isEqual);

	const totalItems = useSelector(({ mission }) => mission.navigationObject?.root?.totalItems);
	const currentPage = useSelector(({ mission }) => mission.navigationObject?.root?.currentPage);
	const numOfPage = useSelector(({ mission }) => mission.navigationObject?.root?.numOfPage);
	const [sortingKey, setSortingKey] = useState(navigationObject?.sortingKey || "");
	const [sortingDirection, setSortingDirection] = useState(navigationObject?.sortingDirection || "");

	const [debouncer] = useState(() => new ProgressiveRejectDebouncer(1 * 1000));

	useEffect(() => {
		let form = {
			limit: DEFAULT_LIMIT,
			page: currentPage,
			sort: `${navigationObject?.sortingKey}:${navigationObject?.sortingDirection}`,
		};
		dispatch(missionAction.getMissionList(form, "root"));
	}, [dispatch]);

	useEffect(() => {
		let form = {
			limit: RANK_LIMIT,
			page: DEFAULT_PAGE,
			sort: `completedCount:DESC`,
		};
		dispatch(missionAction.getMissionList(form, "rank"));
	}, [dispatch]);

	useEffect(() => {
		let form = {
			limit: RANK_LIMIT,
			page: DEFAULT_PAGE,
			sort: `completedMission:DESC`,
		};
		dispatch(parentAction.fetchParentByMission(form));
	}, [dispatch]);

	const handleSortAction = (key) => {
		if (!debouncer.token()) {
			return;
		}
		let direction = "DESC";
		if (sortingKey === key && sortingDirection === "DESC") {
			direction = "ASC";
		}
		setSortingKey(key);
		setSortingDirection(direction);
		let form = {
			limit: DEFAULT_LIMIT,
			page: DEFAULT_PAGE,
			sort: `${key}:${direction}`,
		};
		dispatch(missionAction.getMissionList(form, "root"));
	};

	const HandleRowOnClick = (item) => {
		history.push({
			pathname: generalConstants.PATH.MISSION,
			state: {
				from: FROM_MISSION,
				missionKey: item._key,
			},
		});
	};

	const HandleParentRowOnClick = (item) => {
		history.push({
			pathname: `${generalConstants.PATH.EDIT_PARENT}`,
			state: { from: FROM_MISSION, parentKey: item._key },
		});
	};

	const handlePageChange = (pageNum) => {
		if (!debouncer.token() || currentPage === pageNum) {
			return;
		}
		let form = {
			limit: DEFAULT_LIMIT,
			page: pageNum,
			sort: `${sortingKey}:${sortingDirection}`,
		};
		dispatch(missionAction.getMissionList(form, "root"));
	};

	const handleMissionMsg = () => {
		history.push({ pathname: `${generalConstants.PATH.MISSION}/add`, state: { from: FROM_MISSION } });
	};

	const getStatus = (item) => {
		const status = "MISSION_" + item.status;
		const statusList = statusConstants.status;
		let thekey = "MISSION_OPEN";
		for (let k in statusList) {
			if (k === status) {
				thekey = k;
			}
		}
		return statusConstants.status[thekey].key;
	};

	const renderTH = () => {
		return missionConstants.TABLE_COLUMN.map((column) => {
			return (
				<TableTh key={`${column.key}_table_header`} width={column.width}>
					<THButton key={`${column.key}_table_header`} type="button" onClick={() => handleSortAction(column.key)}>
						<div style={{ textAlign: column.key === "status" ? "center" : "auto" }}>
							{column.value}
							{sortingKey === column.key ? (
								sortingDirection === "ASC" ? (
									<ArrowDropUp fontSize={"small"} style={{ verticalAlign: "middle" }} />
								) : (
									<ArrowDropDown fontSize={"small"} style={{ verticalAlign: "middle" }} />
								)
							) : null}
						</div>
					</THButton>
				</TableTh>
			);
		});
	};
	const renderTableHeader = renderTH();

	const renderTD = () => {
		if (missionList?.length > 0) {
			return missionList?.map((item, index) => {
				const tempObj = {};
				missionConstants.TABLE_COLUMN.forEach((column) => {
					tempObj[column.key] = item[column.key];
				});
				return (
					<TableRow
						key={`${item._id}_${index}_tr`}
						onClick={() => {
							HandleRowOnClick(item);
						}}
					>
						{Object.keys(tempObj).map((columnName, index) => {
							if (tempObj[columnName] || tempObj[columnName] === 0) {
								if (columnName === "status") {
									return (
										<TableTd key={`${item._id}_${columnName}_${index}_td`} style={{ verticalAlign: "middle" }}>
											<Status status={getStatus(item)} />
										</TableTd>
									);
								} else if (
									columnName === "description" ||
									columnName === "instruction" ||
									columnName === "duration" ||
									columnName === "coin" ||
									columnName === "completedCount"
								) {
									return <TableTd key={`${item._id}_${columnName}_${index}_td`}>{tempObj[columnName]}</TableTd>;
								} else if (columnName === "lastUpdateTime") {
									return (
										<TableTd key={`${item._id}_${columnName}_${index}_td`}>
											{(tempObj[columnName] && moment(tempObj[columnName]).format("YYYY年M月DD日 hh:mmA")) || "-"}
										</TableTd>
									);
								} else {
									return (
										<TableTd key={`${item._id}_${columnName}_${index}_td`}>
											{missionConstants[columnName][tempObj[columnName]] ? missionConstants[columnName][tempObj[columnName]].label : ""}
										</TableTd>
									);
								}
							} else {
								return <TableTd key={`${item._id}_${columnName}_${index}_td`}>-</TableTd>;
							}
						})}
					</TableRow>
				);
			});
		}
	};
	const renderTableCell = renderTD();

	const renderRankTD = (items, keys, limit, page = 1, rankBy) => {
		if (items && items.length > 0) {
			const subscriptionList = items.map((item) => {
				return item[rankBy];
			});
			return items.map((item, index) => {
				if (index < limit * (page - 1) || index > limit * page - 1) {
					return null;
				}
				const tempObj = {};
				keys.forEach((column) => {
					tempObj[column.key] = item[column.key];
				});
				return (
					<TableRow
						style={{ flexDirection: "row" }}
						key={`${item._id}_${index}_tr`}
						onClick={() => {
							if (rankBy === "completedMission") {
								HandleParentRowOnClick(item);
								return;
							}
							HandleRowOnClick(item);
						}}
					>
						{Object.keys(tempObj).map((columnName, index) => {
							if (columnName === "rank") {
								const displayRank = subscriptionList.indexOf(item[rankBy]) + 1;
								return (
									<TableTd key={`${item._id}_${columnName}_${index}_td`} style={{ width: "10px" }}>
										<RankBadge rank={displayRank}>{displayRank || "-"}</RankBadge>
									</TableTd>
								);
							} else if (columnName === "description" || columnName === "nickname") {
								return (
									<TableTd key={`${item._id}_${columnName}_${index}_td`} style={{ maxWidth: "100px" }}>
										<TableRowTitle>{tempObj[columnName]}</TableRowTitle>
									</TableTd>
								);
							} else {
								return <TableTd key={`${item._id}_${columnName}_${index}_td`}>{tempObj[columnName] || "-"}</TableTd>;
							}
						})}
					</TableRow>
				);
			});
		}
	};
	const renderRankTableCell = renderRankTD(
		rankList,
		missionConstants.RANK_TABLE_COLUMN,
		RANK_LIMIT,
		DEFAULT_PAGE,
		missionConstants.RANK_TABLE_COLUMN[2].key
	);
	const renderParentRankTableCell = renderRankTD(
		parentRankList,
		missionConstants.PARENT_RANK_TABLE_COLUMN,
		RANK_LIMIT,
		DEFAULT_PAGE,
		missionConstants.PARENT_RANK_TABLE_COLUMN[2].key
	);

	return (
		<>
			<PageContainer>
				<LeftContainer>
					<AdminMenubar section={generalConstants.NAV_TAB.MISSION_MANAGEMENT.key} />
				</LeftContainer>
				<RightContainer>
					<AdminUserInfo />
					<AdminSectionbar
						title={generalConstants.NAV_TAB.MISSION_MANAGEMENT.key}
						buttonLabel={"新增任務"}
						buttonIcon={<CourseIcon />}
						buttonType={generalConstants.BUTTON_TYPE.SUBMIT}
						handleOnClick={handleMissionMsg}
						sublabel={"任務數量: " + totalItems}
						locationPathArray={[
							{ title: "主頁面", isAction: true, target: generalConstants.PATH.DASHBOARD },
							{
								title: generalConstants.TAB_NAME[generalConstants.NAV_TAB.MISSION_MANAGEMENT.key],
								isAction: false,
								target: "",
							},
						]}
					/>
					<MissionContent>
						<ContentLeft>
							<ContentContainer style={{ minHeight: "630px" }}>
								<TableContainer>
									<table style={{ width: "100%", borderCollapse: "collapse", tableLayout: "fixed" }}>
										<thead>
											<tr>{renderTableHeader}</tr>
										</thead>
										<tbody>{renderTableCell}</tbody>
									</table>
								</TableContainer>
								{missionList && missionList.length > 0 && (
									<PaginationContainer style={{ paddingLeft: "20px" }}>
										<Pagination count={numOfPage} page={currentPage} handlePageChange={handlePageChange} />
									</PaginationContainer>
								)}
							</ContentContainer>
						</ContentLeft>
						<ContentRight>
							<ContentContainer style={{ minHeight: "288px", marginLeft: 0, marginBottom: 20 }}>
								<Title> - 完成次數最多排名</Title>
								<table style={{ width: "100%", borderCollapse: "collapse" }}>
									<tbody>{renderRankTableCell}</tbody>
								</table>
							</ContentContainer>
							<ContentContainer style={{ minHeight: "288px", marginLeft: 0, marginTop: 0 }}>
								<Title> - 完成任務最多用戶</Title>
								<table style={{ width: "100%", borderCollapse: "collapse" }}>
									<tbody>{renderParentRankTableCell}</tbody>
								</table>
							</ContentContainer>
						</ContentRight>
					</MissionContent>
					<AdminFooter></AdminFooter>
				</RightContainer>
			</PageContainer>
		</>
	);
};

const MissionContent = styled.div`
	display: flex;
	flex-direction: row;
`;

const ContentLeft = styled.div`
	flex: 0 0 75%;
`;

const ContentRight = styled.div`
	flex: 0 0 25%;
`;

const Title = styled.div`
	font-weight: bold;
	margin: 0 0 5px 4px;
	font-size: 14px;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.43;
	letter-spacing: normal;
	text-align: left;
	color: #333399;
`;

const RankBadge = styled.div`
	display: flex;
	overflow: hidden;
	align-items: center;
	border-radius: 50%;
	justify-content: center;
	width: 20px;
	height: 20px;
	background-color: ${(props) => {
		if (props.rank === 3) return "#b59b53";
		if (props.rank === 2) return "#c0c0be";
		if (props.rank === 1) return "#d8c324";
		else return "#ffffff";
	}};
	border-color: ${(props) => {
		if (props.rank === 3) return "#b59b53";
		if (props.rank === 2) return "#c0c0be";
		if (props.rank === 1) return "#d8c324";
		else return "#dcdcdc";
	}};
	border-width: 1px;
	border-style: solid;
	font-size: 11px;
	font-weight: 600;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.18;
	letter-spacing: normal;
	color: ${(props) => (props.rank > 3 ? "#555555" : "#ffffff")};
`;

const TableRowTitle = styled.div`
	font-size: 13px;
	font-weight: 500;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.46;
	letter-spacing: normal;
	text-align: left;
	color: #0085b5;
	height: 20px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`;
