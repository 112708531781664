import axios from "axios";

export const reportService = {
	fetchReport,
	getReportTarget,
	handleReport,
};

const httpOptions = {
	headers: {
		"Content-Type": "application/json",
	},
	withCredentials: true,
};

async function fetchReport(form) {
	return axios({
		url: `${process.env.REACT_APP_BACKEND_URL}/socialnetwork/report`,
		...httpOptions,
		method: "GET",
		params: form,
	})
		.then(handleResponse)
		.catch(handleError);
}

async function getReportTarget(targetType, key, batchTime = null) {
	return axios({
		url: `${process.env.REACT_APP_BACKEND_URL}/socialnetwork/report/${targetType}/${key}${batchTime == null ? "" : "/" + batchTime}`,
		...httpOptions,
		method: "GET",
	})
		.then(handleResponse)
		.catch(handleError);
}

async function handleReport(data) {
	return axios({
		url: `${process.env.REACT_APP_BACKEND_URL}/socialnetwork/report`,
		...httpOptions,
		data: data,
		method: "PUT",
	})
		.then(handleResponse)
		.catch(handleError);
}

function handleError(err) {
	const msg = (err.response && err.response.data.message) || err.message || err;
	throw { error: msg };
}

function handleResponse(res) {
	return res.data;
}
