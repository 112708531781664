import React, { useState, useEffect } from "react";
import { useSelector /*, useDispatch */ } from "react-redux";
import styled from "styled-components";
import { messageConstants, generalConstants, schoolConstants /*, statusConstants*/ } from "_constants";
//import { messageAction } from "actions";
//import moment from "moment";
import { history } from "_util/_helper";
import { CancelButton } from "components/general";
//import { MuiPickersUtilsProvider, KeyboardTimePicker, KeyboardDatePicker } from "@material-ui/pickers";
//import MomentUtils from "@date-io/moment";
//import { toast } from "react-toastify";
//import Button from "@material-ui/core/Button";
//import { Add, Remove } from "@material-ui/icons";
//import { useParams } from "react-router-dom";
import moment from "moment";

import { AdminMenubar, AdminUserInfo, AdminSectionbar, AdminFooter } from "components/admin";
import { PageContainer, LeftContainer, RightContainer, ContentContainer } from "components/layout";

export const ReadOnlyMsgPage = () => {
	const getRoundedDate = (minutes, d = new Date()) => {
		let ms = 1000 * 60 * minutes;
		let roundedDate = new Date(Math.ceil(d.getTime() / ms) * ms);

		return roundedDate;
	};

	const { message /*, list*/ } = useSelector(({ message }) => ({
		message: message.readmessage,
		list: message.importMsgList,
	}));

	const initForm = {
		type: messageConstants.OPTION.type[0].key,
		infoType: messageConstants.OPTION.infoType[0].key,
		schoolLevel: schoolConstants.OPTION.level[0].key,
		schoolId: "",
		schoolName: "",
		scheduleType: messageConstants.OPTION.scheduleType[0].key,
		scheduleTime: getRoundedDate(5, new Date()),
		category: "",
		title: "",
		detail: "",
		startTime: getRoundedDate(30, new Date()),
		endTime: getRoundedDate(90, new Date()),
		eventType: messageConstants.DATA.eventType.SINGLE,
		fullDay: false,
		url: "",
		// addToCalendar: false,
	};

	const [formData, setFormData] = useState(initForm);

	const [addDateTimeIsClose, setAddDateTimeIsClose] = useState(true);
	const [fullDay, setFullDay] = useState(false);
	const [addURLIsClose, setAddURLIsClose] = useState(true);

	//const dispatch = useDispatch();

	//const { key } = useParams();

	const handleBack = () => {
		history.goBack();
	};

	useEffect(() => {
		const tempForm = {};
		Object.keys(formData).forEach((key) => {
			if (key === "startTime") {
				if (message[key]) {
					setAddDateTimeIsClose(false);
				} else {
					setAddDateTimeIsClose(true);
				}
			}
			if (key === "fullDay") {
				setFullDay(message[key]);
			}
			tempForm[key] = message[key] ? message[key] : formData[key];
		});
		if (tempForm.url !== "") {
			setAddURLIsClose(false);
		} else {
			setAddURLIsClose(true);
		}

		setFormData(tempForm);
	}, [message]);

	return (
		<>
			<PageContainer>
				<LeftContainer>
					<AdminMenubar section={generalConstants.NAV_TAB.MSG_MANAGEMENT.key} />
				</LeftContainer>
				<RightContainer>
					<AdminUserInfo />
					<AdminSectionbar
						title={generalConstants.NAV_TAB.EDIT_MSG.key}
						locationPathArray={[
							{ title: "主頁面", isAction: true, target: generalConstants.PATH.DASHBOARD },
							{
								title: generalConstants.TAB_NAME[generalConstants.NAV_TAB.MSG_MANAGEMENT.key],
								isAction: true,
								target: generalConstants.PATH.MSG_MANAGEMENT,
							},
							{
								title: generalConstants.TAB_NAME[generalConstants.NAV_TAB.EDIT_MSG.key],
								isAction: false,
								target: "",
							},
						]}
					/>
					<ContentContainer>
						<RowInBlock>
							<ContentLeft>
								<Block marginTop="0">
									<Title> - 訊息類別</Title>
									<TextFieldLabel>{messageConstants.Translate.type[formData.type]}</TextFieldLabel>
								</Block>
								<Block>
									<Title> - 資訊分類</Title>

									<TextFieldLabel>{formData.schoolName}</TextFieldLabel>
								</Block>
								<Block>
									{formData.scheduleType === messageConstants.DATA.scheduleType.IMMEDIATELY && (
										<>
											<Title> - 發送時間</Title>
											<TextFieldLabel> {"即時"}</TextFieldLabel>
										</>
									)}
									{formData.scheduleType === messageConstants.DATA.scheduleType.SCHEDULED && (
										<>
											<Title> - 發送時間</Title>
											<TextFieldLabel> {moment(formData.scheduleTime).format("YYYY年MM月DD日 hh:mmA")}</TextFieldLabel>
										</>
									)}
								</Block>
							</ContentLeft>

							<ContentRight>
								<Block marginTop="0">
									<Title> - 訊息設定</Title>
									<Subtitle>標題</Subtitle>
									<RowInBlock>
										<TextFieldLabel>
											{formData.category ? `[${messageConstants.Translate.category[formData.category]}]` : ""} {formData.title}
										</TextFieldLabel>
									</RowInBlock>
									<Subtitle>內容</Subtitle>
									<TextFieldLabel style={{ whiteSpace: "pre-wrap" }}>{formData.detail}</TextFieldLabel>
								</Block>
								<>
									{!addDateTimeIsClose && !fullDay && (
										<>
											<Subtitle>開始日期</Subtitle>
											<TextFieldLabel>{moment(formData.startTime).format("YYYY年MM月DD日 hh:mmA")}</TextFieldLabel>
											<Subtitle>結束日期</Subtitle>
											<TextFieldLabel>{moment(formData.endTime).format("YYYY年MM月DD日 hh:mmA")}</TextFieldLabel>
										</>
									)}
									{!addDateTimeIsClose && fullDay && (
										<>
											<Subtitle>全日</Subtitle>
											<Subtitle>開始日期</Subtitle>
											<TextFieldLabel>{moment(formData.startTime).format("YYYY年MM月DD日")}</TextFieldLabel>
											<Subtitle>結束日期</Subtitle>
											<TextFieldLabel>{moment(formData.endTime).format("YYYY年MM月DD日")}</TextFieldLabel>
										</>
									)}
									{!addURLIsClose && (
										<>
											<Subtitle>連結</Subtitle>
											<TextFieldLabel>{formData.url}</TextFieldLabel>
										</>
									)}
								</>
							</ContentRight>
						</RowInBlock>
						<RowInBlock marginTop={"10px"} style={{ justifyContent: "flex-end" }}>
							<CancelButton label={"返回"} style={{ marginRight: "15px" }} handleOnClick={handleBack} />
						</RowInBlock>
					</ContentContainer>
					<AdminFooter></AdminFooter>
				</RightContainer>
			</PageContainer>
		</>
	);
};

const ContentLeft = styled.div`
	flex: 1;
	border-right: 1px solid #dddddd;
	min-height: 600px;
`;

const ContentRight = styled.div`
	flex: 2;
	padding-left: 20px;
`;

const Block = styled.div`
	margin-top: ${(props) => props.marginTop || "40px"};
	flex-direction: column;
`;

const RowInBlock = styled.div`
	display: flex;
	margin-bottom: ${(props) => props.marginBottom || "10px"};
	flex-direction: row;
`;

const Title = styled.div`
	font-weight: bold;
	margin: 0 0 12px 4px;
	font-size: 14px;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.43;
	letter-spacing: normal;
	text-align: left;
	color: #333399;
`;

const Subtitle = styled.div`
	color: black;
	margin-bottom: ${(props) => props.marginBottom || "10px"};
	font-size: 14px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.43;
	letter-spacing: normal;
`;

const TextFieldLabel = styled.div`
	color: black;
	font-size: 13px;
	line-height: 1.5;
	font-weight: bold;
	margin-bottom: 10px;
`;
