import { authConstants, organizationConstants } from "_constants";

const initialState = {
	isFetching: false,
	organization: {},
	updateOrganizationSuccess: false,
	navigationObject: {
		root: {
			searchWord: "",
			sortingKey: "createdTime",
			sortingDirection: "DESC",
			status: [],
			numOfPage: 1,
			currentPage: 1,
			totalItem: 0,
			organizationList: [],
		},
	},
};

const organizationReducer = (state = initialState, action) => {
	switch (action.type) {
		case authConstants.type.UNAUTHORIZED_ERROR: {
			let navigationObject = initialState.navigationObject;
			if (typeof state.navigationObject?.root != "undefined") {
				navigationObject = {
					...state.navigationObject,
					root: { ...state.navigationObject?.root, currentPage: 1 },
				};
			}
			return {
				...state,
				navigationObject: navigationObject,
			};
		}
		case organizationConstants.type.FETCH_ORGANIZATION_LIST_REQUEST: {
			return {
				...state,
				isFetching: true,
			};
		}
		case organizationConstants.type.FETCH_ORGANIZATION_LIST_FAILURE: {
			return {
				...state,
				isFetching: false,
			};
		}
		case organizationConstants.type.FETCH_ORGANIZATION_LIST_SUCCESS: {
			return {
				...state,
				isFetching: false,
				navigationObject: {
					root: {
						organizationList: action.organizationList,
						totalItem: action.totalNum,
						numOfPage: action.totalPage,
						currentPage: action.currentPage,
						searchWord: action.search,
						sortingKey: action.sortingKey,
						sortingDirection: action.sortingDirection,
						status: action.status,
					},
				},
			};
		}
		case organizationConstants.type.RESET_ALL_ORGANIZATION_LIST: {
			return {
				...state,
				isFetching: true,
				navigationObject: {
					root: {
						organizationList: [],
						totalItem: 0,
						numOfPage: 1,
						currentPage: 1,
						searchWord: "",
						sortingKey: "name",
						sortingDirection: "DESC",
						status: [],
					},
				},
			};
		}
		case organizationConstants.type.FETCH_ORGANIZATION_REQUEST: {
			return {
				...state,
				isFetching: true,
				organization: {},
			};
		}
		case organizationConstants.type.FETCH_ORGANIZATION_FAILURE: {
			return {
				...state,
				isFetching: false,
			};
		}
		case organizationConstants.type.FETCH_ORGANIZATION_SUCCESS: {
			return {
				...state,
				isFetching: false,
				organization: action.organization,
			};
		}
		case organizationConstants.type.UPDATE_ORGANIZATION_REQUEST: {
			return {
				...state,
				isFetching: true,
			};
		}
		case organizationConstants.type.UPDATE_ORGANIZATION_SUCCESS: {
			return {
				...state,
				isFetching: false,
				updateOrganizationSuccess: true,
				organization: action.organization,
			};
		}
		case organizationConstants.type.UPDATE_ORGANIZATION_FAILURE: {
			return {
				...state,
				isFetching: false,
				updateOrganizationSuccess: false,
			};
		}
		default: {
			return state;
		}
	}
};

export default organizationReducer;
