import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

import { learningCentreConstants } from "_constants";
import { Checkbox, SubmitButton } from "components/general";

export const NewCategoryDialog = (props) => {
	const { category, handleOnSubmit, handleOnClose, active } = props;

	const [categoryList, setCategoryList] = useState([]);

	useEffect(() => {
		if (category) {
			setCategoryList(category);
		}
	}, [category]);

	const handleChange = (value) => {
		if (categoryList.length > 0) {
			if (categoryList.includes(value)) {
				setCategoryList(
					categoryList.filter((item) => {
						return item.trim() != value.trim();
					})
				);
			} else {
				setCategoryList([...categoryList, value]);
			}
		} else {
			setCategoryList([value]);
		}
	};

	return (
		<Dialog
			fullWidth={true}
			maxWidth="sm"
			open={active}
			onClose={() => {
				handleOnClose();
				setCategoryList([]);
			}}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<DialogTitle
				id="customized-dialog-title"
				onClose={() => {
					handleOnClose();
					setCategoryList([]);
				}}
			>
				{" "}
				- 新增課程類別
				<IconButton
					style={{ position: "absolute", top: 0, right: 0 }}
					onClick={() => {
						handleOnClose();
						setCategoryList([]);
					}}
				>
					<CloseIcon />
				</IconButton>
			</DialogTitle>
			<SubTitle>所有類別</SubTitle>
			<DialogContent>
				<Block>
					{learningCentreConstants.OPTION.category.map((item, index) => {
						var isCheck = false;

						categoryList.forEach((name) => {
							if (name === item.value) {
								isCheck = true;
							}
						});

						return (
							<div key={`category_${index}`}>
								<Button
									style={{ height: "40px", width: "100%", flexDirection: "row", justifyContent: "space-between" }}
									variant="text"
									onClick={() => {
										handleChange(item.value);
									}}
								>
									<Title style={{ justifyContent: "flex-start" }} key={`category_${index}`}>
										{item.value}
									</Title>
									<Checkbox
										style={{ justifyContent: "flex-end" }}
										name={"category"}
										checked={isCheck}
										handleChange={() => {
											handleChange(item.value);
										}}
									/>
								</Button>

								<LineBottom />
							</div>
						);
					})}
				</Block>
			</DialogContent>
			<DialogActions>
				<SubmitButton
					label={"確認"}
					handleOnClick={() => {
						if (categoryList != "") {
							handleOnSubmit(categoryList);
							handleOnClose();
							setCategoryList([]);
						}
					}}
				/>
			</DialogActions>
		</Dialog>
	);
};

const Block = styled.div`
	margin-top: ${(props) => props.marginTop || "10px"};
	flex-direction: column;
`;

const Title = styled.div`
	color: #0085b5;
	font-size: 13px;
	line-height: 1;
	font-weight: 500;
	margin-bottom: 4px;
`;

const SubTitle = styled.div`
	color: #000000;
	font-size: 14px;
	line-height: 1;
	font-weight: bold;
	margin-left: 30px;
`;

const LineBottom = styled.div`
	width: 100%;
	border-bottom: 1px solid #dddddd;
`;

NewCategoryDialog.propTypes = {
	category: PropTypes.array,
	handleOnSubmit: PropTypes.func,
	handleOnClose: PropTypes.func,
	active: PropTypes.bool,
};

NewCategoryDialog.defaultProps = {
	active: true,
};
