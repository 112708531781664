import { coinConstants } from "_constants";
import { coinService } from "services";
import { toast } from "react-toastify";

export const coinAction = {
	getTransactionHistory,
};

function getTransactionHistory(data, navigationKey) {
	return (dispatch) => {
		dispatch(request());
		coinService.getTransactionHistory(data).then(
			(response) => {
				dispatch(success(response, data));
			},
			(error) => {
				toast.error(error.error);
				dispatch(failure());
			}
		);
	};
	function request() {
		return { type: coinConstants.type.FETCH_COIN_HISTORY_LIST_REQUEST };
	}
	function failure() {
		return { type: coinConstants.type.FETCH_COIN_HISTORY_LIST_FAILURE };
	}
	function success(response, inputData) {
		const { totalItems, coinBalance, items } = response;
		let sortingKey = "lastUpdateTime",
			sortingDirection = "DESC";
		const sortingArray = inputData?.sort?.split(":");
		if (sortingArray && sortingArray.length === 2 && sortingArray[0] && sortingArray[1]) {
			sortingKey = sortingArray[0];
			sortingDirection = sortingArray[1] === "DESC" ? "DESC" : "ASC";
		}
		return {
			type: coinConstants.type.FETCH_COIN_HISTORY_LIST_SUCCESS,
			navigationKey,
			userKey: inputData.userKey,
			coinBalance: coinBalance,
			totalNum: totalItems,
			totalPage: Math.ceil(totalItems / inputData.l),
			currentPage: inputData.p,
			historyList: items,
			sortingDirection,
			sortingKey,
			searchWord: inputData.search || "",
		};
	}
}
