export const socialNetworkConstants = {
	type: {
		RESET_POST_SUCCESS: "RESET_POST_SUCCESS",

		FETCH_POST_TARGET_REQUEST: "FETCH_POST_TARGET_REQUEST",
		FETCH_POST_TARGET_FAILURE: "FETCH_POST_TARGET_FAILURE",
		FETCH_POST_TARGET_SUCCESS: "FETCH_POST_TARGET_SUCCESS",

		FETCH_POST_LIST_REQUEST: "FETCH_POST_LIST_REQUEST",
		FETCH_POST_LIST_FAILURE: "FETCH_POST_LIST_FAILURE",
		FETCH_POST_LIST_SUCCESS: "FETCH_POST_LIST_SUCCESS",

		SUGGEST_COMMENT_REQUEST: "SUGGEST_COMMENT_REQUEST",
		SUGGEST_COMMENT_FAILURE: "SUGGEST_COMMENT_FAILURE",
		SUGGEST_COMMENT_SUCCESS: "SUGGEST_COMMENT_SUCCESS",

		REVIEW_POST_REQUEST: "REVIEW_POST_REQUEST",
		REVIEW_POST_FAILURE: "REVIEW_POST_FAILURE",
		REVIEW_POST_SUCCESS: "REVIEW_POST_SUCCESS",

		DELETE_POST_REQUEST: "DELETE_POST_REQUEST",
		DELETE_POST_FAILURE: "DELETE_POST_FAILURE",
		DELETE_POST_SUCCESS: "DELETE_POST_SUCCESS",
	},
	TABLE_COLUMN: [
		{ key: "status", value: "狀態", searchable: false, width: "5%" },
		{ key: "actor", value: "發佈人", searchable: true, width: "15%" },
		{ key: "target", value: "發佈對象", searchable: true, width: "15%" },
		{ key: "text", value: "內容", searchable: true, width: "30%" },
		{ key: "commentCount", value: "留言數", searchable: false, width: "5%" },
		{ key: "time", value: "發佈時間", searchable: false, width: "15%" },
	],
	COMMENT_LIST_COLUMN: [
		{ key: "actor", value: "評論人", searchable: false, width: "15%" },
		{ key: "text", value: "評論內容", searchable: true, width: "70%" },
		{ key: "time", value: "評論時間", searchable: false, width: "15%" },
	],
	DATA: {
		status: {
			PENDING: { key: "PENDING", label: "待處理", type: "PENDING" },
			COMPLETED: { key: "COMPLETED", label: "完成", type: "SUCCESS" },
			INPROGRESS: { key: "INPROGRESS", label: "處理中", type: "SUCCESS" },
		},
		targetType: {
			activity: "ACTIVITY",
			parent: "PARENT",
			comment: "COMMENT",
		},
		type: {
			SEXUAL_CONTENT: 1,
			VIOLENT_OR_REPULSIVE_CONTENT: 2,
			HATEFUL_OR_ABUSIVE_CONTENT: 3,
			HARMFUL_OR_DANGEROUS_ACTS: 4,
			CHILD_ABUSE: 5,
			PROMOTES_TERRORISM: 6,
			SPAM_OR_MISLEADING: 7,
			INFRINGES_MY_RIGHTS: 8,
			CAPTIONS_ISSUE: 9,
		},
	},
	OPTION: {
		type: [
			{ key: "SEXUAL_CONTENT", value: 1 },
			{ key: "VIOLENT_OR_REPULSIVE_CONTENT", value: 2 },
			{ key: "HATEFUL_OR_ABUSIVE_CONTENT", value: 3 },
			{ key: "HARMFUL_OR_DANGEROUS_ACTS", value: 4 },
			{ key: "CHILD_ABUSE", value: 5 },
			{ key: "PROMOTES_TERRORISM", value: 6 },
			{ key: "SPAM_OR_MISLEADING", value: 7 },
			{ key: "INFRINGES_MY_RIGHTS", value: 8 },
		],
		targetType: [
			{ key: "ACTIVITY", value: "動態" },
			{ key: "PARENT", value: "家長" },
			{ key: "COMMENT", value: "評論" },
		],
	},
	postFilterLabel: {
		REVIEWED: "已審閱",
		NOTREVIEW: "未審閱",
		DELETED: "已移除",
	},
	CHAT_MESSAGE_QUOTA: 5,
	MINIMUM_QUESTION_LENGTH: 10,
	AI_MODEL: [
		{ key: "gemini-1.5-flash-latest", value: "Gemini" },
		{ key: "gpt-3.5-turbo", value: "GPT-3.5" },
		{ key: "gpt-4o-mini", value: "GPT-4" },
	],
};
