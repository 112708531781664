import axios from "axios";

export const tokenService = {
	getTokenInfo,
};

const httpOptions = {
	headers: {
		"Content-Type": "application/json",
	},
	withCredentials: true,
};

async function getTokenInfo(token) {
	return axios({
		url: `${process.env.REACT_APP_BACKEND_URL}/token/${token}`,
		// url: `http://127.0.0.1:5000/token/${token}`,
		...httpOptions,
		method: "GET",
	})
		.then(handleResponse)
		.catch(handleError);
}

function handleError(err) {
	const msg = (err.response && err.response.data.message) || err.message || err;
	throw { error: msg };
}

function handleResponse(res) {
	return res.data;
}
