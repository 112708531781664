import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import styled from "styled-components";

import { WarningButton, TextField } from "components/general";
import { toast } from "react-toastify";
import { validatePassword } from "_util/_helper";

export const AdminSetPasswordForm = (props) => {
	const { message, handleSetPassword, handleOnBack } = props;

	const [isSuccess, setIsSuccess] = useState(false);

	const [formData, setFormData] = useState({
		password: "",
		confirmPassword: "",
	});

	const { setPasswordSuccess } = useSelector(({ admin }) => ({
		setPasswordSuccess: admin.setPasswordSuccess,
	}));

	useEffect(() => {
		if (setPasswordSuccess) {
			setIsSuccess(true);
		}
	}, [setPasswordSuccess]);

	const handleChange = ({ key, value }) => {
		setFormData({ ...formData, [key]: value });
	};

	const handleOnClick = () => {
		if (!formData.password) {
			toast.error("請填寫密碼");
			return;
		}
		if (!formData.confirmPassword) {
			toast.error("請填寫確認密碼");
			return;
		}

		if (formData.password !== formData.confirmPassword) {
			toast.error("密碼和確認密碼必須一致");
			return;
		}
		const message = validatePassword(formData.password);
		if (message) {
			toast.error(message);
			return;
		}
		handleSetPassword(formData.password);
	};

	return (
		<Container>
			{isSuccess ? (
				<InnerContainer>
					<Block>
						<div style={{ marginTop: "20px", marginBottom: "20px" }}>
							<Message>
								<span>密碼已重置</span>
							</Message>
						</div>
						<WarningButton style={{ width: "30%" }} label={"返回"} handleOnClick={handleOnBack} />
					</Block>
				</InnerContainer>
			) : (
				<InnerContainer>
					<Message>
						<span>{message}</span>
					</Message>
					<VerticalLine />
					<TextFieldLabel>密碼</TextFieldLabel>
					<TextField
						placeholder="密碼"
						style={{ backgroundColor: "white" }}
						value={formData.password}
						name="password"
						handleChange={handleChange}
						autoComplete={"password"}
						type="password"
					/>
					<div style={{ marginTop: "20px", marginBottom: "20px" }} />
					<TextFieldLabel>確認密碼</TextFieldLabel>
					<TextField
						placeholder="確認密碼"
						style={{ backgroundColor: "white" }}
						value={formData.confirmPassword}
						name="confirmPassword"
						handleChange={handleChange}
						type="password"
					/>
					<Block>
						<WarningButton style={{ width: "30%" }} label={"提交"} handleOnClick={handleOnClick} />
					</Block>
				</InnerContainer>
			)}
		</Container>
	);
};

AdminSetPasswordForm.propTypes = {
	message: PropTypes.string,
	handleSetPassword: PropTypes.func,
	handleOnBack: PropTypes.func,
};

const Container = styled.div`
	height: 60%;
	width: 50%;
	background-color: white;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	z-index: 10;
	overflow: auto;
`;

const InnerContainer = styled.div`
	width: 80%;
	height: 80%;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	display: flex;
	flex-direction: column;
	justify-content: center; /* align horizontal */
	align-items: center;
`;

const Message = styled.div`
	width: 100%;
	color: black;
	font-size: 20px;
	line-height: 1.5;
	text-align: center;
	display: flex;
	flex-direction: column;
	justify-content: center; /* align horizontal */
	align-items: center;
`;

const TextFieldLabel = styled.div`
	color: black;
	font-size: 16px;
	line-height: 1.5;
	margin-bottom: 10px;
	font-weight: bold;
`;

const VerticalLine = styled.div`
	width: 100%;
	border-bottom: 1px solid black;
	margin-top: 20px;
	margin-bottom: 20px;
`;

const Block = styled.div`
	flex: 1;
	margin-top: 20px;
	width: 100%;
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
`;
