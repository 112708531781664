import { useState } from "react";

/**
 * Stores the sate in the browser history,
 * making the state reusable across refreshes, navigation
 * and even closing and reopening the window!
 *
 * Codesandbox's preview pane does not preserve history state,
 * so make sure to open the preview in a new page
 *
 * @param key The key to store it in history
 * @param defaultTo A default value if nothing exists in history
 */
export function useHistoryState(key, defaultTo) {
	const [state, setState] = useState(() => {
		const value = window.history.state && window.history.state[key];
		return typeof value != "undefined" ? value : defaultTo;
	});
	function replace(value) {
		window.history.replaceState({ ...window.history.state, [key]: value }, document.title);
		setState(value);
	}
	return [state, replace];
}
