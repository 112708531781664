export const adminConstants = {
	type: {
		SET_PASSWORD_REQUEST: "SET_PASSWORD_REQUEST",
		SET_PASSWORD_FAILURE: "SET_PASSWORD_FAILURE",
		SET_PASSWORD_SUCCESS: "SET_PASSWORD_SUCCESS",
		RESET_PASSWORD_SUCCESS: "RESET_PASSWORD_SUCCESS",
		RESET_ADMIN_SUCCESS: "RESET_ADMIN_SUCCESS",

		LOGIN_REQUEST: "LOGIN_REQUEST",
		LOGIN_FAILURE: "LOGIN_FAILURE",
		LOGIN_SUCCESS: "LOGIN_SUCCESS",

		FETCH_ADMIN_LIST_REQUEST: "FETCH_ADMIN_LIST_REQUEST",
		FETCH_ADMIN_LIST_FAILURE: "FETCH_ADMIN_LIST_FAILURE",
		FETCH_ADMIN_LIST_SUCCESS: "FETCH_ADMIN_LIST_SUCCESS",

		FETCH_ADMIN_REQUEST: "FETCH_ADMIN_REQUEST",
		FETCH_ADMIN_FAILURE: "FETCH_ADMIN_FAILURE",
		FETCH_ADMIN_SUCCESS: "FETCH_ADMIN_SUCCESS",

		CREATE_ADMIN_REQUEST: "CREATE_ADMIN_REQUEST",
		CREATE_ADMIN_FAILURE: "CREATE_ADMIN_FAILURE",
		CREATE_ADMIN_SUCCESS: "CREATE_ADMIN_SUCCESS",

		UPDATE_ADMIN_REQUEST: "UPDATE_ADMIN_REQUEST",
		UPDATE_ADMIN_FAILURE: "UPDATE_ADMIN_FAILURE",
		UPDATE_ADMIN_SUCCESS: "UPDATE_ADMIN_SUCCESS",

		UPDATE_SEARCH_KEY_AND_WORD: "UPDATE_SEARCH_KEY_AND_WORD",
	},
	TABLE_COLUMN: [
		{ key: "status", value: "狀態", searchable: false, width: "5%" },
		{ key: "username", value: "用戶名稱", searchable: true, width: "20%" },
		{ key: "email", value: "電郵", searchable: true, width: "20%" },
		{ key: "lastLoginTime", value: "最後登入時間", searchable: false, width: "10%" },
		{ key: "createdDate", value: "開戶時間", searchable: false, width: "10%" },
	],
};
