import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import backgroundImage from "resources/img/admin_loginbg.jpg";

import { AuthContainer, ForgetPasswordForm } from "components/auth";

import { adminAction } from "actions";

import { Loading } from "components/general";

export const LoginPage = () => {
	const { isFetching } = useSelector(({ admin }) => ({
		isFetching: admin.isFetching,
	}));

	const [isLoading, setIsLoading] = useState(false);
	const [isForgetPW, setIsForgetPw] = useState(false);

	useEffect(() => {
		setIsLoading(isFetching);
	}, [isFetching]);

	const dispatch = useDispatch();

	const handleLogin = (form) => {
		dispatch(adminAction.login(form));
	};

	const handleForgetPassword = async (form) => {
		const result = await adminAction.forgetPassword(form);
		if (result) {
			setIsForgetPw(false);
		}
	};
	return (
		<Container>
			{!!isLoading && <Loading />}
			{isForgetPW ? (
				<ForgetPasswordForm setIsForgetPw={setIsForgetPw} handleForgetPassword={handleForgetPassword} />
			) : (
				<AuthContainer handleLogin={handleLogin} setIsForgetPw={setIsForgetPw} />
			)}
			<BackgroundImage />
		</Container>
	);
};

const Container = styled.div`
	height: 100vh;
	width: 100vw;
`;

const BackgroundImage = styled.div`
	height: 100%;
	width: 100%;
	position: fixed;
	background-repeat: no-repeat;
	background-size: cover;
	background-image: url(${backgroundImage});
`;
