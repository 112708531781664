import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { Loading, Status } from "components/general";
import { generalConstants, messageConstants, schoolConstants, schoolDataConstants } from "_constants";
import { messageAction, schoolAction } from "actions";
import { useLocation } from "react-router-dom";
import { history, getMsgStatus } from "_util/_helper";
import Paper from "@material-ui/core/Paper";
import _ from "lodash";
import { ArrowDropDown, ArrowDropUp, Done } from "@material-ui/icons";
import moment from "moment";
import CheckIcon from "@material-ui/icons/Check";
import editIcon from "resources/img/school_inner_pencil.svg";
import IconButton from "@material-ui/core/IconButton";

import { AdminMenubar, AdminUserInfo, AdminSectionbar, AdminFooter } from "components/admin";
import { Pagination } from "components/general";
import { PageContainer, LeftContainer, RightContainer, TableRow, TableTh, THButton, TableTd, PaginationContainer } from "components/layout";

import { ReactComponent as NewMsgIcon } from "resources/img/newmsg.svg";

import ProgressiveRejectDebouncer from "_util/debouncers/ProgressiveRejectDebouncer";

const FROM_ORGANIZATION = "organization";
const DEFAULT_PAGE = 1;
const DEFAULT_LIMIT = 10;
export const SchoolDetailScreen = () => {
	const location = useLocation();
	const [key, setSchoolKey] = useState(location?.state?.schoolKey);
	const [prevPage, setPrevPage] = useState(location?.state?.from);

	const [debouncer] = useState(() => new ProgressiveRejectDebouncer(1 * 1000));

	useEffect(() => {
		setPrevPage(location?.state?.from);
		setSchoolKey(location?.state?.schoolKey);

		return () => {
			dispatch({ type: schoolConstants.type.CLEAR_SCHOOL_BADGE_CACHE });
		};
	}, [location?.state]);

	const {
		isFetching,
		school,
		schoolBadgeCache,
		msgList,
		totalItems,
		currentPage,
		numOfPage,
		messageSortingKey,
		messageSortingDirection,
		organizationObj,
	} = useSelector(({ school, message, organization }) => {
		const res = {
			isFetching: school.isFetching,
			school: school.school,
			schoolBadgeCache: school.imageCache,
			msgList: message?.navigationObject?.school?.msgList,
			totalItems: message?.navigationObject?.school?.totalItems,
			currentPage: message?.navigationObject?.school?.currentPage,
			numOfPage: message?.navigationObject?.school?.numOfPage,
			messageSortingKey: message?.navigationObject?.school?.sortingKey,
			messageSortingDirection: message?.navigationObject?.school?.sortingDirection,
		};
		if (prevPage === FROM_ORGANIZATION) res.organizationObj = organization.organization;
		return res;
	});
	const [sortingKey, setSortingKey] = useState(messageSortingKey);
	const [sortingDirection, setSortingDirection] = useState(messageSortingDirection);

	const initSearchMsgForm = {
		msg: {
			infoType: "SCHOOL",
		},
		school: { key },
		limit: DEFAULT_LIMIT,
		page: currentPage,
		sort: `${sortingKey}:${sortingDirection}`,
	};

	const [searchMsgForm, setSearchMsgForm] = useState(initSearchMsgForm);

	const baseURL = `${process.env.REACT_APP_SCHOOL_BADGE_LINK}`;

	useEffect(() => {
		if (key) {
			dispatch(
				schoolAction.getSchoolByKey(key, {
					...searchMsgForm,
					includeMessage: true,
					page: school ? (school && school._key != key ? DEFAULT_PAGE : currentPage) : DEFAULT_PAGE,
				})
			);
		}
	}, [key]);

	useEffect(() => {
		setSearchMsgForm({
			...searchMsgForm,
			page: school ? (school && school._key != key ? DEFAULT_PAGE : currentPage) : DEFAULT_PAGE,
		});
	}, [school]);

	const nocachet = useRef(Date.now());

	const [isLoading, setIsLoading] = useState(false);
	const [label, setLabel] = useState("");
	const [overview, setOverview] = useState();
	const [gender, setGender] = useState();
	const [religion, setReligion] = useState();
	const [linkMode, setLinkMode] = useState();

	const [level, setLevel] = useState();
	const [district, setDistrict] = useState();
	const [financeType, setFinanceType] = useState();
	const [session, setSession] = useState();
	const [schoolNet, setSchoolNet] = useState();
	const [category, setCategory] = useState();

	const dispatch = useDispatch();

	useEffect(() => {
		setIsLoading(isFetching);
	}, [isFetching]);

	useEffect(() => {
		if (school.name) {
			setLabel(school.name[0]);
		} else {
			setLabel("");
		}
		let tmpstr = "";
		try {
			setLevel(schoolConstants.level[school.level].label);
		} catch (e) {
			setLevel("");
		}
		try {
			setCategory(schoolConstants.OPTION.category[school.level].filter((a) => a.key === school.category)[0].value);
		} catch (e) {
			setCategory("");
		}
		try {
			setDistrict(schoolConstants.district[school.district].label);
		} catch (e) {
			setDistrict("");
		}
		try {
			setFinanceType(schoolConstants.OPTION.financeType[school.level].filter((a) => a.key === school.financeType)[0].value);
		} catch (e) {
			setFinanceType("");
		}
		if (school.session) {
			let tcount = 0;
			school.session.forEach((s) => {
				try {
					tmpstr = tmpstr.concat((tcount > 0 ? "+" : "") + schoolConstants.session[s].label);
					tcount++;
				} catch (e) {
					tmpstr = tmpstr.concat("");
				}
			});
			setSession(tmpstr);
		}
		setSchoolNet(school.schoolNet);
		try {
			setGender(schoolConstants.gender[school.gender].label);
		} catch (e) {
			setGender("");
		}

		try {
			setReligion(schoolConstants.religion[school.religion].label);
		} catch (e) {
			setReligion("");
		}
		if (school.linkMode) {
			setLinkMode(school.linkMode);
		} else {
			setLinkMode("");
		}
	}, [school]);

	useEffect(() => {
		let str = "";

		if (level) {
			str = str.concat(level);
		}

		if (district) {
			if (str.length > 0) str = str.concat(" / ");
			str = str.concat(district);
		}

		if (financeType) {
			if (str.length > 0) str = str.concat(" / ");
			str = str.concat(financeType);
		}

		if (schoolNet) {
			if (str.length > 0) str = str.concat(" / ");
			str = str.concat(`${schoolNet}校網`);
		}

		if (gender) {
			if (str.length > 0) str = str.concat(" / ");
			str = str.concat(gender);
		}

		setOverview(str);
	}, [level, district, financeType, schoolNet, gender]);

	const getCategory = (item) => {
		try {
			if (_.isEmpty(item.category)) return "";
			return "[" + messageConstants.Translate.category[item.category] + "] ";
		} catch (e) {
			return "";
		}
	};

	const handleSortAction = (sort) => {
		if (!debouncer.token()) {
			return;
		}
		let direction = "DESC";
		if (sortingKey === sort && sortingDirection === "DESC") {
			direction = "ASC";
		}
		setSortingKey(sort);
		setSortingDirection(direction);
		setSearchMsgForm({ ...searchMsgForm, page: DEFAULT_PAGE, sort: `${sort}:${direction}` });
		dispatch(messageAction.fetchMessages({ ...searchMsgForm, page: DEFAULT_PAGE, sort: `${sort}:${direction}` }, "school"));
	};

	const renderTH = () => {
		return messageConstants.SCHOOL_MSG_TABLE_COLUMN.map((column) => {
			return (
				<TableTh key={`${column.key}_table_header`} width={column.width}>
					<THButton key={`${column.key}_table_header`} type="button" onClick={() => handleSortAction(column.key)}>
						<div style={{ textAlign: column.key === "status" ? "center" : "auto" }}>
							{column.value}
							{sortingKey === column.key ? (
								sortingDirection === "ASC" ? (
									<ArrowDropUp fontSize={"small"} style={{ verticalAlign: "middle" }} />
								) : (
									<ArrowDropDown fontSize={"small"} style={{ verticalAlign: "middle" }} />
								)
							) : null}
						</div>
					</THButton>
				</TableTh>
			);
		});
	};
	const renderTableHeader = renderTH();

	const renderTD = () => {
		if (msgList && msgList?.length > 0) {
			return msgList?.map((school, index) => {
				const tempObj = {};
				messageConstants.SCHOOL_MSG_TABLE_COLUMN.forEach((column) => {
					tempObj[column.key] = school[column.key];
				});

				return (
					<TableRow
						key={`${school._id}_${index}_tr`}
						onClick={() => {
							HandleRowOnClick(school);
						}}
					>
						{Object.keys(tempObj).map((columnName, index) => {
							if (columnName === "status") {
								return (
									<TableTd key={`${school._id}_${columnName}_${index}_td`} style={{ verticalAlign: "middle" }}>
										<Status status={getMsgStatus(school)} />
									</TableTd>
								);
							} else if (columnName === "type" || columnName === "infoType" || columnName === "targetAudience") {
								return (
									<TableTd key={`${school._id}_${columnName}_${index}_td`}>
										{messageConstants.Translate[columnName][tempObj[columnName]]}
									</TableTd>
								);
							} else if (columnName === "title") {
								return (
									<TableTd key={`${school._id}_${columnName}_${index}_td`}>
										<TitleText>
											{getCategory(school)}
											{tempObj[columnName]}
										</TitleText>
									</TableTd>
								);
							} else if (columnName === "scheduleTime") {
								return (
									<TableTd style={{ whiteSpace: "nowrap" }} key={`${school._id}_${columnName}_${index}_td`}>
										{tempObj[columnName] ? moment(tempObj[columnName]).format("YYYY年M月DD日 hh:mmA") : "-"}
									</TableTd>
								);
							} else if (columnName === "fired") {
								return <TableTd key={`${school._id}_${columnName}_${index}_td`}>{tempObj[columnName] ? <Done /> : null}</TableTd>;
							}
							return <TableTd key={`${school._id}_${columnName}_${index}_td`}>{tempObj[columnName]}</TableTd>;
						})}
					</TableRow>
				);
			});
		}
	};
	const renderTableCell = renderTD();

	const HandleRowOnClick = (item) => {
		history.push({
			pathname:
				prevPage === FROM_ORGANIZATION
					? `${generalConstants.PATH.ORGANIZATION}/school/message/edit`
					: `${generalConstants.PATH.SCHOOL_DETAIL}/message/edit`,
			state: { from: prevPage, school, schoolKey: school._key, msgKey: item._key },
		});
	};

	const handleNewMsg = () => {
		history.push(
			prevPage === FROM_ORGANIZATION
				? `${generalConstants.PATH.ORGANIZATION}/school/message/add`
				: `${generalConstants.PATH.SCHOOL_DETAIL}/message/add`,
			{
				from: prevPage,
				schoolKey: school._key,
				message: {
					infoType:
						school._id === `school/${schoolDataConstants.specialSchoolId}`
							? messageConstants.DATA.infoType.EB
							: messageConstants.DATA.infoType.SCHOOL,
					schoolId: school._id || "",
					schoolName: school.name[0] || school.name[1] || "",
					schoolLevel: school.level || "",
					schoolSession: school.session ? school.session[0] : "",
					schoolDistrict: school.district || "",
					schoolVipOnly: school.vipOnly || false,
				},
			}
		);
	};

	const HandleEditButtonOnClick = () => {
		history.push({
			pathname: prevPage === FROM_ORGANIZATION ? `${generalConstants.PATH.ORGANIZATION}/school/edit` : generalConstants.PATH.EDIT_SCHOOL,
			state: { from: prevPage, schoolKey: school._key },
		});
	};

	const handleMsgPageChange = (pageNum) => {
		setSearchMsgForm({ ...searchMsgForm, page: pageNum });
		dispatch(messageAction.fetchMessages({ ...searchMsgForm, page: pageNum }, "school"));
	};

	const getLocationPath = () => {
		const pathArray = [];

		pathArray.push({ title: "主頁面", isAction: true, target: generalConstants.PATH.DASHBOARD });
		pathArray.push({
			title:
				generalConstants.TAB_NAME[
					prevPage === FROM_ORGANIZATION
						? generalConstants.NAV_TAB.ORGANIZATION_MANAGEMENT.key
						: generalConstants.NAV_TAB.SCHOOL_MANAGEMENT.key
				],
			isAction: true,
			target: prevPage === FROM_ORGANIZATION ? generalConstants.PATH.ORGANIZATION_MANAGEMENT : generalConstants.PATH.SCHOOL_MANAGEMENT,
		});
		if (prevPage === FROM_ORGANIZATION && organizationObj) {
			pathArray.push({
				title: organizationObj.name,
				isAction: true,
				target: generalConstants.PATH.ORGANIZATION,
				stateObj: { from: prevPage, organizationKey: organizationObj._key },
			});
		}
		pathArray.push({ title: label, isAction: false, target: "" });

		return pathArray;
	};

	if (!key) {
		return null;
	}
	return (
		<>
			{!!isLoading && <Loading />}
			<PageContainer>
				<LeftContainer>
					<AdminMenubar
						section={
							prevPage === FROM_ORGANIZATION
								? generalConstants.NAV_TAB.ORGANIZATION_MANAGEMENT.key
								: generalConstants.NAV_TAB.SCHOOL_MANAGEMENT.key
						}
					/>
				</LeftContainer>
				<RightContainer>
					<AdminUserInfo />
					<RowInBlock style={{ paddingLeft: "20px", paddingRight: "20px" }}>
						<LeftPageContainer>
							<AdminSectionbar
								title={generalConstants.NAV_TAB.SCHOOL_DETAIL.key}
								sublabel={`訊息數量: ${totalItems}`}
								label={
									<OverviewContainer marginBottom={"0px"}>
										{school.vipOnly && (
											<VipIconView>
												<VipText>VIP</VipText>
											</VipIconView>
										)}
										{label}
									</OverviewContainer>
								}
								buttonLabel={"發送新訊息"}
								buttonIcon={<NewMsgIcon />}
								handleOnClick={handleNewMsg}
								locationPathArray={getLocationPath()}
							/>
							<Paper elevation={3}>
								<RowInBlock marginBottom={"0px"}>
									<ContentLeft>
										<table style={{ width: "100%", borderCollapse: "collapse", tableLayout: "fixed" }}>
											<thead>
												<tr>{renderTableHeader}</tr>
											</thead>
											<tbody>{renderTableCell}</tbody>
										</table>
										{msgList && msgList?.length > 0 && (
											<PaginationContainer style={{ paddingLeft: "20px" }}>
												<Pagination count={numOfPage} page={currentPage} handlePageChange={handleMsgPageChange} />
											</PaginationContainer>
										)}
									</ContentLeft>
									<ContentRight>
										<Block>
											<TextFieldLabel>本年度訂閱人數</TextFieldLabel>
											<RowInBlock>
												<CurrentRankText>
													{school.currentYearSubscription || school.currentYearSubscription === 0 ? school.currentYearSubscription : "-"}
												</CurrentRankText>
											</RowInBlock>
											<SubLabel>總訂閱人數</SubLabel>
											<RowInBlock>
												<OverallRankText>
													{school.overallSubscription || school.overallSubscription === 0 ? school.overallSubscription : "-"}
												</OverallRankText>
											</RowInBlock>
										</Block>
										<Block>
											<TextFieldLabel>本年度用戶排名</TextFieldLabel>
											<RowInBlock>
												<CurrentRankText>
													{school.currentYearRank || school.currentYearRank === 0 ? school.currentYearRank : "-"}
												</CurrentRankText>
											</RowInBlock>
											<SubLabel>總用戶排名</SubLabel>
											<RowInBlock>
												<OverallRankText>{school.overallRank || school.overallRank === 0 ? school.overallRank : "-"}</OverallRankText>
											</RowInBlock>
										</Block>

										<Block style={{ borderBottom: "none" }}>
											<TextFieldLabel>新聞發佈</TextFieldLabel>
											<RowInBlock>
												<CurrentRankText>{school.numMsg}</CurrentRankText>
											</RowInBlock>
										</Block>
									</ContentRight>
								</RowInBlock>
							</Paper>
						</LeftPageContainer>
						<ContentRight style={{ padding: 0, marginTop: 20, marginLeft: 20 }}>
							<Paper elevation={3}>
								<SchoolInfoContainer>
									<IconButton aria-label="編輯" onClick={HandleEditButtonOnClick} style={{ float: "right", padding: "5px" }}>
										<img alt="" src={editIcon} />
									</IconButton>
									<TextFieldLabel>- 學校資料</TextFieldLabel>

									{school._key && (
										<img
											src={schoolBadgeCache !== "" ? schoolBadgeCache : `${baseURL}${school._key}.jpg?t=${nocachet.current}`}
											style={{ width: "80px" }}
											alt=""
											onError={(e) => (e.target.style.visibility = "hidden")}
										/>
									)}

									{school.status === schoolConstants.status.CLOSED.key && <ClosedBadge>已停辦</ClosedBadge>}

									{school.name && school.name[0] && <SchoolNameLabel>{school.name[0]}</SchoolNameLabel>}
									{school.name && school.name[1] && <SchoolNameLabel>{school.name[1]}</SchoolNameLabel>}
									<OverviewContainer>
										{school.vipOnly && (
											<VipIconView marginRight={"5px"}>
												<VipText>VIP</VipText>
											</VipIconView>
										)}
										<OverviewLabel>{overview}</OverviewLabel>
									</OverviewContainer>

									{school.address && school.address[0] && (
										<SmallRowInBlock>
											<SchoolSmalllDetaiLabel style={{ flex: 3 }}>地址</SchoolSmalllDetaiLabel>
											<SchoolSmalllDetaiLabel style={{ flex: 1 }}>:</SchoolSmalllDetaiLabel>
											{school.latitude && school.longitude ? (
												<SchoolSmalllDetaiLabel style={{ flex: 17 }}>
													<a target="_black" href={`https://map.google.com/?q=${school.latitude},${school.longitude}`}>
														{school.address[0]}
													</a>
												</SchoolSmalllDetaiLabel>
											) : (
												<SchoolSmalllDetaiLabel style={{ flex: 17 }}>{school.address[0]}</SchoolSmalllDetaiLabel>
											)}
										</SmallRowInBlock>
									)}
									{school.address && school.address[1] && (
										<SmallRowInBlock>
											<SchoolSmalllDetaiLabel style={{ flex: 3 }}></SchoolSmalllDetaiLabel>
											<SchoolSmalllDetaiLabel style={{ flex: 1 }}></SchoolSmalllDetaiLabel>
											{school.latitude && school.longitude ? (
												<SchoolSmalllDetaiLabel style={{ flex: 17 }}>
													<a target="_black" href={`https://map.google.com/?q=${school.latitude},${school.longitude}`}>
														{school.address[1]}
													</a>
												</SchoolSmalllDetaiLabel>
											) : (
												<SchoolSmalllDetaiLabel style={{ flex: 17 }}>{school.address[1]}</SchoolSmalllDetaiLabel>
											)}
										</SmallRowInBlock>
									)}
									{school.telNo && (
										<SmallRowInBlock>
											<SchoolSmalllDetaiLabel style={{ flex: 3 }}>電話</SchoolSmalllDetaiLabel>
											<SchoolSmalllDetaiLabel style={{ flex: 1 }}>:</SchoolSmalllDetaiLabel>
											<SchoolSmalllDetaiLabel style={{ flex: 17 }}>{school.telNo}</SchoolSmalllDetaiLabel>
										</SmallRowInBlock>
									)}
									{school.faxNo && (
										<SmallRowInBlock>
											<SchoolSmalllDetaiLabel style={{ flex: 3 }}>傳真</SchoolSmalllDetaiLabel>
											<SchoolSmalllDetaiLabel style={{ flex: 1 }}>:</SchoolSmalllDetaiLabel>
											<SchoolSmalllDetaiLabel style={{ flex: 17 }}>{school.faxNo}</SchoolSmalllDetaiLabel>
										</SmallRowInBlock>
									)}
									{school.website && (
										<SmallRowInBlock>
											<SchoolSmalllDetaiLabel style={{ flex: 3 }}>網址</SchoolSmalllDetaiLabel>
											<SchoolSmalllDetaiLabel style={{ flex: 1 }}>:</SchoolSmalllDetaiLabel>
											<SchoolSmalllDetaiLabel style={{ flex: 17 }}>
												<a
													target="_black"
													href={
														school.website.includes("http://") || school.website.includes("https://")
															? school.website
															: `http://${school.website}`
													}
												>
													{school.website}
												</a>
											</SchoolSmalllDetaiLabel>
										</SmallRowInBlock>
									)}
									<div style={{ height: "16px" }}></div>
									{school.category && (
										<ThinRowInBlock>
											<DetailTitleLabel>學校類別</DetailTitleLabel>
											<DetailMiddle>:</DetailMiddle>
											<DetailLabel>{category}</DetailLabel>
										</ThinRowInBlock>
									)}
									{gender && (
										<ThinRowInBlock>
											<DetailTitleLabel>學生性別</DetailTitleLabel>
											<DetailMiddle> :</DetailMiddle>
											<DetailLabel>{gender}</DetailLabel>
										</ThinRowInBlock>
									)}

									{school.session && (
										<ThinRowInBlock>
											<DetailTitleLabel>上課時間</DetailTitleLabel>
											<DetailMiddle>:</DetailMiddle>
											<DetailLabel>{session}</DetailLabel>
										</ThinRowInBlock>
									)}

									{school.financeType && (
										<ThinRowInBlock>
											<DetailTitleLabel>財政類型</DetailTitleLabel>
											<DetailMiddle>:</DetailMiddle>
											<DetailLabel>{financeType}</DetailLabel>
										</ThinRowInBlock>
									)}

									{religion && school._key !== schoolDataConstants.specialSchoolId && (
										<ThinRowInBlock>
											<DetailTitleLabel>宗教</DetailTitleLabel>
											<DetailMiddle>:</DetailMiddle>
											<DetailLabel>{religion}</DetailLabel>
										</ThinRowInBlock>
									)}
									{school.level === "PRIMARY" && (
										<div>
											<ThinRowInBlock>
												<DetailTitleLabel>一條龍中學</DetailTitleLabel>
												<DetailMiddle>:</DetailMiddle>

												{linkMode === "THROUGH-TRAIN" ? (
													<DetailLabel>
														<CheckIcon style={{ height: "20px" }} />
													</DetailLabel>
												) : (
													<DetailLabel>-</DetailLabel>
												)}
											</ThinRowInBlock>
											<ThinRowInBlock>
												<DetailTitleLabel>直屬中學</DetailTitleLabel>
												<DetailMiddle>:</DetailMiddle>
												{linkMode === "FEEDER" ? (
													<DetailLabel>
														<CheckIcon style={{ height: "20px" }} />
													</DetailLabel>
												) : (
													<DetailLabel>-</DetailLabel>
												)}
											</ThinRowInBlock>
											<ThinRowInBlock>
												<DetailTitleLabel>聯繫中學</DetailTitleLabel>
												<DetailMiddle>:</DetailMiddle>
												{linkMode === "NOMINATED" ? (
													<DetailLabel>
														<CheckIcon style={{ height: "20px" }} />
													</DetailLabel>
												) : (
													<DetailLabel>-</DetailLabel>
												)}
											</ThinRowInBlock>
										</div>
									)}
									{school.level !== "PRIMARY" && school.level !== "SECONDARY" && school._key !== schoolDataConstants.specialSchoolId && (
										<ThinRowInBlock>
											<DetailTitleLabel>幼稚園教育計劃</DetailTitleLabel>
											<DetailMiddle>:</DetailMiddle>
											{school.kes && school.kes === "Y" ? (
												<DetailLabel>
													<CheckIcon style={{ height: "20px" }} />
												</DetailLabel>
											) : (
												<DetailLabel>-</DetailLabel>
											)}
										</ThinRowInBlock>
									)}
								</SchoolInfoContainer>
							</Paper>
						</ContentRight>
					</RowInBlock>
					<AdminFooter></AdminFooter>
				</RightContainer>
			</PageContainer>
		</>
	);
};

const RowInBlock = styled.div`
	display: flex;
	margin-bottom: ${(props) => props.marginBottom || "10px"};
	flex-direction: row;
`;

const ThinRowInBlock = styled.div`
	display: flex;
	flex-direction: row;
	margin-bottom: 4px;
	margin-top: 4px;
	border-bottom: 1px solid #ccc;
`;

const SmallRowInBlock = styled.div`
	display: flex;
	flex-direction: row;
	margin-bottom: 4px;
	margin-top: 4px;
`;

const ContentLeft = styled.div`
	flex: 18;
	min-height: 600px;
	border-right: 1px solid #dddddd;
`;

const LeftPageContainer = styled.div`
	flex: 9;
`;

const ContentRight = styled.div`
	flex: 3;
	padding: 20px;
`;

const SchoolInfoContainer = styled.div`
	flex: 1;
	min-height: 610px;
	margin-left: 20px;
	margin-right: 20px;
	padding-top: 20px;
	padding-bottom: 20px;
`;

const Block = styled.div`
	min-height: ${(props) => props.height || "75px"};
	flex-direction: column;
	border-bottom: 1px solid #dddddd;
	padding: 16px 0;
`;

const TextFieldLabel = styled.div`
	color: #333399;
	font-size: 14px;
	line-height: 1.5;
	font-weight: bold;
	margin-bottom: 0px;
`;

const CurrentRankText = styled.div`
	color: #333399;
	font-size: 38px;
	font-weight: 900;
	font-family: "SF Pro Display";
	font-stretch: normal;
	font-style: normal;
	line-height: 1.18;
	letter-spacing: normal;
	margin-bottom: 3px;
`;

const OverallRankText = styled.div`
	font-size: 18px;
	font-weight: 900;
	font-stretch: normal;
	font-style: normal;
	font-family: "SF Pro Display";
	line-height: 1.17;
	letter-spacing: normal;
	text-align: right;
	color: #333333;
	margin: 0;
`;
const SubLabel = styled.div`
	font-size: 12px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.5;
	letter-spacing: normal;
	text-align: left;
	color: #000000;
`;

const TitleText = styled.span`
	color: #0085b5;
	text-decoration: underline;
`;

const SchoolNameLabel = styled.div`
	color: #000000;
	font-size: 14px;
	line-height: 1;
	font-weight: bold;
	margin-bottom: 4px;
`;

const SchoolSmalllDetaiLabel = styled.div`
	color: #666666;
	font-size: 11px;
	font-weight: 500;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.55;
	letter-spacing: normal;
`;

const DetailTitleLabel = styled.div`
	flex: 12;
	color: #000000;
	vertical-align: middle;
	font-size: 11px;
	font-weight: 500;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.55;
	letter-spacing: normal;
	margin: 4px 4px 5px 0;
`;

const DetailMiddle = styled.div`
	flex: 1;
	text-align: center;
	vertical-align: middle;
	margin: 4px 5px 5px 3px;
	font-size: 11px;
	font-weight: 500;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.55;
	letter-spacing: normal;
	color: #000000;
`;

const DetailLabel = styled.div`
	flex: 16;
	color: #333333;
	padding-left: 5px;
	vertical-align: middle;
	margin: 4px 5px 5px 5px;
	font-size: 11px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.55;
	letter-spacing: normal;
`;

const ClosedBadge = styled.span`
	margin: 8px 0 40px 5px;
	padding: 2px 6px 3px;
	border-radius: 3px;
	background-color: #fff0f2;
	font-size: 10px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.5;
	letter-spacing: normal;
	color: #ff0000;
	float: right;
`;

const OverviewContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-bottom: ${(props) => props.marginBottom || "14px"};
`;

const OverviewLabel = styled.div`
	color: #666666;
	font-size: 12px;
`;

const VipIconView = styled.div`
	display: flex;
	width: 32px;
	flex-direction: row;
	margin-right: ${(props) => props.marginRight || "20px"};
`;

const VipText = styled.div`
	font-size: 12;
	font-weight: bold;
	color: #333333;
	background-color: #c8ae63;
	padding-left: 5px;
	padding-right: 5px;
`;
