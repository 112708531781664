import { authConstants, generalConstants } from "_constants";
import { toast } from "react-toastify";
import { history } from "_util/_helper";
import { adminService } from "services";

export const authAction = {
	kickout,
	logout,
	changePassword,
};

function changePassword(data) {
	return async (dispatch) => {
		dispatch(request());
		try {
			await adminService.changePassword(data);
			toast.success("修改密碼成功");
			dispatch(success());
		} catch (error) {
			if (error.error) {
				toast.error(error.error);
			} else {
				toast.error("無法連接到服務器");
			}
			dispatch(fail());
		}
	};

	function request() {
		return {
			type: authConstants.type.CHANGE_PASSWORD_REQUEST,
		};
	}
	function success() {
		return {
			type: authConstants.type.CHANGE_PASSWORD_SUCCESS,
		};
	}
	function fail() {
		return {
			type: authConstants.type.CHANGE_PASSWORD_FAIL,
		};
	}
}

function kickout() {
	return (dispatch) => {
		dispatch(kick());
		history.push(generalConstants.PATH.LOGIN);
		toast.error("連接逾時，請重新登入");
	};

	function kick() {
		return { type: authConstants.type.UNAUTHORIZED_ERROR };
	}
}
function logout() {
	return (dispatch) => {
		adminService.logout().then(
			() => {
				dispatch(logout());
				history.push(generalConstants.PATH.LOGIN);
				toast.success("用戶已登出");
			},
			(error) => {
				toast.error(error.error);
			}
		);
	};

	function logout() {
		return { type: authConstants.type.LOGOUT };
	}
}
