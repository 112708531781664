import { socialNetworkConstants, generalConstants } from "_constants";
import { socialNetworkService } from "services";
import { toast } from "react-toastify";
import { history } from "_util/_helper";

export const socialNetworkAction = {
	getPost,
	getPosts,
	reviewPost,
	deletePost,
};

function getPosts(form) {
	return (dispatch) => {
		dispatch(request());
		socialNetworkService.getPosts(form).then(
			(response) => {
				dispatch(success(response, form));
			},
			(error) => {
				toast.error(error.error);
				dispatch(failure());
			}
		);
	};

	function request() {
		return { type: socialNetworkConstants.type.FETCH_POST_LIST_REQUEST };
	}
	function failure() {
		return { type: socialNetworkConstants.type.FETCH_POST_LIST_FAILURE };
	}
	function success(response, inputData) {
		const { items, totalItems } = response;
		const { searchWord, sort, p, reviewed, notReview } = inputData;
		return {
			type: socialNetworkConstants.type.FETCH_POST_LIST_SUCCESS,
			totalNum: totalItems,
			totalPage: totalItems / 10 === parseInt(totalItems / 10) ? parseInt(totalItems / 10) : parseInt(totalItems / 10) + 1,
			currentPage: p,
			postList: items,
			searchWord: searchWord,
			sortingKey: sort?.split(":")[0],
			sortingDirection: sort?.split(":")[1],
			reviewedFilter: reviewed ?? null,
			notReviewFilter: notReview ?? null,
		};
	}
}

function getPost(key) {
	return (dispatch) => {
		dispatch(request());
		socialNetworkService.getPost(key).then(
			(response) => {
				dispatch(success(response));
			},
			(error) => {
				toast.error(error.error);
				dispatch(failure());
				if (error.error?.status !== 401) {
					history.push(generalConstants.PATH.POST_MANAGEMENT);
				}
			}
		);
	};

	function request() {
		return { type: socialNetworkConstants.type.FETCH_POST_TARGET_REQUEST };
	}
	function failure() {
		return { type: socialNetworkConstants.type.FETCH_POST_TARGET_FAILURE };
	}
	function success(post) {
		return {
			type: socialNetworkConstants.type.FETCH_POST_TARGET_SUCCESS,
			post,
		};
	}
}

function reviewPost(data) {
	return (dispatch) => {
		dispatch(request());
		socialNetworkService.reviewPost(data).then(
			() => {
				dispatch(success());
			},
			(error) => {
				toast.error(error.error);
				dispatch(failure());
			}
		);
	};

	function request() {
		return { type: socialNetworkConstants.type.REVIEW_POST_REQUEST };
	}
	function failure() {
		return { type: socialNetworkConstants.type.REVIEW_POST_FAILURE };
	}
	function success() {
		return { type: socialNetworkConstants.type.REVIEW_POST_SUCCESS };
	}
}

function deletePost(data) {
	return (dispatch) => {
		dispatch(request());
		socialNetworkService.deletePost(data).then(
			() => {
				dispatch(success());
			},
			(error) => {
				toast.error(error.error);
				dispatch(failure());
			}
		);
	};

	function request() {
		return { type: socialNetworkConstants.type.DELETE_POST_REQUEST };
	}
	function failure() {
		return { type: socialNetworkConstants.type.DELETE_POST_FAILURE };
	}
	function success() {
		return { type: socialNetworkConstants.type.DELETE_POST_SUCCESS };
	}
}
