import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { Avatar } from "@material-ui/core";
import { useSelector } from "react-redux";
import { authAction } from "actions";
import Button from "@material-ui/core/Button";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import { Link } from "react-router-dom";

import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { generalConstants } from "_constants";

export const AdminUserInfo = () => {
	const { user } = useSelector(({ auth }) => ({
		user: auth.user,
		loggedIn: auth.loggedIn,
	}));

	const dispatch = useDispatch();
	const [open, setOpen] = useState(false);
	const anchorRef = useRef(null);

	const handleLogout = () => {
		dispatch(authAction.logout());
	};

	const handleToggle = () => {
		setOpen((prevOpen) => !prevOpen);
	};

	const handleChangePassword = () => {
		setOpen(false);
	};

	const handleClose = (event) => {
		if (anchorRef.current && anchorRef.current.contains(event.target)) {
			return;
		}
		setOpen(false);
	};

	function handleListKeyDown(event) {
		if (event.key === "Tab") {
			event.preventDefault();
			setOpen(false);
		}
	}

	return (
		<Container>
			<UserInfoLayer>
				<RowInBlock style={{ justifyContent: "flex-end" }}>
					<LogoutContainer>
						<Button ref={anchorRef} aria-controls={open ? "menu-list-grow" : undefined} aria-haspopup="true" onClick={handleToggle}>
							<Avatar alt={user.username} style={{ width: "32px", height: "32px" }} />
							<UserName>{user.username}</UserName>
							{open && <ExpandLessIcon />}
							{!open && <ExpandMoreIcon />}
						</Button>
						<Popper style={{ zIndex: 2, marginRight: "10px" }} open={open} anchorEl={anchorRef.current} role={undefined} transition>
							{({ TransitionProps, placement }) => (
								<Grow {...TransitionProps} style={{ transformOrigin: placement === "bottom" ? "center top" : "center bottom" }}>
									<Paper>
										<ClickAwayListener onClickAway={handleClose}>
											<PopOverMenu onKeyDown={handleListKeyDown}>
												<PopMenuItem>
													<ItemBlock>
														<Avatar alt={user.username} style={{ width: "58px", height: "58px", margin: "auto" }} />
														<MenuItemUserName>{user.username}</MenuItemUserName>
													</ItemBlock>
												</PopMenuItem>
												<PopMenuItem>
													<Link style={{ textDecoration: "none" }} to={generalConstants.PATH.CHANGE_PASSWORD}>
														<ItemButton href="" onClick={handleChangePassword}>
															更改密碼
														</ItemButton>
													</Link>
												</PopMenuItem>
												<PopMenuItem>
													<ItemButton href="" onClick={handleLogout}>
														登出
													</ItemButton>
												</PopMenuItem>
											</PopOverMenu>
										</ClickAwayListener>
									</Paper>
								</Grow>
							)}
						</Popper>
					</LogoutContainer>
				</RowInBlock>
			</UserInfoLayer>
		</Container>
	);
};

AdminUserInfo.propTypes = {
	title: PropTypes.string,
	label: PropTypes.string,
	sublabel: PropTypes.string,
	buttonLabel: PropTypes.string,
	handleOnClick: PropTypes.func,
};
const Container = styled.div`
	width: 100%;
	padding-bottom: 1px;
	height: 48px;
`;

const UserInfoLayer = styled.div`
	width: 100%;
	height: 100%;
	background-color: #fafafa;
	border-bottom: 1px #dddddd solid;
`;

const RowInBlock = styled.div`
	height: 100%;
	display: flex;
	flex-direction: row;
`;

const LogoutContainer = styled.div`
	height: 100%;
	display: flex;
	flex-direction: row;
	min-width: 100px;
	width: 10%;
	max-width: 120px;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	font-size: 12px;
	margin-right: 30px;
`;

const UserName = styled.span`
	padding: 0 5px 0 8px;
`;

const MenuItemUserName = styled.div`
	font-size: 13px;
	font-weight: 500;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.15;
	letter-spacing: normal;
	color: #000000;
	margin: 5px 0;
`;

const PopOverMenu = styled.div`
	width: 180px;
`;

const PopMenuItem = styled.div`
	fontsize: 13px;

	border-bottom: #dddddd 1px solid;
	text-align: center;
`;
const ItemBlock = styled.div`
	padding: 11px;
`;

const ItemButton = styled.div`
	display: block;
	padding: 11px;
	text-decoration: none;
	color: #fd5c5c;
	cursor: pointer;
	&:hover {
		background-color: #eeeeee;
	}
`;
