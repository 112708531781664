import { authConstants, coinConstants } from "_constants";

const initialState = {
	isFetching: false,
	isFetchError: false,
	navigationObject: {
		root: {
			userKey: "",
			coinBalance: 0,
			searchWord: "",
			sortingKey: "transactionDate",
			sortingDirection: "DESC",
			numOfPage: 1,
			currentPage: 1,
			totalItems: 0,
			historyList: [],
		},
	},
};

const coinReducer = (state = initialState, action) => {
	switch (action.type) {
		case authConstants.type.UNAUTHORIZED_ERROR: {
			let navigationObject = initialState.navigationObject;
			if (typeof state.navigationObject?.root != "undefined") {
				navigationObject = {
					...state.navigationObject,
					root: { ...state.navigationObject?.root, currentPage: 1 },
				};
			}
			return {
				...state,
				navigationObject: navigationObject,
			};
		}
		case coinConstants.type.FETCH_COIN_HISTORY_LIST_REQUEST: {
			return {
				...state,
				isFetching: true,
				isFetchError: false,
			};
		}
		case coinConstants.type.FETCH_COIN_HISTORY_LIST_FAILURE: {
			return {
				...state,
				isFetching: false,
				isFetchError: true,
			};
		}
		case coinConstants.type.FETCH_COIN_HISTORY_LIST_SUCCESS: {
			const navigationObject = {
				userKey: action.userKey,
				coinBalance: action.coinBalance,
				searchWord: action.searchWord,
				sortingKey: action.sortingKey,
				sortingDirection: action.sortingDirection,
				totalItems: action.totalNum,
				numOfPage: action.totalPage,
				currentPage: action.currentPage,
				historyList: action.historyList,
			};
			return {
				...state,
				isFetching: false,
				isFetchError: false,
				navigationObject: {
					...state.navigationObject,
					[`${action.navigationKey}`]: {
						...navigationObject,
					},
				},
			};
		}
		default: {
			return state;
		}
	}
};

export default coinReducer;
