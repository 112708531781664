/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useState, useEffect } from "react";
import { Prompt, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { generalConstants, missionConstants } from "_constants";
import { missionAction } from "actions";
import { history } from "_util/_helper";
import _ from "lodash";
import { SubmitButton, CancelButton, Loading, Select, TextField, Checkbox } from "components/general";
import { toast } from "react-toastify";

import { AdminMenubar, AdminUserInfo, AdminSectionbar, AdminFooter } from "components/admin";
import { PageContainer, LeftContainer, RightContainer, ContentContainer } from "components/layout";

import { MuiPickersUtilsProvider, KeyboardTimePicker, KeyboardDatePicker } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";

export const EditMissionPage = () => {
	const location = useLocation();

	const [prevPage, setPrevPage] = useState(location?.state?.from);
	const [cloneMission, setCloneMission] = useState(location?.state?.cloneMission);
	const [key, setMissionKey] = useState(location?.state?.missionKey);

	const loggedIn = useSelector(({ auth }) => auth.loggedIn);

	const isFetchError = useSelector(({ mission }) => mission.isFetchError);
	const isFetching = useSelector(({ mission }) => mission.isFetching);
	const createMissionSuccess = useSelector(({ mission }) => mission.createMissionSuccess);
	const updateMissionSuccess = useSelector(({ mission }) => mission.updateMissionSuccess);
	const mission = useSelector(({ mission }) => {
		return key ? mission.mission : null;
	});

	const getRoundedDate = (minutes, d = new Date()) => {
		let ms = 1000 * 60 * minutes;
		let roundedDate = new Date(Math.ceil(d.getTime() / ms) * ms);

		return roundedDate;
	};

	const initForm = {
		key: "",
		rev: "",
		category: missionConstants.OPTION.category[0].key,
		action: missionConstants.OPTION.action[missionConstants.OPTION.category[0].key][0].key,
		foreignId: "",
		recurrence: missionConstants.OPTION.recurrence[0].key,
		description: "",
		instruction: "",
		effectiveFrom: null,
		effectiveTo: null,
		duration: 0,
		coin: 0,
		min: 0,
		max: 0,
		enableForeignId: false,
		enableEffectiveFrom: false,
		enableEffectiveTo: false,
		status: missionConstants.OPTION.status[0].key,
	};

	const [formData, setFormData] = useState(initForm);
	const [isLoading, setIsLoading] = useState(false);
	const [isChanged, setIsChanged] = useState(false);
	const [isSaved, setIsSaved] = useState(false);

	const dispatch = useDispatch();

	useEffect(() => {
		setPrevPage(location?.state?.from);
		setCloneMission(location?.state?.cloneMission);
		setMissionKey(location?.state?.missionKey);
	}, [location?.state]);

	useEffect(() => {
		if (key) {
			dispatch(missionAction.getMissionByKey(key));
		}
	}, [key]);

	useEffect(() => {
		if (isFetchError) {
			dispatch({ type: missionConstants.type.RESET_MISSION_SUCCESS });
			history.push({
				pathname: `${generalConstants.PATH.MISSION_MANAGEMENT}`,
			});
		}
	}, [isFetchError]);

	useEffect(() => {
		if (mission || cloneMission) {
			const currentMission = cloneMission ? cloneMission : mission ? mission : null;
			if (currentMission != null) {
				const data = {
					key: currentMission._key,
					rev: currentMission._rev,
					category: currentMission.category,
					action: currentMission.action,
					foreignId: currentMission?.foreignId?.split("/")[1],
					enableForeignId: currentMission?.foreignId?.split("/")[1] != undefined,
					recurrence: currentMission.recurrence,
					description: currentMission.description,
					instruction: currentMission.instruction,
					duration: currentMission.duration,
					coin: currentMission.coin,
					max: currentMission.max,
					min: currentMission.min,
					status: currentMission.status,
					enableEffectiveFrom: !_.isEmpty(currentMission.effectiveFrom),
					effectiveFrom: currentMission.effectiveFrom || null,
					enableEffectiveTo: !_.isEmpty(currentMission.effectiveTo),
					effectiveTo: currentMission.effectiveTo || null,
				};
				setFormData(data);
				setIsChanged(!_.isEmpty(cloneMission));
			}
		}
	}, [mission, cloneMission]);

	useEffect(() => {
		setIsLoading(isFetching);
	}, [isFetching]);

	useEffect(() => {
		if (updateMissionSuccess) {
			if (!isSaved) {
				setIsSaved(true);
				return;
			}
			dispatch({ type: missionConstants.type.RESET_MISSION_SUCCESS });
			toast.success("已成功更新");
			handleReplace();
		}
	}, [updateMissionSuccess, isSaved, dispatch]);

	useEffect(() => {
		if (createMissionSuccess) {
			if (!isSaved) {
				setIsSaved(true);
				return;
			}
			dispatch({ type: missionConstants.type.RESET_MISSION_SUCCESS });
			toast.success("已成功新增");
			handleReplace();
		}
	}, [createMissionSuccess, isSaved, dispatch]);

	const handleReplace = () => {
		history.replace({
			pathname: key && !cloneMission ? generalConstants.PATH.MISSION : generalConstants.PATH.MISSION_MANAGEMENT,
			state:
				key && !cloneMission
					? {
							from: prevPage,
							missionKey: key,
					  }
					: null,
		});
	};

	const handleChange = ({ key, value }) => {
		let tempData = { ...formData };
		tempData[key] = value;
		if (key === "category") {
			tempData["action"] = missionConstants.OPTION.action[value][0].key;
			tempData["foreignId"] = "";
			tempData["enableForeignId"] = false;
			if (
				missionConstants.OPTION.action[value][0].key != missionConstants.action.READ &&
				missionConstants.OPTION.action[value][0].key != missionConstants.action.SEARCH
			) {
				tempData["duration"] = "";
			}
		} else if (key === "action") {
			tempData["foreignId"] = "";
			tempData["enableForeignId"] = false;
			if (
				!(
					(formData.category === missionConstants.category.POST.key &&
						(value === missionConstants.action.ADD || value === missionConstants.action.COMMENT)) ||
					value === missionConstants.action.SHARE ||
					value === missionConstants.action.SUBSCRIBE ||
					value === missionConstants.action.BOOKMARK
				)
			) {
				tempData["min"] = "";
				tempData["max"] = "";
			}
			if (value != missionConstants.action.READ && value != missionConstants.action.SEARCH) {
				tempData["duration"] = "";
			}
		} else if (key === "enableForeignId") {
			tempData[key] = !formData.enableForeignId;
			if (formData.enableForeignId) {
				tempData[`foreignId`] = "";
			}
			if (
				(formData.action === missionConstants.action.SUBSCRIBE || formData.action === missionConstants.action.BOOKMARK) &&
				!formData.enableForeignId
			) {
				tempData["min"] = "";
				tempData["max"] = "";
			}
		} else if (key === "enableEffectiveFrom") {
			tempData[key] = !formData.enableEffectiveFrom;
			tempData["effectiveFrom"] = !formData.enableEffectiveFrom
				? cloneMission?.effectiveFrom
					? cloneMission.effectiveFrom
					: mission?.effectiveFrom
					? mission.effectiveFrom
					: getRoundedDate(30, new Date())
				: null;
		} else if (key === "enableEffectiveTo") {
			tempData[key] = !formData.enableEffectiveTo;
			tempData["effectiveTo"] = !formData.enableEffectiveTo
				? cloneMission?.effectiveTo
					? cloneMission.effectiveTo
					: mission?.effectiveTo
					? mission.effectiveTo
					: getRoundedDate(90, new Date())
				: null;
		}
		setFormData(tempData);
		setIsChanged(true);
	};

	const handleBack = () => {
		history.goBack();
	};

	const handleSubmit = () => {
		if (!formData.description) {
			toast.error("請填寫任務內容");
			return;
		} else if (!formData.instruction) {
			toast.error("請填寫任務達成條件");
			return;
		} else if (!(Number.isInteger(Number(formData.coin)) && Number(formData.coin) > 0)) {
			toast.error("任務報酬輸入有誤");
			return;
		} else if (!_.isEmpty(formData.min) && !(Number.isInteger(Number(formData.min)) && Number(formData.min) > 0)) {
			toast.error("下限輸入有誤");
			return;
		} else if (!_.isEmpty(formData.max) && !(Number.isInteger(Number(formData.max)) && Number(formData.max) > 0)) {
			toast.error("上限輸入有誤");
			return;
		}

		var data = {
			category: formData.category,
			action: formData.action,
			recurrence:
				getRecurrence(formData.category, formData.action, formData.enableForeignId) != missionConstants.recurrence.ALL
					? getRecurrence(formData.category, formData.action, formData.enableForeignId)
					: formData.recurrence,
			description: formData.description,
			instruction: formData.instruction,
			coin: parseInt(formData.coin) || 0,
			max: parseInt(formData.max) || 0,
			min: parseInt(formData.min) || 0,
			status: formData.status,
			effectiveFrom: formData.enableEffectiveFrom ? new Date(formData.effectiveFrom) : "",
			effectiveTo: formData.enableEffectiveTo ? new Date(formData.effectiveTo) : "",
		};
		if (formData.action === missionConstants.action.READ || formData.action === missionConstants.action.SEARCH) {
			const duration = parseInt(formData.duration) || "";
			if (!duration) {
				toast.error("請填寫任務進行時間下限");
				return;
			} else if (!(Number.isInteger(Number(formData.duration)) && Number(formData.duration) > 0)) {
				toast.error("任務進行時間輸入有誤");
				return;
			}
			data = { ...data, duration };
		} else if (
			!formData.enableForeignId &&
			(formData.action === missionConstants.action.SUBSCRIBE || formData.action === missionConstants.action.BOOKMARK) &&
			parseInt(formData.min || 0) <= 0
		) {
			toast.error("請填寫項目達成數下限");
			return;
		}

		if (formData.enableForeignId && !formData.foreignId) {
			toast.error("指定ID輸入有誤");
			return;
		} else if (formData.foreignId) {
			data = { ...data, foreignId: missionConstants.category[formData.category].link + formData.foreignId };
		}
		if (key && !cloneMission) {
			data = { ...data, key: formData.key, rev: formData.rev };
			dispatch(missionAction.updateMission(data));
		} else {
			dispatch(missionAction.createMission(data));
		}
	};

	const getRecurrence = (category, action, enableForeignId) => {
		if (category === missionConstants.category.MISSION.key && action === missionConstants.action.CHECKIN) {
			return missionConstants.recurrence.DAILY.key;
		} else if (
			(category === missionConstants.category.CHILD.key && action === missionConstants.action.UPDATE) ||
			(category === missionConstants.category.PROFILE.key && action === missionConstants.action.UPDATE) ||
			(category === missionConstants.category.PUSH.key && action === missionConstants.action.ENABLE) ||
			(category === missionConstants.category.COURSE.key && action === missionConstants.action.BOOKMARK) ||
			(category === missionConstants.category.SCHOOL.key && action === missionConstants.action.SUBSCRIBE) ||
			(category === missionConstants.category.SOCIALNETWORK.key && action === missionConstants.action.JOIN) ||
			(category === missionConstants.category.USER.key && action === missionConstants.action.FOLLOW) ||
			(category === missionConstants.category.SOCIALEVENT.key && action === missionConstants.action.TRACK && enableForeignId) ||
			(category === missionConstants.category.SOCIALEVENT.key && action === missionConstants.action.JOIN && enableForeignId) ||
			(category === missionConstants.category.POST.key && action === missionConstants.action.LIKE && enableForeignId)
		) {
			return missionConstants.recurrence.NONE.key;
		} else {
			return missionConstants.recurrence.ALL;
		}
	};

	const getLocationPath = () => {
		const pathArray = [];

		pathArray.push({ title: "主頁面", isAction: true, target: generalConstants.PATH.DASHBOARD });
		pathArray.push({
			title: generalConstants.TAB_NAME[generalConstants.NAV_TAB.MISSION_MANAGEMENT.key],
			isAction: true,
			target: generalConstants.PATH.MISSION_MANAGEMENT,
		});
		pathArray.push({
			title:
				generalConstants.TAB_NAME[
					key && !cloneMission ? generalConstants.NAV_TAB.EDIT_MISSION.key : generalConstants.NAV_TAB.CREATE_MISSION.key
				],
			isAction: false,
			target: "",
		});
		return pathArray;
	};

	if ((!key && location.pathname.includes("/edit")) || (!prevPage && location.pathname.includes("/add"))) {
		return null;
	}
	return (
		<>
			{!!isLoading && <Loading />}
			<Prompt when={isChanged && !isSaved && loggedIn} message="你有未儲存的變更，確定不儲存下離開？" />
			<PageContainer>
				<LeftContainer>
					<AdminMenubar section={generalConstants.NAV_TAB.MISSION_MANAGEMENT.key} />
				</LeftContainer>
				<RightContainer>
					<AdminUserInfo />
					<AdminSectionbar
						title={key && !cloneMission ? generalConstants.NAV_TAB.EDIT_MISSION.key : generalConstants.NAV_TAB.CREATE_MISSION.key}
						locationPathArray={getLocationPath()}
					/>
					<ContentContainer style={{ display: "flex", flexDirection: "row", padding: "0px" }}>
						<ContentLeft>
							<Block>
								<TextFieldLabel>內容</TextFieldLabel>
								<TextField
									placeholder="內容"
									multiline
									rows={6}
									value={formData.description || ""}
									name="description"
									handleChange={handleChange}
								/>
							</Block>
							<Block>
								<TextFieldLabel>達成條件</TextFieldLabel>
								<TextField
									placeholder="達成條件"
									multiline
									rows={6}
									value={formData.instruction || ""}
									name="instruction"
									handleChange={handleChange}
								/>
							</Block>
							<Block>
								<TextFieldLabel>類型</TextFieldLabel>
								<>
									<Select
										name={"category"}
										options={missionConstants.OPTION.category}
										value={formData.category}
										handleChange={handleChange}
									/>
								</>
							</Block>
							<Block>
								<TextFieldLabel>行動要求</TextFieldLabel>
								<>
									<Select
										name={"action"}
										options={missionConstants.OPTION.action[`${formData.category}`]}
										value={formData.action}
										handleChange={handleChange}
									/>
								</>
							</Block>
							<Block>
								<TextFieldLabel>報酬</TextFieldLabel>
								<BlockGroup style={{ alignItems: "center" }}>
									<TextField
										style={{ width: "20%" }}
										placeholder="報酬"
										value={`${formData.coin || ""}`}
										name="coin"
										handleChange={handleChange}
									/>
									<TextFieldLabel style={{ marginTop: 8, marginLeft: 5 }}>KOT幣</TextFieldLabel>
								</BlockGroup>
							</Block>
						</ContentLeft>
						<LineRight />
						<ContentLeft>
							{formData.category != missionConstants.category.POST &&
								formData.action != missionConstants.action.ADD &&
								missionConstants.OPTION.foreignIdMission.indexOf(formData.category) > -1 &&
								formData.action != missionConstants.action.SEARCH && (
									<Block>
										<BlockGroup style={{ alignItems: "center" }}>
											<TextFieldLabel>指定ID</TextFieldLabel>
											<div style={{ marginLeft: 10, marginBottom: 8 }}>
												<>
													<Checkbox name={"enableForeignId"} checked={formData.enableForeignId} handleChange={handleChange} />
												</>
											</div>
										</BlockGroup>
										<TextField
											placeholder="指定ID"
											value={formData.foreignId || ""}
											name="foreignId"
											handleChange={handleChange}
											disabled={!formData.enableForeignId}
										/>
									</Block>
								)}
							{getRecurrence(formData.category, formData.action, formData.enableForeignId) === missionConstants.recurrence.ALL && (
								<Block>
									<TextFieldLabel>重置設定</TextFieldLabel>
									<>
										<Select
											name={"recurrence"}
											options={missionConstants.OPTION.recurrence}
											value={formData.recurrence}
											handleChange={handleChange}
										/>
									</>
								</Block>
							)}
							{(formData.action === missionConstants.action.READ || formData.action === missionConstants.action.SEARCH) && (
								<Block>
									<TextFieldLabel>進行時間</TextFieldLabel>
									<BlockGroup style={{ alignItems: "center" }}>
										<TextField
											style={{ width: "20%" }}
											placeholder="進行時間"
											value={`${formData.duration || ""}`}
											name="duration"
											handleChange={handleChange}
										/>
										<TextFieldLabel style={{ marginTop: 8, marginLeft: 5 }}>秒</TextFieldLabel>
									</BlockGroup>
								</Block>
							)}
							{((formData.category === missionConstants.category.POST.key &&
								(formData.action === missionConstants.action.ADD || formData.action === missionConstants.action.COMMENT)) ||
								formData.action === missionConstants.action.SHARE ||
								(!formData.enableForeignId &&
									(formData.action === missionConstants.action.SUBSCRIBE || formData.action === missionConstants.action.BOOKMARK))) && (
								<Block style={{ justifyContent: "center" }}>
									<TextFieldLabel>
										{formData.action === missionConstants.action.SUBSCRIBE || formData.action === missionConstants.action.BOOKMARK
											? "至少達成"
											: "至少提及"}
									</TextFieldLabel>
									<BlockGroup style={{ alignItems: "center" }}>
										<TextField
											style={{ width: "20%" }}
											placeholder="下限"
											value={`${formData.min || ""}`}
											name="min"
											handleChange={handleChange}
										/>
										<TextFieldLabel style={{ marginTop: 8, marginLeft: 5 }}>
											{formData.action === missionConstants.action.SUBSCRIBE || formData.action === missionConstants.action.BOOKMARK
												? "個"
												: "人"}
										</TextFieldLabel>
										{formData.action != missionConstants.action.SUBSCRIBE && formData.action != missionConstants.action.BOOKMARK && (
											<>
												<TextFieldLabel style={{ marginTop: 8, marginLeft: 30, marginRight: 30 }}>～</TextFieldLabel>
												<TextField
													style={{ width: "20%" }}
													placeholder="上限"
													value={`${formData.max || ""}`}
													name="max"
													handleChange={handleChange}
												/>
												<TextFieldLabel style={{ marginTop: 8, marginLeft: 5 }}>人</TextFieldLabel>
											</>
										)}
									</BlockGroup>
								</Block>
							)}
							<Block>
								<BlockGroup style={{ alignItems: "center" }}>
									<TextFieldLabel>開始時間</TextFieldLabel>
									<div style={{ marginLeft: 10, marginBottom: 5 }}>
										<>
											<Checkbox name={"enableEffectiveFrom"} checked={formData.enableEffectiveFrom} handleChange={handleChange} />
										</>
									</div>
								</BlockGroup>
								<TimeRowInBlock>
									<MuiPickersUtilsProvider utils={MomentUtils}>
										<KeyboardDatePicker
											margin="dense"
											id="date-picker-startTime"
											format="DD/MM/YYYY"
											value={formData.effectiveFrom}
											inputVariant="outlined"
											onChange={(value) => {
												handleChange({ key: "effectiveFrom", value });
											}}
											style={{ marginRight: "10px" }}
											disabled={!formData.enableEffectiveFrom}
										/>
										<KeyboardTimePicker
											margin="dense"
											id="time-picker-startTime"
											value={formData.effectiveFrom}
											inputVariant="outlined"
											onChange={(value) => {
												handleChange({ key: "effectiveFrom", value });
											}}
											disabled={!formData.enableEffectiveFrom}
										/>
									</MuiPickersUtilsProvider>
								</TimeRowInBlock>
								<BlockGroup style={{ alignItems: "center" }}>
									<TextFieldLabel>結束時間</TextFieldLabel>
									<div style={{ marginLeft: 10, marginBottom: 8 }}>
										<>
											<Checkbox name={"enableEffectiveTo"} checked={formData.enableEffectiveTo} handleChange={handleChange} />
										</>
									</div>
								</BlockGroup>
								<TimeRowInBlock>
									<MuiPickersUtilsProvider utils={MomentUtils}>
										<KeyboardDatePicker
											margin="dense"
											id="date-picker-endTime"
											format="DD/MM/YYYY"
											value={formData.effectiveTo}
											inputVariant="outlined"
											onChange={(value) => {
												handleChange({ key: "effectiveTo", value });
											}}
											style={{ marginRight: "10px" }}
											disabled={!formData.enableEffectiveTo}
										/>
										<KeyboardTimePicker
											margin="dense"
											id="time-picker-endTime"
											value={formData.effectiveTo}
											inputVariant="outlined"
											onChange={(value) => {
												handleChange({ key: "effectiveTo", value });
											}}
											disabled={!formData.enableEffectiveTo}
										/>
									</MuiPickersUtilsProvider>
								</TimeRowInBlock>
							</Block>
							<Block>
								<TextFieldLabel>狀態</TextFieldLabel>
								<>
									<Select name={"status"} options={missionConstants.OPTION.status} value={formData.status} handleChange={handleChange} />
								</>
							</Block>
							<RowInBlock style={{ justifyContent: "flex-end" }}>
								<CancelButton label={"取消"} style={{ marginRight: "15px" }} handleOnClick={handleBack} />
								<SubmitButton
									label={"確認"}
									style={{ marginRight: "15px" }}
									handleOnClick={handleSubmit}
									active={isChanged && !isFetching}
								/>
							</RowInBlock>
						</ContentLeft>
					</ContentContainer>
					<AdminFooter></AdminFooter>
				</RightContainer>
			</PageContainer>
		</>
	);
};

const RowInBlock = styled.div`
	padding-top: 30px;
	display: flex;
	flex-direction: row;
`;

const TimeRowInBlock = styled.div`
	display: flex;
	margin-bottom: ${(props) => props.marginBottom || "10px"};
	flex-direction: row;
`;

const ContentLeft = styled.div`
	flex: 1;
	padding: 10px;
`;

const LineRight = styled.div`
	border-right: 1px solid #dddddd;
	margin-top: 20px;
	margin-bottom: 20px;
`;

const BlockGroup = styled.div`
	display: flex;
	flex-direction: row;
`;

const Block = styled.div`
	padding: 10px;
	flex: ${(props) => props.flex || 1};
`;

const TextFieldLabel = styled.div`
	color: #000000;
	font-size: 14px;
	line-height: 1.5;
	font-weight: bold;
	margin-bottom: 7px;
`;
