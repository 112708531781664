export const coinConstants = {
	type: {
		FETCH_COIN_HISTORY_LIST_REQUEST: "FETCH_COIN_HISTORY_LIST_REQUEST",
		FETCH_COIN_HISTORY_LIST_SUCCESS: "FETCH_COIN_HISTORY_LIST_SUCCESS",
		FETCH_COIN_HISTORY_LIST_FAILURE: "FETCH_COIN_HISTORY_LIST_FAILURE",
	},
	TABLE_COLUMN: [
		{ key: "type", value: "類型", searchable: false, width: "5%" },
		{ key: "remark", value: "內容", searchable: false, width: "30%" },
		{ key: "coin", value: "KOT幣", searchable: false, width: "5%" },
		{ key: "qty", value: "數量", searchable: false, width: "5%" },
		{ key: "total", value: "總額", searchable: false, width: "5%" },
		{ key: "transactionDate", value: "交易時間", searchable: false, width: "10%" },
	],
	side: {
		CR: { key: "CR", label: "獲取" },
		DR: { key: "DR", label: "支出" },
	},
	historyType: {
		MISSION: { key: "MISSION", label: "任務" },
		REDEEM: { key: "REDEEM", label: "兌換" },
		REFUND: { key: "REFUND", label: "退回" },
		REFERRAL: { key: "REFERRAL", label: "開戶獎賞" },
	},
};
