import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";

import { parentConstants, generalConstants, statusConstants } from "_constants";
import { parentAction } from "actions";
import _ from "lodash";
import moment from "moment";

import { history } from "_util/_helper";

import { ArrowDropDown, ArrowDropUp } from "@material-ui/icons";

import { Status, Pagination, RefreshButton, SquareCheckbox } from "components/general";
import { StandardSelect, StandardTextField } from "components/general/standard";
import { AdminMenubar, AdminUserInfo, AdminSectionbar, AdminFooter } from "components/admin";
import {
	PageContainer,
	LeftContainer,
	RightContainer,
	ContentContainer,
	SearchBar,
	SearchBarLeft,
	SearchBarRight,
	PaginationContainer,
	TableContainer,
	TableRow,
	TableTh,
	THButton,
	TableTd,
} from "components/layout";

import ProgressiveRejectDebouncer from "_util/debouncers/ProgressiveRejectDebouncer";

import AppleIcon from "@material-ui/icons/Apple";
import { ReactComponent as GoogleIcon } from "resources/img/google.svg";

const DEFAULT_PAGE = 1;
const FROM_PARENT = "parent";
export const ParentManagementPage = () => {
	const { parentList } = useSelector(({ parent }) => ({ parentList: parent.navigationObject?.root?.parentList }));
	const { navigationObject } = useSelector(({ parent }) => ({ navigationObject: parent.navigationObject?.root }));
	const totalItems = useSelector(({ parent }) => parent.navigationObject?.root?.totalItems);
	const numOfPage = useSelector(({ parent }) => parent.navigationObject?.root?.numOfPage);
	const currentPage = useSelector(({ parent }) => parent.navigationObject?.root?.currentPage);

	const [searchKey, setSearchKey] = useState(navigationObject?.searchKey || parentConstants.TABLE_COLUMN[2].key);
	const [searchWord, setSearchWord] = useState(navigationObject?.searchWord || "");
	const [sortingKey, setSortingKey] = useState(navigationObject?.sortingKey || "createdDate");
	const [sortingDirection, setSortingDirection] = useState(navigationObject?.sortingDirection || "DESC");

	const [isReset, setIsReset] = useState(false);

	const [isHovering, setIsHovering] = useState(false);
	const [targetHover, setTargetHover] = useState(null);

	const handleMouseOver = (parent) => {
		setTargetHover(parent);
		setIsHovering(true);
	};

	const handleMouseOut = () => {
		setTargetHover(null);
		setIsHovering(false);
	};

	const dispatch = useDispatch();
	const timeoutRef = useRef(null);
	const [textDebouncer] = useState(() => new ProgressiveRejectDebouncer(0.7 * 1000));
	const [debouncer] = useState(() => new ProgressiveRejectDebouncer(1 * 1000));

	useEffect(() => {
		dispatch(
			parentAction.fetchParent({
				searchKey,
				searchWord,
				page: currentPage,
				sort: `${sortingKey}:${sortingDirection}`,
				notJoinStatus: navigationObject?.notJoinStatus,
				unverifiedStatus: navigationObject?.unverifiedStatus,
				verifiedStatus: navigationObject?.verifiedStatus,
			})
		);
	}, [dispatch]);

	const handleSorting = (key) => {
		if (!debouncer.token()) {
			return;
		}
		let direction = "ASC";
		if (sortingKey === key && sortingDirection === "ASC") {
			direction = "DESC";
		}
		setSortingKey(key);
		setSortingDirection(direction);
		dispatch(
			parentAction.fetchParent({
				searchKey,
				searchWord,
				page: DEFAULT_PAGE,
				sort: `${key}:${direction}`,
				notJoinStatus: navigationObject?.notJoinStatus,
				unverifiedStatus: navigationObject?.unverifiedStatus,
				verifiedStatus: navigationObject?.verifiedStatus,
			})
		);
	};

	const handlePageChange = (pageNum) => {
		if (!debouncer.token() || currentPage === pageNum) {
			return;
		}
		dispatch(
			parentAction.fetchParent({
				searchKey,
				searchWord,
				page: pageNum,
				sort: `${sortingKey}:${sortingDirection}`,
				notJoinStatus: navigationObject?.notJoinStatus,
				unverifiedStatus: navigationObject?.unverifiedStatus,
				verifiedStatus: navigationObject?.verifiedStatus,
			})
		);
	};

	function resetTimeout() {
		if (timeoutRef.current) {
			clearTimeout(timeoutRef.current);
		}
	}

	const handleChange = ({ key, value }) => {
		var targetKey = searchKey;
		var targetWord = searchWord;
		if (key === "searchKey") {
			setSearchKey(value);
			targetKey = value;
		} else if (key === "searchWord") {
			setSearchWord(value);
			targetWord = value;
		}
		if (key === "searchKey" && _.isEmpty(searchWord)) {
			return;
		}
		if (!textDebouncer.token()) {
			resetTimeout();
			timeoutRef.current = setTimeout(() => {
				handleSearch(targetKey, targetWord);
			}, 1000);
			return;
		}
		resetTimeout();
		handleSearch(targetKey, targetWord);
	};

	const handleSearch = (key, value) => {
		dispatch(
			parentAction.fetchParent({
				searchKey: key,
				searchWord: value,
				page: DEFAULT_PAGE,
				sort: `${sortingKey}:${sortingDirection}`,
				notJoinStatus: navigationObject?.notJoinStatus,
				unverifiedStatus: navigationObject?.unverifiedStatus,
				verifiedStatus: navigationObject?.verifiedStatus,
			})
		);
	};

	useEffect(() => {
		if (searchKey === parentConstants.TABLE_COLUMN[2].key && searchWord === "" && isReset) {
			dispatch(
				parentAction.fetchParent({
					searchKey,
					searchWord,
					page: DEFAULT_PAGE,
					sort: `${sortingKey}:${sortingDirection}`,
					notJoinStatus: false,
					unverifiedStatus: false,
					verifiedStatus: false,
				})
			);
			setIsReset(false);
		}
	}, [searchKey, searchWord, isReset, handleSearch]);

	const handleRefreshFilter = () => {
		if (
			searchWord === "" &&
			sortingKey === "createdDate" &&
			sortingDirection === "DESC" &&
			currentPage === DEFAULT_PAGE &&
			!navigationObject?.notJoinStatus &&
			!navigationObject?.unverifiedStatus &&
			!navigationObject?.verifiedStatus
		) {
			return;
		}
		setSearchKey(parentConstants.TABLE_COLUMN[2].key);
		setSearchWord("");
		setSortingKey("createdDate");
		setSortingDirection("DESC");
		setIsReset(true);
	};

	const renderTH = () => {
		return parentConstants.TABLE_COLUMN.map((column) => {
			return (
				<TableTh key={`${column.key}_table_header`} width={column.width}>
					<THButton key={`${column.key}_table_header`} type="button" onClick={() => handleSorting(column.key)}>
						<div style={{ textAlign: column.key === "status" || column.key === "hasSocialAccount" ? "center" : "auto" }}>
							{column.key === "isVip" ? (
								<VipIconView>
									<VipText>{column.value}</VipText>
									{sortingKey === column.key ? (
										sortingDirection === "ASC" ? (
											<ArrowDropUp fontSize={"small"} style={{ verticalAlign: "middle" }} />
										) : (
											<ArrowDropDown fontSize={"small"} style={{ verticalAlign: "middle" }} />
										)
									) : null}
								</VipIconView>
							) : (
								column.value
							)}
							{sortingKey === column.key && column.key != "isVip" ? (
								sortingDirection === "ASC" ? (
									<ArrowDropUp fontSize={"small"} style={{ verticalAlign: "middle" }} />
								) : (
									<ArrowDropDown fontSize={"small"} style={{ verticalAlign: "middle" }} />
								)
							) : null}
						</div>
					</THButton>
				</TableTh>
			);
		});
	};
	const renderTableHeader = renderTH();

	const getTitle = (title) => {
		const maxLength = 50;
		try {
			if (title.length === 0) return "[沒有內容]";
			const tail = title.length > maxLength ? "..." : "";
			return title.substr(0, maxLength) + tail;
		} catch (e) {
			return "[沒有內容]";
		}
	};

	const getStatus = (item) => {
		const status = "PARENT_" + item.status;

		const statusList = statusConstants.status;
		let thekey = "PARENT_ACTIVE";

		for (let k in statusList) {
			if (k === status) {
				thekey = k;
			}
		}
		return item.deleted ? statusConstants.status.PARENT_DELETED.key : statusConstants.status[thekey].key;
	};

	const getSocialStatus = (item) => {
		const hasSocialAccount = item.hasSocialAccount;
		const is_verified = item.is_verified;

		if (hasSocialAccount) {
			return is_verified ? statusConstants.status.SOCIAL_NETWORK_VERIFIED.key : statusConstants.status.SOCIAL_NETWORK_UNVERIFIED.key;
		} else {
			return statusConstants.status.SOCIAL_NETWORK_NONE.key;
		}
	};

	const displaySubscriptionStore = (input) => {
		if (input) {
			if (input.localeCompare(parentConstants.VIP_SUBSCRIPTION_STORE.GOOGLE) === 0) {
				return <GoogleIcon style={{ width: 12, height: 12 }} fill="white" />;
			} else if (input.localeCompare(parentConstants.VIP_SUBSCRIPTION_STORE.APPLE) === 0) {
				return <AppleIcon style={{ width: 15, height: 15, marginTop: 2 }} htmlColor="white" />;
			} else {
				return;
			}
		} else {
			return;
		}
	};

	const getVipSubscriptionPlanName = (plan) => {
		var planName = "";
		if (plan.includes(parentConstants.VIP_SUBSCRIPTION_DURATION.ONE_YEAR)) {
			planName = parentConstants.VIP_SUBSCRIPTION_PLAN_NAME.ONE_YEAR;
		} else if (plan.includes(parentConstants.VIP_SUBSCRIPTION_DURATION.THREE_MONTH)) {
			planName = parentConstants.VIP_SUBSCRIPTION_PLAN_NAME.THREE_MONTH;
		} else if (plan.includes(parentConstants.VIP_SUBSCRIPTION_DURATION.ONE_MONTH)) {
			planName = parentConstants.VIP_SUBSCRIPTION_PLAN_NAME.ONE_MONTH;
		}
		return planName;
	};

	const getVipSubscriptionStatusMessage = (parent) => {
		var vipSubscriptionStatusMessage = "";
		if (parent.vipSubscriptionEffectiveDate && parent.vipSubscriptionStatus === parentConstants.VIP_STATUS.ACTIVE) {
			vipSubscriptionStatusMessage = `VIP已於${moment(parent.vipSubscriptionEffectiveDate).format("YYYY年M月DD日 hh:mmA")}生效`;
		} else if (parent.vipSubscriptionStatus === parentConstants.VIP_STATUS.CANCELLED) {
			if (parent.vipSubscriptionEffectiveDate) {
				vipSubscriptionStatusMessage = `VIP已於${moment(parent.vipSubscriptionEffectiveDate).format("YYYY年M月DD日 hh:mmA")}生效`;
			}
			if (parent.vipSubscriptionExpiryDate) {
				vipSubscriptionStatusMessage += `\nVIP將於${moment(parent.vipSubscriptionExpiryDate).format("YYYY年M月DD日 hh:mmA")}到期`;
			}
		} else if (parent.vipSubscriptionStatus === parentConstants.VIP_STATUS.EXPIRED) {
			if (parent.vipSubscriptionEffectiveDate) {
				vipSubscriptionStatusMessage = `VIP曾於${moment(parent.vipSubscriptionEffectiveDate).format("YYYY年M月DD日 hh:mmA")}生效`;
			}
			if (parent.vipSubscriptionExpiryDate) {
				vipSubscriptionStatusMessage += `\nVIP已於${moment(parent.vipSubscriptionExpiryDate).format("YYYY年M月DD日 hh:mmA")}到期`;
			}
		}
		return vipSubscriptionStatusMessage;
	};

	const HandleRowOnClick = (item, columnName) => {
		if (columnName === "coinBalance") {
			history.push({
				pathname: `${generalConstants.PATH.MISSION_HISTORY}`,
				state: { parentKey: item._key },
			});
		} else {
			history.push({
				pathname: `${generalConstants.PATH.EDIT_PARENT}`,
				state: { from: FROM_PARENT, parentKey: item._key },
			});
		}
	};

	const handleOnChangeCheckbox = ({ key }) => {
		if (!debouncer.token()) {
			return;
		}
		dispatch(
			parentAction.fetchParent({
				searchKey,
				searchWord,
				page: DEFAULT_PAGE,
				sort: `${sortingKey}:${sortingDirection}`,
				notJoinStatus: key === "notJoinStatus" ? !navigationObject?.notJoinStatus : navigationObject?.notJoinStatus,
				unverifiedStatus: key === "unverifiedStatus" ? !navigationObject?.unverifiedStatus : navigationObject?.unverifiedStatus,
				verifiedStatus: key === "verifiedStatus" ? !navigationObject?.verifiedStatus : navigationObject?.verifiedStatus,
			})
		);
	};

	const renderTD = () => {
		if (parentList && parentList.length > 0) {
			return parentList.map((parent, index) => {
				const tempObj = {};
				parentConstants.TABLE_COLUMN.forEach((column) => {
					tempObj[column.key] = parent[column.key];
				});
				return (
					<TableRow key={`${parent._id}_${index}_tr`}>
						{Object.keys(tempObj).map((columnName, index) => {
							if (columnName === "status") {
								return (
									<TableTd
										onClick={() => {
											HandleRowOnClick(parent, columnName);
										}}
										key={`${parent._id}_${columnName}_${index}_td`}
										style={{ verticalAlign: "middle" }}
									>
										<Status status={getStatus(parent)} />
									</TableTd>
								);
							} else if (columnName === "isVip") {
								return (
									<TableTd
										key={`${parent._id}_${columnName}_${index}_td`}
										onClick={() => {
											HandleRowOnClick(parent, columnName);
										}}
									>
										{parent.vipSubscriptionStatus && (
											<VipIconView
												onMouseOver={() => {
													handleMouseOver(parent);
												}}
												onMouseOut={handleMouseOut}
											>
												<VipText
													style={{
														backgroundColor:
															parent.vipSubscriptionStatus === parentConstants.VIP_STATUS.ACTIVE ||
															parent.vipSubscriptionStatus === parentConstants.VIP_STATUS.CANCELLED
																? "#c8ae63"
																: "rgb(153,153,153)",
														color:
															parent.vipSubscriptionStatus === parentConstants.VIP_STATUS.ACTIVE ||
															parent.vipSubscriptionStatus === parentConstants.VIP_STATUS.CANCELLED
																? "#000"
																: "#fff",
													}}
												>
													VIP
													{isHovering && parent === targetHover && !_.isEmpty(getVipSubscriptionStatusMessage(parent)) && (
														<Tooltiptext>
															<div
																style={{
																	display: "flex",
																	flexDirection: "row",
																	alignItems: "center",
																	justifyContent: "center",
																}}
															>
																{getVipSubscriptionPlanName(parent.vipSubscriptionPlanId)}
																<div style={{ marginLeft: "5px" }}>{displaySubscriptionStore(parent.vipSubscriptionStore)}</div>
															</div>
															{getVipSubscriptionStatusMessage(parent)}
														</Tooltiptext>
													)}
												</VipText>
											</VipIconView>
										)}
									</TableTd>
								);
							} else if (columnName === "targetType") {
								return (
									<TableTd
										onClick={() => {
											HandleRowOnClick(parent, columnName);
										}}
										key={`${parent._id}_${columnName}_${index}_td`}
									>
										{parentConstants.Translate[columnName][tempObj[columnName]]}
									</TableTd>
								);
							} else if (columnName === "text") {
								return (
									<TableTd
										onClick={() => {
											HandleRowOnClick(parent, columnName);
										}}
										key={`${parent._id}_${columnName}_${index}_td`}
									>
										<TitleText>{parent._key ? getTitle(tempObj[columnName]) : "[帖子已經不存在]"}</TitleText>
									</TableTd>
								);
							} else if (columnName === "createdDate" || columnName === "lastLoginDateTime") {
								return (
									<TableTd
										onClick={() => {
											HandleRowOnClick(parent, columnName);
										}}
										key={`${parent._id}_${columnName}_${index}_td`}
									>
										{tempObj[columnName] ? moment(tempObj[columnName]).format("YYYY年M月DD日 hh:mmA") : "-"}
									</TableTd>
								);
							} else if (columnName === "hasSocialAccount") {
								return (
									<TableTd
										onClick={() => {
											HandleRowOnClick(parent, columnName);
										}}
										key={`${parent._id}_${columnName}_${index}_td`}
										style={{ verticalAlign: "middle" }}
									>
										<Status status={getSocialStatus(parent)} />
									</TableTd>
								);
							} else if (columnName === "coinBalance") {
								return (
									<TableTd
										onClick={() => {
											HandleRowOnClick(parent, columnName);
										}}
										key={`${parent._id}_${columnName}_${index}_td`}
									>
										<TitleText>{tempObj[columnName]}</TitleText>
									</TableTd>
								);
							}
							return (
								<TableTd
									onClick={() => {
										HandleRowOnClick(parent, columnName);
									}}
									key={`${parent._id}_${columnName}_${index}_td`}
								>
									{tempObj[columnName]}
								</TableTd>
							);
						})}
					</TableRow>
				);
			});
		}
	};
	const renderTableCell = renderTD();

	return (
		<>
			<PageContainer>
				<LeftContainer>
					<AdminMenubar section={generalConstants.NAV_TAB.PARENT_MANAGEMENT.key} />
				</LeftContainer>
				<RightContainer>
					<AdminUserInfo />
					<AdminSectionbar
						title={generalConstants.NAV_TAB.PARENT_MANAGEMENT.key}
						sublabel={`用戶數量: ${totalItems}`}
						locationPathArray={[
							{ title: "主頁面", isAction: true, target: generalConstants.PATH.DASHBOARD },
							{
								title: generalConstants.TAB_NAME[generalConstants.NAV_TAB.PARENT_MANAGEMENT.key],
								isAction: false,
								target: "",
							},
						]}
					/>
					<ContentContainer>
						<SearchBar>
							<SearchBarLeft style={{ flex: 2 }}>
								<RowInBlock marginBottom="0px">
									<Title style={{ lineHeight: "27px" }}>家長搜尋</Title>
									<div style={{ marginLeft: "10px", width: "20%" }}>
										<StandardSelect
											name={"searchKey"}
											options={_.filter(parentConstants.TABLE_COLUMN, { searchable: true })}
											value={searchKey}
											handleChange={handleChange}
										/>
									</div>
									<div style={{ marginLeft: "10px", width: "30%" }}>
										<StandardTextField
											placeholder="關鍵字"
											value={searchWord}
											name="searchWord"
											handleChange={handleChange}
											style={{ borderTopRightRadius: "0px", borderBottomRightRadius: "0px" }}
										/>
									</div>
									<RefreshButton style={{ marginLeft: "5px" }} handleOnClick={handleRefreshFilter} label="重置選項"></RefreshButton>
								</RowInBlock>
							</SearchBarLeft>
							<SearchBarRight style={{ flex: 3 }}>
								<RowInBlock marginBottom="0px">
									<Title style={{ fontSize: "13px", lineHeight: "27px" }}>童行網用戶狀態</Title>
									<div style={{ color: "#ffffff", backgroundColor: "#ff5c5c", paddingTop: 0, paddingBottom: 0, paddingLeft: 5 }}>
										<SquareCheckbox
											key={`notJoinStatus_checkbox`}
											name={"notJoinStatus"}
											label={`未加入`}
											checked={!navigationObject?.notJoinStatus}
											handleChange={handleOnChangeCheckbox}
										/>
									</div>
									<div style={{ color: "#33333", backgroundColor: "#ffd95c", paddingTop: 0, paddingBottom: 0, paddingLeft: 5 }}>
										<SquareCheckbox
											key={`unverifiedStatus_checkbox`}
											name={"unverifiedStatus"}
											label={`未驗証`}
											checked={!navigationObject?.unverifiedStatus}
											handleChange={handleOnChangeCheckbox}
										/>
									</div>
									<div style={{ color: "#33333", backgroundColor: "#5cff82", paddingTop: 0, paddingBottom: 0, paddingLeft: 5 }}>
										<SquareCheckbox
											key={`verifiedStatus_checkbox`}
											name={"verifiedStatus"}
											label={`已驗証`}
											checked={!navigationObject?.verifiedStatus}
											handleChange={handleOnChangeCheckbox}
										/>
									</div>
								</RowInBlock>
							</SearchBarRight>
						</SearchBar>
						<TableContainer>
							<table style={{ width: "100%", borderCollapse: "collapse", tableLayout: "fixed" }}>
								<thead>
									<tr>{renderTableHeader}</tr>
								</thead>
								<tbody>{renderTableCell}</tbody>
							</table>
						</TableContainer>
						<PaginationContainer>
							<Pagination count={numOfPage} page={currentPage} handlePageChange={handlePageChange} />
						</PaginationContainer>
					</ContentContainer>
					<AdminFooter></AdminFooter>
				</RightContainer>
			</PageContainer>
		</>
	);
};

const TitleText = styled.span`
	color: #0085b5;
	text-decoration: underline;
	max-height: 70px;
	display: block;
	overflow: hidden;
	text-overflow: ellipsis;
`;

const RowInBlock = styled.div`
	display: flex;
	margin-bottom: ${(props) => props.marginBottom || "10px"};
	flex-direction: row;
`;

const Title = styled.div`
	font-size: 13px;
	color: black;
	font-weight: bold;
	margin-right: 10px;
`;

const VipIconView = styled.div`
	display: flex;
	width: 32px;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	margin-left: 5px;
`;
const VipText = styled.div`
	font-size: 12;
	font-weight: bold;
	color: #333333;
	background-color: #c8ae63;
	padding-left: 5px;
	padding-right: 5px;
`;

const Tooltiptext = styled.span`
	width: 300px;
	background-color: rgba(0, 0, 0, 0.6);
	color: #fff;
	text-align: center;
	margin-top: 10px;
	padding: 5px 0;
	border-radius: 6px;
	position: absolute;
	z-index: 1;
	white-space: pre-line;
`;
