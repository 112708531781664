export const schoolConstants = {
	type: {
		FETCH_SCHOOL_LIST_REQUEST: "FETCH_SCHOOL_LIST_REQUEST",
		FETCH_SCHOOL_LIST_FAILURE: "FETCH_SCHOOL_LIST_FAILURE",
		FETCH_SCHOOL_LIST_SUCCESS: "FETCH_SCHOOL_LIST_SUCCESS",

		FETCH_SCHOOL_REQUEST: "FETCH_SCHOOL_REQUEST",
		FETCH_SCHOOL_FAILURE: "FETCH_SCHOOL_FAILURE",
		FETCH_SCHOOL_SUCCESS: "FETCH_SCHOOL_SUCCESS",

		CREATE_SCHOOL_REQUEST: "CREATE_SCHOOL_REQUEST",
		CREATE_SCHOOL_FAILURE: "CREATE_SCHOOL_FAILURE",
		CREATE_SCHOOL_SUCCESS: "CREATE_SCHOOL_SUCCESS",

		UPDATE_SCHOOL_REQUEST: "UPDATE_SCHOOL_REQUEST",
		UPDATE_SCHOOL_FAILURE: "UPDATE_SCHOOL_FAILURE",
		UPDATE_SCHOOL_SUCCESS: "UPDATE_SCHOOL_SUCCESS",

		RESET_SCHOOL_SUCCESS: "RESET_SCHOOL_SUCCESS",
		CLEAR_SCHOOL_BADGE_CACHE: "CLEAR_SCHOOL_BADGE_CACHE",

		SCHOOL_FETCH_MSG_LIST_REQUEST: "SCHOOL_FETCH_MSG_LIST_REQUEST",
		SCHOOL_FETCH_MSG_LIST_FAILURE: "SCHOOL_FETCH_MSG_LIST_FAILURE",
		SCHOOL_FETCH_MSG_LIST_SUCCESS: "SCHOOL_FETCH_MSG_LIST_SUCCESS",
	},
	TABLE_COLUMN: [
		{ key: "vipOnly", value: "VIP", searchable: true, width: "5%" },
		{ key: "name", value: "名稱", searchable: true, width: "15%" },
		{ key: "level", value: "學校類型", searchable: false, width: "15%" },
		{ key: "district", value: "地區", searchable: true, width: "5%" },
		{ key: "schoolNet", value: "校網", searchable: true, width: "5%" },
		{ key: "gender", value: "性別", searchable: true, width: "5%" },
		{ key: "category", value: "類別", searchable: true, width: "15%" },
		{ key: "numMsg", value: "新聞發佈", searchable: false, width: "5%" },
		{ key: "currentYearSubscription", value: "本年度訂閱人數", searchable: false, width: "5%" },
		{ key: "currentYearRank", value: "本年度用戶排名", searchable: false, width: "5%" },
		{ key: "overallSubscription", value: "訂閱人數", searchable: false, width: "5%" },
		{ key: "overallRank", value: "用戶排名", searchable: false, width: "5%" },
	],
	ORGANIZATION_VIEW_SCHOOL_TABLE_COLUMN: [
		{ key: "vipOnly", value: "VIP", searchable: true, width: "7%" },
		{ key: "name", value: "名稱", searchable: true, width: "40%" },
		{ key: "level", value: "學校類型", searchable: false, width: "10%" },
		{ key: "district", value: "地區", searchable: true, width: "10%" },
		{ key: "schoolNet", value: "校網", searchable: true, width: "5%" },
		{ key: "gender", value: "性別", searchable: true, width: "5%" },
		{ key: "category", value: "類別", searchable: true, width: "10%" },
		{ key: "numMsg", value: "新聞發佈", searchable: false, width: "8%" },
	],
	level: {
		PRIMARY: { key: "PRIMARY", label: "小學" },
		KINDERGARTEN: { key: "KINDERGARTEN", label: "幼稚園" },
		"KINDERGARTEN-CUM-CHILD CARE CENTRES": { key: "KINDERGARTEN-CUM-CHILD CARE CENTRES", label: "幼稚園暨幼兒中心" },
		SECONDARY: { key: "SECONDARY", label: "中學" },
	},

	linkMode: {
		"THROUGH-TRAIN": { key: "THROUGH-TRAIN", label: "一條龍" },
		FEEDER: { key: "FEEDER", label: "直屬" },
		NOMINATED: { key: "NOMINATED", label: "聯繫" },
	},

	district: {
		"CENTRAL AND WESTERN": { label: "中西區" },
		EASTERN: { label: "東區" },
		ISLANDS: { label: "離島區" },
		"KOWLOON CITY": { label: "九龍城區" },
		"KWAI TSING": { label: "葵青區" },
		"KWUN TONG": { label: "觀塘區" },
		NORTH: { label: "北區" },
		"SAI KUNG": { label: "西貢區" },
		"SHA TIN": { label: "沙田區" },
		"SHAM SHUI PO": { label: "深水埗區" },
		SOUTHERN: { label: "南區" },
		"TAI PO": { label: "大埔區" },
		"TSUEN WAN": { label: "荃灣區" },
		"TUEN MUN": { label: "屯門區" },
		"WAN CHAI": { label: "灣仔區" },
		"WONG TAI SIN": { label: "黃大仙區" },
		"YAU TSIM MONG": { label: "油尖旺區" },
		"YUEN LONG": { label: "元朗區" },
	},

	schoolNet: {
		11: { label: "11" },
		12: { label: "12" },
		14: { label: "14" },
		16: { label: "16" },
		18: { label: "18" },
		31: { label: "31" },
		32: { label: "32" },
		34: { label: "34" },
		35: { label: "35" },
		40: { label: "40" },
		41: { label: "41" },
		43: { label: "43" },
		45: { label: "45" },
		46: { label: "46" },
		48: { label: "48" },
		62: { label: "62" },
		64: { label: "64" },
		65: { label: "65" },
		66: { label: "66" },
		70: { label: "70" },
		71: { label: "71" },
		72: { label: "72" },
		73: { label: "73" },
		74: { label: "74" },
		80: { label: "80" },
		81: { label: "81" },
		83: { label: "83" },
		84: { label: "84" },
		88: { label: "88" },
		89: { label: "89" },
		91: { label: "91" },
		95: { label: "95" },
		96: { label: "96" },
		97: { label: "97" },
		98: { label: "98" },
		99: { label: "99" },
	},

	gender: {
		"CO-ED": { label: "男女" },
		BOYS: { label: "男" },
		GIRLS: { label: "女" },
	},
	religion: {
		BUDDHISM: { label: "佛教" },
		CATHOLICISM: { label: "天主教" },
		CONFUCIANISM: { label: "孔教" },
		"CONFUCIANISM,BUDDHISM & TAOISM": { label: "儒釋道三教" },
		ISLAM: { label: "伊斯蘭教" },
		"PROTESTANTISM / CHRISTIANITY": { label: "基督教" },
		SIKH: { label: "錫克教" },
		TAOISM: { label: "道教" },
		"NOT APPLICABLE": { label: "不適用" },
		OTHERS: { label: "其他" },
	},
	session: {
		"A.M.": { label: "上午" },
		"P.M.": { label: "下午" },
		"WHOLE DAY": { label: "全日" },
		EVENING: { label: "夜校" },
	},
	status: {
		ACTIVE: { key: "ACTIVE", label: "正常" },
		CLOSED: { key: "CLOSED", label: "關閉" },
	},
	kes: {
		Y: "Y",
	},
	category: {
		"Aided Primary Schools": { key: "Aided Primary Schools", label: "資助小學" },
		"Aided Special Schools": { key: "Aided Special Schools", label: "資助特殊學校" },
		"Direct Subsidy Scheme Primary Schools": { key: "Direct Subsidy Scheme Primary Schools", label: "直接資助計劃小學" },
		"English Schools Foundation (Primary)": { key: "English Schools Foundation (Primary)", label: "英基學校協會（小學）" },
		"Government Primary Schools": { key: "Government Primary Schools", label: "官立小學" },
		"International Schools (Primary)": { key: "International Schools (Primary)", label: "國際學校（小學）" },
		Kindergartens: { key: "Kindergartens", label: "幼稚園" },
		"Kindergarten-cum-child Care Centres": { key: "Kindergarten-Cum-Child Care Centres", label: "幼稚園暨幼兒中心" },
		"Private Primary Schools": { key: "Private Primary Schools", label: "私立小學" },
		"Aided Secondary Schools": { key: "Aided Secondary Schools", label: "資助中學" },
		"Direct Subsidy Scheme Secondary Schools": { key: "Direct Subsidy Scheme Secondary Schools", label: "直接資助計劃中學" },
		"Caput Secondary Schools": { key: "Caput Secondary Schools", label: "按位津貼中學" },
		"Private Secondary Schools (Day/Evening)": { key: "Private Secondary Schools (Day/Evening)", label: "私立中學（日校/夜校）" },
		"English Schools Foundation (Secondary)": { key: "English Schools Foundation (Secondary)", label: "英基學校協會（中學）" },
		"International Schools (Secondary)": { key: "International Schools (Secondary)", label: "國際學校（中學）" },
		"Government Secondary Schools": { key: "Government Secondary Schools", label: "官立中學" },
	},

	OPTION: {
		level: [
			{ key: "KINDERGARTEN", value: "幼稚園" },
			{ key: "KINDERGARTEN-CUM-CHILD CARE CENTRES", value: "幼稚園暨幼兒中心" },
			{ key: "PRIMARY", value: "小學" },
			{ key: "SECONDARY", value: "中學" },
		],
		levelTab: [
			{ key: "", value: "全部" },
			{ key: "SECONDARY", value: "中學" },
			{ key: "PRIMARY", value: "小學" },
			{ key: "KINDERGARTEN", value: "幼稚園" },
			{ key: "KINDERGARTEN-CUM-CHILD CARE CENTRES", value: "幼兒中心" },
		],
		district: [
			{ key: "CENTRAL AND WESTERN", value: "中西區" },
			{ key: "EASTERN", value: "東區" },
			{ key: "ISLANDS", value: "離島區" },
			{ key: "KOWLOON CITY", value: "九龍城區" },
			{ key: "KWAI TSING", value: "葵青區" },
			{ key: "KWUN TONG", value: "觀塘區" },
			{ key: "NORTH", value: "北區" },
			{ key: "SAI KUNG", value: "西貢區" },
			{ key: "SHA TIN", value: "沙田區" },
			{ key: "SHAM SHUI PO", value: "深水埗區" },
			{ key: "SOUTHERN", value: "南區" },
			{ key: "TAI PO", value: "大埔區" },
			{ key: "TSUEN WAN", value: "荃灣區" },
			{ key: "TUEN MUN", value: "屯門區" },
			{ key: "WAN CHAI", value: "灣仔區" },
			{ key: "WONG TAI SIN", value: "黃大仙區" },
			{ key: "YAU TSIM MONG", value: "油尖旺區" },
			{ key: "YUEN LONG", value: "元朗區" },
		],
		session: [
			{ key: "WHOLE DAY", value: "全日" },
			{ key: "A.M.", value: "上午" },
			{ key: "P.M.", value: "下午" },
			{ key: "EVENING", value: "夜校" },
		],
		gender: [
			{ key: "CO-ED", value: "男女" },
			{ key: "BOYS", value: "男" },
			{ key: "GIRLS", value: "女" },
		],
		schoolNet: [
			{ key: "11", value: "11" },
			{ key: "12", value: "12" },
			{ key: "14", value: "14" },
			{ key: "16", value: "16" },
			{ key: "18", value: "18" },
			{ key: "31", value: "31" },
			{ key: "32", value: "32" },
			{ key: "34", value: "34" },
			{ key: "35", value: "35" },
			{ key: "40", value: "40" },
			{ key: "41", value: "41" },
			{ key: "43", value: "43" },
			{ key: "45", value: "45" },
			{ key: "46", value: "46" },
			{ key: "48", value: "48" },
			{ key: "62", value: "62" },
			{ key: "64", value: "64" },
			{ key: "65", value: "65" },
			{ key: "66", value: "66" },
			{ key: "70", value: "70" },
			{ key: "71", value: "71" },
			{ key: "72", value: "72" },
			{ key: "73", value: "73" },
			{ key: "74", value: "74" },
			{ key: "80", value: "80" },
			{ key: "81", value: "81" },
			{ key: "83", value: "83" },
			{ key: "84", value: "84" },
			{ key: "88", value: "88" },
			{ key: "89", value: "89" },
			{ key: "91", value: "91" },
			{ key: "95", value: "95" },
			{ key: "96", value: "96" },
			{ key: "97", value: "97" },
			{ key: "98", value: "98" },
			{ key: "99", value: "99" },
		],
		religion: [
			{ key: "BUDDHISM", value: "佛教" },
			{ key: "CATHOLICISM", value: "天主教" },
			{ key: "CONFUCIANISM", value: "孔教" },
			{ key: "CONFUCIANISM,BUDDHISM & TAOISM", value: "儒釋道三教" },
			{ key: "ISLAM", value: "伊斯蘭教" },
			{ key: "PROTESTANTISM / CHRISTIANITY", value: "基督教" },
			{ key: "SIKH", value: "錫克教" },
			{ key: "TAOISM", value: "道教" },
			{ key: "NOT APPLICABLE", value: "不適用" },
			{ key: "OTHERS", value: "其他" },
		],
		financeType: {
			PRIMARY: [
				{ key: "GOVERNMENT", value: "官立" },
				{ key: "AIDED", value: "資助" },
				{ key: "DIRECT SUBSIDY SCHEME", value: "直資" },
				{ key: "PRIVATE", value: "私立" },
				{ key: "PRIVATE INDEPENDENT SCH SCHEME", value: "私立獨立學校計劃" },
				{ key: "ENGLISH SCHOOLS FOUNDATION", value: "英基學校協會" },
			],
			KINDERGARTEN: [{ key: "PRIVATE", value: "私立" }],
			"KINDERGARTEN-CUM-CHILD CARE CENTRES": [{ key: "PRIVATE", value: "私立" }],
			SECONDARY: [
				{ key: "AIDED", value: "資助" },
				{ key: "DIRECT SUBSIDY SCHEME", value: "直資" },
				{ key: "CAPUT", value: "按位津貼" },
				{ key: "PRIVATE", value: "私立" },
				{ key: "ENGLISH SCHOOLS FOUNDATION", value: "英基學校協會" },
				{ key: "GOVERNMENT", value: "官立" },
				{ key: "PRIVATE INDEPENDENT SCH SCHEME", value: "私立獨立學校計劃" },
			],
		},
		linkMode: [
			{ key: "THROUGH-TRAIN", value: "一條龍" },
			{ key: "FEEDER", value: "直屬" },
			{ key: "NOMINATED", value: "聯繫" },
		],
		status: [
			{ key: "ACTIVE", value: "正常" },
			{ key: "CLOSED", value: "已停辦" },
		],
		category: {
			KINDERGARTEN: [{ key: "Kindergartens", value: "幼稚園" }],
			"KINDERGARTEN-CUM-CHILD CARE CENTRES": [{ key: "Kindergarten-cum-child Care Centres", value: "幼稚園暨幼兒中心" }],
			PRIMARY: [
				{ key: "Aided Primary Schools", value: "資助小學" },
				{ key: "Aided Special Schools", value: "資助特殊學校" },
				{ key: "Direct Subsidy Scheme Primary Schools", value: "直接資助計劃小學" },
				{ key: "English Schools Foundation (Primary)", value: "英基學校協會（小學）" },
				{ key: "Government Primary Schools", value: "官立小學" },
				{ key: "International Schools (Primary)", value: "國際學校（小學）" },
				{ key: "Private Primary Schools", value: "私立小學" },
			],
			SECONDARY: [
				{ key: "Aided Secondary Schools", value: "資助中學" },
				{ key: "Aided Special Schools", value: "資助特殊學校" },
				{ key: "Caput Secondary Schools", value: "按位津貼中學" },
				{ key: "Direct Subsidy Scheme Secondary Schools", value: "直接資助計劃中學" },
				{ key: "English Schools Foundation (Secondary)", value: "英基學校協會（中學）" },
				{ key: "Government Secondary Schools", value: "官立中學" },
				{ key: "International Schools (Secondary)", value: "國際學校（中學）" },
				{ key: "Private Secondary Schools (Day/Evening)", value: "私立中學（日校/夜校）" },
			],
		},
	},
};
