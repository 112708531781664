import { statusConstants } from "_constants";

export const getMsgStatus = (item) => {
	let status = statusConstants.status.MSG_PROCESSING.key;
	if (item.status === statusConstants.adminMsgStatus.PROCESSING) {
		status = statusConstants.status.MSG_PROCESSING.key;
	} else if (item.status === statusConstants.adminMsgStatus.FAIL) {
		status = statusConstants.status.MSG_ERROR.key;
	} else if (item.status === statusConstants.adminMsgStatus.PENDING) {
		status = statusConstants.status.MSG_PENDING.key;
	} else if (item.status === statusConstants.adminMsgStatus.FINISH) {
		status = item.action === "DELETE" ? statusConstants.status.MSG_DELETED.key : statusConstants.status.MSG_SUCCESS.key;
	}
	return status;
};
