import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { generalConstants, learningCoursesConstants, learningCentreConstants } from "_constants";
import { learningCentreAction, learningCoursesAction } from "actions";
import moment from "moment";
import _ from "lodash";
import { history, AvatarHelper } from "_util/_helper";
import { Loading, CategoryButton, Pagination, WhatsAppButton, FacebookButton, IGButton, AlertDialog } from "components/general";
import { useLocation } from "react-router-dom";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import isEqual from "react-fast-compare";
import { toast } from "react-toastify";

import { ArrowDropDown, ArrowDropUp } from "@material-ui/icons";

import { ReactComponent as AddCourseIcon } from "resources/img/addcourseicon.svg";
import editIcon from "resources/img/school_inner_pencil.svg";
import cloneIcon from "resources/img/clone_icon.png";
import BinIcon from "resources/img/bin.svg";
import { ReactComponent as OrganizationIcon } from "resources/img/organization.svg";

import { AdminMenubar, AdminUserInfo, AdminSectionbar, AdminFooter } from "components/admin";
import {
	PageContainer,
	LeftContainer,
	RightContainer,
	PaginationContainer,
	TableRow,
	TableTh,
	THButton,
	TableTd,
	SearchBar,
	SearchBarLeft,
	SearchBarRight,
} from "components/layout";

import ProgressiveRejectDebouncer from "_util/debouncers/ProgressiveRejectDebouncer";

const DEFAULT_LIMIT = 10;
const DEFAULT_PAGE = 1;
const FROM_CENTER = "learningCenter";
const FROM_ORGANIZATION = "organization";
export const LearningCentreDetailScreen = () => {
	const location = useLocation();
	const initForm = {
		key: "",
		createdBy: "",
		createdTime: "",
		category: [],
		name: "",
		nameEng: "",
		description: "",
		status: "",
		profilePic: "",
		address: "",
		district: "",
		openingHours: "",
		email: "",
		phone: "",
		website: "",
		whatsapp: "",
		ig: "",
		facebook: "",
		lastUpdateBy: "",
		lastUpdateTime: "",
	};
	const [prevPage, setPrevPage] = useState(location?.state?.from);
	const [key, setLearningCenterKey] = useState(location?.state?.learningCenterKey);
	const [isDeleteAlertShow, setIsDeleteAlertShow] = useState(false);
	const [formData, setFormData] = useState(initForm);
	const [label, setLabel] = useState("");
	const [isLoading, setIsLoading] = useState(false);

	const [debouncer] = useState(() => new ProgressiveRejectDebouncer(1.0 * 1000));

	const { learningCoursesList } = useSelector(({ course }) => {
		if (prevPage === FROM_ORGANIZATION) {
			return { learningCoursesList: course.navigationObject?.organization?.learningCoursesList };
		} else {
			return { learningCoursesList: course.navigationObject?.learningCenter?.learningCoursesList };
		}
	}, isEqual);

	const totalItems = useSelector(({ course }) => {
		if (prevPage === FROM_ORGANIZATION) {
			return course.navigationObject?.organization?.totalItems;
		} else {
			return course.navigationObject?.learningCenter?.totalItems;
		}
	});
	const currentPage = useSelector(({ course }) => {
		if (prevPage === FROM_ORGANIZATION) {
			return course.navigationObject?.organization?.currentPage;
		} else {
			return course.navigationObject?.learningCenter?.currentPage;
		}
	});
	const numOfPage = useSelector(({ course }) => {
		if (prevPage === FROM_ORGANIZATION) {
			return course.navigationObject?.organization?.numOfPage;
		} else {
			return course.navigationObject?.learningCenter?.numOfPage;
		}
	});

	const { centre, organizationObj } = useSelector(({ centre, organization }) => {
		const res = {
			centre: centre.centre,
		};
		if (prevPage === FROM_ORGANIZATION) res.organizationObj = organization.organization;
		return res;
	}, isEqual);
	const deleteLearningCentreSuccess = useSelector(({ centre }) => centre.deleteLearningCentreSuccess);
	const isFetchError = useSelector(({ centre }) => centre.isFetchError);
	const isFetching = useSelector(({ centre }) => centre.isFetching);

	const deleteInProgress = useSelector(({ centre }) => centre.deleteInProgress);

	useEffect(() => {
		setPrevPage(location?.state?.from);
		setLearningCenterKey(location?.state?.learningCenterKey);
	}, [location?.state]);

	const { navigationObject } = useSelector(({ course }) => {
		if (prevPage === FROM_ORGANIZATION) {
			return { navigationObject: course.navigationObject?.organization };
		} else {
			return { navigationObject: course.navigationObject?.learningCenter };
		}
	}, isEqual);

	const [sortingKey, setSortingKey] = useState(navigationObject?.sortingKey);
	const [sortingDirection, setSortingDirection] = useState(navigationObject?.sortingDirection);

	const dispatch = useDispatch();

	useEffect(() => {
		if (key) {
			if (navigationObject?.learningCenterKey === key) {
				dispatch(
					learningCentreAction.getLearningCenterByKey(
						key,
						{
							includeCourse: true,
							sort: `${navigationObject?.sortingKey}:${navigationObject?.sortingDirection}`,
							l: DEFAULT_LIMIT,
							p: currentPage,
						},
						prevPage
					)
				);
			} else {
				setSortingKey("name");
				setSortingDirection("ASC");
				dispatch(
					learningCentreAction.getLearningCenterByKey(
						key,
						{
							includeCourse: true,
							sort: `name:ASC`,
							l: DEFAULT_LIMIT,
							p: DEFAULT_PAGE,
						},
						prevPage
					)
				);
			}
		}
	}, [dispatch, key]);

	useEffect(() => {
		setIsLoading(isFetching || deleteInProgress);
	}, [isFetching, deleteInProgress]);

	useEffect(() => {
		if (isFetchError) {
			dispatch({ type: learningCentreConstants.type.RESET_LEARNING_CENTRE_SUCCESS });
			history.goBack();
		}
	}, [isFetchError]);

	useEffect(() => {
		if (centre) {
			var data = {
				key: centre._key,
				createdBy: centre.createBy,
				createdTime: centre.createdTime,
				category: centre.category || [],
				name: centre.name ? centre.name : "",
				nameEng: centre.nameEng ? centre.nameEng : "",
				description: centre.description,
				status: centre.status,
				profilePic: centre.profilePic,
				address: centre.address,
				district: centre.district,
				openingHours: centre.openingHours,
				email: centre.email,
				phone: centre.phone,
				website: centre.website,
				whatsapp: centre.whatsapp,
				ig: centre.ig,
				facebook: centre.facebook,
				deleted: centre.deleted,
				lastUpdateBy: centre.lastUpdateBy,
				lastUpdateTime: centre.lastUpdateTime,
			};
			if (centre.latitude && centre.longitude) {
				data = {
					...data,
					latitude: centre.latitude,
					longitude: centre.longitude,
				};
			}
			setFormData(data);
			setLabel(_.isEmpty(data.name) ? data.nameEng || "" : data.name);
		}
	}, [centre]);

	useEffect(() => {
		if (!deleteInProgress && deleteLearningCentreSuccess) {
			dispatch({ type: learningCentreConstants.type.RESET_LEARNING_CENTRE_SUCCESS });
			toast.success("已成功刪除");
			handleReplace();
		} else if (!deleteInProgress && isDeleteAlertShow) {
			setIsDeleteAlertShow(false);
		}
	}, [deleteLearningCentreSuccess, deleteInProgress]);

	const handleReplace = () => {
		history.replace({
			pathname: prevPage === FROM_CENTER ? generalConstants.PATH.LEARNING_CENTRE_MANAGEMENT : generalConstants.PATH.ORGANIZATION,
			state: prevPage === FROM_CENTER ? null : { from: prevPage, organizationKey: organizationObj._key },
		});
	};

	const handleSorting = (sort) => {
		if (!debouncer.token()) {
			return;
		}
		let direction = "DESC";
		if (sortingKey === sort && sortingDirection === "DESC") {
			direction = "ASC";
		}
		setSortingKey(sort);
		setSortingDirection(direction);

		dispatch(
			learningCoursesAction.getLearningCourses(
				{
					learningCenterKey: key,
					l: DEFAULT_LIMIT,
					p: DEFAULT_PAGE,
					sort: `${sort}:${direction}`,
				},
				prevPage
			)
		);
	};

	const handlePageChange = (pageNum) => {
		if (!debouncer.token() || currentPage === pageNum) {
			return;
		}
		dispatch(
			learningCoursesAction.getLearningCourses(
				{
					learningCenterKey: key,
					l: DEFAULT_LIMIT,
					p: pageNum,
					sort: `${sortingKey}:${sortingDirection}`,
				},
				prevPage
			)
		);
	};

	const getDistrict = (item) => {
		if (item === "") {
			return "/";
		}
		return learningCoursesConstants.DATA.district[item].label;
	};

	const getText = (text) => {
		return text ? text.replace(/\n/g, "<br />") : "";
	};

	const handleClone = () => {
		history.push({
			pathname:
				prevPage === FROM_ORGANIZATION
					? `${generalConstants.PATH.ORGANIZATION}/learningCenter/add`
					: `${generalConstants.PATH.LEARNING_CENTER}/add`,
			state:
				prevPage === FROM_ORGANIZATION
					? { from: prevPage, cloneCenter: centre, organizationKey: organizationObj._key }
					: { from: prevPage, cloneCenter: centre },
		});
	};

	const handleEdit = () => {
		history.push({
			pathname:
				prevPage === FROM_ORGANIZATION
					? `${generalConstants.PATH.ORGANIZATION}/learningCenter/edit`
					: `${generalConstants.PATH.LEARNING_CENTER}/edit`,
			state:
				prevPage === FROM_ORGANIZATION
					? { from: prevPage, organizationKey: organizationObj._key, learningCenterKey: key }
					: { from: prevPage, learningCenterKey: key },
		});
	};

	const handleDelete = () => {
		const data = {
			key: centre._key,
			rev: centre._rev,
		};

		dispatch(learningCentreAction.deleteLearningCenter(data));
	};

	const handleCloseDeleteAlert = () => {
		setIsDeleteAlertShow(false);
	};

	const renderTH = () => {
		return learningCoursesConstants.TABLE_COLUMN.map((column) => {
			return (
				<TableTh key={`${column.key}_table_header`} width={column.width}>
					<THButton key={`${column.key}_table_header`} type="button" onClick={() => handleSorting(column.key)}>
						<div>
							{column.value}
							{sortingKey === column.key ? (
								sortingDirection === "ASC" ? (
									<ArrowDropUp fontSize={"small"} style={{ verticalAlign: "middle" }} />
								) : (
									<ArrowDropDown fontSize={"small"} style={{ verticalAlign: "middle" }} />
								)
							) : null}
						</div>
					</THButton>
				</TableTh>
			);
		});
	};
	const renderTableHeader = renderTH();
	const renderTD = () => {
		if (learningCoursesList && learningCoursesList.length > 0) {
			return learningCoursesList.map((course, index) => {
				const tempObj = {};
				learningCoursesConstants.TABLE_COLUMN.forEach((column) => {
					tempObj[column.key] = course[column.key];
				});

				return (
					<TableRow
						key={`${course._id}_${index}_tr`}
						onClick={() => {
							handleCourseDetails(course._key);
						}}
					>
						{Object.keys(tempObj).map((columnName, index) => {
							if (columnName === "category") {
								var categoryName = "-";
								if (typeof tempObj[columnName] != "undefined" && typeof tempObj[columnName] != "string") {
									tempObj[columnName].forEach((name, index) => {
										if (index === 0) {
											categoryName = `${name}`;
										} else {
											categoryName = `${categoryName}、${name}`;
										}
									});
								}
								return <TableTd key={`${course._id}_${columnName}_${index}_td`}>{categoryName}</TableTd>;
							} else if (columnName === "age") {
								return (
									<TableTd key={`${course._id}_${columnName}_${index}_td`}>
										{typeof course.minAge != "undefined" && typeof course.maxAge != "undefined"
											? `${course.minAge}歲 - ${course.maxAge}歲`
											: typeof course.minAge != "undefined"
											? `${course.minAge}歲以上`
											: typeof course.maxAge != "undefined"
											? `${course.maxAge}歲以下`
											: `-`}
									</TableTd>
								);
							} else if (columnName === "createdTime" || columnName === "lastUpdateTime") {
								return (
									<TableTd key={`${course._id}_${columnName}_${index}_td`}>
										{tempObj[columnName] ? moment(tempObj[columnName]).format("YYYY年M月DD日 hh:mmA") : "-"}
									</TableTd>
								);
							} else if (columnName === "district") {
								return (
									<TableTd key={`${course._id}_${columnName}_${index}_td`}>
										{course.isOnline ? "網上課程" : getDistrict(tempObj[columnName] || "")}
									</TableTd>
								);
							} else if (columnName === "name") {
								var courseName = "";
								if (typeof tempObj[columnName] != "undefined") {
									courseName = tempObj[columnName];

									if (_.isEmpty(courseName)) {
										courseName = course["nameEng"];
									}
								}
								return (
									<TableTd style={{ display: "flex", alignItems: "center" }} key={`${course._id}_${columnName}_${index}_td`}>
										{course.deleted && <DeleteBadge>{"已移除"}</DeleteBadge>}
										{!course.deleted && course.status === learningCoursesConstants.DATA.status.CLOSED.key && (
											<ClosedBadge>{learningCoursesConstants.DATA.status[course.status].label}</ClosedBadge>
										)}
										{!course.deleted &&
											(course.status === learningCoursesConstants.DATA.status.SUSPENDED.key ||
												course.status === learningCoursesConstants.DATA.status.CANCELLED.key) && (
												<PendingBadge>{learningCoursesConstants.DATA.status[course.status].label}</PendingBadge>
											)}
										{courseName}
										{(course?.lastUpdateBy?.startsWith("organization/") || course?.createdBy?.startsWith("organization/")) && (
											<OrganizationIcon style={{ marginLeft: 5, marginRight: 5, width: 15, height: 19 }} fill={"#333399"} />
										)}
									</TableTd>
								);
							}
							return <TableTd key={`${course._id}_${columnName}_${index}_td`}>{tempObj[columnName]}</TableTd>;
						})}
					</TableRow>
				);
			});
		}
	};
	const renderTableCell = renderTD();

	const handleNewCourse = () => {
		history.push({
			pathname:
				prevPage === FROM_ORGANIZATION
					? `${generalConstants.PATH.ORGANIZATION}/learningCenter/course/add`
					: `${generalConstants.PATH.LEARNING_CENTER}/course/add`,
			state: { from: prevPage, learningCenterKey: key },
		});
	};

	const handleCourseDetails = (courseKey) => {
		history.push({
			pathname:
				prevPage === FROM_ORGANIZATION
					? `${generalConstants.PATH.ORGANIZATION}/learningCenter/course`
					: `${generalConstants.PATH.LEARNING_CENTER}/course`,
			state: { from: prevPage, learningCenterKey: key, courseKey: courseKey },
		});
	};

	const getLocationPath = () => {
		const pathArray = [];

		pathArray.push({ title: "主頁面", isAction: true, target: generalConstants.PATH.DASHBOARD });
		pathArray.push({
			title:
				generalConstants.TAB_NAME[
					prevPage === FROM_CENTER
						? generalConstants.NAV_TAB.LEARNING_CENTRE_MANAGEMENT.key
						: generalConstants.NAV_TAB.ORGANIZATION_MANAGEMENT.key
				],
			isAction: true,
			target: prevPage === FROM_CENTER ? generalConstants.PATH.LEARNING_CENTRE_MANAGEMENT : generalConstants.PATH.ORGANIZATION_MANAGEMENT,
		});
		if (prevPage === FROM_ORGANIZATION && organizationObj) {
			pathArray.push({
				title: organizationObj.name,
				isAction: true,
				target: generalConstants.PATH.ORGANIZATION,
				stateObj: { from: prevPage, organizationKey: organizationObj._key },
			});
		}
		pathArray.push({ title: label, isAction: false, target: "" });

		return pathArray;
	};

	if (!key) {
		return null;
	}
	return (
		<>
			{!!isLoading && <Loading />}
			<AlertDialog
				active={isDeleteAlertShow}
				handleOnClose={handleCloseDeleteAlert}
				handleOnYes={handleDelete}
				title={"確定移除補習社？"}
			/>
			<PageContainer>
				<LeftContainer>
					<AdminMenubar
						section={
							prevPage === FROM_CENTER
								? generalConstants.NAV_TAB.LEARNING_CENTRE_MANAGEMENT.key
								: generalConstants.NAV_TAB.ORGANIZATION_MANAGEMENT.key
						}
					/>
				</LeftContainer>
				<RightContainer>
					<AdminUserInfo />
					<RowInBlock style={{ paddingLeft: "20px", paddingRight: "20px" }}>
						<LeftPageContainer>
							<AdminSectionbar
								title={generalConstants.NAV_TAB.LEARNING_CENTRE_DETAIL.key}
								sublabel={`課程數量: ${totalItems}`}
								label={label}
								buttonLabel={"新增課程"}
								buttonIcon={<AddCourseIcon />}
								buttonType={generalConstants.BUTTON_TYPE.SUBMIT}
								handleOnClick={handleNewCourse}
								locationPathArray={getLocationPath()}
							/>
							<Paper elevation={3}>
								<RowInBlock marginBottom={"0px"}>
									<ContentLeft>
										<SearchBar style={{ paddingLeft: "20px", paddingRight: "20px", paddingTop: "20px" }}>
											<SearchBarLeft>
												<RowInBlock marginBottom="0px">
													<Title>- 課程</Title>
												</RowInBlock>
											</SearchBarLeft>
											<SearchBarRight />
										</SearchBar>
										<table style={{ width: "100%", borderCollapse: "collapse", tableLayout: "fixed" }}>
											<thead>
												<tr>{renderTableHeader}</tr>
											</thead>
											<tbody>{renderTableCell}</tbody>
										</table>
										<PaginationContainer style={{ paddingLeft: "20px" }}>
											<Pagination count={numOfPage} page={currentPage} handlePageChange={handlePageChange} />
										</PaginationContainer>
									</ContentLeft>
								</RowInBlock>
							</Paper>
						</LeftPageContainer>
						<ContentRight style={{ padding: 0, marginTop: 20, marginLeft: 20 }}>
							<Paper elevation={3}>
								<CentreInfoContainer>
									<div>
										<IconButton aria-label="編輯" onClick={handleEdit} style={{ float: "right", padding: "5px" }}>
											<img alt="" src={editIcon} />
										</IconButton>
										<IconButton aria-label="刪除" onClick={() => setIsDeleteAlertShow(true)} style={{ float: "right", padding: "5px" }}>
											<img alt="" src={BinIcon} />
										</IconButton>
										<IconButton aria-label="複製" onClick={handleClone} style={{ float: "right", padding: "5px" }}>
											<img alt="" src={cloneIcon} />
										</IconButton>
										<TextFieldLabel>- 補習社資料</TextFieldLabel>
										{!_.isEmpty(formData.profilePic) && (
											<Avatar showBadge={false} src={AvatarHelper(formData.profilePic)} size={45} type={"parent"} />
										)}
										{formData.deleted && <DetailDeleteBadge>{"已移除"}</DetailDeleteBadge>}
										{!formData.deleted && formData.status === learningCentreConstants.DATA.status.CLOSED.key && (
											<DetailClosedBadge>{learningCentreConstants.DATA.status[formData.status].label}</DetailClosedBadge>
										)}
										{formData.name && <CentreNameLabel>{formData.name}</CentreNameLabel>}
										{formData.nameEng && <CentreNameLabel>{formData.nameEng}</CentreNameLabel>}
										<SmallRowInBlock>
											<CentreSmalllDetaiLabel style={{ flex: 3 }}>地址</CentreSmalllDetaiLabel>
											<CentreSmalllDetaiLabel style={{ flex: 1 }}>:</CentreSmalllDetaiLabel>
											{formData.latitude && formData.longitude ? (
												<CentreSmalllDetaiLabel style={{ flex: 17 }}>
													<a target="_black" href={`https://map.google.com/?q=${formData.latitude},${formData.longitude}`}>
														{formData.address}
													</a>
												</CentreSmalllDetaiLabel>
											) : (
												<CentreSmalllDetaiLabel style={{ flex: 17 }}>{formData.address}</CentreSmalllDetaiLabel>
											)}
										</SmallRowInBlock>
										<SmallRowInBlock>
											<CentreSmalllDetaiLabel style={{ flex: 3 }}>電話</CentreSmalllDetaiLabel>
											<CentreSmalllDetaiLabel style={{ flex: 1 }}>:</CentreSmalllDetaiLabel>
											<CentreSmalllDetaiLabel style={{ flex: 17 }}>{formData.phone || "-"}</CentreSmalllDetaiLabel>
										</SmallRowInBlock>
										<SmallRowInBlock>
											<CentreSmalllDetaiLabel style={{ flex: 3 }}>電郵</CentreSmalllDetaiLabel>
											<CentreSmalllDetaiLabel style={{ flex: 1 }}>:</CentreSmalllDetaiLabel>
											{formData.email ? (
												<CentreSmalllDetaiLabel style={{ flex: 17 }}>
													<a target="_black" href={`mailto:${formData.email}`}>
														{formData.email}
													</a>
												</CentreSmalllDetaiLabel>
											) : (
												<CentreSmalllDetaiLabel style={{ flex: 17 }}>{"-"}</CentreSmalllDetaiLabel>
											)}
										</SmallRowInBlock>
										<SmallRowInBlock>
											<CentreSmalllDetaiLabel style={{ flex: 3 }}>網址</CentreSmalllDetaiLabel>
											<CentreSmalllDetaiLabel style={{ flex: 1 }}>:</CentreSmalllDetaiLabel>

											{formData.website ? (
												<CentreSmalllDetaiLabel style={{ flex: 17 }}>
													<a
														target="_black"
														href={
															formData.website.includes("http://") || formData.website.includes("https://")
																? formData.website
																: `http://${formData.website}`
														}
													>
														{formData.website || "-"}
													</a>
												</CentreSmalllDetaiLabel>
											) : (
												<CentreSmalllDetaiLabel style={{ flex: 17 }}>{"-"}</CentreSmalllDetaiLabel>
											)}
										</SmallRowInBlock>
										{(!!formData.facebook || !!formData.ig || !!formData.whatsapp) && (
											<SmallRowInBlock style={{ marginTop: "10px", marginBottom: "10px" }}>
												{!!formData.facebook && <FacebookButton value={formData.facebook} />}
												{!!formData.ig && <IGButton value={formData.ig} />}
												{!!formData.whatsapp && <WhatsAppButton value={formData.whatsapp} />}
											</SmallRowInBlock>
										)}
										<div>
											<SubTitle style={{ flex: 3 }}>營業時間</SubTitle>
											<SubText style={{ flex: 17 }} dangerouslySetInnerHTML={{ __html: getText(formData.openingHours) || "-" }} />
										</div>
										<div>
											<SubTitle style={{ flex: 3 }}>補習社簡介</SubTitle>
											<SubText style={{ flex: 17 }} dangerouslySetInnerHTML={{ __html: getText(formData.description) || "-" }} />
										</div>
										<div>
											<SubTitle style={{ flex: 3 }}>課程類別</SubTitle>
											{formData.category.length > 0 ? (
												<CategoryGroup>
													{formData.category.map((item, index) => {
														const itemText = item.trim();
														return (
															<div key={`${itemText}_${index}_category_item`} style={{ marginRight: "5px", marginBottom: "5px" }}>
																<CategoryButton label={itemText} />
															</div>
														);
													})}
												</CategoryGroup>
											) : (
												<SubText style={{ flex: 17 }}>{"-"}</SubText>
											)}
										</div>
									</div>
									<div>
										{!_.isEmpty(formData.lastUpdateTime) && (
											<RowInLastUpdate style={{ alignItems: "flex-end", justifyContent: "flex-end", marginRight: 15 }}>
												<CentreSmalllDetaiLabel>
													最後更新:{(formData.lastUpdateTime && moment(formData.lastUpdateTime).format("YYYY年M月DD日 hh:mmA")) || "-"}
												</CentreSmalllDetaiLabel>
											</RowInLastUpdate>
										)}
										{!_.isEmpty(formData.lastUpdateBy) ? (
											<RowInLastUpdate style={{ alignItems: "flex-end", justifyContent: "flex-end", marginRight: 15 }}>
												<CentreSmalllDetaiLabel>({formData.lastUpdateBy || "-"})</CentreSmalllDetaiLabel>
											</RowInLastUpdate>
										) : (
											<div>&nbsp;</div>
										)}
									</div>
								</CentreInfoContainer>
							</Paper>
						</ContentRight>
					</RowInBlock>
					<AdminFooter></AdminFooter>
				</RightContainer>
			</PageContainer>
		</>
	);
};

const ContentLeft = styled.div`
	flex: 18;
	height: 590px;
	border-right: 1px solid #dddddd;
`;

const ContentRight = styled.div`
	flex: 6;
	padding: 20px;
`;

const LeftPageContainer = styled.div`
	flex: 16;
`;

const CentreInfoContainer = styled.div`
	flex: 1;
	height: 600px;
	margin-left: 20px;
	margin-right: 20px;
	padding-top: 20px;
	padding-bottom: 20px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
`;

const RowInBlock = styled.div`
	display: flex;
	margin-bottom: ${(props) => props.marginBottom || "10px"};
	flex-direction: row;
`;

const TextFieldLabel = styled.div`
	color: #333399;
	font-size: 14px;
	line-height: 1.5;
	font-weight: bold;
	margin-bottom: 0px;
`;

const Avatar = styled.img`
	border-radius: 50%;
	width: 80px;
	height: 80px;
	border: #aaaaaa 1px solid;
`;

const CentreNameLabel = styled.div`
	color: #000000;
	font-size: 14px;
	line-height: 1;
	font-weight: bold;
	margin-bottom: 4px;
`;

const SmallRowInBlock = styled.div`
	display: flex;
	flex-direction: row;
	margin-bottom: 4px;
	margin-top: 4px;
`;

const CentreSmalllDetaiLabel = styled.div`
	color: #666666;
	font-size: 11px;
	font-weight: 500;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.55;
	letter-spacing: normal;
`;

const Title = styled.div`
	font-weight: bold;
	margin: 0 0 12px 4px;
	font-size: 14px;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.43;
	letter-spacing: normal;
	text-align: left;
	color: #333399;
	width: 100%;
`;

const SubTitle = styled.div`
	color: #000000;
	font-size: 11px;
	line-height: 1;
	font-weight: bold;
	margin-bottom: 8px;
`;

const SubText = styled.div`
	color: #333333;
	font-size: 11px;
	line-height: 1;
	margin-bottom: 8px;
`;

const CategoryGroup = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
`;

const DeleteBadge = styled.span`
	padding: 2px 6px 3px;
	border-radius: 3px;
	background-color: #ff5c5c;
	margin: 9px 6px 9px 0;
	font-size: 10px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.5;
	letter-spacing: normal;
	color: white;
`;

const ClosedBadge = styled.span`
	padding: 2px 6px 3px;
	border-radius: 3px;
	background-color: #fff0f2;
	margin: 9px 6px 9px 0;
	font-size: 10px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.5;
	letter-spacing: normal;
	color: #ff0000;
`;

const DetailDeleteBadge = styled.span`
	margin: 8px 0 40px 5px;
	padding: 2px 6px 3px;
	border-radius: 3px;
	background-color: #ff5c5c;
	font-size: 10px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.5;
	letter-spacing: normal;
	color: white;
	float: right;
`;

const DetailClosedBadge = styled.span`
	margin: 8px 0 40px 5px;
	padding: 2px 6px 3px;
	border-radius: 3px;
	background-color: #fff0f2;
	font-size: 10px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.5;
	letter-spacing: normal;
	color: #ff0000;
	float: right;
`;

const PendingBadge = styled.span`
	padding: 2px 6px 3px;
	border-radius: 3px;
	background-color: #ffd95c;
	margin: 9px 6px 9px 0;
	font-size: 10px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.5;
	letter-spacing: normal;
	color: black;
`;

const RowInLastUpdate = styled.div`
	display: flex;
	flex-direction: row;
`;
