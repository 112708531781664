import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";

import { schoolConstants, generalConstants, dashboardConstants, messageConstants } from "_constants";
import { messageAction, schoolAction } from "actions";
import _ from "lodash";
import isEqual from "react-fast-compare";

import { history, getMsgStatus } from "_util/_helper";
import moment from "moment";

import { Pagination, Status, RefreshButton } from "components/general";
import { StandardSelect } from "components/general/standard";

import Button from "@material-ui/core/Button";
import { Tabs, Tab } from "@material-ui/core";

import { AdminMenubar, AdminUserInfo, AdminFooter } from "components/admin";
import {
	PageContainer,
	LeftContainer,
	RightContainer,
	ContentContainer,
	SearchBar,
	SearchBarLeft,
	PaginationContainer,
} from "components/layout";

import { ReactComponent as ViewHistoryIcon } from "resources/img/historyicon.svg";

import ProgressiveRejectDebouncer from "_util/debouncers/ProgressiveRejectDebouncer";

const DEFAULT_PAGE = 1;
const DEFAULT_RANK_LIMIT = 5;
const DEFAULT_SCHOOL_LIMIT = 10;

const initSearchSchoolForm = {
	level: "",
	district: "",
	gender: "",
	includeSummary: true,
	page: DEFAULT_PAGE,
	limit: DEFAULT_SCHOOL_LIMIT,
};

const initRankAndNewsForm = {
	level: "",
	page: DEFAULT_PAGE,
	limit: DEFAULT_RANK_LIMIT,
};

const initSearchMsgForm = {
	msg: {
		infoType: "SCHOOL",
		dateRange: "today",
	},
	school: {
		level: "",
	},
	limit: DEFAULT_RANK_LIMIT,
	page: DEFAULT_PAGE,
	sort: "lastUpdateTime:DESC",
};

const FROM_SCHOOL = "school";
export const DashboardPage = () => {
	const {
		msgSchoolLevel,
		msgDateRange,
		msgList,
		msgPage,
		msgTotal,
		subscriptionList,
		schoolBySubscriptionLevel,
		newsList,
		schoolByNewsLevel,
	} = useSelector(({ dashboard, message }) => ({
		msgSchoolLevel: message.navigationObject?.dashboard?.level || "",
		msgDateRange: message.navigationObject?.dashboard?.dateRange || "",
		msgList: message.navigationObject?.dashboard?.msgList,
		msgPage: message.navigationObject?.dashboard?.currentPage,
		msgTotal: message.navigationObject?.dashboard?.numOfPage,
		schoolBySubscriptionLevel: dashboard.schoolBySubscription?.level,
		subscriptionList: dashboard.schoolBySubscription?.schoolList,
		schoolByNewsLevel: dashboard.schoolByNews?.level,
		newsList: dashboard.schoolByNews?.schoolList,
	}));

	const { schoolList, summary, schoolPage, schoolTotal } = useSelector(
		({ school }) => ({
			schoolList: school.navigationObject?.dashboard?.schoolList,
			summary: school.navigationObject?.dashboard?.summary,
			schoolPage: school.navigationObject?.dashboard?.currentPage,
			schoolTotal: school.navigationObject?.dashboard?.numOfPage,
		}),
		isEqual
	);

	const { navigationObject } = useSelector(
		({ school }) => ({
			navigationObject: school.navigationObject?.dashboard,
		}),
		isEqual
	);

	const { loggedIn } = useSelector(({ auth }) => ({ loggedIn: auth.loggedIn }));

	const schoolFilter = {
		level: navigationObject?.level || "",
		district: navigationObject?.district || "",
		gender: navigationObject?.gender || "",
		includeSummary: true,
		page: schoolPage,
		limit: DEFAULT_SCHOOL_LIMIT,
	};

	const subscriptionFilter = {
		type: "subscription",
		level: schoolBySubscriptionLevel,
		page: DEFAULT_PAGE,
		limit: DEFAULT_RANK_LIMIT,
	};

	const newsFilter = {
		type: "news",
		level: schoolByNewsLevel,
		page: DEFAULT_PAGE,
		limit: DEFAULT_RANK_LIMIT,
	};

	const [firstLoad, setFirstLoad] = useState(true);
	const [searchMsgForm, setSearchMsgForm] = useState(initSearchMsgForm);
	const [searchSchoolForm, setSearchSchoolForm] = useState(schoolFilter || initSearchSchoolForm);
	const [rankBySubscriptionForm, setRankBySubscriptionForm] = useState(subscriptionFilter || initRankAndNewsForm);
	const [rankByNewsForm, setRankByNewsForm] = useState(newsFilter || initRankAndNewsForm);

	const dispatch = useDispatch();

	const [debouncer] = useState(() => new ProgressiveRejectDebouncer(1 * 1000));

	useEffect(() => {
		if (searchSchoolForm) {
			let form = { includeSummary: true, page: searchSchoolForm.page, limit: searchSchoolForm.limit };
			if (searchSchoolForm.level) {
				form = { ...form, level: searchSchoolForm.level };
			}
			if (searchSchoolForm.district) {
				form = { ...form, district: searchSchoolForm.district };
			}
			if (searchSchoolForm.gender) {
				form = { ...form, gender: searchSchoolForm.gender };
			}
			dispatch(schoolAction.getSchoolList(form, "dashboard"));
		}
	}, [dispatch, searchSchoolForm]);

	useEffect(() => {
		if (rankBySubscriptionForm) {
			dispatch(schoolAction.getSchoolList({ ...rankBySubscriptionForm, sort: "overallRank:ASC" }, "subscription"));
		}
	}, [dispatch, rankBySubscriptionForm]);

	useEffect(() => {
		if (rankByNewsForm) {
			dispatch(schoolAction.getSchoolList({ ...rankByNewsForm, sort: "numMsg:DESC" }, "news"));
		}
	}, [dispatch, rankByNewsForm]);

	const handleRefreshFilter = () => {
		if (
			searchSchoolForm.district === "" &&
			searchSchoolForm.gender === "" &&
			searchSchoolForm.level === "" &&
			searchSchoolForm.page === DEFAULT_PAGE
		) {
			return;
		}
		setSearchSchoolForm(initSearchSchoolForm);
	};

	const HandleRowOnClick = (item) => {
		history.push({
			pathname: generalConstants.PATH.SCHOOL_DETAIL,
			state: {
				from: FROM_SCHOOL,
				schoolKey: item._key,
			},
		});
	};

	const HandleMsgRowOnClick = (item) => {
		history.push({ pathname: generalConstants.PATH.EDIT_MSG, state: { msgKey: item._key } });
	};

	const handlePageChange = (pageNum) => {
		if (!debouncer.token() || schoolPage === pageNum) {
			return;
		}
		setSearchSchoolForm({ ...searchSchoolForm, page: pageNum });
	};

	const handleSelectChange = ({ key, value }) => {
		setSearchSchoolForm({ ...searchSchoolForm, [key]: value, page: DEFAULT_PAGE });
	};

	const handleMsgPageChange = (pageNum) => {
		setSearchMsgForm({ ...searchMsgForm, page: pageNum });
		dispatch(messageAction.fetchMessages({ ...searchMsgForm, page: pageNum }, "dashboard"));
	};

	const handleShowAllMsg = () => {
		setSearchMsgForm({
			...searchMsgForm,
			page: 1,
		});
		dispatch({ type: dashboardConstants.type.DASHBOARD_SET_MESSAGE_FILTER, level: msgSchoolLevel || "", dateRange: "" });
	};

	useEffect(() => {
		if (loggedIn) {
			setFirstLoad(false);
			setSearchMsgForm({
				...searchMsgForm,
				msg: { dateRange: firstLoad ? "today" : msgDateRange || "" },
				school: { level: msgSchoolLevel || "" },
			});
			dispatch(
				messageAction.fetchMessages(
					{
						...searchMsgForm,
						msg: { dateRange: firstLoad ? "today" : msgDateRange || "" },
						school: { level: msgSchoolLevel || "" },
					},
					"dashboard"
				)
			);
		}
	}, [msgSchoolLevel, msgDateRange, loggedIn]);

	const getSchoolCounts = () => {
		const tabs = _.filter(schoolConstants.OPTION.levelTab, function (tab) {
			return !_.isEmpty(tab.key);
		});
		const result = tabs.map((tab) => {
			const items = _.find(summary, (item) => item.level === tab.key);
			return tab.value + ":" + (items?.count || 0);
		});
		return _.join(result, " / ");
	};

	const getOverflowByMsg = (msg) => {
		const schoolId = msg.schoolId;

		if (schoolList.length > 0) {
			const school = _.find(schoolList, function (o) {
				return o._id === schoolId;
			});
			if (school) {
				return getOverview(school);
			}
		}
	};

	const getVipStatus = (msg) => {
		const schoolId = msg.schoolId;
		if (schoolList.length > 0) {
			const school = _.find(schoolList, function (o) {
				return o._id === schoolId;
			});
			if (school) {
				return school.vipOnly;
			}
		}
		return false;
	};

	const getOverview = (school) => {
		let str = "";

		if (school.level) {
			if (str.length > 0) str = str.concat(" / ");
			str = str.concat(schoolConstants.level[school.level].label);
		}

		if (school.district) {
			if (str.length > 0) str = str.concat(" / ");
			str = str.concat(schoolConstants.district[school.district].label);
		}

		if (school.financeType) {
			if (str.length > 0) str = str.concat(" / ");
			str = str.concat(schoolConstants.OPTION.financeType[school.level].filter((a) => a.key === school.financeType)[0].value);
		}

		if (school.schoolNet) {
			if (str.length > 0) str = str.concat(" / ");
			str = str.concat(school.schoolNet + "校網");
		}

		if (school.gender) {
			if (str.length > 0) str = str.concat(" / ");
			str = str.concat(schoolConstants.gender[school.gender].label);
		}

		return str;
	};

	const getCategoryShort = (msg) => {
		return msg && msg.category ? messageConstants.Translate.category_short[msg.category] : "";
	};

	const renderTD = (items, keys) => {
		if (items && items.length > 0) {
			return items.map((item, index) => {
				const tempObj = {};
				keys.forEach((column) => {
					tempObj[column.key] = item[column.key];
				});
				return (
					<TableRow
						key={`${item._id}_${index}_tr`}
						onClick={() => {
							HandleRowOnClick(item);
						}}
					>
						{Object.keys(tempObj).map((columnName, index) => {
							if (columnName === "name") {
								return (
									<TableTd key={`${item._id}_${columnName}_${index}_td`}>
										{item.status === schoolConstants.status.CLOSED.key && <ClosedBadge>已停辦</ClosedBadge>}
										<TableRowTitle>{tempObj[columnName][0]}</TableRowTitle>
										<OverviewContainer>
											{item.vipOnly && (
												<VipIconView>
													<VipText>VIP</VipText>
												</VipIconView>
											)}
											<Overview>{getOverview(item)}</Overview>
										</OverviewContainer>
									</TableTd>
								);
							} else {
								return <TableTd key={`${item._id}_${columnName}_${index}_td`}>{tempObj[columnName] || "-"}</TableTd>;
							}
						})}
					</TableRow>
				);
			});
		}
	};

	const renderRankTD = (items, keys, limit, page = 1, rankBy = "overallRank") => {
		if (items && items.length > 0) {
			const subscriptionList = items.map((item) => {
				return item[rankBy];
			});

			return items.map((item, index) => {
				if (index < limit * (page - 1) || index > limit * page - 1) {
					return null;
				}
				const tempObj = {};
				keys.forEach((column) => {
					tempObj[column.key] = item[column.key];
				});
				return (
					<TableRow
						style={{ flexDirection: "row" }}
						key={`${item._id}_${index}_tr`}
						onClick={() => {
							HandleRowOnClick(item);
						}}
					>
						{Object.keys(tempObj).map((columnName, index) => {
							if (columnName === "overallRank") {
								return (
									<TableTd key={`${item._id}_${columnName}_${index}_td`} style={{ width: "40px" }}>
										<RankBadge rank={tempObj["overallRank"]}>{tempObj[columnName] || "-"}</RankBadge>
									</TableTd>
								);
							} else if (columnName === "rank") {
								const displayRank = subscriptionList.indexOf(item[rankBy]) + 1;
								return (
									<TableTd key={`${item._id}_${columnName}_${index}_td`} style={{ width: "40px" }}>
										<RankBadge rank={displayRank}>{displayRank || "-"}</RankBadge>
									</TableTd>
								);
							} else if (columnName === "name") {
								return (
									<TableTd key={`${item._id}_${columnName}_${index}_td`}>
										{item.status === schoolConstants.status.CLOSED.key && <ClosedBadge>已停辦</ClosedBadge>}
										<TableRowTitle>{tempObj[columnName][0]}</TableRowTitle>
									</TableTd>
								);
							} else {
								return (
									<TableTd key={`${item._id}_${columnName}_${index}_td`} style={{ width: "40px" }}>
										{tempObj[columnName] || "-"}
									</TableTd>
								);
							}
						})}
					</TableRow>
				);
			});
		}
	};

	const renderTabs = (rankForm, setRankForm) => {
		return (
			<TabsContainer>
				<Tabs
					indicatorColor="primary"
					textColor="primary"
					value={typeof rankForm?.school != "undefined" ? rankForm.school["level"] : rankForm["level"]}
					style={{ minHeight: "26px" }}
					onChange={(event, value) => {
						tabChange(rankForm, setRankForm, value);
					}}
				>
					{schoolConstants.OPTION.levelTab.map((tab) => (
						<Tab
							style={{ padding: "3px 0px", marginLeft: "10px", marginRight: "10px", minWidth: "auto", minHeight: "26px", fontSize: "12px" }}
							key={tab.key}
							value={tab.key}
							label={tab.value}
						/>
					))}
				</Tabs>
			</TabsContainer>
		);
	};

	const tabChange = (rankForm, setRankForm, value) => {
		if (typeof rankForm?.school != "undefined") {
			if (searchMsgForm.school.level === value) return;
			setSearchMsgForm({ ...searchMsgForm, page: 1 });
			dispatch({ type: dashboardConstants.type.DASHBOARD_SET_MESSAGE_FILTER, level: value, dateRange: searchMsgForm.msg.dateRange });
		} else {
			setRankForm({ ...rankForm, level: value });
		}
	};

	const renderMsgTD = () => {
		if (msgList && msgList?.length > 0) {
			return msgList?.map((msg, index) => {
				const tempObj = {};
				dashboardConstants.MSG_TABLE_COLUMN.forEach((column) => {
					tempObj[column.key] = msg[column.key];
				});

				return (
					<TableRow
						key={`${msg._id}_${index}_tr`}
						onClick={() => {
							HandleMsgRowOnClick(msg);
						}}
					>
						{Object.keys(tempObj).map((columnName, index) => {
							if (columnName === "status") {
								return (
									<TableTd key={`${msg._id}_${columnName}_${index}_td`} style={{ verticalAlign: "middle" }}>
										<Status status={getMsgStatus(msg)} />
									</TableTd>
								);
							} else if (columnName === "type" || columnName === "infoType") {
								return (
									<TableTd key={`${msg._id}_${columnName}_${index}_td`}>
										{messageConstants.Translate[columnName][tempObj[columnName]]}
									</TableTd>
								);
							} else if (columnName === "title") {
								return (
									<TableTd key={`${msg._id}_${columnName}_${index}_td`}>
										<TableRowTitle style={{ overflowX: "hidden", height: "20px" }}>{msg.schoolName}</TableRowTitle>
										<OverviewContainer>
											{getVipStatus(msg) && (
												<VipIconView>
													<VipText>VIP</VipText>
												</VipIconView>
											)}
											<Overview>{getOverflowByMsg(msg)}</Overview>
										</OverviewContainer>
										<TableContent>
											{msg.category && <MsgCategory>[{getCategoryShort(msg)}]</MsgCategory>} {tempObj[columnName]}
										</TableContent>
									</TableTd>
								);
							} else if (columnName === "scheduleTime") {
								return (
									<TableTd style={{ width: "60px", minWidth: "60px" }} key={`${msg._id}_${columnName}_${index}_td`}>
										<ScheduleTime>{tempObj[columnName] ? moment(tempObj[columnName]).format("YYYY年M月DD日 hh:mmA") : "-"}</ScheduleTime>
										<Status style={{ margin: "0 0 0 auto" }} status={getMsgStatus(msg)} />
									</TableTd>
								);
							}
							return <TableTd key={`${msg._id}_${columnName}_${index}_td`}>{tempObj[columnName]}</TableTd>;
						})}
					</TableRow>
				);
			});
		} else {
			return (
				<EmptyTableRow>
					<EmptyTableTd>暫未有帳戶動向</EmptyTableTd>
				</EmptyTableRow>
			);
		}
	};
	const renderMsgTableCell = renderMsgTD();

	const renderTableCell = renderTD(schoolList, dashboardConstants.SCHOOL_TABLE_COLUMN);

	const renderRankByRankTableCell = renderRankTD(
		subscriptionList,
		dashboardConstants.SCHOOL_BY_RANK_TABLE_COLUMN,
		DEFAULT_RANK_LIMIT,
		DEFAULT_PAGE,
		"overallRank"
	);

	const renderRankByNewsTableCell = renderRankTD(
		newsList,
		dashboardConstants.SCHOOL_BY_NEWS_TABLE_COLUMN,
		DEFAULT_RANK_LIMIT,
		DEFAULT_PAGE,
		"numMsg"
	);

	return (
		<>
			<PageContainer>
				<LeftContainer>
					<AdminMenubar section={generalConstants.NAV_TAB.DASHBOARD.key} />
				</LeftContainer>
				<RightContainer>
					<AdminUserInfo />

					<DashboardContent>
						<ContentLeft>
							<ContentContainer style={{ minHeight: "630px", marginTop: 20 }}>
								<Title>
									- 帳戶 <SchoolCounts>({getSchoolCounts()})</SchoolCounts>
								</Title>
								<SearchBar
									style={{ borderBottom: "#dddddd 1px solid", marginLeft: "-20px", marginRight: "-20px", padding: "5px 20px 4px 20px" }}
								>
									<SearchBarLeft>
										<RowInBlock marginBottom="0px">
											<div style={{ width: "30%", marginRight: "10px", maxWidth: 200 }}>
												<StandardSelect
													emptyText={"學校類型"}
													displayEmpty
													name={"level"}
													options={schoolConstants.OPTION.level}
													value={searchSchoolForm.level}
													handleChange={handleSelectChange}
												/>
											</div>
											<div style={{ width: "30%", marginRight: "10px", maxWidth: 150 }}>
												<StandardSelect
													emptyText={"地區"}
													displayEmpty
													name={"district"}
													options={schoolConstants.OPTION.district}
													value={searchSchoolForm.district}
													handleChange={handleSelectChange}
												/>
											</div>
											<div style={{ width: "30%", maxWidth: 100 }}>
												<StandardSelect
													emptyText={"性別"}
													displayEmpty
													name={"gender"}
													options={schoolConstants.OPTION.gender}
													value={searchSchoolForm.gender}
													handleChange={handleSelectChange}
												/>
											</div>
											<RefreshButton style={{ marginLeft: "5px" }} handleOnClick={handleRefreshFilter} label="重置選項"></RefreshButton>
										</RowInBlock>
									</SearchBarLeft>
								</SearchBar>
								<table style={{ width: "100%", borderCollapse: "collapse" }}>
									<tbody>{renderTableCell}</tbody>
								</table>
								<PaginationContainer>
									<Pagination count={schoolTotal} page={schoolPage} handlePageChange={handlePageChange} />
								</PaginationContainer>
							</ContentContainer>
						</ContentLeft>
						<ContentMid>
							<ContentContainer style={{ minHeight: "630px", marginLeft: 0, marginTop: 20 }}>
								<Title> - {searchMsgForm.msg.dateRange === "today" ? "今日帳戶動向" : "帳戶動向"}</Title>
								{renderTabs(searchMsgForm, setSearchMsgForm)}
								<TableContainer>
									<table style={{ width: "100%", borderCollapse: "collapse" }}>
										<tbody>{renderMsgTableCell}</tbody>
									</table>
								</TableContainer>
								<MsgFooter>
									{!_.isEmpty(searchMsgForm.msg.dateRange) && (
										<MsgFooterLeft>
											<Button
												onClick={() => {
													handleShowAllMsg();
												}}
											>
												<ViewHistoryIcon /> 瀏覽歷史動向
											</Button>
										</MsgFooterLeft>
									)}
									<MsgFooterRight>
										{msgList && msgList?.length > 0 && (
											<PaginationContainer style={{ float: "right" }}>
												<Pagination
													siblingCount={0}
													boundaryCount={1}
													count={msgTotal}
													page={msgPage}
													handlePageChange={handleMsgPageChange}
												/>
											</PaginationContainer>
										)}
									</MsgFooterRight>
								</MsgFooter>
							</ContentContainer>
						</ContentMid>
						<ContentRight>
							<ContentContainer style={{ minHeight: "288px", marginLeft: 0, marginTop: 20, marginBottom: 20 }}>
								<Title> - 帳戶排名</Title>
								{renderTabs(rankBySubscriptionForm, setRankBySubscriptionForm)}
								<table style={{ width: "100%", borderCollapse: "collapse" }}>
									<tbody>{renderRankByRankTableCell}</tbody>
								</table>
							</ContentContainer>

							<ContentContainer style={{ minHeight: "288px", marginLeft: 0, marginTop: 0 }}>
								<Title> - 新聞發佈</Title>
								{renderTabs(rankByNewsForm, setRankByNewsForm)}
								<table style={{ width: "100%", borderCollapse: "collapse" }}>
									<tbody>{renderRankByNewsTableCell}</tbody>
								</table>
							</ContentContainer>
						</ContentRight>
					</DashboardContent>
					<AdminFooter></AdminFooter>
				</RightContainer>
			</PageContainer>
		</>
	);
};

const DashboardContent = styled.div`
	display: flex;
	flex-direction: row;
`;

const ContentLeft = styled.div`
	flex: 0 0 45%;
`;

const ContentMid = styled.div`
	flex: 0 0 33%;
`;

const ContentRight = styled.div`
	flex: 0 0 22%;
`;

const Title = styled.div`
	font-weight: bold;
	margin: 0 0 5px 4px;
	font-size: 14px;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.43;
	letter-spacing: normal;
	text-align: left;
	color: #333399;
`;

const TableContainer = styled.div`
	height: 503px;
`;

const TableRow = styled.tr`
	border-bottom: 1px solid #dddddd;
	&:hover {
		background: #eeeeee;
		cursor: pointer;
	}
`;

const TableTd = styled.td`
	height: 20px;
	padding: 6px 0px 9px 0px;
	max-width: 120px;
`;

const TableContent = styled.div`
	height: 20px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`;

const EmptyTableRow = styled.tr``;

const EmptyTableTd = styled.td`
	height: 470px;
	padding: 6px 10px 9px 10px;
	text-align: center;
	color: #666666;
`;

const TableRowTitle = styled.div`
	font-size: 13px;
	font-weight: 500;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.46;
	letter-spacing: normal;
	text-align: left;
	color: #0085b5;
	height: 20px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`;

const OverviewContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
`;

const Overview = styled.div`
	margin: 0;
	font-size: 10px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.5;
	letter-spacing: normal;
	text-align: left;
	color: #666666;
`;

const VipIconView = styled.div`
	display: flex;
	width: 32px;
	flex-direction: row;
	margin-right: 5px;
`;

const VipText = styled.div`
	font-size: 12;
	font-weight: bold;
	color: #333333;
	background-color: #c8ae63;
	padding-left: 5px;
	padding-right: 5px;
`;

const MsgCategory = styled.span`
	color: red;
`;

const ScheduleTime = styled.div`
	margin: 0px 0px 15px 0px;
	font-size: 8px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.25;
	letter-spacing: normal;
	text-align: right;
	color: #9f9f9f;
`;

const RowInBlock = styled.div`
	display: flex;
	margin-bottom: ${(props) => props.marginBottom || "10px"};
	flex-direction: row;
`;

const ClosedBadge = styled.span`
	padding: 2px 6px 3px;
	border-radius: 3px;
	background-color: #fff0f2;
	margin: 9px 6px 9px 0;
	font-size: 10px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.5;
	letter-spacing: normal;
	color: #ff0000;
`;

const RankBadge = styled.div`
	display: flex;
	overflow: hidden;
	align-items: center;
	border-radius: 50%;
	justify-content: center;
	width: 20px;
	height: 20px;
	background-color: ${(props) => {
		if (props.rank === 3) return "#b59b53";
		if (props.rank === 2) return "#c0c0be";
		if (props.rank === 1) return "#d8c324";
		else return "#ffffff";
	}};
	border-color: ${(props) => {
		if (props.rank === 3) return "#b59b53";
		if (props.rank === 2) return "#c0c0be";
		if (props.rank === 1) return "#d8c324";
		else return "#dcdcdc";
	}};
	border-width: 1px;
	border-style: solid;
	font-size: 11px;
	font-weight: 600;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.18;
	letter-spacing: normal;
	color: ${(props) => (props.rank > 3 ? "#555555" : "#ffffff")};
`;

const MsgFooter = styled.div`
	display: flex;
	height: 53px;
`;
const MsgFooterLeft = styled.div`
	flex: 0 0 115px;
	padding: 20px 0 0px 0;
	& * {
		color: #1385b5;
	}
	& svg {
		fill: #1385b5;
		padding-right: 5px;
	}
`;
const MsgFooterRight = styled.div`
	flex: 1;
`;

const TabsContainer = styled.div`
	margin-left: -20px;
	margin-right: -20px;
	border-bottom: #dddddd 1px solid;
	padding: 2px 10px 10px 10px;
	margin-bottom: 5px;
`;

const SchoolCounts = styled.span`
	font-size: 11px;
	font-weight: 500;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.55;
	letter-spacing: normal;
	text-align: left;
	color: #666666;
`;
