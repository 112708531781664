export const reportConstants = {
	type: {
		RESET_REPORT_SUCCESS: "RESET_REPORT_SUCCESS",

		FETCH_REPORT_TARGET_REQUEST: "FETCH_REPORT_TARGET_REQUEST",
		FETCH_REPORT_TARGET_FAILURE: "FETCH_REPORT_TARGET_FAILURE",
		FETCH_REPORT_TARGET_SUCCESS: "FETCH_REPORT_TARGET_SUCCESS",

		FETCH_REPORT_LIST_REQUEST: "FETCH_REPORT_LIST_REQUEST",
		FETCH_REPORT_LIST_FAILURE: "FETCH_REPORT_LIST_FAILURE",
		FETCH_REPORT_LIST_SUCCESS: "FETCH_REPORT_LIST_SUCCESS",

		BYPASS_REPORT_REQUEST: "BYPASS_REPORT_REQUEST",
		BYPASS_REPORT_FAILURE: "BYPASS_REPORT_FAILURE",
		BYPASS_REPORT_SUCCESS: "BYPASS_REPORT_SUCCESS",

		HANDLE_REPORT_REQUEST: "HANDLE_REPORT_REQUEST",
		HANDLE_REPORT_FAILURE: "HANDLE_REPORT_FAILURE",
		HANDLE_REPORT_SUCCESS: "HANDLE_REPORT_SUCCESS",
	},
	TABLE_COLUMN: [
		{ key: "status", value: "狀態", searchable: false, width: "5%" },
		{ key: "targetType", value: "目標類型", searchable: false, width: "5%" },
		{ key: "text", value: "內容", searchable: true, width: "20%" },
		{ key: "actor", value: "用戶", searchable: false, width: "15%" },
		{ key: "reportCount", value: "舉報數量", searchable: false, width: "5%" },
		{ key: "time", value: "動態時間", searchable: false, width: "10%" },
		{ key: "reportDate", value: "舉報時間", searchable: false, width: "10%" },
		{ key: "handleDate", value: "處理時間", searchable: false, width: "10%" },
	],
	REPORT_LIST_COLUMN: [
		{ key: "reportBy", value: "舉報人", searchable: false, width: "15%" },
		{ key: "type", value: "類型", searchable: false, width: "5%" },
		{ key: "text", value: "舉報內容", searchable: true, width: "20%" },
		{ key: "createdDate", value: "舉報時間", searchable: false, width: "10%" },
		{ key: "updatedDate", value: "更新時間", searchable: false, width: "10%" },
		{ key: "status", value: "狀態", searchable: false, width: "5%" },
	],
	Translate: {
		targetType: {
			activity: "動態",
			parent: "家長",
			comment: "評語",
		},
		type: {
			SEXUAL_CONTENT: "色情內容",
			VIOLENT_OR_REPULSIVE_CONTENT: "暴力內容",
			HATEFUL_OR_ABUSIVE_CONTENT: "仇恨或歧視言論",
			HARMFUL_OR_DANGEROUS_ACTS: "騷擾、威脅或厭惡內容",
			CHILD_ABUSE: "兒童安全",
			PROMOTES_TERRORISM: "恐怖主義",
			SPAM_OR_MISLEADING: "垃圾內容",
			INFRINGES_MY_RIGHTS: "侵犯個人權利",
		},
		status: {
			PENDING: "待處理",
			COMPLETED: "完成",
			INPROGRESS: "處理中",
		},
		deleted: "已移除",
		blocked: "已封鎖",
	},

	DATA: {
		status: {
			PENDING: { key: "PENDING", label: "待處理", type: "PENDING" },
			COMPLETED: { key: "COMPLETED", label: "完成", type: "SUCCESS" },
			INPROGRESS: { key: "INPROGRESS", label: "處理中", type: "SUCCESS" },
		},
		targetType: {
			activity: "ACTIVITY",
			parent: "PARENT",
			comment: "COMMENT",
		},
		type: {
			SEXUAL_CONTENT: 1,
			VIOLENT_OR_REPULSIVE_CONTENT: 2,
			HATEFUL_OR_ABUSIVE_CONTENT: 3,
			HARMFUL_OR_DANGEROUS_ACTS: 4,
			CHILD_ABUSE: 5,
			PROMOTES_TERRORISM: 6,
			SPAM_OR_MISLEADING: 7,
			INFRINGES_MY_RIGHTS: 8,
			CAPTIONS_ISSUE: 9,
		},
	},
	OPTION: {
		type: [
			{ key: "SEXUAL_CONTENT", value: 1 },
			{ key: "VIOLENT_OR_REPULSIVE_CONTENT", value: 2 },
			{ key: "HATEFUL_OR_ABUSIVE_CONTENT", value: 3 },
			{ key: "HARMFUL_OR_DANGEROUS_ACTS", value: 4 },
			{ key: "CHILD_ABUSE", value: 5 },
			{ key: "PROMOTES_TERRORISM", value: 6 },
			{ key: "SPAM_OR_MISLEADING", value: 7 },
			{ key: "INFRINGES_MY_RIGHTS", value: 8 },
		],
		targetType: [
			{ key: "ACTIVITY", value: "動態" },
			{ key: "PARENT", value: "家長" },
			{ key: "COMMENT", value: "評論" },
		],
	},
};
