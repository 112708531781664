import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import FormControl from "@material-ui/core/FormControl";
import { default as MuiSelect } from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";

export const StandardSelect = (props) => {
	const { name, options, value, iconList, handleChange, displayEmpty, emptyText } = props;

	const renderItem = () => {
		if (!!options && options.length > 0) {
			return options.map((option, index) => {
				let icon;
				if (iconList) {
					icon = iconList[option.key];
				}
				return (
					<MenuItem key={`${name}_select_${index}`} value={option.key}>
						<RowInBlock>
							{icon && <Icon src={icon} />}
							{option.value}
						</RowInBlock>
					</MenuItem>
				);
			});
		}
	};

	const renderItems = renderItem();

	return (
		<FormControl variant="standard" fullWidth>
			<MuiSelect
				id={`${name}_select`}
				onChange={(event) => {
					if (handleChange) {
						handleChange({ key: event.target.name, value: event.target.value });
					}
				}}
				name={name}
				value={value}
				input={<Input labelwidth={0} />}
				displayEmpty={displayEmpty}
				style={{ fontSize: "12px" }}
			>
				{!!displayEmpty && (
					<MenuItem value={""} style={{ height: "36px" }}>
						{emptyText ? emptyText : ""}
					</MenuItem>
				)}
				{renderItems}
			</MuiSelect>
		</FormControl>
	);
};

StandardSelect.propTypes = {
	name: PropTypes.string,
	options: PropTypes.array,
	value: PropTypes.string,
	iconList: PropTypes.object,
	handleChange: PropTypes.func,
	displayEmpty: PropTypes.bool,
	emptyText: PropTypes.string,
};

StandardSelect.default = {
	displayEmpty: false,
	iconList: {},
};

const RowInBlock = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
`;

const Icon = styled.img`
	border-radius: 20%;
	width: 20px;
	height: 20px;
	margin-right: 5px;
`;
