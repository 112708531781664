import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import IconButton from "@material-ui/core/IconButton";
import facebookLogo from "resources/img/fb_icon.png";
import validator from "validator";

export const FacebookButton = (props) => {
	const { value, style, width, height } = props;
	const [facebookLink, setFacebookLink] = useState();
	const [validURL, setValidURL] = useState(false);

	useEffect(() => {
		if (value) {
			const modifiedUrl = value.trim().startsWith("http") ? value : `https://${value}`;
			setFacebookLink(modifiedUrl);
			setValidURL(validator.isURL(modifiedUrl));
		} else {
			setValidURL(false);
		}
	}, [value]);

	if (validURL) {
		return (
			<IconButton
				target="_black"
				href={facebookLink}
				size={"small"}
				style={{
					...style,
				}}
			>
				<img alt="" src={facebookLogo} width={width} height={height} />
			</IconButton>
		);
	} else {
		return null;
	}
};

FacebookButton.propTypes = {
	value: PropTypes.string,
	style: PropTypes.object,
	width: PropTypes.number,
	height: PropTypes.number,
};

FacebookButton.defaultProps = {
	width: 28,
	height: 28,
};
