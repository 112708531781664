export const diffConstants = {
	type: {
		UPDATE_DIFF_REQUEST: "UPDATE_DIFF_REQUEST",
		UPDATE_DIFF_FAILURE: "UPDATE_DIFF_FAILURE",
		UPDATE_DIFF_SUCCESS: "UPDATE_DIFF_SUCCESS",

		DATA_SCRAP_REQUEST: "DATA_SCRAP_REQUEST",
		DATA_SCRAP_FAILURE: "DATA_SCRAP_FAILURE",
		DATA_SCRAP_SUCCESS: "DATA_SCRAP_SUCCESS",

		SET_DATA_SCRAP_SEARCH_FILTER: "SET_DATA_SCRAP_SEARCH_FILTER",

		DATA_SCRAP_DETAIL_REQUEST: "DATA_SCRAP_DETAIL_REQUEST",
		DATA_SCRAP_DETAIL_FAILURE: "DATA_SCRAP_DETAIL_FAILURE",
		DATA_SCRAP_DETAIL_SUCCESS: "DATA_SCRAP_DETAIL_SUCCESS",

		DATA_SCRAP_GET_DIFFERENCES_BY_WEBSITE_ID_REQUEST: "DATA_SCRAP_GET_DIFFERENCES_BY_WEBSITE_ID_REQUEST",
		DATA_SCRAP_GET_DIFFERENCES_BY_WEBSITE_ID_FAILURE: "DATA_SCRAP_GET_DIFFERENCES_BY_WEBSITE_ID_FAILURE",
		DATA_SCRAP_GET_DIFFERENCES_BY_WEBSITE_ID_SUCCESS: "DATA_SCRAP_GET_DIFFERENCES_BY_WEBSITE_ID_SUCCESS",

		DATA_SCRAP_GET_DIFFERENCE_DETAIL_REQUEST: "DATA_SCRAP_GET_DIFFERENCE_DETAIL_REQUEST",
		DATA_SCRAP_GET_DIFFERENCE_DETAIL_FAILURE: "DATA_SCRAP_GET_DIFFERENCE_DETAIL_FAILURE",
		DATA_SCRAP_GET_DIFFERENCE_DETAIL_SUCCESS: "DATA_SCRAP_GET_DIFFERENCE_DETAIL_SUCCESS",

		DATA_SCRAP_DELETE_LINK_REQUEST: "DATA_SCRAP_DELETE_LINK_REQUEST",
		DATA_SCRAP_DELETE_LINK_FAILURE: "DATA_SCRAP_DELETE_LINK_FAILURE",
		DATA_SCRAP_DELETE_LINK_SUCCESS: "DATA_SCRAP_DELETE_LINK_SUCCCESS",

		DATA_SCRAP_ADD_LINK_REQUEST: "DATA_SCRAP_ADD_LINK_REQUEST",
		DATA_SCRAP_ADD_LINK_FAILURE: "DATA_SCRAP_ADD_LINK_FAILURE",
		DATA_SCRAP_ADD_LINK_SUCCESS: "DATA_SCRAP_ADD_LINK_SUCCCESS",
		ADD_LINK_DONE: "ADD_LINK_DONE",

		DATA_SCRAP_UPDATE_DIFFS_REQUEST: "DATA_SCRAP_UPDATE_DIFFS_REQUEST",
		DATA_SCRAP_UPDATE_DIFFS_FAILURE: "DATA_SCRAP_UPDATE_DIFFS_FAILURE",
		DATA_SCRAP_UPDATE_DIFFS_SUCCESS: "DATA_SCRAP_UPDATE_DIFFS_SUCCESS",

		DATA_SCRAP_UPDATE_WEBSITES_REQUEST: "DATA_SCRAP_UPDATE_WEBSITES_REQUEST",
		DATA_SCRAP_UPDATE_WEBSITES_FAILURE: "DATA_SCRAP_UPDATE_WEBSITES_FAILURE",
		DATA_SCRAP_UPDATE_WEBSITES_SUCCESS: "DATA_SCRAP_UPDATE_WEBSITES_SUCCESS",

		DATA_SCRAP_COMPARE_DIFFS_REQUEST: "DATA_SCRAP_COMPARE_DIFFS_REQUEST",
		DATA_SCRAP_COMPARE_DIFFS_FAILURE: "DATA_SCRAP_COMPARE_DIFFS_FAILURE",
		DATA_SCRAP_COMPARE_DIFFS_SUCCESS: "DATA_SCRAP_COMPARE_DIFFS_SUCCESS",

		RESET_TEMP_DIFF: "RESET_TEMP_DIFF",

		DATA_SCRAP_GEN_ALL_DIFF_REQUEST: "DATA_SCRAP_GEN_ALL_DIFF_REQUEST",
		DATA_SCRAP_GEN_ALL_DIFF_FAILURE: "DATA_SCRAP_GEN_ALL_DIFF_FAILURE",
		DATA_SCRAP_GEN_ALL_DIFF_SUCCESS: "DATA_SCRAP_GEN_ALL_DIFF_SUCCESS",

		DATA_SCRAP_ERROR_REQUEST: "DATA_SCRAP_ERROR_REQUEST",
		DATA_SCRAP_ERROR_FAILURE: "DATA_SCRAP_ERROR_FAILURE",
		DATA_SCRAP_ERROR_SUCCESS: "DATA_SCRAP_ERROR_SUCCESS",

		DATA_SCRAP_LOG_REQUEST: "DATA_SCRAP_LOG_REQUEST",
		DATA_SCRAP_LOG_FAILURE: "DATA_SCRAP_LOG_FAILURE",
		DATA_SCRAP_LOG_SUCCESS: "DATA_SCRAP_LOG_SUCCESS",

		DATA_SCRAP_UPDATE_LINK_REQUEST: "DATA_SCRAP_UPDATE_LINK_REQUEST",
		DATA_SCRAP_UPDATE_LINK_FAILURE: "DATA_SCRAP_UPDATE_LINK_FAILURE",
		DATA_SCRAP_UPDATE_LINK_SUCCESS: "DATA_SCRAP_UPDATE_LINK_SUCCCESS",
		UPDATE_LINK_DONE: "UPDATE_LINK_DONE",
	},
	DATA_SCRAP_TABLE_COLUMN: [
		{ key: "level", value: "學校類型", searchable: true, isNumber: false, width: "5%" },
		{ key: "name", value: "名稱", searchable: false, isNumber: false, width: "15%" },
		{ key: "district", value: "地區", searchable: true, isNumber: false, width: "5%" },
		{ key: "schoolNet", value: "校網", searchable: true, isNumber: false, width: "5%" },
		{ key: "gender", value: "性別", searchable: true, isNumber: false, width: "5%" },
		{ key: "noOfDiff", value: "未處理數量", searchable: false, isNumber: true, width: "10%" },
		{ key: "overallSubscription", value: "訂閱人數", searchable: false, isNumber: true, width: "5%" },
		{ key: "numMsg", value: "新聞發佈", searchable: false, isNumber: true, width: "5%" },
		{ key: "overallRank", value: "用戶排名", searchable: false, isNumber: false, width: "5%" },
		{ key: "lastUpdateTime", value: "最後更新時間", searchable: false, isNumber: false, width: "10%" },
	],
};
