/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useState, useEffect, useRef, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { generalConstants, statusConstants, socialNetworkConstants } from "_constants";
import { authAction, socialNetworkAction } from "actions";
import { history, AvatarHelper } from "_util/_helper";
import {
	SubmitButton,
	CancelButton,
	Loading,
	DangerButton,
	AlertDialog,
	ModalDialog,
	Status,
	LightBox,
	TextField,
	CourseImageUploader,
} from "components/general";
import ProgressiveRejectDebouncer from "_util/debouncers/ProgressiveRejectDebouncer";

import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import moment from "moment";
import _ from "lodash";

import CircularProgress from "@material-ui/core/CircularProgress";
import uuid from "react-native-uuid";

import { AdminMenubar, AdminUserInfo, AdminSectionbar, AdminFooter } from "components/admin";
import { PageContainer, LeftContainer, RightContainer, ContentContainer, TableRow, TableTh, THButton, TableTd } from "components/layout";
import { StandardSelect } from "components/general/standard";

import sendIcon from "resources/img/send_arrow.png";
import GeminiIcon from "resources/img/gemini.png";
import GPT3_5Icon from "resources/img/chatgpt3_5.png";
import GPT4Icon from "resources/img/chatgpt4.png";

const modelIconList = {
	"gemini-1.5-flash-latest": GeminiIcon,
	"gpt-3.5-turbo": GPT3_5Icon,
	"gpt-4o-mini": GPT4Icon,
};
export const PostDetailPage = () => {
	const location = useLocation();
	const [postKey, setPostKey] = useState(location?.state?.postKey);

	useEffect(() => {
		setPostKey(location?.state?.postKey);
	}, [location?.state]);

	const getRoundedDate = (minutes, d = new Date()) => {
		const ms = 1000 * 60 * minutes;
		const roundedDate = new Date(Math.ceil(d.getTime() / ms) * ms);

		return roundedDate;
	};

	const { isFetching, reviewPostSuccess, deletePostSuccess, post } = useSelector(({ socialNetwork }) => ({
		isFetching: socialNetwork.isFetching,
		reviewPostSuccess: socialNetwork.reviewPostSuccess,
		deletePostSuccess: socialNetwork.deletePostSuccess,
		post: socialNetwork.post,
	}));

	const initForm = {
		actor: null,
		target: null,
		time: getRoundedDate(5, new Date()),
		reviewed: false,
		deleted: false,
		text: "",
		imageUrl: [],
		updatedBy: null,
		commentText: "",
		commentImageUrl: "",
		questionText: "",
	};
	const initChatForm = {
		sessionId: "",
		chatHistory: [],
		quota: socialNetworkConstants.CHAT_MESSAGE_QUOTA,
		isReading: false,
	};

	const [debouncer] = useState(() => new ProgressiveRejectDebouncer(1 * 1000));

	const [formData, setFormData] = useState(initForm);
	const [chatFromData, setChatFromData] = useState(initChatForm);
	const [isLoading, setIsLoading] = useState(false);
	const [isDetailModalShow, setIsDetailModalShow] = useState(false);
	const [isCommentModalShow, setIsCommentModalShow] = useState(false);
	const [isReviewAlertShow, setIsReviewAlertShow] = useState(false);
	const [isDeletePostAlertShow, setIsDeletePostAlertShow] = useState(false);
	const [isSuggestCommentAlertShow, setIsSuggestCommentAlertShow] = useState(false);

	const [AIModel, setAIModel] = useState(socialNetworkConstants.AI_MODEL[0].key);
	const [selectModel, setSelectModel] = useState("");
	const [isConfimAIModelAlertShow, setIsConfimAIModelAlertShow] = useState(false);

	const [selectedComment, setSelectedComment] = useState(false);

	const dispatch = useDispatch();
	const answerRef = useRef(null);

	const handleBack = () => {
		history.goBack();
	};

	const handleReplace = () => {
		history.replace(generalConstants.PATH.POST_MANAGEMENT);
	};

	const scrollToLastView = () => {
		if (answerRef.current) {
			answerRef.current.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
			return true;
		}
		return false;
	};

	useEffect(() => {
		if (reviewPostSuccess) {
			dispatch({ type: socialNetworkConstants.type.RESET_POST_SUCCESS });
			toast.success(formData.commentText ? "已回應帖子" : "帖子已審閱");
			setFormData({ ...formData, commentText: "", commentImageUrl: "" });
			handleReplace();
		}
	}, [reviewPostSuccess, dispatch]);

	useEffect(() => {
		if (deletePostSuccess) {
			dispatch({ type: socialNetworkConstants.type.RESET_POST_SUCCESS });
			toast.success("帖子已刪除");
			handleReplace();
		}
	}, [deletePostSuccess, dispatch]);

	useEffect(() => {
		setIsLoading(isFetching);
	}, [isFetching]);

	useEffect(() => {
		if (postKey) {
			dispatch(socialNetworkAction.getPost(postKey));
		}
	}, []);

	useEffect(() => {
		if (post) {
			const tempForm = {};
			Object.keys(formData).forEach((key) => {
				tempForm[key] = post[key] !== undefined ? post[key] : formData[key];
			});
			setFormData(tempForm);
		}
	}, [post]);

	const handleChange = ({ key, value }) => {
		if (key === "AIModel") {
			if (chatFromData.chatHistory.length > 0) {
				setIsConfimAIModelAlertShow(true);
				setSelectModel(value);
				return;
			}
			setAIModel(value);
			return;
		}
		let tempData = { ...formData };
		tempData[key] = value;
		setFormData(tempData);
	};

	const handleChangeImage = (file) => {
		setFormData({ ...formData, commentImageUrl: file });
	};

	const handleClearImage = () => {
		setFormData({ ...formData, commentImageUrl: "" });
	};

	const handleCloseDetailModal = () => {
		setIsDetailModalShow(false);
	};

	const handleCloseCommentModal = () => {
		if (formData.commentText.trim().length > 0) {
			if (!window.confirm("你有未儲存的變更，確定不儲存下離開？")) return;
		}
		setIsCommentModalShow(false);
		setFormData({ ...formData, commentText: "", commentImageUrl: "" });
	};

	const handleCloseReviewAlert = () => {
		setIsReviewAlertShow(false);
	};

	const handleCloseDeletePostAlert = () => {
		setIsDeletePostAlertShow(false);
	};

	const handleCloseAIModelAlert = () => {
		setIsConfimAIModelAlertShow(false);
	};

	const handleCloseSuggestCommentAlert = () => {
		if (!_.isEmpty(chatFromData.sessionId)) {
			if (!window.confirm("所有建議內容將會消失，請先複製所需內容。確定離開？")) {
				setIsLoading(false);
				return;
			}
		}
		setChatFromData(initChatForm);
		setIsSuggestCommentAlertShow(false);
	};

	const handleConfirmAIModelAlert = () => {
		setIsConfimAIModelAlertShow(false);
		setAIModel(selectModel);
		setChatFromData(initChatForm);
		setSelectModel("");
		setFormData({ ...formData, questionText: formData.text });
	};

	const renderTH = () => {
		return socialNetworkConstants.COMMENT_LIST_COLUMN.map((column) => {
			return (
				<TableTh key={`${column.key}_table_header`}>
					<THButton key={`${column.key}_table_header`} type="button">
						<div style={{ textAlign: column.key === "status" ? "center" : "auto" }}>{column.value}</div>
					</THButton>
				</TableTh>
			);
		});
	};
	const renderTableHeader = renderTH();

	const renderTD = () => {
		if (post && post.commentList && post.commentList.length > 0) {
			return post.commentList.map((comment, index) => {
				const tempObj = {};
				socialNetworkConstants.COMMENT_LIST_COLUMN.forEach((column) => {
					tempObj[column.key] = comment[column.key];
				});
				return (
					<TableRow
						key={`${index}_tr`}
						onClick={() => {
							HandleRowOnClick(comment);
						}}
					>
						{Object.keys(tempObj).map((columnName, index) => {
							if (columnName === "actor") {
								return <TableTd key={`${columnName}_${index}_td`}>{tempObj[columnName]["nickname"]}</TableTd>;
							} else if (columnName === "time") {
								return <TableTd key={`${columnName}_${index}_td`}>{moment(tempObj[columnName]).format("YYYY年MM月DD日 hh:mmA")}</TableTd>;
							}
							return <TableTd key={`${columnName}_${index}_td`}>{tempObj[columnName]}</TableTd>;
						})}
					</TableRow>
				);
			});
		}
	};
	const renderTableCell = renderTD();

	const HandleRowOnClick = (item) => {
		setSelectedComment(item);
		setIsDetailModalShow(true);
	};

	const handleReviewOnly = () => {
		dispatch(socialNetworkAction.reviewPost({ key: postKey }));
	};

	const handleReviewWithComment = (text, image) => {
		if (text.trim().length === 0) {
			toast.error("請填寫回應內容");
			return;
		}
		let newData = { key: postKey, text };
		if (image) {
			newData = {
				...newData,
				image,
			};
		}
		dispatch(socialNetworkAction.reviewPost(newData));
	};

	const handleDeletePost = () => {
		dispatch(socialNetworkAction.deletePost({ key: postKey }));
	};

	const onTokenExpired = useCallback(() => {
		dispatch(authAction.kickout());
	}, [dispatch]);

	const throttleOnTokenExpired = useCallback(_.throttle(onTokenExpired, 1000, { trailing: false }), []);

	const handleSuggestComment = async (text, historyData) => {
		try {
			var newHistory = { ...historyData };
			setIsLoading(true);
			var sessionId = !_.isEmpty(chatFromData.sessionId) ? chatFromData.sessionId : uuid.v4();
			var url = `${process.env.REACT_APP_BACKEND_URL}/socialnetwork/post/suggestComment?postKey=${postKey}&question=${text}&sessionId=${sessionId}&model=${AIModel}`;
			await fetch(url, {
				credentials: "include",
				headers: {
					"Content-Type": "text/event-stream",
					"Access-Control-Allow-Origin": "*",
				},
				responseType: "stream",
				method: "GET",
			})
				.then(async (res) => {
					const reader = res.body.getReader();
					var fullDataString = "";
					// eslint-disable-next-line no-constant-condition
					while (true) {
						const { value, done } = await reader.read();
						const utf8Decoder = new TextDecoder("utf-8");
						let data = value ? utf8Decoder.decode(value, { stream: true }) : "";
						if (res.status === 401) {
							throttleOnTokenExpired();
						} else if (!res.ok) {
							try {
								const errorData = JSON.parse(data);
								toast.error(errorData.message);
							} catch (error) {
								//
							}
							setIsLoading(false);
							newHistory = historyData.map((item) => {
								if (!item.isAnswer) {
									return { ...item, isAnswer: true };
								}
								return item;
							});
							setChatFromData({
								...chatFromData,
								sessionId,
								chatHistory: newHistory,
								isReading: false,
							});
							return;
						}
						fullDataString += data;
						newHistory = historyData.map((item) => {
							if (!item.isAnswer) {
								return { ...item, answerText: fullDataString, isAnswer: false };
							}
							return item;
						});
						if (done) {
							setIsLoading(false);
							newHistory = newHistory.map((item) => {
								if (!item.isAnswer) {
									return { ...item, isAnswer: true };
								}
								return item;
							});
							var newFormData = {
								...chatFromData,
								sessionId,
								quota: chatFromData.quota - 1,
								chatHistory: newHistory,
								isReading: false,
							};
							scrollToLastView();
							setChatFromData(newFormData);
							break;
						} else {
							scrollToLastView();
							setChatFromData({
								...chatFromData,
								sessionId,
								chatHistory: newHistory,
								isReading: true,
							});
						}
					}
				})
				.catch((error) => {
					setIsLoading(false);
					setChatFromData({
						...chatFromData,
						isReading: false,
					});
					toast.error(error.error);
				});
		} catch (error) {
			setIsLoading(false);
			setChatFromData({
				...chatFromData,
				isReading: false,
			});
		}
	};

	const getText = (text) => {
		return text ? text.replace(/\n/g, "<br />") : "";
	};

	const getStatus = (item) => {
		let thekey = item.deleted ? "POST_DELETED" : item.reviewed ? "POST_REVIEWED" : "POST_NO_REVIEW";
		return statusConstants.status[thekey].key;
	};

	if (!postKey) return null;
	return (
		<>
			{!!isLoading && <Loading />}
			<AlertDialog
				active={isReviewAlertShow}
				handleOnClose={handleCloseReviewAlert}
				handleOnYes={handleReviewOnly}
				handleOnOption={() => {
					setIsReviewAlertShow(false);
					setIsCommentModalShow(true);
				}}
				noLabel="取消"
				yesLabel="確定"
				optionLabel="審閱並作出回應"
				title="確定審閱帖子？"
			/>
			<AlertDialog
				active={isDeletePostAlertShow}
				handleOnClose={handleCloseDeletePostAlert}
				handleOnYes={handleDeletePost}
				title={"確定刪除帖子？"}
			/>
			<AlertDialog
				active={isConfimAIModelAlertShow}
				handleOnClose={handleCloseAIModelAlert}
				handleOnYes={handleConfirmAIModelAlert}
				title={"現有的建議內容將不會保留，確定要切換AI語言模型?"}
			/>
			<ModalDialog active={isDetailModalShow} handleOnClose={handleCloseDetailModal} noLabel="關閉" title={"-帖子內容"}>
				<PostDetailContainer>
					<PostDetailBlock>
						<Subtitle>評論發佈人</Subtitle>
						<RowInBlock style={{ alignItems: "center", marginTop: "10px" }}>
							{!_.isEmpty(selectedComment.actor?.photoUrl) && (
								<Avatar showBadge={false} src={AvatarHelper(selectedComment.actor.photoUrl)} type={"parent"} />
							)}
							<TextFieldLabel style={{ marginLeft: "10px" }}>{selectedComment.actor ? selectedComment.actor.nickname : "-"}</TextFieldLabel>
						</RowInBlock>
					</PostDetailBlock>
					<PostDetailBlock>
						<Subtitle>評論內容</Subtitle>
						<PostDetail dangerouslySetInnerHTML={{ __html: selectedComment.text == null ? "沒有內容" : getText(selectedComment.text) }} />
						<>
							{selectedComment && !_.isEmpty(selectedComment.imageUrl) && (
								<div>
									<LightBox images={selectedComment.imageUrl} />
								</div>
							)}
						</>
						<PostDetailTime>發佈時間 {moment(selectedComment.time).format("YYYY年MM月DD日 hh:mmA")}</PostDetailTime>
					</PostDetailBlock>
				</PostDetailContainer>
			</ModalDialog>
			<ModalDialog
				active={isSuggestCommentAlertShow}
				handleOnClose={handleCloseSuggestCommentAlert}
				noLabel="關閉"
				title={`-回應建議 查詢次數(${chatFromData.quota}/5)`}
			>
				<PostDetailContainer>
					<PostDetailBlock>
						<RowInBlock style={{ width: "40%", alignItems: "center" }}>
							<Subtitle style={{ width: "80%" }}>AI語言模型</Subtitle>
							<StandardSelect
								name={"AIModel"}
								options={socialNetworkConstants.AI_MODEL}
								iconList={modelIconList}
								value={AIModel}
								handleChange={handleChange}
							/>
						</RowInBlock>
						<Subtitle>建議內容</Subtitle>
						<PostDetail height="400px">
							{chatFromData.chatHistory.map((item, index) => {
								return (
									<div key={`chat_history_item_${index}`}>
										<RowInBlock style={{ flex: 1, alignItems: "flex-end", justifyContent: "flex-end" }}>
											<BubbleGroup
												style={{
													marginTop: "10px",
													paddingRight: "10px",
													paddingLeft: "10px",
													paddingTop: "10px",
													paddingBottom: "5px",
													backgroundColor: "#333399",
													borderRadius: "10px",
												}}
											>
												<QuestionLabel dangerouslySetInnerHTML={{ __html: getText(item.questionText) }} />
											</BubbleGroup>
										</RowInBlock>
										<RowInBlock style={{ flex: 1 }}>
											{!_.isEmpty(item.answerText) ? (
												<BubbleGroup
													style={{
														marginTop: "10px",
														paddingRight: "10px",
														paddingLeft: "10px",
														paddingTop: "5px",
														backgroundColor: "#F1F3F2",
														borderRadius: "10px",
													}}
												>
													<AnswerLabel ref={answerRef} dangerouslySetInnerHTML={{ __html: getText(item.answerText) }} />
												</BubbleGroup>
											) : (
												<>{!item.isAnswer && <CircularProgress />}</>
											)}
										</RowInBlock>
									</div>
								);
							})}
						</PostDetail>
						{chatFromData.quota > 0 && (
							<div>
								<Subtitle>查問內容</Subtitle>
								<RowInBlock
									style={{
										marginTop: "5px",
										textAlign: "right",
										alignItems: "center",
										paddingRight: "10px",
									}}
								>
									<TextField
										placeholder="查問內容"
										multiline
										maxrows={10}
										variant="outlined"
										value={formData.questionText}
										name="questionText"
										handleChange={handleChange}
									/>
									{!chatFromData.isReading && (
										<SendButton
											src={sendIcon}
											onClick={() => {
												if (formData.questionText.trim().length < socialNetworkConstants.MINIMUM_QUESTION_LENGTH) {
													toast.error(`資料不足,請輸入至少${socialNetworkConstants.MINIMUM_QUESTION_LENGTH}個字再嘗試`);
													return;
												}
												if (!debouncer.token()) {
													return;
												}
												const historyData = [
													...chatFromData.chatHistory,
													{ questionText: formData.questionText, answerText: "", isAnswer: false },
												];
												setFormData({
													...formData,
													questionText: "",
												});
												setChatFromData({
													...chatFromData,
													chatHistory: historyData,
													isReading: true,
												});
												scrollToLastView();
												handleSuggestComment(formData.questionText, historyData);
											}}
										/>
									)}
								</RowInBlock>
							</div>
						)}
					</PostDetailBlock>
					<PostDetailBlock>
						<Subtitle style={{ marginBottom: "5px" }}>回應內容</Subtitle>
						<TextField
							placeholder="回應內容"
							multiline
							rows={6}
							value={formData.commentText}
							name="commentText"
							handleChange={handleChange}
						/>
						<Block />
						<Subtitle style={{ marginBottom: "5px" }}>回應相片</Subtitle>
						<CourseImageUploader
							src={formData.commentImageUrl || ""}
							handleChange={handleChangeImage}
							handleClear={handleClearImage}
							noImageLabel="沒有圖片"
							isCover={true}
						/>
						<Block />
						<RowInBlock
							style={{
								maxHeight: "40px",
								textAlign: "right",
								justifyContent: "flex-end",
								paddingRight: "10px",
							}}
						>
							<SubmitButton
								label={"發表"}
								disabled={false}
								style={{ marginRight: "15px" }}
								handleOnClick={() => {
									handleReviewWithComment(formData.commentText, formData.commentImageUrl);
								}}
							/>
						</RowInBlock>
					</PostDetailBlock>
				</PostDetailContainer>
			</ModalDialog>
			<ModalDialog active={isCommentModalShow} handleOnClose={handleCloseCommentModal} noLabel="關閉" title={"發表回應"}>
				<PostDetailContainer>
					{!!isLoading && (
						<LoadingContainer>
							<LoadingBox>
								<CircularProgress />
							</LoadingBox>
						</LoadingContainer>
					)}
					<PostDetailBlock>
						<Subtitle>帖子發佈人</Subtitle>
						<RowInBlock style={{ alignItems: "center", marginTop: "10px" }}>
							{!_.isEmpty(formData.target?.photoUrl) && (
								<Avatar showBadge={false} src={AvatarHelper(formData.actor.photoUrl)} type={"parent"} />
							)}
							<TextFieldLabel style={{ marginLeft: "10px" }}>{formData.actor ? formData.actor.nickname : "-"}</TextFieldLabel>
						</RowInBlock>
						<Subtitle>發佈時間</Subtitle>
						<TextFieldLabel> {moment(formData.time).format("YYYY年MM月DD日 hh:mmA")}</TextFieldLabel>
						<Subtitle>帖子內容</Subtitle>
						<PostDetail dangerouslySetInnerHTML={{ __html: formData.text == null ? "沒有內容" : getText(formData.text) }} />
						<>
							{formData && !_.isEmpty(formData.imageUrl) && (
								<div>
									<LightBox images={formData.imageUrl} />
								</div>
							)}
						</>
					</PostDetailBlock>
					<PostDetailBlock>
						<Subtitle>回應內容</Subtitle>
						<TextField
							placeholder="回應內容"
							multiline
							rows={6}
							value={formData.commentText}
							name="commentText"
							handleChange={handleChange}
						/>
						<Block />
						<Subtitle>回應相片</Subtitle>
						<CourseImageUploader
							src={formData.commentImageUrl || ""}
							handleChange={handleChangeImage}
							handleClear={handleClearImage}
							noImageLabel="沒有圖片"
							isCover={true}
						/>
						<Block />
						<RowInBlock
							style={{
								maxHeight: "40px",
								textAlign: "right",
								justifyContent: "flex-end",
								paddingRight: "10px",
							}}
						>
							<SubmitButton
								label={"回應建議"}
								disabled={false}
								style={{ marginRight: "15px" }}
								handleOnClick={() => {
									setIsSuggestCommentAlertShow(true);
									setFormData({ ...formData, questionText: formData.text });
								}}
							/>
							<SubmitButton
								label={"發表"}
								disabled={false}
								style={{ marginRight: "15px" }}
								handleOnClick={() => {
									handleReviewWithComment(formData.commentText, formData.commentImageUrl);
								}}
							/>
						</RowInBlock>
					</PostDetailBlock>
				</PostDetailContainer>
			</ModalDialog>
			<PageContainer>
				<LeftContainer>
					<AdminMenubar section={generalConstants.NAV_TAB.POST_MANAGEMENT.key} />
				</LeftContainer>
				<RightContainer>
					<AdminUserInfo />
					<AdminSectionbar
						title={generalConstants.NAV_TAB.POST_DETAIL.key}
						sublabel={`評論數量: ${post && post.commentList && post.commentList.length ? post.commentList.length : "0"}`}
						locationPathArray={[
							{ title: "主頁面", isAction: true, target: generalConstants.PATH.DASHBOARD },
							{
								title: generalConstants.TAB_NAME[generalConstants.NAV_TAB.POST_MANAGEMENT.key],
								isAction: true,
								target: generalConstants.PATH.POST_MANAGEMENT,
							},
							{
								title: generalConstants.TAB_NAME[generalConstants.NAV_TAB.POST_DETAIL.key],
								isAction: false,
								target: "",
							},
						]}
					/>
					<ContentContainer style={{ display: "flex", flex: 2, flexWrap: "wrap", marginBottom: "18px" }}>
						<Title>-帖子詳情</Title>
						<ContentLeft>
							<BlockGroup>
								<Block marginTop="10px">
									<>
										<Subtitle>狀態</Subtitle>
										<TextFieldLabel>
											<Status style={{ margin: "0 auto 0 0" }} status={getStatus(formData)} />
										</TextFieldLabel>
									</>
								</Block>
								<Block marginTop="10px">
									<>
										<Subtitle>發佈時間</Subtitle>
										<TextFieldLabel> {moment(formData.time).format("YYYY年MM月DD日 hh:mmA")}</TextFieldLabel>
									</>
								</Block>
							</BlockGroup>

							<BlockGroup>
								<Block>
									<>
										<Subtitle>發佈人</Subtitle>
										<RowInBlock style={{ alignItems: "center", marginTop: "10px" }}>
											{!_.isEmpty(formData.actor?.photoUrl) && (
												<Avatar showBadge={false} src={AvatarHelper(formData.actor.photoUrl)} type={"parent"} />
											)}
											<TextFieldLabel style={{ marginLeft: "10px" }}>{formData.actor ? formData.actor.nickname : "-"}</TextFieldLabel>
										</RowInBlock>
									</>
								</Block>
							</BlockGroup>
							<BlockGroup>
								<Block>
									<>
										<Subtitle>發佈對象</Subtitle>
										<RowInBlock style={{ alignItems: "center", marginTop: "10px" }}>
											{!_.isEmpty(formData.target?.photoUrl) && (
												<Avatar showBadge={false} src={AvatarHelper(formData.target.photoUrl)} type={"parent"} />
											)}
											<TextFieldLabel style={{ marginLeft: "10px" }}>{formData.target ? formData.target.nickname : "-"}</TextFieldLabel>
										</RowInBlock>
									</>
								</Block>
							</BlockGroup>
						</ContentLeft>
						<ContentRight>
							<Block marginTop="10px">
								<>
									<Subtitle>帖子內容</Subtitle>
									<PostDetail height="109px" dangerouslySetInnerHTML={{ __html: getText(formData.text) }} />
								</>
							</Block>
							<BlockGroup>
								<Block>
									<>
										{!_.isEmpty(formData.imageUrl) && (
											<div>
												<LightBox images={formData.imageUrl} />
											</div>
										)}
									</>
								</Block>

								<RowInBlock
									style={{
										maxHeight: "40px",
										textAlign: "right",
										paddingTop: "100px",
										justifyContent: "flex-end",
										paddingRight: "10px",
									}}
								>
									<CancelButton label={"返回"} style={{ marginRight: "15px" }} handleOnClick={handleBack} />
									{formData.reviewed && !formData.deleted && (
										<SubmitButton
											label={"發表回應"}
											disabled={false}
											style={{ marginRight: "15px" }}
											handleOnClick={() => {
												setIsCommentModalShow(true);
											}}
										/>
									)}
									{!formData.reviewed && !formData.deleted && (
										<SubmitButton
											label={"審閱"}
											disabled={false}
											style={{ marginRight: "15px" }}
											handleOnClick={() => {
												setIsReviewAlertShow(true);
											}}
										/>
									)}
									{!formData.deleted && (
										<DangerButton
											disabled
											label={"刪除帖子"}
											handleOnClick={() => {
												setIsDeletePostAlertShow(true);
											}}
										/>
									)}
								</RowInBlock>
							</BlockGroup>
						</ContentRight>
					</ContentContainer>

					<ContentContainer style={{ paddingTop: 0 }}>
						<Block marginTop="0">
							<OverflowScroll>
								<table style={{ width: "100%", borderCollapse: "collapse", tableLayout: "fixed" }}>
									<thead>
										<tr>{renderTableHeader}</tr>
									</thead>
									<tbody>{renderTableCell}</tbody>
								</table>
							</OverflowScroll>
						</Block>
					</ContentContainer>
					<AdminFooter></AdminFooter>
				</RightContainer>
			</PageContainer>
		</>
	);
};

const ContentLeft = styled.div`
	flex: 0 0 400px;
	border-right: 1px solid #dddddd;
`;

const ContentRight = styled.div`
	flex: 1;
	padding-left: 20px;
`;

const BlockGroup = styled.div`
	display: flex;
	flex-direction: row;
`;

const BubbleGroup = styled.div`
	display: flex;
	flex-direction: row;
	max-width: 20vw;
`;

const Block = styled.div`
	padding: 10px;
	flex: ${(props) => props.flex || 1};
`;

const RowInBlock = styled.div`
	display: flex;
	margin-bottom: ${(props) => props.marginBottom || "10px"};
	flex-direction: row;
`;

const Title = styled.div`
	font-weight: bold;
	margin: 0 0 12px 4px;
	font-size: 14px;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.43;
	letter-spacing: normal;
	text-align: left;
	color: #333399;
	width: 100%;
`;

const Subtitle = styled.div`
	color: black;
	margin-bottom: ${(props) => props.marginBottom || "3px"};
	font-size: 14px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.43;
	letter-spacing: normal;
`;

const TextFieldLabel = styled.div`
	color: black;
	font-size: 13px;
	line-height: 1.5;
	margin-bottom: 6px;

	font-weight: 500;
	font-stretch: normal;
	font-style: normal;
	letter-spacing: normal;
	color: #333333;
`;

const Avatar = styled.img`
	border-radius: 50%;
	width: 50px;
	height: 50px;
	border: #aaaaaa 1px solid;
`;

const OverflowScroll = styled.div`
	margin-bottom: 20px;
`;

const PostDetailContainer = styled.div`
	display: flex;
	margin: 0 -10px;
`;

const PostDetailBlock = styled.div`
	flex: 1;
	padding: 0 10px;
`;

const PostDetail = styled.div`
	height: ${(props) => props.height || "237px"};
	padding: 8px 10px 10px;
	border-radius: 3px;
	border: solid 1px #cccccc;
	background-color: #ffffff;
	margin: 5px 0;
	overflow-y: scroll;
`;

const PostDetailTime = styled.div`
	font-size: 11px;
	font-weight: 500;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.55;
	letter-spacing: normal;
	text-align: right;
	color: #838383;
`;

const LoadingContainer = styled.div`
	height: 100%;
	width: 100%;
	background-color: white;
	position: absolute;
	z-index: 100;
	opacity: 0.7;
	align-items: center;
	justify-content: center;
`;

const LoadingBox = styled.div`
	display: flex;
	position: absolute;
	top: 35%;
	left: 45%;
`;

const SendButton = styled.img`
	max-width: 30px;
	max-height: 30px;
	background-color: #333399;
	border-radius: 25px;
	margin-left: 10px;
	padding-top: 3px;
	padding-left: 15px;
	padding-right: 15px;
`;

const QuestionLabel = styled.div`
	color: white;
	font-size: 13px;
	line-height: 1.5;
	margin-bottom: 6px;
	font-weight: 500;
	letter-spacing: normal;
`;

const AnswerLabel = styled.div`
	font-size: 13px;
	line-height: 1.5;
	margin-bottom: 6px;

	font-weight: 500;
	font-stretch: normal;
	font-style: normal;
	letter-spacing: normal;
	color: #333333;
`;
