import React, { useState, useEffect } from "react";
import { Prompt } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { Loading, Select, TextField, SubmitButton, CancelButton, Checkbox, SingleImageUploader } from "components/general";
import _ from "lodash";
import { generalConstants, schoolConstants, schoolDataConstants, organizationConstants } from "_constants";
import { organizationService } from "services";
import { schoolAction } from "actions";
import { useLocation } from "react-router-dom";
import { history } from "_util/_helper";
import { toast } from "react-toastify";
import { AsyncPaginate } from "react-select-async-paginate";
import { AdminMenubar, AdminUserInfo, AdminSectionbar, AdminFooter } from "components/admin";
import { PageContainer, LeftContainer, RightContainer, ContentContainer } from "components/layout";

const DEFAULT_PAGE = 1;
const DEFAULT_LIMIT = 5;
const FROM_ORGANIZATION = "organization";
export const EditSchoolPage = () => {
	const location = useLocation();
	const [key, setSchoolKey] = useState(location?.state?.schoolKey);
	const [prevPage, setPrevPage] = useState(location?.state?.from);

	useEffect(() => {
		setPrevPage(location?.state?.from);
		setSchoolKey(location?.state?.schoolKey);
	}, [location?.state]);

	const { isFetching, createSchoolSuccess, updateSchoolSuccess, school, loggedIn, organizationObj } = useSelector(
		({ school, auth, organization }) => {
			const res = {
				isFetching: school.isFetching,
				updateSchoolSuccess: school.updateSchoolSuccess,
				createSchoolSuccess: school.createSchoolSuccess,
				loggedIn: auth.loggedIn,
			};
			if (key) res.school = school.school;
			if (prevPage === FROM_ORGANIZATION) res.organizationObj = organization.organization;
			return res;
		}
	);

	const baseURL = `${process.env.REACT_APP_SCHOOL_BADGE_LINK}`;
	const schoolIdLimit = parseInt(process.env.REACT_APP_SCHOOL_ID_LENGTH);
	const [isLoading, setIsLoading] = useState(false);
	const initForm = {
		key: "",
		level: schoolConstants.level.PRIMARY.key,
		//email: "",
		name: ["", ""],
		address: ["", ""],
		telNo: "",
		faxNo: "",
		website: "",
		gender: schoolConstants.OPTION.gender[0].key,
		religion: "",
		financeType: schoolConstants.OPTION.financeType.PRIMARY[0].key,
		session: [schoolConstants.OPTION.session[0].key],
		district: schoolConstants.OPTION.district[0].key,
		linkMode: "",
		schoolNet: "",
		kes: "",
		category: schoolConstants.OPTION.category.PRIMARY[0].key,
		status: schoolConstants.status.ACTIVE.key,
		// addToCalendar: false,
		clearImage: false,
		schoolBadge: null,
		latitude: "",
		longitude: "",
		vipOnly: false,
	};

	const [formData, setFormData] = useState(initForm);
	const [isChanged, setIsChanged] = useState(false);
	const [isSaved, setIsSaved] = useState(false);
	const dispatch = useDispatch();

	const [selectedValue, setSelectedValue] = useState(null);

	useEffect(() => {
		if (key) dispatch(schoolAction.getSchoolByKey(key, {}));
	}, [key, dispatch]);

	useEffect(() => {
		setIsLoading(isFetching);
	}, [isFetching]);

	const handleChange = ({ key, value }) => {
		let tempData = { ...formData };
		if (key === "level") {
			if (value !== tempData.level) {
				if (value === "SECONDARY") {
					tempData.category = schoolConstants.OPTION.category.SECONDARY[0].key;
					tempData.financeType = schoolConstants.OPTION.financeType.SECONDARY[0].key;
				} else if (value === "PRIMARY") {
					_.pull(tempData.session, "EVENING");
					tempData.category = schoolConstants.OPTION.category.PRIMARY[0].key;
					tempData.financeType = schoolConstants.OPTION.financeType.PRIMARY[0].key;
				} else if (value === "KINDERGARTEN") {
					_.pull(tempData.session, "EVENING");
					tempData.category = schoolConstants.OPTION.category.KINDERGARTEN[0].key;
					tempData.financeType = schoolConstants.OPTION.financeType.KINDERGARTEN[0].key;
				} else if (value === "KINDERGARTEN-CUM-CHILD CARE CENTRES") {
					_.pull(tempData.session, "EVENING");
					tempData.category = schoolConstants.OPTION.category["KINDERGARTEN-CUM-CHILD CARE CENTRES"][0].key;
					tempData.financeType = schoolConstants.OPTION.financeType["KINDERGARTEN-CUM-CHILD CARE CENTRES"][0].key;
				}
			}
			tempData[key] = value;
		} else if (key === "name0") {
			tempData.name[0] = value;
		} else if (key === "name1") {
			tempData.name[1] = value;
		} else if (key === "address0") {
			tempData.address[0] = value;
		} else if (key === "address1") {
			tempData.address[1] = value;
		} else if (key === "kes") {
			tempData.kes = tempData.kes === "Y" ? "" : "Y";
		} else if (key === "session") {
			if (_.includes(tempData.session, value)) {
				_.pull(tempData.session, value);
			} else {
				tempData.session.push(value);
			}
		} else if (key === "vipOnly") {
			tempData.vipOnly = !tempData.vipOnly;
		} else {
			tempData[key] = value;
		}

		setFormData(tempData);

		setIsChanged(true);
	};

	const getCategoryOption = (inputLevel) => {
		switch (inputLevel) {
			case "SECONDARY":
				return schoolConstants.OPTION.category.SECONDARY;
			case "PRIMARY":
				return schoolConstants.OPTION.category.PRIMARY;
			case "KINDERGARTEN":
				return schoolConstants.OPTION.category.KINDERGARTEN;
			case "KINDERGARTEN-CUM-CHILD CARE CENTRES":
				return schoolConstants.OPTION.category["KINDERGARTEN-CUM-CHILD CARE CENTRES"];
		}
	};

	const validateInputs = () => {
		if (!formData.name[0] && !formData.name[1]) {
			toast.error("請填寫學校名稱");
			return false;
		}

		if (!formData.address[0] && !formData.address[1]) {
			toast.error("請填寫地址");
			return false;
		}

		if ((formData.latitude && !formData.longitude) || (!formData.latitude && formData.longitude)) {
			toast.error("請填寫經緯度");
			return false;
		}

		if (formData.status && !_.has(schoolConstants.status, formData.status)) {
			toast.error("狀態輸入有誤");
			return false;
		}

		if (isBureau(key)) return true;

		if (!_.has(schoolConstants.gender, formData.gender)) {
			toast.error("學生性別輸入有誤");
			return false;
		}
		if (formData.religion && !_.has(schoolConstants.religion, formData.religion)) {
			toast.error("宗教輸入有誤");
			return false;
		}
		if (!_.has(schoolConstants.level, formData.level)) {
			toast.error("學校類型輸入有誤");
			return false;
		}
		if (
			formData.level === schoolConstants.level.PRIMARY.key &&
			!_.isEmpty(formData.schoolNet) &&
			!_.has(schoolConstants.schoolNet, formData.schoolNet)
		) {
			toast.error("校網輸入有誤");
			return false;
		}

		if (formData.session && !_.isArray(formData.session)) {
			toast.error("上課時間輸入有誤");
		}

		_.forEach(formData.session, function (s) {
			if (formData.level === schoolConstants.level.SECONDARY.key && s === "EVENING") {
				return;
			} else if (!_.has(schoolConstants.session, s)) {
				toast.error("上課時間輸入有誤");
			}
		});

		if (!_.has(schoolConstants.district, formData.district)) {
			toast.error("區域輸入有誤");
			return false;
		}

		if (!schoolConstants.OPTION.financeType[formData.level].map((a) => a.key).includes(formData.financeType)) {
			toast.error("財政類型輸入有誤");
			return false;
		}
		if (
			formData.level === schoolConstants.level.PRIMARY.key &&
			!_.isEmpty(formData.linkMode) &&
			!_.has(schoolConstants.linkMode, formData.linkMode)
		) {
			toast.error("連結模式輸入有誤");
			return false;
		}
		if (
			formData.level !== schoolConstants.level.PRIMARY.key &&
			formData.level !== schoolConstants.level.SECONDARY.key &&
			formData.kes &&
			!_.has(schoolConstants.kes, formData.kes)
		) {
			toast.error("幼稚園教育計劃輸入有誤");
			return false;
		}
		if (isNaN(formData.latitude) || isNaN(formData.longitude)) {
			toast.error("經緯度輸入有誤");
			return false;
		}

		return true;
	};

	const validateIDInput = (schoolId) => {
		if (!/\d+/.test(schoolId) || _.size(schoolId) !== schoolIdLimit) {
			toast.error(`請填寫${schoolIdLimit}位數字的學校ID`);
			return false;
		}
		return true;
	};

	const handleChangeImage = (file) => {
		setFormData({ ...formData, clearImage: false, schoolBadge: file });
		setIsChanged(true);
	};

	const handleClearImage = () => {
		setFormData({ ...formData, clearImage: true, schoolBadge: null });
		setIsChanged(true);
	};

	const handleSubmit = () => {
		if (!validateInputs()) return;

		if (!key && !validateIDInput(formData.key)) return;

		const data = {
			key: key || formData.key,
			name: formData.name,
			status: formData.status,
		};
		if (!isBureau(key)) {
			_.merge(data, {
				address: formData.address,
				telNo: formData.telNo,
				faxNo: formData.faxNo,
				website: formData.website,
				gender: formData.gender,
				religion: formData.religion,
				level: formData.level,
				session: formData.session,
				district: formData.district,
				financeType: formData.financeType,
				category: formData.category,
				schoolBadge: formData.schoolBadge,
				organizationKey: organizationObj?._key || "",
				latitude: Number(formData.latitude),
				longitude: Number(formData.longitude),
				vipOnly: formData.vipOnly,
			});

			if (formData.level === "PRIMARY") {
				_.merge(data, {
					schoolNet: formData.schoolNet,
					linkMode: formData.linkMode,
				});
			}
			if (formData.kes) {
				_.merge(data, {
					kes: formData.kes,
				});
			}
			if (formData.clearImage) {
				_.merge(data, {
					clearImage: formData.clearImage,
				});
			}
			if (selectedValue != null) {
				_.merge(data, {
					organizationKey: selectedValue.value || "",
				});
			}
		}
		if (key) {
			_.merge(data, {
				rev: school._rev,
			});
			dispatch(schoolAction.updateSchool(data));
		} else {
			dispatch(schoolAction.createSchool(data));
		}
	};

	useEffect(() => {
		if (!school) return;
		const tempForm = {};
		Object.keys(formData).forEach((key) => {
			switch (key) {
				case "key":
					tempForm[key] = school["_key"] !== undefined ? school["_key"] : formData[key];
					break;
				case "religion":
					tempForm[key] = _.has(schoolConstants.religion, school[key]) ? school[key] : formData[key];
					break;
				case "session":
					if (!_.isArray(school[key])) tempForm[key] = formData[key];
					else {
						tempForm[key] = [];
						_.forEach(school[key], function (s) {
							if (_.has(schoolConstants.session, s)) {
								tempForm[key].push(s);
							}
						});
					}
					break;
				case "schollBadge":
				case "clearImage":
				case "organizationKey":
				case "organizationName":
					break;
				default:
					tempForm[key] = school[key] !== undefined ? school[key] : formData[key];
					break;
			}
		});
		if (school.organizationKey && school.organizationName) {
			tempForm["organizationKey"] = school.organizationKey;
			setSelectedValue({ value: school.organizationKey, name: school.organizationName });
		}
		setFormData(tempForm);
	}, [school]);

	useEffect(() => {
		if (updateSchoolSuccess) {
			if (!isSaved) {
				setIsSaved(true);
				return;
			}
			dispatch({
				type: schoolConstants.type.RESET_SCHOOL_SUCCESS,
				filePath: !_.isNull(formData.schoolBadge) ? URL.createObjectURL(formData.schoolBadge) : "",
			});
			toast.success("已更新學校");
			handleReplace();
		}
	}, [updateSchoolSuccess, isSaved, dispatch]);

	useEffect(() => {
		if (createSchoolSuccess) {
			if (!isSaved) {
				setIsSaved(true);
				return;
			}
			dispatch({ type: schoolConstants.type.RESET_SCHOOL_SUCCESS });
			toast.success("已新增學校");
			handleReplace();
		}
	}, [createSchoolSuccess, isSaved, dispatch]);

	const handleBack = () => {
		if (key) {
			history.push({
				pathname: prevPage === FROM_ORGANIZATION ? `${generalConstants.PATH.ORGANIZATION}/school` : generalConstants.PATH.SCHOOL_DETAIL,
				state: { from: prevPage, schoolKey: key },
			});
		} else {
			history.push(generalConstants.PATH.SCHOOL_MANAGEMENT);
		}
	};

	const handleReplace = () => {
		if (key) {
			history.replace({
				pathname: prevPage === FROM_ORGANIZATION ? `${generalConstants.PATH.ORGANIZATION}/school` : generalConstants.PATH.SCHOOL_DETAIL,
				state:
					prevPage === FROM_ORGANIZATION
						? { from: prevPage, organizationKey: organizationObj._key, schoolKey: key }
						: { from: prevPage, schoolKey: key },
			});
		} else {
			history.replace({
				pathname: prevPage === FROM_ORGANIZATION ? generalConstants.PATH.ORGANIZATION : generalConstants.PATH.SCHOOL_MANAGEMENT,
				state: prevPage === FROM_ORGANIZATION ? { from: prevPage, organizationKey: organizationObj._key } : null,
			});
		}
	};

	const isBureau = (key) => {
		return key === schoolDataConstants.specialSchoolId;
	};

	const handleSelectChange = (value) => {
		setIsChanged(true);
		setSelectedValue(value);
		handleChange({ key: "organizationKey", value: value?.value || "" });
	};

	const loadOptions = async (searchQuery, loadedOptions, { page }) => {
		const response = await organizationService.getOrganizations({
			search: searchQuery,
			status: [organizationConstants.approvalStatus.APPROVED],
			type: "SCHOOL",
			l: DEFAULT_LIMIT,
			p: page,
		});
		if (response && response.items) {
			var hasMore = true;
			var optionList = response.items.map((i) => ({ value: `${i._key}`, name: _.isEmpty(i.name) ? i.nameEng : i.name }));
			if (_.isEmpty(searchQuery) && page === 1 && school) {
				if (school.organizationKey != null) {
					optionList = [
						{ value: school.organizationKey, name: school.organizationName },
						...optionList.filter((item) => item.value != school.organizationKey),
					];
				}
			}
			if (loadedOptions.length >= response.totalItems || optionList.length === response.totalItems) {
				hasMore = false;
			}
			return {
				options: optionList,
				hasMore: hasMore,
				additional: {
					page: page + 1,
				},
			};
		}
		return {
			options: [],
			hasMore: false,
			additional: {
				page: page,
			},
		};
	};

	const getLocationPath = () => {
		const pathArray = [];

		pathArray.push({ title: "主頁面", isAction: true, target: generalConstants.PATH.DASHBOARD });
		pathArray.push({
			title:
				generalConstants.TAB_NAME[
					prevPage === FROM_ORGANIZATION
						? generalConstants.NAV_TAB.ORGANIZATION_MANAGEMENT.key
						: generalConstants.NAV_TAB.SCHOOL_MANAGEMENT.key
				],
			isAction: true,
			target: prevPage === FROM_ORGANIZATION ? generalConstants.PATH.ORGANIZATION_MANAGEMENT : generalConstants.PATH.SCHOOL_MANAGEMENT,
		});
		if (prevPage === FROM_ORGANIZATION && organizationObj) {
			pathArray.push({
				title: organizationObj.name,
				isAction: true,
				target: generalConstants.PATH.ORGANIZATION,
				stateObj: { from: prevPage, organizationKey: organizationObj._key },
			});
		}
		if (key && school && typeof school.name != "undefined") {
			pathArray.push({
				title: !_.isEmpty(school.name[0]) ? school.name[0] : school.name[1] || "",
				isAction: true,
				target: prevPage === FROM_ORGANIZATION ? `${generalConstants.PATH.ORGANIZATION}/school` : generalConstants.PATH.SCHOOL_DETAIL,
				stateObj: { from: prevPage, schoolKey: key },
			});
		}
		pathArray.push({
			title:
				generalConstants.TAB_NAME[
					key && school && typeof school.name != "undefined"
						? generalConstants.NAV_TAB.EDIT_SCHOOL.key
						: generalConstants.NAV_TAB.CREATE_SCHOOL.key
				],
			isAction: false,
			target: "",
		});
		return pathArray;
	};

	if ((!key && location.pathname.includes("/edit")) || (!prevPage && location.pathname.includes("/newschool"))) {
		return null;
	}
	return (
		<>
			{!!isLoading && <Loading />}
			<Prompt when={isChanged && !isSaved && loggedIn} message="你有未儲存的變更，確定不儲存下離開？" />
			<PageContainer>
				<LeftContainer>
					<AdminMenubar
						section={
							prevPage === FROM_ORGANIZATION
								? generalConstants.NAV_TAB.ORGANIZATION_MANAGEMENT.key
								: generalConstants.NAV_TAB.SCHOOL_MANAGEMENT.key
						}
					/>
				</LeftContainer>
				<RightContainer>
					<AdminUserInfo />
					<AdminSectionbar
						title={key ? generalConstants.NAV_TAB.EDIT_SCHOOL.key : generalConstants.NAV_TAB.CREATE_SCHOOL.key}
						locationPathArray={getLocationPath()}
					/>
					<ContentContainer style={{ display: "flex", flexDirection: "row" }}>
						<ContentLeft>
							{prevPage != FROM_ORGANIZATION ? (
								<BlockGroup>
									<div style={{ marginLeft: "10px", marginRight: "10px", width: "50%" }}>
										<TextFieldLabel>學校ID</TextFieldLabel>
										<TextField placeholder="學校ID" disabled={!!key} value={formData.key} name="key" handleChange={handleChange} />
									</div>
									{formData.key != schoolDataConstants.specialSchoolId && (
										<div style={{ marginRight: "10px", width: "50%" }}>
											<TextFieldLabel>機構</TextFieldLabel>
											<>
												<AsyncPaginate
													isClearable
													value={selectedValue}
													loadOptions={loadOptions}
													getOptionValue={(option) => option.name}
													getOptionLabel={(option) => option.name}
													onChange={handleSelectChange}
													placeholder="請選擇"
													additional={{
														page: DEFAULT_PAGE,
													}}
												/>
											</>
										</div>
									)}
								</BlockGroup>
							) : (
								<div style={{ marginLeft: "10px", marginRight: "10px" }}>
									<TextFieldLabel>學校ID</TextFieldLabel>
									<TextField placeholder="學校ID" disabled={!!key} value={formData.key} name="key" handleChange={handleChange} />
								</div>
							)}
							<BlockGroup>
								{!isBureau(key) && (
									<Block flex="0 0 135px">
										<TextFieldLabel>學校徽標</TextFieldLabel>
										<SingleImageUploader
											src={`${baseURL}${key}.jpg`}
											handleChange={handleChangeImage}
											handleClear={handleClearImage}
											uploadLabel="上傳校徽"
											noImageLabel="沒有校徽"
										/>
									</Block>
								)}

								<Block>
									<TextFieldLabel>名稱</TextFieldLabel>
									<TextField placeholder="名稱" value={formData.name[0]} name="name0" handleChange={handleChange} />

									<TextFieldLabel style={{ marginTop: "9px" }}>Name</TextFieldLabel>
									<TextField placeholder="Name" value={formData.name[1]} name="name1" handleChange={handleChange} />
								</Block>
							</BlockGroup>

							{!isBureau(key) && (
								<div>
									<BlockGroup>
										<Block>
											<TextFieldLabel>學校類型</TextFieldLabel>
											<>
												<Select name={"level"} options={schoolConstants.OPTION.level} value={formData.level} handleChange={handleChange} />
											</>
										</Block>
										<Block>
											<TextFieldLabel>學校類別</TextFieldLabel>
											<>
												<Select
													name={"category"}
													options={getCategoryOption(formData.level)}
													value={formData.category}
													handleChange={handleChange}
												/>
											</>
										</Block>
									</BlockGroup>

									<BlockGroup>
										<Block>
											<TextFieldLabel>地區</TextFieldLabel>
											<>
												<Select
													name={"district"}
													options={schoolConstants.OPTION.district}
													value={formData.district}
													handleChange={handleChange}
												/>
											</>
										</Block>
										{formData.level === "PRIMARY" && (
											<Block>
												<TextFieldLabel>校網</TextFieldLabel>
												<>
													<Select
														displayEmpty
														name={"schoolNet"}
														options={schoolConstants.OPTION.schoolNet}
														value={formData.schoolNet}
														handleChange={handleChange}
													/>
												</>
											</Block>
										)}
										<Block>
											<TextFieldLabel>學生性別</TextFieldLabel>
											<>
												<Select
													name={"gender"}
													options={schoolConstants.OPTION.gender}
													value={formData.gender}
													handleChange={handleChange}
												/>
											</>
										</Block>
									</BlockGroup>

									<BlockGroup>
										<Block>
											<TextFieldLabel>財政類型</TextFieldLabel>
											<>
												<Select
													name={"financeType"}
													options={schoolConstants.OPTION.financeType[formData.level]}
													value={formData.financeType}
													handleChange={handleChange}
												/>
											</>
										</Block>

										{formData.level === "PRIMARY" && (
											<Block>
												<TextFieldLabel>連結模式</TextFieldLabel>
												<>
													<Select
														displayEmpty
														name={"linkMode"}
														options={schoolConstants.OPTION.linkMode}
														value={formData.linkMode}
														handleChange={handleChange}
													/>
												</>
											</Block>
										)}

										<Block>
											<TextFieldLabel>宗教</TextFieldLabel>
											<>
												<Select
													displayEmpty
													name={"religion"}
													options={schoolConstants.OPTION.religion}
													value={formData.religion}
													handleChange={handleChange}
												/>
											</>
										</Block>
									</BlockGroup>

									<BlockGroup>
										<Block>
											<TextFieldLabel>授課時間</TextFieldLabel>
											<>
												{schoolConstants.OPTION.session
													.filter((o) => !(formData.level !== "SECONDARY" && o.key === "EVENING") && _.has(schoolConstants.session, o.key))
													.map((item) => {
														return (
															<Checkbox
																key={`${item.key}_checkbox`}
																name={"session"}
																label={item.value}
																checked={_.includes(formData.session, item.key)}
																value={item.key}
																handleChange={handleChange}
															/>
														);
													})}
											</>
										</Block>
										{formData.level !== "PRIMARY" && formData.level !== "SECONDARY" && (
											<Block>
												<TextFieldLabel>幼稚園教育計劃</TextFieldLabel>
												<Checkbox
													key={`kes_checkbox`}
													name={"kes"}
													label={`幼稚園教育計劃`}
													checked={formData.kes === "Y"}
													value={"Y"}
													handleChange={handleChange}
												/>
											</Block>
										)}
									</BlockGroup>

									<BlockGroup>
										<VIPRowInBlock>
											<VIPTextFieldLabe>只供VIP用戶訂閱</VIPTextFieldLabe>
											<Checkbox
												key={`vipOnly_checkbox`}
												name={"vipOnly"}
												checked={formData.vipOnly}
												handleChange={handleChange}
												color="red"
											/>
										</VIPRowInBlock>
									</BlockGroup>
								</div>
							)}
						</ContentLeft>
						<ContentRight>
							{!isBureau(key) && (
								<div style={{ minHeight: "460px" }}>
									<Block>
										<TextFieldLabel>地址</TextFieldLabel>
										<TextField
											placeholder="地址"
											multiline
											rows={3}
											value={formData.address[0]}
											name="address0"
											handleChange={handleChange}
										/>
									</Block>
									<Block>
										<TextFieldLabel>Address</TextFieldLabel>
										<TextField
											placeholder="Address"
											multiline
											rows={3}
											value={formData.address[1]}
											name="address1"
											handleChange={handleChange}
										/>
									</Block>

									<BlockGroup>
										<Block>
											<TextFieldLabel>經度</TextFieldLabel>
											<TextField placeholder="經度" value={formData.longitude} name="longitude" handleChange={handleChange} />
										</Block>
										<Block>
											<TextFieldLabel>緯度</TextFieldLabel>
											<TextField placeholder="緯度" value={formData.latitude} name="latitude" handleChange={handleChange} />
										</Block>
									</BlockGroup>

									<BlockGroup>
										<Block>
											<TextFieldLabel>電話</TextFieldLabel>
											<TextField placeholder="電話" value={formData.telNo} name="telNo" handleChange={handleChange} />
										</Block>
										<Block>
											<TextFieldLabel>傳真</TextFieldLabel>
											<TextField placeholder="傳真" value={formData.faxNo} name="faxNo" handleChange={handleChange} />
										</Block>
									</BlockGroup>
									<Block>
										<TextFieldLabel>網址</TextFieldLabel>
										<TextField placeholder="網址" value={formData.website} name="website" handleChange={handleChange} />
									</Block>

									<Block>
										<TextFieldLabel>狀態</TextFieldLabel>
										<>
											<Select name={"status"} options={schoolConstants.OPTION.status} value={formData.status} handleChange={handleChange} />
										</>
									</Block>
								</div>
							)}
							<RowInBlock style={{ justifyContent: "flex-end" }}>
								<CancelButton label={"取消"} style={{ marginRight: "15px" }} handleOnClick={handleBack} />
								<SubmitButton
									label={"確認"}
									style={{ marginRight: "15px" }}
									handleOnClick={handleSubmit}
									active={isChanged && !isFetching}
								/>
							</RowInBlock>
						</ContentRight>
					</ContentContainer>
					<AdminFooter></AdminFooter>
				</RightContainer>
			</PageContainer>
		</>
	);
};

const RowInBlock = styled.div`
	padding-top: 30px;
	display: flex;
	flex-direction: row;
`;

const ContentLeft = styled.div`
	flex: 1;
	padding: 10px;
	border-right: 1px solid #dddddd;
`;

const ContentRight = styled.div`
	flex: 1;
	padding: 10px;
`;

const BlockGroup = styled.div`
	display: flex;
	flex-direction: row;
`;

const Block = styled.div`
	padding: 10px;
	flex: ${(props) => props.flex || 1};
`;

const TextFieldLabel = styled.div`
	color: #000000;
	font-size: 13px;
	line-height: 1.5;
	font-weight: bold;
	margin-bottom: 7px;
`;

const VIPRowInBlock = styled.div`
	padding-top: 30px;
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 10px;
	margin-top: -15px;
`;

const VIPTextFieldLabe = styled.div`
	color: red;
	font-size: 13px;
	line-height: 1.5;
	font-weight: bold;
	margin-bottom: 2px;
	margin-right: 10px;
`;
