import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";

import { messageConstants, generalConstants } from "_constants";
import { messageAction } from "actions";
import _ from "lodash";
import moment from "moment";

import { history } from "_util/_helper";
import { useSortableData } from "_util/hook/sortableData";

import { ExcelImportDialog, CancelButton, SubmitButton, AlertDialog } from "components/general";

import { ArrowDropDown, ArrowDropUp } from "@material-ui/icons";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";

import { AdminMenubar, AdminUserInfo, AdminSectionbar, AdminFooter } from "components/admin";
import { PageContainer, LeftContainer, RightContainer, ContentContainer, TableRow, TableTh, THButton, TableTd } from "components/layout";

import { ReactComponent as ImportExcelIcon } from "resources/img/import_excel.svg";

export const ImportMsgPage = () => {
	const { msgList, messageSuccess } = useSelector(({ message }) => ({
		msgList: message.importMsgList,
		messageSuccess: message.submitBatchMessageSuccess,
	}));

	const [displayList, setDisplayList] = useState(msgList);
	const [isAlertShow, setIsAlertShow] = useState(false);
	const [isImportShow, setIsImportShow] = useState(false);

	const [error, IsError] = useState(false);
	const dispatch = useDispatch();
	const { items, requestSort, sortConfig } = useSortableData(displayList);

	useEffect(() => {
		setDisplayList(msgList);
		let res = _.find(msgList, function (o) {
			return o.msg;
		});
		if (res) {
			IsError(true);
		} else {
			IsError(false);
		}
	}, [msgList]);

	const handleCloseAlert = () => {
		setIsAlertShow(false);
	};

	useEffect(() => {
		if (messageSuccess) {
			setIsAlertShow(false);
			history.push(generalConstants.PATH.MSG_MANAGEMENT);
		}
	}, [messageSuccess]);

	const handleImport = () => {
		dispatch(messageAction.createBatchAdminMsg(msgList));
		//setIsAlertShow(false);
	};

	const handleCloseImport = () => {
		setIsImportShow(false);
	};

	const handleShowImport = () => {
		setIsImportShow(true);
	};

	const handleBack = () => {
		history.goBack();
	};

	const handleSubmit = () => {
		setIsAlertShow(true);
	};

	const HandleRowOnClick = (item) => {
		dispatch(messageAction.getImportMsgByKey(msgList, item.num));
		history.push(generalConstants.PATH.READ_MSG);
	};

	const renderTH = () => {
		return messageConstants.IMPORT_TABLE_COLUMN.map((column) => {
			return (
				<TableTh key={`${column.key}_table_header`} width={column.width}>
					<THButton key={`${column.key}_table_header`} type="button" onClick={() => requestSort(column.key)}>
						<div>
							{column.value}
							{sortConfig && sortConfig.key === column.key ? (
								sortConfig.direction === "ascending" ? (
									<ArrowDropUp fontSize={"small"} style={{ verticalAlign: "middle" }} />
								) : (
									<ArrowDropDown fontSize={"small"} style={{ verticalAlign: "middle" }} />
								)
							) : null}
						</div>
					</THButton>
				</TableTh>
			);
		});
	};
	const renderTableHeader = renderTH();

	const renderTD = () => {
		if (items.length > 0) {
			return items.map((msg, index) => {
				const tempObj = {};
				messageConstants.IMPORT_TABLE_COLUMN.forEach((column) => {
					tempObj[column.key] = msg[column.key];
				});
				tempObj["scheduleType"] = msg["scheduleType"];
				tempObj["msg"] = msg["msg"];
				if (msg["msg"]) {
					return (
						<Tooltip
							arrow
							key={`${msg.num}_tooltip`}
							title={<Typography key={`${msg.num}_tooltip_frag_ty`}> {msg["msg"]}有錯誤</Typography>}
						>
							<TableRow
								key={`${msg.num}_${index}_tr`}
								style={{ color: "white", backgroundColor: "#ff5c5c" }}
								onClick={() => {
									HandleRowOnClick(msg);
								}}
							>
								{Object.keys(tempObj).map((columnName, index) => {
									if (columnName === "type" || columnName === "infoType") {
										return (
											<TableTd key={`${msg.num}_${columnName}_${index}_td`}>
												{messageConstants.Translate[columnName][tempObj[columnName]]}
											</TableTd>
										);
									} else if (columnName === "title") {
										return <TableTd key={`${msg.num}_${columnName}_${index}_td`}>{tempObj[columnName]}</TableTd>;
									} else if (columnName === "scheduleTime") {
										if (messageConstants.DATA.scheduleType.IMMEDIATELY.localeCompare(tempObj["scheduleType"]) === 0) {
											return <TableTd key={`${msg.num}_${columnName}_${index}_td`}>{"即時"}</TableTd>;
										} else {
											return (
												<TableTd key={`${msg.num}_${columnName}_${index}_td`}>
													{tempObj[columnName] ? moment(tempObj[columnName]).format("YYYY年M月DD日 hh:mmA") : "-"}
												</TableTd>
											);
										}
									} else if (columnName === "scheduleType") {
										return null;
									} else if (columnName === "msg") {
										return null;
									}
									return <TableTd key={`${msg.num}_${columnName}_${index}_td`}>{tempObj[columnName]}</TableTd>;
								})}
							</TableRow>
						</Tooltip>
					);
				} else {
					return (
						<TableRow
							key={`${msg.num}_${index}_tr`}
							onClick={() => {
								HandleRowOnClick(msg);
							}}
						>
							{Object.keys(tempObj).map((columnName, index) => {
								if (columnName === "type" || columnName === "infoType") {
									return (
										<TableTd key={`${msg.num}_${columnName}_${index}_td`}>
											{messageConstants.Translate[columnName][tempObj[columnName]]}
										</TableTd>
									);
								} else if (columnName === "title") {
									return <TableTd key={`${msg.num}_${columnName}_${index}_td`}>{tempObj[columnName]}</TableTd>;
								} else if (columnName === "scheduleTime") {
									if (messageConstants.DATA.scheduleType.IMMEDIATELY.localeCompare(tempObj["scheduleType"]) === 0) {
										return <TableTd key={`${msg.num}_${columnName}_${index}_td`}>{"即時"}</TableTd>;
									} else {
										return (
											<TableTd key={`${msg.num}_${columnName}_${index}_td`}>
												{tempObj[columnName] ? moment(tempObj[columnName]).format("YYYY年M月DD日 hh:mmA") : "-"}
											</TableTd>
										);
									}
								} else if (columnName === "scheduleType") {
									return null;
								} else if (columnName === "msg") {
									return null;
								}
								return <TableTd key={`${msg.num}_${columnName}_${index}_td`}>{tempObj[columnName]}</TableTd>;
							})}
						</TableRow>
					);
				}
			});
		}
	};
	const renderTableCell = renderTD();

	return (
		<>
			<AlertDialog active={isAlertShow} handleOnClose={handleCloseAlert} handleOnYes={handleImport} title={"確認導入文件？"}></AlertDialog>
			<ExcelImportDialog active={isImportShow} goImportMsg={false} handleOnClose={handleCloseImport}></ExcelImportDialog>
			<PageContainer>
				<LeftContainer>
					<AdminMenubar section={generalConstants.NAV_TAB.MSG_MANAGEMENT.key} />
				</LeftContainer>
				<RightContainer>
					<AdminUserInfo />
					<AdminSectionbar
						title={generalConstants.NAV_TAB.IMPORT_MSG.key}
						sublabel={`總訊息數量: ${displayList.length}`}
						buttonLabel="導入Excel"
						buttonIcon={<ImportExcelIcon style={{ width: "22px", height: "22px" }} />}
						handleOnClick={handleShowImport}
						buttonType={generalConstants.BUTTON_TYPE.IMPORT}
						locationPathArray={[
							{ title: "主頁面", isAction: true, target: generalConstants.PATH.DASHBOARD },
							{
								title: generalConstants.TAB_NAME[generalConstants.NAV_TAB.MSG_MANAGEMENT.key],
								isAction: true,
								target: generalConstants.PATH.MSG_MANAGEMENT,
							},
							{
								title: generalConstants.TAB_NAME[generalConstants.NAV_TAB.IMPORT_MSG.key],
								isAction: false,
								target: "",
							},
						]}
					/>
					<ContentContainer>
						<table style={{ width: "100%", borderCollapse: "collapse", tableLayout: "fixed" }}>
							<thead>
								<tr>{renderTableHeader}</tr>
							</thead>
							<tbody>{renderTableCell}</tbody>
						</table>

						<RowInBlock marginTop={"10px"} style={{ justifyContent: "flex-end", marginTop: "50px" }}>
							<CancelButton label={"取消"} style={{ marginRight: "15px", borderRadius: 40 }} handleOnClick={handleBack} />
							<SubmitButton label={"確認導入"} active={!error} style={{ borderRadius: 40 }} handleOnClick={handleSubmit} />
						</RowInBlock>
					</ContentContainer>
					<AdminFooter></AdminFooter>
				</RightContainer>
			</PageContainer>
		</>
	);
};

const RowInBlock = styled.div`
	display: flex;
	margin-bottom: ${(props) => props.marginBottom || "10px"};
	flex-direction: row;
`;
