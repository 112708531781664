import axios from "axios";

export const socialNetworkService = {
	getPosts,
	getPost,
	reviewPost,
	deletePost,
};

const httpOptions = {
	headers: {
		"Content-Type": "application/json",
	},
	withCredentials: true,
};

function createFormData(data) {
	let formData = new FormData();

	for (let key in data) {
		let value = data[key];
		if (Array.isArray(value)) {
			value = JSON.stringify(value);
		}
		formData.append(key, value);
	}
	return formData;
}

async function getPosts(form) {
	return axios({
		url: `${process.env.REACT_APP_BACKEND_URL}/socialnetwork/post`,
		...httpOptions,
		method: "GET",
		params: form,
	})
		.then(handleResponse)
		.catch(handleError);
}

async function getPost(key) {
	return axios({
		url: `${process.env.REACT_APP_BACKEND_URL}/socialnetwork/post/${key}`,
		...httpOptions,
		method: "GET",
	})
		.then(handleResponse)
		.catch(handleError);
}

async function reviewPost(data) {
	let formData = createFormData(data);
	return axios({
		url: `${process.env.REACT_APP_BACKEND_URL}/socialnetwork/post/review`,
		...httpOptions,
		headers: {
			...httpOptions.headers,
			enctype: "multipart/form-data",
		},
		data: formData,
		method: "POST",
	})
		.then(handleResponse)
		.catch(handleError);
}

async function deletePost(data) {
	return axios({
		url: `${process.env.REACT_APP_BACKEND_URL}/socialnetwork/post`,
		...httpOptions,
		method: "DELETE",
		params: data,
	})
		.then(handleResponse)
		.catch(handleError);
}

function handleError(err) {
	const msg = (err.response && err.response.data.message) || err.message || err;
	throw { error: msg };
}

function handleResponse(res) {
	return res.data;
}
