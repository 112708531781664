import React from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";

export const ImportButton = (props) => {
	const { label, handleOnClick, style, active, buttonIcon } = props;

	return (
		<Button
			variant="contained"
			style={{ 
				padding: "8px 34px", 
				borderRadius: 40, 
				textTransform: "none", 
				backgroundColor: active ? "#177040" : "#eeeeee", 
				borderColor: "#177040", 
				fill: "white", 
				color: "white", 
				...style 
			}}
			onClick={() => {
				if (handleOnClick && !!active) {
					handleOnClick();
				}
			}}
			startIcon={buttonIcon}
		>
			{label}
		</Button>
	);
};

ImportButton.propTypes = {
	label: PropTypes.string,
	handleOnClick: PropTypes.func,
	style: PropTypes.object,
	active: PropTypes.bool,
	buttonIcon: PropTypes.object,
};

ImportButton.defaultProps = {
	active: true,
};
