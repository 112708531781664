import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./store";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
const theme = createTheme({
	typography: {
		fontFamily: [
			"Noto Sans TC",
			"-apple-system",
			"BlinkMacSystemFont",
			'"Segoe UI"',
			"Roboto",
			'"Helvetica Neue"',
			"Arial",
			"sans-serif",
			'"Apple Color Emoji"',
			'"Segoe UI Emoji"',
			'"Segoe UI Symbol"',
		].join(","),
		fontSize: 13,
		button: {
			textTransform: "none",
		},
	},
	overrides: {
		MuiDialogTitle: {
			root: {
				borderTop: "6px solid #333399",
				"& h2": {
					fontSize: 14,
					fontWeight: "bold",
					fontStretch: "normal",
					fontStyle: "normal",
					lineHeight: 1.43,
					letterSpacing: "normal",
					textAlign: "left",
					color: "#333399",
				},
			},
		},
	},
});

ReactDOM.render(
	<Provider store={store}>
		<PersistGate loading={null} persistor={persistor}>
			<ThemeProvider theme={theme}>
				<ToastContainer
					position="top-center"
					autoClose={4000}
					hideProgressBar={true}
					newestOnTop={false}
					closeOnClick
					rtl={false}
					pauseOnFocusLoss
					pauseOnHover
				/>
				<App />
			</ThemeProvider>
		</PersistGate>
	</Provider>,
	document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
