import { learningCentreConstants } from "_constants";
import { learningCentreService } from "services";
import { toast } from "react-toastify";

export const learningCentreAction = {
	createLearningCenter,
	updateLearningCenter,
	getLearningCenters,
	getLearningCenterByKey,
	deleteLearningCenter,
};

function deleteLearningCenter(data) {
	return (dispatch) => {
		dispatch(request());
		learningCentreService.deleteLearningCenter(data).then(
			() => {
				dispatch(success());
			},
			(error) => {
				toast.error(error.error);
				dispatch(failure());
			}
		);
	};

	function request() {
		return { type: learningCentreConstants.type.DELETE_LEARNING_CENTRE_REQUEST };
	}
	function failure() {
		return { type: learningCentreConstants.type.DELETE_LEARNING_CENTRE_FAILURE };
	}
	function success() {
		return { type: learningCentreConstants.type.DELETE_LEARNING_CENTRE_SUCCESS };
	}
}

function createLearningCenter(data) {
	return (dispatch) => {
		dispatch(request());
		learningCentreService.createLearningCenter(data).then(
			() => {
				dispatch(success());
			},
			(error) => {
				toast.error(error.error);
				dispatch(failure());
			}
		);
	};

	function request() {
		return { type: learningCentreConstants.type.CREATE_LEARNING_CENTRE_REQUEST };
	}
	function failure() {
		return { type: learningCentreConstants.type.CREATE_LEARNING_CENTRE_FAILURE };
	}
	function success() {
		return { type: learningCentreConstants.type.CREATE_LEARNING_CENTRE_SUCCESS };
	}
}

function updateLearningCenter(data) {
	return (dispatch) => {
		dispatch(request());
		learningCentreService.updateLearningCenter(data).then(
			() => {
				dispatch(success());
			},
			(error) => {
				toast.error(error.error);
				dispatch(failure());
			}
		);
	};

	function request() {
		return { type: learningCentreConstants.type.UPDATE_LEARNING_CENTRE_REQUEST };
	}
	function failure() {
		return { type: learningCentreConstants.type.UPDATE_LEARNING_CENTRE_FAILURE };
	}
	function success() {
		return { type: learningCentreConstants.type.UPDATE_LEARNING_CENTRE_SUCCESS };
	}
}

function getLearningCenters(data) {
	return (dispatch) => {
		dispatch(request());
		learningCentreService.getLearningCenters(data).then(
			(response) => {
				dispatch(success(response, data));
			},
			(error) => {
				toast.error(error.error);
				dispatch(failure());
			}
		);
	};

	function request() {
		return { type: learningCentreConstants.type.FETCH_LEARNING_CENTRE_LIST_REQUEST };
	}
	function failure() {
		return { type: learningCentreConstants.type.FETCH_LEARNING_CENTRE_LIST_FAILURE };
	}
	function success(response, originalInput) {
		const { totalItems, items } = response;
		let sortingKey = "name",
			sortingDirection = "ASC";
		const sortingArray = originalInput?.sort?.split(":");
		if (sortingArray && sortingArray.length === 2 && sortingArray[0] && sortingArray[1]) {
			sortingKey = sortingArray[0];
			sortingDirection = sortingArray[1] === "DESC" ? "DESC" : "ASC";
		}
		const searchKey = originalInput.search;
		const district = originalInput.district;
		const category = originalInput.category;
		return {
			type: learningCentreConstants.type.FETCH_LEARNING_CENTRE_LIST_SUCCESS,
			isOrganizationDetail: originalInput.isOrganizationDetail,
			totalNum: totalItems,
			totalPage: Math.ceil(totalItems / data.l),
			currentPage: data.p,
			learningCentreList: items,
			searchKey,
			district,
			category,
			sortingKey,
			sortingDirection,
		};
	}
}

function getLearningCenterByKey(key, data, navigationKey) {
	return (dispatch) => {
		dispatch(request());
		learningCentreService.getLearningCenterByKey(key, data).then(
			(response) => {
				dispatch(success(response));
			},
			(error) => {
				toast.error(error.error);
				dispatch(failure());
			}
		);
	};

	function request() {
		return { type: learningCentreConstants.type.FETCH_CENTRE_REQUEST };
	}
	function failure() {
		return { type: learningCentreConstants.type.FETCH_CENTRE_FAILURE };
	}
	function success(centre) {
		if (data && data.includeCourse) {
			const { totalItems, items } = centre?.courses || {};
			let sortingKey = "name",
				sortingDirection = "ASC";
			const sortingArray = data?.sort?.split(":");
			if (sortingArray && sortingArray.length === 2 && sortingArray[0] && sortingArray[1]) {
				sortingKey = sortingArray[0];
				sortingDirection = sortingArray[1] === "DESC" ? "DESC" : "ASC";
			}
			return {
				type: learningCentreConstants.type.FETCH_CENTRE_SUCCESS,
				centre: centre?.lc,
				navigationKey,
				totalNum: totalItems,
				totalPage: Math.ceil(totalItems / data.l),
				currentPage: data.p,
				learningCoursesList: items,
				sortingDirection,
				sortingKey,
				searchKey: data.search || "",
				district: data.district || "",
				category: data.category || "",
			};
		} else {
			return {
				type: learningCentreConstants.type.FETCH_CENTRE_SUCCESS,
				centre,
			};
		}
	}
}
