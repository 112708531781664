import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";

import { schoolConstants, generalConstants } from "_constants";
import { schoolAction } from "actions";
import _ from "lodash";
import isEqual from "react-fast-compare";

import { history } from "_util/_helper";

import { Pagination, Switch, RefreshButton } from "components/general";
import { StandardSelect, StandardTextField } from "components/general/standard";

import { ArrowDropDown, ArrowDropUp } from "@material-ui/icons";

import ProgressiveRejectDebouncer from "_util/debouncers/ProgressiveRejectDebouncer";

import { AdminMenubar, AdminUserInfo, AdminSectionbar, AdminFooter } from "components/admin";
import {
	PageContainer,
	LeftContainer,
	RightContainer,
	ContentContainer,
	SearchBar,
	SearchBarLeft,
	SearchBarRight,
	PaginationContainer,
	TableContainer,
	TableRow,
	TableTh,
	THButton,
	TableTd,
} from "components/layout";
import { ReactComponent as AddSchoolIcon } from "resources/img/addschool.svg";

const DEFAULT_PAGE = 1;
const DEFAULT_LIMIT = 10;

const initSearchForm = {
	level: "",
	district: "",
	gender: "",
	schoolNet: "",
	name: "",
	includeSummary: true,
	page: DEFAULT_PAGE,
	limit: DEFAULT_LIMIT,
	sort: "currentYearRank:ASC",
	withSubscriptionOnly: false,
};

const FROM_SCHOOL = "school";
export const SchoolManagementPage = () => {
	const { schoolList, summary, currentPage, numOfPage, schoolSortingKey, schoolSortingDirection } = useSelector(
		({ school }) => ({
			schoolList: school.navigationObject?.root?.schoolList,
			summary: school.navigationObject?.root?.summary,
			currentPage: school.navigationObject?.root?.currentPage,
			numOfPage: school.navigationObject?.root?.numOfPage,
			schoolSortingKey: school.navigationObject?.root?.sortingKey,
			schoolSortingDirection: school.navigationObject?.root?.sortingDirection,
		}),
		isEqual
	);

	const { navigationObject } = useSelector(
		({ school }) => ({
			navigationObject: school.navigationObject?.root,
		}),
		isEqual
	);

	const searchFilter = {
		level: navigationObject?.level || "",
		district: navigationObject?.district || "",
		gender: navigationObject?.gender || "",
		schoolNet: navigationObject?.schoolNet || "",
		name: navigationObject?.name || "",
		includeSummary: true,
		withSubscriptionOnly: navigationObject?.withSubscriptionOnly || false,
		page: navigationObject?.currentPage,
		limit: DEFAULT_LIMIT,
		sort:
			schoolSortingKey && schoolSortingDirection
				? `${navigationObject.sortingKey}:${navigationObject.sortingDirection}`
				: "currentYearRank:ASC",
	};

	const timeoutRef = useRef(null);

	const [sortingKey, setSortingKey] = useState(schoolSortingKey);
	const [sortingDirection, setSortingDirection] = useState(schoolSortingDirection);

	const [searchForm, setSearchForm] = useState(searchFilter || initSearchForm);

	const dispatch = useDispatch();
	const [textDebouncer] = useState(() => new ProgressiveRejectDebouncer(0.7 * 1000));
	const [debouncer] = useState(() => new ProgressiveRejectDebouncer(1 * 1000));

	useEffect(() => {
		handleSchoolSearch(searchForm);
	}, [dispatch]);

	function resetTimeout() {
		if (timeoutRef.current) {
			clearTimeout(timeoutRef.current);
		}
	}

	const handleSchoolSearch = (form) => {
		let newForm = { includeSummary: true, page: form.page, limit: form.limit };
		if (form.level) {
			newForm = { ...newForm, level: form.level };
		}
		if (form.district) {
			newForm = { ...newForm, district: form.district };
		}
		if (form.gender) {
			newForm = { ...newForm, gender: form.gender };
		}
		if (form.schoolNet) {
			newForm = { ...newForm, schoolNet: form.schoolNet };
		}
		if (form.name) {
			newForm = { ...newForm, name: form.name };
		}
		if (form.withSubscriptionOnly) {
			newForm = { ...newForm, withSubscriptionOnly: form.withSubscriptionOnly };
		}
		if (form.sort) {
			const sortObj = form.sort.split(":");
			if (sortObj[0] && sortObj[1]) {
				newForm = { ...newForm, sort: form.sort };
			}
		}
		dispatch(schoolAction.getSchoolList(newForm, "root"));
	};

	const handleRefreshFilter = () => {
		if (
			sortingKey === "currentYearRank" &&
			sortingDirection === "ASC" &&
			!searchForm.withSubscriptionOnly &&
			searchForm.level === "" &&
			searchForm.district === "" &&
			searchForm.gender === "" &&
			searchForm.schoolNet === "" &&
			searchForm.name === "" &&
			searchForm.page === DEFAULT_PAGE
		) {
			return;
		}
		setSortingKey("currentYearRank");
		setSortingDirection("ASC");
		handleSchoolSearch(initSearchForm);
		setSearchForm(initSearchForm);
	};

	const HandleRowOnClick = (item) => {
		history.push({
			pathname: generalConstants.PATH.SCHOOL_DETAIL,
			state: {
				from: FROM_SCHOOL,
				schoolKey: item._key,
			},
		});
	};

	const handlePageChange = (pageNum) => {
		if (!debouncer.token() || currentPage === pageNum) {
			return;
		}
		handleSchoolSearch({ ...searchForm, page: pageNum });
		setSearchForm({ ...searchForm, page: pageNum });
	};

	const handleSwitchChange = (value) => {
		if (!debouncer.token()) {
			return;
		}
		handleSchoolSearch({ ...searchForm, withSubscriptionOnly: value, page: DEFAULT_PAGE });
		setSearchForm({ ...searchForm, withSubscriptionOnly: value, page: DEFAULT_PAGE });
	};

	const handleSelectChange = ({ key, value }) => {
		if (key === "name") {
			setSearchForm({ ...searchForm, [key]: value, page: DEFAULT_PAGE });
			if (!textDebouncer.token()) {
				resetTimeout();
				timeoutRef.current = setTimeout(() => {
					handleSchoolSearch({ ...searchForm, [key]: value, page: DEFAULT_PAGE });
					setSearchForm({ ...searchForm, [key]: value, page: DEFAULT_PAGE });
				}, 1000);
				return;
			}
		}
		resetTimeout();
		handleSchoolSearch({ ...searchForm, [key]: value, page: DEFAULT_PAGE });
		setSearchForm({ ...searchForm, [key]: value, page: DEFAULT_PAGE });
	};

	const handleNewSchool = () => {
		history.push({
			pathname: generalConstants.PATH.CREATE_SCHOOL,
			state: { from: FROM_SCHOOL },
		});
	};

	const handleSortAction = (sort) => {
		if (!debouncer.token()) {
			return;
		}
		let direction = "DESC";
		if (sortingKey === sort && sortingDirection === "DESC") {
			direction = "ASC";
		}
		setSortingKey(sort);
		setSortingDirection(direction);
		handleSchoolSearch({ ...searchForm, page: DEFAULT_PAGE, sort: `${sort}:${direction}` });
		setSearchForm({ ...searchForm, page: DEFAULT_PAGE, sort: `${sort}:${direction}` });
	};

	const getSchoolCounts = () => {
		const tabs = _.filter(schoolConstants.OPTION.levelTab, function (tab) {
			return !_.isEmpty(tab.key);
		});
		const result = tabs.map((tab) => {
			const items = _.find(summary, (item) => item.level === tab.key);
			return tab.value + ":" + (items?.count || 0);
		});
		return _.join(result, " / ");
	};

	const renderTH = () => {
		return schoolConstants.TABLE_COLUMN.map((column) => {
			return (
				<TableTh key={`${column.key}_table_header`} width={column.width}>
					<THButton
						style={
							column.key === "overallSubscription" || column.key === "currentYearSubscription" ? { borderLeft: "#666666 1px solid" } : {}
						}
						key={`${column.key}_table_header`}
						type="button"
						onClick={() => handleSortAction(column.key)}
					>
						<div style={{ textAlign: column.key === "status" ? "center" : "auto" }}>
							{column.key === "vipOnly" ? (
								<VipIconView>
									<VipText>{column.value}</VipText>
									{sortingKey === column.key ? (
										sortingDirection === "ASC" ? (
											<ArrowDropUp fontSize={"small"} style={{ verticalAlign: "middle" }} />
										) : (
											<ArrowDropDown fontSize={"small"} style={{ verticalAlign: "middle" }} />
										)
									) : null}
								</VipIconView>
							) : (
								column.value
							)}
							{sortingKey === column.key && column.key != "vipOnly" ? (
								sortingDirection === "ASC" ? (
									<ArrowDropUp fontSize={"small"} style={{ verticalAlign: "middle" }} />
								) : (
									<ArrowDropDown fontSize={"small"} style={{ verticalAlign: "middle" }} />
								)
							) : null}
						</div>
					</THButton>
				</TableTh>
			);
		});
	};
	const renderTableHeader = renderTH();

	const renderTD = () => {
		if (schoolList?.length > 0) {
			return schoolList?.map((item, index) => {
				const tempObj = {};
				schoolConstants.TABLE_COLUMN.forEach((column) => {
					tempObj[column.key] = item[column.key];
				});
				return (
					<TableRow
						key={`${item._id}_${index}_tr`}
						onClick={() => {
							HandleRowOnClick(item);
						}}
					>
						{Object.keys(tempObj).map((columnName, index) => {
							if (tempObj[columnName] || tempObj[columnName] === 0) {
								if (columnName === "name") {
									return (
										<TableTd key={`${item._id}_${columnName}_${index}_td`}>
											{item.status === schoolConstants.status.CLOSED.key && <ClosedBadge>已停辦</ClosedBadge>}
											{tempObj[columnName][0]}
										</TableTd>
									);
								} else if (columnName === "overallSubscription" || columnName === "currentYearSubscription") {
									return (
										<TableTd style={{ borderLeft: "#dddddd 1px solid" }} key={`${item._id}_${columnName}_${index}_td`}>
											{tempObj[columnName]}
										</TableTd>
									);
								} else if (columnName === "numMsg") {
									return <TableTd key={`${item._id}_${columnName}_${index}_td`}>{tempObj[columnName]}</TableTd>;
								} else if (columnName === "overallRank" || columnName === "currentYearRank") {
									return <TableTd key={`${item._id}_${columnName}_${index}_td`}>{tempObj[columnName]}</TableTd>;
								} else if (columnName === "vipOnly") {
									return (
										<TableTd key={`${item._id}_${columnName}_${index}_td`}>
											{tempObj[columnName] && (
												<VipIconView>
													<VipText>VIP</VipText>
												</VipIconView>
											)}
										</TableTd>
									);
								} else {
									return (
										<TableTd key={`${item._id}_${columnName}_${index}_td`}>
											{schoolConstants[columnName][tempObj[columnName]] ? schoolConstants[columnName][tempObj[columnName]].label : ""}
										</TableTd>
									);
								}
							} else {
								return <TableTd key={`${item._id}_${columnName}_${index}_td`}>{columnName === "vipOnly" ? "" : "-"}</TableTd>;
							}
						})}
					</TableRow>
				);
			});
		}
	};
	const renderTableCell = renderTD();

	return (
		<>
			<PageContainer>
				<LeftContainer>
					<AdminMenubar section={generalConstants.NAV_TAB.SCHOOL_MANAGEMENT.key} />
				</LeftContainer>
				<RightContainer>
					<AdminUserInfo />
					<AdminSectionbar
						title={generalConstants.NAV_TAB.SCHOOL_MANAGEMENT.key}
						buttonLabel={"新增學校"}
						sublabel={getSchoolCounts()}
						handleOnClick={handleNewSchool}
						buttonIcon={<AddSchoolIcon />}
						buttonType={generalConstants.BUTTON_TYPE.SUBMIT}
						locationPathArray={[
							{ title: "主頁面", isAction: true, target: generalConstants.PATH.DASHBOARD },
							{
								title: generalConstants.TAB_NAME[generalConstants.NAV_TAB.SCHOOL_MANAGEMENT.key],
								isAction: false,
								target: "",
							},
						]}
					/>
					<ContentContainer>
						<SearchBar>
							<SearchBarLeft>
								<RowInBlock marginBottom="0px">
									<div style={{ width: "20%", marginRight: "10px", maxWidth: 200 }}>
										<StandardSelect
											emptyText={"學校類型"}
											displayEmpty
											name={"level"}
											options={schoolConstants.OPTION.level}
											value={searchForm.level}
											handleChange={handleSelectChange}
										/>
									</div>
									<div style={{ width: "20%", marginRight: "10px", maxWidth: 150 }}>
										<StandardSelect
											emptyText={"地區"}
											displayEmpty
											name={"district"}
											options={schoolConstants.OPTION.district}
											value={searchForm.district}
											handleChange={handleSelectChange}
										/>
									</div>
									<div style={{ width: "20%", marginRight: "10px", maxWidth: 100 }}>
										<StandardSelect
											emptyText={"校網"}
											displayEmpty
											name={"schoolNet"}
											options={schoolConstants.OPTION.schoolNet}
											value={searchForm.schoolNet}
											handleChange={handleSelectChange}
										/>
									</div>
									<div style={{ width: "20%", marginRight: "10px", maxWidth: 100 }}>
										<StandardSelect
											emptyText={"性別"}
											displayEmpty
											name={"gender"}
											options={schoolConstants.OPTION.gender}
											value={searchForm.gender}
											handleChange={handleSelectChange}
										/>
									</div>
									<div style={{ width: "20%", marginRight: "0px" }}>
										<StandardTextField placeholder="學校名稱" value={searchForm.name} name="name" handleChange={handleSelectChange} />
									</div>
									<RefreshButton style={{ marginLeft: "5px" }} handleOnClick={handleRefreshFilter} label="重置選項"></RefreshButton>
								</RowInBlock>
							</SearchBarLeft>
							<SearchBarRight>
								<RowInBlock marginBottom="0px" style={{ fontSize: "13px", lineHeight: "27px", float: "right" }}>
									<span>只顯示有訂閱學校</span>
									<SwitchContainer>
										<Switch checked={searchForm.withSubscriptionOnly || false} handleChange={handleSwitchChange} />
									</SwitchContainer>
								</RowInBlock>
							</SearchBarRight>
						</SearchBar>
						<TableContainer>
							<table style={{ width: "100%", borderCollapse: "collapse", tableLayout: "fixed" }}>
								<thead>
									<tr>{renderTableHeader}</tr>
								</thead>
								<tbody>{renderTableCell}</tbody>
							</table>
						</TableContainer>
						{schoolList && schoolList.length > 0 && (
							<PaginationContainer style={{ paddingLeft: "20px" }}>
								<Pagination count={numOfPage} page={currentPage} handlePageChange={handlePageChange} />
							</PaginationContainer>
						)}
					</ContentContainer>
					<AdminFooter></AdminFooter>
				</RightContainer>
			</PageContainer>
		</>
	);
};

const RowInBlock = styled.div`
	display: flex;
	margin-bottom: ${(props) => props.marginBottom || "10px"};
	flex-direction: row;
`;

const SwitchContainer = styled.div`
	line-height: 32px;
	padding-left: 5px;
`;

const ClosedBadge = styled.span`
	padding: 2px 6px 3px;
	border-radius: 3px;
	background-color: #fff0f2;
	margin: 9px 6px 9px 0;
	font-size: 10px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.5;
	letter-spacing: normal;
	color: #ff0000;
`;

const VipIconView = styled.div`
	display: flex;
	width: 32px;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	margin-left: 5px;
`;
const VipText = styled.div`
	font-size: 12;
	font-weight: bold;
	color: #333333;
	background-color: #c8ae63;
	padding-left: 5px;
	padding-right: 5px;
`;
