import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { organizationConstants } from "_constants";

export const OrganizationStatus = (props) => {
	const { item, style, ignoreActive } = props;

	const approvalText = organizationConstants.approvalStatusLabel[item.approvalStatus];
	const text = organizationConstants.statusLabel[item.status];

	if (item.approvalStatus === organizationConstants.approvalStatus.PENDING) {
		return <PendingStatus style={style}>{approvalText}</PendingStatus>;
	} else if (item.approvalStatus === organizationConstants.approvalStatus.REJECTED) {
		return <RejectedStatus style={style}>{approvalText}</RejectedStatus>;
	} else if (!ignoreActive && item.status === organizationConstants.status.ACTIVE) {
		return <ActiveStatus style={style}>{text}</ActiveStatus>;
	} else if (item.status === organizationConstants.status.UNACTIVATE) {
		return <UnactivateStatus style={style}>{text}</UnactivateStatus>;
	} else if (item.status === organizationConstants.status.CLOSED) {
		return <ClosedStatus style={style}>{text}</ClosedStatus>;
	} else if (item.status === organizationConstants.status.SUSPEND) {
		return <SuspendStatus style={style}>{text}</SuspendStatus>;
	} else if (item.status === organizationConstants.status.BLOCKED) {
		return <BlockStatus style={style}>{text}</BlockStatus>;
	} else {
		return <div></div>;
	}
};

const RootStatus = styled.div`
	padding: 3px 0px;
	background-color: red;
	text-align: center;
	border-radius: 3px;
	max-width: 60px;
	margin: 0 auto;
	font-size: 11px;
`;

const SuspendStatus = styled(RootStatus)`
	color: black;
	background-color: #ffd95c;
`;

const ClosedStatus = styled(RootStatus)`
	color: white;
	background-color: #ff5c5c;
`;

const PendingStatus = styled(RootStatus)`
	color: black;
	background-color: #ffd95c;
`;
const UnactivateStatus = styled(RootStatus)`
	color: black;
	background-color: #ffd95c;
`;
const ActiveStatus = styled(RootStatus)`
	color: black;
	background-color: #5cff82;
`;
const RejectedStatus = styled(RootStatus)`
	color: white;
	background-color: #ff5c5c;
`;
const BlockStatus = styled(RootStatus)`
	color: white;
	background-color: #000000;
`;

OrganizationStatus.propTypes = {
	item: PropTypes.object,
	style: PropTypes.object,
	ignoreActive: PropTypes.bool,
};
