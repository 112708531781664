import { useState, useMemo } from "react";

export const useSortableData = (items, config = null) => {
	const [sortConfig, setSortConfig] = useState(config);

	const sortedItems = useMemo(() => {
		let sortableItems = [...items];
		if (sortConfig !== null) {
			sortableItems.sort((a, b) => {
				if ((a[sortConfig.key] === undefined || a[sortConfig.key] === "") && sortConfig.nullBig) {
					return sortConfig.direction === "ascending" ? 1 : -1;
				}
				if ((b[sortConfig.key] === undefined || b[sortConfig.key] === "") && sortConfig.nullBig) {
					return sortConfig.direction === "ascending" ? -1 : 1;
				}

				if ((a[sortConfig.key] === undefined || a[sortConfig.key] === "") && !sortConfig.nullBig) {
					return sortConfig.direction === "ascending" ? -1 : 1;
				}
				if ((b[sortConfig.key] === undefined || b[sortConfig.key] === "") && !sortConfig.nullBig) {
					return sortConfig.direction === "ascending" ? 1 : -1;
				}

				if (a[sortConfig.key] < b[sortConfig.key]) {
					return sortConfig.direction === "ascending" ? -1 : 1;
				}
				if (a[sortConfig.key] > b[sortConfig.key]) {
					return sortConfig.direction === "ascending" ? 1 : -1;
				}

				return 0;
			});
		}

		return sortableItems;
	}, [items, sortConfig]);

	const requestSort = (key, nullBig = true) => {
		let direction = "ascending";
		if (sortConfig && sortConfig.key === key && sortConfig.direction === "ascending") {
			direction = "descending";
		}

		setSortConfig({ key, direction, nullBig });
	};

	const requestSortWithDirection = (key, direction) => {
		setSortConfig({ key, direction });
	};

	return { items: sortedItems, requestSort, sortConfig, requestSortWithDirection };
};
