export const templateConstants = {
	type: {
		SUBMIT_TEMPLATE_REQUEST: "SUBMIT_TEMPLATE_REQUEST",
		SUBMIT_TEMPLATE_FAILURE: "SUBMIT_TEMPLATE_FAILURE",
		SUBMIT_TEMPLATE_SUCCESS: "SUBMIT_TEMPLATE_SUCCESS",
		RESET_TEMPLATE_SUCCESS: "RESET_TEMPLATE_SUCCESS",

		FETCH_TEMPLATE_REQUEST: "FETCH_TEMPLATE_REQUEST",
		FETCH_TEMPLATE_FAILURE: "FETCH_TEMPLATE_FAILURE",
		FETCH_TEMPLATE_SUCCESS: "FETCH_TEMPLATE_SUCCESS",

		FETCH_TEMPLATE_LIST_SUCCESS: "FETCH_TEMPLATE_LIST_SUCCESS",

		UPDATE_TEMPLATE_REQUEST: "UPDATE_TEMPLATE_REQUEST",
		UPDATE_TEMPLATE_FAILURE: "UPDATE_TEMPLATE_FAILURE",
		UPDATE_TEMPLATE_SUCCESS: "UPDATE_TEMPLATE_SUCCESS",

		DELETE_TEMPLATE_REQUEST: "DELETE_TEMPLATE_REQUEST",
		DELETE_TEMPLATE_FAILURE: "DELETE_TEMPLATE_FAILURE",
		DELETE_TEMPLATE_SUCCESS: "DELETE_TEMPLATE_SUCCESS",
	},
	TABLE_COLUMN: [
		{ key: "name", value: "模板列表", searchable: true },
		{ key: "status", value: "", searchable: false },
	],
	Translate: {
		type: {
			ADMINMSG: "訊息",
		},
	},
	DATA: {
		type: { ADMINMSG: "adminmsg" },
		variant: {
			NORMAL: "normal",
			LITE: "lite",
		},
	},
	OPTION: {
		type: [{ key: "ADMINMSG", value: "訊息" }],
	},
};
