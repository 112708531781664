import React, { useState, useEffect } from "react";
import { Prompt } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { Loading, Select, TextField, SubmitButton, CancelButton, SingleImageUploader, WarningButton } from "components/general";
import { generalConstants, organizationConstants } from "_constants";
import { history } from "_util/_helper";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import editIcon from "resources/img/school_inner_pencil.svg";
import { AdminMenubar, AdminUserInfo, AdminSectionbar, AdminFooter } from "components/admin";
import { PageContainer, LeftContainer, RightContainer, ContentContainer } from "components/layout";
import { MenuItem, Select as MUISelect, IconButton } from "@material-ui/core";
import { organizationAction } from "actions";

export const EditOrganizationPage = () => {
	const location = useLocation();
	const { isFetching, organizationObj, updateOrganizationSuccess } = useSelector(({ organization }) => ({
		isFetching: organization.isFetching,
		organizationObj: organization.organization,
		updateOrganizationSuccess: organization.updateOrganizationSuccess,
	}));

	const [key] = useState(location?.state?.organizationKey);
	const [isLoading, setIsLoading] = useState(false);
	const initForm = {
		contactPerson: "",
		email: "",
		name: "",
		status: "ACTIVE",
		approvalStatus: "APPROVED",
		address: "",
		whatsapp: "",
		facebook: "",
		website: "",
		isMultiCenter: false,
		numOfCenter: "1",
		phone: "",
		profilePic: "",
	};
	const [enableEditEmail, setEnableEditEmail] = useState(false);
	const [formData, setFormData] = useState(initForm);
	const [isChanged, setIsChanged] = useState(false);
	const [label, setLabel] = useState("");
	const dispatch = useDispatch();

	useEffect(() => {
		if (organizationObj) {
			setFormData({
				...formData,
				key: organizationObj._key,
				rev: organizationObj._rev,
				contactPerson: organizationObj.contactPerson || "",
				email: organizationObj.email || "",
				phone: organizationObj.phone || "",
				facebook: organizationObj.facebook || "",
				whatsapp: organizationObj.whatsapp || "",
				name: organizationObj.name || "",
				address: organizationObj.address || "",
				website: organizationObj.website || "",
				isMultiCenter: organizationObj.isMultiCenter,
				numOfCenter: organizationObj.numOfCenter || "",
				profilePic: organizationObj.profilePic || "",
				status: organizationObj.status || "",
				approvalStatus: organizationObj.approvalStatus || "",
				createdTime: organizationObj.createdTime,
				approvedBy: organizationObj.approvedBy || "",
				approvalTime: organizationObj.approvalTime || "",
				lastUpdateTime: organizationObj.lastUpdateTime || "",
				lastUpdateBy: organizationObj.lastUpdateBy || "",
				remark: organizationObj.remark || "",
				rejectReason: organizationObj.rejectReason || "",
			});
			setLabel(organizationObj.name);
		}
	}, [organizationObj]);

	useEffect(() => {
		if (key) {
			dispatch(organizationAction.getOrganization({ key, includeSubsidiary: false }));
		}
	}, [dispatch]);

	useEffect(() => {
		setIsLoading(isFetching);
		if (isChanged && !isFetching && updateOrganizationSuccess) {
			setIsChanged(false);
			history.goBack();
		}
	}, [isFetching, updateOrganizationSuccess]);

	const handleChange = ({ key, value }) => {
		setFormData({ ...formData, [key]: value });
		setIsChanged(true);
	};

	const HandleEditButtonOnClick = () => {
		setEnableEditEmail((prev) => !prev);
	};

	const handleResendEmail = () => {
		organizationAction.resendVerifyEmail({ key: organizationObj._key });
	};

	const handleChangeImage = (file) => {
		setFormData({ ...formData, profilePic: file });
		setIsChanged(true);
	};

	const handleClearImage = () => {
		setFormData({ ...formData, profilePic: "" });
		setIsChanged(true);
	};
	const handleSubmit = () => {
		if (!formData.name) {
			toast.error("請填寫機構名稱");
			return;
		}
		if (
			!formData.email ||
			// eslint-disable-next-line no-useless-escape
			!/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()\.,;\s@\"]+\.{0,1})+([^<>()\.,;:\s@\"]{2,}|[\d\.]+))$/.test(
				formData.email
			)
		) {
			toast.error("請填寫有效的電郵地址");
			return;
		}
		if (!formData.phone) {
			toast.error("請填寫電話");
			return;
		}
		if (!formData.address) {
			toast.error("請填寫機構地址");
			return;
		}
		if (!formData.contactPerson) {
			toast.error("請填寫聯絡人名字");
			return;
		}

		if (formData.isMultiCenter && (!/^[0-9]*$/.test(formData.numOfCenter) || formData.numOfCenter < 1)) {
			toast.error("必須輸入正分校數");
			return;
		}

		if (
			(organizationObj.status !== organizationConstants.status.ACTIVE &&
				formData.status === organizationConstants.status.ACTIVE &&
				!organizationObj.emailVerified) ||
			(organizationObj.status !== organizationConstants.status.UNACTIVATE &&
				formData.status === organizationConstants.status.UNACTIVATE &&
				organizationObj.emailVerified)
		) {
			toast.error(`無法設置狀態欄為${organizationConstants.statusLabel[formData.status]}`);
			return;
		}

		dispatch(organizationAction.updateOrganization(formData));
	};

	const handleBack = () => {
		history.goBack();
	};

	const handleIsMultiCenterChange = (event) => {
		if (event.target.value === "false") {
			setFormData({ ...formData, isMultiCenter: false, numOfCenter: "1" });
		} else {
			setFormData({ ...formData, isMultiCenter: true });
		}
		setIsChanged(true);
	};

	const handleChangeNumOfCenter = ({ key, value }) => {
		if (!!value && (!/^[0-9]*$/.test(value) || value < 1)) {
			toast.error("必須輸入正整數");
		} else {
			setFormData({ ...formData, [key]: value });
		}
		setIsChanged(true);
	};

	const getLocationPath = () => {
		const pathArray = [];

		pathArray.push({ title: "主頁面", isAction: true, target: generalConstants.PATH.DASHBOARD });
		pathArray.push({
			title: generalConstants.TAB_NAME[generalConstants.NAV_TAB.ORGANIZATION_MANAGEMENT.key],
			isAction: true,
			target: generalConstants.PATH.ORGANIZATION_MANAGEMENT,
		});
		if (organizationObj) {
			pathArray.push({
				title: label,
				isAction: true,
				target: generalConstants.PATH.ORGANIZATION,
				stateObj: { organizationKey: organizationObj._key },
			});
		}
		pathArray.push({
			title: generalConstants.TAB_NAME[generalConstants.NAV_TAB.EDIT_ORGANIZATION.key],
			isAction: false,
			target: "",
		});
		return pathArray;
	};

	if (!key) {
		return null;
	}
	return (
		<>
			{!!isLoading && <Loading />}
			<Prompt when={isChanged} message="你有未儲存的變更，確定不儲存下離開？" />
			<PageContainer>
				<LeftContainer>
					<AdminMenubar section={generalConstants.NAV_TAB.ORGANIZATION_MANAGEMENT.key} />
				</LeftContainer>
				<RightContainer>
					<AdminUserInfo />
					<AdminSectionbar title={generalConstants.NAV_TAB.EDIT_ORGANIZATION.key} locationPathArray={getLocationPath()} />
					<ContentContainer>
						<TitleFieldLabel style={{ marginLeft: 5 }}>- 帳戶資料</TitleFieldLabel>
						<div style={{ display: "flex", flexDirection: "row" }}>
							<ContentLeft>
								<BlockGroup>
									<TopBlock flex="0 0 135px">
										<TextFieldLabel>頭像</TextFieldLabel>
										<SingleImageUploader
											src={formData.profilePic ?? ""}
											handleChange={handleChangeImage}
											handleClear={handleClearImage}
											uploadLabel="上傳圖片"
											noImageLabel="沒有圖片"
										/>
									</TopBlock>

									<AvatarBlock>
										<TextFieldLabel>狀態</TextFieldLabel>
										<Select
											name={"status"}
											options={organizationConstants.STATUS_OPTION}
											value={formData.status ? formData.status : ""}
											handleChange={handleChange}
										/>
										<WarningButton
											style={{ marginTop: "9px" }}
											label={"重發認證電郵"}
											handleOnClick={handleResendEmail}
											active={organizationObj?.status === organizationConstants.status.UNACTIVATE}
										/>
										<TextFieldLabel style={{ marginTop: "9px" }}>聯絡人名字*</TextFieldLabel>
										<TextField placeholder="聯絡人名字" value={formData.contactPerson} name="contactPerson" handleChange={handleChange} />
									</AvatarBlock>
								</BlockGroup>

								<div>
									<BlockGroup>
										<Block>
											<TextFieldLabel>電郵*</TextFieldLabel>
											<BlockGroup>
												<TextField
													placeholder="電郵"
													disabled={!enableEditEmail}
													value={formData.email}
													name="email"
													handleChange={handleChange}
												/>
												<IconButton aria-label="編輯" onClick={HandleEditButtonOnClick} style={{ padding: "5px" }}>
													<img alt="" src={editIcon} />
												</IconButton>
											</BlockGroup>
										</Block>
									</BlockGroup>

									<BlockGroup>
										<Block>
											<TextFieldLabel>電話*</TextFieldLabel>
											<TextField placeholder="電話" value={formData.phone} name="phone" handleChange={handleChange} />
										</Block>
									</BlockGroup>

									<BlockGroup>
										<Block>
											<TextFieldLabel>Whatsapp</TextFieldLabel>
											<TextField placeholder="Whatsapp" value={formData.whatsapp} name="whatsapp" handleChange={handleChange} />
										</Block>
									</BlockGroup>

									<BlockGroup>
										<Block>
											<TextFieldLabel>Facebook連結</TextFieldLabel>
											<TextField placeholder="Facebook連結" value={formData.facebook} name="facebook" handleChange={handleChange} />
										</Block>
									</BlockGroup>
								</div>
							</ContentLeft>
							<ContentRight style={{ paddingTop: 5 }}>
								<div style={{ minHeight: "460px" }}>
									<BlockGroup>
										<Block style={{ marginTop: 0, paddingTop: 0 }}>
											<TextFieldLabel>機構名稱*</TextFieldLabel>
											<TextField placeholder="機構名稱" value={formData.name} name="name" handleChange={handleChange} />
										</Block>
									</BlockGroup>
									<Block>
										<TextFieldLabel>機構地址*</TextFieldLabel>
										<TextField
											placeholder="機構地址"
											multiline
											rows={5}
											value={formData.address}
											name="address"
											handleChange={handleChange}
										/>
									</Block>
									<Block>
										<TextFieldLabel>網址</TextFieldLabel>
										<TextField placeholder="網址" value={formData.website} name="website" handleChange={handleChange} />
									</Block>
									<div style={{ paddingLeft: 10, paddingTop: 10, marginBottom: 0, paddingBottom: 0 }}>
										<TextFieldLabel>需要管理多間分校嗎?*</TextFieldLabel>
									</div>
									<Block style={{ paddingTop: 0, marginTop: 10 }}>
										<MUISelect
											value={formData.isMultiCenter ? "true" : "false"}
											onChange={handleIsMultiCenterChange}
											input={<TextField value={"isMultiCenter"} style={{ backgroundColor: "white" }} fullWidth={false} />}
										>
											<MenuItem key={"Yes"} value={"true"}>
												{"需要"}
											</MenuItem>
											<MenuItem key={"No"} value={"false"}>
												{"不需要"}
											</MenuItem>
										</MUISelect>
										<TextField
											style={{ marginLeft: 10, backgroundColor: "white", width: 100 }}
											fullWidth={false}
											value={formData.numOfCenter}
											name="numOfCenter"
											disabled={!formData.isMultiCenter}
											handleChange={handleChangeNumOfCenter}
										/>
									</Block>
								</div>
							</ContentRight>
						</div>
						<RowInBlock style={{ justifyContent: "flex-end" }}>
							<CancelButton label={"取消"} style={{ marginRight: "15px" }} handleOnClick={handleBack} />
							<SubmitButton label={"確認"} style={{ marginRight: "15px" }} handleOnClick={handleSubmit} active={isChanged && !isFetching} />
						</RowInBlock>
					</ContentContainer>
					<AdminFooter></AdminFooter>
				</RightContainer>
			</PageContainer>
		</>
	);
};

const RowInBlock = styled.div`
	padding-top: 30px;
	display: flex;
	flex-direction: row;
`;

const ContentLeft = styled.div`
	flex: 1;
	max-width: 410px;
	padding-right: 15px;
	border-right: 1px solid #dddddd;
`;

const ContentRight = styled.div`
	flex: 1;
	max-width: 410px;
	padding: 10px;
`;

const BlockGroup = styled.div`
	display: flex;
	flex-direction: row;
`;

const Block = styled.div`
	padding: 10px;
	flex: ${(props) => props.flex || 1};
`;

const AvatarBlock = styled.div`
	padding-left: 15px;
	padding-top: 0px;
	padding-right: 10px;
	border-left: 1px solid #dddddd;
	flex: 1;
`;

const TopBlock = styled.div`
	padding-left: 10px;
	padding-button: 10px;
	flex: ${(props) => props.flex || 1};
`;

const TextFieldLabel = styled.div`
	color: #000000;
	font-size: 13px;
	line-height: 1.5;
	font-weight: bold;
	margin-bottom: 7px;
`;

const TitleFieldLabel = styled.div`
	color: #333399;
	font-size: 14px;
	line-height: 1.5;
	font-weight: bold;
	margin-bottom: 10px;
`;
