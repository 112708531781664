import { authConstants } from "_constants";

const initialState = {
	loggedIn: false,
	changePasswordRequesting: false,
	changePasswordResult: false,
	user: {},
};

const authReducer = (state = initialState, action) => {
	switch (action.type) {
		case authConstants.type.LOGIN_SUCCESS: {
			return {
				...state,
				loggedIn: true,
				user: action.user,
			};
		}
		case authConstants.type.UNAUTHORIZED_ERROR: {
			return { user: {}, loggedIn: false };
		}
		case authConstants.type.LOGOUT: {
			return { user: {}, loggedIn: false };
		}
		case authConstants.type.CHANGE_PASSWORD_INIT: {
			return { ...state, changePasswordRequesting: false, changePasswordResult: false };
		}
		case authConstants.type.CHANGE_PASSWORD_REQUEST: {
			return { ...state, changePasswordRequesting: true };
		}
		case authConstants.type.CHANGE_PASSWORD_SUCCESS: {
			return { ...state, changePasswordRequesting: false, changePasswordResult: true };
		}
		case authConstants.type.CHANGE_PASSWORD_FAIL: {
			return { ...state, changePasswordRequesting: false, changePasswordResult: false };
		}
		default: {
			return state;
		}
	}
};

export default authReducer;
