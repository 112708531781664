import React, { useState } from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";

import ProgressiveRejectDebouncer from "_util/debouncers/ProgressiveRejectDebouncer";

export const RefreshButton = (props) => {
	const { label, handleOnClick, style } = props;
	const [debouncer] = useState(() => new ProgressiveRejectDebouncer(1 * 1000));
	return (
		<Button
			variant="outlined"
			size="small"
			style={{
				height: "30px",
				padding: "3px 10px",
				borderRadius: 50,
				borderColor: "#dedede",
				color: "#7d7d7d",
				fontSize: "12px",
				lineHeight: "1.0",
				overflow: "hidden",
				whiteSpace: "nowrap",
				textOverflow: "ellipsis",
				...style,
			}}
			onClick={() => {
				if (!debouncer.token()) return;
				if (handleOnClick) {
					handleOnClick();
				}
			}}
		>
			{label}
		</Button>
	);
};

RefreshButton.propTypes = {
	label: PropTypes.string,
	handleOnClick: PropTypes.func,
	style: PropTypes.object,
};
