import { authConstants, diffConstants } from "_constants";

const initialState = {
	isFetching: false,
	diff: {},

	websites: [],
	updateWebsite: {},
	schoolName: "",
	linkDifferences: [],
	differenceDetail: {},
	isDetailFetching: false,
	lastUpdateTime: "",
	link: "",
	updatedWebsiteInfo: {},
	addLinkSuccess: false,
	tempDiff: {},
	tempSelected: false,
	updateLinkSuccess: false,
	navigationObject: {
		root: {
			dataScrapList: [],
			currentPage: 1,
			numOfPage: 1,
			sortingKey: "noOfDiff",
			sortingDirection: "DESC",
			dataScrapSearchFilter: { level: "", district: "", gender: "", schoolNet: "", schoolName: "" },
		},
	},
};

const diffReducer = (state = initialState, action) => {
	switch (action.type) {
		case authConstants.type.UNAUTHORIZED_ERROR: {
			let navigationObject = initialState.navigationObject;
			if (typeof state.navigationObject?.root != "undefined") {
				navigationObject = {
					...state.navigationObject,
					root: { ...state.navigationObject?.root, currentPage: 1 },
				};
			}
			return {
				...state,
				navigationObject: navigationObject,
			};
		}
		case diffConstants.type.UPDATE_DIFF_REQUEST: {
			return {
				...state,
				isFetching: true,
			};
		}
		case diffConstants.type.UPDATE_DIFF_FAILURE: {
			return {
				...state,
				isFetching: false,
				updateResult: "FAIL",
			};
		}
		case diffConstants.type.UPDATE_DIFF_SUCCESS: {
			return {
				...state,
				isFetching: false,
				diff: action.diff,
				updateResult: action.updateResult,
				updatedWebsiteInfo: action.updatedWebsiteInfo,
			};
		}
		case diffConstants.type.DATA_SCRAP_REQUEST: {
			return {
				...state,
				isFetching: true,
			};
		}

		case diffConstants.type.DATA_SCRAP_FAILURE: {
			return {
				...state,
				isFetching: false,
				updateResult: "FAIL",
			};
		}
		case diffConstants.type.DATA_SCRAP_SUCCESS: {
			const navigationObject = {
				dataScrapList: action.dataScrapList,
				numOfPage: action.totalPage,
				currentPage: action.currentPage,
				totalItems: action.totalNum,
				sortingKey: action.sortingKey ?? "",
				sortingDirection: action.sortingDirection ?? "",
				dataScrapSearchFilter: {
					level: action.level ?? "",
					district: action.district ?? "",
					gender: action.gender ?? "",
					schoolNet: action.schoolNet ?? "",
					name: action.name ?? "",
				},
			};
			return {
				...state,
				isFetching: false,
				navigationObject: {
					...state.navigationObject,
					[`${action.navigationKey}`]: {
						...navigationObject,
					},
				},
			};
		}
		case diffConstants.type.DATA_SCRAP_DETAIL_REQUEST: {
			return {
				...state,
				isFetching: true,
			};
		}

		case diffConstants.type.DATA_SCRAP_DETAIL_FAILURE: {
			return {
				...state,
				isFetching: false,
			};
		}
		case diffConstants.type.DATA_SCRAP_DETAIL_SUCCESS: {
			return {
				...state,
				isFetching: false,
				websites: action.websites,
				schoolName: action.schoolName,
			};
		}

		case diffConstants.type.DATA_SCRAP_GET_DIFFERENCES_BY_WEBSITE_ID_REQUEST: {
			return {
				...state,
				isFetching: true,
			};
		}

		case diffConstants.type.DATA_SCRAP_GET_DIFFERENCES_BY_WEBSITE_ID_FAILURE: {
			return {
				...state,
				isFetching: false,
			};
		}
		case diffConstants.type.DATA_SCRAP_GET_DIFFERENCES_BY_WEBSITE_ID_SUCCESS: {
			return {
				...state,
				isFetching: false,
				linkDifferences: action.linkDifferences,
				lastUpdateTime: action.lastUpdateTime,
				link: action.link,
			};
		}
		case diffConstants.type.DATA_SCRAP_GET_DIFFERENCE_DETAIL_REQUEST: {
			return {
				...state,
				isDetailFetching: true,
			};
		}

		case diffConstants.type.DATA_SCRAP_GET_DIFFERENCE_DETAIL_FAILURE: {
			return {
				...state,
				isDetailFetching: false,
			};
		}
		case diffConstants.type.DATA_SCRAP_GET_DIFFERENCE_DETAIL_SUCCESS: {
			return {
				...state,
				isDetailFetching: false,
				differenceDetail: action.differenceDetail,
			};
		}

		case diffConstants.type.DATA_SCRAP_UPDATE_DIFFS_REQUEST: {
			return {
				...state,
				isFetching: true,
			};
		}

		case diffConstants.type.DATA_SCRAP_UPDATE_DIFFS_FAILURE: {
			return {
				...state,
				isFetching: false,
			};
		}
		case diffConstants.type.DATA_SCRAP_UPDATE_DIFFS_SUCCESS: {
			return {
				...state,
				isFetching: false,
				linkDifferences: action.linkDifferences,
				lastUpdateTime: action.lastUpdateTime,
				link: action.link,
				updatedWebsiteInfo: action.updatedWebsiteInfo,
			};
		}

		case diffConstants.type.DATA_SCRAP_UPDATE_WEBSITES_REQUEST: {
			return {
				...state,
				isFetching: true,
			};
		}

		case diffConstants.type.DATA_SCRAP_UPDATE_WEBSITES_FAILURE: {
			return {
				...state,
				isFetching: false,
			};
		}
		case diffConstants.type.DATA_SCRAP_UPDATE_WEBSITES_SUCCESS: {
			return {
				...state,
				isFetching: false,
				websites: action.websites,
				schoolName: action.schoolName,
			};
		}

		case diffConstants.type.DATA_SCRAP_ADD_LINK_REQUEST: {
			return {
				...state,
				isFetching: true,
				addLinkSuccess: false,
			};
		}

		case diffConstants.type.DATA_SCRAP_ADD_LINK_FAILURE: {
			return {
				...state,
				isFetching: false,
				addLinkSuccess: false,
			};
		}
		case diffConstants.type.DATA_SCRAP_ADD_LINK_SUCCESS: {
			return {
				...state,
				isFetching: false,
				websites: action.websites,
				addLinkSuccess: true,
			};
		}
		case diffConstants.type.ADD_LINK_DONE: {
			return {
				...state,
				addLinkSuccess: false,
			};
		}
		case diffConstants.type.DATA_SCRAP_UPDATE_LINK_REQUEST: {
			return {
				...state,
				isFetching: true,
				updateLinkSuccess: false,
			};
		}
		case diffConstants.type.DATA_SCRAP_UPDATE_LINK_FAILURE: {
			return {
				...state,
				isFetching: false,
				updateLinkSuccess: false,
			};
		}
		case diffConstants.type.DATA_SCRAP_UPDATE_LINK_SUCCESS: {
			return {
				...state,
				isFetching: false,
				updateWebsite: action.website,
				updateLinkSuccess: true,
			};
		}
		case diffConstants.type.UPDATE_LINK_DONE: {
			return {
				...state,
				updateLinkSuccess: false,
			};
		}

		case diffConstants.type.DATA_SCRAP_DELETE_LINK_REQUEST: {
			return {
				...state,
				isFetching: true,
			};
		}

		case diffConstants.type.DATA_SCRAP_DELETE_LINK_FAILURE: {
			return {
				...state,
				isFetching: false,
			};
		}
		case diffConstants.type.DATA_SCRAP_DELETE_LINK_SUCCESS: {
			return {
				...state,
				isFetching: false,
				websites: action.websites,
			};
		}

		case diffConstants.type.DATA_SCRAP_COMPARE_DIFFS_REQUEST: {
			return {
				...state,
				isFetching: true,
			};
		}

		case diffConstants.type.DATA_SCRAP_COMPARE_DIFFS_FAILURE: {
			return {
				...state,
				isFetching: false,
			};
		}
		case diffConstants.type.DATA_SCRAP_COMPARE_DIFFS_SUCCESS: {
			return {
				...state,
				isFetching: false,
				tempDiff: action.tempDiff,
				tempSelected: true,
			};
		}

		case diffConstants.type.RESET_TEMP_DIFF: {
			return {
				...state,
				isFetching: false,
				tempDiff: {},
				tempSelected: false,
			};
		}

		default: {
			return state;
		}
	}
};

export default diffReducer;
