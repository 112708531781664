/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useState, useEffect } from "react";
import { Prompt } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { messageConstants, generalConstants, schoolConstants, schoolDataConstants } from "_constants";
import _ from "lodash";
import { AsyncPaginate } from "react-select-async-paginate";

import { history } from "_util/_helper";
import { useHistoryState } from "_util/hook";
import {
	Select,
	Checkbox,
	TextField,
	SubmitButton,
	CancelButton,
	ExcelImportDialog,
	TemplateImportDialog,
	TemplateExportDialog,
} from "components/general";
import { MuiPickersUtilsProvider, KeyboardTimePicker, KeyboardDatePicker } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { toast } from "react-toastify";
import Button from "@material-ui/core/Button";

import PropTypes from "prop-types";
import Add from "@material-ui/icons/AddCircleOutline";
import Remove from "@material-ui/icons/RemoveCircleOutline";
import { AdminMenubar, AdminUserInfo, AdminSectionbar, AdminFooter } from "components/admin";
import { PageContainer, LeftContainer, RightContainer, ContentContainer } from "components/layout";
import moment from "moment";
import ImportIcon from "resources/img/import_template.svg";
import ExportIcon from "resources/img/export_template.svg";

import { ReactComponent as ImportExcelIcon } from "resources/img/import_excel.svg";
import { schoolService } from "services";

const FROM_SCHOOL = "school";
const FROM_DATASCRAP = "dataScrap";
const FROM_ORGANIZATION = "organization";
const DEFAULT_PAGE = 1;
const DEFAULT_LIMIT = 10;
export const NewMsgPage = (props) => {
	const { from, schoolKey, message } = props.location.state;

	const getRoundedDate = (minutes, d = new Date()) => {
		let ms = 1000 * 60 * minutes;
		let roundedDate = new Date(Math.ceil(d.getTime() / ms) * ms);

		return roundedDate;
	};

	const isSameDate = (date1, date2) => {
		return date1.getFullYear() === date2.getFullYear() && date1.getMonth() === date2.getMonth() && date1.getDate() === date2.getDate();
	};

	const { loggedIn, organizationObj } = useSelector(({ auth, organization }) => {
		const res = { loggedIn: auth.loggedIn };
		if (from === FROM_ORGANIZATION) res.organizationObj = organization.organization;
		return res;
	});

	const initForm = {
		type: messageConstants.OPTION.type[0].key,
		targetAudience: "",
		infoType: messageConstants.OPTION.infoType[0].key,
		schoolLevel: schoolConstants.OPTION.level[0].key,
		schoolDistrict: schoolConstants.OPTION.district[0].key,
		schoolSession: "",
		schoolId: "",
		schoolName: "",
		scheduleType: messageConstants.OPTION.scheduleType[0].key,
		scheduleTime: getRoundedDate(5, new Date()),
		category: "",
		title: "",
		detail: "",
		startTime: getRoundedDate(30, new Date()),
		endTime: getRoundedDate(90, new Date()),
		eventType: messageConstants.DATA.eventType.SINGLE,
		fullDay: false,
		url: "",
	};

	const initExportData = {
		type: "adminmsg",
		category: "",
		title: "",
		detail: "",
	};

	const [formData, setFormData] = useHistoryState("formData", initForm);
	const [selectedSchool, setSelectedSchool] = useHistoryState("selectedSchool", null);

	const [exportData, setExportData] = useState(initExportData);

	const [addDateTimeIsClose, setAddDateTimeIsClose] = useHistoryState("addDateTimeIsClose", true);
	const [addURLIsClose, setAddURLIsClose] = useHistoryState("addURLIsClose", true);
	const [isAlertShow, setIsAlertShow] = useState(false);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [isExportShow, setIsExportShow] = useState(false);
	const [isImportShow, setIsImportShow] = useState(false);

	const dispatch = useDispatch();

	useEffect(() => {
		if (addDateTimeIsClose) {
			setFormData({
				...formData,
				startTime: getRoundedDate(30, new Date()),
				endTime: getRoundedDate(90, new Date()),
				eventType: messageConstants.DATA.eventType.SINGLE,
				fullDay: false,
			});
		}
	}, [addDateTimeIsClose, dispatch]);

	const handleChange = ({ key, value }) => {
		if (key === "infoType") {
			if (value === messageConstants.DATA.infoType.EB) {
				setFormData({ ...formData, [key]: value, schoolId: `school/${schoolDataConstants.specialSchoolId}`, schoolName: "教育局" });
				setSelectedSchool(null);
			} else {
				setFormData({
					...formData,
					[key]: value,
					schoolId: "",
					schoolLevel: schoolConstants.OPTION.level[0].key,
					schoolDistrict: schoolConstants.OPTION.district[0].key,
					schoolSession: "",
				});
			}
		} else if (key === "fullDay") {
			setFormData({ ...formData, [key]: !formData.fullDay });
		} else if (key === "schoolLevel") {
			setFormData({ ...formData, [key]: value, schoolId: "" });
		} else if (key === "scheduleTime") {
			const tempTime = new Date(value);
			setFormData({ ...formData, [key]: tempTime });
		} else if (key === "startTime" || key === "endTime") {
			const tempTime = new Date(value);
			let anotherKey = "";
			let anotherValue = null;
			let sameDate;
			if (key === "startTime") {
				anotherKey = "endTime";
				if (value > new Date(formData.endTime).getTime()) {
					anotherValue = tempTime;
				} else {
					anotherValue = formData.endTime;
				}
				sameDate = isSameDate(tempTime, anotherValue);
			} else {
				anotherKey = "startTime";
				if (value < new Date(formData.startTime).getTime()) {
					anotherValue = tempTime;
				} else {
					anotherValue = formData.startTime;
				}
				sameDate = isSameDate(tempTime, anotherValue);
			}
			setFormData({
				...formData,
				[key]: tempTime,
				[anotherKey]: anotherValue,
				eventType: sameDate ? messageConstants.DATA.eventType.SINGLE : messageConstants.DATA.eventType.PERIOD,
			});
		} else {
			setFormData({ ...formData, [key]: value });
		}
	};

	const handleBack = () => {
		history.goBack();
	};

	const handleSubmit = () => {
		if (!formData.type) {
			toast.error("請填寫訊息類別");
			return;
		}
		if (!formData.infoType) {
			toast.error("請填寫資訊分類");
			return;
		}

		if (
			formData.type === messageConstants.DATA.type.NEWS &&
			(formData.infoType === messageConstants.DATA.infoType.SCHOOL || formData.infoType === messageConstants.DATA.infoType.EB)
		) {
			if (!formData.schoolId) {
				toast.error("請填寫學校");
				return;
			}
		}

		if (!formData.title) {
			toast.error("請填寫標題");
			return;
		}

		if (!addDateTimeIsClose && !formData.startTime) {
			toast.error("請填寫開始日期");
			return;
		}
		if (!addDateTimeIsClose && !formData.endTime) {
			toast.error("請填寫結束日期");
			return;
		}
		if (!formData.targetAudience && (formData.type === messageConstants.DATA.type.NOTIFICATION || selectedSchool?.vipOnly)) {
			toast.error("請填寫發送對象");
			return;
		}

		setIsSubmitting(true);
	};

	useEffect(() => {
		if (isSubmitting) {
			setIsSubmitting(false);

			var newStartTime;
			var newEndTime;
			if (formData.fullDay) {
				const startDate = new Date(formData.startTime);
				const endDate = new Date(formData.endTime);
				newStartTime = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate(), 0, 0, 0);
				newEndTime = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate(), 23, 59, 59);
			}

			var data = {
				type: formData.type,
				infoType:
					formData.type === messageConstants.DATA.type.NOTIFICATION
						? messageConstants.DATA.infoType.OTHER
						: messageConstants.DATA.infoType.SCHOOL,
				schoolId: formData.type === messageConstants.DATA.type.NOTIFICATION ? "" : formData.schoolId,
				schoolName: formData.type === messageConstants.DATA.type.NOTIFICATION ? "" : formData.schoolName || "",
				scheduleTime: formData.scheduleType === messageConstants.DATA.scheduleType.IMMEDIATELY ? "" : new Date(formData.scheduleTime),
				category: formData.category,
				title: formData.title,
				detail: formData.detail,
				startTime: addDateTimeIsClose ? "" : formData.fullDay ? newStartTime : new Date(formData.startTime),
				endTime: addDateTimeIsClose ? "" : formData.fullDay ? newEndTime : new Date(formData.endTime),
				eventType: formData.eventType,
				fullDay: formData.fullDay,
				url: addURLIsClose ? "" : formData.url,
			};
			if (formData.type === messageConstants.OPTION.type[1].key || (!_.isEmpty(formData.schoolId) && selectedSchool?.vipOnly)) {
				data = { ...data, targetAudience: formData.targetAudience };
			}
			history.push({
				pathname:
					from === FROM_ORGANIZATION
						? `${generalConstants.PATH.ORGANIZATION}/school/message/confirm`
						: from === FROM_SCHOOL
						? `${generalConstants.PATH.SCHOOL_DETAIL}/message/confirm`
						: from === FROM_DATASCRAP
						? `${generalConstants.PATH.DATA_SCRAP_DETAIL}/message/confirm`
						: generalConstants.PATH.MSG_CONFIRM,
				state:
					from === FROM_SCHOOL || from === FROM_ORGANIZATION
						? { from: from, schoolKey: schoolKey, message: data, schoolVipOnly: selectedSchool?.vipOnly }
						: from === FROM_DATASCRAP
						? {
								from: from,
								dataScrapKey: selectedSchool?.schoolId,
								schoolKey: schoolKey,
								message: { ...data, ...message },
								schoolVipOnly: selectedSchool?.vipOnly,
						  }
						: { from: from, message: data, schoolVipOnly: selectedSchool?.vipOnly },
			});
			setIsSubmitting(false);
		}
	}, [isSubmitting]);

	useEffect(() => {
		if (history.action === "POP") return;
		let temp = initForm;
		if (message) {
			temp = { ...temp, ...message };
			if (temp.url !== "") {
				setAddURLIsClose(false);
			} else {
				setAddURLIsClose(true);
			}
			if (message["startTime"]) {
				setAddDateTimeIsClose(false);
			} else {
				setAddDateTimeIsClose(true);
				temp = {
					...temp,
					startTime: getRoundedDate(30, new Date()),
					endTime: getRoundedDate(90, new Date()),
					eventType: messageConstants.DATA.eventType.SINGLE,
					fullDay: false,
				};
			}
			if (message.type === messageConstants.OPTION.type[1].key) {
				temp = {
					...temp,
					infoType: messageConstants.OPTION.infoType[0].key,
					schoolLevel: schoolConstants.OPTION.level[0].key,
					district: schoolConstants.OPTION.district[0].key,
				};
			}
			if (!message.scheduleTime) {
				temp.scheduleType = messageConstants.OPTION.scheduleType[0].key;
			} else if (moment().diff(moment(temp.scheduleTime), "second") < 0) {
				temp.scheduleType = messageConstants.OPTION.scheduleType[1].key;
			} else {
				temp.scheduleType = messageConstants.OPTION.scheduleType[0].key;
			}
			if (message.infoType === messageConstants.OPTION.infoType[0].key) {
				setSelectedSchool({ schoolId: message.schoolId, schoolName: message.schoolName, vipOnly: message.schoolVipOnly });
			}
		}
		if (!_.isEmpty(temp)) setFormData(temp);
	}, [message]);

	const handleCloseAlert = () => {
		setIsAlertShow(false);
	};

	const handleOnClick = () => {
		setIsAlertShow(true);
	};

	const handleCloseExport = () => {
		setIsExportShow(false);
	};

	const handleCloseImport = () => {
		setIsImportShow(false);
	};
	const handleOnExport = () => {
		if (!formData.title) {
			toast.error("請填寫標題");
			return;
		}

		setExportData({
			...exportData,
			category: formData.category,
			title: formData.title,
			detail: formData.detail,
		});

		setIsExportShow(true);
	};

	const handleOnImport = () => {
		setIsImportShow(true);
	};

	const handleImport = (importData) => {
		if (!importData) {
			toast.error("模板導入錯誤");
			return;
		}
		setFormData({
			...formData,
			category: importData.category,
			title: importData.title,
			detail: importData.detail,
		});
		toast.success("已成功導入模板");
		setIsImportShow(false);
	};

	// handle selection
	const handleSelectChange = (value) => {
		setSelectedSchool(value);
		if (!_.isEmpty(value)) {
			setFormData({ ...formData, schoolId: value.schoolId, schoolName: value.schoolName });
		} else {
			setFormData({ ...formData, schoolId: "", schoolName: "" });
		}
	};

	const loadOptions = async (searchQuery, loadedOptions, { page }) => {
		let form = {
			page: page,
			limit: DEFAULT_LIMIT,
			sort: `name:ASC`,
		};
		if (!_.isEmpty(searchQuery)) {
			form = {
				...form,
				name: searchQuery,
			};
		}
		if (formData.schoolLevel) {
			form = {
				...form,
				level: formData.schoolLevel,
			};
		}
		if (formData.schoolDistrict) {
			form = {
				...form,
				district: formData.schoolDistrict,
			};
		}
		if (formData.schoolSession) {
			form = {
				...form,
				session: formData.schoolSession,
			};
		}
		const response = await schoolService.getSchoolList(form);
		if (response && response.items) {
			var hasMore = true;
			var optionList = response.items.map((i) => {
				return { schoolId: i._id, schoolName: i.name[0] || i.name[1] || "", vipOnly: i.vipOnly || false };
			});
			if (loadedOptions.length >= response.totalItems || optionList.length === response.totalItems) {
				hasMore = false;
			}
			return {
				options: optionList,
				hasMore: hasMore,
				additional: {
					page: page + 1,
				},
			};
		}
		return {
			options: [],
			hasMore: false,
			additional: {
				page: page,
			},
		};
	};

	const getLocationPath = () => {
		const pathArray = [];

		pathArray.push({ title: "主頁面", isAction: true, target: generalConstants.PATH.DASHBOARD });
		pathArray.push({
			title:
				generalConstants.TAB_NAME[
					from === FROM_ORGANIZATION
						? generalConstants.NAV_TAB.ORGANIZATION_MANAGEMENT.key
						: from === FROM_SCHOOL
						? generalConstants.NAV_TAB.SCHOOL_MANAGEMENT.key
						: from === FROM_DATASCRAP
						? generalConstants.NAV_TAB.DATA_SCRAP.key
						: generalConstants.NAV_TAB.MSG_MANAGEMENT.key
				],
			isAction: true,
			target:
				from === FROM_ORGANIZATION
					? generalConstants.PATH.ORGANIZATION_MANAGEMENT
					: from === FROM_SCHOOL
					? generalConstants.PATH.SCHOOL_MANAGEMENT
					: from === FROM_DATASCRAP
					? generalConstants.PATH.DATA_SCRAP
					: generalConstants.PATH.MSG_MANAGEMENT,
		});
		if (from === FROM_SCHOOL || from === FROM_ORGANIZATION) {
			if (from === FROM_ORGANIZATION && organizationObj) {
				pathArray.push({
					title: organizationObj.name || "",
					isAction: true,
					target: generalConstants.PATH.ORGANIZATION,
					stateObj: { from: from, organizationKey: organizationObj._key },
				});
			}
			pathArray.push({
				title: message?.schoolName,
				isAction: true,
				target: from === FROM_ORGANIZATION ? `${generalConstants.PATH.ORGANIZATION}/school` : generalConstants.PATH.SCHOOL_DETAIL,
				stateObj: { from: from, schoolKey: schoolKey },
			});
		} else if (from === FROM_DATASCRAP) {
			pathArray.push({
				title: generalConstants.TAB_NAME[generalConstants.NAV_TAB.DATA_SCRAP_DETAIL.key],
				isAction: true,
				target: generalConstants.PATH.DATA_SCRAP_DETAIL,
				stateObj: {
					dataScrapKey: schoolKey,
					school: {
						_id: message.schoolId,
						name: [message.schoolName],
						level: message.schoolLevel,
						session: [message.schoolSession],
						district: message.schoolDistrict,
						vipOnly: message.schoolVipOnly,
					},
				},
			});
		}
		pathArray.push({
			title: generalConstants.TAB_NAME[generalConstants.NAV_TAB.NEW_MSG.key],
			isAction: false,
			target: "",
		});

		return pathArray;
	};

	return (
		<>
			<ExcelImportDialog active={isAlertShow} handleOnClose={handleCloseAlert} title={"確定移除訊息？"}></ExcelImportDialog>
			<TemplateExportDialog active={isExportShow} handleOnClose={handleCloseExport} template={exportData}></TemplateExportDialog>
			<TemplateImportDialog active={isImportShow} handleOnClose={handleCloseImport} handleOnImport={handleImport}></TemplateImportDialog>
			<Prompt when={!isSubmitting && loggedIn} message="你有未儲存的變更，確定不儲存下離開？" />
			<PageContainer>
				<LeftContainer>
					<AdminMenubar
						section={
							from === FROM_ORGANIZATION
								? generalConstants.NAV_TAB.ORGANIZATION_MANAGEMENT.key
								: from === FROM_SCHOOL
								? generalConstants.NAV_TAB.SCHOOL_MANAGEMENT.key
								: from === FROM_DATASCRAP
								? generalConstants.NAV_TAB.DATA_SCRAP.key
								: generalConstants.NAV_TAB.MSG_MANAGEMENT.key
						}
					/>
				</LeftContainer>
				<RightContainer>
					<AdminUserInfo />
					<AdminSectionbar
						title={generalConstants.NAV_TAB.NEW_MSG.key}
						handleOnClick={handleOnClick}
						buttonLabel="導入Excel"
						buttonIcon={<ImportExcelIcon style={{ width: "22px", height: "22px" }} />}
						buttonType={generalConstants.BUTTON_TYPE.IMPORT}
						locationPathArray={getLocationPath()}
					/>
					<ContentContainer>
						<RowInBlock>
							<ContentLeft>
								<Block marginTop="0">
									<Title> - 訊息類別</Title>
									{from != FROM_SCHOOL && from != FROM_ORGANIZATION ? (
										<div style={{ width: "80%" }}>
											<Select name={"type"} options={messageConstants.OPTION.type} value={formData.type} handleChange={handleChange} />
										</div>
									) : (
										<TextFieldLabel>{messageConstants.Translate.type[formData.type]}</TextFieldLabel>
									)}
								</Block>
								{from != FROM_SCHOOL && from != FROM_ORGANIZATION && formData.type === messageConstants.DATA.type.NEWS && (
									<Block>
										<Title> - 資訊分類</Title>
										<RowInBlock>
											{messageConstants.OPTION.infoType.map((item) => {
												return (
													<Checkbox
														key={`${item.key}_checkbox`}
														name={"infoType"}
														label={item.value}
														checked={formData.infoType === item.key}
														value={item.key}
														handleChange={handleChange}
													/>
												);
											})}
										</RowInBlock>
										{formData.infoType === messageConstants.DATA.infoType.SCHOOL && (
											<>
												<RowInBlock>
													<div style={{ width: "40%", marginRight: "10px" }}>
														<Select
															name={"schoolLevel"}
															options={schoolConstants.OPTION.level}
															value={formData.schoolLevel}
															handleChange={handleChange}
														/>
													</div>
													<div style={{ width: "25%", marginRight: "10px" }}>
														<Select
															name={"schoolDistrict"}
															options={schoolConstants.OPTION.district}
															value={formData.schoolDistrict}
															handleChange={handleChange}
														/>
													</div>
													<div style={{ width: "25%" }}>
														<Select
															displayEmpty
															emptyText={"上課時間"}
															name={"schoolSession"}
															options={schoolConstants.OPTION.session}
															value={formData.schoolSession}
															handleChange={handleChange}
														/>
													</div>
												</RowInBlock>
												<div style={{ marginRight: "20px" }}>
													<>
														<AsyncPaginate
															isClearable
															value={selectedSchool}
															loadOptions={loadOptions}
															cacheUniqs={[formData.schoolLevel, formData.schoolDistrict, formData.schoolSession]}
															getOptionValue={(option) => option.schoolName}
															getOptionLabel={(option) => {
																return (
																	<OverviewContainer>
																		{option.vipOnly && (
																			<VipIconView>
																				<VipText>VIP</VipText>
																			</VipIconView>
																		)}
																		{option.schoolName}
																	</OverviewContainer>
																);
															}}
															onChange={handleSelectChange}
															placeholder="請選擇"
															additional={{
																page: DEFAULT_PAGE,
															}}
														/>
													</>
												</div>
											</>
										)}
									</Block>
								)}
								{(formData.type === messageConstants.OPTION.type[1].key || (!_.isEmpty(formData.schoolId) && selectedSchool?.vipOnly)) && (
									<Block>
										<Title> - 發送對象</Title>
										<div style={{ width: "80%" }}>
											<Select
												name={"targetAudience"}
												options={messageConstants.OPTION.targetAudience}
												value={formData.targetAudience}
												handleChange={handleChange}
											/>
										</div>
									</Block>
								)}
								<Block>
									<Title> - 設定訊息發送時間</Title>

									<RowInBlock marginBottom={"0px"}>
										{messageConstants.OPTION.scheduleType.map((item) => {
											return (
												<Checkbox
													key={`${item.key}_checkbox`}
													name={"scheduleType"}
													label={item.value}
													checked={formData.scheduleType === item.key}
													value={item.key}
													handleChange={handleChange}
												/>
											);
										})}
									</RowInBlock>
									{formData.scheduleType === messageConstants.DATA.scheduleType.SCHEDULED && (
										<RowInBlock>
											<MuiPickersUtilsProvider utils={MomentUtils}>
												<KeyboardDatePicker
													margin="dense"
													id="date-picker-scheduleTime"
													format="DD/MM/YYYY"
													inputVariant="outlined"
													value={formData.scheduleTime}
													onChange={(value) => {
														handleChange({ key: "scheduleTime", value });
													}}
													style={{ marginRight: "10px" }}
												/>
												<KeyboardTimePicker
													margin="dense"
													id="time-picker-scheduleTime"
													value={formData.scheduleTime}
													inputVariant="outlined"
													onChange={(value) => {
														handleChange({ key: "scheduleTime", value });
													}}
												/>
											</MuiPickersUtilsProvider>
										</RowInBlock>
									)}
								</Block>
							</ContentLeft>

							<ContentRight>
								<Block marginTop="0">
									<Button style={{ float: "right" }} onClick={handleOnExport}>
										<img src={ExportIcon} alt="" style={{ paddingRight: "5px" }} />
										導出訊息模板
									</Button>
									<Button style={{ float: "right" }} onClick={handleOnImport}>
										<img src={ImportIcon} alt="" style={{ paddingRight: "5px" }} />
										導入訊息模板
									</Button>
									<Title> - 訊息設定</Title>
									<Subtitle>標題</Subtitle>
									<RowInBlock>
										<div style={{ flex: 2, marginRight: "10px" }}>
											<Select
												displayEmpty
												name={"category"}
												options={messageConstants.OPTION.category}
												value={formData.category}
												handleChange={handleChange}
											/>
										</div>
										<div style={{ flex: 7 }}>
											<TextField placeholder="標題" value={formData.title} name="title" handleChange={handleChange} />
										</div>
									</RowInBlock>
									<Subtitle>內容</Subtitle>

									<TextField placeholder="內容" multiline rows={6} value={formData.detail} name="detail" handleChange={handleChange} />
								</Block>
								<Block>
									<Button
										variant="text"
										startIcon={addDateTimeIsClose ? <Add /> : <Remove />}
										onClick={() => {
											setAddDateTimeIsClose(!addDateTimeIsClose);
										}}
									>
										新增日期及時間
									</Button>
								</Block>
								{!addDateTimeIsClose && (
									<Block style={{ marginTop: "10px", marginLeft: "30px" }}>
										<Checkbox
											key={`fullDay_checkbox`}
											name={"fullDay"}
											label={"全日"}
											checked={formData.fullDay}
											handleChange={handleChange}
										/>
										<Subtitle marginBottom={"0px"}>開始日期</Subtitle>
										<RowInBlock>
											<MuiPickersUtilsProvider utils={MomentUtils}>
												<KeyboardDatePicker
													margin="dense"
													id="date-picker-startTime"
													format="DD/MM/YYYY"
													value={formData.startTime}
													inputVariant="outlined"
													onChange={(value) => {
														handleChange({ key: "startTime", value });
													}}
													style={{ marginRight: "10px" }}
												/>
												<KeyboardTimePicker
													disabled={formData.fullDay}
													style={formData.fullDay ? { display: "none" } : null}
													margin="dense"
													id="time-picker-startTime"
													value={formData.startTime}
													inputVariant="outlined"
													onChange={(value) => {
														handleChange({ key: "startTime", value });
													}}
												/>
											</MuiPickersUtilsProvider>
										</RowInBlock>
										<Subtitle marginBottom={"0px"}>結束日期</Subtitle>
										<RowInBlock>
											<MuiPickersUtilsProvider utils={MomentUtils}>
												<KeyboardDatePicker
													// disabled={formData.fullDay}
													margin="dense"
													id="date-picker-endTime"
													format="DD/MM/YYYY"
													value={formData.endTime}
													inputVariant="outlined"
													onChange={(value) => {
														handleChange({ key: "endTime", value });
													}}
													style={{ marginRight: "10px" }}
												/>
												<KeyboardTimePicker
													disabled={formData.fullDay}
													style={formData.fullDay ? { display: "none" } : null}
													margin="dense"
													id="time-picker-endTime"
													value={formData.endTime}
													inputVariant="outlined"
													onChange={(value) => {
														handleChange({ key: "endTime", value });
													}}
												/>
											</MuiPickersUtilsProvider>
										</RowInBlock>
									</Block>
								)}
								<Block marginTop={"10px"}>
									<Button
										variant="text"
										startIcon={addURLIsClose ? <Add /> : <Remove />}
										onClick={() => {
											setAddURLIsClose(!addURLIsClose);
										}}
									>
										新增連結
									</Button>
								</Block>
								{!addURLIsClose && (
									<Block style={{ marginTop: "10px", marginLeft: "30px" }}>
										<TextField placeholder="連結" value={formData.url} name="url" handleChange={handleChange} />
									</Block>
								)}
							</ContentRight>
						</RowInBlock>
						<RowInBlock marginTop={"10px"} style={{ justifyContent: "flex-end" }}>
							<CancelButton label={"取消"} style={{ marginRight: "15px" }} handleOnClick={handleBack} />
							<SubmitButton label={"確認"} handleOnClick={handleSubmit} />
						</RowInBlock>
					</ContentContainer>
					<AdminFooter></AdminFooter>
				</RightContainer>
			</PageContainer>
		</>
	);
};

NewMsgPage.propTypes = {
	location: PropTypes.object,
};

const ContentLeft = styled.div`
	flex: 0 0 400px;
	padding-right: 20px;
	border-right: 1px solid #dddddd;
`;

const ContentRight = styled.div`
	flex: 1;
	padding-left: 20px;
`;

const Block = styled.div`
	margin-top: ${(props) => props.marginTop || "40px"};
	flex-direction: column;
`;

const RowInBlock = styled.div`
	display: flex;
	margin-bottom: ${(props) => props.marginBottom || "10px"};
	flex-direction: row;
`;

const Title = styled.div`
	font-weight: bold;
	margin: 0 0 12px 4px;
	font-size: 14px;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.43;
	letter-spacing: normal;
	text-align: left;
	color: #333399;
`;

const Subtitle = styled.div`
	color: black;
	margin-bottom: ${(props) => props.marginBottom || "10px"};
	font-size: 14px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.43;
	letter-spacing: normal;
`;

const TextFieldLabel = styled.div`
	color: black;
	font-size: 13px;
	line-height: 1.5;
	font-weight: bold;
	margin-bottom: 40px;
`;

const OverviewContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
`;

const VipIconView = styled.div`
	display: flex;
	width: 32px;
	flex-direction: row;
	margin-right: 5px;
`;

const VipText = styled.div`
	font-size: 12;
	font-weight: bold;
	color: #333333;
	background-color: #c8ae63;
	padding-left: 5px;
	padding-right: 5px;
`;
