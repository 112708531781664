export const generalConstants = {
	BUTTON_TYPE: {
		SUBMIT: "SUBMIT",
		WARNING: "WARNING",
		IMPORT: "IMPORT",
	},
	NAV_TAB: {
		DASHBOARD: { key: "DASHBOARD", show: true },
		CHANGE_PASSWORD: { key: "CHANGE_PASSWORD", show: false },

		MSG_MANAGEMENT: { key: "MSG_MANAGEMENT", show: true },
		NEW_MSG: { key: "NEW_MSG", show: false },
		EDIT_MSG: { key: "EDIT_MSG", show: false },
		READ_MSG: { key: "READ_MSG", show: false },
		IMPORT_MSG: { key: "IMPORT_MSG", show: false },
		MSG_CONFIRM: { key: "MSG_CONFIRM", show: false },

		ADMIN_MANAGEMENT: { key: "ADMIN_MANAGEMENT", show: true },
		CREATE_ADMIN: { key: "CREATE_ADMIN", show: false },
		EDIT_ADMIN: { key: "EDIT_ADMIN", show: false },

		SCHOOL_MANAGEMENT: { key: "SCHOOL_MANAGEMENT", show: true },
		SCHOOL_DETAIL: { key: "SCHOOL_DETAIL", show: false },
		EDIT_SCHOOL: { key: "EDIT_SCHOOL", show: false },
		CREATE_SCHOOL: { key: "CREATE_SCHOOL", show: false },

		ORGANIZATION_MANAGEMENT: { key: "ORGANIZATION_MANAGEMENT", show: true },
		EDIT_ORGANIZATION: { key: "EDIT_ORGANIZATION", show: false },

		LEARNING_CENTRE_MANAGEMENT: { key: "LEARNING_CENTRE_MANAGEMENT", show: true },
		LEARNING_CENTRE_DETAIL: { key: "LEARNING_CENTRE_DETAIL", show: false },
		EDIT_LEARNING_CENTRE: { key: "EDIT_LEARNING_CENTRE", show: false },
		CREATE_LEARNING_CENTRE: { key: "CREATE_LEARNING_CENTRE", show: false },

		LEARNING_COURSE_MANAGEMENT: { key: "LEARNING_COURSE_MANAGEMENT", show: true },
		LEARNING_COURSE_DETAIL: { key: "LEARNING_COURSE_DETAIL", show: false },
		EDIT_LEARNING_COURSE: { key: "EDIT_LEARNING_COURSE", show: false },
		CREATE_LEARNING_COURSE: { key: "CREATE_LEARNING_COURSE", show: false },

		REPORT_MANAGEMENT: { key: "REPORT_MANAGEMENT", show: true },
		REPORT_DETAIL: { key: "REPORT_DETAIL", show: false },

		POST_MANAGEMENT: { key: "POST_MANAGEMENT", show: true },
		POST_DETAIL: { key: "POST_DETAIL", show: false },

		PARENT_MANAGEMENT: { key: "PARENT_MANAGEMENT", show: true },
		EDIT_PARENT: { key: "EDIT_PARENT", show: false },
		MISSION_HISTORY: { key: "MISSION_HISTORY", show: false },

		ALL_DIFF: { key: "ALL_DIFF", show: false },
		DIFF_DETAIL: { key: "DIFF_DETAIL", show: false },

		DATA_SCRAP: { key: "DATA_SCRAP", show: true },
		DATA_SCRAP_DETAIL: { key: "DATA_SCRAP_DETAIL", show: false },

		MISSION_MANAGEMENT: { key: "MISSION_MANAGEMENT", show: true },
		MISSION_DETAILS: { key: "MISSION_DETAILS", show: false },
		CREATE_MISSION: { key: "CREATE_MISSION", show: false },
		EDIT_MISSION: { key: "EDIT_MISSION", show: false },
	},
	TAB_NAME: {
		CHANGE_PASSWORD: "更改密碼",
		DASHBOARD: "概覽",
		MSG_MANAGEMENT: "訊息管理",
		NEW_MSG: "發送訊息",
		EDIT_MSG: "編輯訊息",
		READ_MSG: "導入訊息",
		IMPORT_MSG: "導入文件確認",
		MSG_CONFIRM: "確認訊息",

		ADMIN_MANAGEMENT: "管理員管理",
		CREATE_ADMIN: "新增管理員",
		EDIT_ADMIN: "編輯管理員",

		SCHOOL_MANAGEMENT: "學校管理",
		SCHOOL_DETAIL: "學校資訊",
		EDIT_SCHOOL: "編輯學校",
		CREATE_SCHOOL: "新增學校",

		ORGANIZATION_MANAGEMENT: "機構管理",
		EDIT_ORGANIZATION: "編輯機構",

		LEARNING_CENTRE_MANAGEMENT: "補習社管理",
		EDIT_LEARNING_CENTRE: "編輯補習社",
		LEARNING_CENTRE_DETAIL: "補習社資料",
		CREATE_LEARNING_CENTRE: "新增補習社",
		LEARNING_COURSE_MANAGEMENT: "課程管理",
		LEARNING_COURSE_DETAIL: "課程管理",
		EDIT_LEARNING_COURSE: "編輯課程",
		CREATE_LEARNING_COURSE: "新增課程",

		REPORT_MANAGEMENT: "舉報管理",
		REPORT_DETAIL: "舉報詳情",

		POST_MANAGEMENT: "帖子管理",
		POST_DETAIL: "帖子詳情",

		PARENT_MANAGEMENT: "家長管理",
		EDIT_PARENT: "編輯家長",
		MISSION_HISTORY: "交易記錄",

		ABOUT_US: "關於亞網",
		CUSTOMER_SERVICE: "客戶服務",
		FAQ: "常見問題",
		TERMS_CONDITION_PRIVACY: "條款及細則和私隱政策",
		CONTACT_US: "聯繫我們",

		ALL_DIFF: "全部網頁更新",
		DIFF_DETAIL: "網頁更新詳情",

		DATA_SCRAP: "數據抓取",
		DATA_SCRAP_DETAIL: "抓取變化詳情",

		MISSION_MANAGEMENT: "任務管理",
		CREATE_MISSION: "新增任務",
		EDIT_MISSION: "編輯任務",
		MISSION_DETAILS: "任務詳情",
	},
	FOOTER_TAB: {
		ABOUT_US: { key: "ABOUT_US", show: true },
		CUSTOMER_SERVICE: { key: "CUSTOMER_SERVICE", show: true },
		FAQ: { key: "FAQ", show: true },
		TERMS_CONDITION_PRIVACY: { key: "TERMS_CONDITION_PRIVACY", show: true },
		CONTACT_US: { key: "CONTACT_US", show: true },
	},
	PATH: {
		LOGIN: "/",
		FORGET_PASSWORD: "/forgetPassword",
		CHANGE_PASSWORD: "/changePassword",

		DASHBOARD: "/dashboard",

		MSG_MANAGEMENT: "/msgManagement",
		NEW_MSG: "/newmsg",
		EDIT_MSG: "/message",
		READ_MSG: "/readmsg",
		IMPORT_MSG: "/importmsg",
		MSG_CONFIRM: "/msgconfirm",

		ADMIN_MANAGEMENT: "/adminManagement",
		CREATE_ADMIN: "/createAdmin",
		ADMIN_INFO: "/info",

		SCHOOL_MANAGEMENT: "/schoolManagement",
		SCHOOL_DETAIL: "/school",
		EDIT_SCHOOL: "/school/edit",
		CREATE_SCHOOL: "/newschool",

		ORGANIZATION: "/organization",
		LEARNING_CENTER: "/learningCenter",
		LEARNING_COURSE: "/course",
		LEARNING_CENTRE_MANAGEMENT: "/learningCentreManagement",
		LEARNING_COURSE_MANAGEMENT: "/courseManagement",
		ORGANIZATION_MANAGEMENT: "/organizationManagement",

		REPORT_MANAGEMENT: "/reportManagement",
		REPORT_TARGET: "/report",

		POST_MANAGEMENT: "/postManagement",
		POST_DETAIL: "/post",

		PARENT_MANAGEMENT: "/parentManagement",
		EDIT_PARENT: "/parent",
		MISSION_HISTORY: "/history",

		DATA_SCRAP: "/dataScrap",
		DATA_SCRAP_DETAIL: "/dataScrapDetail",

		MISSION: "/mission",
		MISSION_MANAGEMENT: "/missionManagement",

		ABOUT_US: "/about_us",
		CUSTOMER_SERVICE: "/cs",
		FAQ: "https://kidsontrack.hk/doc/Privacy_Policy.html",
		TERMS_CONDITION_PRIVACY: "https://kidsontrack.hk/doc/Terms_and_Conditions.html",
		CONTACT_US: "mailto:cs@squarebox.com.hk",
	},
};
