import React, { useState, useEffect } from "react";
import { Prompt } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { adminConstants, generalConstants } from "_constants";
import { history } from "_util/_helper";
import { TextField, SubmitButton, CancelButton, Loading } from "components/general";
import { toast } from "react-toastify";
import { adminAction } from "../../actions/admin.action";

import { AdminMenubar, AdminUserInfo, AdminSectionbar, AdminFooter } from "components/admin";
import { PageContainer, LeftContainer, RightContainer, ContentContainer } from "components/layout";

export const CreateAdminPage = () => {
	const { isFetching, loggedIn, createAdminSuccess } = useSelector(({ admin, auth }) => ({
		isFetching: admin.isFetching,
		loggedIn: auth.loggedIn,
		createAdminSuccess: admin.createAdminSuccess,
	}));

	const initForm = {
		email: "",
		username: "",
		fullName: "",
	};

	const [formData, setFormData] = useState(initForm);
	const [isLoading, setIsLoading] = useState(false);
	const [isSaved, setIsSaved] = useState(false);
	const dispatch = useDispatch();

	const handleChange = ({ key, value }) => {
		setFormData({ ...formData, [key]: value });
	};

	const handleBack = () => {
		history.push(generalConstants.PATH.ADMIN_MANAGEMENT);
	};

	useEffect(() => {
		setIsLoading(isFetching);
	}, [isFetching]);

	const handleSubmit = () => {
		if (!formData.email) {
			toast.error("請填寫電郵");
			return;
		}
		if (!formData.username) {
			toast.error("請填寫用户名稱");
			return;
		}

		if (!formData.fullName) {
			toast.error("請填寫全名");
			return;
		}

		dispatch(adminAction.createAdmin(formData));
	};

	useEffect(() => {
		if (createAdminSuccess) {
			if (!isSaved) {
				setIsSaved(true);
				return;
			}
			dispatch({ type: adminConstants.type.RESET_ADMIN_SUCCESS });
			toast.success("已成功新增管理員");
			history.replace(generalConstants.PATH.ADMIN_MANAGEMENT);
		}
	}, [createAdminSuccess, isSaved, dispatch]);

	return (
		<>
			{!!isLoading && <Loading />}
			<Prompt when={!isSaved && loggedIn} message="你有未儲存的變更，確定不儲存下離開？" />
			<PageContainer>
				<LeftContainer>
					<AdminMenubar section={generalConstants.NAV_TAB.ADMIN_MANAGEMENT.key} />
				</LeftContainer>
				<RightContainer>
					<AdminUserInfo />
					<AdminSectionbar
						title={generalConstants.NAV_TAB.CREATE_ADMIN.key}
						locationPathArray={[
							{ title: "主頁面", isAction: true, target: generalConstants.PATH.DASHBOARD },
							{
								title: generalConstants.TAB_NAME[generalConstants.NAV_TAB.ADMIN_MANAGEMENT.key],
								isAction: true,
								target: generalConstants.PATH.ADMIN_MANAGEMENT,
							},
							{
								title: generalConstants.TAB_NAME[generalConstants.NAV_TAB.CREATE_ADMIN.key],
								isAction: false,
								target: "",
							},
						]}
					/>
					<ContentContainer>
						<RowInBlock>
							<ContentLeft>
								<Block style={{ width: "90%" }} marginTop={"10px"}>
									<TextFieldLabel>電郵</TextFieldLabel>
									<TextField
										placeholder="電郵"
										style={{ backgroundColor: "white" }}
										value={formData.email}
										name="email"
										handleChange={handleChange}
									/>
								</Block>
								<Block style={{ width: "90%" }}>
									<TextFieldLabel>用户名稱</TextFieldLabel>
									<TextField
										placeholder="用户名稱"
										style={{ backgroundColor: "white" }}
										value={formData.username}
										name="username"
										handleChange={handleChange}
									/>
								</Block>
								<Block style={{ width: "90%" }}>
									<TextFieldLabel>全名</TextFieldLabel>
									<TextField
										placeholder="全名"
										style={{ backgroundColor: "white" }}
										value={formData.fullName}
										name="fullName"
										handleChange={handleChange}
									/>
								</Block>
							</ContentLeft>

							<ContentRight></ContentRight>
						</RowInBlock>
						<RowInBlock marginTop={"10px"} style={{ justifyContent: "flex-end" }}>
							<CancelButton label={"取消"} style={{ marginRight: "15px" }} handleOnClick={handleBack} />
							<SubmitButton active={!isFetching} label={"確認"} handleOnClick={handleSubmit} />
						</RowInBlock>
					</ContentContainer>
					<AdminFooter></AdminFooter>
				</RightContainer>
			</PageContainer>
		</>
	);
};

const ContentLeft = styled.div`
	flex: 0 0 400px;
	border-right: 1px solid #dddddd;
	min-height: 040px;
`;

const ContentRight = styled.div`
	flex: 1;
	padding-left: 20px;
`;

const Block = styled.div`
	margin-top: ${(props) => props.marginTop || "40px"};
	flex-direction: column;
`;

const RowInBlock = styled.div`
	display: flex;
	margin-bottom: ${(props) => props.marginBottom || "10px"};
	flex-direction: row;
`;

const TextFieldLabel = styled.div`
	color: black;
	font-size: 13px;
	line-height: 1.5;
	font-weight: bold;
	margin-bottom: 10px;
`;
