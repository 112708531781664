import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import { tokenAction, adminAction } from "actions";
import { toast } from "react-toastify";

import { tokenConstants } from "_constants";

import backgroundImage from "resources/img/admin_loginbg.jpg";
import { SimplePopupMessage, Loading } from "components/general";
import { AdminSetPasswordForm } from "components/auth";
import ProgressiveRejectDebouncer from "_util/debouncers/ProgressiveRejectDebouncer";

export const VerifyPage = () => {
	const { token } = useParams();

	const { message, action, isAdminFetching, isTokenFetching } = useSelector(({ token, admin }) => ({
		message: token.message,
		action: token.action,
		getTokenInfoSuccess: token.getTokenInfoSuccess,
		isAdminFetching: admin.isFetching,
		isTokenFetching: token.isFetching,
	}));

	const dispatch = useDispatch();
	const [debouncer] = useState(() => new ProgressiveRejectDebouncer(2 * 1000));

	useEffect(() => {
		dispatch(tokenAction.getTokenInfo(token));
	}, []);

	const handleOnBack = () => {
		window.location.href = "/";
	};

	const handleSetPassword = (password) => {
		if (!debouncer.token()) {
			toast.error(`請稍後再試`);
			return;
		}
		dispatch(adminAction.setPassword({ token, password }));
	};

	const renderForm = () => {
		switch (action) {
			case tokenConstants.action.ADMIN_REGISTRATION:
				return <AdminSetPasswordForm message={message} handleSetPassword={handleSetPassword} handleOnBack={handleOnBack} />;

			case tokenConstants.action.ADMIN_RESET_PASSWORD:
				return <AdminSetPasswordForm message={message} handleSetPassword={handleSetPassword} handleOnBack={handleOnBack} />;

			default:
				return <div>Wrong Action</div>;
		}
	};

	const renderByAction = renderForm();

	return (
		<Container>
			{(isTokenFetching || isAdminFetching) && <Loading />}
			{action ? renderByAction : <SimplePopupMessage message={message} handleOnClick={handleOnBack} />}
			<BackgroundImage />
		</Container>
	);
};

const Container = styled.div`
	height: 100vh;
	width: 100vw;
`;

const BackgroundImage = styled.div`
	height: 100%;
	width: 100%;
	position: fixed;
	background-repeat: no-repeat;
	background-size: cover;
	background-image: url(${backgroundImage});
`;
