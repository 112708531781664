import { authConstants, parentConstants } from "_constants";

const initialState = {
	isFetching: false,
	submitParentSuccess: false,
	parent: {},
	parentRankList: [],
	navigationObject: {
		root: {
			sortingKey: "createdDate",
			sortingDirection: "DESC",
			searchKey: parentConstants.TABLE_COLUMN[2].key,
			searchWord: "",
			numOfPage: 1,
			currentPage: 1,
			totalItems: 0,
			parentList: [],
			notJoinStatus: false,
			unverifiedStatus: false,
			verifiedStatus: false,
		},
	},
};

const parentReducer = (state = initialState, action) => {
	switch (action.type) {
		case authConstants.type.UNAUTHORIZED_ERROR: {
			let navigationObject = initialState.navigationObject;
			if (typeof state.navigationObject?.root != "undefined") {
				navigationObject = {
					...state.navigationObject,
					root: { ...state.navigationObject?.root, currentPage: 1 },
				};
			}
			return {
				...state,
				navigationObject: navigationObject,
			};
		}
		case parentConstants.type.SUBMIT_PARENT_REQUEST: {
			return {
				...state,
				submitParentSuccess: false,
			};
		}
		case parentConstants.type.SUBMIT_PARENT_FAILURE: {
			return {
				...state,
				submitParentSuccess: false,
			};
		}
		case parentConstants.type.SUBMIT_PARENT_SUCCESS: {
			return {
				...state,
				submitParentSuccess: true,
			};
		}

		case parentConstants.type.FETCH_PARENT_LIST_REQUEST: {
			return {
				...state,
				isFetching: true,
			};
		}
		case parentConstants.type.FETCH_PARENT_LIST_FAILURE: {
			return {
				...state,
				isFetching: false,
			};
		}
		case parentConstants.type.FETCH_PARENT_LIST_SUCCESS: {
			return {
				...state,
				isFetching: false,
				navigationObject: {
					root: {
						sortingKey: action.sortingKey,
						sortingDirection: action.sortingDirection,
						searchKey: action.searchKey,
						searchWord: action.searchWord,
						parentList: action.parentList,
						totalItems: action.totalNum,
						numOfPage: action.totalPage,
						currentPage: action.currentPage,
						notJoinStatus: action.notJoinStatus,
						unverifiedStatus: action.unverifiedStatus,
						verifiedStatus: action.verifiedStatus,
					},
				},
			};
		}
		case parentConstants.type.FETCH_PARENT_LIST_FOR_MISSION_REQUEST: {
			return {
				...state,
				isFetching: true,
			};
		}
		case parentConstants.type.FETCH_PARENT_LIST_FOR_MISSION_FAILURE: {
			return {
				...state,
				isFetching: false,
			};
		}
		case parentConstants.type.FETCH_PARENT_LIST_FOR_MISSION_SUCCESS: {
			return {
				...state,
				isFetching: false,
				parentRankList: action.parentList,
			};
		}
		case parentConstants.type.FETCH_PARENT_REQUEST: {
			return {
				...state,
				isFetching: true,
				parent: {},
			};
		}
		case parentConstants.type.FETCH_PARENT_FAILURE: {
			return {
				...state,
				isFetching: false,
			};
		}
		case parentConstants.type.FETCH_PARENT_SUCCESS: {
			return {
				...state,
				isFetching: false,
				parent: action.parent,
			};
		}
		case parentConstants.type.RESET_PARENT_SUCCESS: {
			return {
				...state,
				submitParentSuccess: false,
			};
		}

		default: {
			return state;
		}
	}
};

export default parentReducer;
