import { tokenConstants } from "_constants";
import { tokenService } from "services";
import { toast } from "react-toastify";

export const tokenAction = {
	getTokenInfo,
};

function getTokenInfo(token) {
	return (dispatch) => {
		dispatch(request());
		tokenService.getTokenInfo(token).then(
			(response) => {
				dispatch(success(response));
				dispatch(reset());
			},
			(error) => {
				toast.error(error.error);
				dispatch(failure());
			}
		);
	};

	function request() {
		return { type: tokenConstants.type.GET_TOKEN_INFO_REQUEST };
	}
	function failure() {
		return { type: tokenConstants.type.GET_TOKEN_INFO_FAILURE };
	}
	function success(response) {
		return {
			type: tokenConstants.type.GET_TOKEN_INFO_SUCCESS,
			message: response.message ? response.message : "",
			action: response.action ? response.action : "",
		};
	}
	function reset() {
		return { type: tokenConstants.type.RESET_TOKEN_INFO_SUCCESS };
	}
}
