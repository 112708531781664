import axios from "axios";

export const schoolService = {
	getSchoolList,
	getSchoolByKey,
	createSchool,
	updateSchool,
};

const httpOptions = {
	headers: {
		"Content-Type": "application/json",
		"Access-Control-Allow-Origin": "*",
	},
	withCredentials: true,
};

function createFormData(data) {
	let formData = new FormData();

	for (let key in data) {
		let value = data[key];
		if (Array.isArray(value)) {
			value = JSON.stringify(value);
		}
		formData.append(key, value);
	}
	return formData;
}

async function getSchoolList(form) {
	return axios({
		url: `${process.env.REACT_APP_BACKEND_URL}/school`,
		...httpOptions,
		method: "GET",
		params: form,
	})
		.then(handleResponse)
		.catch(handleError);
}

async function getSchoolByKey(key, data) {
	return axios({
		url: `${process.env.REACT_APP_BACKEND_URL}/school/${key}`,
		...httpOptions,
		method: "GET",
		params: data,
	})
		.then(handleResponse)
		.catch(handleError);
}

async function createSchool(data) {
	const formData = createFormData(data);
	return axios({
		url: `${process.env.REACT_APP_BACKEND_URL}/school`,
		...httpOptions,
		headers: {
			...httpOptions.headers,
			enctype: "multipart/form-data",
		},
		data: formData,
		method: "POST",
	})
		.then(handleResponse)
		.catch(handleError);
}

async function updateSchool(data) {
	const formData = createFormData(data);
	return axios({
		url: `${process.env.REACT_APP_BACKEND_URL}/school`,
		...httpOptions,
		headers: {
			...httpOptions.headers,
			enctype: "multipart/form-data",
		},
		data: formData,
		method: "PUT",
	})
		.then(handleResponse)
		.catch(handleError);
}

function handleError(err) {
	const msg = (err.response && err.response.data.message) || err.message || err;
	throw { error: msg };
}

function handleResponse(res) {
	return res.data;
}
