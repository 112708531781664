export const AvatarHelper = (src) => {
	if (src) {
		if (src.startsWith("http")) {
			return src;
		} else {
			return `${process.env.REACT_APP_BACKEND_URL}/socialNetwork/profilepic/${src}`;
		}
	}
	return null;
};
