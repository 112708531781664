import axios from "axios";

export const coinService = {
	getTransactionHistory,
};

const httpOptions = {
	headers: {
		"Content-Type": "application/json",
	},
	withCredentials: true,
};

async function getTransactionHistory(data) {
	const formData = data ? { ...data } : undefined;
	return axios({
		url: `${process.env.REACT_APP_BACKEND_URL}/coin/history`,
		...httpOptions,
		method: "GET",
		params: formData,
	})
		.then(handleResponse)
		.catch(handleError);
}

function handleError(err) {
	const msg = (err.response && err.response.data.message) || err.message || err;
	throw { error: msg };
}

function handleResponse(res) {
	return res.data;
}
