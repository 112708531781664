import { templateConstants, generalConstants } from "_constants";
import { templateService } from "services";
import { toast } from "react-toastify";
import { history } from "_util/_helper";

export const templateAction = {
	fetchTemplate,
	getTemplateByKey,
	createTemplate,
	updateTemplate,
	deleteTemplate,
};

function fetchTemplate(form) {
	return (dispatch) => {
		templateService.fetchTemplate(form).then(
			(response) => {
				dispatch(success(response));
			},
			(error) => {
				toast.error(error.error);
			}
		);
	};
	function success(response) {
		const { totalItems, items } = response;
		return {
			type: templateConstants.type.FETCH_TEMPLATE_LIST_SUCCESS,
			totalNum: totalItems,
			totalPage: Math.ceil(totalItems / form.limit),
			currentPage: form.page,
			templateList: items,
		};
	}
}

function getTemplateByKey(key) {
	return (dispatch) => {
		dispatch(request());
		templateService.getTemplateByKey(key).then(
			(response) => {
				dispatch(success(response));
			},
			(error) => {
				toast.error(error.error);
				if (error.error?.status !== 401) {
					history.push(generalConstants.PATH.TEMPLATE_MANAGEMENT);
				}
			}
		);
	};
	function request() {
		return { type: templateConstants.type.FETCH_TEMPLATE_REQUEST };
	}
	function success(template) {
		return {
			type: templateConstants.type.FETCH_TEMPLATE_SUCCESS,
			template,
		};
	}
}

function createTemplate(data) {
	return (dispatch) => {
		dispatch(request());
		templateService.createTemplate(data).then(
			() => {
				dispatch(success());
			},
			(error) => {
				toast.error(error.error);
				dispatch(failure());
			}
		);
	};

	function request() {
		return { type: templateConstants.type.SUBMIT_TEMPLATE_REQUEST };
	}
	function failure() {
		return { type: templateConstants.type.SUBMIT_TEMPLATE_FAILURE };
	}
	function success() {
		return { type: templateConstants.type.SUBMIT_TEMPLATE_SUCCESS };
	}
}

function updateTemplate(data) {
	return (dispatch) => {
		dispatch(request());
		templateService.updateTemplate(data).then(
			() => {
				dispatch(success());
			},
			(error) => {
				toast.error(error.error);
				dispatch(failure());
			}
		);
	};

	function request() {
		return { type: templateConstants.type.UPDATE_TEMPLATE_REQUEST };
	}
	function failure() {
		return { type: templateConstants.type.UPDATE_TEMPLATE_FAILURE };
	}
	function success() {
		return { type: templateConstants.type.UPDATE_TEMPLATE_SUCCESS };
	}
}

function deleteTemplate(data) {
	return (dispatch) => {
		dispatch(request());
		templateService.deleteTemplate(data).then(
			() => {
				dispatch(success());
			},
			(error) => {
				toast.error(error.error);
				dispatch(failure());
			}
		);
	};

	function request() {
		return { type: templateConstants.type.DELETE_TEMPLATE_REQUEST };
	}
	function failure() {
		return { type: templateConstants.type.DELETE_TEMPLATE_FAILURE };
	}
	function success() {
		return { type: templateConstants.type.DELETE_TEMPLATE_SUCCESS };
	}
}
