import { tokenConstants } from "_constants";

const initialState = {
	getTokenInfoSuccess: false,
	message: "",
	action: "",
};

const tokenReducer = (state = initialState, action) => {
	switch (action.type) {
		case tokenConstants.type.GET_TOKEN_INFO_REQUEST: {
			return {
				...state,
				message: "",
				action: "",
			};
		}
		case tokenConstants.type.GET_TOKEN_INFO_FAILURE: {
			return {
				...state,
				getTokenInfoSuccess: false,
			};
		}
		case tokenConstants.type.GET_TOKEN_INFO_SUCCESS: {
			return {
				...state,
				getTokenInfoSuccess: true,
				message: action.message,
				action: action.action,
			};
		}
		case tokenConstants.type.RESET_TOKEN_INFO_SUCCESS: {
			return {
				...state,
				getTokenInfoSuccess: false,
			};
		}
		default: {
			return state;
		}
	}
};

export default tokenReducer;
