import { diffConstants } from "_constants";
import { diffService } from "services";
import { toast } from "react-toastify";

export const diffAction = {
	updateDiff,
	getDataScrapList,
	getDataScrapDetail,
	getDifferencesByWebsiteId,
	getDataScrapDifferenceDetail,
	updateAllSelectedDiffs,
	updateAllSelectedWebsites,
	addDataScrapWebsite,
	updateDataScrapWebsite,
	deleteDataScrapWebsite,
	compareDiffs,
	genAllDiff,
	getAllLog,
	getAllError,
};

function updateDiff(form) {
	return (dispatch) => {
		dispatch(request());
		diffService.updateDiff(form).then(
			(response) => {
				dispatch(success(response));
			},
			(error) => {
				toast.error(error.error);
				dispatch(failure());
			}
		);
	};
	function request() {
		return { type: diffConstants.type.UPDATE_DIFF_REQUEST };
	}
	function failure() {
		return { type: diffConstants.type.UPDATE_DIFF_FAILURE };
	}
	function success(response) {
		const { diff, updateResult, updatedWebsiteInfo } = response;
		return { type: diffConstants.type.UPDATE_DIFF_SUCCESS, diff, updateResult, updatedWebsiteInfo };
	}
}

function getDataScrapList(form, navigationKey) {
	return (dispatch) => {
		dispatch(request());
		diffService.getDataScrapList(form).then(
			(response) => {
				dispatch(success(response));
			},
			(error) => {
				toast.error(error.error);
				dispatch(failure());
			}
		);
	};

	function request() {
		return { type: diffConstants.type.DATA_SCRAP_REQUEST };
	}
	function failure() {
		return { type: diffConstants.type.DATA_SCRAP_FAILURE };
	}
	function success(response) {
		const { totalItems, items } = response;
		let result = {
			type: diffConstants.type.DATA_SCRAP_SUCCESS,
			navigationKey,
			dataScrapList: items,
			totalNum: totalItems,
			totalPage: Math.ceil(totalItems / form.limit),
			currentPage: form.page,
		};
		let sortingKey = "lastUpdateTime",
			sortingDirection = "DESC";
		const sortingArray = form?.sort?.split(":");
		if (sortingArray && sortingArray.length === 2 && sortingArray[0] && sortingArray[1]) {
			sortingKey = sortingArray[0];
			sortingDirection = sortingArray[1] === "DESC" ? "DESC" : "ASC";
			result = { ...result, sortingKey, sortingDirection };
		}
		if (form.level) {
			result = { ...result, level: form.level };
		}
		if (form.district) {
			result = { ...result, district: form.district };
		}
		if (form.gender) {
			result = { ...result, gender: form.gender };
		}
		if (form.schoolNet) {
			result = { ...result, schoolNet: form.schoolNet };
		}
		if (form.name) {
			result = { ...result, name: form.name };
		}
		return result;
	}
}

function getDataScrapDetail(key) {
	return (dispatch) => {
		dispatch(request());
		diffService.getDataScrapDetail(key).then(
			(response) => {
				dispatch(success(response));
			},
			(error) => {
				toast.error(error.error);
				dispatch(failure());
			}
		);
	};

	function request() {
		return { type: diffConstants.type.DATA_SCRAP_DETAIL_REQUEST };
	}
	function failure() {
		return { type: diffConstants.type.DATA_SCRAP_DETAIL_FAILURE };
	}
	function success(response) {
		const { websites, schoolName } = response;
		return {
			type: diffConstants.type.DATA_SCRAP_DETAIL_SUCCESS,
			websites,
			schoolName,
		};
	}
}

function getDifferencesByWebsiteId(key) {
	return (dispatch) => {
		dispatch(request());
		diffService.getDifferencesByWebsiteId(key).then(
			(response) => {
				dispatch(success(response));
			},
			(error) => {
				toast.error(error.error);
				dispatch(failure());
			}
		);
	};

	function request() {
		return { type: diffConstants.type.DATA_SCRAP_GET_DIFFERENCES_BY_WEBSITE_ID_REQUEST };
	}
	function failure() {
		return { type: diffConstants.type.DATA_SCRAP_GET_DIFFERENCES_BY_WEBSITE_ID_FAILURE };
	}
	function success(response) {
		const { linkDifferences } = response;
		return {
			type: diffConstants.type.DATA_SCRAP_GET_DIFFERENCES_BY_WEBSITE_ID_SUCCESS,
			linkDifferences,
		};
	}
}

function getDataScrapDifferenceDetail(key) {
	return (dispatch) => {
		dispatch(request());
		diffService.getDataScrapDifferenceDetail(key).then(
			(response) => {
				dispatch(success(response));
			},
			(error) => {
				toast.error(error.error);
				dispatch(failure());
			}
		);
	};

	function request() {
		return { type: diffConstants.type.DATA_SCRAP_GET_DIFFERENCE_DETAIL_REQUEST };
	}
	function failure() {
		return { type: diffConstants.type.DATA_SCRAP_GET_DIFFERENCE_DETAIL_FAILURE };
	}
	function success(response) {
		return {
			type: diffConstants.type.DATA_SCRAP_GET_DIFFERENCE_DETAIL_SUCCESS,
			differenceDetail: response,
		};
	}
}

function updateAllSelectedDiffs(form) {
	return (dispatch) => {
		dispatch(request());
		diffService.updateAllSelectedDiffs(form).then(
			(response) => {
				dispatch(success(response));
			},
			(error) => {
				toast.error(error.error);
				dispatch(failure());
			}
		);
	};

	function request() {
		return { type: diffConstants.type.DATA_SCRAP_UPDATE_DIFFS_REQUEST };
	}
	function failure() {
		return { type: diffConstants.type.DATA_SCRAP_UPDATE_DIFFS_FAILURE };
	}
	function success(response) {
		const { linkDifferences, updatedWebsiteInfo } = response;
		return {
			type: diffConstants.type.DATA_SCRAP_UPDATE_DIFFS_SUCCESS,
			linkDifferences,
			updatedWebsiteInfo,
		};
	}
}

function updateAllSelectedWebsites(form) {
	return (dispatch) => {
		dispatch(request());
		diffService.updateAllSelectedWebsites(form).then(
			(response) => {
				dispatch(success(response));
			},
			(error) => {
				toast.error(error.error);
				dispatch(failure());
			}
		);
	};

	function request() {
		return { type: diffConstants.type.DATA_SCRAP_UPDATE_WEBSITES_REQUEST };
	}
	function failure() {
		return { type: diffConstants.type.DATA_SCRAP_UPDATE_WEBSITES_FAILURE };
	}
	function success(response) {
		const { websites, schoolName } = response;
		return {
			type: diffConstants.type.DATA_SCRAP_UPDATE_WEBSITES_SUCCESS,
			websites,
			schoolName,
		};
	}
}

function addDataScrapWebsite(form) {
	return (dispatch) => {
		dispatch(request());
		diffService.addDataScrapWebsite(form).then(
			(response) => {
				dispatch(success(response));
			},
			(error) => {
				toast.error(error.error);
				dispatch(failure());
			}
		);
	};

	function request() {
		return { type: diffConstants.type.DATA_SCRAP_ADD_LINK_REQUEST };
	}
	function failure() {
		return { type: diffConstants.type.DATA_SCRAP_ADD_LINK_FAILURE };
	}
	function success(response) {
		const { websites } = response;
		return {
			type: diffConstants.type.DATA_SCRAP_ADD_LINK_SUCCESS,
			websites,
		};
	}
}

function updateDataScrapWebsite(form) {
	return (dispatch) => {
		dispatch(request());
		diffService.updateDataScrapWebsite(form).then(
			(response) => {
				dispatch(success(response));
			},
			(error) => {
				toast.error(error.error);
				dispatch(failure());
			}
		);
	};

	function request() {
		return { type: diffConstants.type.DATA_SCRAP_UPDATE_LINK_REQUEST };
	}
	function failure() {
		return { type: diffConstants.type.DATA_SCRAP_UPDATE_LINK_FAILURE };
	}
	function success(response) {
		const { website } = response;
		return {
			type: diffConstants.type.DATA_SCRAP_UPDATE_LINK_SUCCESS,
			website,
		};
	}
}

function deleteDataScrapWebsite(form) {
	return (dispatch) => {
		dispatch(request());
		diffService.deleteDataScrapWebsite(form).then(
			(response) => {
				dispatch(success(response));
			},
			(error) => {
				toast.error(error.error);
				dispatch(failure());
			}
		);
	};

	function request() {
		return { type: diffConstants.type.DATA_SCRAP_DELETE_LINK_REQUEST };
	}
	function failure() {
		return { type: diffConstants.type.DATA_SCRAP_DELETE_LINK_FAILURE };
	}
	function success(response) {
		const { websites } = response;
		return {
			type: diffConstants.type.DATA_SCRAP_DELETE_LINK_SUCCESS,
			websites,
		};
	}
}

function compareDiffs(form) {
	return (dispatch) => {
		dispatch(request());
		diffService.compareDiffs(form).then(
			(response) => {
				dispatch(success(response));
			},
			(error) => {
				toast.error(error.error);
				dispatch(failure());
			}
		);
	};

	function request() {
		return { type: diffConstants.type.DATA_SCRAP_COMPARE_DIFFS_REQUEST };
	}
	function failure() {
		return { type: diffConstants.type.DATA_SCRAP_COMPARE_DIFFS_FAILURE };
	}
	function success(response) {
		const { tempDiff } = response;
		return {
			type: diffConstants.type.DATA_SCRAP_COMPARE_DIFFS_SUCCESS,
			tempDiff,
		};
	}
}

function genAllDiff() {
	return (dispatch) => {
		dispatch(request());
		diffService.genAllDiff().then(
			() => {
				dispatch(success());
			},
			(error) => {
				toast.error(error.error);
				dispatch(failure());
			}
		);
	};

	function request() {
		return { type: diffConstants.type.DATA_SCRAP_GEN_ALL_DIFF_REQUEST };
	}
	function failure() {
		return { type: diffConstants.type.DATA_SCRAP_GEN_ALL_DIFF_FAILURE };
	}
	function success() {
		return {
			type: diffConstants.type.DATA_SCRAP_GEN_ALL_DIFF_SUCCESS,
		};
	}
}

function getAllLog() {
	return (dispatch) => {
		dispatch(request());
		diffService.getAllLog().then(
			() => {
				dispatch(success());
			},
			(error) => {
				toast.error(error.error);
				dispatch(failure());
			}
		);
	};

	function request() {
		return { type: diffConstants.type.DATA_SCRAP_LOG_REQUEST };
	}
	function failure() {
		return { type: diffConstants.type.DATA_SCRAP_LOG_FAILURE };
	}
	function success() {
		return {
			type: diffConstants.type.DATA_SCRAP_LOG_SUCCESS,
		};
	}
}

function getAllError() {
	return (dispatch) => {
		dispatch(request());
		diffService.getAllError().then(
			() => {
				dispatch(success());
			},
			(error) => {
				toast.error(error.error);
				dispatch(failure());
			}
		);
	};

	function request() {
		return { type: diffConstants.type.DATA_SCRAP_ERROR_REQUEST };
	}
	function failure() {
		return { type: diffConstants.type.DATA_SCRAP_ERROR_FAILURE };
	}
	function success() {
		return {
			type: diffConstants.type.DATA_SCRAP_ERROR_SUCCESS,
		};
	}
}
