/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import Button from "@material-ui/core/Button";
import _ from "lodash";
import moment from "moment";
import { toast } from "react-toastify";

import { AdminMenubar, AdminUserInfo, AdminSectionbar, AdminFooter } from "components/admin";
import {
	PageContainer,
	LeftContainer,
	RightContainer,
	ContentContainer,
	SearchBar,
	SearchBarRight,
	SearchBarLeft,
} from "components/layout";
import { CancelButton, Loading, Status, AlertDialog } from "components/general";

import { history } from "_util/_helper";
import { generalConstants, statusConstants, missionConstants } from "_constants";
import { missionAction } from "actions";

import editIcon from "resources/img/school_inner_pencil.svg";
import BinIcon from "resources/img/bin.svg";
import cloneIcon from "resources/img/clone_icon.png";

export const MissionDetailScreen = () => {
	const location = useLocation();

	const dispatch = useDispatch();

	const [prevPage, setPrevPage] = useState(location?.state?.from);
	const [key, setMissionKey] = useState(location?.state?.missionKey);
	const [isDeleteAlertShow, setIsDeleteAlertShow] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	const { mission } = useSelector(({ mission }) => {
		return { mission: key ? mission.mission : null };
	});
	const isFetching = useSelector(({ mission }) => mission.isFetching);
	const deleteInProgress = useSelector(({ mission }) => mission.deleteInProgress);
	const deleteMissionSuccess = useSelector(({ mission }) => mission.deleteMissionSuccess);

	useEffect(() => {
		setPrevPage(location?.state?.from);
		setMissionKey(location?.state?.missionKey);
	}, [location?.state]);

	useEffect(() => {
		if (key) {
			dispatch(missionAction.getMissionByKey(key));
		}
	}, [dispatch]);

	useEffect(() => {
		setIsLoading(isFetching || deleteInProgress);
	}, [isFetching, deleteInProgress]);

	useEffect(() => {
		if (deleteMissionSuccess) {
			dispatch({ type: missionConstants.type.RESET_MISSION_SUCCESS });
			toast.success("任務已移除");
			history.replace(generalConstants.PATH.MISSION_MANAGEMENT);
		}
	}, [deleteMissionSuccess]);

	const handleBack = () => {
		history.goBack();
	};

	const handleClone = () => {
		history.push({
			pathname: `${generalConstants.PATH.MISSION}/add`,
			state: { from: prevPage, cloneMission: mission, missionKey: key },
		});
	};

	const handleEdit = () => {
		history.push({
			pathname: `${generalConstants.PATH.MISSION}/edit`,
			state: { from: prevPage, missionKey: key },
		});
	};

	const getLocationPath = () => {
		const pathArray = [];

		pathArray.push({ title: "主頁面", isAction: true, target: generalConstants.PATH.DASHBOARD });
		pathArray.push({
			title: generalConstants.TAB_NAME[generalConstants.NAV_TAB.MISSION_MANAGEMENT.key],
			isAction: true,
			target: generalConstants.PATH.MISSION_MANAGEMENT,
		});
		pathArray.push({
			title: generalConstants.TAB_NAME[generalConstants.NAV_TAB.MISSION_DETAILS.key],
			isAction: false,
			target: "",
		});

		return pathArray;
	};

	const handleCloseDeleteAlert = () => {
		setIsDeleteAlertShow(false);
	};

	const handleDelete = () => {
		const data = {
			key: mission._key,
			rev: mission._rev,
		};
		dispatch(missionAction.deleteMission(data));
	};

	const getStatus = (item) => {
		const status = "MISSION_" + item.status;
		const statusList = statusConstants.status;
		let thekey = "MISSION_OPEN";
		for (let k in statusList) {
			if (k === status) {
				thekey = k;
			}
		}
		return statusConstants.status[thekey].key;
	};

	const getMissionAction = (category, action) => {
		var label = "-";
		missionConstants.OPTION.action[category].forEach((item) => {
			if (item.key === action) {
				label = item.value;
			}
		});
		return label;
	};

	const getMinAndMaxText = (min, max, action) => {
		var label = "-";
		const unit = action === missionConstants.action.SUBSCRIBE || action === missionConstants.action.BOOKMARK ? "個" : "人";
		if (min > 0 && max > 0) {
			label = `${min}${unit} ～ ${max}${unit}`;
		} else if (max > 0) {
			label = `${max}${unit}`;
		} else if (min > 0) {
			label = `${min}${unit}`;
		}
		return label;
	};

	const getRecurrence = (category, action, enableForeignId) => {
		if (category === missionConstants.category.MISSION.key && action === missionConstants.action.CHECKIN) {
			return missionConstants.recurrence.DAILY.key;
		} else if (
			(category === missionConstants.category.CHILD.key && action === missionConstants.action.UPDATE) ||
			(category === missionConstants.category.PROFILE.key && action === missionConstants.action.UPDATE) ||
			(category === missionConstants.category.PUSH.key && action === missionConstants.action.ENABLE) ||
			(category === missionConstants.category.COURSE.key && action === missionConstants.action.BOOKMARK) ||
			(category === missionConstants.category.SCHOOL.key && action === missionConstants.action.SUBSCRIBE) ||
			(category === missionConstants.category.SOCIALNETWORK.key && action === missionConstants.action.JOIN) ||
			(category === missionConstants.category.USER.key && action === missionConstants.action.FOLLOW) ||
			(category === missionConstants.category.SOCIALEVENT.key && action === missionConstants.action.TRACK && enableForeignId) ||
			(category === missionConstants.category.SOCIALEVENT.key && action === missionConstants.action.JOIN && enableForeignId) ||
			(category === missionConstants.category.POST.key && action === missionConstants.action.LIKE && enableForeignId)
		) {
			return missionConstants.recurrence.NONE.key;
		} else {
			return missionConstants.recurrence.ALL;
		}
	};

	if (!key) {
		return null;
	}
	return (
		<>
			{!!isLoading && <Loading />}
			<AlertDialog active={isDeleteAlertShow} handleOnClose={handleCloseDeleteAlert} handleOnYes={handleDelete} title={"確定移除任務？"} />
			<PageContainer>
				<LeftContainer>
					<AdminMenubar section={generalConstants.NAV_TAB.MISSION_MANAGEMENT.key} />
				</LeftContainer>
				<RightContainer>
					<AdminUserInfo />
					<AdminSectionbar title={generalConstants.NAV_TAB.MISSION_DETAILS.key} label={""} locationPathArray={getLocationPath()} />
					<ContentContainer style={{ padding: "0px" }}>
						<SearchBar style={{ paddingLeft: "20px", paddingRight: "20px", paddingTop: "20px" }}>
							<SearchBarLeft style={{ alignItems: "center", marginTop: "5px" }}>
								<RowInSearchBlock style={{ float: "left" }}>
									<TextFieldLabel style={{ marginRight: "10px", marginTop: "2px" }}>狀態</TextFieldLabel>
									<div style={{ width: "60px" }}>
										<Status status={getStatus(mission)} />
									</div>
								</RowInSearchBlock>
							</SearchBarLeft>
							<SearchBarRight>
								<RowInSearchBlock style={{ float: "right" }} marginBottom="0px">
									<Button aria-label="複製" onClick={handleClone} startIcon={<img alt="" src={cloneIcon} />}>
										<SubTitle>複製任務</SubTitle>
									</Button>
									<Button onClick={() => setIsDeleteAlertShow(true)} startIcon={<img alt="" src={BinIcon} />}>
										<SubTitle>刪除任務</SubTitle>
									</Button>
									<Button onClick={handleEdit} startIcon={<img alt="" src={editIcon} />}>
										<SubTitle>修改任務</SubTitle>
									</Button>
								</RowInSearchBlock>
							</SearchBarRight>
						</SearchBar>
						<div style={{ flexDirection: "row", display: "flex" }}>
							<ContentLeft>
								<Block>
									<TextFieldLabel>內容</TextFieldLabel>
									<TextFieldValue>{mission?.description || "-"}</TextFieldValue>
								</Block>
								<Block>
									<TextFieldLabel>達成條件</TextFieldLabel>
									<TextFieldValue>{mission?.instruction || "-"}</TextFieldValue>
								</Block>
								<Block>
									<TextFieldLabel>類型</TextFieldLabel>
									<TextFieldValue>{missionConstants.category[mission.category]?.label || "-"}</TextFieldValue>
								</Block>
								<Block>
									<TextFieldLabel>行動要求</TextFieldLabel>
									<TextFieldValue>
										{mission?.category && mission?.action ? getMissionAction(mission.category, mission.action) : "-"}
									</TextFieldValue>
								</Block>
								<Block>
									<TextFieldLabel>報酬</TextFieldLabel>
									<TextFieldValue>{`${mission.coin || "-"} KOT幣`}</TextFieldValue>
								</Block>
								<Block>
									<TextFieldLabel>完成次數</TextFieldLabel>
									<TextFieldValue>{`${mission.completedCount} 次`}</TextFieldValue>
								</Block>
							</ContentLeft>
							<ContentLeft>
								{mission?.foreignId && missionConstants.OPTION.foreignIdMission.indexOf(mission.category) > -1 && (
									<Block>
										<TextFieldLabel>指定ID</TextFieldLabel>
										<TextFieldValue>{mission.foreignId.split("/")[1] || "-"}</TextFieldValue>
									</Block>
								)}
								{getRecurrence(mission.category, mission.action, !_.isEmpty(mission.foreignId)) === missionConstants.recurrence.ALL && (
									<Block>
										<TextFieldLabel>重置設定</TextFieldLabel>
										<TextFieldValue>{missionConstants.recurrence[mission.recurrence]?.label || "-"}</TextFieldValue>
									</Block>
								)}
								{mission.duration &&
									(mission.action === missionConstants.action.READ || mission.action === missionConstants.action.SEARCH) && (
										<Block>
											<TextFieldLabel>進行時間</TextFieldLabel>
											<TextFieldValue>{`${mission.duration || "-"} 秒`}</TextFieldValue>
										</Block>
									)}
								{(mission.category === missionConstants.category.POST.key &&
									(mission.action === missionConstants.action.ADD || mission.action === missionConstants.action.COMMENT)) ||
									((mission.action === missionConstants.action.SHARE ||
										(!mission.foreignId &&
											(mission.action === missionConstants.action.SUBSCRIBE || mission.action === missionConstants.action.BOOKMARK))) && (
										<Block>
											<TextFieldLabel>
												{mission.action === missionConstants.action.SUBSCRIBE || mission.action === missionConstants.action.BOOKMARK
													? "至少達成"
													: "至少提及"}
											</TextFieldLabel>
											<TextFieldValue>{getMinAndMaxText(mission?.min, mission?.max, mission.action)}</TextFieldValue>
										</Block>
									))}
								{mission.effectiveFrom && (
									<Block>
										<>
											<TextFieldLabel>開始時間</TextFieldLabel>
											<TextFieldValue> {moment(mission.effectiveFrom).format("YYYY年MM月DD日 hh:mm:ssA")}</TextFieldValue>
										</>
									</Block>
								)}
								{mission.effectiveTo && (
									<Block>
										<>
											<TextFieldLabel>結束時間</TextFieldLabel>
											<TextFieldValue> {moment(mission.effectiveTo).format("YYYY年MM月DD日 hh:mm:ssA")}</TextFieldValue>
										</>
									</Block>
								)}
								<RowInBlock style={{ alignItems: "flex-end", justifyContent: "flex-end" }}>
									<CancelButton label={"返回"} style={{ marginRight: "15px" }} handleOnClick={handleBack} />
								</RowInBlock>
							</ContentLeft>
						</div>
						{!_.isEmpty(mission?.lastUpdateTime) && (
							<RowInLastUpdate style={{ alignItems: "flex-end", justifyContent: "flex-end", marginRight: 15, paddingBottom: 10 }}>
								<MissionSmalllDetaiLabel>
									最後更新:{(mission.lastUpdateTime && moment(mission.lastUpdateTime).format("YYYY年M月DD日 hh:mmA")) || "-"}
								</MissionSmalllDetaiLabel>
							</RowInLastUpdate>
						)}
					</ContentContainer>
					<AdminFooter></AdminFooter>
				</RightContainer>
			</PageContainer>
		</>
	);
};

const RowInBlock = styled.div`
	padding-top: 30px;
	display: flex;
	flex-direction: row;
`;

const RowInSearchBlock = styled.div`
	display: flex;
	flex-direction: row;
`;

const RowInLastUpdate = styled.div`
	display: flex;
	flex-direction: row;
`;

const ContentLeft = styled.div`
	flex: 1;
	padding: 10px;
	border-right: 1px solid #dddddd;
`;

const Block = styled.div`
	padding: 10px;
	flex: ${(props) => props.flex || 1};
`;

const TextFieldLabel = styled.div`
	color: #000000;
	font-size: 13px;
	line-height: 1.5;
	font-weight: bold;
	margin-bottom: 7px;
`;

const TextFieldValue = styled.div`
	font-size: 13px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.46;
	letter-spacing: normal;
	text-align: left;
	color: #333333;
	word-wrap: break-word;
`;

const SubTitle = styled.div`
	color: #666666;
	font-size: 12px;
	font-weight: 500;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.55;
	letter-spacing: normal;
`;

const MissionSmalllDetaiLabel = styled.div`
	color: #666666;
	font-size: 11px;
	font-weight: 500;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.55;
	letter-spacing: normal;
`;
