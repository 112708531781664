import { reportConstants, generalConstants } from "_constants";
import { reportService } from "services";
import { toast } from "react-toastify";
import { history } from "_util/_helper";

export const reportAction = {
	fetchReport,
	getReportTarget,
	handleReport,
	bypassReport,
};

function fetchReport(form) {
	return (dispatch) => {
		dispatch(request());
		reportService.fetchReport(form).then(
			(response) => {
				dispatch(success(response, form));
			},
			(error) => {
				toast.error(error.error);
				dispatch(failure());
			}
		);
	};

	function request() {
		return { type: reportConstants.type.FETCH_REPORT_LIST_REQUEST };
	}
	function failure() {
		return { type: reportConstants.type.FETCH_REPORT_LIST_FAILURE };
	}
	function success(response, inputData) {
		const { items, totalItems } = response;
		const { searchWord, sort, page } = inputData;
		return {
			type: reportConstants.type.FETCH_REPORT_LIST_SUCCESS,
			totalNum: totalItems,
			totalPage: totalItems / 10 === parseInt(totalItems / 10) ? parseInt(totalItems / 10) : parseInt(totalItems / 10) + 1,
			currentPage: page,
			reportList: items,
			searchWord: searchWord,
			sortingKey: sort.split(":")[0],
			sortingDirection: sort.split(":")[1],
		};
	}
}

function getReportTarget(targetType, key, batchTime = null) {
	return (dispatch) => {
		dispatch(request());
		reportService.getReportTarget(targetType, key, batchTime).then(
			(response) => {
				dispatch(success(response));
			},
			(error) => {
				toast.error(error.error);
				dispatch(failure());
				if (error.error?.status !== 401) {
					history.push(generalConstants.PATH.REPORT_MANAGEMENT);
				}
			}
		);
	};

	function request() {
		return { type: reportConstants.type.FETCH_REPORT_TARGET_REQUEST };
	}
	function failure() {
		return { type: reportConstants.type.FETCH_REPORT_TARGET_FAILURE };
	}
	function success(report) {
		return {
			type: reportConstants.type.FETCH_REPORT_TARGET_SUCCESS,
			report,
		};
	}
}

function bypassReport(data) {
	if (typeof data == "object") data.action = "BYPASS";
	return (dispatch) => {
		dispatch(request());
		reportService.handleReport(data).then(
			() => {
				dispatch(success());
			},
			(error) => {
				toast.error(error.error);
				dispatch(failure());
			}
		);
	};

	function request() {
		return { type: reportConstants.type.BYPASS_REPORT_REQUEST };
	}
	function failure() {
		return { type: reportConstants.type.BYPASS_REPORT_FAILURE };
	}
	function success() {
		return { type: reportConstants.type.BYPASS_REPORT_SUCCESS };
	}
}

function handleReport(data) {
	if (typeof data == "object") data.action = "DELETE";
	return (dispatch) => {
		dispatch(request());
		reportService.handleReport(data).then(
			() => {
				dispatch(success());
			},
			(error) => {
				toast.error(error.error);
				dispatch(failure());
			}
		);
	};

	function request() {
		return { type: reportConstants.type.HANDLE_REPORT_REQUEST };
	}
	function failure() {
		return { type: reportConstants.type.HANDLE_REPORT_FAILURE };
	}
	function success() {
		return { type: reportConstants.type.HANDLE_REPORT_SUCCESS };
	}
}
