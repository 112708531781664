import React, { useState, useEffect } from "react";
import { Prompt } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { adminConstants, statusConstants, generalConstants } from "_constants";
import { adminAction } from "actions";
import { history } from "_util/_helper";
import { Select, SubmitButton, CancelButton, WarningButton, Loading, TextField } from "components/general";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";

import { AdminMenubar, AdminUserInfo, AdminSectionbar, AdminFooter } from "components/admin";
import { PageContainer, LeftContainer, RightContainer, ContentContainer } from "components/layout";

export const EditAdminPage = () => {
	const { isFetching, admin, createAdminSuccess, updateAdminSuccess, loggedIn } = useSelector(({ admin, auth }) => ({
		isFetching: admin.isFetching,
		admin: admin.admin,
		createAdminSuccess: admin.createAdminSuccess,
		updateAdminSuccess: admin.updateAdminSuccess,
		loggedIn: auth.loggedIn,
	}));

	const location = useLocation();
	const [key, setAdminKey] = useState(location?.state?.adminKey);

	useEffect(() => {
		setAdminKey(location?.state?.adminKey);
	}, [location?.state]);

	const initForm = {
		key: "",
		email: "",
		username: "",
		fullName: "",
	};

	const [formData, setFormData] = useState(initForm);
	const [isLoading, setIsLoading] = useState(false);
	const [isChanged, setIsChanged] = useState(false);
	const [isSaved, setIsSaved] = useState(false);
	const [isResend, setIsResend] = useState(false);

	const dispatch = useDispatch();

	useEffect(() => {
		if (key) {
			dispatch(adminAction.getAdmin(key));
		}
	}, [dispatch, key]);

	useEffect(() => {
		const data = {
			key: admin._key,
			status: admin.status,
			email: admin.email,
			fullName: admin.fullName ? admin.fullName : "",
		};
		setFormData(data);
	}, [admin]);

	const handleChange = ({ key, value }) => {
		setFormData({ ...formData, [key]: value });
		setIsChanged(true);
	};

	const handleBack = () => {
		history.push(generalConstants.PATH.ADMIN_MANAGEMENT);
	};

	useEffect(() => {
		setIsLoading(isFetching);
	}, [isFetching]);

	const handleSubmit = () => {
		if (
			admin.status !== statusConstants.status.ADMIN_STATUS_UNACTIVATE.key &&
			formData.status === statusConstants.status.ADMIN_STATUS_UNACTIVATE.key
		) {
			toast.error("不能更改狀態至未激活");
			return;
		}

		if (!formData.email) {
			toast.error("請填寫電郵");
			return;
		}

		if (!formData.fullName) {
			toast.error("請填寫全名");
			return;
		}

		dispatch(adminAction.updateAdmin({ username: admin.username, ...formData }));
	};

	const handleResendEmail = () => {
		adminAction.resendActivationEmail(admin._key);
		setIsResend(true);
	};

	useEffect(() => {
		if (updateAdminSuccess) {
			if (!isSaved) {
				setIsSaved(true);
				return;
			}
			dispatch({ type: adminConstants.type.RESET_ADMIN_SUCCESS });
			toast.success("已更新管理員資料");
			history.replace(generalConstants.PATH.ADMIN_MANAGEMENT);
		}
	}, [updateAdminSuccess, isSaved, dispatch]);

	useEffect(() => {
		if (createAdminSuccess) {
			if (!isSaved) {
				setIsSaved(true);
				return;
			}
			dispatch({ type: adminConstants.type.RESET_ADMIN_SUCCESS });
			toast.success("已新增管理員");
			history.replace(generalConstants.PATH.ADMIN_MANAGEMENT);
		}
	}, [createAdminSuccess, isSaved, dispatch]);

	if (!key) {
		return null;
	}
	return (
		<>
			{!!isLoading && <Loading />}
			<Prompt when={isChanged && !isSaved && loggedIn} message="你有未儲存的變更，確定不儲存下離開？" />
			<PageContainer>
				<LeftContainer>
					<AdminMenubar section={generalConstants.NAV_TAB.ADMIN_MANAGEMENT.key} />
				</LeftContainer>
				<RightContainer>
					<AdminUserInfo />
					<AdminSectionbar
						title={generalConstants.NAV_TAB.EDIT_ADMIN.key}
						locationPathArray={[
							{ title: "主頁面", isAction: true, target: generalConstants.PATH.DASHBOARD },
							{
								title: generalConstants.TAB_NAME[generalConstants.NAV_TAB.ADMIN_MANAGEMENT.key],
								isAction: true,
								target: generalConstants.PATH.ADMIN_MANAGEMENT,
							},
							{
								title: generalConstants.TAB_NAME[generalConstants.NAV_TAB.EDIT_ADMIN.key],
								isAction: false,
								target: "",
							},
						]}
					/>
					<ContentContainer>
						<RowInBlock>
							<ContentLeft>
								<Block style={{ width: "90%" }} marginTop="0">
									<TextFieldLabel>用户名稱</TextFieldLabel>
									<TextFieldLabel>{admin.username}</TextFieldLabel>
								</Block>
								<Block style={{ width: "90%" }} marginTop={"20px"}>
									<TextFieldLabel>狀態</TextFieldLabel>
									<Select
										name={"status"}
										options={statusConstants.adminStatus}
										value={formData.status ? formData.status : ""}
										handleChange={handleChange}
									/>
								</Block>
								{admin.status === statusConstants.status.ADMIN_STATUS_UNACTIVATE.key && (
									<Block style={{ width: "90%" }} marginTop={"10px"}>
										<WarningButton label={"重發認證電郵"} handleOnClick={handleResendEmail} active={!isResend} />
									</Block>
								)}
								<Block style={{ width: "90%" }}>
									<TextFieldLabel>電郵</TextFieldLabel>
									<TextField placeholder="電郵" value={formData.email} name="email" handleChange={handleChange} />
								</Block>
								<Block style={{ width: "90%" }}>
									<TextFieldLabel>全名</TextFieldLabel>
									<TextField placeholder="全名" value={formData.fullName} name="fullName" handleChange={handleChange} />
								</Block>
							</ContentLeft>

							<ContentRight></ContentRight>
						</RowInBlock>
						<RowInBlock marginTop={"10px"} style={{ justifyContent: "flex-end" }}>
							<CancelButton label={"取消"} style={{ marginRight: "15px" }} handleOnClick={handleBack} />
							<SubmitButton label={"確認"} handleOnClick={handleSubmit} active={isChanged && !isFetching} />
						</RowInBlock>
					</ContentContainer>
					<AdminFooter></AdminFooter>
				</RightContainer>
			</PageContainer>
		</>
	);
};

const ContentLeft = styled.div`
	flex: 0 0 400px;
	border-right: 1px solid #dddddd;
	min-height: 400px;
`;

const ContentRight = styled.div`
	flex: 1;
	padding-left: 20px;
`;

const Block = styled.div`
	margin-top: ${(props) => props.marginTop || "40px"};
	flex-direction: column;
`;

const RowInBlock = styled.div`
	display: flex;
	margin-bottom: ${(props) => props.marginBottom || "10px"};
	flex-direction: row;
`;

const TextFieldLabel = styled.div`
	color: black;
	font-size: 13px;
	line-height: 1.5;
	font-weight: bold;
	margin-bottom: 10px;
`;
