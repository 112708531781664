import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import MuiPagination from "@material-ui/lab/Pagination";
import MuiPaginationItem from "@material-ui/lab/PaginationItem";

export const Pagination = ({ count, page, handlePageChange, ...otherProps }) => {
	const useStyles = makeStyles(() => ({
		muiRootOverride: {
			background: "none !important",
		},
		muiSelectedOverride: {
			background: "none !important",
			color: "#0085b5",
		},
	}));

	const classes = useStyles();

	const handleOnChange = (event, value) => {
		handlePageChange(value);
	};

	return (
		<MuiPagination
			count={count}
			showFirstButton
			showLastButton
			page={page}
			onChange={handleOnChange}
			size="small" 
			renderItem={(item) => <MuiPaginationItem style={{paddingRight:"8px",paddingLeft:"8px"}} classes={{ root: classes.muiRootOverride, selected: classes.muiSelectedOverride }} {...item} />}
			{...otherProps}
		/>
	);
};

Pagination.propTypes = {
	count: PropTypes.number,
	page: PropTypes.number,
	handlePageChange: PropTypes.func,
};


