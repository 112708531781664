export const organizationConstants = {
	type: {
		RESET_ALL_ORGANIZATION_LIST: "RESET_ALL_ORGANIZATION_LIST",
		FETCH_ORGANIZATION_LIST_REQUEST: "FETCH_ORGANIZATION_LIST_REQUEST",
		FETCH_ORGANIZATION_LIST_SUCCESS: "FETCH_ORGANIZATION_LIST_SUCCESS",
		FETCH_ORGANIZATION_LIST_FAILURE: "FETCH_ORGANIZATION_LIST_FAILURE",

		FETCH_ORGANIZATION_REQUEST: "FETCH_ORGANIZATION_REQUEST",
		FETCH_ORGANIZATION_SUCCESS: "FETCH_ORGANIZATION_SUCCESS",
		FETCH_ORGANIZATION_FAILURE: "FETCH_ORGANIZATION_FAILURE",
		UPDATE_ORGANIZATION_REQUEST: "UPDATE_ORGANIZATION_REQUEST",
		UPDATE_ORGANIZATION_SUCCESS: "UPDATE_ORGANIZATION_SUCCESS",
		UPDATE_ORGANIZATION_FAILURE: "UPDATE_ORGANIZATION_FAILURE",
	},
	TABLE_COLUMN: [
		{ key: "status", value: "狀態", searchable: false, width: "8%" },
		{ key: "type", value: "機構類型", searchable: true, width: "8%" },
		{ key: "name", value: "機構名稱", searchable: true, width: "20%" },
		{ key: "contactPerson", value: "聯絡人名字", searchable: false, width: "20%" },
		{ key: "remark", value: "備註", searchable: false, width: "5%" },
		{ key: "rejectReason", value: "拒絕原因", searchable: false, width: "8%" },
		{ key: "createdTime", value: "申請時間", searchable: false, width: "15%" },
		{ key: "approvalTime", value: "處理時間", searchable: false, width: "15%" },
		{ key: "hasSocialAccount", value: "童行網", searchable: false, width: "8%" },
	],
	APPROVAL_STATUS_OPTION: [
		{ key: "PENDING", value: "待批核" },
		{ key: "APPROVED", value: "通過批核" },
		{ key: "REJECTED", value: "不獲批核" },
	],
	STATUS_OPTION: [
		{ key: "UNACTIVATE", value: "未激活" },
		{ key: "ACTIVE", value: "正常" },
		{ key: "SUSPEND", value: "暫停" },
		{ key: "CLOSED", value: "關閉" },
		{ key: "BLOCKED", value: "封鎖" },
	],
	organizationType: {
		SCHOOL: "學校",
		LEARNING_CENTER: "補習社",
	},
	status: {
		ACTIVE: "ACTIVE",
		UNACTIVATE: "UNACTIVATE",
		SUSPEND: "SUSPEND",
		CLOSED: "CLOSED",
		BLOCKED: "BLOCKED",
	},
	approvalStatus: {
		PENDING: "PENDING",
		REJECTED: "REJECTED",
		APPROVED: "APPROVED",
	},
	statusLabel: {
		ACTIVE: "正常",
		UNACTIVATE: "未激活",
		SUSPEND: "暫停",
		CLOSED: "關閉",
		BLOCKED: "已封鎖",
	},
	approvalStatusLabel: {
		PENDING: "待批核",
		REJECTED: "不獲批核",
		APPROVED: "通過批核",
	},
	OPTION: {
		isVerified: [
			{ key: "true", value: "已驗証" },
			{ key: "false", value: "未驗証" },
		],
	},
};
