import React from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";

export const DangerButton = (props) => {
	const { label, handleOnClick, style, active } = props;

	return (
		<Button
			variant="contained"
			style={{
				padding: "8px 34px",
				borderRadius: 40,
				backgroundColor: active ? "#ff5c5c" : "#eeeeee",
				borderColor: "#ff5c5c",
				color: active ? "white" : "black",
				textAlign: "center",
				whiteSpace: "nowrap",
				...style,
			}}
			onClick={() => {
				if (handleOnClick && !!active) {
					handleOnClick();
				}
			}}
		>
			{label}
		</Button>
	);
};

DangerButton.propTypes = {
	label: PropTypes.string,
	handleOnClick: PropTypes.func,
	style: PropTypes.object,
	active: PropTypes.bool,
};

DangerButton.defaultProps = {
	active: true,
};
