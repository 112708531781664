import React from "react";
import PropTypes from "prop-types";
import Input from "@material-ui/core/Input";

export const StandardTextField = (props) => {
	const { name, placeholder, value, handleChange, ...otherProps } = props;

	return (
		<Input
			name={name}
			labelwidth={0}
			placeholder={placeholder}
			fullWidth
			value={value}
			onChange={(event) => {
				if (handleChange) {
					handleChange({ key: event.target.name, value: event.target.value });
				}
			}}
            {...otherProps}
            style={{fontSize:"12px"}}
		/>
	);
};

StandardTextField.propTypes = {
	name: PropTypes.string,
	placeholder: PropTypes.string,
	value: PropTypes.string,
	handleChange: PropTypes.func,
	otherProps: PropTypes.any,
};
