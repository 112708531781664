import styled from "styled-components";

export const PageContainer = styled.div`
	width: 100%;
	margin: auto;
	height: 100%;
	display: flex;
`;

export const LeftContainer = styled.div`
	flex: 0 0 171px;
`;

export const RightContainer = styled.div`
	flex: 1;
	background-color: #f2f2f2;
`;

export const ContentContainer = styled.div`
	margin: 0px 20px 0px 20px;
	padding: 16px 20px 17px;
	border-radius: 3px;
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);
	background-color: #ffffff;
	/*
    overflow-y: scroll;
    height: 100vh;
    */
	&:after {
		clear: both;
		content: "";
		display: block;
	}
`;

export const PaginationContainer = styled.div`
	padding: 23px 0px 0px 0px;
	margin-left: -10px;
	margin-right: -10px;
`;
