/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import {
	Loading,
	SubmitButton,
	WarningButton,
	TextField,
	Switch,
	NewDataScrapLinkDialog,
	AlertDialog,
	TemplateImportDialog,
} from "components/general";
import { generalConstants, diffConstants, schoolDataConstants, messageConstants } from "_constants";
import { diffAction } from "actions";
import { useLocation } from "react-router-dom";
import Paper from "@material-ui/core/Paper";
import { AddCircleOutline, ErrorOutline, ExpandMore } from "@material-ui/icons";
import xDate from "xdate";
import Checkbox from "@material-ui/core/Checkbox";
import { default as MuiSelect } from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import CircularProgress from "@material-ui/core/CircularProgress";
import { ReactComponent as DraftMessageIcon } from "resources/img/draftmsg.svg";
import { toast } from "react-toastify";
import { ReactComponent as BinIcon } from "resources/img/datascrap_bin.svg";
import { history } from "_util/_helper";
import Radio from "@material-ui/core/Radio";
import { AdminMenubar, AdminUserInfo, AdminSectionbar } from "components/admin";
import { PageContainer, LeftContainer, RightContainer } from "components/layout";
import {
	formatDifferenceToHtml,
	trimOutput,
	formatDifferenceToOldDataHtml,
	formatDifferenceToNewDataHtml,
} from "_util/_helper/differencesFormat";

import { makeStyles } from "@material-ui/core/styles";

import ImportIcon from "resources/img/import_template.svg";
import _ from "lodash";

const FROM_DATASCRAP = "dataScrap";
export const DataScrapDetailScreen = () => {
	const {
		isFetching,
		websites,
		schoolName,
		linkDifferences,
		differenceDetail,
		diff,
		user,
		updatedWebsiteInfo,
		tempDiff,
		tempSelected,
		isDetailFetching,
	} = useSelector(({ diff, auth }) => ({
		isFetching: diff.isFetching,
		websites: diff.websites,
		schoolName: diff.schoolName,
		linkDifferences: diff.linkDifferences,
		differenceDetail: diff.differenceDetail,
		diff: diff.diff,
		user: auth.user,
		updatedWebsiteInfo: diff.updatedWebsiteInfo,
		tempDiff: diff.tempDiff,
		tempSelected: diff.tempSelected,
		isDetailFetching: diff.isDetailFetching,
	}));
	const useStyles = makeStyles(() => ({
		iconOverride: {
			color: "#ffffff",
		},
		label: {
			fontSize: "12px",
			fontWeight: "bold",
		},
	}));
	const location = useLocation();
	const [key, setDataScrapKey] = useState(location?.state?.dataScrapKey);
	const [school, setSchool] = useState(location?.state?.school);

	useEffect(() => {
		setDataScrapKey(location?.state?.dataScrapKey);
		setSchool(location?.state?.school);
	}, [location?.state]);

	const classes = useStyles();
	const [displayList, setDisplayList] = useState([]);
	const [rightHeader, setRightHeader] = useState("連結1");
	const [changedHrefLinks, setChangedHrefLinks] = useState([]);

	const [changedHrefTexts, setChangedHrefTexts] = useState([]);
	const [displayLink, setDisplayLink] = useState("");
	const [displayDiff, setDisplayDiff] = useState("");
	const [isLoading, setIsLoading] = useState(true);

	const [detailLoading, setDetailLoading] = useState(false);
	const [websiteList, setWebsiteList] = useState([]);
	const [selectedDiff, setSelectedDiff] = useState([]);
	const [selectOption, setSelectOption] = useState("NONE");
	const [linkSelectOption, setLinkSelectOption] = useState("NONE");
	const [diffSelectOption, setDiffSelectOption] = useState("NONE");
	const [isNewLinkShow, setIsNewLinkShow] = useState(false);
	const [isDeleteAlertShow, setIsDeleteAlertShow] = useState(false);
	const [isUpdateAlertShow, setIsUpdateAlertShow] = useState(false);
	const [selectedLink, setSelectedLink] = useState([]);
	const [isLinkSelected, setIsLinkSelected] = useState(false);
	const [messageContent, setMessageContent] = useState("");
	const [isCompare, setIsCompare] = useState(false);
	const [isDiffSelected, setIsDiffSelected] = useState(false);
	const [isTrimmed, setIsTrimmed] = useState(false);
	const [targetDiff, setTargetDiff] = useState({});
	const [targetWebsite, setTargetWebsite] = useState({});
	const [isCheckLink, setIsCheckLink] = useState(true);
	const [radioValue, setRadioValue] = useState("DIFF");
	const [dataRadioValue, setDataRadioValue] = useState("CONTENT");
	const [isImportShow, setIsImportShow] = useState(false);

	const dispatch = useDispatch();

	useEffect(() => {
		setTargetDiff({});
		dispatch({ type: diffConstants.type.RESET_TEMP_DIFF });
		if (key) {
			dispatch(diffAction.getDataScrapDetail(key));
		}
	}, []);

	useEffect(() => {
		let tempDisplayList = displayList.map((x) => {
			return x._id === diff._id ? { ...x, status: diff.status } : x;
		});
		setDisplayList(tempDisplayList);
	}, [diff]);

	useEffect(() => {
		setIsLoading(isFetching);
	}, [isFetching]);

	useEffect(() => {
		setDetailLoading(isDetailFetching);
	}, [isDetailFetching]);

	useEffect(() => {
		setTargetDiff(differenceDetail);
	}, [differenceDetail]);

	useEffect(() => {
		setDisplayList(linkDifferences);
	}, [linkDifferences]);

	useEffect(() => {
		if (displayList?.length > 0) {
			dispatch(diffAction.getDataScrapDifferenceDetail(displayList[0]._id));
		}
	}, [displayList]);

	useEffect(() => {
		setWebsiteList(websites);
		setLinkSelectOption("NONE");
		setIsLinkSelected(false);
		setSelectedLink([]);
		setIsDeleteAlertShow(false);
		if (websites?.length > 0 && websites[0].schoolId === key) {
			handleTDOnClick(1, websites[0]);
		} else {
			dispatch({ type: diffConstants.type.RESET_TEMP_DIFF });
			setTargetDiff({});
			setDisplayList([]);
			setDisplayLink("");
		}
	}, [websites]);
	const renderVariableHtml = (html) => {
		const htmlObject = { __html: html };
		return <div style={{ margin: "10px" }} dangerouslySetInnerHTML={htmlObject} />;
	};
	useEffect(() => {
		if (!_.isEmpty(updatedWebsiteInfo)) {
			let tempWebsiteList = websiteList.map((x) => {
				return x._id === updatedWebsiteInfo._id ? { ...x, hasOutstanding: updatedWebsiteInfo.hasOutstanding } : x;
			});
			setWebsiteList(tempWebsiteList);
		}
	}, [updatedWebsiteInfo]);

	useEffect(() => {
		if (tempSelected) {
			setTargetDiff(tempDiff);
		}
	}, [tempDiff, tempSelected]);
	useEffect(() => {
		if (radioValue === "OLD") {
			setDisplayDiff(targetDiff?.differences ? formatDifferenceToOldDataHtml(targetDiff?.differences) : "");
		}
		if (radioValue === "NEW") {
			setDisplayDiff(targetDiff?.differences ? formatDifferenceToNewDataHtml(targetDiff?.differences) : "");
		}
		if (radioValue === "DIFF") {
			if (isTrimmed) {
				setDisplayDiff(targetDiff?.differences ? formatDifferenceToHtml(trimOutput(targetDiff?.differences)) : "");
			} else {
				setDisplayDiff(targetDiff?.differences ? formatDifferenceToHtml(targetDiff?.differences) : "");
			}
		}
		setChangedHrefLinks(targetDiff?.changedHrefLinks || []);
		setChangedHrefTexts(targetDiff?.changedHrefTexts || []);
		setSelectOption(targetDiff?.status || "NONE");
	}, [targetDiff]);

	const renderTD = () => {
		if (displayList?.length > 0) {
			return displayList.map((link, index) => {
				return (
					<TableRow
						key={`${link._id}_${index}_tr`}
						style={{ backgroundColor: targetDiff && targetDiff._id === link._id ? "#eeeeee" : "#ffffff" }}
					>
						<TableTd key={`checkBox_${index}_td`} style={{ width: "10px" }}>
							<Checkbox
								style={{ color: "#333399" }}
								checked={_.includes(selectedDiff, link._id)}
								onChange={() => {
									handleDiffCheckboxOnChange(link);
								}}
								disabled={isCompare && selectedDiff.length === 2 && !_.includes(selectedDiff, link._id)}
							></Checkbox>
						</TableTd>
						<TableTd
							key={`${link._id}_diff_${index}_td`}
							type="button"
							onClick={() => {
								handleDiffRowOnClick(link);
							}}
						>
							{xDate(link.dateTime).toString("yyyy年M月dd日 h:mmtt")}
							{link.status === "OUTSTANDING" && <ErrorOutline fontSize={"small"} style={{ color: "red", float: "right" }} />}
						</TableTd>
					</TableRow>
				);
			});
		}
	};
	const renderTableCell = renderTD();

	const handleDiffRowOnClick = (item) => {
		dispatch(diffAction.getDataScrapDifferenceDetail(item._id));
	};
	const handleStatusChange = (value) => {
		setSelectOption(value);
	};
	const handleLinkChange = (value) => {
		setLinkSelectOption(value);
	};
	const handleDiffStatusChange = (value) => {
		setDiffSelectOption(value);
	};
	const handleNewButtonOnClick = () => {
		setIsNewLinkShow(true);
	};

	const handleTextFieldChange = ({ value }) => {
		setMessageContent(value);
	};
	const handleDeleteOnClick = () => {
		setIsDeleteAlertShow(true);
	};

	const handleDeleteYes = () => {
		const data = { ids: selectedLink, schoolId: key };
		dispatch(diffAction.deleteDataScrapWebsite(data));
	};

	const handleDeleteOnClose = () => {
		setIsDeleteAlertShow(false);
	};

	const handleUpdateYes = () => {
		setIsCheckLink(!isCheckLink);
		dispatch(diffAction.updateDataScrapWebsite({ id: targetWebsite._id, checkLink: !isCheckLink }));
		setIsUpdateAlertShow(false);
	};

	const handleUpdateOnClose = () => {
		setIsUpdateAlertShow(false);
	};
	const handleCloseNewLink = () => {
		setIsNewLinkShow(false);
	};
	const handleRadioChange = (e, item) => {
		setRadioValue(e.target.value);
		setIsTrimmed(false);
		if (e.target.value === "OLD") {
			setDisplayDiff(item?.differences ? formatDifferenceToOldDataHtml(item?.differences) : "");
		}
		if (e.target.value === "NEW") {
			setDisplayDiff(item?.differences ? formatDifferenceToNewDataHtml(item?.differences) : "");
		}
		if (e.target.value === "DIFF") {
			setDisplayDiff(item?.differences ? formatDifferenceToHtml(item?.differences) : "");
		}
	};
	const handleDataRadioChange = (e) => {
		setDataRadioValue(e.target.value);
	};

	const setColor = (value) => {
		if (value === "OUTSTANDING") {
			return "#fecf2f";
		} else if (value === "PROCESSED") {
			return "#00b22a";
		} else if (value === "IGNORED") {
			return "#7c6e68";
		} else {
			return "#8f96a2";
		}
	};

	const handleOnImport = () => {
		setIsImportShow(true);
	};

	const handleImport = (importData) => {
		if (!importData) {
			toast.error("模板導入錯誤");
			return;
		}
		setMessageContent(importData.detail);
		toast.success("已成功導入模板");
		setIsImportShow(false);
	};

	const handleCloseImport = () => {
		setIsImportShow(false);
	};

	const handleNewMsg = () => {
		history.push(`${generalConstants.PATH.DATA_SCRAP_DETAIL}/message/add`, {
			from: FROM_DATASCRAP,
			schoolKey: key,
			message: {
				infoType:
					school._id === `school/${schoolDataConstants.specialSchoolId}`
						? messageConstants.DATA.infoType.EB
						: messageConstants.DATA.infoType.SCHOOL,
				schoolId: school._id || "",
				schoolName: school.name[0] || school.name[1] || "",
				schoolLevel: school.level || "",
				schoolSession: school.session ? school.session[0] : "",
				schoolDistrict: school.district || "",
				schoolVipOnly: school.vipOnly || false,
				detail: messageContent,
			},
		});
	};

	const handleSubmitButton = () => {
		dispatch(diffAction.updateDiff({ status: selectOption, id: targetDiff._id, username: user.username }));
	};

	const handleDiffsSubmitButton = () => {
		dispatch(
			diffAction.updateAllSelectedDiffs({
				status: diffSelectOption,
				ids: selectedDiff,
				websiteId: targetWebsite._id,
				username: user.username,
			})
		);
	};

	const handleDiffsCompareButton = () => {
		dispatch(
			diffAction.compareDiffs({
				ids: selectedDiff,
			})
		);
	};

	const handleLinkSubmitOnClick = () => {
		dispatch(
			diffAction.updateAllSelectedWebsites({
				status: linkSelectOption,
				ids: selectedLink,
				username: user.username,
				schoolId: key,
			})
		);
	};

	const handleTDOnClick = (header, website) => {
		dispatch({ type: diffConstants.type.RESET_TEMP_DIFF });
		setDisplayDiff("");
		setTargetDiff({});
		setSelectOption("NONE");
		setRightHeader(`連結${header}`);
		handleSelectAllDiffCheckboxOnChange(false);
		setTargetWebsite(website);
		setDisplayLink(website.website);
		setIsCheckLink(website.checkLink);
		dispatch(diffAction.getDifferencesByWebsiteId(website?._id));
	};
	const handleSelectAllCheckboxOnChange = (selected) => {
		if (selected) {
			setSelectedLink(_.map(websites, "_id"));
			if (websites?.length > 0) {
				setIsLinkSelected(true);
			}
		} else {
			setSelectedLink([]);

			setLinkSelectOption("NONE");
			setIsLinkSelected(false);
		}
	};
	const handleSelectAllDiffCheckboxOnChange = (selected) => {
		if (selected) {
			setSelectedDiff(linkDifferences?.map((t) => t._id) || []);
			if (linkDifferences?.length > 0) {
				setIsDiffSelected(true);
			}
		} else {
			setSelectedDiff([]);
			setDiffSelectOption("NONE");
			setIsDiffSelected(false);
		}
	};
	const handleDiffCheckboxOnChange = (item) => {
		let tempSelectedList = [...selectedDiff];
		if (_.includes(tempSelectedList, item._id)) {
			_.pull(tempSelectedList, item._id);
		} else {
			tempSelectedList.push(item._id);
		}
		setSelectedDiff(tempSelectedList);
		setIsDiffSelected(tempSelectedList.length > 0);
		if (tempSelectedList?.length === 0) {
			setDiffSelectOption("NONE");
		}
	};
	const handleCheckboxOnChange = (item) => {
		let tempSelectedList = [...selectedLink];
		if (_.includes(tempSelectedList, item._id)) {
			_.pull(tempSelectedList, item._id);
		} else {
			tempSelectedList.push(item._id);
		}
		setSelectedLink(tempSelectedList);
		setIsLinkSelected(tempSelectedList?.length > 0);
		if (tempSelectedList?.length === 0) {
			setLinkSelectOption("NONE");
		}
	};
	const handleSwitchChange = (e) => {
		setSelectedDiff([]);
		setIsDiffSelected(false);
		setIsCompare(e);
	};
	const handleLinkSwitchChange = () => {
		setIsUpdateAlertShow(true);
	};
	const handleTrimmedChange = (e) => {
		setIsTrimmed(e);
		setDisplayDiff(formatDifferenceToHtml(e ? trimOutput(targetDiff?.differences) : targetDiff?.differences));
	};
	const renderStatusSelectBox = (selectOption, handleSelectOnChange, disable, style) => {
		return (
			<MuiSelect
				disabled={disable}
				disableUnderline
				IconComponent={ExpandMore}
				style={style}
				onChange={(e) => handleSelectOnChange(e.target.value)}
				value={selectOption}
				classes={{ icon: classes.iconOverride }}
				id="editStatus"
			>
				<MenuItem value="NONE" style={{ fontSize: 13 }}>
					設置快照為...
				</MenuItem>
				<MenuItem value="OUTSTANDING" style={{ fontSize: 13 }}>
					設置為待處理
				</MenuItem>
				<MenuItem value="PROCESSED" style={{ fontSize: 13 }}>
					設置為已處理
				</MenuItem>
				<MenuItem value="IGNORED" style={{ fontSize: 13 }}>
					設置為忽略
				</MenuItem>
			</MuiSelect>
		);
	};
	const renderDiffStatusSelect = renderStatusSelectBox(diffSelectOption, handleDiffStatusChange, false, {
		paddingLeft: "5px",
		fontSize: "13px",
		backgroundColor: setColor(diffSelectOption),
		color: "#ffffff",
		width: "110px",
		height: "26px",
		borderRadius: "3px",
	});

	const renderStatusSelect = renderStatusSelectBox(selectOption, handleStatusChange, tempSelected, {
		paddingLeft: "5px",
		marginRight: "5px",
		fontSize: "13px",
		backgroundColor: setColor(selectOption),
		color: "#ffffff",
		width: "110px",
		height: "30px",
		borderRadius: "3px",
	});
	const renderLinkStatusSelect = renderStatusSelectBox(linkSelectOption, handleLinkChange, false, {
		paddingLeft: "5px",
		fontSize: "13px",
		backgroundColor: setColor(linkSelectOption),
		color: "#ffffff",
		width: "110px",
		height: "26px",
		borderRadius: "3px",
	});
	const renderWebsitesTD = () => {
		if (websiteList.length > 0) {
			return websiteList.map((website, index) => {
				return (
					<TableRow
						key={`${index}_tr`}
						style={{ backgroundColor: targetWebsite && targetWebsite._id === website._id ? "#eeeeee" : "#ffffff" }}
					>
						<WebsiteTableTd key={`checkBox_${index}_td`} style={{ width: "10px" }}>
							<Checkbox
								style={{ color: "#333399" }}
								checked={_.includes(selectedLink, website._id)}
								onChange={() => {
									handleCheckboxOnChange(website);
								}}
							></Checkbox>
						</WebsiteTableTd>

						<WebsiteTableTd
							key={`name_${index}_td`}
							style={{ verticalAlign: "middle" }}
							type="button"
							onClick={() => {
								handleTDOnClick(index + 1, website);
							}}
						>
							<a
								href={
									website.website.includes("http://") || website.website.includes("https://")
										? website.website
										: `http://${website.website}`
								}
								target="_blank"
								rel="noopener noreferrer"
							>
								<TitleText>{`連結${index + 1}`}</TitleText>
							</a>
						</WebsiteTableTd>

						<WebsiteTableTd
							key={`outstanding_${index}_td`}
							style={{ width: "10px", textAlign: "right" }}
							type="button"
							onClick={() => {
								handleTDOnClick(index + 1, website);
							}}
						>
							{website.hasOutstanding && <ErrorOutline fontSize={"small"} style={{ color: "red" }} />}
						</WebsiteTableTd>
					</TableRow>
				);
			});
		}
	};
	const renderWebsitesTableCell = renderWebsitesTD();

	if (!key) {
		return null;
	}
	return (
		<>
			<AlertDialog
				active={isDeleteAlertShow}
				handleOnClose={handleDeleteOnClose}
				handleOnYes={handleDeleteYes}
				title={`確定移除${selectedLink.length}條連結？`}
			/>
			<AlertDialog
				active={isUpdateAlertShow}
				handleOnClose={handleUpdateOnClose}
				handleOnYes={handleUpdateYes}
				title={`確定${isCheckLink ? "關閉" : "開啟"}比較網頁連結？`}
			/>
			<TemplateImportDialog
				active={isImportShow}
				handleOnClose={handleCloseImport}
				handleOnImport={handleImport}
				variant="lite"
			></TemplateImportDialog>
			<NewDataScrapLinkDialog
				active={isNewLinkShow}
				handleOnClose={handleCloseNewLink}
				schoolName={schoolName}
				schoolId={key}
				username={user.username}
			></NewDataScrapLinkDialog>
			{!!isLoading && <Loading />}
			<PageContainer>
				<LeftContainer>
					<AdminMenubar section={generalConstants.NAV_TAB.DATA_SCRAP.key} />
				</LeftContainer>
				<RightContainer>
					<AdminUserInfo />
					<AdminSectionbar
						title={generalConstants.NAV_TAB.DATA_SCRAP_DETAIL.key}
						sublabel={schoolName}
						bigSublabel={true}
						locationPathArray={[
							{ title: "主頁面", isAction: true, target: generalConstants.PATH.DASHBOARD },
							{
								title: generalConstants.TAB_NAME[generalConstants.NAV_TAB.DATA_SCRAP.key],
								isAction: true,
								target: generalConstants.PATH.DATA_SCRAP,
							},
							{
								title: generalConstants.TAB_NAME[generalConstants.NAV_TAB.DATA_SCRAP_DETAIL.key],
								isAction: false,
								target: "",
							},
						]}
					/>

					<RowInBlock style={{ paddingLeft: "20px", paddingRight: "20px" }}>
						<LeftPageContainer>
							<LeftColumn>
								<LeftPageContainer>
									<Paper elevation={3}>
										<RowInBlock>
											<LeftColumnContainer>
												<ColumnInBlock>
													<ContentLeftBottom>
														<TableLabel>- 抓取連結</TableLabel>
														<HeaderContainer>
															<SelectAllHeader style={{ position: "sticky", top: "0" }}>
																<HeaderContainer>
																	<Checkbox
																		checked={websiteList.length > 0 && selectedLink.length === websiteList.length}
																		style={{ color: "#333399" }}
																		onChange={(e) => {
																			handleSelectAllCheckboxOnChange(e.target.checked);
																		}}
																	/>
																	<HeaderLabel>名稱</HeaderLabel>
																	<SelectContainer style={{ paddingRight: "0px" }}>
																		{isLinkSelected && (
																			<>
																				{renderLinkStatusSelect}
																				<Button
																					color="primary"
																					startIcon={<BinIcon />}
																					style={{ minWidth: "20px", color: "#000000", padding: "0px", paddingLeft: "8px" }}
																					onClick={() => {
																						handleDeleteOnClick();
																					}}
																				/>
																			</>
																		)}
																	</SelectContainer>
																</HeaderContainer>
															</SelectAllHeader>
														</HeaderContainer>
														<ContentList>
															<table style={{ width: "100%", borderCollapse: "collapse" }}>
																<tbody>{renderWebsitesTableCell}</tbody>
															</table>
														</ContentList>
														<ThinRowInBlock>
															<AddButtonContainer>
																<Button
																	color="primary"
																	startIcon={<AddCircleOutline style={{ fontSize: "20px", color: "#000000" }} />}
																	onClick={() => {
																		handleNewButtonOnClick();
																	}}
																>
																	<AddButtonText>新增抓取連結</AddButtonText>
																</Button>
															</AddButtonContainer>
														</ThinRowInBlock>
														<ThinRowInBlock>
															<ButtonContainer>
																{selectedLink.length > 0 && (
																	<SubmitButton
																		label={"確認"}
																		active={linkSelectOption !== "NONE" && !isFetching}
																		style={{
																			padding: "1px 5px",
																			boxShadow: linkSelectOption !== "NONE" ? "0px 3px 1px -2px" : "none",
																			color: linkSelectOption !== "NONE" ? "white" : "#999999",
																		}}
																		handleOnClick={handleLinkSubmitOnClick}
																	/>
																)}
															</ButtonContainer>
														</ThinRowInBlock>
													</ContentLeftBottom>
													<ContentListHeader>
														<TextFieldLabel>- {rightHeader}快照變化紀錄</TextFieldLabel>
														<HeaderContainer>
															<SelectAllHeader style={{ position: "sticky", top: "0" }}>
																<HeaderContainer>
																	<Checkbox
																		disabled={isCompare}
																		style={{ color: "#333399" }}
																		checked={!isCompare && linkDifferences?.length > 0 && selectedDiff?.length === linkDifferences?.length}
																		onChange={(e) => {
																			handleSelectAllDiffCheckboxOnChange(e.target.checked);
																		}}
																	/>
																	<HeaderLabel>時間</HeaderLabel>
																	<SelectContainer>{!isCompare && isDiffSelected && <>{renderDiffStatusSelect}</>}</SelectContainer>
																</HeaderContainer>
															</SelectAllHeader>
														</HeaderContainer>
														<ContentList>
															<table style={{ width: "100%", borderCollapse: "collapse" }}>
																<tbody>{renderTableCell}</tbody>
															</table>
														</ContentList>

														<ThinRowInBlock>
															<AddButtonContainer>
																<Switch
																	handleChange={(e) => {
																		handleSwitchChange(e);
																	}}
																></Switch>

																<AddButtonText>開啟快照比較</AddButtonText>
															</AddButtonContainer>
														</ThinRowInBlock>
														<ThinRowInBlock>
															<ButtonContainer>
																{isCompare
																	? selectedDiff.length > 0 && (
																			<SubmitButton
																				label={"比較"}
																				active={selectedDiff.length === 2 && !isFetching}
																				style={{
																					padding: "1px 5px",
																					boxShadow: selectedDiff.length === 2 ? "0px 3px 1px -2px" : "none",
																					color: selectedDiff.length === 2 ? "white" : "#999999",
																				}}
																				handleOnClick={handleDiffsCompareButton}
																			/>
																	  )
																	: selectedDiff.length > 0 && (
																			<SubmitButton
																				label={"確認"}
																				active={diffSelectOption !== "NONE" && !isFetching}
																				style={{
																					padding: "1px 5px",
																					boxShadow: diffSelectOption !== "NONE" ? "0px 3px 1px -2px" : "none",
																					color: diffSelectOption !== "NONE" ? "white" : "#999999",
																				}}
																				handleOnClick={handleDiffsSubmitButton}
																			/>
																	  )}
															</ButtonContainer>
														</ThinRowInBlock>
													</ContentListHeader>
												</ColumnInBlock>
											</LeftColumnContainer>
											<RightColumnContainer>
												<LeftRightContainer>
													<TextFieldLabel>- 連結快照變化詳情</TextFieldLabel>
													<InfoRow>
														<ContentLinkInfo>
															<DateLabelText>
																更新時間:{_.isEmpty(targetDiff) ? "N/A" : xDate(targetDiff?.dateTime).toString("yyyy年M月dd日 h:mmtt")}
															</DateLabelText>
															<RadioGroup row name="diff" value={dataRadioValue} onChange={(e) => handleDataRadioChange(e)}>
																<FormControlLabel
																	classes={{ label: classes.label }}
																	value="CONTENT"
																	control={<Radio style={{ color: "#333399" }} />}
																	label="內容變化"
																	style={{
																		marginLeft: "10px",
																		color: dataRadioValue === "CONTENT" ? "#333399" : "#555555",
																	}}
																/>
																<FormControlLabel
																	classes={{ label: classes.label }}
																	value="LINK"
																	control={<Radio style={{ color: "#333399" }} />}
																	label="連結變化"
																	style={{
																		color: dataRadioValue === "LINK" ? "#333399" : "#555555",
																	}}
																/>
															</RadioGroup>
															<LinkSwitchContainer>
																<Switch
																	handleChange={() => {
																		handleLinkSwitchChange();
																	}}
																	checked={isCheckLink}
																></Switch>

																<LinkSwitchText>比較網頁連結</LinkSwitchText>
															</LinkSwitchContainer>
														</ContentLinkInfo>

														{!_.isEmpty(targetDiff) && renderStatusSelect}
													</InfoRow>
													<ThinRowInBlock>
														<RightLeftContainer>
															<RightLeftColumnContainer>
																<RightDiffContainer>
																	<RightDiffTitleContainer>
																		<LinkLabelText>連結路徑:</LinkLabelText>
																		<a
																			href={
																				displayLink.includes("http://") || displayLink.includes("https://")
																					? displayLink
																					: `http://${displayLink}`
																			}
																			target="_blank"
																			rel="noopener noreferrer"
																		>
																			<TitleText>{displayLink}</TitleText>
																		</a>
																	</RightDiffTitleContainer>

																	<OuterDiffContainer>
																		<ContentDiff>
																			{detailLoading ? (
																				<CircularProgress />
																			) : dataRadioValue === "CONTENT" ? (
																				renderVariableHtml(displayDiff)
																			) : (
																				changedHrefLinks.map((link, index) => (
																					<div key={index}>
																						{`${changedHrefTexts[index]} `}
																						<a
																							href={link.includes("http://") || link.includes("https://") ? link : `http://${link}`}
																							target="_blank"
																							rel="noopener noreferrer"
																						>
																							<TitleText>{link}</TitleText>
																						</a>
																					</div>
																				))
																			)}
																		</ContentDiff>
																	</OuterDiffContainer>
																</RightDiffContainer>
																<ThinRowInBlock>
																	{!_.isEmpty(targetDiff) && dataRadioValue === "CONTENT" && (
																		<>
																			<RadioGroup row name="diff" value={radioValue} onChange={(e) => handleRadioChange(e, targetDiff)}>
																				<FormControlLabel
																					classes={{ label: classes.label }}
																					value="OLD"
																					control={<Radio style={{ color: "#333399" }} />}
																					label="顯示較舊內容"
																					labelPlacement="top"
																					style={{
																						margin: "0px",
																						marginRight: "10px",
																						color: radioValue === "OLD" ? "#333399" : "#555555",
																					}}
																				/>

																				<RadioLineLeft />
																				<FormControlLabel
																					classes={{ label: classes.label }}
																					value="DIFF"
																					control={<Radio style={{ color: "#333399" }} />}
																					label="顯示變化內容"
																					labelPlacement="top"
																					style={{
																						margin: "0px",
																						marginRight: "10px",
																						color: radioValue === "DIFF" ? "#333399" : "#555555",
																					}}
																				/>
																				<RadioLineRight />
																				<FormControlLabel
																					classes={{ label: classes.label }}
																					value="NEW"
																					control={<Radio style={{ color: "#333399" }} />}
																					label="顯示較新內容"
																					labelPlacement="top"
																					style={{
																						margin: "0px",
																						marginRight: "10px",
																						color: radioValue === "NEW" ? "#333399" : "#555555",
																					}}
																				/>
																			</RadioGroup>

																			{radioValue === "DIFF" && (
																				<TrimmedSwitchContainer>
																					<Switch
																						checked={isTrimmed}
																						handleChange={(e) => {
																							handleTrimmedChange(e);
																						}}
																					></Switch>
																					<AddButtonText>顯示刪減內容</AddButtonText>
																				</TrimmedSwitchContainer>
																			)}
																		</>
																	)}
																	<ButtonContainer>
																		<SubmitButton
																			label={"確認"}
																			active={selectOption !== "NONE" && !_.isEmpty(targetDiff) && !isFetching}
																			style={{
																				boxShadow: selectOption !== "NONE" && !_.isEmpty(targetDiff) ? "0px 3px 1px -2px" : "none",
																				color: selectOption !== "NONE" && !_.isEmpty(targetDiff) ? "white" : "#999999",
																			}}
																			handleOnClick={() => {
																				handleSubmitButton();
																			}}
																		/>
																	</ButtonContainer>
																</ThinRowInBlock>
															</RightLeftColumnContainer>
														</RightLeftContainer>
													</ThinRowInBlock>
												</LeftRightContainer>
											</RightColumnContainer>
										</RowInBlock>
									</Paper>
								</LeftPageContainer>
							</LeftColumn>
						</LeftPageContainer>

						<RightPageContainer>
							<RightColumn>
								<RightPageContainer>
									<Paper elevation={3}>
										<RowInBlock>
											<RightColumnContainer>
												<LeftRightContainer>
													<ThinRowInBlock style={{ marginBottom: 0 }}>
														<TextFieldLabel style={{ marginBottom: "0px" }}>- 信息草案</TextFieldLabel>
													</ThinRowInBlock>
													<ThinRowInBlock style={{ marginBottom: 0 }}>
														<SubmitButtonContainer>
															<Button style={{ marginTop: "13px", padding: "2px 10px" }} onClick={handleOnImport}>
																<img src={ImportIcon} alt="" style={{ paddingRight: "5px" }} />
																導入訊息模板
															</Button>

															<WarningButton
																style={{ marginRight: "10px", marginBottom: "0px", borderRadius: "10", padding: "8px 15.6px 8px 16px" }}
																label={"設置訊息草案"}
																buttonIcon={<DraftMessageIcon />}
																handleOnClick={() => {
																	handleNewMsg();
																}}
															/>
														</SubmitButtonContainer>
													</ThinRowInBlock>
													<ThinRowInBlock>
														<RightRightContainer>
															<RightRightColumnContainer>
																<RightMessageContainer>
																	<TextField
																		multiline
																		rows={29}
																		name="message"
																		value={messageContent}
																		handleChange={handleTextFieldChange}
																	/>
																</RightMessageContainer>
															</RightRightColumnContainer>
														</RightRightContainer>
													</ThinRowInBlock>
												</LeftRightContainer>
											</RightColumnContainer>
										</RowInBlock>
									</Paper>
								</RightPageContainer>
							</RightColumn>
						</RightPageContainer>
					</RowInBlock>
				</RightContainer>
			</PageContainer>
		</>
	);
};

const RowInBlock = styled.div`
	display: flex;
	margin-bottom: ${(props) => props.marginBottom || "10px"};
	flex-direction: row;
`;
const LeftColumn = styled.div`
	display: flex;
	flex-direction: column;
	padding-right: 10px;
	min-width: 700px;
`;
const RightColumn = styled.div`
	display: flex;
	flex-direction: column;
	padding-left: 10px;
	min-width: 500px;
	min-height: 640px;
`;
const ColumnInBlock = styled.div`
	display: flex;
	flex-direction: column;
	padding: 10px;
	padding-top: 0px;
	height: 604px;
	width: 250px;

	border-right: solid 1px #dddddd;
`;

const ThinRowInBlock = styled.div`
	display: flex;
	flex-direction: row;
	margin-bottom: 4px;
`;
const InfoRow = styled.div`
	display: flex;
	flex-direction: row;
	padding-right: 10px;
`;
const OuterDiffContainer = styled.div`
	flex: 3;
	height: 358px;
	padding: 10px;
`;

const ContentLeftBottom = styled.div`
	flex: 1;
	border-bottom: solid 1px #dddddd;
	margin-bottom: 40px;
`;
const ContentLinkInfo = styled.div`
	flex: 1;
	display: flex;
`;
const SelectAllHeader = styled.div`
	margin-right: 10px;
	display: flex;
	flex-direction: row;
	background-color: white;
	border-bottom: solid 2px;
	width: 96%;
`;
const HeaderContainer = styled.div`
	flex: 1;
`;
const HeaderLabel = styled.span`
	width: 10px;
	height: 18px;
	margin: 9px 20px 15px 0px;
	font-size: 12px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.43;
	letter-spacing: normal;
	text-align: left;
	color: #000000;
`;
const ContentListHeader = styled.div`
	flex: 1;
	height: 200px;
`;
const ContentList = styled.div`
	flex: 1;
	height: 135px;
	margin-right: 10px;
	overflow-y: scroll;
	overflow-x: hidden;
`;
const ContentDiff = styled.div`
	flex: 20;
	overflow-y: scroll;
	overflow-x: hidden;
	height: 358px;
`;
const AddButtonContainer = styled.div`
	flex: 1;
	font-size: 14px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	letter-spacing: normal;
	color: #333333;
`;
const LinkSwitchContainer = styled.div`
	font-size: 12px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	letter-spacing: normal;
	color: #333333;
	padding-top: 10px;
`;
const TrimmedSwitchContainer = styled.div`
	flex: 1;
	font-size: 12px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	letter-spacing: normal;
	color: #333333;
	padding-top: 25px;
`;
const ButtonContainer = styled.div`
	flex: 1;
	float: right;
	font-size: 5px;
	font-stretch: normal;
	font-style: normal;
	letter-spacing: normal;
	text-align: right;
	color: #333333;
`;
const SubmitButtonContainer = styled.div`
	flex: 5;
	float: right;
	text-align: right;
`;

const LeftPageContainer = styled.div`
	flex: 2;
	margin-bottom: 10px;
	min-width: 391px;
`;
const RightPageContainer = styled.div`
	flex: 1;
	margin-bottom: 10px;
	min-width: 200px;
	min-height: 630px;
`;

const TextFieldLabel = styled.div`
	color: #333399;
	font-size: 14px;
	line-height: 1.5;
	font-weight: bold;
	margin-bottom: 13px;

	margin-top: 10px;
	width: 200px;
	height: 20px;
`;
const TableLabel = styled.div`
	color: #333399;
	font-size: 14px;
	line-height: 1.5;
	font-weight: bold;
	margin-top: 10px;
	margin-bottom: 5px;
`;
const SelectContainer = styled.div`
	flex: 1;
	float: right;
	padding: 5px;
`;

const TitleText = styled.span`
	color: #0085b5;
	text-decoration: underline;
	font-size: 12px;
`;

const TableRow = styled.tr`
	border-bottom: 1px solid #cccccc;
	&:hover {
		background: #eeeeee;
		cursor: pointer;
	}
`;

const TableTd = styled.td`
	height: 10px;
	font-size: 12px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	letter-spacing: normal;
	text-align: left;
	color: #333333;
`;

const WebsiteTableTd = styled.td`
	height: 10px;
	font-size: 12px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1;
	letter-spacing: normal;
	text-align: left;
	color: #333333;
`;

const LinkLabelText = styled.span`
	font-size: 12px;
	font-weight: 500;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.5;
	letter-spacing: normal;
	text-align: left;
	color: #555555;
`;
const DateLabelText = styled.span`
	font-size: 12px;
	font-weight: 500;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.5;
	letter-spacing: normal;
	text-align: left;
	color: #555555;
	padding-top: 10px;
`;
const AddButtonText = styled.span`
	width: 84px;
	height: 20px;
	font-size: 14px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.43;
	letter-spacing: normal;
	text-align: left;
	color: #000000;
`;
const LinkSwitchText = styled.span`
	width: 84px;
	height: 20px;
	font-size: 12px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.43;
	letter-spacing: normal;
	text-align: left;
	color: #000000;
`;

const RightColumnContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	padding: 5px;
	padding-bottom: 0px;
`;
const LeftColumnContainer = styled.div`
	padding: 5px;
`;
const LeftRightContainer = styled.div`
	flex: 2;
	min-height: 640px;
`;
const RightLeftContainer = styled.div`
	flex: 3;
	height: 358px;
	padding-right: 10px;
`;
const RightDiffTitleContainer = styled.div`
	padding: 5px 42px 5px 10px;
	background-color: #f4f4f4;
`;
const RightDiffContainer = styled.div`
	flex: 3;
	height: 300px;
	border: solid 1px #666666;
	margin-bottom: 10px;
`;
const RightRightContainer = styled.div`
	flex: 2;
	padding: 7px;
`;
const RightLeftColumnContainer = styled.div`
	display: flex;
	flex-direction: column;
	padding: 2px;
`;
const RightRightColumnContainer = styled.div`
	display: flex;
	flex-direction: column;
`;
const RightMessageContainer = styled.div`
	flex: 2;
	padding-bottom: 5px;
`;

const RadioLineLeft = styled.div`
	width: 50px;
	height: 38px;
	left: 530px;
	border-bottom: 1px solid #bcbcbc;
	position: absolute;
`;
const RadioLineRight = styled.div`
	width: 50px;
	height: 38px;
	left: 615px;
	border-bottom: 1px solid #bcbcbc;
	position: absolute;
`;
