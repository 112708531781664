import axios from "axios";

export const adminService = {
	setPassword,
	login,
	logout,
	getAdminList,
	getAdmin,
	createAdmin,
	updateAdmin,
	resendActivationEmail,
	forgetPassword,
	getSchoolList,
	changePassword,
};

const httpOptions = {
	headers: {
		"Content-Type": "application/json",
		"Access-Control-Allow-Origin": "*",
	},
	withCredentials: true,
};

async function setPassword(form) {
	return axios({
		url: `${process.env.REACT_APP_BACKEND_URL}/admin/setPassword`,
		// url: `http://127.0.0.1:5000/admin/setPassword`,
		...httpOptions,
		data: form,
		method: "POST",
	})
		.then(handleResponse)
		.catch(handleError);
}

async function login(form) {
	return axios({
		url: `${process.env.REACT_APP_BACKEND_URL}/admin/login`,
		// url: `http://127.0.0.1:5000/admin/login`,
		...httpOptions,
		data: form,
		method: "POST",
	})
		.then(handleResponse)
		.catch(handleError);
}
async function logout() {
	return axios({
		url: `${process.env.REACT_APP_BACKEND_URL}/admin/logout`,
		// url: `http://127.0.0.1:5000/admin/login`,
		...httpOptions,
		method: "POST",
	})
		.then(handleResponse)
		.catch(handleError);
}

async function getAdminList() {
	return axios({
		url: `${process.env.REACT_APP_BACKEND_URL}/admin`,
		// url: `http://127.0.0.1:5000/admin`,
		...httpOptions,
		method: "GET",
	})
		.then(handleResponse)
		.catch(handleError);
}

async function getAdmin(key) {
	return axios({
		url: `${process.env.REACT_APP_BACKEND_URL}/admin/${key}`,
		// url: `http://127.0.0.1:5000/admin`,
		...httpOptions,
		method: "GET",
	})
		.then(handleResponse)
		.catch(handleError);
}

async function createAdmin(form) {
	return axios({
		url: `${process.env.REACT_APP_BACKEND_URL}/admin/create`,
		// url: `http://127.0.0.1:5000/admin/create`,
		...httpOptions,
		data: form,
		method: "POST",
	})
		.then(handleResponse)
		.catch(handleError);
}

async function updateAdmin(form) {
	return axios({
		url: `${process.env.REACT_APP_BACKEND_URL}/admin/updateAdmin`,
		// url: `http://127.0.0.1:5000/admin/create`,
		...httpOptions,
		data: form,
		method: "POST",
	})
		.then(handleResponse)
		.catch(handleError);
}

async function resendActivationEmail(form) {
	return axios({
		url: `${process.env.REACT_APP_BACKEND_URL}/admin/resendActivationEmail`,
		// url: `http://127.0.0.1:5000/admin/create`,
		...httpOptions,
		data: form,
		method: "POST",
	})
		.then(handleResponse)
		.catch(handleError);
}

async function forgetPassword(form) {
	return axios({
		url: `${process.env.REACT_APP_BACKEND_URL}/admin/forgetPassword`,
		// url: `http://127.0.0.1:5000/admin/create`,
		...httpOptions,
		data: form,
		method: "POST",
	})
		.then(handleResponse)
		.catch(handleError);
}

async function getSchoolList() {
	return axios({
		url: `${process.env.REACT_APP_BACKEND_URL}/school`,
		// url: `http://127.0.0.1:5000/school`,
		...httpOptions,
		method: "GET",
	})
		.then(handleResponse)
		.catch(handleError);
}

async function changePassword(data) {
	return axios({
		url: `${process.env.REACT_APP_BACKEND_URL}/admin/changePassword`,
		...httpOptions,
		data: data,
		method: "POST",
	})
		.then(handleResponse)
		.catch(handleError);
}

function handleError(err) {
	const msg = (err.response && err.response.data.message) || err.message || err;
	throw { error: msg };
}

function handleResponse(res) {
	return res.data;
}
