import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import IconButton from "@material-ui/core/IconButton";
import whatsappLogo from "resources/img/whatsapp_icon.png";
import { parsePhoneNumberWithError, isPossiblePhoneNumber } from "libphonenumber-js";
import validator from "validator";

export const WhatsAppButton = (props) => {
	const { value, style, width, height, countryCode } = props;
	const [whatsAppLink, setWhatsAppLink] = useState();
	const [validURL, setValidURL] = useState(false);
	useEffect(() => {
		if (value) {
			let modifiedUrl = "";
			try {
				// eslint-disable-next-line no-useless-escape
				modifiedUrl = /^[^+](\D*\d){8,}\D*/.test(value.trim()) ? `+${value.trim()}` : value.trim();
				if (isPossiblePhoneNumber(modifiedUrl, countryCode)) {
					const phoneNumber = parsePhoneNumberWithError(modifiedUrl, countryCode);
					modifiedUrl = `https://wa.me/${phoneNumber.number.substring(1)}`;
				} else {
					modifiedUrl = value.trim().startsWith("http") ? value : `https://${value}`;
				}
			} catch (error) {
				modifiedUrl = value.trim().startsWith("http") ? value : `https://${value}`;
			}
			setWhatsAppLink(modifiedUrl);
			setValidURL(validator.isURL(modifiedUrl));
		} else {
			setValidURL(false);
		}
	}, [value, countryCode]);

	if (validURL) {
		return (
			<IconButton
				target="_black"
				href={whatsAppLink}
				size={"small"}
				style={{
					...style,
				}}
			>
				<img alt="" src={whatsappLogo} width={width} height={height} />
			</IconButton>
		);
	} else {
		return null;
	}
};

WhatsAppButton.propTypes = {
	value: PropTypes.string,
	countryCode: PropTypes.string,
	style: PropTypes.object,
	width: PropTypes.number,
	height: PropTypes.number,
};

WhatsAppButton.defaultProps = {
	width: 28,
	height: 28,
	countryCode: "HK",
};
