import React, { useState } from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import ProgressiveRejectDebouncer from "_util/debouncers/ProgressiveRejectDebouncer";

export const AlertDialog = (props) => {
	const { title, description, yesLabel, noLabel, optionLabel, handleOnYes, handleOnClose, handleOnOption, active } = props;
	const [debouncer] = useState(() => new ProgressiveRejectDebouncer(1 * 1000));
	return (
		<Dialog open={active} onClose={handleOnClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
			<DialogTitle id="alert-dialog-title">{title}</DialogTitle>
			{description && (
				<DialogContent>
					<DialogContentText id="alert-dialog-description">{description}</DialogContentText>
				</DialogContent>
			)}
			<DialogActions>
				{handleOnOption && optionLabel && (
					<Button onClick={handleOnOption} color="primary">
						{optionLabel}
					</Button>
				)}
				<Button onClick={handleOnClose} color="primary">
					{noLabel}
				</Button>
				<Button
					onClick={() => {
						if (!debouncer.token()) return;
						handleOnYes();
					}}
					color="primary"
					autoFocus
				>
					{yesLabel}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

AlertDialog.propTypes = {
	title: PropTypes.string,
	description: PropTypes.string,
	yesLabel: PropTypes.string,
	noLabel: PropTypes.string,
	optionLabel: PropTypes.string,
	handleOnYes: PropTypes.func,
	handleOnClose: PropTypes.func,
	handleOnOption: PropTypes.func,
	active: PropTypes.bool,
};

AlertDialog.defaultProps = {
	title: "Title",
	description: "",
	yesLabel: "是",
	noLabel: "否",
	active: true,
};
