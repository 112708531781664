export const parentConstants = {
	type: {
		SUBMIT_PARENT_REQUEST: "SUBMIT_PARENT_REQUEST",
		SUBMIT_PARENT_FAILURE: "SUBMIT_PARENT_FAILURE",
		SUBMIT_PARENT_SUCCESS: "SUBMIT_PARENT_SUCCESS",
		RESET_PARENT_SUCCESS: "RESET_PARENT_SUCCESS",

		FETCH_PARENT_REQUEST: "FETCH_PARENT_REQUEST",
		FETCH_PARENT_FAILURE: "FETCH_PARENT_FAILURE",
		FETCH_PARENT_SUCCESS: "FETCH_PARENT_SUCCESS",

		FETCH_PARENT_LIST_REQUEST: "FETCH_PARENT_LIST_REQUEST",
		FETCH_PARENT_LIST_FAILURE: "FETCH_PARENT_LIST_FAILURE",
		FETCH_PARENT_LIST_SUCCESS: "FETCH_PARENT_LIST_SUCCESS",

		FETCH_PARENT_LIST_FOR_MISSION_REQUEST: "FETCH_PARENT_LIST_FOR_MISSION_REQUEST",
		FETCH_PARENT_LIST_FOR_MISSION_FAILURE: "FETCH_PARENT_LIST_FOR_MISSION_FAILURE",
		FETCH_PARENT_LIST_FOR_MISSION_SUCCESS: "FETCH_PARENT_LIST_FOR_MISSION_SUCCESS",
	},
	TABLE_COLUMN: [
		{ key: "status", value: "狀態", searchable: false, width: "5%" },
		{ key: "isVip", value: "VIP", searchable: false, width: "4%" },
		{ key: "nickname", value: "用戶名稱", searchable: true, width: "15%" },
		{ key: "email", value: "電郵", searchable: true, width: "15%" },
		{ key: "coinBalance", value: "KOT幣", searchable: false, width: "5%" },
		{ key: "completedMission", value: "完成任務次數", searchable: false, width: "5%" },
		{ key: "lastLoginDateTime", value: "最後登入時間", searchable: false, width: "10%" },
		{ key: "createdDate", value: "開戶時間", searchable: false, width: "10%" },
		{ key: "hasSocialAccount", value: "童行網", searchable: false, width: "8%" },
	],
	DATA: {
		status: {
			ACTIVE: { key: "ACTIVE", label: "正常", type: "SUCCESS" },
			BLOCK: { key: "BLOCK", label: "已封鎖", type: "FAILURE" },
			CLOSED: { key: "CLOSED", label: "關閉", type: "PENDING" },
			UNACTIVATE: { key: "UNACTIVATE", label: "未激活", type: "PENDING" },
		},
		socialStatus: {
			VERIFIED: { key: "VERIFIED", label: "已驗証", type: "SUCCESS" },
			UNVERIFIED: { key: "UNVERIFIED", label: "未驗証", type: "PENDING" },
			NONE: { key: "NONE", label: "未加入", type: "FAILURE" },
		},
		sex: {
			MALE: { key: "male", label: "男" },
			FEMALE: { key: "female", label: "女" },
		},
		accountType: {
			PERSONAL: { key: "PERSONAL", label: "個人" },
			CORPORATE: { key: "CORPORATE", label: "機構" },
		},
		privateStatus: {
			PRIVATE: { key: "PRIVATE", label: "不公開" },
			PUBLIC: { key: "PUBLIC", label: "公開" },
		},
	},
	OPTION: {
		isVerified: [
			{ key: "true", value: "已驗証" },
			{ key: "false", value: "未驗証" },
		],
	},
	VIP_STATUS: {
		ACTIVE: "ACTIVE",
		EXPIRED: "EXPIRED",
		CANCELLED: "CANCELLED",
	},
	VIP_SUBSCRIPTION_DURATION: {
		ONE_MONTH: "1m",
		THREE_MONTH: "3m",
		ONE_YEAR: "1y",
	},
	VIP_SUBSCRIPTION_PLAN_NAME: {
		ONE_MONTH: "1個月VIP訂閱計劃",
		THREE_MONTH: "3個月VIP訂閱計劃",
		ONE_YEAR: "1年VIP訂閱計劃",
	},
	signInMethod: {
		GOOGLE: "GOOGLE",
		EMAIL: "EMAIL",
		FACEBOOK: "FACEBOOK",
		APPLE: "APPLE",
	},
	VIP_SUBSCRIPTION_STORE: {
		GOOGLE: "PLAY_STORE",
		APPLE: "APP_STORE",
	},
};
