/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useState, useEffect } from "react";
import { Prompt } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { generalConstants, learningCoursesConstants } from "_constants";
import { learningCoursesAction } from "actions";
import { learningCentreService } from "services";
import { history } from "_util/_helper";
import _ from "lodash";
import {
	SubmitButton,
	CancelButton,
	CategoryButton,
	Loading,
	Select,
	TextField,
	NewCategoryDialog,
	CourseImageUploader,
	Checkbox,
	SquareCheckbox,
} from "components/general";
import { MuiPickersUtilsProvider, KeyboardTimePicker } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import Button from "@material-ui/core/Button";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import moment from "moment";
import isEqual from "react-fast-compare";
import { AddCircleOutline, Close, ArrowDropDown, ArrowRight, DeleteOutline } from "@material-ui/icons";
import { IconButton } from "@material-ui/core";
import { AsyncPaginate } from "react-select-async-paginate";

import { AdminMenubar, AdminUserInfo, AdminSectionbar, AdminFooter } from "components/admin";
import { PageContainer, LeftContainer, RightContainer, ContentContainer } from "components/layout";

const DEFAULT_LIMIT = 10;
const DEFAULT_PAGE = 1;
const FROM_CENTER = "learningCenter";
const FROM_COURSE = "course";
const FROM_ORGANIZATION = "organization";
const week = ["一", "二", "三", "四", "五", "六", "日"];
export const EditCoursePage = () => {
	const location = useLocation();

	const [prevPage, setPrevPage] = useState(location?.state?.from);
	const [cloneCourse, setCloneCourse] = useState(location?.state?.cloneCourse);
	const [key, setCourseKey] = useState(location?.state?.courseKey);
	const [centerKey, setLearningCenterKey] = useState(location?.state?.learningCenterKey);

	const loggedIn = useSelector(({ auth }) => auth.loggedIn);
	const { centre } = useSelector(({ centre }) => {
		return { centre: centre.centre };
	}, isEqual);
	const { organizationObj } = useSelector(({ organization }) => {
		return { organizationObj: prevPage === FROM_ORGANIZATION ? organization.organization : null };
	}, isEqual);
	const isFetchError = useSelector(({ course }) => course.isFetchError);
	const isFetching = useSelector(({ course }) => course.isFetching);
	const createLearningCourseSuccess = useSelector(({ course }) => course.createLearningCourseSuccess);
	const updateLearningCourseSuccess = useSelector(({ course }) => course.updateLearningCourseSuccess);
	const course = useSelector(({ course }) => {
		return key ? course.course : null;
	});

	const initForm = {
		key: "",
		createdBy: "",
		createdTime: "",
		content: "",
		category: [],
		coursePhoto: [],
		coverPhoto: [],
		curriculum: "",
		district: "",
		fee: undefined,
		feeBase: "",
		isOnline: false,
		lastUpdateTime: "",
		learningCenterKey: "",
		maxAge: undefined,
		minAge: undefined,
		name: "",
		nameEng: "",
		status: learningCoursesConstants.OPTION.status[0].key,
		tutor: "",
		venue: "",
		schedule: {
			1: {
				isShow: false,
				list: [],
			},
			2: {
				isShow: false,
				list: [],
			},
			3: {
				isShow: false,
				list: [],
			},
			4: {
				isShow: false,
				list: [],
			},
			5: {
				isShow: false,
				list: [],
			},
			6: {
				isShow: false,
				list: [],
			},
			7: {
				isShow: false,
				list: [],
			},
		},
	};

	const getRoundedDate = (minutes, d = new Date()) => {
		let roundedDate = d.setMinutes(minutes);
		return roundedDate;
	};

	const defaultSelectSchedule = {
		weekday: {
			1: { isSelect: false },
			2: { isSelect: false },
			3: { isSelect: false },
			4: { isSelect: false },
			5: { isSelect: false },
			6: { isSelect: false },
			7: { isSelect: false },
		},
		startTime: getRoundedDate(0, new Date()),
		endTime: getRoundedDate(30, new Date()),
	};

	const [formData, setFormData] = useState(initForm);
	const [isLoading, setIsLoading] = useState(false);
	const [isChanged, setIsChanged] = useState(false);
	const [isSaved, setIsSaved] = useState(false);
	const [isNewCategoryShow, setIsNewCategoryShow] = useState(false);
	const [selectSchedule, setSelectSchedule] = useState(defaultSelectSchedule);
	const [selectedValue, setSelectedValue] = useState(null);

	const dispatch = useDispatch();

	useEffect(() => {
		setPrevPage(location?.state?.from);
		setCloneCourse(location?.state?.cloneCourse);
		setCourseKey(location?.state?.courseKey);
		setLearningCenterKey(location?.state?.learningCenterKey);
	}, [location?.state]);

	useEffect(() => {
		if (key) {
			dispatch(learningCoursesAction.getLearningCourseByKey(key));
		}
	}, [key]);

	useEffect(() => {
		if (isFetchError) {
			dispatch({ type: learningCoursesConstants.type.RESET_LEARNING_COURSES_SUCCESS });
			history.push({
				pathname:
					prevPage === FROM_ORGANIZATION
						? `${generalConstants.PATH.ORGANIZATION_MANAGEMENT}`
						: prevPage === FROM_CENTER
						? `${generalConstants.PATH.LEARNING_CENTRE_MANAGEMENT}`
						: `${generalConstants.PATH.LEARNING_COURSE_MANAGEMENT}`,
			});
		}
	}, [isFetchError]);

	useEffect(() => {
		if (centerKey && centre) {
			setFormData({ ...formData, district: centre.district });
		}
	}, [centerKey]);

	useEffect(() => {
		if (course || cloneCourse) {
			const currentCourse = cloneCourse ? cloneCourse : course ? course : null;
			if (currentCourse != null) {
				var newSchedule = initForm.schedule;
				if (currentCourse.timeslot) {
					if (Object.keys(currentCourse.timeslot).length > 0) {
						Object.keys(currentCourse.timeslot).forEach((day) => {
							var timeList = [];
							currentCourse.timeslot[day].forEach((item) => {
								timeList.push(item);
							});
							newSchedule = {
								...newSchedule,
								[`${day}`]: {
									isShow: true,
									list: timeList,
								},
							};
						});
					}
				}
				const data = {
					key: currentCourse._key,
					rev: currentCourse._rev,
					createdBy: currentCourse.createdBy || "",
					createdTime: currentCourse.createdTime || "",
					content: currentCourse.content || "",
					category: currentCourse.category || [],
					coursePhoto: currentCourse.coursePhoto || [],
					coverPhoto: currentCourse.coverPhoto || [],
					curriculum: currentCourse.curriculum || "",
					district: currentCourse.district || "",
					fee: currentCourse.fee || undefined,
					feeBase: currentCourse.feeBase || "",
					isOnline: currentCourse.isOnline || false,
					lastUpdateTime: currentCourse.lastUpdateTime || "",
					learningCenterKey: currentCourse.learningCenterKey || "",
					maxAge: currentCourse.maxAge || undefined,
					minAge: currentCourse.minAge || undefined,
					name: currentCourse.name || "",
					nameEng: currentCourse.nameEng || "",
					status: currentCourse.status || "",
					tutor: currentCourse.tutor || "",
					venue: currentCourse.venue || "",
					schedule: newSchedule || {},
				};
				setSelectedValue({
					value: currentCourse.learningCenterKey,
					name: currentCourse.learningCenterName,
					district: currentCourse.district,
				});
				setFormData(data);
				setIsChanged(!_.isEmpty(cloneCourse));
			}
		}
	}, [course, cloneCourse]);

	useEffect(() => {
		setIsLoading(isFetching);
	}, [isFetching]);

	useEffect(() => {
		if (updateLearningCourseSuccess) {
			if (!isSaved) {
				setIsSaved(true);
				return;
			}
			dispatch({ type: learningCoursesConstants.type.RESET_LEARNING_COURSES_SUCCESS });
			toast.success("已成功更新");
			handleReplace();
		}
	}, [updateLearningCourseSuccess, isSaved, dispatch]);

	useEffect(() => {
		if (createLearningCourseSuccess) {
			if (!isSaved) {
				setIsSaved(true);
				return;
			}
			dispatch({ type: learningCoursesConstants.type.RESET_LEARNING_COURSES_SUCCESS });
			toast.success("已成功新增");
			handleReplace();
		}
	}, [createLearningCourseSuccess, isSaved, dispatch]);

	const handleReplace = () => {
		if (key) {
			history.replace({
				pathname:
					prevPage === FROM_ORGANIZATION
						? `${generalConstants.PATH.ORGANIZATION}/learningCenter/course`
						: prevPage === FROM_CENTER
						? `${generalConstants.PATH.LEARNING_CENTER}/course`
						: `${generalConstants.PATH.LEARNING_COURSE}`,
				state:
					prevPage === FROM_ORGANIZATION
						? { from: prevPage, organizationKey: organizationObj._key, learningCenterKey: centre._key, courseKey: key }
						: prevPage === FROM_CENTER
						? { from: prevPage, learningCenterKey: centre._key, courseKey: key }
						: { from: prevPage, courseKey: key },
			});
		} else {
			history.replace(
				prevPage != FROM_ORGANIZATION && prevPage != FROM_CENTER
					? generalConstants.PATH.LEARNING_COURSE_MANAGEMENT
					: {
							pathname:
								prevPage === FROM_ORGANIZATION
									? `${generalConstants.PATH.ORGANIZATION}/learningCenter/`
									: generalConstants.PATH.LEARNING_CENTER,
							state:
								prevPage === FROM_ORGANIZATION
									? { from: prevPage, organizationKey: organizationObj._key, learningCenterKey: centre._key }
									: { from: prevPage, learningCenterKey: centre._key },
					  }
			);
		}
	};

	const handleChange = ({ key, value }) => {
		let tempData = { ...formData };
		if (key === "isOnline") {
			tempData[key] = !formData.isOnline;
			if (!formData.isOnline) {
				tempData[`venue`] = "";
				tempData[`district`] = "";
			}
		} else if (key === "maxAge" || key === "minAge" || key === "fee") {
			tempData[key] = value === "" ? undefined : value;
		} else {
			tempData[key] = value;
		}
		setFormData(tempData);
		setIsChanged(true);
	};

	const handleCheckBoxSelect = ({ key }) => {
		const day = key.split("_")[1];
		setSelectSchedule({
			...selectSchedule,
			weekday: {
				...selectSchedule.weekday,
				[`${day}`]: {
					isSelect: !selectSchedule.weekday[day].isSelect,
				},
			},
		});
	};

	const handleCoverChangeImage = (file) => {
		setFormData({ ...formData, coverPhoto: [file] });
		setIsChanged(true);
	};

	const handleCoverClearImage = () => {
		setFormData({ ...formData, coverPhoto: [] });
		setIsChanged(true);
	};

	const handleCourseChangeImage = (file) => {
		setFormData({ ...formData, coursePhoto: formData.coursePhoto.length > 0 ? [...formData.coursePhoto, file] : [file] });
		setIsChanged(true);
	};

	const handleCourseClearImage = (index) => {
		let newPhotoList = [];
		if (formData.coursePhoto.length > 1) {
			formData.coursePhoto.forEach((item, zIndex) => {
				if (index != zIndex) {
					newPhotoList.push(item);
				}
			});
		}
		setFormData({ ...formData, coursePhoto: newPhotoList });
		setIsChanged(true);
	};

	const handleNewButtonOnClick = () => {
		setIsNewCategoryShow(true);
	};

	const handleCloseNewCategory = () => {
		setIsNewCategoryShow(false);
	};

	const handleSubmitNewCategory = (value) => {
		setFormData({ ...formData, category: value });
		setIsChanged(true);
	};

	const handleRemoveCategory = (value) => {
		if (formData.category.length > 0) {
			setFormData({
				...formData,
				category: formData.category.filter((category) => {
					return category.trim() != value.trim();
				}),
			});
			setIsChanged(true);
		}
	};

	const handleBack = () => {
		history.goBack();
	};

	const handleSubmit = () => {
		let newSchedule = [];
		Object.keys(formData.schedule).forEach((day) => {
			formData.schedule[day].list.forEach((item) => {
				newSchedule.push({ weekday: week[day - 1], startTime: item.startTime, endTime: item.endTime });
			});
		});
		var data = {
			key: formData.key,
			rev: formData.rev,
			content: formData.content,
			category: JSON.stringify(formData.category)
				.replace(/[\]\\["]/g, "")
				.trim(),
			curriculum: formData.curriculum,
			district: formData.district,
			feeBase: formData.feeBase,
			isOnline: formData.isOnline,
			name: formData.name,
			nameEng: formData.nameEng,
			status: formData.status,
			tutor: formData.tutor,
			venue: formData.venue,
			schedule: newSchedule,
			learningCenterKey: centerKey ? centerKey || "" : course ? course.learningCenterKey || "" : "",
		};
		if (typeof formData.fee != "undefined") {
			data = {
				...data,
				fee: formData.fee,
			};
		}
		if (typeof formData.maxAge != "undefined") {
			data = {
				...data,
				maxAge: formData.maxAge,
			};
		}
		if (typeof formData.minAge != "undefined") {
			data = {
				...data,
				minAge: formData.minAge,
			};
		}
		if (prevPage === FROM_COURSE) {
			data = {
				...data,
				learningCenterKey: selectedValue != null ? selectedValue.value || "" : "",
			};
		}

		let newData = data;
		if (formData.coursePhoto.length > 0) {
			const stringList = formData.coursePhoto.filter((item) => typeof item === "string");
			const fileList = formData.coursePhoto.filter((item) => typeof item != "string");
			fileList.forEach((photo, index) => {
				newData = {
					...newData,
					[`coursePhoto${index}`]: photo,
				};
			});
			newData = {
				...newData,
				coursePhoto: stringList,
			};
		} else {
			newData = {
				...newData,
				coursePhoto: [],
			};
		}
		if (formData.coverPhoto.length > 0) {
			if (typeof formData.coverPhoto[0] === "string") {
				newData = {
					...newData,
					coverPhoto: formData.coverPhoto,
				};
			} else {
				newData = {
					...newData,
					coverPhoto: [],
					[`coverPhoto0`]: formData.coverPhoto[0],
				};
			}
		} else {
			newData = {
				...newData,
				coverPhoto: [],
			};
		}

		if (key && !cloneCourse) {
			dispatch(learningCoursesAction.updateLearningCourse(newData));
		} else {
			dispatch(learningCoursesAction.createLearningCourse(newData));
		}
	};

	const renderCategory = () => {
		return formData.category.map((item, index) => {
			const itemText = item.trim();
			return (
				<div key={`${itemText}_${index}_category_item`} style={{ marginRight: "5px", marginBottom: "5px" }}>
					<CategoryButton
						label={item}
						buttonIcon={
							<IconButton
								style={{ paddingTop: 5, paddingBottom: 5 }}
								onClick={() => {
									handleRemoveCategory(item);
								}}
							>
								<Close style={{ fontSize: "16px", color: "#FFFFFF" }} />
							</IconButton>
						}
					/>
				</div>
			);
		});
	};
	const renderCategoryList = renderCategory();

	const getLocationPath = () => {
		const pathArray = [];

		pathArray.push({ title: "主頁面", isAction: true, target: generalConstants.PATH.DASHBOARD });
		pathArray.push({
			title:
				generalConstants.TAB_NAME[
					prevPage === FROM_COURSE
						? generalConstants.NAV_TAB.LEARNING_COURSE_MANAGEMENT.key
						: prevPage === FROM_CENTER
						? generalConstants.NAV_TAB.LEARNING_CENTRE_MANAGEMENT.key
						: generalConstants.NAV_TAB.ORGANIZATION_MANAGEMENT.key
				],
			isAction: true,
			target:
				prevPage === FROM_COURSE
					? generalConstants.PATH.LEARNING_COURSE_MANAGEMENT
					: prevPage === FROM_CENTER
					? generalConstants.PATH.LEARNING_CENTRE_MANAGEMENT
					: generalConstants.PATH.ORGANIZATION_MANAGEMENT,
		});
		if (prevPage === FROM_ORGANIZATION && organizationObj) {
			pathArray.push({
				title: organizationObj.name || "",
				isAction: true,
				target: generalConstants.PATH.ORGANIZATION,
				stateObj: { from: prevPage, organizationKey: organizationObj._key },
			});
		}
		if (prevPage === FROM_CENTER || prevPage === FROM_ORGANIZATION) {
			pathArray.push({
				title: _.isEmpty(centre.name) ? centre.nameEng || "" : centre.name || "",
				isAction: true,
				target:
					prevPage === FROM_ORGANIZATION
						? `${generalConstants.PATH.ORGANIZATION}/learningCenter/`
						: `${generalConstants.PATH.LEARNING_CENTER}`,
				stateObj:
					prevPage === FROM_ORGANIZATION
						? { from: prevPage, organizationKey: organizationObj._key, learningCenterKey: centre._key }
						: { from: prevPage, learningCenterKey: centre._key },
			});
		}
		if (key && course && !cloneCourse) {
			pathArray.push({
				title: course && course.name && !_.isEmpty(course.name) ? course.name || "-" : course.nameEng || "-",
				isAction: true,
				target:
					prevPage === FROM_ORGANIZATION
						? `${generalConstants.PATH.ORGANIZATION}/learningCenter/course`
						: prevPage === FROM_CENTER
						? `${generalConstants.PATH.LEARNING_CENTER}/course`
						: `${generalConstants.PATH.LEARNING_COURSE}`,
				stateObj:
					prevPage === FROM_ORGANIZATION
						? { from: prevPage, organizationKey: organizationObj._key, learningCenterKey: centre._key, courseKey: key }
						: prevPage === FROM_CENTER
						? { from: prevPage, learningCenterKey: centre._key, courseKey: key }
						: { from: prevPage, courseKey: key },
			});
		}
		pathArray.push({
			title:
				generalConstants.TAB_NAME[
					key && !cloneCourse ? generalConstants.NAV_TAB.EDIT_LEARNING_COURSE.key : generalConstants.NAV_TAB.CREATE_LEARNING_COURSE.key
				],
			isAction: false,
			target: "",
		});
		return pathArray;
	};

	// handle selection
	const handleSelectChange = (value) => {
		setIsChanged(true);
		setSelectedValue(value);
		if (!formData.isOnline && value != null) {
			setFormData({ ...formData, district: value.district });
		}
	};

	const loadOptions = async (searchQuery, loadedOptions, { page }) => {
		const response = await learningCentreService.getLearningCenters({
			search: searchQuery,
			l: DEFAULT_LIMIT,
			p: page,
			sort: `score:DESC`,
		});
		if (response && response.items) {
			var hasMore = true;
			var optionList = response.items.map((i) => ({
				value: `${i._key}`,
				name: !_.isEmpty(i.name) ? i.name || "" : i.nameEng || "",
				district: i.district,
			}));
			if (_.isEmpty(searchQuery) && page === 1 && course) {
				if (course.learningCenterKey != null) {
					optionList = [
						{
							value: course.learningCenterKey,
							name: course.learningCenterName,
							district: course.district,
						},
						...optionList.filter((item) => item.value != course.learningCenterKey),
					];
				}
			}
			if (loadedOptions.length >= response.totalItems || optionList.length === response.totalItems) {
				hasMore = false;
			}
			return {
				options: optionList,
				hasMore: hasMore,
				additional: {
					page: page + 1,
				},
			};
		}
		return {
			options: [],
			hasMore: false,
			additional: {
				page: page,
			},
		};
	};

	if ((!key && location.pathname.includes("/edit")) || (!prevPage && location.pathname.includes("/add"))) {
		return null;
	}
	return (
		<>
			<NewCategoryDialog
				category={formData.category || []}
				active={isNewCategoryShow}
				handleOnSubmit={handleSubmitNewCategory}
				handleOnClose={handleCloseNewCategory}
			/>
			{!!isLoading && <Loading />}
			<Prompt when={isChanged && !isSaved && loggedIn} message="你有未儲存的變更，確定不儲存下離開？" />
			<PageContainer>
				<LeftContainer>
					<AdminMenubar
						section={
							prevPage === FROM_CENTER
								? generalConstants.NAV_TAB.LEARNING_CENTRE_MANAGEMENT.key
								: prevPage === FROM_COURSE
								? generalConstants.NAV_TAB.LEARNING_COURSE_MANAGEMENT.key
								: generalConstants.NAV_TAB.ORGANIZATION_MANAGEMENT.key
						}
					/>
				</LeftContainer>
				<RightContainer>
					<AdminUserInfo />
					<AdminSectionbar
						title={
							key && !cloneCourse ? generalConstants.NAV_TAB.EDIT_LEARNING_COURSE.key : generalConstants.NAV_TAB.CREATE_LEARNING_COURSE.key
						}
						locationPathArray={getLocationPath()}
					/>
					<ContentContainer style={{ display: "flex", flexDirection: "row", padding: "0px" }}>
						<ContentLeft>
							<Block>
								{prevPage === FROM_COURSE ? (
									<BlockGroup>
										<div style={{ marginRight: "10px", width: "50%" }}>
											<TextFieldLabel>課程名稱 (中文)</TextFieldLabel>
											<TextField placeholder="課程名稱 (中文)" value={formData.name || ""} name="name" handleChange={handleChange} />
										</div>
										<div style={{ width: "50%" }}>
											<TextFieldLabel>補習社</TextFieldLabel>
											<>
												<AsyncPaginate
													isClearable
													value={selectedValue}
													loadOptions={loadOptions}
													getOptionValue={(option) => option.name}
													getOptionLabel={(option) => option.name}
													onChange={handleSelectChange}
													placeholder="請選擇"
													additional={{
														page: DEFAULT_PAGE,
													}}
												/>
											</>
										</div>
									</BlockGroup>
								) : (
									<div>
										<TextFieldLabel>課程名稱 (中文)</TextFieldLabel>
										<TextField placeholder="課程名稱 (中文)" value={formData.name || ""} name="name" handleChange={handleChange} />
									</div>
								)}
								<TextFieldLabel style={{ marginTop: "10px" }}>Course Name (English)</TextFieldLabel>
								<TextField placeholder="Course Name (English)" value={formData.nameEng || ""} name="nameEng" handleChange={handleChange} />
							</Block>
							<BlockGroup>
								<Block>
									<TextFieldLabel>課程費用</TextFieldLabel>
									<BlockGroup>
										<TextField placeholder="費用" value={formData.fee} name="fee" handleChange={handleChange} />
										<TextFieldLabel style={{ marginLeft: "10px", marginRight: "10px", marginTop: "8px" }}>/</TextFieldLabel>
										<TextField value={`${formData.feeBase}`} name="feeBase" handleChange={handleChange} />
									</BlockGroup>
								</Block>
								<Block>
									<TextFieldLabel>課程導師</TextFieldLabel>
									<TextField placeholder="課程導師" value={formData.tutor || ""} name="tutor" handleChange={handleChange} />
								</Block>
							</BlockGroup>
							<BlockGroup>
								<Block>
									<TextFieldLabel>(最小年紀/歲)</TextFieldLabel>
									<TextField placeholder="(最小年紀/歲)" value={formData.minAge} name="minAge" handleChange={handleChange} />
								</Block>
								<TextFieldLabel style={{ paddingTop: "45px" }}>~</TextFieldLabel>
								<Block>
									<TextFieldLabel>(最大年紀/歲)</TextFieldLabel>
									<TextField placeholder="(最大年紀/歲)" value={formData.maxAge} name="maxAge" handleChange={handleChange} />
								</Block>
							</BlockGroup>
							<BlockGroup>
								<Block>
									<TextFieldLabel>課程舉行地點</TextFieldLabel>
									<TextField
										placeholder="課程舉行地點"
										value={formData.venue || ""}
										name="venue"
										handleChange={handleChange}
										disabled={formData.isOnline}
									/>
								</Block>
								<Block>
									<TextFieldLabel>地區</TextFieldLabel>
									<>
										<Select
											name={"district"}
											options={learningCoursesConstants.OPTION.district}
											value={formData.district}
											handleChange={handleChange}
											disabled={formData.isOnline}
										/>
									</>
								</Block>
								<Block style={{ paddingTop: "35px" }}>
									<>
										<Checkbox name={"isOnline"} label={"網上課程"} checked={formData.isOnline} handleChange={handleChange} />
									</>
								</Block>
							</BlockGroup>
							<Block>
								<TextFieldLabel>課程特色</TextFieldLabel>
								<TextField
									placeholder="課程特色"
									multiline
									rows={6}
									value={formData.curriculum}
									name="curriculum"
									handleChange={handleChange}
								/>
							</Block>
						</ContentLeft>
						<LineRight />
						<ContentLeft>
							<BlockGroup>
								<Block>
									<TextFieldLabel>課程內容</TextFieldLabel>
									<TextField
										placeholder="課程內容"
										multiline
										rows={6}
										value={formData.content}
										name="content"
										handleChange={handleChange}
									/>
								</Block>
							</BlockGroup>
							<Block>
								<TextFieldLabel>課程週期</TextFieldLabel>
								<SubTitleLabel>請選擇並添加到列表中</SubTitleLabel>
								<BlockGroup style={{ flex: 1, flexWrap: "wrap" }}>
									{Object.keys(selectSchedule.weekday).map((day, index) => {
										return (
											<div
												key={`week_checkbox_${index}`}
												style={{
													color: "#33333",
													paddingTop: 0,
													paddingBottom: 0,
													paddingLeft: 5,
												}}
											>
												<SquareCheckbox
													key={`week_${day}_checkbox_${index}`}
													name={`weekday_${day}`}
													label={`星期${week[day - 1]}`}
													checked={!selectSchedule.weekday[day].isSelect}
													handleChange={handleCheckBoxSelect}
												/>
											</div>
										);
									})}
								</BlockGroup>
								<BlockGroup>
									<MuiPickersUtilsProvider utils={MomentUtils}>
										<KeyboardTimePicker
											margin="dense"
											id="time-picker-startTime"
											value={selectSchedule.startTime}
											inputVariant="outlined"
											format="HH:mm"
											onChange={(date) => {
												setSelectSchedule({
													...selectSchedule,
													startTime: date._d,
												});
											}}
										/>
									</MuiPickersUtilsProvider>
									<TextFieldLabel style={{ paddingTop: "18px", marginLeft: "10px", marginRight: "10px" }}>~</TextFieldLabel>
									<MuiPickersUtilsProvider utils={MomentUtils}>
										<KeyboardTimePicker
											margin="dense"
											id="time-picker-endTime"
											value={selectSchedule.endTime}
											inputVariant="outlined"
											format="HH:mm"
											onChange={(date) => {
												setSelectSchedule({
													...selectSchedule,
													endTime: date._d,
												});
											}}
										/>
									</MuiPickersUtilsProvider>
									<div style={{ marginLeft: "10px", marginTop: "12px", width: "20%" }}>
										<Button
											style={{ backgroundColor: "#8f96a2", width: "20%" }}
											onClick={() => {
												if (selectSchedule && selectSchedule.weekday && selectSchedule.startTime && selectSchedule.endTime) {
													var newSchedule = { ...formData.schedule };

													if (moment(selectSchedule.startTime).diff(moment(selectSchedule.endTime), "second") > 0) {
														toast.error("開始時間不能大於結束時間");
														return;
													} else if (moment(selectSchedule.startTime).diff(moment(selectSchedule.endTime), "second") === 0) {
														toast.error("開始時間不能與結束時間一樣");
														return;
													} else {
														var isSame = false;
														var notChange = true;
														Object.keys(selectSchedule.weekday).forEach((day) => {
															if (selectSchedule.weekday[day].isSelect) {
																notChange = false;
																newSchedule[day].list.forEach((zItem) => {
																	if (
																		zItem.startTime === moment(selectSchedule.startTime).format("HH:mm") &&
																		zItem.endTime === moment(selectSchedule.endTime).format("HH:mm")
																	) {
																		isSame = true;
																	}
																});
															}
														});
														Object.keys(selectSchedule.weekday).forEach((day) => {
															if (selectSchedule.weekday[day].isSelect) {
																if (!isSame) {
																	newSchedule[day].list.push({
																		startTime: moment(selectSchedule.startTime).format("HH:mm"),
																		endTime: moment(selectSchedule.endTime).format("HH:mm"),
																	});
																	newSchedule[day].list = _.sortBy(newSchedule[day].list, ["startTime", "endTime"]);
																	newSchedule[day].isShow = true;
																}
															}
														});
														if (notChange) {
															toast.error("請選擇星期");
															return;
														}
														if (isSame) {
															toast.error("已存在這個組合");
															return;
														}

														setFormData({
															...formData,
															schedule: newSchedule,
														});
														setIsChanged(true);
													}
												} else {
													toast.error("請選擇並完善課程週期");
													return;
												}
											}}
										>
											<AddButtonText style={{ textAlign: "center", color: "#FFFFFF" }}>添加</AddButtonText>
										</Button>
									</div>
								</BlockGroup>
								<LineTop />
								{formData.schedule && (
									<div>
										{Object.keys(formData.schedule).map((day, index) => {
											return (
												<div key={`${day}_${index}_schedule`}>
													<Button
														style={{ height: "24px", width: "100%", justifyContent: "flex-start" }}
														variant="text"
														startIcon={formData.schedule[day].isShow ? <ArrowDropDown /> : <ArrowRight />}
														onClick={() => {
															var newSchedule = formData.schedule;
															newSchedule[day].isShow = !newSchedule[day].isShow;
															setFormData({
																...formData,
																schedule: newSchedule,
															});
														}}
													>
														<SubTitleLabel style={{ textAlign: "left", marginTop: "8px" }}>{`星期${week[day - 1]}`}</SubTitleLabel>
													</Button>
													{formData.schedule && formData.schedule[day].isShow && (
														<div>
															{formData.schedule[day].list.map((item, index) => {
																return (
																	<div key={`time${day}_${index}`}>
																		<Button
																			style={{ height: "24px", width: "100%", justifyContent: "flex-end" }}
																			variant="text"
																			endIcon={<DeleteOutline />}
																			onClick={() => {
																				const currentList = formData.schedule[day].list.filter(
																					(zItem) => zItem.startTime !== item.startTime || zItem.endTime !== item.endTime
																				);
																				var newSchedule = formData.schedule;
																				newSchedule[day].list = currentList;
																				if (currentList.length === 0) {
																					newSchedule[day].isShow = false;
																				}
																				setFormData({
																					...formData,
																					schedule: newSchedule,
																				});
																				setIsChanged(true);
																			}}
																		>
																			<SubTitleLabel
																				style={{ textAlign: "right", marginTop: "8px" }}
																			>{`${item.startTime} - ${item.endTime}`}</SubTitleLabel>
																		</Button>
																	</div>
																);
															})}
														</div>
													)}
												</div>
											);
										})}
									</div>
								)}
							</Block>
						</ContentLeft>
						<LineRight />
						<ContentRight>
							<Block>
								<TextFieldLabel>課程類別</TextFieldLabel>
								{formData.category.length > 0 && <CategoryGroup>{renderCategoryList}</CategoryGroup>}
								<AddButtonContainer>
									<Button
										color="primary"
										startIcon={<AddCircleOutline style={{ fontSize: "20px", color: "#000000" }} />}
										onClick={() => {
											handleNewButtonOnClick();
										}}
									>
										<AddButtonText>新增</AddButtonText>
									</Button>
								</AddButtonContainer>
							</Block>
							<Block>
								<TextFieldLabel>課程頁面照</TextFieldLabel>
								<div>
									<CourseImageUploader
										src={formData.coverPhoto.length > 0 ? formData.coverPhoto[0] : ""}
										handleChange={handleCoverChangeImage}
										handleClear={handleCoverClearImage}
										noImageLabel="沒有圖片"
										isCover={true}
									/>
								</div>
							</Block>
							<Block>
								<TextFieldLabel>課程相關照片/資料/小册子</TextFieldLabel>
								<ScrollView>
									{formData.coursePhoto.length === 0 ? (
										<CourseImageUploader
											src={""}
											handleChange={handleCourseChangeImage}
											handleClear={handleCourseClearImage}
											noImageLabel="沒有圖片"
										/>
									) : (
										<BlockGroup>
											{formData.coursePhoto.map((photo, index) => {
												return (
													<div key={`image_${index}`} style={{ marginRight: "20px", width: "80px", height: "80px" }}>
														<CourseImageUploader
															src={photo}
															handleChange={handleCourseChangeImage}
															handleClear={() => {
																handleCourseClearImage(index);
															}}
															noImageLabel="沒有圖片"
														/>
													</div>
												);
											})}
											<CourseImageUploader src={""} handleChange={handleCourseChangeImage} handleClear={null} noImageLabel="沒有圖片" />
										</BlockGroup>
									)}
								</ScrollView>
							</Block>
							<Block>
								<TextFieldLabel>狀態</TextFieldLabel>
								<>
									<Select
										name={"status"}
										options={learningCoursesConstants.OPTION.status}
										value={formData.status}
										handleChange={handleChange}
									/>
								</>
							</Block>
							<RowInBlock style={{ justifyContent: "flex-end" }}>
								<CancelButton label={"取消"} style={{ marginRight: "15px" }} handleOnClick={handleBack} />
								<SubmitButton
									label={"確認"}
									style={{ marginRight: "15px" }}
									handleOnClick={handleSubmit}
									active={isChanged && !isFetching}
								/>
							</RowInBlock>
						</ContentRight>
					</ContentContainer>
					<AdminFooter></AdminFooter>
				</RightContainer>
			</PageContainer>
		</>
	);
};

const RowInBlock = styled.div`
	padding-top: 30px;
	display: flex;
	flex-direction: row;
`;

const ContentLeft = styled.div`
	flex: 1;
	padding: 10px;
`;

const ContentRight = styled.div`
	flex: 1;
	padding: 10px;
`;

const LineRight = styled.div`
	border-right: 1px solid #dddddd;
	margin-top: 20px;
	margin-bottom: 20px;
`;

const LineTop = styled.div`
	border-top: 1px solid #666666;
	margin-top: 10px;
	margin-bottom: 10px;
`;

const ScrollView = styled.div`
	display: flex;
	overflow-x: scroll;
	width: 300px;
	padding-left: 5px;
	padding-top: 20px;
	padding-bottom: 10px;
`;

const BlockGroup = styled.div`
	display: flex;
	flex-direction: row;
`;

const Block = styled.div`
	padding: 10px;
	flex: ${(props) => props.flex || 1};
`;

const CategoryGroup = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
`;

const TextFieldLabel = styled.div`
	color: #000000;
	font-size: 14px;
	line-height: 1.5;
	font-weight: bold;
	margin-bottom: 7px;
`;

const SubTitleLabel = styled.div`
	color: #555555;
	font-size: 12px;
	line-height: 1.5;
	font-weight: 500;
	margin-bottom: 7px;
`;

const AddButtonContainer = styled.div`
	flex: 1;
	font-size: 14px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	letter-spacing: normal;
	color: #333333;
`;

const AddButtonText = styled.span`
	width: 84px;
	height: 20px;
	font-size: 14px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.43;
	letter-spacing: normal;
	text-align: left;
	color: #000000;
`;
