import axios from "axios";

export const diffService = {
	updateDiff,
	getDataScrapList,
	getDataScrapDetail,
	getDifferencesByWebsiteId,
	getDataScrapDifferenceDetail,
	updateAllSelectedDiffs,
	updateAllSelectedWebsites,
	addDataScrapWebsite,
	updateDataScrapWebsite,
	deleteDataScrapWebsite,
	compareDiffs,
	genAllDiff,
	getAllLog,
	getAllError,
};

const httpOptions = {
	headers: {
		"Content-Type": "application/json",
		"Access-Control-Allow-Origin": "*",
	},
	withCredentials: true,
};
async function updateDiff(form) {
	return axios({
		url: `${process.env.REACT_APP_BACKEND_URL}/datascrap/diff/update`,
		...httpOptions,
		method: "PUT",
		data: form,
	})
		.then(handleResponse)
		.catch(handleError);
}

async function getDataScrapList(form) {
	return axios({
		url: `${process.env.REACT_APP_BACKEND_URL}/datascrap/diff/dataScrap`,
		...httpOptions,
		method: "GET",
		params: form,
	})
		.then(handleResponse)
		.catch(handleError);
}

async function getDataScrapDetail(id) {
	return axios({
		url: `${process.env.REACT_APP_BACKEND_URL}/datascrap/diff/dataScrapDetail/${id}`,
		...httpOptions,
		method: "GET",
	})
		.then(handleResponse)
		.catch(handleError);
}
async function getDifferencesByWebsiteId(id) {
	return axios({
		url: `${process.env.REACT_APP_BACKEND_URL}/datascrap/diff/website/${id}`,
		...httpOptions,
		method: "GET",
	})
		.then(handleResponse)
		.catch(handleError);
}

async function getDataScrapDifferenceDetail(id) {
	return axios({
		url: `${process.env.REACT_APP_BACKEND_URL}/datascrap/diff/detail/${id}`,
		...httpOptions,
		method: "GET",
	})
		.then(handleResponse)
		.catch(handleError);
}

async function updateAllSelectedDiffs(form) {
	return axios({
		url: `${process.env.REACT_APP_BACKEND_URL}/datascrap/diff/updateDiffs`,
		...httpOptions,
		method: "PUT",
		data: form,
	})
		.then(handleResponse)
		.catch(handleError);
}

async function updateAllSelectedWebsites(form) {
	return axios({
		url: `${process.env.REACT_APP_BACKEND_URL}/datascrap/diff/updateWebsites`,
		...httpOptions,
		method: "PUT",
		data: form,
	})
		.then(handleResponse)
		.catch(handleError);
}

async function addDataScrapWebsite(form) {
	return axios({
		url: `${process.env.REACT_APP_BACKEND_URL}/datascrap/website/add`,
		...httpOptions,
		method: "POST",
		data: form,
	})
		.then(handleResponse)
		.catch(handleError);
}

async function updateDataScrapWebsite(form) {
	return axios({
		url: `${process.env.REACT_APP_BACKEND_URL}/datascrap/website/update`,
		...httpOptions,
		method: "PUT",
		data: form,
	})
		.then(handleResponse)
		.catch(handleError);
}

async function deleteDataScrapWebsite(form) {
	return axios({
		url: `${process.env.REACT_APP_BACKEND_URL}/datascrap/website/delete`,
		...httpOptions,
		method: "POST",
		data: form,
	})
		.then(handleResponse)
		.catch(handleError);
}

async function compareDiffs(form) {
	return axios({
		url: `${process.env.REACT_APP_BACKEND_URL}/datascrap/diff/compare`,
		...httpOptions,
		method: "POST",
		data: form,
	})
		.then(handleResponse)
		.catch(handleError);
}

async function genAllDiff() {
	return axios({
		url: `${process.env.REACT_APP_BACKEND_URL}/datascrap/diff/genAllDiff`,
		...httpOptions,
		method: "GET",
	})
		.then(handleResponse)
		.catch(handleError);
}

async function getAllLog() {
	return axios({
		url: `${process.env.REACT_APP_BACKEND_URL}/datascrap/log`,
		...httpOptions,
		method: "GET",
	})
		.then(handleResponse)
		.catch(handleError);
}

async function getAllError() {
	return axios({
		url: `${process.env.REACT_APP_BACKEND_URL}/datascrap/error`,
		...httpOptions,
		method: "GET",
	})
		.then(handleResponse)
		.catch(handleError);
}
function handleError(err) {
	const diff = (err.response && err.response.data.message) || err.message || err;
	throw { error: diff };
}

function handleResponse(res) {
	return res.data;
}
