import DiffMatchPatch from "diff-match-patch";

export const formatDifferenceToHtml = (difference) => {
	const dmp = new DiffMatchPatch();
	const diff = dmp.diff_prettyHtml(difference);
	return diff.replace(/&para;/g, "").replace(/<br><br>/g, "");
};

export const formatDifferenceToOldDataHtml = (differences) => {
	const oldData = differences.filter((t) => t[0] <= 0).map((t) => [0, t[1]]);
	return formatDifferenceToHtml(oldData);
};

export const formatDifferenceToNewDataHtml = (differences) => {
	const oldData = differences.filter((t) => t[0] >= 0).map((t) => [0, t[1]]);
	return formatDifferenceToHtml(oldData);
};

export const trimOutput = (fullOutput) => {
	if (!fullOutput) {
		return [];
	}
	return fullOutput.map((x) => {
		if (x[0] == 0) {
			if (x[1].split("\n").length - 1 > 4) {
				const splitted = x[1].split("\n");
				const newX = [];
				for (let i = 0; i < 2; i++) {
					newX.push(splitted[i] + "\n");
				}
				newX.push("...\n...\n...\n");
				for (let i = splitted.length - 3; i < splitted.length - 1; i++) {
					newX.push(splitted[i] + "\n");
				}
				return [x[0], newX.join("")];
			} else {
				return x;
			}
		}
		return x;
	});
};
