import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import isEqual from "react-fast-compare";

import { learningCoursesConstants, generalConstants } from "_constants";
import { learningCoursesAction } from "actions";
import moment from "moment";
import _ from "lodash";
import { ReactComponent as OrganizationIcon } from "resources/img/organization.svg";

import ProgressiveRejectDebouncer from "_util/debouncers/ProgressiveRejectDebouncer";
import { history } from "_util/_helper";

import { ArrowDropDown, ArrowDropUp } from "@material-ui/icons";

import { Pagination, RefreshButton } from "components/general";
import { StandardSelect, StandardTextField } from "components/general/standard";
import { AdminMenubar, AdminUserInfo, AdminSectionbar, AdminFooter } from "components/admin";
import {
	PageContainer,
	LeftContainer,
	RightContainer,
	ContentContainer,
	SearchBar,
	SearchBarLeft,
	SearchBarRight,
	PaginationContainer,
	TableContainer,
	TableRow,
	TableTh,
	THButton,
	TableTd,
} from "components/layout";

import { ReactComponent as CourseIcon } from "resources/img/course_icon.svg";

const FROM_COURSE = "course";
const DEFAULT_LIMIT = 10;
const DEFAULT_PAGE = 1;
export const CourseManagementPage = () => {
	const { navigationObject } = useSelector(
		({ course }) => ({
			navigationObject: course.navigationObject?.root,
		}),
		isEqual
	);

	const { learningCoursesList } = useSelector(
		({ course }) => ({
			learningCoursesList: course.navigationObject?.root?.learningCoursesList,
		}),
		isEqual
	);

	const totalItems = useSelector(({ course }) => course.navigationObject?.root?.totalItems);
	const currentPage = useSelector(({ course }) => course.navigationObject?.root?.currentPage);
	const numOfPage = useSelector(({ course }) => course.navigationObject?.root?.numOfPage);
	const sortingKey = useSelector(({ course }) => course.navigationObject?.root?.sortingKey);

	const [filterDistrict, setFilterDistrict] = useState(navigationObject?.district || "");
	const [filterCategory, setFilterCategory] = useState(navigationObject?.category || "");
	const [searchWord, setSearchWord] = useState(navigationObject?.searchKey || "");
	const [sortingDirection, setSortingDirection] = useState(navigationObject?.sortingDirection || "");

	const dispatch = useDispatch();
	const timeoutRef = useRef(null);
	const [textDebouncer] = useState(() => new ProgressiveRejectDebouncer(0.7 * 1000));
	const [debouncer] = useState(() => new ProgressiveRejectDebouncer(1 * 1000));

	const handleSorting = (key) => {
		if (!debouncer.token()) {
			return;
		}
		let direction = "DESC";
		if (sortingKey === key && sortingDirection === "DESC") {
			direction = "ASC";
		}
		setSortingDirection(direction);
		let form = {
			l: DEFAULT_LIMIT,
			p: DEFAULT_PAGE,
			sort: `${key}:${direction}`,
		};
		if (navigationObject?.searchKey) {
			form = { ...form, search: navigationObject?.searchKey };
		}
		if (navigationObject?.district) {
			form = navigationObject?.district === "online" ? { ...form, isOnline: true } : { ...form, district: navigationObject?.district };
		}
		if (navigationObject?.category) {
			form = { ...form, category: navigationObject?.category };
		}
		dispatch(learningCoursesAction.getLearningCourses(form, "root"));
	};

	const handlePageChange = (pageNum) => {
		if (!debouncer.token() || currentPage === pageNum) {
			return;
		}
		let form = {
			l: DEFAULT_LIMIT,
			p: pageNum,
			sort: `${sortingKey}:${sortingDirection}`,
		};
		if (searchWord) {
			form = { ...form, search: searchWord || "" };
		}
		if (filterDistrict) {
			form = filterDistrict === "online" ? { ...form, isOnline: true } : { ...form, district: filterDistrict || "" };
		}
		if (filterCategory) {
			form = { ...form, category: filterCategory || "" };
		}
		dispatch(learningCoursesAction.getLearningCourses(form, "root"));
	};

	useEffect(() => {
		let form = {
			l: DEFAULT_LIMIT,
			p: currentPage,
			sort: `${navigationObject?.sortingKey}:${navigationObject?.sortingDirection}`,
		};
		if (navigationObject?.searchKey) {
			form = { ...form, search: navigationObject?.searchKey };
		}
		if (navigationObject?.district) {
			form = navigationObject?.district === "online" ? { ...form, isOnline: true } : { ...form, district: navigationObject?.district };
		}
		if (navigationObject?.category) {
			form = { ...form, category: navigationObject?.category };
		}
		dispatch(learningCoursesAction.getLearningCourses(form, "root"));
	}, [dispatch]);

	function resetTimeout() {
		if (timeoutRef.current) {
			clearTimeout(timeoutRef.current);
		}
	}

	const handleSelectChange = ({ key, value }) => {
		let searchingWord = false;
		let useDefaultKey = false;
		if (key === "district") {
			setFilterDistrict(value);
		} else if (key === "category") {
			setFilterCategory(value);
		} else if (key === "searchWord") {
			if (searchWord && searchWord.trim().length > 0 && _.isEmpty(value)) {
				useDefaultKey = true;
			}
			setSearchWord(value);
			searchingWord = !_.isEmpty(value);
		}
		const tempSearch = key === "searchWord" ? value : searchWord;
		const tempDistrict = key === "district" ? value : filterDistrict;
		const tempCategory = key === "category" ? value : filterCategory;
		let form = {
			l: DEFAULT_LIMIT,
			p: DEFAULT_PAGE,
			sort: useDefaultKey ? "name:ASC" : searchingWord ? "score:DESC" : `${sortingKey}:${sortingDirection}`,
		};
		if (tempSearch) {
			form = { ...form, search: tempSearch };
		}
		if (tempDistrict) {
			form = tempDistrict === "online" ? { ...form, isOnline: true } : { ...form, district: tempDistrict };
		}
		if (tempCategory) {
			form = { ...form, category: tempCategory };
		}
		if (!textDebouncer.token()) {
			resetTimeout();
			timeoutRef.current = setTimeout(() => {
				dispatch(learningCoursesAction.getLearningCourses(form, "root"));
			}, 1000);
			return;
		}
		resetTimeout();
		dispatch(learningCoursesAction.getLearningCourses(form, "root"));
	};

	const handleNewCourse = () => {
		history.push({
			pathname: `${generalConstants.PATH.LEARNING_COURSE}/add`,
			state: { from: FROM_COURSE },
		});
	};

	const handleCourseDetails = (courseKey) => {
		history.push({
			pathname: `${generalConstants.PATH.LEARNING_COURSE}`,
			state: { from: FROM_COURSE, courseKey: courseKey },
		});
	};

	const getDistrict = (item) => {
		if (item === "") {
			return "/";
		}
		return learningCoursesConstants.DATA.district[item].label;
	};

	const handleRefreshFilter = () => {
		if (filterDistrict === "" && filterCategory === "" && searchWord === "" && sortingDirection === "ASC" && currentPage === DEFAULT_PAGE) {
			return;
		}
		setFilterCategory("");
		setFilterDistrict("");
		setSearchWord("");
		setSortingDirection("ASC");
		dispatch(
			learningCoursesAction.getLearningCourses(
				{
					l: DEFAULT_LIMIT,
					p: DEFAULT_PAGE,
					sort: "name:ASC",
				},
				"root"
			)
		);
	};

	const renderTH = () => {
		return learningCoursesConstants.MANAGMENT_TABLE_COLUMN.map((column) => {
			return (
				<TableTh key={`${column.key}_table_header`} width={column.width}>
					<THButton key={`${column.key}_table_header`} type="button" onClick={() => handleSorting(column.key)}>
						<div>
							{column.value}
							{sortingKey === column.key ? (
								sortingDirection === "ASC" ? (
									<ArrowDropUp fontSize={"small"} style={{ verticalAlign: "middle" }} />
								) : (
									<ArrowDropDown fontSize={"small"} style={{ verticalAlign: "middle" }} />
								)
							) : null}
						</div>
					</THButton>
				</TableTh>
			);
		});
	};
	const renderTableHeader = renderTH();
	const renderTD = () => {
		if (learningCoursesList && learningCoursesList.length > 0) {
			return learningCoursesList.map((course, index) => {
				const tempObj = {};
				learningCoursesConstants.MANAGMENT_TABLE_COLUMN.forEach((column) => {
					tempObj[column.key] = course[column.key];
				});

				return (
					<TableRow
						key={`${course._id}_${index}_tr`}
						onClick={() => {
							handleCourseDetails(course._key);
						}}
					>
						{Object.keys(tempObj).map((columnName, index) => {
							if (columnName === "name") {
								var courseName = "";
								if (typeof tempObj[columnName] != "undefined") {
									courseName = tempObj[columnName];

									if (_.isEmpty(courseName)) {
										courseName = course["nameEng"];
									}
								}
								return (
									<TableTd style={{ display: "flex", alignItems: "center" }} key={`${course._id}_${columnName}_${index}_td`}>
										{course.deleted && <DeleteBadge>{"已移除"}</DeleteBadge>}
										{!course.deleted && course.status === learningCoursesConstants.DATA.status.CLOSED.key && (
											<InactiveBadge>{learningCoursesConstants.DATA.status[course.status].label}</InactiveBadge>
										)}
										{!course.deleted &&
											(course.status === learningCoursesConstants.DATA.status.SUSPENDED.key ||
												course.status === learningCoursesConstants.DATA.status.CANCELLED.key) && (
												<PendingBadge>{learningCoursesConstants.DATA.status[course.status].label}</PendingBadge>
											)}
										{courseName}
										{(course?.lastUpdateBy?.startsWith("organization/") || course?.createdBy?.startsWith("organization/")) && (
											<OrganizationIcon style={{ marginLeft: 5, marginRight: 5, width: 15, height: 19 }} fill={"#333399"} />
										)}
									</TableTd>
								);
							} else if (columnName === "district") {
								return (
									<TableTd key={`${course._id}_${columnName}_${index}_td`}>
										{course.isOnline ? "網上課程" : getDistrict(tempObj[columnName] || "")}
									</TableTd>
								);
							} else if (columnName === "age") {
								return (
									<TableTd key={`${course._id}_${columnName}_${index}_td`}>
										{typeof course.minAge != "undefined" && typeof course.maxAge != "undefined"
											? `${course.minAge}歲 - ${course.maxAge}歲`
											: typeof course.minAge != "undefined"
											? `${course.minAge}歲以上`
											: typeof course.maxAge != "undefined"
											? `${course.maxAge}歲以下`
											: `-`}
									</TableTd>
								);
							} else if (columnName === "category") {
								var categoryName = "-";
								if (typeof tempObj[columnName] != "undefined" && typeof tempObj[columnName] != "string") {
									tempObj[columnName].forEach((name, index) => {
										if (index === 0) {
											categoryName = `${name}`;
										} else {
											categoryName = `${categoryName}、${name}`;
										}
									});
								}
								return <TableTd key={`${course._id}_${columnName}_${index}_td`}>{categoryName}</TableTd>;
							} else if (columnName === "learningCenterName") {
								return <TableTd key={`${course._id}_${columnName}_${index}_td`}>{tempObj[columnName]}</TableTd>;
							} else if (columnName === "lastUpdateTime") {
								return (
									<TableTd key={`${course._id}_${columnName}_${index}_td`}>
										{tempObj[columnName] ? moment(tempObj[columnName]).format("YYYY年M月DD日 hh:mmA") : "-"}
									</TableTd>
								);
							}

							return <TableTd key={`${course._id}_${columnName}_${index}_td`}>{tempObj[columnName]}</TableTd>;
						})}
					</TableRow>
				);
			});
		}
	};
	const renderTableCell = renderTD();

	return (
		<>
			<PageContainer>
				<LeftContainer>
					<AdminMenubar section={generalConstants.NAV_TAB.LEARNING_COURSE_MANAGEMENT.key} />
				</LeftContainer>
				<RightContainer>
					<AdminUserInfo />
					<AdminSectionbar
						title={generalConstants.NAV_TAB.LEARNING_COURSE_MANAGEMENT.key}
						sublabel={`課程數量: ${totalItems}`}
						buttonLabel={"新增課程"}
						buttonIcon={<CourseIcon />}
						buttonType={generalConstants.BUTTON_TYPE.SUBMIT}
						handleOnClick={handleNewCourse}
						locationPathArray={[
							{ title: "主頁面", isAction: true, target: generalConstants.PATH.DASHBOARD },
							{
								title: generalConstants.TAB_NAME[generalConstants.NAV_TAB.LEARNING_COURSE_MANAGEMENT.key],
								isAction: false,
								target: "",
							},
						]}
					/>
					<ContentContainer>
						<SearchBar>
							<SearchBarLeft>
								<RowInBlock marginBottom="0px">
									<div style={{ marginLeft: "10px", width: "20%" }}>
										<StandardSelect
											emptyText={"上課地點"}
											name={"district"}
											displayEmpty
											options={[{ key: "online", value: "網上課程" }, ...learningCoursesConstants.OPTION.district]}
											value={filterDistrict}
											handleChange={handleSelectChange}
										/>
									</div>
									<div style={{ marginLeft: "10px", width: "20%" }}>
										<StandardSelect
											emptyText={"課程類別"}
											name={"category"}
											displayEmpty
											options={learningCoursesConstants.OPTION.category}
											value={filterCategory}
											handleChange={handleSelectChange}
										/>
									</div>
									<div style={{ marginLeft: "10px", width: "40%" }}>
										<StandardTextField
											placeholder="關鍵字"
											value={searchWord}
											name="searchWord"
											handleChange={handleSelectChange}
											style={{
												borderTopRightRadius: "0px",
												borderBottomRightRadius: "0px",
											}}
										/>
									</div>
									<RefreshButton style={{ marginLeft: "5px" }} handleOnClick={handleRefreshFilter} label="重置選項"></RefreshButton>
								</RowInBlock>
							</SearchBarLeft>
							<SearchBarRight />
						</SearchBar>
						<TableContainer>
							<table
								style={{
									width: "100%",
									borderCollapse: "collapse",
									tableLayout: "fixed",
								}}
							>
								<thead>
									<tr>{renderTableHeader}</tr>
								</thead>
								<tbody>{renderTableCell}</tbody>
							</table>
						</TableContainer>
						<PaginationContainer>
							<Pagination count={numOfPage} page={currentPage} handlePageChange={handlePageChange} />
						</PaginationContainer>
					</ContentContainer>
					<AdminFooter></AdminFooter>
				</RightContainer>
			</PageContainer>
		</>
	);
};

const RowInBlock = styled.div`
	display: flex;
	margin-bottom: ${(props) => props.marginBottom || "10px"};
	flex-direction: row;
`;

const DeleteBadge = styled.span`
	padding: 2px 6px 3px;
	border-radius: 3px;
	background-color: #ff5c5c;
	margin: 9px 6px 9px 0;
	font-size: 10px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.5;
	letter-spacing: normal;
	color: white;
`;

const InactiveBadge = styled.span`
	padding: 2px 6px 3px;
	border-radius: 3px;
	background-color: #fff0f2;
	margin: 9px 6px 9px 0;
	font-size: 10px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.5;
	letter-spacing: normal;
	color: #ff0000;
`;

const PendingBadge = styled.span`
	padding: 2px 6px 3px;
	border-radius: 3px;
	background-color: #ffd95c;
	margin: 9px 6px 9px 0;
	font-size: 10px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.5;
	letter-spacing: normal;
	color: black;
`;
