import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { reportConstants, generalConstants, statusConstants } from "_constants";
import { reportAction } from "actions";
import { history } from "_util/_helper";
import { SubmitButton, CancelButton, Loading, DangerButton, AlertDialog, ModalDialog, Status, LightBox } from "components/general";

import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import moment from "moment";
import _ from "lodash";

import { AdminMenubar, AdminUserInfo, AdminSectionbar, AdminFooter } from "components/admin";
import { PageContainer, LeftContainer, RightContainer, ContentContainer, TableRow, TableTh, THButton, TableTd } from "components/layout";

export const ReportTargetPage = () => {
	const location = useLocation();
	const [key, setReportKey] = useState(location?.state?.reportKey);
	const [targetType, setTargetType] = useState(location?.state?.targetType);
	const [batchTime, setBatchTime] = useState(location?.state?.batchTime);

	useEffect(() => {
		setReportKey(location?.state?.reportKey);
		setTargetType(location?.state?.targetType);
		setBatchTime(location?.state?.batchTime);
	}, [location?.state]);

	const getRoundedDate = (minutes, d = new Date()) => {
		const ms = 1000 * 60 * minutes;
		const roundedDate = new Date(Math.ceil(d.getTime() / ms) * ms);

		return roundedDate;
	};

	const getText = (text) => {
		return text ? text.replace(/\n/g, "<br />") : "";
	};

	const getStatus = (item) => {
		const status = "REPORT_" + item.status;
		const statusList = statusConstants.status;
		let thekey = "REPORT_PENDING";

		for (var k in statusList) {
			if (k === status) {
				thekey = k;
			}
		}

		return statusConstants.status[thekey].key;
	};

	const { isFetching, bypassReportSuccess, handleReportSuccess, report } = useSelector(({ report }) => ({
		isFetching: report.isFetching,
		bypassReportSuccess: report.bypassReportSuccess,
		handleReportSuccess: report.handleReportSuccess,
		report: report.report,
	}));

	const initForm = {
		actor: null,
		time: getRoundedDate(5, new Date()),
		reportDate: getRoundedDate(30, new Date()),
		status: reportConstants.DATA.status.PENDING.key,
		blocked: false,
		deleted: false,
		object: {},
		updatedBy: null,
		handleDate: getRoundedDate(30, new Date()),
	};

	const [formData, setFormData] = useState(initForm);
	const [isLoading, setIsLoading] = useState(false);
	const [isDeleteAlertShow, setIsDeleteAlertShow] = useState(false);
	const [isBypassAlertShow, setIsBypassAlertShow] = useState(false);
	const [isDetailModalShow, setIsDetailModalShow] = useState(false);

	const [highlightReport, setHighlightReport] = useState(false);

	const dispatch = useDispatch();

	const handleBack = () => {
		history.goBack();
	};

	useEffect(() => {
		setIsLoading(isFetching);
	}, [isFetching]);

	useEffect(() => {
		if (key) {
			dispatch(reportAction.getReportTarget(targetType, key, batchTime));
		}
	}, []);

	useEffect(() => {
		if (report) {
			const tempForm = {};
			Object.keys(formData).forEach((key) => {
				tempForm[key] = report[key] !== undefined ? report[key] : formData[key];
			});
			setFormData(tempForm);
		}
	}, [report]);

	useEffect(() => {
		if (bypassReportSuccess) {
			dispatch({ type: reportConstants.type.RESET_REPORT_SUCCESS });
			toast.success("舉報已經忽略");
			handleReplace();
		}
	}, [bypassReportSuccess, dispatch]);

	useEffect(() => {
		if (handleReportSuccess) {
			dispatch({ type: reportConstants.type.RESET_REPORT_SUCCESS });
			toast.success("舉報已經處理，帖子已移除");
			handleReplace();
		}
	}, [handleReportSuccess, dispatch]);

	const handleReplace = () => {
		history.replace(generalConstants.PATH.REPORT_MANAGEMENT);
	};

	const handleBypass = () => {
		const data = {
			targetType,
			key,
			cutOffTime: report.reportDate,
		};

		dispatch(reportAction.bypassReport(data));
	};

	const handleDelete = () => {
		const data = {
			targetType,
			key,
			cutOffTime: report.reportDate,
		};

		dispatch(reportAction.handleReport(data));
	};

	const handleCloseDeleteAlert = () => {
		setIsDeleteAlertShow(false);
	};
	const handleCloseBypassAlert = () => {
		setIsBypassAlertShow(false);
	};

	const handleCloseDetailModal = () => {
		setIsDetailModalShow(false);
	};

	const getReportTypeLabel = (item) => {
		const type = item.type;
		const types = reportConstants.DATA.type;
		let thekey = "SEXUAL_CONTENT";

		for (let k in types) {
			if (types[k] && types[k] === type) {
				thekey = k;
			}
		}
		return reportConstants.Translate.type[thekey];
	};

	const renderTH = () => {
		return reportConstants.REPORT_LIST_COLUMN.map((column) => {
			return (
				<TableTh key={`${column.key}_table_header`} width={column.width}>
					<THButton key={`${column.key}_table_header`} type="button">
						<div style={{ textAlign: column.key === "status" ? "center" : "auto" }}>{column.value}</div>
					</THButton>
				</TableTh>
			);
		});
	};
	const renderTableHeader = renderTH();

	const renderTD = () => {
		if (report && report.relatedReports && report.relatedReports.length > 0) {
			return report.relatedReports.map((report, index) => {
				const tempObj = {};
				reportConstants.REPORT_LIST_COLUMN.forEach((column) => {
					tempObj[column.key] = report[column.key];
				});

				return (
					<TableRow
						key={`${report.key}_${index}_tr`}
						onClick={() => {
							HandleRowOnClick(report);
						}}
					>
						{Object.keys(tempObj).map((columnName, index) => {
							if (columnName === "type") {
								return <TableTd key={`${report.key}_${columnName}_${index}_td`}>{getReportTypeLabel(tempObj)}</TableTd>;
							} else if (columnName === "status") {
								return (
									<TableTd key={`${report.key}_${columnName}_${index}_td`}>
										<Status status={getStatus(tempObj)}></Status>
									</TableTd>
								);
							} else if (columnName === "createdDate" || columnName === "updatedDate") {
								return (
									<TableTd key={`${report.key}_${columnName}_${index}_td`}>
										{moment(tempObj[columnName]).format("YYYY年MM月DD日 hh:mmA")}
									</TableTd>
								);
							}
							return <TableTd key={`${report.key}_${columnName}_${index}_td`}>{tempObj[columnName]}</TableTd>;
						})}
					</TableRow>
				);
			});
		}
	};
	const renderTableCell = renderTD();

	const HandleRowOnClick = (item) => {
		setHighlightReport(item);
		setIsDetailModalShow(true);
	};

	if (!key) {
		return null;
	}
	return (
		<>
			{!!isLoading && <Loading />}
			<AlertDialog active={isBypassAlertShow} handleOnClose={handleCloseBypassAlert} handleOnYes={handleBypass} title={"確定忽略舉報？"} />
			<AlertDialog active={isDeleteAlertShow} handleOnClose={handleCloseDeleteAlert} handleOnYes={handleDelete} title={"確定移除帖子？"} />
			<ModalDialog active={isDetailModalShow} handleOnClose={handleCloseDetailModal} noLabel="關閉" title={"-舉報內容"}>
				<ReportDetailContainer>
					<ReportDetailBlock>
						<Subtitle>訊息內容</Subtitle>
						<ReportDetail
							dangerouslySetInnerHTML={{
								__html: highlightReport.text == null ? "沒有內容" : getText(highlightReport.text),
							}}
						/>
					</ReportDetailBlock>
					<ReportDetailBlock>
						<Subtitle>帖子內容</Subtitle>
						<ReportTargetDetail
							dangerouslySetInnerHTML={{
								__html:
									highlightReport && highlightReport.target && highlightReport.target.object.text
										? getText(highlightReport.target.object.text)
										: "",
							}}
						/>
						<>
							{highlightReport &&
								highlightReport.target &&
								highlightReport.target.object &&
								!_.isEmpty(highlightReport.target.object.imageUrl) && (
									<div>
										<LightBox images={highlightReport.target.object.imageUrl} />
									</div>
								)}
						</>
						<ReportDetailTime>舉報時間 {moment(highlightReport.createdDate).format("YYYY年MM月DD日 hh:mmA")}</ReportDetailTime>
					</ReportDetailBlock>
				</ReportDetailContainer>
			</ModalDialog>
			<PageContainer>
				<LeftContainer>
					<AdminMenubar section={generalConstants.NAV_TAB.REPORT_MANAGEMENT.key} />
				</LeftContainer>
				<RightContainer>
					<AdminUserInfo />
					<AdminSectionbar
						title={generalConstants.NAV_TAB.REPORT_DETAIL.key}
						sublabel={`舉報數量: ${report && report.relatedReports && report.relatedReports.length ? report.relatedReports.length : ""}`}
						locationPathArray={[
							{ title: "主頁面", isAction: true, target: generalConstants.PATH.DASHBOARD },
							{
								title: generalConstants.TAB_NAME[generalConstants.NAV_TAB.REPORT_MANAGEMENT.key],
								isAction: true,
								target: generalConstants.PATH.REPORT_MANAGEMENT,
							},
							{
								title: generalConstants.TAB_NAME[generalConstants.NAV_TAB.REPORT_DETAIL.key],
								isAction: false,
								target: "",
							},
						]}
					/>
					<ContentContainer style={{ display: "flex", flex: 2, flexWrap: "wrap", marginBottom: "18px" }}>
						<Title>-動態詳情</Title>
						<ContentLeft>
							<BlockGroup>
								<Block marginTop="10px">
									<>
										<Subtitle>狀態</Subtitle>
										<TextFieldLabel>
											<Status
												style={{ margin: "0 auto 0 0" }}
												status={formData.deleted ? "REPORT_DELETED" : formData.blocked ? "REPORT_BLOCKED" : getStatus(formData)}
											/>
										</TextFieldLabel>
									</>
								</Block>
								<Block marginTop="10px">
									<>
										<Subtitle>動態更新時間</Subtitle>
										<TextFieldLabel> {moment(formData.time).format("YYYY年MM月DD日 hh:mmA")}</TextFieldLabel>
									</>
								</Block>
							</BlockGroup>

							<BlockGroup>
								<Block>
									<>
										<Subtitle>帳戶</Subtitle>
										<TextFieldLabel>{formData.actor || "-"}</TextFieldLabel>
									</>
								</Block>
								<Block>
									<>
										<Subtitle>最後舉報時間</Subtitle>
										<TextFieldLabel>{moment(formData.reportDate).format("YYYY年MM月DD日 hh:mmA")}</TextFieldLabel>
									</>
								</Block>
							</BlockGroup>

							<BlockGroup>
								<Block>
									<>
										<Subtitle>處理人</Subtitle>
										<TextFieldLabel>{formData.updatedBy || "-"}</TextFieldLabel>
									</>
								</Block>
								<Block>
									<>
										<Subtitle>處理時間</Subtitle>
										<TextFieldLabel>
											{formData.handleDate ? moment(formData.handleDate).format("YYYY年MM月DD日 hh:mmA") : "-"}
										</TextFieldLabel>
									</>
								</Block>
							</BlockGroup>
						</ContentLeft>
						<ContentRight>
							<Block marginTop="10px">
								<>
									<Subtitle>帖子內容</Subtitle>
									<ReportDetail
										height="109px"
										dangerouslySetInnerHTML={{
											__html: getText(formData.object?.text),
										}}
									/>
								</>
							</Block>
							<BlockGroup>
								<Block>
									<>
										{formData.object && !_.isEmpty(formData.object.imageUrl) && (
											<div>
												<LightBox images={formData.object?.imageUrl} />
											</div>
										)}
									</>
								</Block>

								<RowInBlock
									style={{ maxHeight: "40px", textAlign: "right", paddingTop: "40px", justifyContent: "flex-end", paddingRight: "10px" }}
								>
									<CancelButton label={"返回"} style={{ marginRight: "15px" }} handleOnClick={handleBack} />
									{formData.status !== reportConstants.DATA.status.COMPLETED.key && (
										<SubmitButton
											label={"忽略"}
											disabled={false}
											style={{ marginRight: "15px" }}
											handleOnClick={() => {
												setIsBypassAlertShow();
											}}
										/>
									)}
									{!formData.deleted && formData.status !== reportConstants.DATA.status.COMPLETED.key && (
										<DangerButton
											disabled
											label={"刪除帖子"}
											handleOnClick={() => {
												setIsDeleteAlertShow();
											}}
										/>
									)}
								</RowInBlock>
							</BlockGroup>
						</ContentRight>
					</ContentContainer>

					<ContentContainer style={{ paddingTop: 0 }}>
						<Block marginTop="0">
							<OverflowScroll>
								<table style={{ width: "100%", borderCollapse: "collapse", tableLayout: "fixed" }}>
									<thead>
										<tr>{renderTableHeader}</tr>
									</thead>
									<tbody>{renderTableCell}</tbody>
								</table>
							</OverflowScroll>
						</Block>
					</ContentContainer>
					<AdminFooter></AdminFooter>
				</RightContainer>
			</PageContainer>
		</>
	);
};

const ContentLeft = styled.div`
	flex: 0 0 400px;
	border-right: 1px solid #dddddd;
`;

const ContentRight = styled.div`
	flex: 1;
	padding-left: 20px;
`;

const BlockGroup = styled.div`
	display: flex;
	flex-direction: row;
`;

const Block = styled.div`
	padding: 10px;
	flex: ${(props) => props.flex || 1};
`;

const RowInBlock = styled.div`
	display: flex;
	margin-bottom: ${(props) => props.marginBottom || "10px"};
	flex-direction: row;
`;

const Title = styled.div`
	font-weight: bold;
	margin: 0 0 12px 4px;
	font-size: 14px;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.43;
	letter-spacing: normal;
	text-align: left;
	color: #333399;
	width: 100%;
`;

const Subtitle = styled.div`
	color: black;
	margin-bottom: ${(props) => props.marginBottom || "3px"};
	font-size: 14px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.43;
	letter-spacing: normal;
`;

const TextFieldLabel = styled.div`
	color: black;
	font-size: 13px;
	line-height: 1.5;
	margin-bottom: 6px;

	font-weight: 500;
	font-stretch: normal;
	font-style: normal;
	letter-spacing: normal;
	color: #333333;
`;

const OverflowScroll = styled.div`
	margin-bottom: 20px;
`;

const ReportDetailContainer = styled.div`
	display: flex;
	margin: 0 -10px;
`;

const ReportDetailBlock = styled.div`
	flex: 1;
	padding: 0 10px;
`;

const ReportDetail = styled.div`
	height: ${(props) => props.height || "237px"};
	padding: 8px 10px 10px;
	border-radius: 3px;
	border: solid 1px #cccccc;
	background-color: #ffffff;
	margin: 5px 0;
	overflow-y: scroll;
`;

const ReportTargetDetail = styled.div`
	height: ${(props) => props.height || "237px"};
	padding: 8px 10px 10px;
	border-radius: 3px;
	border: solid 1px #e5e5e5;
	background-color: #e5e5e5;
	margin: 5px 0;
	overflow-y: scroll;
`;

const ReportDetailTime = styled.div`
	font-size: 11px;
	font-weight: 500;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.55;
	letter-spacing: normal;
	text-align: right;
	color: #838383;
`;
