import React from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import styled from "styled-components";

import { generalConstants } from "_constants";
import { authAction } from "actions";
import { WarningButton, ImportButton } from "components/general";

import { history } from "_util/_helper";

import headerLogo from "resources/img/header_logo@2x.png";

export const AdminNavbar = (props) => {
	const { title, buttonLabel, handleOnClick, label, sublabel } = props;

	const dispatch = useDispatch();

	const locationPath = `主頁 > ${generalConstants.TAB_NAME[title]}`;

	const handleOnChangeNav = (item) => {
		history.push(generalConstants.PATH[item]);
	};

	const handleLogout = () => {
		dispatch(authAction.logout());
	};

	const renderNavItem = Object.keys(generalConstants.NAV_TAB).map((item) => {
		if (generalConstants.NAV_TAB[item].show) {
			return (
				<NavItem
					key={item}
					current={item === title}
					onClick={() => {
						handleOnChangeNav(item);
					}}
				>
					{generalConstants.TAB_NAME[item]}
				</NavItem>
			);
		}
		return null;
	});

	return (
		<Container>
			<IconBox><Logo src={headerLogo}></Logo></IconBox>

			<UserInfoLayer>
				<InnerContainer>
					<RowInBlock style={{ justifyContent: "flex-end" }}>
						<LogoutContainer onClick={handleLogout}>登出</LogoutContainer>
					</RowInBlock>
				</InnerContainer>
			</UserInfoLayer>

			<NavLayer>
				<InnerContainer>
					<RowInBlock>
						<NavItem />
						{renderNavItem}
					</RowInBlock>
				</InnerContainer>
			</NavLayer>

			<SectionLayer>
				<InnerContainer>
					<RowInBlock style={{ justifyContent: "space-between" }}>
						<Block>
							{sublabel && (
								<div style={{
									display: "flex",
									flexDirection: "row"
								}}>
									<Title>{label ? label : generalConstants.TAB_NAME[title]}</Title><SubTitle>( {sublabel} )</SubTitle>
								</div>
							)}
							{!sublabel && (
								<Title>{label ? label : generalConstants.TAB_NAME[title]}</Title>
							)}
							<LocationPath>{locationPath}</LocationPath>
						</Block>
						{buttonLabel && (
							<div>
								<WarningButton style={{borderRadius:'50px'}} label={buttonLabel} handleOnClick={handleOnClick} />
							</div>
						)}
						{(title === "NEW_MSG" || title === "IMPORT_MSG") && (
							<div>
								<ImportButton handleOnClick={handleOnClick} />
							</div>
						)}
					</RowInBlock>
				</InnerContainer>
			</SectionLayer>
		</Container>
	);
};

AdminNavbar.propTypes = {
	title: PropTypes.string,
	label: PropTypes.string,
	sublabel: PropTypes.string,
	buttonLabel: PropTypes.string,
	handleOnClick: PropTypes.func,
};

const Logo = styled.img`
	max-width:100px;
	max-height:100px;
	width:100%;
	height:100%;
	padding:14px 8px 10px 12px;
`;
const Container = styled.div`
	width: 100%;
	padding-bottom: 10px;
	height: 190px;
`;

const UserInfoLayer = styled.div`
	width: 100%;
	height: 48px;
	background-color: white;
`;

const NavLayer = styled.div`
	width: 100%;
	height: 52px;
	background-color: #f6f6f6;
`;

const SectionLayer = styled.div`
	width: 100%;
	height: 60px;
	background-color: #333399;
	padding-top: 30px;
`;

const IconBox = styled.div`
	width: 120px;
	height: 125px;
	box-shadow: 0 6px 4px 0 rgba(0, 0, 0, 0.16);
	background-color: white;
	position: absolute;
	left: 10%;
`;

const InnerContainer = styled.div`
	height: 100%;
	width: 80%;
	margin: auto;
`;

const RowInBlock = styled.div`
	height: 100%;
	display: flex;
	flex-direction: row;
`;

const Block = styled.div`
	display: flex;
	flex-direction: column;
`;

const Title = styled.div`
	color: white;
	font-size: 23px;
	font-weight: bold;
	line-height: 1.5;
	text-align: left;
`;

const SubTitle = styled.div`
	color: white;
	font-size: 16px;
	line-height: 2;
	text-align: center;
`;

const LocationPath = styled.div`
	color: #80ddff;
	font-size: 12px;
	font-weight: bold;
	line-height: 1.5;
	text-align: left;
`;

const LogoutContainer = styled.div`
	height: 100%;
	display: flex;
	flex-direction: row;
	min-width: 100px;
	width: 10%;
	max-width: 120px;
	align-items: center;
	justify-content: center;
	cursor: pointer;

	&:hover {
		background: #eeeeee;
	}
`;

const NavItem = styled.div`
	height: 100%;
	text-align: center;
	min-width: 120px;
	width: 10%;
	max-width: 150px;
	font-size: 16px;
	line-height: 1.5;
	color: ${(props) => (props.current ? "#333399" : "#666666")};
	font-weight: bold;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;

	&:hover {
		background: #eeeeee;
	}
`;
