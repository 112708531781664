import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { TextField, WarningButton } from "components/general";
import { toast } from "react-toastify";
import { useKey } from "_util/hook";

export const ForgetPasswordForm = (props) => {
	const { handleForgetPassword, setIsForgetPw } = props;

	const [formData, setFormData] = useState({ email: "" });

	const enterPress = useKey("enter");

	useEffect(() => {
		if (enterPress) {
			handleOnClick();
		}
	}, [enterPress]);

	const handleChange = ({ key, value }) => {
		setFormData({ ...formData, [key]: value });
	};
	const handleOnClick = () => {
		// if (!formData.username) {
		// 	toast.error("請填寫用户名稱");
		// 	return;
		// }
		if (!formData.email) {
			toast.error("請填寫電郵");
			return;
		}
		handleForgetPassword(formData);
	};

	const handleBack = () => {
		setIsForgetPw(false);
		// history.push(generalConstants.PATH.FORGET_PASSWORD);
	};

	return (
		<Container>
			<LeftContainer></LeftContainer>
			<RightContainer>
				<RightInnerContainer>
					<div style={{ flex: 1 }}>
						<TitleText>忘記密碼</TitleText>
						<TitlesSubText>請輸入與您的用戶名稱和電郵。 我們將通過電郵向您發送重置密碼的鏈接。</TitlesSubText>
					</div>
					<VerticalLine />
					<div
						style={{
							flex: 2,
							display: "flex",
							flexDirection: "column",
							justifyContent: "center",
						}}
					>
						{/* <TextFieldLabel>用户名稱</TextFieldLabel>
						<TextField
							placeholder="用户名稱"
							style={{ backgroundColor: "white" }}
							value={formData.username}
							name="username"
							handleChange={handleChange}
							autoComplete={"username"}
						/> */}
						{/* <div style={{ marginTop: "20px", marginBottom: "20px" }} /> */}
						<TextFieldLabel>電郵</TextFieldLabel>
						<TextField
							placeholder="電郵"
							style={{ backgroundColor: "white" }}
							value={formData.email}
							name="email"
							handleChange={handleChange}
						/>
					</div>
					<div style={{ flex: 2, display: "flex", flexDirection: "row", alignItems: "center", marginTop: 20 }}>
						<BackContainer>
							<BackText onClick={handleBack}>返回</BackText>
						</BackContainer>
						<div style={{ width: "100%" }}>
							<WarningButton style={{ width: "100%" }} label={"提交"} handleOnClick={handleOnClick} />
						</div>
					</div>
				</RightInnerContainer>
			</RightContainer>
		</Container>
	);
};

ForgetPasswordForm.propTypes = {
	handleForgetPassword: PropTypes.func,
	setIsForgetPw: PropTypes.func,
};

const Container = styled.div`
	height: 60%;
	width: 50%;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	z-index: 10;
	display: flex;
	flex-direction: row;
	filter: drop-shadow(0px 0px 20px rgba(0, 0, 0));
`;

const LeftContainer = styled.div`
	flex: 2;
	background-color: white;
	border-top-left-radius: 10px;
	border-bottom-left-radius: 10px;
`;

const RightContainer = styled.div`
	flex: 3;
	background-color: #333399;
	border-top-right-radius: 10px;
	border-bottom-right-radius: 10px;
	overflow: auto;
	display: flex;
	align-items: center;
	justify-content: center;
`;

const RightInnerContainer = styled.div`
	width: 80%;
	display: flex;
	flex-direction: column;
	height: 90%;
`;

const TitleText = styled.div`
	color: white;
	font-size: 26px;
	line-height: 1.5;
	font-weight: bold;
`;
const TitlesSubText = styled.div`
	color: white;
	font-size: 14px;
	line-height: 1.5;
	font-weight: bold;
`;

const VerticalLine = styled.div`
	width: 100%;
	border-bottom: 1px solid white;
	/* margin-top: 20px; */
	margin-bottom: 20px;
`;

const TextFieldLabel = styled.div`
	color: white;
	font-size: 16px;
	line-height: 1.5;
	font-weight: bold;
	margin-bottom: 10px;
`;

const BackContainer = styled.div`
	width: 100%;
`;
const BackText = styled.span`
	color: white;
	font-size: 16px;
	line-height: 1.5;
	font-weight: bold;
	cursor: pointer;
	text-decoration: underline;
`;
