import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { statusConstants } from "_constants";

export const Status = (props) => {
	const { status, style } = props;

	const text = statusConstants.status[status]?.label;
	const type = statusConstants.status[status]?.type;
	if (type === statusConstants.type.PENDING) {
		return <PendingStatus style={style}>{text}</PendingStatus>;
	} else if (type === statusConstants.type.SUCCESS) {
		return <SuccessStatus style={style}>{text}</SuccessStatus>;
	} else if (type === statusConstants.type.FAILURE) {
		return <FailureStatus style={style}>{text}</FailureStatus>;
	} else if (type === statusConstants.type.BLOCK) {
		return <BlockStatus style={style}>{text}</BlockStatus>;
	} else {
		return <div></div>;
	}
};

const RootStatus = styled.div`
	padding: 3px 0px;
	background-color: red;
	text-align: center;
	border-radius: 3px;
	max-width: 60px;
	margin: 0 auto;
	font-size:11px;
`;

const PendingStatus = styled(RootStatus)`
	color: black;
	background-color: #ffd95c;
`;
const SuccessStatus = styled(RootStatus)`
	color: black;
	background-color: #5cff82;
`;
const FailureStatus = styled(RootStatus)`
	color: white;
	background-color: #ff5c5c;
`;
const BlockStatus = styled(RootStatus)`
	color: white;
	background-color: #000000;
`;


Status.propTypes = {
	status: PropTypes.string,
	style: PropTypes.object,
};