import styled from "styled-components";

export const TableContainer = styled.div`
	margin-left: -20px;
	margin-right: -20px;
	margin-top: 12px;
`;

export const TableRow = styled.tr`
	border-bottom: 1px solid #dddddd;
	&:hover {
		background: #eeeeee;
		cursor: pointer;
	}
`;

export const THButton = styled.div`
	border: none;
	color: black;
	font-weight: bold;
	text-align: left;
	text-decoration: none;
	cursor: pointer;
	line-height: 22px;
	padding: 4px 5px 5px 5px;
	&:hover {
		background: #eeeeee;
	}
`;

export const TableTh = styled.th`
	border-bottom: 2px solid black;
	padding: 0;
	position: relative;
	height: 22px;
	min-width: 70px;
	width: ${(props) => props.width};
	@media (max-width: 768px) {
		width: 5%;
	}
`;

export const TableTd = styled.td`
	height: 20px;
	padding: 10px 5px;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
`;
