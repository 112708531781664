import { authConstants, templateConstants } from "_constants";

const initialState = {
	isFetching: false,
	submitTemplateSuccess: false,
	updateTemplateSuccess: false,
	deleteTemplateSuccess: false,
	templateList: [],
	currentPage: 1,
	numOfPage: 1,
	totalItems: 0,
	template: {},
};

const templateReducer = (state = initialState, action) => {
	switch (action.type) {
		case authConstants.type.UNAUTHORIZED_ERROR: {
			return { ...state, currentPage: 1 };
		}
		case templateConstants.type.SUBMIT_TEMPLATE_REQUEST: {
			return {
				...state,
				isFetching: true,
				submitTemplateSuccess: false,
			};
		}
		case templateConstants.type.SUBMIT_TEMPLATE_FAILURE: {
			return {
				...state,
				isFetching: false,
				submitTemplateSuccess: false,
			};
		}
		case templateConstants.type.SUBMIT_TEMPLATE_SUCCESS: {
			return {
				...state,
				isFetching: false,
				submitTemplateSuccess: true,
			};
		}
		case templateConstants.type.RESET_TEMPLATE_SUCCESS: {
			return {
				...state,
				isFetching: false,
				submitTemplateSuccess: false,
				updateTemplateSuccess: false,
				deleteTemplateSuccess: false,
			};
		}
		case templateConstants.type.FETCH_TEMPLATE_LIST_SUCCESS: {
			return {
				...state,
				templateList: action.templateList,
				totalItems: action.totalNum,
				numOfPage: action.totalPage,
				currentPage: action.currentPage,
			};
		}

		case templateConstants.type.FETCH_TEMPLATE_REQUEST: {
			return {
				...state,
				template: {},
			};
		}
		case templateConstants.type.FETCH_TEMPLATE_SUCCESS: {
			return {
				...state,
				template: action.template,
			};
		}

		case templateConstants.type.UPDATE_TEMPLATE_REQUEST: {
			return {
				...state,
				isFetching: true,
				updateTemplateSuccess: false,
			};
		}
		case templateConstants.type.UPDATE_TEMPLATE_FAILURE: {
			return {
				...state,
				isFetching: false,
				updateTemplateSuccess: false,
			};
		}
		case templateConstants.type.UPDATE_TEMPLATE_SUCCESS: {
			return {
				...state,
				isFetching: false,
				updateTemplateSuccess: true,
			};
		}

		case templateConstants.type.DELETE_TEMPLATE_REQUEST: {
			return {
				...state,
				isFetching: true,
				deleteTemplateSuccess: false,
			};
		}
		case templateConstants.type.DELETE_TEMPLATE_FAILURE: {
			return {
				...state,
				isFetching: false,
				deleteTemplateSuccess: false,
			};
		}
		case templateConstants.type.DELETE_TEMPLATE_SUCCESS: {
			return {
				...state,
				isFetching: false,
				deleteTemplateSuccess: true,
			};
		}

		default: {
			return state;
		}
	}
};

export default templateReducer;
