import React, { useState, useEffect } from "react";
import { Prompt } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { generalConstants, learningCentreConstants, organizationConstants } from "_constants";
import { learningCentreAction } from "actions";
import { organizationService } from "services";
import { history } from "_util/_helper";
import { toast } from "react-toastify";
import isEqual from "react-fast-compare";
import _ from "lodash";
import {
	SubmitButton,
	CancelButton,
	CategoryButton,
	Loading,
	Select,
	TextField,
	NewCategoryDialog,
	SingleImageUploader,
} from "components/general";
import Button from "@material-ui/core/Button";
import { useLocation } from "react-router-dom";
import { AddCircleOutline, Close } from "@material-ui/icons";
import { IconButton } from "@material-ui/core";
import { AsyncPaginate } from "react-select-async-paginate";

import { AdminMenubar, AdminUserInfo, AdminSectionbar, AdminFooter } from "components/admin";
import { PageContainer, LeftContainer, RightContainer, ContentContainer } from "components/layout";

const DEFAULT_LIMIT = 10;
const DEFAULT_PAGE = 1;
const FROM_CENTER = "learningCenter";
const FROM_ORGANIZATION = "organization";
export const EditCentrePage = () => {
	const location = useLocation();
	const initForm = {
		key: "",
		createdBy: "",
		createdTime: "",
		category: [],
		name: "",
		nameEng: "",
		description: "",
		status: learningCentreConstants.OPTION.status[0].key,
		profilePic: "",
		address: "",
		district: "",
		openingHours: "",
		email: "",
		phone: "",
		website: "",
		whatsapp: "",
		ig: "",
		facebook: "",
		organizationKey: "",
	};

	const [prevPage, setPrevPage] = useState(location?.state?.from);
	const [cloneCenter, setCloneCenter] = useState(location?.state?.cloneCenter);
	const [key, setLearningCenterKey] = useState(location?.state?.learningCenterKey);
	const [orgKey, setOrganizationKey] = useState(location?.state?.organizationKey);
	const [formData, setFormData] = useState(initForm);
	const [isLoading, setIsLoading] = useState(false);
	const [isChanged, setIsChanged] = useState(false);
	const [isSaved, setIsSaved] = useState(false);
	const [isNewCategoryShow, setIsNewCategoryShow] = useState(false);

	const [selectedValue, setSelectedValue] = useState(null);

	const { centre } = useSelector(({ centre }) => {
		return { centre: key ? centre.centre : null };
	}, isEqual);

	const { organizationObj } = useSelector(({ organization }) => {
		return { organizationObj: prevPage === FROM_ORGANIZATION ? organization.organization : null };
	}, isEqual);
	const loggedIn = useSelector(({ auth }) => auth.loggedIn);
	const createLearningCentreSuccess = useSelector(({ centre }) => centre.createLearningCentreSuccess);
	const updateLearningCentreSuccess = useSelector(({ centre }) => centre.updateLearningCentreSuccess);
	const isFetching = useSelector(({ centre }) => centre.isFetching);
	const isFetchError = useSelector(({ centre }) => centre.isFetchError);

	useEffect(() => {
		setPrevPage(location?.state?.from);
		setCloneCenter(location?.state?.cloneCenter);
		setLearningCenterKey(location?.state?.learningCenterKey);
		setOrganizationKey(location?.state?.organizationKey);
	}, [location?.state]);

	useEffect(() => {
		if (isFetchError) {
			dispatch({ type: learningCentreConstants.type.RESET_LEARNING_CENTRE_SUCCESS });
			history.push({
				pathname:
					prevPage === FROM_ORGANIZATION ? `${generalConstants.PATH.ORGANIZATION}` : `${generalConstants.PATH.LEARNING_CENTRE_MANAGEMENT}`,
				state: { organizationKey: orgKey },
			});
		}
	}, [isFetchError]);

	const dispatch = useDispatch();

	useEffect(() => {
		if (key) {
			dispatch(learningCentreAction.getLearningCenterByKey(key, {}, prevPage));
		}
	}, [key]);

	useEffect(() => {
		if (centre || cloneCenter) {
			const currentCenter = cloneCenter ? cloneCenter : centre ? centre : null;
			if (currentCenter != null) {
				const data = {
					key: currentCenter._key,
					rev: currentCenter._rev,
					createdBy: currentCenter.createBy || "",
					createdTime: currentCenter.createdTime,
					category: currentCenter.category || [],
					name: currentCenter.name ? currentCenter.name : "",
					nameEng: currentCenter.nameEng ? currentCenter.nameEng : "",
					description: currentCenter.description,
					status: currentCenter.status || learningCentreConstants.OPTION.status[0].key,
					profilePic: currentCenter.profilePic || "",
					address: currentCenter.address,
					district: currentCenter.district || "",
					openingHours: currentCenter.openingHours,
					email: currentCenter.email,
					phone: currentCenter.phone,
					website: currentCenter.website,
					whatsapp: currentCenter.whatsapp,
					ig: currentCenter.ig,
					facebook: currentCenter.facebook,
					organizationKey: currentCenter.organizationKey || "",
					latitude: currentCenter.latitude,
					longitude: currentCenter.longitude,
				};
				setSelectedValue({ value: currentCenter.organizationKey, name: currentCenter.organizationName });
				setFormData(data);
				setIsChanged(!_.isEmpty(cloneCenter));
			}
		}
	}, [centre, cloneCenter]);

	const handleBack = () => {
		history.goBack();
	};

	useEffect(() => {
		setIsLoading(isFetching);
	}, [isFetching]);

	useEffect(() => {
		if (updateLearningCentreSuccess) {
			if (!isSaved) {
				setIsSaved(true);
				return;
			}
			dispatch({ type: learningCentreConstants.type.RESET_LEARNING_CENTRE_SUCCESS });
			toast.success("已成功更新");
			handleReplace();
		}
	}, [updateLearningCentreSuccess, isSaved, dispatch]);

	useEffect(() => {
		if (createLearningCentreSuccess) {
			if (!isSaved) {
				setIsSaved(true);
				return;
			}
			dispatch({ type: learningCentreConstants.type.RESET_LEARNING_CENTRE_SUCCESS });
			toast.success("已成功新增");
			handleReplace();
		}
	}, [createLearningCentreSuccess, isSaved, dispatch]);

	const handleReplace = () => {
		if (key) {
			history.replace({
				pathname:
					prevPage === FROM_ORGANIZATION
						? `${generalConstants.PATH.ORGANIZATION}/learningCenter`
						: `${generalConstants.PATH.LEARNING_CENTER}`,
				state:
					prevPage === FROM_ORGANIZATION
						? { from: prevPage, organizationKey: organizationObj._key, learningCenterKey: cloneCenter ? cloneCenter._key : centre._key }
						: { from: prevPage, learningCenterKey: cloneCenter ? cloneCenter._key : centre._key },
			});
		} else {
			history.replace({
				pathname: prevPage === FROM_ORGANIZATION ? generalConstants.PATH.ORGANIZATION : generalConstants.PATH.LEARNING_CENTRE_MANAGEMENT,
				state: prevPage === FROM_ORGANIZATION ? { from: prevPage, organizationKey: organizationObj._key } : { from: prevPage },
			});
		}
	};

	const handleChange = ({ key, value }) => {
		let tempData = { ...formData };
		tempData[key] = value;
		setFormData(tempData);
		setIsChanged(true);
	};

	const handleChangeImage = (file) => {
		setFormData({ ...formData, profilePic: file });
		setIsChanged(true);
	};

	const handleClearImage = () => {
		setFormData({ ...formData, profilePic: "" });
		setIsChanged(true);
	};

	const handleNewButtonOnClick = () => {
		setIsNewCategoryShow(true);
	};

	const handleCloseNewCategory = () => {
		setIsNewCategoryShow(false);
	};

	const handleSubmitNewCategory = (value) => {
		setFormData({ ...formData, category: value });
		setIsChanged(true);
	};

	const handleRemoveCategory = (value) => {
		if (formData.category.length > 0) {
			setFormData({
				...formData,
				category: formData.category.filter((category) => {
					return category != value;
				}),
			});
			setIsChanged(true);
		}
	};

	const handleSubmit = () => {
		if (
			(typeof formData.latitude != "undefined" && isNaN(formData.latitude)) ||
			(typeof formData.longitude != "undefined" && isNaN(formData.longitude))
		) {
			toast.error("經緯度輸入有誤");
			return false;
		}

		var data = {
			key: formData.key,
			rev: formData.rev,
			category: formData.category || "",
			name: formData.name || "",
			nameEng: formData.nameEng || "",
			description: formData.description || "",
			status: formData.status || "",
			profilePic: formData.profilePic || "",
			address: formData.address || "",
			district: formData.district || "",
			openingHours: formData.openingHours || "",
			email: formData.email || "",
			phone: formData.phone || "",
			website: formData.website || "",
			whatsapp: formData.whatsapp || "",
			ig: formData.ig || "",
			facebook: formData.facebook || "",
			organizationKey: orgKey || "",
			latitude: Number(formData.latitude),
			longitude: Number(formData.longitude),
		};

		if (formData.category) {
			data = {
				...data,
				category: JSON.stringify(formData.category)
					.replace(/[\]\\["]/g, "")
					.trim(),
			};
		}
		if (selectedValue != null) {
			data = {
				...data,
				organizationKey: selectedValue.value || "",
			};
		}
		if (key && !cloneCenter) {
			dispatch(learningCentreAction.updateLearningCenter(data));
		} else {
			dispatch(learningCentreAction.createLearningCenter(data));
		}
	};

	const renderCategory = () => {
		return formData.category.map((item, index) => {
			return (
				<div key={`${item}_${index}_category_item`} style={{ marginRight: "5px", marginBottom: "5px" }}>
					<CategoryButton
						label={item}
						buttonIcon={
							<IconButton
								style={{ paddingTop: 5, paddingBottom: 5 }}
								onClick={() => {
									handleRemoveCategory(item);
								}}
							>
								<Close style={{ fontSize: "16px", color: "#FFFFFF" }} />
							</IconButton>
						}
					/>
				</div>
			);
		});
	};
	const renderCategoryList = renderCategory();
	const getLocationPath = () => {
		const pathArray = [];

		pathArray.push({ title: "主頁面", isAction: true, target: generalConstants.PATH.DASHBOARD });
		pathArray.push({
			title:
				generalConstants.TAB_NAME[
					prevPage === FROM_CENTER
						? generalConstants.NAV_TAB.LEARNING_CENTRE_MANAGEMENT.key
						: generalConstants.NAV_TAB.ORGANIZATION_MANAGEMENT.key
				],
			isAction: true,
			target: prevPage === FROM_CENTER ? generalConstants.PATH.LEARNING_CENTRE_MANAGEMENT : generalConstants.PATH.ORGANIZATION_MANAGEMENT,
		});
		if (prevPage === FROM_ORGANIZATION && organizationObj) {
			pathArray.push({
				title: organizationObj.name || "",
				isAction: true,
				target: `${generalConstants.PATH.ORGANIZATION}`,
				stateObj: { from: prevPage, organizationKey: organizationObj._key },
			});
		}
		if (key && centre && !cloneCenter) {
			pathArray.push({
				title: _.isEmpty(centre.name) ? centre.nameEng || "" : centre.name || "",
				isBack: true,
				target:
					prevPage === FROM_ORGANIZATION
						? `${generalConstants.PATH.ORGANIZATION}/learningCenter`
						: `${generalConstants.PATH.LEARNING_CENTER}`,
				stateObj:
					prevPage === FROM_ORGANIZATION
						? { from: prevPage, organizationKey: organizationObj._key, learningCenterKey: centre._key }
						: { from: prevPage, learningCenterKey: centre._key },
			});
		}
		pathArray.push({
			title:
				generalConstants.TAB_NAME[
					key && !cloneCenter ? generalConstants.NAV_TAB.EDIT_LEARNING_CENTRE.key : generalConstants.NAV_TAB.CREATE_LEARNING_CENTRE.key
				],
			isAction: false,
			target: "",
		});
		return pathArray;
	};

	// handle selection
	const handleSelectChange = (value) => {
		setIsChanged(true);
		setSelectedValue(value);
	};

	const loadOptions = async (searchQuery, loadedOptions, { page }) => {
		const response = await organizationService.getOrganizations({
			search: searchQuery,
			status: [organizationConstants.approvalStatus.APPROVED],
			type: "LEARNING_CENTER",
			l: DEFAULT_LIMIT,
			p: page,
		});
		if (response && response.items) {
			var hasMore = true;
			var optionList = response.items.map((i) => ({ value: `${i._key}`, name: _.isEmpty(i.name) ? i.nameEng : i.name }));
			if (_.isEmpty(searchQuery) && page === 1 && centre) {
				if (centre.organizationKey != null) {
					optionList = [
						{ value: centre.organizationKey, name: centre.organizationName },
						...optionList.filter((item) => item.value != centre.organizationKey),
					];
				}
			}
			if (loadedOptions.length >= response.totalItems || optionList.length === response.totalItems) {
				hasMore = false;
			}
			return {
				options: optionList,
				hasMore: hasMore,
				additional: {
					page: page + 1,
				},
			};
		}
		return {
			options: [],
			hasMore: false,
			additional: {
				page: page,
			},
		};
	};

	if ((!key && location.pathname.includes("/edit")) || (!prevPage && location.pathname.includes("/add"))) {
		return null;
	}
	return (
		<>
			<NewCategoryDialog
				category={formData.category || []}
				active={isNewCategoryShow}
				handleOnSubmit={handleSubmitNewCategory}
				handleOnClose={handleCloseNewCategory}
			/>
			{!!isLoading && <Loading />}
			<Prompt when={isChanged && !isSaved && loggedIn} message="你有未儲存的變更，確定不儲存下離開？" />
			<PageContainer>
				<LeftContainer>
					<AdminMenubar
						section={
							prevPage === FROM_CENTER
								? generalConstants.NAV_TAB.LEARNING_CENTRE_MANAGEMENT.key
								: generalConstants.NAV_TAB.ORGANIZATION_MANAGEMENT.key
						}
					/>
				</LeftContainer>
				<RightContainer>
					<AdminUserInfo />
					<AdminSectionbar
						title={
							key && !cloneCenter ? generalConstants.NAV_TAB.EDIT_LEARNING_CENTRE.key : generalConstants.NAV_TAB.CREATE_LEARNING_CENTRE.key
						}
						locationPathArray={getLocationPath()}
					/>
					<ContentContainer style={{ display: "flex", flexDirection: "row", padding: "0px" }}>
						<ContentLeft>
							<BlockGroup>
								<Block flex="0 0 100px">
									<TextFieldLabel style={{ marginLeft: "15px" }}>補習社徽標</TextFieldLabel>
									<Block>
										<SingleImageUploader
											src={formData.profilePic}
											handleChange={handleChangeImage}
											handleClear={handleClearImage}
											uploadLabel="上傳圖片"
											noImageLabel="沒有圖片"
										/>
									</Block>
								</Block>
								<LineRight />
								<Block style={{ marginLeft: "10px" }}>
									{prevPage === FROM_CENTER ? (
										<BlockGroup>
											<div style={{ marginRight: "10px", width: "50%" }}>
												<TextFieldLabel>補習社名稱 (中文)</TextFieldLabel>
												<TextField placeholder="補習社名稱 (中文)" value={formData.name} name="name" handleChange={handleChange} />
											</div>
											<div style={{ width: "50%" }}>
												<TextFieldLabel>機構</TextFieldLabel>
												<>
													<AsyncPaginate
														isClearable
														value={selectedValue}
														loadOptions={loadOptions}
														getOptionValue={(option) => option.name}
														getOptionLabel={(option) => option.name}
														onChange={handleSelectChange}
														placeholder="請選擇"
														additional={{
															page: DEFAULT_PAGE,
														}}
													/>
												</>
											</div>
										</BlockGroup>
									) : (
										<div>
											<TextFieldLabel>補習社名稱 (中文)</TextFieldLabel>
											<TextField placeholder="補習社名稱 (中文)" value={formData.name} name="name" handleChange={handleChange} />
										</div>
									)}
									<TextFieldLabel style={{ marginTop: "10px" }}>Learning Centre Name (English)</TextFieldLabel>
									<TextField
										placeholder="Learning Centre Name (English)"
										value={formData.nameEng}
										name="nameEng"
										handleChange={handleChange}
									/>
								</Block>
							</BlockGroup>

							<div>
								<BlockGroup>
									<Block flex="0 0 320px">
										<TextFieldLabel>補習社簡介</TextFieldLabel>
										<TextField
											placeholder="補習社簡介"
											multiline
											rows={10}
											value={formData.description}
											name="description"
											handleChange={handleChange}
										/>
										<TextFieldLabel style={{ marginTop: "10px" }}>課程類別</TextFieldLabel>
										{formData.category.length > 0 && <CategoryGroup>{renderCategoryList}</CategoryGroup>}
										<AddButtonContainer>
											<Button
												color="primary"
												startIcon={<AddCircleOutline style={{ fontSize: "20px", color: "#000000" }} />}
												onClick={() => {
													handleNewButtonOnClick();
												}}
											>
												<AddButtonText>新增</AddButtonText>
											</Button>
										</AddButtonContainer>
									</Block>
									<LineRight style={{ marginTop: "5px" }} />
									<Block>
										<TextFieldLabel>營業時間</TextFieldLabel>
										<TextField
											placeholder="營業時間"
											multiline
											rows={16}
											value={formData.openingHours}
											name="openingHours"
											handleChange={handleChange}
										/>
									</Block>
								</BlockGroup>
							</div>
						</ContentLeft>
						<ContentRight>
							<div style={{ minHeight: "460px" }}>
								<Block>
									<TextFieldLabel>地址</TextFieldLabel>
									<TextField placeholder="地址" multiline rows={3} value={formData.address} name="address" handleChange={handleChange} />
								</Block>
								<BlockGroup>
									<Block>
										<TextFieldLabel>經度</TextFieldLabel>
										<TextField placeholder="經度" value={formData.longitude} name="longitude" handleChange={handleChange} />
									</Block>
									<Block>
										<TextFieldLabel>緯度</TextFieldLabel>
										<TextField placeholder="緯度" value={formData.latitude} name="latitude" handleChange={handleChange} />
									</Block>
								</BlockGroup>
								<Block>
									<TextFieldLabel>地區</TextFieldLabel>
									<>
										<Select
											name={"district"}
											options={learningCentreConstants.OPTION.district}
											value={formData.district}
											handleChange={handleChange}
										/>
									</>
								</Block>

								<BlockGroup>
									<Block>
										<TextFieldLabel>電話</TextFieldLabel>
										<TextField placeholder="電話" value={formData.phone} name="phone" handleChange={handleChange} />
									</Block>
									<Block>
										<TextFieldLabel>電郵</TextFieldLabel>
										<TextField placeholder="電郵" value={formData.email} name="email" handleChange={handleChange} />
									</Block>
								</BlockGroup>

								<BlockGroup>
									<Block>
										<TextFieldLabel>網址</TextFieldLabel>
										<TextField placeholder="網址" value={formData.website} name="website" handleChange={handleChange} />
									</Block>
									<Block>
										<TextFieldLabel>Whatsapp</TextFieldLabel>
										<TextField placeholder="Whatsapp" value={formData.whatsapp} name="whatsapp" handleChange={handleChange} />
									</Block>
								</BlockGroup>

								<BlockGroup>
									<Block>
										<TextFieldLabel>Facebook 專頁</TextFieldLabel>
										<TextField placeholder="Facebook 專頁" value={formData.facebook} name="facebook" handleChange={handleChange} />
									</Block>
									<Block>
										<TextFieldLabel>Instagram</TextFieldLabel>
										<TextField placeholder="Instagram" value={formData.ig} name="ig" handleChange={handleChange} />
									</Block>
								</BlockGroup>

								<Block>
									<TextFieldLabel>狀態</TextFieldLabel>
									<>
										<Select
											name={"status"}
											options={learningCentreConstants.OPTION.status}
											value={formData.status}
											handleChange={handleChange}
										/>
									</>
								</Block>
							</div>
							<RowInBlock style={{ justifyContent: "flex-end" }}>
								<CancelButton label={"取消"} style={{ marginRight: "15px" }} handleOnClick={handleBack} />
								<SubmitButton
									label={"確認"}
									style={{ marginRight: "15px" }}
									handleOnClick={handleSubmit}
									active={isChanged && !isFetching}
								/>
							</RowInBlock>
						</ContentRight>
					</ContentContainer>
					<AdminFooter></AdminFooter>
				</RightContainer>
			</PageContainer>
		</>
	);
};

const RowInBlock = styled.div`
	padding-top: 30px;
	display: flex;
	flex-direction: row;
`;

const ContentLeft = styled.div`
	flex: 1;
	padding: 10px;
	border-right: 1px solid #dddddd;
`;

const ContentRight = styled.div`
	flex: 1;
	padding: 10px;
`;

const LineRight = styled.div`
	border-right: 1px solid #dddddd;
`;

const BlockGroup = styled.div`
	display: flex;
	flex-direction: row;
`;

const Block = styled.div`
	padding: 10px;
	flex: ${(props) => props.flex || 1};
`;

const CategoryGroup = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
`;

const TextFieldLabel = styled.div`
	color: #000000;
	font-size: 13px;
	line-height: 1.5;
	font-weight: bold;
	margin-bottom: 7px;
`;

const AddButtonContainer = styled.div`
	flex: 1;
	font-size: 14px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	letter-spacing: normal;
	color: #333333;
`;

const AddButtonText = styled.span`
	width: 84px;
	height: 20px;
	font-size: 14px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.43;
	letter-spacing: normal;
	text-align: left;
	color: #000000;
`;
