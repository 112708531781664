import axios from "axios";

export const organizationService = {
	getOrganizations,
	getOrganization,
	approve,
	reject,
	updateOrganization,
	resendVerifyEmail,
};

const httpOptions = {
	headers: {
		"Content-Type": "application/json",
		"Access-Control-Allow-Origin": "*",
	},
	withCredentials: true,
};

async function resendVerifyEmail(form) {
	return axios({
		url: `${process.env.REACT_APP_BACKEND_URL}/organization/resendVerifyEmail`,
		...httpOptions,
		data: form,
		method: "POST",
	})
		.then(handleResponse)
		.catch(handleError);
}

function createFormData(data) {
	let formData = new FormData();

	for (let key in data) {
		let value = data[key];
		if (Array.isArray(value)) {
			value = JSON.stringify(value);
		}
		if (key === "profilePic" && value === "") {
			continue;
		} else {
			formData.append(key, value);
		}
	}
	return formData;
}

async function updateOrganization(data) {
	let formData = createFormData(data);

	return axios({
		url: `${process.env.REACT_APP_BACKEND_URL}/organization`,
		...httpOptions,
		headers: {
			...httpOptions.headers,
			enctype: "multipart/form-data",
		},
		method: "PUT",
		data: formData,
	})
		.then(handleResponse)
		.catch(handleError);
}

async function approve(data) {
	return axios({
		url: `${process.env.REACT_APP_BACKEND_URL}/organization/approve`,
		...httpOptions,
		method: "POST",
		data: data,
	})
		.then(handleResponse)
		.catch(handleError);
}

async function reject(data) {
	return axios({
		url: `${process.env.REACT_APP_BACKEND_URL}/organization/reject`,
		...httpOptions,
		method: "POST",
		data: data,
	})
		.then(handleResponse)
		.catch(handleError);
}

async function getOrganization(data) {
	return axios({
		url: `${process.env.REACT_APP_BACKEND_URL}/organization/${data.key}`,
		...httpOptions,
		method: "GET",
		params: data,
	})
		.then(handleResponse)
		.catch(handleError);
}

async function getOrganizations(data) {
	let formData = data;
	if (formData.status.length > 0) {
		formData.status = JSON.stringify(formData.status);
	}
	return axios({
		url: `${process.env.REACT_APP_BACKEND_URL}/organization/`,
		...httpOptions,
		method: "GET",
		params: formData,
	})
		.then(handleResponse)
		.catch(handleError);
}

function handleError(err) {
	const msg = (err.response && err.response.data.message) || err.message || err;
	throw { error: msg };
}

function handleResponse(res) {
	return res.data;
}
