import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";

import { learningCentreConstants, generalConstants } from "_constants";
import { learningCentreAction } from "actions";
import moment from "moment";
import _ from "lodash";
import isEqual from "react-fast-compare";
import { ReactComponent as OrganizationIcon } from "resources/img/organization.svg";

import ProgressiveRejectDebouncer from "_util/debouncers/ProgressiveRejectDebouncer";
import { history } from "_util/_helper";

import { ArrowDropDown, ArrowDropUp } from "@material-ui/icons";

import { Pagination, RefreshButton } from "components/general";
import { StandardSelect, StandardTextField } from "components/general/standard";
import { AdminMenubar, AdminUserInfo, AdminSectionbar, AdminFooter } from "components/admin";
import {
	PageContainer,
	LeftContainer,
	RightContainer,
	ContentContainer,
	SearchBar,
	SearchBarLeft,
	SearchBarRight,
	PaginationContainer,
	TableContainer,
	TableRow,
	TableTh,
	THButton,
	TableTd,
} from "components/layout";

import { ReactComponent as AddCentreIcon } from "resources/img/addlearningcentreicon.svg";

const DEFAULT_LIMIT = 10;
const DEFAULT_PAGE = 1;
const FROM_CENTER = "learningCenter";
export const LearningCentreManagementPage = () => {
	const { navigationObject } = useSelector(
		({ centre }) => ({
			navigationObject: centre.navigationObject?.root,
		}),
		isEqual
	);

	const { learningCentreList } = useSelector(
		({ centre }) => ({
			learningCentreList: centre.navigationObject?.root?.learningCentreList,
		}),
		isEqual
	);

	const totalItems = useSelector(({ centre }) => centre.navigationObject?.root?.totalItems);
	const currentPage = useSelector(({ centre }) => centre.navigationObject?.root?.currentPage);
	const numOfPage = useSelector(({ centre }) => centre.navigationObject?.root?.numOfPage);
	const sortingKey = useSelector(({ centre }) => centre.navigationObject?.root?.sortingKey);

	const [filterDistrict, setFilterDistrict] = useState(navigationObject?.district);
	const [filterCategory, setFilterCategory] = useState(navigationObject?.category);
	const [searchWord, setSearchWord] = useState(navigationObject?.searchKey);
	const [sortingDirection, setSortingDirection] = useState(navigationObject?.sortingDirection);

	const dispatch = useDispatch();
	const timeoutRef = useRef(null);
	const [textDebouncer] = useState(() => new ProgressiveRejectDebouncer(0.7 * 1000));
	const [debouncer] = useState(() => new ProgressiveRejectDebouncer(1 * 1000));

	const handleSorting = (key) => {
		if (!debouncer.token()) {
			return;
		}
		let direction = "DESC";
		if (sortingKey === key && sortingDirection === "DESC") {
			direction = "ASC";
		}
		setSortingDirection(direction);

		dispatch(
			learningCentreAction.getLearningCenters({
				search: navigationObject?.searchKey,
				district: navigationObject?.district,
				category: navigationObject?.category,
				l: DEFAULT_LIMIT,
				p: DEFAULT_PAGE,
				sort: `${key}:${direction}`,
			})
		);
	};

	const handlePageChange = (pageNum) => {
		if (!debouncer.token() || currentPage === pageNum) {
			return;
		}
		dispatch(
			learningCentreAction.getLearningCenters({
				search: searchWord || "",
				district: filterDistrict || "",
				category: filterCategory || "",
				l: DEFAULT_LIMIT,
				p: pageNum,
				sort: `${sortingKey}:${sortingDirection}`,
			})
		);
	};
	useEffect(() => {
		dispatch(
			learningCentreAction.getLearningCenters({
				search: navigationObject?.searchKey,
				district: navigationObject?.district,
				category: navigationObject?.category,
				l: DEFAULT_LIMIT,
				p: currentPage,
				sort: `${navigationObject?.sortingKey}:${navigationObject?.sortingDirection}`,
			})
		);
	}, [dispatch]);

	const handleRefreshFilter = () => {
		if (filterDistrict === "" && filterCategory === "" && searchWord === "" && sortingDirection === "ASC" && currentPage === DEFAULT_PAGE) {
			return;
		}
		setFilterDistrict("");
		setFilterCategory("");
		setSearchWord("");
		setSortingDirection("ASC");
		dispatch(
			learningCentreAction.getLearningCenters({
				search: "",
				district: "",
				category: "",
				l: DEFAULT_LIMIT,
				p: DEFAULT_PAGE,
				sort: "name:ASC",
			})
		);
	};

	const getDistrict = (item) => {
		if (item === "") {
			return "/";
		}
		return learningCentreConstants.DATA.district[item].label;
	};

	function resetTimeout() {
		if (timeoutRef.current) {
			clearTimeout(timeoutRef.current);
		}
	}

	const handleSelectChange = ({ key, value }) => {
		let searchingWord = false;
		let useDefaultKey = false;
		if (key === "district") {
			setFilterDistrict(value);
		} else if (key === "category") {
			setFilterCategory(value);
		} else if (key === "searchWord") {
			if (searchWord && searchWord.trim().length > 0 && _.isEmpty(value)) {
				useDefaultKey = true;
			}
			setSearchWord(value);
			searchingWord = !_.isEmpty(value);
		}

		if (!textDebouncer.token()) {
			resetTimeout();
			timeoutRef.current = setTimeout(() => {
				dispatch(
					learningCentreAction.getLearningCenters({
						search: key === "searchWord" ? value : searchWord,
						district: key === "district" ? value : filterDistrict,
						category: key === "category" ? value : filterCategory,
						l: DEFAULT_LIMIT,
						p: DEFAULT_PAGE,
						sort: useDefaultKey ? "name:ASC" : searchingWord ? "score:DESC" : `${sortingKey}:${sortingDirection}`,
					})
				);
			}, 1000);
			return;
		}
		resetTimeout();
		dispatch(
			learningCentreAction.getLearningCenters({
				search: key === "searchWord" ? value : searchWord,
				district: key === "district" ? value : filterDistrict,
				category: key === "category" ? value : filterCategory,
				l: DEFAULT_LIMIT,
				p: DEFAULT_PAGE,
				sort: useDefaultKey ? "name:ASC" : searchingWord ? "score:DESC" : `${sortingKey}:${sortingDirection}`,
			})
		);
	};

	const HandleRowOnClick = (item) => {
		history.push({
			pathname: `${generalConstants.PATH.LEARNING_CENTER}`,
			state: { from: FROM_CENTER, learningCenterKey: item._key },
		});
	};

	const handleNewCenter = () => {
		history.push({
			pathname: `${generalConstants.PATH.LEARNING_CENTER}/add`,
			state: { from: FROM_CENTER },
		});
	};

	const renderTH = () => {
		return learningCentreConstants.TABLE_COLUMN.map((column) => {
			return (
				<TableTh key={`${column.key}_table_header`} width={column.width}>
					<THButton key={`${column.key}_table_header`} type="button" onClick={() => handleSorting(column.key)}>
						<div>
							{column.value}
							{sortingKey === column.key ? (
								sortingDirection === "ASC" ? (
									<ArrowDropUp fontSize={"small"} style={{ verticalAlign: "middle" }} />
								) : (
									<ArrowDropDown fontSize={"small"} style={{ verticalAlign: "middle" }} />
								)
							) : null}
						</div>
					</THButton>
				</TableTh>
			);
		});
	};
	const renderTableHeader = renderTH();

	const renderTD = () => {
		if (learningCentreList && learningCentreList?.length > 0) {
			return learningCentreList?.map((center, index) => {
				const tempObj = {};
				learningCentreConstants.TABLE_COLUMN.forEach((column) => {
					tempObj[column.key] = center[column.key];
				});

				return (
					<TableRow
						key={`${center._id}_${index}_tr`}
						onClick={() => {
							HandleRowOnClick(center);
						}}
					>
						{Object.keys(tempObj).map((columnName, index) => {
							if (columnName === "name") {
								var centreName = "";
								if (typeof tempObj[columnName] != "undefined") {
									centreName = tempObj[columnName];

									if (_.isEmpty(centreName)) {
										centreName = center["nameEng"];
									}
								}
								return (
									<TableTd style={{ display: "flex", alignItems: "center" }} key={`${center._id}_${columnName}_${index}_td`}>
										{center.deleted && <DeleteBadge>{"已移除"}</DeleteBadge>}
										{!center.deleted && center.status === learningCentreConstants.DATA.status.CLOSED.key && (
											<InactiveBadge>{learningCentreConstants.DATA.status[center.status].label}</InactiveBadge>
										)}
										{centreName}
										{(center?.lastUpdateBy?.startsWith("organization/") || center?.createdBy?.startsWith("organization/")) && (
											<OrganizationIcon style={{ marginLeft: 5, marginRight: 5, width: 15, height: 19 }} fill={"#333399"} />
										)}
									</TableTd>
								);
							} else if (columnName === "district") {
								return (
									<TableTd key={`${center._id}_${columnName}_${index}_td`}>
										{center.isOnline ? "網上課程" : getDistrict(tempObj[columnName] || "")}
									</TableTd>
								);
							} else if (columnName === "category") {
								var categoryName = "-";
								if (typeof tempObj[columnName] != "undefined" && typeof tempObj[columnName] != "string") {
									tempObj[columnName].forEach((name, index) => {
										if (index === 0) {
											categoryName = `${name}`;
										} else {
											categoryName = `${categoryName}、${name}`;
										}
									});
								}
								return <TableTd key={`${center._id}_${columnName}_${index}_td`}>{categoryName}</TableTd>;
							} else if (columnName === "lastUpdateTime") {
								return (
									<TableTd key={`${center._id}_${columnName}_${index}_td`}>
										{tempObj[columnName] ? moment(tempObj[columnName]).format("YYYY年M月DD日 hh:mmA") : "-"}
									</TableTd>
								);
							}
							return <TableTd key={`${center._id}_${columnName}_${index}_td`}>{tempObj[columnName]}</TableTd>;
						})}
					</TableRow>
				);
			});
		}
	};
	const renderTableCell = renderTD();

	return (
		<>
			<PageContainer>
				<LeftContainer>
					<AdminMenubar section={generalConstants.NAV_TAB.LEARNING_CENTRE_MANAGEMENT.key} />
				</LeftContainer>
				<RightContainer>
					<AdminUserInfo />
					<AdminSectionbar
						title={generalConstants.NAV_TAB.LEARNING_CENTRE_MANAGEMENT.key}
						buttonLabel={"新增補習社"}
						sublabel={`補習社數量: ${totalItems}`}
						handleOnClick={handleNewCenter}
						buttonIcon={<AddCentreIcon />}
						buttonType={generalConstants.BUTTON_TYPE.SUBMIT}
						locationPathArray={[
							{ title: "主頁面", isAction: true, target: generalConstants.PATH.DASHBOARD },
							{ title: generalConstants.TAB_NAME[generalConstants.NAV_TAB.LEARNING_CENTRE_MANAGEMENT.key], isAction: false, target: "" },
						]}
					/>
					<ContentContainer>
						<SearchBar>
							<SearchBarLeft>
								<RowInBlock marginBottom="0px">
									<div style={{ marginLeft: "10px", width: "20%" }}>
										<StandardSelect
											emptyText={"地區"}
											name={"district"}
											displayEmpty
											options={learningCentreConstants.OPTION.district}
											value={filterDistrict}
											handleChange={handleSelectChange}
										/>
									</div>
									<div style={{ marginLeft: "10px", width: "20%" }}>
										<StandardSelect
											emptyText={"課程類別"}
											name={"category"}
											displayEmpty
											options={learningCentreConstants.OPTION.category}
											value={filterCategory}
											handleChange={handleSelectChange}
										/>
									</div>
									<div style={{ marginLeft: "10px", width: "40%" }}>
										<StandardTextField
											placeholder="關鍵字"
											value={searchWord}
											name="searchWord"
											handleChange={handleSelectChange}
											style={{ borderTopRightRadius: "0px", borderBottomRightRadius: "0px" }}
										/>
									</div>
									<RefreshButton style={{ marginLeft: "5px" }} handleOnClick={handleRefreshFilter} label="重置選項"></RefreshButton>
								</RowInBlock>
							</SearchBarLeft>
							<SearchBarRight />
						</SearchBar>
						<TableContainer>
							<table style={{ width: "100%", borderCollapse: "collapse", tableLayout: "fixed" }}>
								<thead>
									<tr>{renderTableHeader}</tr>
								</thead>
								<tbody>{renderTableCell}</tbody>
							</table>
						</TableContainer>
						<PaginationContainer>
							<Pagination count={numOfPage} page={currentPage} handlePageChange={handlePageChange} />
						</PaginationContainer>
					</ContentContainer>
					<AdminFooter></AdminFooter>
				</RightContainer>
			</PageContainer>
		</>
	);
};

const RowInBlock = styled.div`
	display: flex;
	margin-bottom: ${(props) => props.marginBottom || "10px"};
	flex-direction: row;
`;

const DeleteBadge = styled.span`
	padding: 2px 6px 3px;
	border-radius: 3px;
	background-color: #ff5c5c;
	margin: 9px 6px 9px 0;
	font-size: 10px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.5;
	letter-spacing: normal;
	color: white;
`;

const InactiveBadge = styled.span`
	padding: 2px 6px 3px;
	border-radius: 3px;
	background-color: #fff0f2;
	margin: 9px 6px 9px 0;
	font-size: 10px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.5;
	letter-spacing: normal;
	color: #ff0000;
`;
