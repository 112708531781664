import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import isEqual from "react-fast-compare";
import moment from "moment";

import { AdminMenubar, AdminUserInfo, AdminSectionbar, AdminFooter } from "components/admin";
import { Pagination } from "components/general";
import {
	PageContainer,
	LeftContainer,
	RightContainer,
	ContentContainer,
	PaginationContainer,
	TableContainer,
	TableRow,
	TableTh,
	THButton,
	TableTd,
} from "components/layout";

import { generalConstants, coinConstants } from "_constants";
import { coinAction } from "actions";

import ProgressiveRejectDebouncer from "_util/debouncers/ProgressiveRejectDebouncer";

const DEFAULT_PAGE = 1;
const DEFAULT_LIMIT = 10;
const FROM_PARENT = "parent";
const FROM_MISSION = "mission";
export const MissionHistoryPage = () => {
	const dispatch = useDispatch();
	const { navigationObject } = useSelector(({ coin }) => ({ navigationObject: coin.navigationObject?.root }), isEqual);
	const { historyList } = useSelector(({ coin }) => ({ historyList: coin.navigationObject?.root?.historyList }), isEqual);

	const totalItems = useSelector(({ coin }) => coin.navigationObject?.root?.totalItems);
	const currentPage = useSelector(({ coin }) => coin.navigationObject?.root?.currentPage);
	const numOfPage = useSelector(({ coin }) => coin.navigationObject?.root?.numOfPage);
	const currentUserKey = useSelector(({ coin }) => coin.navigationObject?.root?.userKey);
	const coinBalance = useSelector(({ coin }) => coin.navigationObject?.root?.coinBalance);
	const [sortingKey] = useState(navigationObject?.sortingKey || "");
	const [sortingDirection] = useState(navigationObject?.sortingDirection || "");

	const [lastFetchTime] = useState(new Date().getTime());

	const [debouncer] = useState(() => new ProgressiveRejectDebouncer(1 * 1000));

	const location = useLocation();
	const [prevPage, setPrevPage] = useState(location?.state?.from);
	const [key, setParentKey] = useState(location?.state?.parentKey);

	useEffect(() => {
		setPrevPage(location?.state?.from);
		setParentKey(location?.state?.parentKey);
	}, [location?.state]);

	useEffect(() => {
		if (key) {
			let form = {
				userKey: key,
				l: DEFAULT_LIMIT,
				p: currentUserKey != key ? DEFAULT_PAGE : currentPage,
				lastFetchTime: lastFetchTime,
			};
			dispatch(coinAction.getTransactionHistory(form, "root"));
		}
	}, [dispatch]);

	const handlePageChange = (pageNum) => {
		if (!debouncer.token() || currentPage === pageNum) {
			return;
		}
		let form = {
			userKey: key,
			l: DEFAULT_LIMIT,
			p: pageNum,
			sort: `${sortingKey}:${sortingDirection}`,
			lastFetchTime: lastFetchTime,
		};
		dispatch(coinAction.getTransactionHistory(form, "root"));
	};

	const renderTH = () => {
		return coinConstants.TABLE_COLUMN.map((column) => {
			return (
				<TableTh key={`${column.key}_table_header`} width={column.width}>
					<THButton key={`${column.key}_table_header`}>
						<div style={{ textAlign: column.key === "status" ? "center" : "auto" }}>{column.value}</div>
					</THButton>
				</TableTh>
			);
		});
	};
	const renderTableHeader = renderTH();

	const renderTD = () => {
		if (historyList?.length > 0) {
			return historyList?.map((item, index) => {
				const tempObj = {};
				coinConstants.TABLE_COLUMN.forEach((column) => {
					tempObj[column.key] = item[column.key];
				});
				return (
					<TableRow key={`${item._id}_${index}_tr`}>
						{Object.keys(tempObj).map((columnName, index) => {
							if (tempObj[columnName] || tempObj[columnName] === 0) {
								if (columnName === "transactionDate") {
									return (
										<TableTd key={`${item._id}_${columnName}_${index}_td`}>
											{(tempObj[columnName] && moment(tempObj[columnName]).format("YYYY年M月DD日 hh:mm:ssA")) || "-"}
										</TableTd>
									);
								} else if (columnName === "side") {
									return (
										<TableTd key={`${item._id}_${columnName}_${index}_td`}>
											{coinConstants.side[[tempObj[columnName]]]?.label || ""}
										</TableTd>
									);
								} else if (columnName === "type") {
									var value = tempObj[columnName];
									if (value === coinConstants.historyType.REDEEM.key && item.side === coinConstants.side.CR.key) {
										value = coinConstants.historyType.REFUND.key;
									}
									return <TableTd key={`${item._id}_${columnName}_${index}_td`}>{coinConstants.historyType[value]?.label || ""}</TableTd>;
								} else {
									return <TableTd key={`${item._id}_${columnName}_${index}_td`}>{[tempObj[columnName]]}</TableTd>;
								}
							} else {
								return <TableTd key={`${item._id}_${columnName}_${index}_td`}>-</TableTd>;
							}
						})}
					</TableRow>
				);
			});
		}
	};
	const renderTableCell = renderTD();

	const getLocationPath = () => {
		const pathArray = [];

		pathArray.push({ title: "主頁面", isAction: true, target: generalConstants.PATH.DASHBOARD });
		pathArray.push({
			title:
				generalConstants.TAB_NAME[
					prevPage === FROM_MISSION ? generalConstants.NAV_TAB.MISSION_MANAGEMENT.key : generalConstants.NAV_TAB.PARENT_MANAGEMENT.key
				],
			isAction: true,
			target: prevPage === FROM_MISSION ? generalConstants.PATH.MISSION_MANAGEMENT : generalConstants.PATH.PARENT_MANAGEMENT,
		});
		if (prevPage === FROM_MISSION) {
			pathArray.push({
				title: generalConstants.TAB_NAME[generalConstants.NAV_TAB.EDIT_PARENT.key],
				isAction: true,
				target: generalConstants.PATH.EDIT_PARENT,
				stateObj: { from: prevPage, parentKey: key },
			});
		}
		if (prevPage === FROM_PARENT) {
			pathArray.push({
				title: generalConstants.TAB_NAME[generalConstants.NAV_TAB.EDIT_PARENT.key],
				isAction: true,
				target: generalConstants.PATH.EDIT_PARENT,
				stateObj: { from: prevPage, parentKey: key },
			});
		}
		pathArray.push({
			title: generalConstants.TAB_NAME[generalConstants.NAV_TAB.MISSION_HISTORY.key],
			isAction: false,
			target: "",
		});

		return pathArray;
	};

	if (!key) {
		return null;
	}
	return (
		<>
			<PageContainer>
				<LeftContainer>
					<AdminMenubar
						section={
							prevPage === FROM_MISSION ? generalConstants.NAV_TAB.MISSION_MANAGEMENT.key : generalConstants.NAV_TAB.PARENT_MANAGEMENT.key
						}
					/>
				</LeftContainer>
				<RightContainer>
					<AdminUserInfo />
					<AdminSectionbar
						title={generalConstants.NAV_TAB.MISSION_HISTORY.key}
						sublabel={`KOT幣結餘: ${coinBalance || "-"} / 交易數量: ${totalItems || "-"}`}
						locationPathArray={getLocationPath()}
					/>
					<ContentContainer style={{ minHeight: "630px" }}>
						<TableContainer>
							<table style={{ width: "100%", borderCollapse: "collapse", tableLayout: "fixed" }}>
								<thead>
									<tr>{renderTableHeader}</tr>
								</thead>
								<tbody>{renderTableCell}</tbody>
							</table>
						</TableContainer>
						{historyList && historyList.length > 0 && (
							<PaginationContainer style={{ paddingLeft: "20px" }}>
								<Pagination count={numOfPage} page={currentPage} handlePageChange={handlePageChange} />
							</PaginationContainer>
						)}
					</ContentContainer>
					<AdminFooter></AdminFooter>
				</RightContainer>
			</PageContainer>
		</>
	);
};
