import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

import { diffConstants } from "_constants";

import { useSelector, useDispatch } from "react-redux";
import { TextField, SubmitButton } from "components/general";
import { diffAction } from "actions";

import { toast } from "react-toastify";

export const NewDataScrapLinkDialog = (props) => {
	const { handleOnClose, active, schoolName, schoolId, username } = props;
	const { isFetching, addLinkSuccess } = useSelector(({ diff }) => ({
		isFetching: diff.isFetching,
		addLinkSuccess: diff.addLinkSuccess,
	}));

	const dispatch = useDispatch();

	const [website, setWebsite] = useState("");
	const handleChange = ({ value }) => {
		setWebsite(value);
	};

	useEffect(() => {
		if (addLinkSuccess) {
			dispatch({ type: diffConstants.type.ADD_LINK_DONE });
			toast.success("成功新增連結");
			setWebsite("");
			handleOnClose();
		}
	}, [addLinkSuccess]);

	const handleSubmit = () => {
		const data = {
			website: website,
			priority: 1,
			type: "ADMISSION",
			name: schoolName,
			xpath: "/html",
			checkImage: false,
			checkLink: true,
			schoolId: schoolId,
			username: username,
		};

		dispatch(diffAction.addDataScrapWebsite(data));
	};

	return (
		<Dialog
			fullWidth={true}
			maxWidth="sm"
			open={active}
			onClose={handleOnClose}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<DialogTitle id="customized-dialog-title" onClose={handleOnClose}>
				{" "}
				- 新增抓取連結
				<IconButton style={{ position: "absolute", top: 0, right: 0 }} onClick={handleOnClose}>
					<CloseIcon />
				</IconButton>
			</DialogTitle>
			<DialogContent>
				<Block>
					<RowInBlock>
						<TextField placeholder="請填寫連結" value={website} name="website" handleChange={handleChange} />
					</RowInBlock>
				</Block>
			</DialogContent>
			<DialogActions>
				<SubmitButton active={!isFetching} label={"確認"} handleOnClick={handleSubmit} />
			</DialogActions>
		</Dialog>
	);
};

const Block = styled.div`
	margin-top: ${(props) => props.marginTop || "10px"};
	flex-direction: column;
`;

const RowInBlock = styled.div`
	display: flex;
	margin-bottom: ${(props) => props.marginBottom || "10px"};
	flex-direction: row;
`;

NewDataScrapLinkDialog.propTypes = {
	handleOnClose: PropTypes.func,
	active: PropTypes.bool,
	schoolName: PropTypes.string,

	schoolId: PropTypes.string,

	username: PropTypes.string,
};

NewDataScrapLinkDialog.defaultProps = {
	active: true,
	schoolName: "",
	schoolId: "",
	username: "",
};
