import React from "react";
import PropTypes from "prop-types";
//import { useDispatch } from "react-redux";
import styled from "styled-components";
import { history } from "_util/_helper";

import { generalConstants } from "_constants";
//import { authAction } from "actions";
import { WarningButton, ImportButton, SubmitButton } from "components/general";

export const AdminSectionbar = (props) => {
	const { title, buttonLabel, handleOnClick, label, sublabel, buttonIcon, buttonType, bigSublabel, locationPathArray } = props;

	//const dispatch = useDispatch();

	const locationPath = `主頁面 > ${generalConstants.TAB_NAME[title]}`;

	return (
		<Container>
			<SectionLayer>
				<RowInBlock style={{ justifyContent: "space-between" }}>
					<BlockLeft>
						{sublabel && (
							<div
								style={{
									display: "flex",
									flexDirection: "row",
								}}
							>
								<Title>{label ? label : generalConstants.TAB_NAME[title]}</Title>
								{bigSublabel ? <BigSubTitle>- {sublabel} </BigSubTitle> : <SubTitle>( {sublabel} )</SubTitle>}
							</div>
						)}
						{!sublabel && <Title>{label ? label : generalConstants.TAB_NAME[title]}</Title>}
						{locationPathArray.length === 0 ? (
							<LocationPath>{locationPath}</LocationPath>
						) : (
							<RowInBlock style={{ height: "20px", alignItems: "center" }}>
								{locationPathArray.map((path, index) => {
									return (
										<RowInBlock key={`location_path_${index}`} style={{ height: "20px", alignItems: "center" }}>
											{path.isBack ? (
												<LocationTitle
													onClick={() => {
														history.goBack(path.target);
													}}
												>
													{path.title}
												</LocationTitle>
											) : path.isAction ? (
												<LocationTitle
													onClick={() => {
														if (typeof path.stateObj != "undefined" && path.stateObj != null) {
															history.push({
																pathname: path.target,
																state: path.stateObj,
															});
														} else {
															history.push(path.target);
														}
													}}
												>
													{path.title}
												</LocationTitle>
											) : (
												<LocationPath>{path.title}</LocationPath>
											)}
											{locationPathArray.length - 1 != index && (
												<LocationPath
													style={{
														paddingLeft: "5px",
														paddingRight: "5px",
													}}
												>{` > `}</LocationPath>
											)}
										</RowInBlock>
									);
								})}
							</RowInBlock>
						)}
					</BlockLeft>
					<BlockRight>
						{buttonLabel && buttonType === generalConstants.BUTTON_TYPE.WARNING && (
							<WarningButton label={buttonLabel} handleOnClick={handleOnClick} buttonIcon={buttonIcon} />
						)}
						{buttonLabel && buttonType === generalConstants.BUTTON_TYPE.IMPORT && (
							<ImportButton label={buttonLabel} handleOnClick={handleOnClick} buttonIcon={buttonIcon} />
						)}
						{buttonLabel && buttonType === generalConstants.BUTTON_TYPE.SUBMIT && (
							<SubmitButton style={{ padding: "8px 34px" }} label={buttonLabel} handleOnClick={handleOnClick} buttonIcon={buttonIcon} />
						)}
					</BlockRight>
				</RowInBlock>
			</SectionLayer>
		</Container>
	);
};

AdminSectionbar.propTypes = {
	title: PropTypes.string,
	label: PropTypes.any,
	sublabel: PropTypes.string,
	buttonLabel: PropTypes.string,
	handleOnClick: PropTypes.func,
	buttonType: PropTypes.string,
	buttonIcon: PropTypes.object,
	bigSublabel: PropTypes.bool,
	locationPathArray: PropTypes.array,
};

AdminSectionbar.defaultProps = {
	buttonType: generalConstants.BUTTON_TYPE.WARNING,
	locationPathArray: [],
};

const Container = styled.div`
	width: 100%;
`;

const SectionLayer = styled.div`
	height: 60px;
	background-color: #f2f2f2;
	padding: 5px 20px;
`;

const RowInBlock = styled.div`
	height: 100%;
	display: flex;
	flex-direction: row;
`;

const BlockLeft = styled.div`
	flex: 1;
`;
const BlockRight = styled.div`
	flex: 0 0 200px;
	text-align: right;
	padding-top: 12px;
`;

const Title = styled.div`
	font-size: 24px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.5;
	letter-spacing: normal;
	text-align: left;
	color: #333399;
	max-width: 90%;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
`;

const SubTitle = styled.div`
	margin: 10px 10px 6px 10px;
	font-size: 11px;
	font-weight: 500;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.55;
	letter-spacing: normal;
	text-align: left;
	color: #666666;
`;

const LocationTitle = styled.div`
	color: #0085b5;
	font-size: 12px;
	font-weight: bold;
	line-height: 1.5;
	text-align: left;
	max-width: 150px;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
`;

const LocationPath = styled.div`
	color: #333333;
	font-size: 12px;
	font-weight: bold;
	line-height: 1.5;
	text-align: left;
	max-width: 150px;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
`;

const BigSubTitle = styled.div`
	margin: 5px 5px 6px 10px;
	font-size: 20px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.45;
	letter-spacing: normal;
	color: #333399;
`;
