import { authConstants, reportConstants } from "_constants";

const initialState = {
	isFetching: false,
	bypassReportSuccess: false,
	handleReportSuccess: false,
	report: {},
	navigationObject: {
		root: {
			searchWord: "",
			sortingKey: "status",
			sortingDirection: "DESC",
			numOfPage: 1,
			currentPage: 1,
			totalItems: 0,
			reportList: [],
		},
	},
};

const reportReducer = (state = initialState, action) => {
	switch (action.type) {
		case authConstants.type.UNAUTHORIZED_ERROR: {
			return { ...state, currentPage: 1 };
		}
		case reportConstants.type.RESET_REPORT_SUCCESS: {
			return {
				...state,
				bypassReportSuccess: false,
				handleReportSuccess: false,
			};
		}
		case reportConstants.type.FETCH_REPORT_LIST_REQUEST: {
			return {
				...state,
				isFetching: true,
			};
		}
		case reportConstants.type.FETCH_REPORT_LIST_FAILURE: {
			return {
				...state,
				isFetching: false,
			};
		}
		case reportConstants.type.FETCH_REPORT_LIST_SUCCESS: {
			return {
				...state,
				isFetching: false,
				navigationObject: {
					root: {
						reportList: action.reportList,
						totalItems: action.totalNum,
						numOfPage: action.totalPage,
						currentPage: action.currentPage,
						searchWord: action.searchWord,
						sortingKey: action.sortingKey,
						sortingDirection: action.sortingDirection,
					},
				},
			};
		}
		case reportConstants.type.FETCH_REPORT_TARGET_REQUEST: {
			return {
				...state,
				isFetching: true,
				report: {},
			};
		}
		case reportConstants.type.FETCH_REPORT_TARGET_FAILURE: {
			return {
				...state,
				isFetching: false,
			};
		}
		case reportConstants.type.FETCH_REPORT_TARGET_SUCCESS: {
			return {
				...state,
				isFetching: false,
				report: action.report,
			};
		}

		case reportConstants.type.BYPASS_REPORT_REQUEST: {
			return {
				...state,
				bypassReportSuccess: false,
			};
		}
		case reportConstants.type.BYPASS_REPORT_FAILURE: {
			return {
				...state,
				bypassReportSuccess: false,
			};
		}
		case reportConstants.type.BYPASS_REPORT_SUCCESS: {
			return {
				...state,
				bypassReportSuccess: true,
			};
		}

		case reportConstants.type.HANDLE_REPORT_REQUEST: {
			return {
				...state,
				handleReportSuccess: false,
			};
		}
		case reportConstants.type.HANDLE_REPORT_FAILURE: {
			return {
				...state,
				handleReportSuccess: false,
			};
		}
		case reportConstants.type.HANDLE_REPORT_SUCCESS: {
			return {
				...state,
				handleReportSuccess: true,
			};
		}

		default: {
			return state;
		}
	}
};

export default reportReducer;
