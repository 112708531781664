import { combineReducers } from "redux";

import dashboardReducer from "./dashboard.reducer";
import messageReducer from "./message.reducer";
import tokenReducer from "./token.reducer";
import adminReducer from "./admin.reducer";
import authReducer from "./auth.reducer";
import schoolReducer from "./school.reducer";
import reportReducer from "./report.reducer";
import learningCentreReducer from "./learningCentre.reducer";
import learningCoursesReducer from "./learningCourses.reducer";
import organizationReducer from "./organization.reducer";

import { authConstants } from "_constants";
import parentReducer from "./parent.reducer";
import templateReducer from "./template.reducer";
import diffReducer from "./diff.reducer";
import missionReducer from "./mission.reducer";
import coinReducer from "./coin.reducer";
import socialNetworkReducer from "./socialNetwork_reducer";

const appReducer = combineReducers({
	dashboard: dashboardReducer,
	message: messageReducer,
	token: tokenReducer,
	admin: adminReducer,
	auth: authReducer,
	school: schoolReducer,
	report: reportReducer,
	parent: parentReducer,
	centre: learningCentreReducer,
	course: learningCoursesReducer,
	organization: organizationReducer,
	template: templateReducer,
	diff: diffReducer,
	mission: missionReducer,
	coin: coinReducer,
	socialNetwork: socialNetworkReducer,
});

const rootReducer = (state, action) => {
	// clear all state from LOGOUT action.
	if (action.type === authConstants.type.LOGOUT) {
		state = {
			auth: state.auth,
		};
	}
	return appReducer(state, action);
};

export default rootReducer;
