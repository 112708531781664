import axios from "axios";

export const parentService = {
	fetchParent,
	getParentByKey,
	verifyParent,
	unverifyParent,
	updateParent,
};

const httpOptions = {
	headers: {
		"Content-Type": "application/json",
	},
	withCredentials: true,
};

async function fetchParent(form) {
	return axios({
		url: `${process.env.REACT_APP_BACKEND_URL}/socialnetwork/parent`,
		...httpOptions,
		method: "GET",
		params: form,
	})
		.then(handleResponse)
		.catch(handleError);
}

async function getParentByKey(key) {
	return axios({
		url: `${process.env.REACT_APP_BACKEND_URL}/socialnetwork/parent/${key}`,
		...httpOptions,
		method: "GET",
	})
		.then(handleResponse)
		.catch(handleError);
}

async function updateParent(data) {
	return axios({
		url: `${process.env.REACT_APP_BACKEND_URL}/socialnetwork/parent/`,
		...httpOptions,
		method: "PUT",
		data: data,
	})
		.then(handleResponse)
		.catch(handleError);
}

async function verifyParent(key) {
	return axios({
		url: `${process.env.REACT_APP_BACKEND_URL}/socialnetwork/parent/verify/`,
		...httpOptions,
		method: "POST",
		data: { key: key },
	})
		.then(handleResponse)
		.catch(handleError);
}

async function unverifyParent(key) {
	return axios({
		url: `${process.env.REACT_APP_BACKEND_URL}/socialnetwork/parent/unverify/`,
		...httpOptions,
		method: "POST",
		data: { key: key },
	})
		.then(handleResponse)
		.catch(handleError);
}

function handleError(err) {
	const msg = (err.response && err.response.data.message) || err.message || err;
	throw { error: msg };
}

function handleResponse(res) {
	return res.data;
}
