/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useState, useEffect } from "react";
import { Prompt } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { messageConstants, generalConstants } from "_constants";
import { messageAction } from "actions";

import { history } from "_util/_helper";
import PropTypes from "prop-types";
import { SubmitButton, CancelButton, Loading } from "components/general";
import { toast } from "react-toastify";
import Button from "@material-ui/core/Button";
import moment from "moment";
import editIcon from "resources/img/school_inner_pencil.svg";

import { AdminMenubar, AdminUserInfo, AdminSectionbar, AdminFooter } from "components/admin";
import { PageContainer, LeftContainer, RightContainer, ContentContainer } from "components/layout";

const FROM_SCHOOL = "school";
const FROM_DATASCRAP = "dataScrap";
const FROM_ORGANIZATION = "organization";
export const MsgConfirmPage = (props) => {
	const { from, schoolKey, message, schoolVipOnly } = props.location.state;

	const { isFetching, submitMessageSuccess, updateMessageSuccess, loggedIn, organizationObj } = useSelector(
		({ message, auth, organization }) => {
			const res = {
				isFetching: message.isFetching,
				updateMessageSuccess: message.updateMessageSuccess,
				submitMessageSuccess: message.submitMessageSuccess,
				loggedIn: auth.loggedIn,
			};
			if (from === FROM_ORGANIZATION) res.organizationObj = organization.organization;
			return res;
		}
	);

	const [isLoading, setIsLoading] = useState(false);

	const [isSaved, setIsSaved] = useState(false);
	const dispatch = useDispatch();

	const handleBack = () => {
		history.goBack();
	};

	const handleExit = () => {
		history.push(
			from === FROM_ORGANIZATION
				? {
						pathname: `${generalConstants.PATH.ORGANIZATION}/school`,
						state: { from: from, schoolKey: schoolKey },
				  }
				: from === FROM_SCHOOL
				? {
						pathname: generalConstants.PATH.SCHOOL_DETAIL,
						state: { from: from, schoolKey: schoolKey },
				  }
				: from === FROM_DATASCRAP
				? {
						pathname: generalConstants.PATH.DATA_SCRAP_DETAIL,
						state: {
							from: from,
							dataScrapKey: schoolKey,
							school: {
								_id: message.schoolId,
								name: [message.schoolName],
								level: message.schoolLevel,
								session: [message.schoolSession],
								district: message.schoolDistrict,
								vipOnly: message.schoolVipOnly,
							},
						},
				  }
				: generalConstants.PATH.MSG_MANAGEMENT
		);
	};

	useEffect(() => {
		setIsLoading(isFetching);
	}, [isFetching]);

	useEffect(() => {
		if (updateMessageSuccess) {
			if (!isSaved) {
				setIsSaved(true);
				return;
			}
			dispatch({ type: messageConstants.type.RESET_MSG_SUCCESS });
			toast.success("已更新訊息");
			history.replace(
				from === FROM_ORGANIZATION
					? {
							pathname: `${generalConstants.PATH.ORGANIZATION}/school`,
							state: { from: from, schoolKey: schoolKey },
					  }
					: from === FROM_SCHOOL
					? {
							pathname: generalConstants.PATH.SCHOOL_DETAIL,
							state: { from: from, schoolKey: schoolKey },
					  }
					: from === FROM_DATASCRAP
					? {
							pathname: generalConstants.PATH.DATA_SCRAP_DETAIL,
							state: {
								from: from,
								dataScrapKey: schoolKey,
								school: {
									_id: message.schoolId,
									name: [message.schoolName],
									level: message.schoolLevel,
									session: [message.schoolSession],
									district: message.schoolDistrict,
									vipOnly: message.schoolVipOnly,
								},
							},
					  }
					: generalConstants.PATH.MSG_MANAGEMENT
			);
		}
	}, [updateMessageSuccess, isSaved, dispatch]);

	useEffect(() => {
		if (submitMessageSuccess) {
			if (!isSaved) {
				setIsSaved(true);
				return;
			}
			dispatch({ type: messageConstants.type.RESET_MSG_SUCCESS });
			toast.success("已建立訊息");
			history.replace(
				from === FROM_ORGANIZATION
					? {
							pathname: `${generalConstants.PATH.ORGANIZATION}/school`,
							state: { from: from, schoolKey: schoolKey },
					  }
					: from === FROM_SCHOOL
					? {
							pathname: generalConstants.PATH.SCHOOL_DETAIL,
							state: { from: from, schoolKey: schoolKey },
					  }
					: from === FROM_DATASCRAP
					? {
							pathname: generalConstants.PATH.DATA_SCRAP_DETAIL,
							state: {
								from: from,
								dataScrapKey: schoolKey,
								school: {
									_id: message.schoolId,
									name: [message.schoolName],
									level: message.schoolLevel,
									session: [message.schoolSession],
									district: message.schoolDistrict,
									vipOnly: message.schoolVipOnly,
								},
							},
					  }
					: generalConstants.PATH.MSG_MANAGEMENT
			);
		}
	}, [submitMessageSuccess, isSaved, dispatch]);

	const handleSubmit = () => {
		if (!message.type) {
			toast.error("請填寫訊息類別");
			return;
		}
		if (!message.infoType) {
			toast.error("請填寫資訊分類");
			return;
		}
		if (message.infoType === messageConstants.DATA.infoType.SCHOOL || message.infoType === messageConstants.DATA.infoType.EB) {
			if (!message.schoolId) {
				toast.error("請填寫學校");
				return;
			}
		}
		if (!message.title) {
			toast.error("請填寫標題");
			return;
		}
		if (message.key) {
			dispatch(messageAction.updateAdminMsg(message));
		} else {
			dispatch(messageAction.createAdminMsg(message));
		}
	};

	const checkOnLeave = (location, action) => {
		return action === "POP" || isSaved || !loggedIn ? true : "訊息並未儲存，確定離開？";
	};

	const getLocationPath = () => {
		const pathArray = [];

		pathArray.push({ title: "主頁面", isAction: true, target: generalConstants.PATH.DASHBOARD });
		if (from === FROM_SCHOOL || from === FROM_ORGANIZATION) {
			pathArray.push({
				title:
					generalConstants.TAB_NAME[
						from === FROM_ORGANIZATION
							? generalConstants.NAV_TAB.ORGANIZATION_MANAGEMENT.key
							: generalConstants.NAV_TAB.SCHOOL_MANAGEMENT.key
					],
				isAction: true,
				target: from === FROM_ORGANIZATION ? generalConstants.PATH.ORGANIZATION_MANAGEMENT : generalConstants.PATH.SCHOOL_MANAGEMENT,
			});
			if (from === FROM_ORGANIZATION) {
				pathArray.push({
					title: organizationObj.name || "",
					isAction: true,
					target: `${generalConstants.PATH.ORGANIZATION}`,
					stateObj: { from: from, organizationKey: organizationObj._key },
				});
			}
			pathArray.push({
				title: message?.schoolName || "",
				isAction: true,
				target: from === FROM_ORGANIZATION ? `${generalConstants.PATH.ORGANIZATION}/school` : generalConstants.PATH.SCHOOL_DETAIL,
				stateObj: { from: from, schoolKey: schoolKey },
			});
		} else if (from === FROM_DATASCRAP) {
			pathArray.push({
				title: generalConstants.TAB_NAME[generalConstants.NAV_TAB.DATA_SCRAP.key],
				isAction: true,
				target: generalConstants.PATH.DATA_SCRAP,
			});
			pathArray.push({
				title: generalConstants.TAB_NAME[generalConstants.NAV_TAB.DATA_SCRAP_DETAIL.key],
				isAction: true,
				target: generalConstants.PATH.DATA_SCRAP_DETAIL,
				stateObj: {
					from: from,
					dataScrapKey: schoolKey,
					school: {
						_id: message.schoolId,
						name: [message.schoolName],
						level: message.schoolLevel,
						session: [message.schoolSession],
						district: message.schoolDistrict,
						vipOnly: message.schoolVipOnly,
					},
				},
			});
		} else {
			pathArray.push({
				title: generalConstants.TAB_NAME[generalConstants.NAV_TAB.MSG_MANAGEMENT.key],
				isAction: true,
				target: generalConstants.PATH.MSG_MANAGEMENT,
			});
		}
		pathArray.push({
			title: generalConstants.TAB_NAME[generalConstants.NAV_TAB.MSG_CONFIRM.key],
			isAction: false,
			target: "",
		});

		return pathArray;
	};

	return (
		<>
			{!!isLoading && <Loading />}
			<Prompt message={checkOnLeave} />
			<PageContainer>
				<LeftContainer>
					<AdminMenubar
						section={
							from === FROM_ORGANIZATION
								? generalConstants.NAV_TAB.ORGANIZATION_MANAGEMENT.key
								: from === FROM_SCHOOL
								? generalConstants.NAV_TAB.SCHOOL_MANAGEMENT.key
								: from === FROM_DATASCRAP
								? generalConstants.NAV_TAB.DATA_SCRAP.key
								: generalConstants.NAV_TAB.MSG_MANAGEMENT.key
						}
					/>
				</LeftContainer>
				<RightContainer>
					<AdminUserInfo />
					<AdminSectionbar title={generalConstants.NAV_TAB.MSG_CONFIRM.key} locationPathArray={getLocationPath()} />
					<ContentContainer>
						<Button style={{ float: "right" }} onClick={handleBack}>
							<img src={editIcon} alt="" style={{ paddingRight: "5px" }} />
							修改訊息
						</Button>

						<RowInBlock>
							<ContentTop>
								<Block>
									<Subtitle>訊息類別</Subtitle>
									<Semicolon>:</Semicolon>
									<TextFieldLabel>{messageConstants.Translate.type[message.type]}</TextFieldLabel>
								</Block>
								{message.type === messageConstants.DATA.type.NEWS && (
									<Block>
										<Subtitle>資訊分類</Subtitle>
										<Semicolon>:</Semicolon>
										<OverviewContainer>
											{schoolVipOnly && (
												<VipIconView>
													<VipText>VIP</VipText>
												</VipIconView>
											)}
											<TextFieldLabel>{message.schoolName}</TextFieldLabel>
										</OverviewContainer>
									</Block>
								)}
								{message.targetAudience && (message.type === messageConstants.OPTION.type[1].key || schoolVipOnly) && (
									<Block>
										<Subtitle>發送對象</Subtitle>
										<Semicolon>:</Semicolon>
										<TextFieldLabel>{messageConstants.Translate.targetAudience[message.targetAudience]}</TextFieldLabel>
									</Block>
								)}
								<Block>
									<Subtitle>發送時間</Subtitle>
									<Semicolon>:</Semicolon>
									<TextFieldLabel>
										{message.scheduleTime ? moment(message.scheduleTime).format("YYYY年MM月DD日 hh:mmA") : "即時"}
									</TextFieldLabel>
								</Block>
							</ContentTop>

							<ContentBottom>
								<Block>
									<Subtitle>標題</Subtitle>
									<Semicolon>:</Semicolon>
									<TextFieldLabel>
										{message.category ? `[${messageConstants.Translate.category[message.category]}]` : ""} {message.title}
									</TextFieldLabel>
								</Block>
								<Block>
									<Subtitle>內容</Subtitle>
									<Semicolon>:</Semicolon>
									<TextFieldLabel style={{ whiteSpace: "pre-wrap" }}>{message.detail}</TextFieldLabel>
								</Block>

								<>
									{!message.fullDay && (
										<Block>
											<Subtitle>日期及時間</Subtitle>
											<Semicolon>:</Semicolon>
											<TextFieldLabel>
												{message.startTime ? moment(message.startTime).format("YYYY年MM月DD日 hh:mmA") : ""} ~{" "}
												{message.endTime ? moment(message.endTime).format("YYYY年MM月DD日 hh:mmA") : ""}
											</TextFieldLabel>
										</Block>
									)}
									{message.fullDay && (
										<Block>
											<Subtitle>日期及時間</Subtitle>
											<Semicolon>:</Semicolon>
											<TextFieldLabel>
												{message.startTime ? moment(message.startTime).format("YYYY年MM月DD日") : ""} ~{" "}
												{message.endTime ? moment(message.endTime).format("YYYY年MM月DD日") : ""} (全日)
											</TextFieldLabel>
										</Block>
									)}

									<Block>
										<Subtitle>連結</Subtitle>
										<Semicolon>:</Semicolon>
										<RowInBlock>
											<TextFieldLabel>{message.url ? message.url : "-"}</TextFieldLabel>
										</RowInBlock>
									</Block>
								</>
							</ContentBottom>
						</RowInBlock>
						<Block marginTop={"10px"} style={{ justifyContent: "flex-end" }}>
							<CancelButton label={"取消"} style={{ marginRight: "15px" }} handleOnClick={handleExit} />
							<SubmitButton active={!isFetching} label={"提交"} handleOnClick={handleSubmit} />
						</Block>
					</ContentContainer>
					<AdminFooter></AdminFooter>
				</RightContainer>
			</PageContainer>
		</>
	);
};

MsgConfirmPage.propTypes = {
	location: PropTypes.object,
};

const ContentTop = styled.div`
	flex: 1;
	border-bottom: 2px solid #666666;
`;

const ContentBottom = styled.div`
	flex: 1;
	padding-top: 20px;
`;

const Block = styled.div`
	display: flex;
	margin-bottom: 25px;
	flex-direction: row;
`;

const RowInBlock = styled.div`
	display: flex;
	margin-bottom: ${(props) => props.marginBottom || "10px"};
	flex-direction: column;
`;

const Subtitle = styled.div`
	flex: 0 0 100px;
	color: black;
	font-stretch: normal;
	font-style: normal;
	letter-spacing: normal;

	font-size: 13px;
	font-weight: bold;
	line-height: 1.15;
	text-align: left;
	color: #000000;
`;

const Semicolon = styled.div`
	font-size: 13px;
	font-weight: 500;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.15;
	letter-spacing: normal;
	text-align: left;
	color: #000000;
	padding: 0 10px;
`;

const TextFieldLabel = styled.div`
	flex: 1;
	color: black;
	font-size: 13px;

	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.15;
	letter-spacing: normal;
	text-align: left;
	color: #333333;
`;

const OverviewContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
`;

const VipIconView = styled.div`
	display: flex;
	width: 32px;
	flex-direction: row;
	margin-right: 5px;
`;

const VipText = styled.div`
	font-size: 12;
	font-weight: bold;
	color: #333333;
	background-color: #c8ae63;
	padding-left: 5px;
	padding-right: 5px;
`;
