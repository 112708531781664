import { organizationConstants, generalConstants } from "_constants";
import { organizationService } from "services";
import { toast } from "react-toastify";
import { history } from "_util/_helper";

export const organizationAction = {
	getOrganizations,
	getOrganization,
	approve,
	reject,
	updateOrganization,
	resendVerifyEmail,
};

function resendVerifyEmail(data) {
	organizationService.resendVerifyEmail(data).then(
		() => {
			toast.success("已重發認證電郵");
		},
		(error) => {
			toast.error(error.error);
		}
	);
}

function updateOrganization(data) {
	return (dispatch) => {
		dispatch(request());
		organizationService.updateOrganization(data).then(
			(response) => {
				dispatch(success(response));
				toast.success("更新成功");
			},
			(error) => {
				toast.error(error.error);
				dispatch(failure());
			}
		);
	};

	function request() {
		return { type: organizationConstants.type.UPDATE_ORGANIZATION_REQUEST };
	}
	function failure() {
		return { type: organizationConstants.type.UPDATE_ORGANIZATION_FAILURE };
	}
	function success(organization) {
		return {
			type: organizationConstants.type.UPDATE_ORGANIZATION_SUCCESS,
			organization,
		};
	}
}

function reject(data) {
	return (dispatch) => {
		dispatch(request());
		organizationService.reject(data).then(
			(response) => {
				dispatch(success(response));
				toast.success("更新成功");
			},
			(error) => {
				toast.error(error.error);
				dispatch(failure());
			}
		);
	};

	function request() {
		return { type: organizationConstants.type.UPDATE_ORGANIZATION_REQUEST };
	}
	function failure() {
		return { type: organizationConstants.type.UPDATE_ORGANIZATION_FAILURE };
	}
	function success(organization) {
		return {
			type: organizationConstants.type.UPDATE_ORGANIZATION_SUCCESS,
			organization,
		};
	}
}

function approve(data) {
	return (dispatch) => {
		dispatch(request());
		organizationService.approve(data).then(
			(response) => {
				dispatch(success(response));
				toast.success("更新成功");
			},
			(error) => {
				toast.error(error.error);
				dispatch(failure());
			}
		);
	};

	function request() {
		return { type: organizationConstants.type.UPDATE_ORGANIZATION_REQUEST };
	}
	function failure() {
		return { type: organizationConstants.type.UPDATE_ORGANIZATION_FAILURE };
	}
	function success(organization) {
		return {
			type: organizationConstants.type.UPDATE_ORGANIZATION_SUCCESS,
			organization,
		};
	}
}

function getOrganization(data) {
	return (dispatch) => {
		dispatch(request());
		organizationService.getOrganization(data).then(
			(response) => {
				if (!data.includeSubsidiary) {
					dispatch(success(response));
				} else {
					dispatch(success(response, data));
				}
			},
			(error) => {
				toast.error(error.error);
				dispatch(failure());
				if (error.error?.status !== 401) {
					history.push(generalConstants.PATH.ORGANIZATION_MANAGEMENT);
				}
			}
		);
	};

	function request() {
		return { type: organizationConstants.type.FETCH_ORGANIZATION_REQUEST };
	}
	function failure() {
		return { type: organizationConstants.type.FETCH_ORGANIZATION_FAILURE };
	}

	function success(response, data) {
		if (data?.includeSubsidiary) {
			let sortingKey = "createdTime",
				sortingDirection = "DESC";
			const sortingArray = data?.sort?.split(":");
			if (sortingArray && sortingArray.length === 2 && sortingArray[0] && sortingArray[1]) {
				sortingKey = sortingArray[0];
				sortingDirection = sortingArray[1] === "DESC" ? "DESC" : "ASC";
			}
			const learningCenterList = response.learningCenterList;
			const schoolList = response.schoolList;
			let navigationObject = {
				center: {
					learningCentreList: learningCenterList.items,
					totalItems: learningCenterList.totalItems,
					numOfPage:
						learningCenterList?.totalItems / 10 === parseInt(learningCenterList?.totalItems / 10)
							? parseInt(learningCenterList?.totalItems / 10)
							: parseInt(learningCenterList?.totalItems / 10) + 1,
					currentPage: data.p,
					sortingKey,
					sortingDirection,
				},
				school: {
					schoolList: schoolList.items,
					totalItems: schoolList.totalItems,
					numOfPage:
						schoolList.totalItems / 10 === parseInt(schoolList.totalItems / 10)
							? parseInt(schoolList.totalItems / 10)
							: parseInt(schoolList.totalItems / 10) + 1,
					currentPage: data.p,
					sortingKey,
					sortingDirection,
				},
			};
			return {
				type: organizationConstants.type.FETCH_ORGANIZATION_SUCCESS,
				includeSubsidiary: true,
				organization: response.organization,
				navigationObject: navigationObject,
			};
		} else {
			return {
				type: organizationConstants.type.FETCH_ORGANIZATION_SUCCESS,
				organization: response,
			};
		}
	}
}

function getOrganizations(data) {
	return (dispatch) => {
		dispatch(request());
		organizationService.getOrganizations(data).then(
			(response) => {
				dispatch(success(response));
			},
			(error) => {
				toast.error(error.error);
				dispatch(failure());
			}
		);
	};

	function request() {
		return { type: organizationConstants.type.FETCH_ORGANIZATION_LIST_REQUEST };
	}
	function failure() {
		return { type: organizationConstants.type.FETCH_ORGANIZATION_LIST_FAILURE };
	}
	function success(response) {
		const { totalItems, items } = response;
		let sortingKey = "name",
			sortingDirection = "ASC";
		const sortingArray = data?.sort?.split(":");
		if (sortingArray && sortingArray.length === 2 && sortingArray[0] && sortingArray[1]) {
			sortingKey = sortingArray[0];
			sortingDirection = sortingArray[1] === "DESC" ? "DESC" : "ASC";
		}
		return {
			type: organizationConstants.type.FETCH_ORGANIZATION_LIST_SUCCESS,
			totalNum: totalItems,
			totalPage: Math.ceil(totalItems / data.l),
			currentPage: data.p,
			organizationList: items,
			search: data.search,
			sortingKey,
			sortingDirection,
			status: typeof (data?.status || []) === "string" ? JSON.parse(data?.status) : data?.status || [],
		};
	}
}
