export const tokenConstants = {
	type: {
		GET_TOKEN_INFO_REQUEST: "GET_TOKEN_INFO_REQUEST",
		GET_TOKEN_INFO_FAILURE: "GET_TOKEN_INFO_FAILURE",
		GET_TOKEN_INFO_SUCCESS: "GET_TOKEN_INFO_SUCCESS",
		RESET_TOKEN_INFO_SUCCESS: "RESET_TOKEN_INFO_SUCCESS",
	},
	action: {
		ADMIN_REGISTRATION: "ADMIN_REGISTRATION",
		ADMIN_RESET_PASSWORD: "ADMIN_RESET_PASSWORD",
	},
};
