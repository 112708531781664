import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import xDate from "xdate";
import isEqual from "react-fast-compare";

import ProgressiveRejectDebouncer from "_util/debouncers/ProgressiveRejectDebouncer";

import { schoolConstants, diffConstants, generalConstants } from "_constants";
import { diffAction } from "actions";

import { history } from "_util/_helper";
import { Loading, Pagination, AlertDialog } from "components/general";
import { StandardSelect, StandardTextField } from "components/general/standard";

import { ArrowDropDown, ArrowDropUp } from "@material-ui/icons";

import { ReactComponent as LinkIcon } from "resources/img/linkicon.svg";

import { AdminMenubar, AdminUserInfo, AdminSectionbar } from "components/admin";
import {
	PageContainer,
	LeftContainer,
	RightContainer,
	ContentContainer,
	SearchBar,
	SearchBarLeft,
	SearchBarRight,
	PaginationContainer,
	TableContainer,
} from "components/layout";

const DEFAULT_PAGE = 1;
const DEFAULT_LIMIT = 10;
export const DataScrapPage = () => {
	const { navigationObject } = useSelector(
		({ diff }) => ({
			navigationObject: diff.navigationObject?.root,
		}),
		isEqual
	);

	const { dataScrapList } = useSelector(({ diff }) => ({ dataScrapList: diff.navigationObject?.root?.dataScrapList }), isEqual);

	const isFetching = useSelector(({ diff }) => diff.isFetching);
	const totalItems = useSelector(({ diff }) => diff.navigationObject?.root?.totalItems);
	const currentPage = useSelector(({ diff }) => diff.navigationObject?.root?.currentPage);
	const numOfPage = useSelector(({ diff }) => diff.navigationObject?.root?.numOfPage);

	const initSearchForm = {
		name: "",
		level: "",
		district: "",
		gender: "",
		schoolNet: "",
		page: DEFAULT_PAGE,
		limit: DEFAULT_LIMIT,
	};

	const searchFilter = {
		level: navigationObject?.dataScrapSearchFilter?.level || "",
		district: navigationObject?.dataScrapSearchFilter?.district || "",
		gender: navigationObject?.dataScrapSearchFilter?.gender || "",
		schoolNet: navigationObject?.dataScrapSearchFilter?.schoolNet || "",
		name: navigationObject?.dataScrapSearchFilter?.name || "",
		page: currentPage,
		limit: DEFAULT_LIMIT,
		sort:
			navigationObject?.sortingKey && navigationObject?.sortingDirection
				? `${navigationObject?.sortingKey}:${navigationObject?.sortingDirection}`
				: "noOfDiff:DESC",
	};

	const timeoutRef = useRef(null);

	const [searchForm, setSearchForm] = useState(searchFilter || initSearchForm);
	const [isLoading, setIsLoading] = useState(true);
	const [showDiffConfirmation, setShowDiffConfirmation] = useState(false);

	const [sortingKey, setSortingKey] = useState(navigationObject?.sortingKey || "noOfDiff");
	const [sortingDirection, setSortingDirection] = useState(navigationObject?.sortingDirection || "DESC");

	const dispatch = useDispatch();
	const [textDebouncer] = useState(() => new ProgressiveRejectDebouncer(0.7 * 1000));
	const [debouncer] = useState(() => new ProgressiveRejectDebouncer(1.0 * 1000));

	useEffect(() => {
		handleDataScrapSearch(searchForm);
	}, []);

	useEffect(() => {
		setIsLoading(isFetching);
	}, [isFetching]);

	const HandleRowOnClick = (item) => {
		history.push({
			pathname: `${generalConstants.PATH.DATA_SCRAP_DETAIL}`,
			state: {
				dataScrapKey: item._key,
				school: {
					_id: `school/${item._key}`,
					_key: item._key,
					name: item.name,
					level: item.level,
					session: item.session,
					gender: item.gender,
					district: item.district,
					vipOnly: item.vipOnly,
				},
			},
		});
	};

	const handleDataScrapSearch = (form) => {
		let newForm = { page: form.page, limit: form.limit };
		if (form.level) {
			newForm = { ...newForm, level: form.level };
		}
		if (form.district) {
			newForm = { ...newForm, district: form.district };
		}
		if (form.gender) {
			newForm = { ...newForm, gender: form.gender };
		}
		if (form.schoolNet) {
			newForm = { ...newForm, schoolNet: form.schoolNet };
		}
		if (form.name) {
			newForm = { ...newForm, name: form.name };
		}
		if (form.sort) {
			const sortObj = form.sort.split(":");
			if (sortObj[0] && sortObj[1]) {
				newForm = { ...newForm, sort: form.sort };
			}
		}
		dispatch(diffAction.getDataScrapList(newForm, "root"));
	};

	const handlePageChange = (pageNum) => {
		if (!debouncer.token() || currentPage === pageNum) {
			return;
		}
		let form = {
			...searchForm,
			page: pageNum,
			limit: DEFAULT_LIMIT,
		};
		dispatch(diffAction.getDataScrapList(form, "root"));
	};

	function resetTimeout() {
		if (timeoutRef.current) {
			clearTimeout(timeoutRef.current);
		}
	}

	const handleSelectChange = ({ key, value }) => {
		if (key === "name") {
			setSearchForm({ ...searchForm, [key]: value, page: DEFAULT_PAGE });
			if (!textDebouncer.token()) {
				resetTimeout();
				timeoutRef.current = setTimeout(() => {
					handleDataScrapSearch({ ...searchForm, [key]: value, page: DEFAULT_PAGE });
					setSearchForm({ ...searchForm, [key]: value, page: DEFAULT_PAGE });
				}, 1000);
				return;
			}
		}
		resetTimeout();
		handleDataScrapSearch({ ...searchForm, [key]: value, page: DEFAULT_PAGE });
		setSearchForm({ ...searchForm, [key]: value, page: DEFAULT_PAGE });
	};

	const handleSortAction = (sort) => {
		if (!debouncer.token()) {
			return;
		}
		let direction = "DESC";
		if (sortingKey === sort && sortingDirection === "DESC") {
			direction = "ASC";
		}
		setSortingKey(sort);
		setSortingDirection(direction);
		setSearchForm({ ...searchForm, page: DEFAULT_PAGE, sort: `${sort}:${direction}` });
		handleDataScrapSearch({ ...searchForm, page: DEFAULT_PAGE, sort: `${sort}:${direction}` });
	};

	const handleRefreshFilter = () => {
		if (
			searchForm.district === "" &&
			searchForm.gender === "" &&
			searchForm.level === "" &&
			searchForm.name === "" &&
			searchForm.schoolNet === "" &&
			searchForm.page === DEFAULT_PAGE &&
			searchForm.sort === "noOfDiff:DESC"
		) {
			return;
		}
		setSortingKey("");
		setSortingDirection("");
		setSearchForm(initSearchForm);
		handleDataScrapSearch(initSearchForm);
	};

	const handleGenerateDiffOnClick = () => {
		setShowDiffConfirmation(true);
	};

	const handleGenerateDiffOnClose = () => {
		setShowDiffConfirmation(false);
	};

	const handleGenerateDiffYes = () => {
		dispatch(diffAction.genAllDiff());
		setShowDiffConfirmation(false);
	};

	const renderTH = () => {
		return diffConstants.DATA_SCRAP_TABLE_COLUMN.map((column) => {
			return (
				<TableTh key={`${column.key}_table_header`} width={column.width}>
					<THButton key={`${column.key}_table_header`} type="button" onClick={() => handleSortAction(column.key)}>
						<div style={{ marginLeft: "10px" }}>
							{column.value}
							{sortingKey === column.key ? (
								sortingDirection === "ASC" ? (
									<ArrowDropUp fontSize={"small"} style={{ verticalAlign: "middle" }} />
								) : (
									<ArrowDropDown fontSize={"small"} style={{ verticalAlign: "middle" }} />
								)
							) : null}
						</div>
					</THButton>
				</TableTh>
			);
		});
	};
	const renderTableHeader = renderTH();

	const renderTD = () => {
		if (dataScrapList?.length > 0) {
			return dataScrapList?.map((item, index) => {
				const tempObj = {};
				diffConstants.DATA_SCRAP_TABLE_COLUMN.forEach((column) => {
					tempObj[column.key] = item[column.key];
				});

				return (
					<TableRow
						key={`${item._id}_${index}_tr`}
						onClick={() => {
							HandleRowOnClick(item);
						}}
					>
						{Object.keys(tempObj).map((columnName, index) => {
							if (tempObj[columnName]) {
								if (columnName === "name") {
									return (
										<TableTd key={`${item._id}_${columnName}_${index}_td`}>
											<OverviewContainer>
												{item.status === schoolConstants.status.CLOSED.key && <ClosedBadge>已停辦</ClosedBadge>}
												{item.vipOnly && (
													<VipIconView>
														<VipText>VIP</VipText>
													</VipIconView>
												)}
												<span style={{ color: "#0085b5" }}>{tempObj[columnName][0] + " "}</span>
												{item.hasLink && <LinkIcon />}
											</OverviewContainer>
										</TableTd>
									);
								} else if (columnName === "overallSubscription") {
									return (
										<TableTd key={`${item._id}_${columnName}_${index}_td`}>
											{" "}
											<font color="#333399">{tempObj[columnName]}</font>
										</TableTd>
									);
								} else if (columnName === "numMsg") {
									return (
										<TableTd key={`${item._id}_${columnName}_${index}_td`}>
											{" "}
											<font color="#333399">{tempObj[columnName]}</font>
										</TableTd>
									);
								} else if (columnName === "overallRank") {
									return (
										<TableTd key={`${item._id}_${columnName}_${index}_td`}>
											{" "}
											<font color="#333399">{tempObj[columnName]}</font>
										</TableTd>
									);
								} else if (columnName === "noOfDiff") {
									return (
										<TableTd key={`${item._id}_${columnName}_${index}_td`}>
											<font color="#ff0000">{tempObj[columnName]}</font>
										</TableTd>
									);
								} else if (columnName === "lastUpdateTime") {
									return (
										<TableTd key={`${item._id}_${columnName}_${index}_td`}>
											{tempObj[columnName] ? xDate(tempObj[columnName]).toString("yyyy年M月dd日 h:mmtt") : "-"}
										</TableTd>
									);
								} else {
									return (
										<TableTd key={`${item._id}_${columnName}_${index}_td`}>
											{schoolConstants[columnName][tempObj[columnName]] ? schoolConstants[columnName][tempObj[columnName]].label : ""}
										</TableTd>
									);
								}
							} else {
								return (
									<TableTd key={`${item._id}_${columnName}_${index}_td`}>
										{" "}
										<font color="#999999">
											{diffConstants.DATA_SCRAP_TABLE_COLUMN.find((x) => x.key === columnName)?.isNumber ? 0 : "-"}
										</font>
									</TableTd>
								);
							}
						})}
					</TableRow>
				);
			});
		}
	};
	const renderTableCell = renderTD();

	return (
		<>
			<AlertDialog
				active={showDiffConfirmation}
				handleOnClose={handleGenerateDiffOnClose}
				handleOnYes={handleGenerateDiffYes}
				title={`確定產生數據比較？`}
			/>
			{!!isLoading && <Loading />}
			<PageContainer>
				<LeftContainer>
					<AdminMenubar section={generalConstants.NAV_TAB.DATA_SCRAP.key} />
				</LeftContainer>
				<RightContainer>
					<AdminUserInfo />
					<AdminSectionbar
						title={generalConstants.NAV_TAB.DATA_SCRAP.key}
						sublabel={`數據數量: ${totalItems || "-"}`}
						locationPathArray={[
							{ title: "主頁面", isAction: true, target: generalConstants.PATH.DASHBOARD },
							{
								title: generalConstants.TAB_NAME[generalConstants.NAV_TAB.DATA_SCRAP.key],
								isAction: false,
								target: "",
							},
						]}
					/>
					<ContentContainer>
						<SearchBar>
							<SearchBarLeft>
								<RowInBlock marginBottom="0px">
									<div style={{ width: "30%", marginRight: "10px", maxWidth: 200 }}>
										<StandardSelect
											emptyText={"學校類型"}
											displayEmpty
											name={"level"}
											options={schoolConstants.OPTION.level}
											value={searchForm.level}
											handleChange={handleSelectChange}
										/>
									</div>
									<div style={{ width: "30%", marginRight: "10px", maxWidth: 150 }}>
										<StandardSelect
											emptyText={"地區"}
											displayEmpty
											name={"district"}
											options={schoolConstants.OPTION.district}
											value={searchForm.district}
											handleChange={handleSelectChange}
										/>
									</div>
									<div style={{ width: "30%", marginRight: "10px", maxWidth: 100 }}>
										<StandardSelect
											emptyText={"校網"}
											displayEmpty
											name={"schoolNet"}
											options={schoolConstants.OPTION.schoolNet}
											value={searchForm.schoolNet}
											handleChange={handleSelectChange}
										/>
									</div>
									<div style={{ width: "30%", marginRight: "10px", maxWidth: 100 }}>
										<StandardSelect
											emptyText={"性別"}
											displayEmpty
											name={"gender"}
											options={schoolConstants.OPTION.gender}
											value={searchForm.gender}
											handleChange={handleSelectChange}
										/>
									</div>
									<div style={{ width: "40%", marginRight: "0px" }}>
										<StandardTextField placeholder="學校名稱" value={searchForm.name} name="name" handleChange={handleSelectChange} />
									</div>
									<RefreshButton onClick={handleRefreshFilter}>重置選項</RefreshButton>
								</RowInBlock>
							</SearchBarLeft>
							<SearchBarRight>
								<RowInBlockRight marginBottom="0px">
									<DiffButton onClick={handleGenerateDiffOnClick}>產生數據比較</DiffButton>
								</RowInBlockRight>
							</SearchBarRight>
						</SearchBar>
						<TableContainer>
							<table
								style={{
									width: "100%",
									borderCollapse: "collapse",
									tableLayout: "fixed",
								}}
							>
								<thead>
									<tr>{renderTableHeader}</tr>
								</thead>
								<tbody>{renderTableCell}</tbody>
							</table>
						</TableContainer>
						<PaginationContainer>
							<Pagination count={numOfPage} page={currentPage} handlePageChange={handlePageChange} />
						</PaginationContainer>
					</ContentContainer>
				</RightContainer>
			</PageContainer>
		</>
	);
};

const RefreshButton = styled.div`
	background-color: #ffffff;
	color: #7d7d7d;
	display: flex;
	min-width: 56px;
	border-radius: 30px;
	justify-content: center;
	align-items: center;
	size: "small";
	border: solid 1px #dedede;
	font-size: 12px;
	margin-left: 5px;
	padding-left: 5px;
	padding-right: 5px;
	line-height: 1.5;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	&:hover {
		background: #eeeeee;
		cursor: pointer;
	}
`;

const TableRow = styled.tr`
	border-bottom: 1px solid #dddddd;
	&:hover {
		background: #eeeeee;
		cursor: pointer;
	}
`;

const TableTh = styled.th`
	border-bottom: 2px solid black;
	padding: 15px;
	position: relative;
	height: 20px;
	min-width: 70px;
`;

const THButton = styled.div`
	border: none;
	color: black;
	font-weight: bold;
	width: 100%;
	text-align: left;
	text-decoration: none;
	line-height: 50px;
	position: absolute;
	right: 0;
	top: 0;
	height: 100%;
	cursor: pointer;

	&:hover {
		background: #eeeeee;
	}
`;

const TableTd = styled.td`
	font-size: 13px;
	font-weight: 500;
	height: 20px;
	padding: 10px;
`;

const RowInBlock = styled.div`
	display: flex;
	margin-bottom: ${(props) => props.marginBottom || "10px"};
	flex-direction: row;
`;

const RowInBlockRight = styled.div`
	display: flex;
	margin-bottom: ${(props) => props.marginBottom || "10px"};
	flex-direction: row;
	justify-content: right;
`;

const ClosedBadge = styled.span`
	padding: 2px 6px 3px;
	border-radius: 3px;
	background-color: #fff0f2;
	margin: 9px 6px 9px 0;
	font-size: 10px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.5;
	letter-spacing: normal;
	color: #ff0000;
`;

const DiffButton = styled.div`
	background-color: #ffffff;
	color: #7d7d7d;
	display: flex;
	min-width: 56px;
	border-radius: 30px;
	justify-content: center;
	align-items: center;
	size: "small";
	border: solid 1px #dedede;
	font-size: 12px;
	margin-left: 5px;
	padding-left: 5px;
	padding-right: 5px;
	line-height: 1.5;
	&:hover {
		background: #eeeeee;
		cursor: pointer;
	}
`;

const OverviewContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
`;

const VipIconView = styled.div`
	display: flex;
	width: 32px;
	flex-direction: row;
	margin-right: 5px;
`;

const VipText = styled.div`
	font-size: 12;
	font-weight: bold;
	color: #333333;
	background-color: #c8ae63;
	padding-left: 5px;
	padding-right: 5px;
`;
