import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import MuiSwitch from "@material-ui/core/Switch";

export const Switch = ({ checked, handleChange }) => {
	const useStyles = makeStyles(() => ({
		checkedOverride: {
			color: "#333399 !important",
		},
		trackOverride: {
			backgroundColor: "#333399 !important",
		},
	}));

	const classes = useStyles();

	const handleOnChange = (event, value) => {
		handleChange(value);
	};

	return (
		<MuiSwitch
			checked={checked}
			onChange={handleOnChange}
			name="checkedA"
			size="small"
			classes={{ checked: classes.checkedOverride, track: classes.trackOverride }}
		/>
	);
};

Switch.propTypes = {
	checked: PropTypes.bool,
	handleChange: PropTypes.func,
};
