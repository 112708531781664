import React from "react";
import styled from "styled-components";
import CircularProgress from "@material-ui/core/CircularProgress";

export const Loading = () => {
	return (
		<Container>
			<LoadingBox>
				<CircularProgress />
			</LoadingBox>
		</Container>
	);
};

const Container = styled.div`
	height: 100%;
	width: 100%;
	background-color: white;
	position: fixed;
	z-index: 100;
	opacity: 0.7;
`;

const LoadingBox = styled.div`
	height: 100%;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
`;
