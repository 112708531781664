import React from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

export const ModalDialog = (props) => {
    const { title, children, yesLabel, /*noLabel,*/ handleOnYes, handleOnClose, active, fullWidth, maxWidth } = props;
   
	return (
		<Dialog open={active} onClose={handleOnClose}  fullWidth={fullWidth} maxWidth={maxWidth} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
			<DialogTitle id="alert-dialog-title">{title}</DialogTitle>
			{handleOnClose && (
				<IconButton style={{ position: "absolute", top: 0, right: 0 }} onClick={handleOnClose}>
					<CloseIcon />
				</IconButton>
			)}
			{children && (
				<DialogContent>{children}</DialogContent>
			)}
			<DialogActions>
                {handleOnYes && (
                    <Button onClick={handleOnYes} color="primary" autoFocus>
                        {yesLabel}
                    </Button>
                )}
			</DialogActions>
		</Dialog>
	);
};

ModalDialog.propTypes = {
	title: PropTypes.string,
	children: PropTypes.any,
	yesLabel: PropTypes.string,
	//noLabel: PropTypes.string,
	handleOnYes: PropTypes.func,
	handleOnClose: PropTypes.func,
    active: PropTypes.bool,
    fullWidth: PropTypes.bool,
    maxWidth: PropTypes.string,
};

ModalDialog.defaultProps = {
	title: "Title",
	children: null,
	yesLabel: "是",
	//noLabel: "否",
    active: true,
    fullWidth: true,
    maxWidth: 'md',
};
