import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { generalConstants, organizationConstants, learningCentreConstants, schoolConstants } from "_constants";
import { schoolAction, learningCentreAction, organizationAction } from "actions";
import moment from "moment";
import _ from "lodash";
import { history, AvatarHelper } from "_util/_helper";
import {
	ApprovalSelect,
	TextField,
	SubmitButton,
	Loading,
	Pagination,
	OrganizationStatus,
	WhatsAppButton,
	FacebookButton,
	IGButton,
} from "components/general";
import { useLocation, Prompt } from "react-router-dom";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import { ArrowDropDown, ArrowDropUp } from "@material-ui/icons";
import isEqual from "react-fast-compare";
import Button from "@material-ui/core/Button";
import ArrowLeftIcon from "@material-ui/icons/ArrowLeft";
import { toast } from "react-toastify";
import { ReactComponent as OrganizationIcon } from "resources/img/organization.svg";
import editIcon from "resources/img/school_inner_pencil.svg";

import { AdminMenubar, AdminUserInfo, AdminSectionbar, AdminFooter } from "components/admin";
import {
	PageContainer,
	LeftContainer,
	RightContainer,
	PaginationContainer,
	TableRow,
	TableTh,
	THButton,
	TableTd,
	SearchBar,
	SearchBarLeft,
} from "components/layout";

import { ReactComponent as AddCentreIcon } from "resources/img/addlearningcentreicon.svg";
import { ReactComponent as AddSchoolIcon } from "resources/img/addschool.svg";

import ProgressiveRejectDebouncer from "_util/debouncers/ProgressiveRejectDebouncer";

const DEFAULT_LIMIT = 10;
const DEFAULT_PAGE = 1;
const FROM_ORGANIZATION = "organization";
const CENTER_TYPE = "LEARNING_CENTER";
const SCHOOL_TYPE = "SCHOOL";
export const OrganizationDetailScreen = () => {
	const isFetching = useSelector(({ organization }) => organization.isFetching);
	const updateOrganizationSuccess = useSelector(({ organization }) => organization.updateOrganizationSuccess);

	const { organization, learningCentreList, schoolList } = useSelector(
		({ organization, centre, school }) => ({
			organization: organization.organization,
			learningCentreList: centre?.navigationObject?.organization?.learningCentreList,
			schoolList: school?.navigationObject?.organization?.schoolList,
		}),
		isEqual
	);
	const { navigationObject } = useSelector(({ school, centre }) => {
		const res = {};
		if (organization.type === CENTER_TYPE) res.navigationObject = centre?.navigationObject?.organization;
		if (organization.type === SCHOOL_TYPE) res.navigationObject = school?.navigationObject?.organization;
		return res;
	}, isEqual);

	const [currentPage, setCurrentPage] = useState(navigationObject?.currentPage || 1);
	const [numOfPage, setNumOfPage] = useState(navigationObject?.numOfPage || 1);
	const [totalItems, setTotalItems] = useState(navigationObject?.totalItems || 0);

	const [debouncer] = useState(() => new ProgressiveRejectDebouncer(1 * 1000));

	const location = useLocation();
	const [key, setOrganizationKey] = useState(location?.state?.organizationKey);

	useEffect(() => {
		setOrganizationKey(location?.state?.organizationKey);
	}, [location?.state]);

	useEffect(() => {
		setCurrentPage(navigationObject?.currentPage || 1);
		setNumOfPage(navigationObject?.numOfPage || 1);
		setTotalItems(navigationObject?.totalItems || 0);
	}, [navigationObject]);

	const initForm = {
		key: "",
		createdTime: "",
		type: "",
		approvedBy: "",
		approvalTime: "",
		lastUpdateTime: "",
		lastUpdateBy: "",
		remark: "",
		name: "",
		status: "",
		approvalStatus: "",
		profilePic: "",
		address: "",
		email: "",
		phone: "",
		website: "",
		whatsapp: "",
		facebook: "",
		contactPerson: "",
		isMultiCenter: "",
		numOfCenter: "",
		firstLogin: "",
		rejectReason: "",
	};

	const [formData, setFormData] = useState(initForm);
	const [label, setLabel] = useState("");
	const [isLoading, setIsLoading] = useState(false);
	const [isChanged, setIsChanged] = useState(false);
	const [showRejectReason, setShowRejectReason] = useState(false);
	const [sortingKey, setSortingKey] = useState(navigationObject?.sortingKey);
	const [sortingDirection, setSortingDirection] = useState(navigationObject?.sortingDirection);
	const [originalStatus, setOrginalStatus] = useState("");

	const dispatch = useDispatch();

	useEffect(() => {
		if (key) {
			if (organization?._key === key) {
				dispatch(
					organizationAction.getOrganization({
						key,
						includeSubsidiary: true,
						p: currentPage,
						sort: `${navigationObject?.sortingKey}:${navigationObject?.sortingDirection}`,
					})
				);
			} else {
				setSortingKey("createdTime");
				setSortingDirection("DESC");
				dispatch(
					organizationAction.getOrganization({
						key,
						includeSubsidiary: true,
						p: 1,
						sort: `createdTime:DESC`,
					})
				);
			}
		}
	}, []);

	useEffect(() => {
		if (typeof organization != "undefined" && organization?._key === key) {
			const data = {
				key: organization._key,
				rev: organization._rev,
				type: organization.type,
				createdTime: organization.createdTime,
				approvedBy: organization.approvedBy || "",
				approvalTime: organization.approvalTime || "",
				lastUpdateTime: organization.lastUpdateTime || "",
				lastUpdateBy: organization.lastUpdateBy || "",
				remark: organization.remark || "",
				name: organization.name,
				status: organization.status || "",
				approvalStatus: organization.approvalStatus || "",
				profilePic: organization.profilePic || "",
				address: organization.address,
				email: organization.email,
				phone: organization.phone,
				website: organization.website || "",
				whatsapp: organization.whatsapp || "",
				facebook: organization.facebook || "",
				contactPerson: organization.contactPerson,
				isMultiCenter: organization.isMultiCenter,
				numOfCenter: organization.numOfCenter,
				firstLogin: organization.firstLogin,
				rejectReason: organization.rejectReason || "",
			};
			setOrginalStatus(organization.approvalStatus);
			setShowRejectReason(organization.approvalStatus === organizationConstants.approvalStatus.REJECTED);
			setFormData(data);
			setLabel(data.name);
		}
	}, [organization]);

	useEffect(() => {
		setIsLoading(isFetching);
		if (isChanged && !isFetching && updateOrganizationSuccess) {
			setIsChanged(false);
			history.goBack();
		}
	}, [isFetching, updateOrganizationSuccess]);

	const handleSubmit = () => {
		if (originalStatus === organizationConstants.approvalStatus.REJECTED) {
			dispatch(organizationAction.updateOrganization(formData));
		} else {
			if (formData.approvalStatus === organizationConstants.approvalStatus.PENDING) {
				dispatch(organizationAction.updateOrganization(formData));
			} else if (formData.approvalStatus === organizationConstants.approvalStatus.REJECTED) {
				if (!formData.rejectReason || formData.rejectReason.trim().length === 0) {
					toast.error("請填寫拒絕原因");
					return;
				}
				dispatch(organizationAction.reject(formData));
			} else if (formData.approvalStatus === organizationConstants.approvalStatus.APPROVED) {
				dispatch(organizationAction.approve(formData));
			}
		}
	};

	const handlePageChange = (pageNum) => {
		if (!debouncer.token() || currentPage === pageNum) {
			return;
		}
		if (organization.type === CENTER_TYPE) {
			dispatch(
				learningCentreAction.getLearningCenters({
					isOrganizationDetail: true,
					organizationKey: organization._key,
					l: DEFAULT_LIMIT,
					p: pageNum,
					sort: `${sortingKey}:${sortingDirection}`,
				})
			);
		} else if (organization.type === SCHOOL_TYPE) {
			dispatch(
				schoolAction.getSchoolList(
					{
						organizationKey: organization._key,
						page: pageNum,
						limit: DEFAULT_LIMIT,
						sort: `${sortingKey}:${sortingDirection}`,
					},
					"organization"
				)
			);
		}
	};

	const handleLearningCenterSorting = (key) => {
		if (!debouncer.token()) {
			return;
		}
		let direction = "DESC";
		if (sortingKey === key && sortingDirection === "DESC") {
			direction = "ASC";
		}
		setSortingKey(key);
		setSortingDirection(direction);
		dispatch(
			learningCentreAction.getLearningCenters({
				isOrganizationDetail: true,
				organizationKey: organization._key,
				l: DEFAULT_LIMIT,
				p: DEFAULT_PAGE,
				sort: `${key}:${direction}`,
			})
		);
	};

	const handleSchoolSorting = (key) => {
		if (!debouncer.token()) {
			return;
		}
		let direction = "DESC";
		if (sortingKey === key && sortingDirection === "DESC") {
			direction = "ASC";
		}
		setSortingKey(key);
		setSortingDirection(direction);
		dispatch(
			schoolAction.getSchoolList(
				{
					organizationKey: organization._key,
					page: DEFAULT_PAGE,
					limit: DEFAULT_LIMIT,
					sort: `${key}:${direction}`,
				},
				"organization"
			)
		);
	};

	const getDistrict = (item) => {
		if (item === "") {
			return "/";
		}
		return learningCentreConstants.DATA.district[item].label;
	};

	const HandleRowOnClick = (item) => {
		if (organization.type === SCHOOL_TYPE) {
			history.push({
				pathname: `${generalConstants.PATH.ORGANIZATION}/school`,
				state: { from: FROM_ORGANIZATION, schoolKey: item._key },
			});
		} else {
			history.push({
				pathname: `${generalConstants.PATH.ORGANIZATION}/learningCenter/`,
				state: { from: FROM_ORGANIZATION, learningCenterKey: item._key },
			});
		}
	};

	const handleNewCenter = () => {
		history.push({
			pathname: `${generalConstants.PATH.ORGANIZATION}/learningCenter/add`,
			state: { from: FROM_ORGANIZATION, organizationKey: key },
		});
	};

	const handleNewSchool = () => {
		history.push({
			pathname: `${generalConstants.PATH.ORGANIZATION}/newschool`,
			state: { from: FROM_ORGANIZATION, organizationKey: key },
		});
	};

	const handleEdit = () => {
		history.push({
			pathname: `${generalConstants.PATH.ORGANIZATION}/edit`,
			state: { organizationKey: key },
		});
	};

	const handleChange = ({ key, value }) => {
		setIsChanged(true);
		setFormData({ ...formData, [key]: value });
		if (key === "approvalStatus") {
			if (value === organizationConstants.approvalStatus.REJECTED) {
				setShowRejectReason(true);
			} else {
				setShowRejectReason(false);
			}
		}
	};

	const getText = (text) => {
		return text ? text.replace(/\n/g, "<br />") : "";
	};

	const handleBack = () => {
		history.goBack();
	};

	const renderTH = () => {
		if (organization.type === SCHOOL_TYPE && schoolList?.length > 0) {
			return schoolConstants.ORGANIZATION_VIEW_SCHOOL_TABLE_COLUMN.map((column) => {
				return (
					<TableTh key={`${column.key}_table_header`} width={column.width}>
						<THButton
							style={
								column.key === "overallSubscription" || column.key === "currentYearSubscription" ? { borderLeft: "#666666 1px solid" } : {}
							}
							key={`${column.key}_table_header`}
							type="button"
							onClick={() => handleSchoolSorting(column.key)}
						>
							<div style={{ textAlign: column.key === "status" ? "center" : "auto" }}>
								{column.key === "vipOnly" ? (
									<VipIconView>
										<VipText>{column.value}</VipText>
										{sortingKey === column.key ? (
											sortingDirection === "ASC" ? (
												<ArrowDropUp fontSize={"small"} style={{ verticalAlign: "middle" }} />
											) : (
												<ArrowDropDown fontSize={"small"} style={{ verticalAlign: "middle" }} />
											)
										) : null}
									</VipIconView>
								) : (
									column.value
								)}
								{sortingKey === column.key && column.key != "vipOnly" ? (
									sortingDirection === "ASC" ? (
										<ArrowDropUp fontSize={"small"} style={{ verticalAlign: "middle" }} />
									) : (
										<ArrowDropDown fontSize={"small"} style={{ verticalAlign: "middle" }} />
									)
								) : null}
							</div>
						</THButton>
					</TableTh>
				);
			});
		} else if (organization.type === CENTER_TYPE && learningCentreList?.length > 0) {
			return learningCentreConstants.ORGANIZATION_VIEW_LEARNING_CENTER_COLUMN.map((column) => {
				return (
					<TableTh key={`${column.key}_table_header`} width={column.width}>
						<THButton key={`${column.key}_table_header`} type="button" onClick={() => handleLearningCenterSorting(column.key)}>
							<div style={{ textAlign: column.key === "status" || column.key === "hasSocialAccount" ? "center" : "auto" }}>
								{column.value}
								{sortingKey === column.key ? (
									sortingDirection === "ASC" ? (
										<ArrowDropUp fontSize={"small"} style={{ verticalAlign: "middle" }} />
									) : (
										<ArrowDropDown fontSize={"small"} style={{ verticalAlign: "middle" }} />
									)
								) : null}
							</div>
						</THButton>
					</TableTh>
				);
			});
		} else {
			return <></>;
		}
	};
	const renderTableHeader = renderTH();

	const renderTD = () => {
		const column =
			organization.type === SCHOOL_TYPE
				? schoolConstants.ORGANIZATION_VIEW_SCHOOL_TABLE_COLUMN
				: organization.type === CENTER_TYPE
				? learningCentreConstants.ORGANIZATION_VIEW_LEARNING_CENTER_COLUMN
				: [];
		const list = organization.type === SCHOOL_TYPE ? schoolList : organization.type === CENTER_TYPE ? learningCentreList : [];
		const tempObj = {};
		if (list?.length > 0) {
			return list?.map((item, index) => {
				column.forEach((column) => {
					tempObj[column.key] = item[column.key];
				});
				return (
					<TableRow
						key={`${item._id}_${index}_tr`}
						onClick={() => {
							HandleRowOnClick(item);
						}}
					>
						{Object.keys(tempObj).map((columnName, index) => {
							if (organization.type === SCHOOL_TYPE) {
								if (tempObj[columnName] || tempObj[columnName] === 0) {
									if (columnName === "name") {
										return (
											<TableTd key={`${item._id}_${columnName}_${index}_td`}>
												{item.status === schoolConstants.status.CLOSED.key && <ClosedBadge>已停辦</ClosedBadge>}
												{tempObj[columnName][0]}
											</TableTd>
										);
									} else if (columnName === "numMsg") {
										return <TableTd key={`${item._id}_${columnName}_${index}_td`}>{tempObj[columnName]}</TableTd>;
									} else if (columnName === "vipOnly") {
										return (
											<TableTd key={`${item._id}_${columnName}_${index}_td`}>
												{tempObj[columnName] && (
													<VipIconView>
														<VipText>VIP</VipText>
													</VipIconView>
												)}
											</TableTd>
										);
									} else {
										return (
											<TableTd key={`${item._id}_${columnName}_${index}_td`}>
												{schoolConstants[columnName][tempObj[columnName]] ? schoolConstants[columnName][tempObj[columnName]].label : ""}
											</TableTd>
										);
									}
								} else {
									return <TableTd key={`${item._id}_${columnName}_${index}_td`}>{columnName === "vipOnly" ? "" : "-"}</TableTd>;
								}
							} else if (organization.type === CENTER_TYPE) {
								if (columnName === "name") {
									var centreName = "";
									if (typeof tempObj[columnName] != "undefined") {
										centreName = tempObj[columnName];
										if (_.isEmpty(centreName)) {
											centreName = item["nameEng"];
										}
									}
									return (
										<TableTd key={`${item._id}_${columnName}_${index}_td`} style={{ display: "flex", alignItems: "center" }}>
											{item.deleted === true && <DeleteBadge>{"已移除"}</DeleteBadge>}
											{!item.deleted && item.status === learningCentreConstants.DATA.status.CLOSED.key && (
												<InactiveBadge>{learningCentreConstants.DATA.status[item.status].label}</InactiveBadge>
											)}
											{centreName}
											{(item?.lastUpdateBy?.startsWith("organization/") || item?.createdBy?.startsWith("organization/")) && (
												<div style={{ marginLeft: 5, marginRight: 5, width: 15, height: 19 }}>
													<OrganizationIcon style={{ marginRight: 5 }} fill={"#333399"} />
												</div>
											)}
										</TableTd>
									);
								} else if (columnName === "lastUpdateTime") {
									return (
										<TableTd key={`${item._id}_${columnName}_${index}_td`}>
											{tempObj[columnName] ? moment(tempObj[columnName]).format("YYYY年M月DD日 hh:mmA") : "-"}
										</TableTd>
									);
								} else if (columnName === "district") {
									return (
										<TableTd key={`${item._id}_${columnName}_${index}_td`}>
											{item.isOnline ? "網上課程" : getDistrict(tempObj[columnName] || "")}
										</TableTd>
									);
								} else if (columnName === "category") {
									var categoryName = "";
									if (typeof tempObj[columnName] != "undefined") {
										tempObj[columnName].forEach((name, index) => {
											if (index === 0) {
												categoryName = `${name}`;
											} else {
												categoryName = `${categoryName}、${name}`;
											}
										});
									}
									return <TableTd key={`${item._id}_${columnName}_${index}_td`}>{categoryName}</TableTd>;
								}
								return <TableTd key={`${item._id}_${columnName}_${index}_td`}>{tempObj[columnName]}</TableTd>;
							}
						})}
					</TableRow>
				);
			});
		}
	};
	const renderTableCell = renderTD();

	if (!key) {
		return null;
	}
	if (originalStatus === organizationConstants.approvalStatus.PENDING || originalStatus === organizationConstants.approvalStatus.REJECTED) {
		return (
			<>
				{!!isLoading && <Loading />}
				<Prompt when={isChanged} message="你有未儲存的變更，確定不儲存下離開？" />
				<PageContainer>
					<LeftContainer>
						<AdminMenubar section={generalConstants.NAV_TAB.ORGANIZATION_MANAGEMENT.key} />
					</LeftContainer>
					<RightContainer>
						<AdminUserInfo />
						<AdminSectionbar
							title={generalConstants.NAV_TAB.ORGANIZATION_MANAGEMENT.key}
							label={label}
							locationPathArray={[
								{ title: "主頁面", isAction: true, target: generalConstants.PATH.DASHBOARD },
								{
									title: generalConstants.TAB_NAME[generalConstants.NAV_TAB.ORGANIZATION_MANAGEMENT.key],
									isAction: true,
									target: generalConstants.PATH.ORGANIZATION_MANAGEMENT,
								},
								{ title: label, isAction: false, target: "" },
							]}
						/>
						<RowInBlock style={{ paddingLeft: "20px", paddingRight: "20px", flex: 1, width: "100%" }}>
							<ApprovalPageContainer>
								<ApproveContentLeft>
									<Paper style={{ padding: 20 }} elevation={3}>
										<Title>- 帳戶詳情</Title>
										{originalStatus === organizationConstants.approvalStatus.PENDING && (
											<div>
												<ApprovalSubTitle>請選擇此機構的批核狀態</ApprovalSubTitle>
												<ApprovalSelect
													name={"approvalStatus"}
													options={organizationConstants.APPROVAL_STATUS_OPTION}
													value={formData.approvalStatus}
													handleChange={handleChange}
												/>
											</div>
										)}
										{originalStatus === organizationConstants.approvalStatus.REJECTED && (
											<div>
												<ApprovalSubTitle>此機構的批核狀態</ApprovalSubTitle>
												<RowInBlock>
													<OrganizationStatus
														style={{
															paddingTop: 5,
															paddingLeft: 10,
															paddingBottom: 5,
															paddingRight: 10,
															fontSize: 13,
															lingHeight: 1.5,
															fontWeight: 500,
														}}
														item={formData}
													/>
													<div style={{ flex: 1 }} />
												</RowInBlock>
											</div>
										)}
										{showRejectReason && (
											<>
												<ApprovalSubTitle style={{ marginTop: 20, paddingBottom: 0, marginBottom: 5 }}>拒絕原因</ApprovalSubTitle>
												<TextField
													placeholder="拒絕原因"
													multiline
													rows={4}
													value={formData.rejectReason}
													name="rejectReason"
													handleChange={handleChange}
												/>
											</>
										)}
										<ApprovalSubTitle style={{ marginTop: 20, paddingBottom: 0, marginBottom: 5 }}>備註</ApprovalSubTitle>
										<TextField placeholder="備註" multiline rows={4} value={formData.remark} name="remark" handleChange={handleChange} />
										<RowInBlock style={{ marginTop: 20 }}>
											<Button onClick={handleBack}>
												<ArrowLeftIcon />
												<BackLink>返回</BackLink>
											</Button>

											<div style={{ flex: "1 0 0" }} />
											<SubmitButton active={!isFetching} label={"保存及確認"} handleOnClick={handleSubmit} />
										</RowInBlock>
									</Paper>
								</ApproveContentLeft>
							</ApprovalPageContainer>
							<Paper style={{ flex: 2, display: "flex", flexDirection: "row", marginLeft: 20, marginRight: 40, padding: 10 }} elevation={3}>
								<MiddleColumn>
									<BlockGroup>
										<Block>
											<TextFieldLabel>機構名稱</TextFieldLabel>
											<TextFieldValueLabel>{formData.name}</TextFieldValueLabel>
										</Block>
									</BlockGroup>
									{formData.type && (
										<BlockGroup>
											<Block>
												<TextFieldLabel>機構類型</TextFieldLabel>
												<TextFieldValueLabel>{organizationConstants.organizationType[formData.type]}</TextFieldValueLabel>
											</Block>
										</BlockGroup>
									)}
									<BlockGroup>
										<Block>
											<TextFieldLabel>機構地址</TextFieldLabel>
											<TextFieldValueLabel dangerouslySetInnerHTML={{ __html: getText(formData.address) || "-" }} />
										</Block>
									</BlockGroup>
									<BlockGroup>
										<Block>
											<TextFieldLabel>聯絡人名字</TextFieldLabel>
											<TextFieldValueLabel>{formData.contactPerson}</TextFieldValueLabel>
										</Block>
									</BlockGroup>
								</MiddleColumn>
								<div style={{ flex: 1, display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
									<div>
										<BlockGroup>
											<Block>
												<TextFieldLabel>電郵</TextFieldLabel>
												<TextFieldValueLabel>
													{formData.email ? (
														<a target="_black" href={`mailto:${formData.email}`}>
															{formData.email}
														</a>
													) : (
														<div>-</div>
													)}
												</TextFieldValueLabel>
											</Block>
										</BlockGroup>

										<BlockGroup>
											<Block>
												<TextFieldLabel>電話</TextFieldLabel>
												<TextFieldValueLabel>{formData.phone}</TextFieldValueLabel>
											</Block>
										</BlockGroup>

										<BlockGroup>
											<Block>
												<TextFieldLabel>網址</TextFieldLabel>
												<TextFieldValueLabel>
													{formData.website ? (
														<a
															target="_black"
															href={
																formData.website.includes("http://") || formData.website.includes("https://")
																	? formData.website
																	: `http://${formData.website}`
															}
														>
															{formData.website}
														</a>
													) : (
														<div>-</div>
													)}
												</TextFieldValueLabel>
											</Block>
										</BlockGroup>

										<BlockGroup>
											<Block>
												<TextFieldLabel>管理分校數目</TextFieldLabel>
												<TextFieldValueLabel>{formData.numOfCenter}</TextFieldValueLabel>
											</Block>
										</BlockGroup>
										{(!!formData.facebook || !!formData.ig || !!formData.whatsapp) && (
											<SmallRowInBlock style={{ marginTop: "10px", marginBottom: "10px" }}>
												{!!formData.facebook && <FacebookButton value={formData.facebook} />}
												{!!formData.ig && <IGButton value={formData.ig} />}
												{!!formData.whatsapp && <WhatsAppButton value={formData.whatsapp} />}
											</SmallRowInBlock>
										)}
									</div>
									<div>
										{!_.isEmpty(formData.lastUpdateTime) && (
											<RowInLastUpdate style={{ alignItems: "flex-end", justifyContent: "flex-end", marginRight: 15 }}>
												<CentreSmalllDetaiLabel>
													最後更新:{(formData.lastUpdateTime && moment(formData.lastUpdateTime).format("YYYY年M月DD日 hh:mmA")) || "-"}
												</CentreSmalllDetaiLabel>
											</RowInLastUpdate>
										)}
										{!_.isEmpty(formData.lastUpdateBy) && (
											<RowInLastUpdate style={{ alignItems: "flex-end", justifyContent: "flex-end", marginRight: 15 }}>
												<CentreSmalllDetaiLabel>({formData.lastUpdateBy})</CentreSmalllDetaiLabel>
											</RowInLastUpdate>
										)}
									</div>
								</div>
							</Paper>
						</RowInBlock>
						<AdminFooter></AdminFooter>
					</RightContainer>
				</PageContainer>
			</>
		);
	} else {
		return (
			<>
				{!!isLoading && <Loading />}
				<PageContainer>
					<LeftContainer>
						<AdminMenubar section={generalConstants.NAV_TAB.ORGANIZATION_MANAGEMENT.key} />
					</LeftContainer>
					<RightContainer>
						<AdminUserInfo />
						<RowInBlock style={{ paddingLeft: "20px", paddingRight: "20px" }}>
							<LeftPageContainer>
								{originalStatus === organizationConstants.approvalStatus.APPROVED ? (
									<AdminSectionbar
										title={generalConstants.NAV_TAB.ORGANIZATION_MANAGEMENT.key}
										label={label}
										sublabel={`${organization.type === SCHOOL_TYPE ? "學校" : "補習社"}數量: ${totalItems}`}
										buttonLabel={`新增${organization.type === SCHOOL_TYPE ? "學校" : "補習社"}`}
										buttonIcon={organization.type === SCHOOL_TYPE ? <AddSchoolIcon /> : <AddCentreIcon />}
										buttonType={generalConstants.BUTTON_TYPE.SUBMIT}
										handleOnClick={organization.type === SCHOOL_TYPE ? handleNewSchool : handleNewCenter}
										locationPathArray={[
											{ title: "主頁面", isAction: true, target: generalConstants.PATH.DASHBOARD },
											{
												title: generalConstants.TAB_NAME[generalConstants.NAV_TAB.ORGANIZATION_MANAGEMENT.key],
												isAction: true,
												target: generalConstants.PATH.ORGANIZATION_MANAGEMENT,
											},
											{ title: label, isAction: false, target: "" },
										]}
									/>
								) : (
									<AdminSectionbar
										title={generalConstants.NAV_TAB.ORGANIZATION_MANAGEMENT.key}
										label={label}
										locationPathArray={[
											{ title: "主頁面", isAction: true, target: generalConstants.PATH.DASHBOARD },
											{
												title: generalConstants.TAB_NAME[generalConstants.NAV_TAB.ORGANIZATION_MANAGEMENT.key],
												isAction: true,
												target: generalConstants.PATH.ORGANIZATION_MANAGEMENT,
											},
											{ title: label, isAction: false, target: "" },
										]}
									/>
								)}

								<Paper elevation={3}>
									<RowInBlock marginBottom={"0px"}>
										<ContentLeft>
											<SearchBar style={{ paddingLeft: "20px", paddingRight: "20px", paddingTop: "20px" }}>
												<SearchBarLeft>
													<RowInBlock marginBottom="0px">
														<Title>{`- ${organization.type === SCHOOL_TYPE ? "學校" : "補習社"}`}</Title>
													</RowInBlock>
												</SearchBarLeft>
											</SearchBar>
											<table style={{ width: "100%", borderCollapse: "collapse", tableLayout: "fixed" }}>
												<thead>
													<tr>{renderTableHeader}</tr>
												</thead>
												<tbody>{renderTableCell}</tbody>
											</table>
											<PaginationContainer style={{ paddingLeft: "20px" }}>
												<Pagination count={numOfPage} page={currentPage} handlePageChange={handlePageChange} />
											</PaginationContainer>
										</ContentLeft>
									</RowInBlock>
								</Paper>
							</LeftPageContainer>
							<ContentRight style={{ padding: 0, marginTop: 20, marginLeft: 20 }}>
								<Paper elevation={3}>
									<CentreInfoContainer>
										<div>
											<IconButton aria-label="編輯" onClick={handleEdit} style={{ float: "right", padding: "5px" }}>
												<img alt="" src={editIcon} />
											</IconButton>

											<TextFieldLabel>- 機構資料</TextFieldLabel>
											{!_.isEmpty(formData.profilePic) && (
												<Avatar showBadge={false} src={AvatarHelper(formData.profilePic)} size={45} type={"parent"} />
											)}
											<SmallRowInBlock style={{ verticalAlign: "middle" }}>
												{formData.name && <CentreNameLabel>{formData.name}</CentreNameLabel>}
												<OrganizationStatus style={{ marginLeft: 5, width: 150, height: 20 }} item={formData} ignoreActive />
											</SmallRowInBlock>
											{formData.type && (
												<SmallRowInBlock>
													<CentreSmalllDetaiLabel style={{ flex: 4 }}>機構類型</CentreSmalllDetaiLabel>
													<CentreSmalllDetaiLabel style={{ flex: 1 }}>:</CentreSmalllDetaiLabel>
													<CentreSmalllDetaiLabel style={{ flex: 16 }}>
														{organizationConstants.organizationType[formData.type]}
													</CentreSmalllDetaiLabel>
												</SmallRowInBlock>
											)}
											<SmallRowInBlock>
												<CentreSmalllDetaiLabel style={{ flex: 3 }}>聯絡人</CentreSmalllDetaiLabel>
												<CentreSmalllDetaiLabel style={{ flex: 1 }}>:</CentreSmalllDetaiLabel>
												<CentreSmalllDetaiLabel style={{ flex: 16 }}>{formData.contactPerson || "-"}</CentreSmalllDetaiLabel>
											</SmallRowInBlock>
											<SmallRowInBlock>
												<CentreSmalllDetaiLabel style={{ flex: 2 }}>地址</CentreSmalllDetaiLabel>
												<CentreSmalllDetaiLabel style={{ flex: 1 }}>:</CentreSmalllDetaiLabel>
												<CentreSmalllDetaiLabel
													style={{ flex: 17 }}
													dangerouslySetInnerHTML={{ __html: getText(formData.address) || "-" }}
												></CentreSmalllDetaiLabel>
											</SmallRowInBlock>
											<SmallRowInBlock>
												<CentreSmalllDetaiLabel style={{ flex: 2 }}>電話</CentreSmalllDetaiLabel>
												<CentreSmalllDetaiLabel style={{ flex: 1 }}>:</CentreSmalllDetaiLabel>
												<CentreSmalllDetaiLabel style={{ flex: 17 }}>{formData.phone || "-"}</CentreSmalllDetaiLabel>
											</SmallRowInBlock>
											<SmallRowInBlock>
												<CentreSmalllDetaiLabel style={{ flex: 2 }}>電郵</CentreSmalllDetaiLabel>
												<CentreSmalllDetaiLabel style={{ flex: 1 }}>:</CentreSmalllDetaiLabel>
												{formData.email ? (
													<CentreSmalllDetaiLabel style={{ flex: 17 }}>
														<a target="_black" href={`mailto:${formData.email}`}>
															{formData.email}
														</a>
													</CentreSmalllDetaiLabel>
												) : (
													<CentreSmalllDetaiLabel style={{ flex: 17 }}>{"-"}</CentreSmalllDetaiLabel>
												)}
											</SmallRowInBlock>
											<SmallRowInBlock>
												<CentreSmalllDetaiLabel style={{ flex: 2 }}>網址</CentreSmalllDetaiLabel>
												<CentreSmalllDetaiLabel style={{ flex: 1 }}>:</CentreSmalllDetaiLabel>

												{formData.website ? (
													<CentreSmalllDetaiLabel style={{ flex: 17 }}>
														<a
															target="_black"
															href={
																formData.website.includes("http://") || formData.website.includes("https://")
																	? formData.website
																	: `http://${formData.website}`
															}
														>
															{formData.website || "-"}
														</a>
													</CentreSmalllDetaiLabel>
												) : (
													<CentreSmalllDetaiLabel style={{ flex: 17 }}>{"-"}</CentreSmalllDetaiLabel>
												)}
											</SmallRowInBlock>
											{(!!formData.facebook || !!formData.ig || !!formData.whatsapp) && (
												<SmallRowInBlock style={{ marginTop: "10px", marginBottom: "10px" }}>
													{!!formData.facebook && <FacebookButton value={formData.facebook} />}
													{!!formData.ig && <IGButton value={formData.ig} />}
													{!!formData.whatsapp && <WhatsAppButton value={formData.whatsapp} />}
												</SmallRowInBlock>
											)}
											<div>
												<SubTitle style={{ flex: 3 }}>備註</SubTitle>
												<SubText style={{ flex: 17 }} dangerouslySetInnerHTML={{ __html: getText(formData.remark) || "-" }} />
											</div>
											{originalStatus === organizationConstants.approvalStatus.REJECTED && (
												<div>
													<SubTitle style={{ flex: 3 }}>拒絕原因</SubTitle>
													<SubText style={{ flex: 17 }} dangerouslySetInnerHTML={{ __html: getText(formData.rejectReason) || "-" }} />
												</div>
											)}
										</div>
										<div>
											{!_.isEmpty(formData.lastUpdateTime) && (
												<RowInLastUpdate style={{ alignItems: "flex-end", justifyContent: "flex-end", marginRight: 15 }}>
													<CentreSmalllDetaiLabel>
														最後更新:{(formData.lastUpdateTime && moment(formData.lastUpdateTime).format("YYYY年M月DD日 hh:mmA")) || "-"}
													</CentreSmalllDetaiLabel>
												</RowInLastUpdate>
											)}
											{!_.isEmpty(formData.lastUpdateBy) ? (
												<RowInLastUpdate style={{ alignItems: "flex-end", justifyContent: "flex-end", marginRight: 15 }}>
													<CentreSmalllDetaiLabel>({formData.lastUpdateBy || "-"})</CentreSmalllDetaiLabel>
												</RowInLastUpdate>
											) : (
												<div>&nbsp;</div>
											)}
										</div>
									</CentreInfoContainer>
								</Paper>
							</ContentRight>
						</RowInBlock>
						<AdminFooter></AdminFooter>
					</RightContainer>
				</PageContainer>
			</>
		);
	}
};

const ContentLeft = styled.div`
	flex: 18;
	min-height: 590px;
`;

const ApproveContentLeft = styled.div`
	flex: 18;
`;

const ContentRight = styled.div`
	flex: 6;
	padding: 20px;
`;

const LeftPageContainer = styled.div`
	flex: 16;
`;

const ApprovalPageContainer = styled.div`
	flex: 1;
	width: 360px;
`;

const MiddleColumn = styled.div`
	border-right: #dddddd 1px solid;
	flex: 1;
	max-width: 360px;
`;

const CentreInfoContainer = styled.div`
	flex: 1;
	height: 600px;
	margin-left: 20px;
	margin-right: 20px;
	padding-top: 20px;
	padding-bottom: 20px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
`;

const RowInBlock = styled.div`
	display: flex;
	margin-bottom: ${(props) => props.marginBottom || "10px"};
	flex-direction: row;
`;

const TextFieldLabel = styled.div`
	color: #333399;
	font-size: 14px;
	line-height: 1.5;
	font-weight: bold;
	margin-bottom: 0px;
`;

const TextFieldValueLabel = styled.div`
	color: #000000;
	font-size: 14px;
	line-height: 1.5;
	font-weight: normal;
	margin-bottom: 0px;
`;

const Avatar = styled.img`
	border-radius: 50%;
	width: 80px;
	height: 80px;
	border: #aaaaaa 1px solid;
`;

const CentreNameLabel = styled.div`
	color: #000000;
	font-size: 14px;
	line-height: 20px;
	font-weight: bold;
	margin-bottom: 4px;
`;

const SmallRowInBlock = styled.div`
	display: flex;
	flex-direction: row;
	margin-bottom: 4px;
	margin-top: 4px;
`;

const CentreSmalllDetaiLabel = styled.div`
	color: #666666;
	font-size: 11px;
	font-weight: 500;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.55;
	letter-spacing: normal;
`;

const Title = styled.div`
	font-weight: bold;
	margin: 0 0 12px 4px;
	font-size: 14px;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.43;
	letter-spacing: normal;
	text-align: left;
	color: #333399;
	width: 100%;
`;

const SubTitle = styled.div`
	color: #000000;
	font-size: 11px;
	line-height: 1;
	font-weight: bold;
	margin-bottom: 8px;
`;

const ApprovalSubTitle = styled.div`
	color: black;
	margin-bottom: ${(props) => props.marginBottom || "10px"};

	padding: 5px 0;
	font-size: 14px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.43;
	letter-spacing: normal;
	text-align: left;
	color: #000000;
`;

const SubText = styled.div`
	color: #333333;
	font-size: 11px;
	line-height: 1;
	margin-bottom: 8px;
`;

const BackLink = styled.span`
	color: #333399,
	font-weight: bold;
	font-size: 13px;
	line-height: 1.46;

`;

const BlockGroup = styled.div`
	display: flex;
	flex-direction: row;
`;

const Block = styled.div`
	padding: 10px;
	flex: ${(props) => props.flex || 1};
`;

const DeleteBadge = styled.span`
	padding: 2px 6px 3px;
	border-radius: 3px;
	background-color: #ff5c5c;
	margin: 9px 6px 9px 0;
	font-size: 10px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.5;
	letter-spacing: normal;
	color: white;
`;

const InactiveBadge = styled.span`
	padding: 2px 6px 3px;
	border-radius: 3px;
	background-color: #fff0f2;
	margin: 9px 6px 9px 0;
	font-size: 10px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.5;
	letter-spacing: normal;
	color: #ff0000;
`;

const RowInLastUpdate = styled.div`
	display: flex;
	flex-direction: row;
`;

const ClosedBadge = styled.span`
	padding: 2px 6px 3px;
	border-radius: 3px;
	background-color: #fff0f2;
	margin: 9px 6px 9px 0;
	font-size: 10px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.5;
	letter-spacing: normal;
	color: #ff0000;
`;

const VipIconView = styled.div`
	display: flex;
	width: 32px;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	margin-left: 5px;
`;
const VipText = styled.div`
	font-size: 12;
	font-weight: bold;
	color: #333333;
	background-color: #c8ae63;
	padding-left: 5px;
	padding-right: 5px;
`;
