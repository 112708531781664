import React, { useState } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import Zoom from "yet-another-react-lightbox/plugins/zoom";

export const LightBox = (props) => {
	const { images, thumbnailWidth, thumbnailHeight } = props;

	const [active, setActive] = useState(false);
	const [selectedIndex, setSelectedIndex] = useState(0);

	if (_.isArray(images)) {
		return (
			<div>
				{images.map((imageUrl, index) => {
					return (
						<img
							key={`image_${index}`}
							style={{ marginRight: "10px" }}
							src={imageUrl}
							width={thumbnailWidth}
							height={thumbnailHeight}
							onClick={() => {
								setSelectedIndex(index);
								setActive(true);
							}}
						/>
					);
				})}
				<Lightbox
					open={active}
					close={() => {
						setActive(false);
					}}
					index={selectedIndex}
					slides={images.map((imgUrl) => {
						return {
							src: imgUrl,
							description: " ",
						};
					})}
					plugins={[Zoom]}
				/>
			</div>
		);
	} else if (_.isString(images)) {
		return (
			<div>
				<img
					src={images}
					width={thumbnailWidth}
					height={thumbnailHeight}
					onClick={() => {
						setActive(true);
					}}
				/>
				<Lightbox
					open={active}
					close={() => {
						setActive(false);
					}}
					slides={[
						{
							src: images,
							description: " ",
						},
					]}
					plugins={[Zoom]}
				/>
			</div>
		);
	}
	return <div></div>;
};

LightBox.propTypes = {
	images: PropTypes.array,
	thumbnailWidth: PropTypes.string,
	thumbnailHeight: PropTypes.string,
};

LightBox.defaultProps = {
	images: [],
	thumbnailWidth: "64px",
	thumbnailHeight: "64px",
};
