/* eslint-disable no-mixed-spaces-and-tabs */
import { adminConstants } from "_constants";

const initialState = {
	isFetching: false,
	admin: {},
	setPasswordSuccess: false,
	createAdminSuccess: false,
	updateAdminSuccess: false,
	navigationObject: {
		root: {
			searchKey: adminConstants.TABLE_COLUMN[1].key,
			searchWord: "",
			adminList: [],
		},
	},
};

const adminReducer = (state = initialState, action) => {
	switch (action.type) {
		case adminConstants.type.SET_PASSWORD_REQUEST: {
			return {
				...state,
				isFetching: true,
				setPasswordSuccess: false,
			};
		}

		case adminConstants.type.SET_PASSWORD_FAILURE: {
			return {
				...state,
				isFetching: false,
				setPasswordSuccess: false,
			};
		}
		case adminConstants.type.SET_PASSWORD_SUCCESS: {
			return {
				...state,
				isFetching: false,
				setPasswordSuccess: true,
			};
		}
		case adminConstants.type.RESET_PASSWORD_SUCCESS: {
			return {
				...state,
				setPasswordSuccess: false,
			};
		}

		case adminConstants.type.LOGIN_REQUEST: {
			return {
				...state,
				isFetching: true,
			};
		}

		case adminConstants.type.LOGIN_FAILURE: {
			return {
				...state,
				isFetching: false,
			};
		}
		case adminConstants.type.LOGIN_SUCCESS: {
			return {
				...state,
				isFetching: false,
			};
		}

		case adminConstants.type.FETCH_ADMIN_LIST_REQUEST: {
			return {
				...state,
				isFetching: true,
			};
		}

		case adminConstants.type.FETCH_ADMIN_LIST_FAILURE: {
			return {
				...state,
				isFetching: false,
			};
		}
		case adminConstants.type.FETCH_ADMIN_LIST_SUCCESS: {
			const navigationObject = state?.navigationObject
				? {
						...state.navigationObject[action.navigationKey],
						adminList: action.adminList,
				  }
				: { adminList: action.adminList, searchKey: adminConstants.TABLE_COLUMN[1].key, searchWord: "" };
			return {
				...state,
				isFetching: false,
				isFetchError: false,
				navigationObject: {
					...state.navigationObject,
					[action.navigationKey]: {
						...navigationObject,
					},
				},
			};
		}
		case adminConstants.type.UPDATE_SEARCH_KEY_AND_WORD: {
			const navigationObject = state?.navigationObject
				? {
						...state?.navigationObject[action.navigationKey],
						searchKey: action.searchKey,
						searchWord: action.searchWord,
				  }
				: { adminList: [], searchKey: action.searchKey, searchWord: action.searchWord };
			return {
				...state,
				navigationObject: {
					...state.navigationObject,
					[action.navigationKey]: {
						...navigationObject,
					},
				},
			};
		}
		case adminConstants.type.FETCH_ADMIN_REQUEST: {
			return {
				...state,
				isFetching: true,
			};
		}

		case adminConstants.type.FETCH_ADMIN_FAILURE: {
			return {
				...state,
				isFetching: false,
			};
		}
		case adminConstants.type.FETCH_ADMIN_SUCCESS: {
			return {
				...state,
				isFetching: false,
				admin: action.admin,
			};
		}

		case adminConstants.type.CREATE_ADMIN_REQUEST: {
			return {
				...state,
				isFetching: true,
				createAdminSuccess: false,
			};
		}

		case adminConstants.type.CREATE_ADMIN_FAILURE: {
			return {
				...state,
				isFetching: false,
				createAdminSuccess: false,
			};
		}
		case adminConstants.type.CREATE_ADMIN_SUCCESS: {
			return {
				...state,
				isFetching: false,
				createAdminSuccess: true,
			};
		}

		case adminConstants.type.UPDATE_ADMIN_REQUEST: {
			return {
				...state,
				isFetching: true,
				updateAdminSuccess: false,
			};
		}

		case adminConstants.type.UPDATE_ADMIN_FAILURE: {
			return {
				...state,
				isFetching: false,
				updateAdminSuccess: false,
			};
		}
		case adminConstants.type.UPDATE_ADMIN_SUCCESS: {
			return {
				...state,
				isFetching: false,
				updateAdminSuccess: true,
			};
		}
		case adminConstants.type.RESET_ADMIN_SUCCESS: {
			return {
				...state,
				createAdminSuccess: false,
				updateAdminSuccess: false,
			};
		}

		default: {
			return state;
		}
	}
};

export default adminReducer;
