import { generalConstants, parentConstants } from "_constants";
import { parentService } from "services";
import { toast } from "react-toastify";
import { history } from "_util/_helper";

export const parentAction = {
	fetchParent,
	fetchParentByMission,
	getParentByKey,
	verifyParent,
	unverifyParent,
	updateParent,
};

function fetchParent(form) {
	return (dispatch) => {
		dispatch(request());
		parentService.fetchParent(form).then(
			(response) => {
				dispatch(success(response, form));
			},
			(error) => {
				toast.error(error.error);
				dispatch(failure());
			}
		);
	};

	function request() {
		return { type: parentConstants.type.FETCH_PARENT_LIST_REQUEST };
	}
	function failure() {
		return { type: parentConstants.type.FETCH_PARENT_LIST_FAILURE };
	}
	function success(response, inputData) {
		const { items, totalItems } = response;
		const { searchKey, searchWord, sort, notJoinStatus, unverifiedStatus, verifiedStatus, page } = inputData;
		return {
			type: parentConstants.type.FETCH_PARENT_LIST_SUCCESS,
			searchKey,
			searchWord,
			totalNum: totalItems,
			totalPage: totalItems / 10 === parseInt(totalItems / 10) ? parseInt(totalItems / 10) : parseInt(totalItems / 10) + 1,
			currentPage: page,
			parentList: items,
			sortingKey: sort.split(":")[0],
			sortingDirection: sort.split(":")[1],
			notJoinStatus,
			unverifiedStatus,
			verifiedStatus,
		};
	}
}

function fetchParentByMission(form) {
	return (dispatch) => {
		dispatch(request());
		parentService.fetchParent(form).then(
			(response) => {
				dispatch(success(response));
			},
			(error) => {
				toast.error(error.error);
				dispatch(failure());
			}
		);
	};

	function request() {
		return { type: parentConstants.type.FETCH_PARENT_LIST_FOR_MISSION_REQUEST };
	}
	function failure() {
		return { type: parentConstants.type.FETCH_PARENT_LIST_FOR_MISSION_FAILURE };
	}
	function success(data) {
		const { items } = data;
		return {
			type: parentConstants.type.FETCH_PARENT_LIST_FOR_MISSION_SUCCESS,
			parentList: items,
		};
	}
}

function getParentByKey(key) {
	return (dispatch) => {
		dispatch(request());
		parentService.getParentByKey(key).then(
			(response) => {
				dispatch(success(response));
			},
			(error) => {
				toast.error(error.error);
				dispatch(failure());
				if (error.error?.status !== 401) {
					history.push(generalConstants.PATH.PARENT_MANAGEMENT);
				}
			}
		);
	};

	function request() {
		return { type: parentConstants.type.FETCH_PARENT_REQUEST };
	}
	function failure() {
		return { type: parentConstants.type.FETCH_PARENT_FAILURE };
	}
	function success(parent) {
		return {
			type: parentConstants.type.FETCH_PARENT_SUCCESS,
			parent,
		};
	}
}

function verifyParent(key) {
	return (dispatch) => {
		dispatch(request());
		parentService.verifyParent(key).then(
			() => {
				dispatch(success());
			},
			(error) => {
				toast.error(error.error);
				dispatch(failure());
			}
		);
	};

	function request() {
		return { type: parentConstants.type.SUBMIT_PARENT_REQUEST };
	}
	function failure() {
		return { type: parentConstants.type.SUBMIT_PARENT_FAILURE };
	}
	function success() {
		return { type: parentConstants.type.SUBMIT_PARENT_SUCCESS };
	}
}

function unverifyParent(key) {
	return (dispatch) => {
		dispatch(request());
		parentService.unverifyParent(key).then(
			() => {
				dispatch(success());
			},
			(error) => {
				toast.error(error.error);
				dispatch(failure());
			}
		);
	};

	function request() {
		return { type: parentConstants.type.SUBMIT_PARENT_REQUEST };
	}
	function failure() {
		return { type: parentConstants.type.SUBMIT_PARENT_FAILURE };
	}
	function success() {
		return { type: parentConstants.type.SUBMIT_PARENT_SUCCESS };
	}
}

function updateParent(data) {
	return (dispatch) => {
		dispatch(request());
		parentService.updateParent(data).then(
			() => {
				dispatch(success());
			},
			(error) => {
				toast.error(error.error);
				dispatch(failure());
			}
		);
	};

	function request() {
		return { type: parentConstants.type.SUBMIT_PARENT_REQUEST };
	}
	function failure() {
		return { type: parentConstants.type.SUBMIT_PARENT_FAILURE };
	}
	function success() {
		return { type: parentConstants.type.SUBMIT_PARENT_SUCCESS };
	}
}
