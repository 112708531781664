import { authConstants, missionConstants } from "_constants";

const initialState = {
	isFetching: false,
	isFetchError: false,
	deleteInProgress: false,
	mission: {},
	createMissionSuccess: false,
	updateMissionSuccess: false,
	deleteMissionSuccess: false,
	rankList: [],
	navigationObject: {
		root: {
			sortingKey: "lastUpdateTime",
			sortingDirection: "DESC",
			numOfPage: 1,
			currentPage: 1,
			totalItems: 0,
			missionList: [],
		},
	},
};

const missionReducer = (state = initialState, action) => {
	switch (action.type) {
		case authConstants.type.UNAUTHORIZED_ERROR: {
			let navigationObject = initialState.navigationObject;
			if (typeof state.navigationObject?.root != "undefined") {
				navigationObject = {
					...state.navigationObject,
					root: { ...state.navigationObject?.root, currentPage: 1 },
				};
			}
			return {
				...state,
				navigationObject: navigationObject,
			};
		}
		case missionConstants.type.FETCH_MISSION_LIST_REQUEST: {
			return {
				...state,
				isFetching: true,
				isFetchError: false,
			};
		}
		case missionConstants.type.FETCH_MISSION_LIST_FAILURE: {
			return {
				...state,
				isFetching: false,
				isFetchError: true,
			};
		}
		case missionConstants.type.FETCH_MISSION_LIST_SUCCESS: {
			if (action.navigationKey === "rank") {
				return {
					...state,
					isFetching: false,
					isFetchError: false,
					rankList: action.missionList,
				};
			}
			const navigationObject = {
				searchWord: action.searchWord,
				sortingKey: action.sortingKey,
				sortingDirection: action.sortingDirection,
				totalItems: action.totalNum,
				numOfPage: action.totalPage,
				currentPage: action.currentPage,
				missionList: action.missionList,
			};
			return {
				...state,
				isFetching: false,
				isFetchError: false,
				navigationObject: {
					...state.navigationObject,
					[`${action.navigationKey}`]: {
						...navigationObject,
					},
				},
			};
		}
		case missionConstants.type.FETCH_MISSION_REQUEST: {
			return {
				...state,
				isFetching: true,
				isFetchError: false,
				mission: {},
			};
		}
		case missionConstants.type.FETCH_MISSION_FAILURE: {
			return {
				...state,
				isFetching: false,
				isFetchError: true,
			};
		}
		case missionConstants.type.FETCH_MISSION_SUCCESS: {
			return {
				...state,
				isFetching: false,
				isFetchError: false,
				mission: action.mission,
			};
		}
		case missionConstants.type.CREATE_MISSION_REQUEST: {
			return {
				...state,
				isFetching: true,
				createMissionSuccess: false,
			};
		}
		case missionConstants.type.CREATE_MISSION_FAILURE: {
			return {
				...state,
				isFetching: false,
				createMissionSuccess: false,
			};
		}
		case missionConstants.type.CREATE_MISSION_SUCCESS: {
			return {
				...state,
				isFetching: false,
				createMissionSuccess: true,
			};
		}
		case missionConstants.type.UPDATE_MISSION_REQUEST: {
			return {
				...state,
				isFetching: true,
				updateMissionSuccess: false,
			};
		}
		case missionConstants.type.UPDATE_MISSION_FAILURE: {
			return {
				...state,
				isFetching: false,
				updateMissionSuccess: false,
			};
		}
		case missionConstants.type.UPDATE_MISSION_SUCCESS: {
			return {
				...state,
				isFetching: false,
				updateMissionSuccess: true,
			};
		}
		case missionConstants.type.DELETE_MISSION_REQUEST: {
			return {
				...state,
				deleteInProgress: true,
				deleteMissionSuccess: false,
			};
		}
		case missionConstants.type.DELETE_MISSION_FAILURE: {
			return {
				...state,
				deleteInProgress: false,
				deleteMissionSuccess: false,
			};
		}
		case missionConstants.type.DELETE_MISSION_SUCCESS: {
			return {
				...state,
				deleteInProgress: false,
				deleteMissionSuccess: true,
			};
		}
		case missionConstants.type.RESET_MISSION_SUCCESS: {
			return {
				...state,
				createMissionSuccess: false,
				updateMissionSuccess: false,
				deleteMissionSuccess: false,
				isFetchError: false,
			};
		}
		default: {
			return state;
		}
	}
};

export default missionReducer;
