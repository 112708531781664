import { dashboardConstants } from "_constants";

const initialState = {
	schoolBySubscription: {
		schoolList: [],
		level: "",
	},
	schoolByNews: {
		schoolList: [],
		level: "",
	},
};

const dashboardReducer = (state = initialState, action) => {
	switch (action.type) {
		case dashboardConstants.type.DASHBOARD_SET_SCHOOL_BY_SUBSCRIPTION_LIST: {
			return {
				...state,
				schoolBySubscription: {
					...state.schoolBySubscription,
					schoolList: action.schoolList,
					level: action.level,
				},
			};
		}
		case dashboardConstants.type.DASHBOARD_SET_SCHOOL_BY_NEWS_LIST: {
			return {
				...state,
				schoolByNews: {
					...state.schoolByNews,
					schoolList: action.schoolList,
					level: action.level,
				},
			};
		}
		default: {
			return state;
		}
	}
};

export default dashboardReducer;
