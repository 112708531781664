import React from "react";
import PropTypes from "prop-types";
import OutlinedInput from "@material-ui/core/OutlinedInput";

export const TextField = (props) => {
	const { name, placeholder, value, handleChange, ...otherProps } = props;

	return (
		<OutlinedInput margin='dense'
			inputProps={{ style: {fontSize: 13} }}
			name={name}
			labelWidth={0}
			placeholder={placeholder}
			fullWidth
			value={value}
			onChange={(event) => {
				if (handleChange) {
					handleChange({ key: event.target.name, value: event.target.value });
				}
			}}
			{...otherProps}
		/>
	);
};

TextField.propTypes = {
	name: PropTypes.string,
	placeholder: PropTypes.string,
	value: PropTypes.string,
	handleChange: PropTypes.func,
	otherProps: PropTypes.any,
};
