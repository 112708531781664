export const messageConstants = {
	type: {
		SUBMIT_MSG_REQUEST: "SUBMIT_MSG_REQUEST",
		SUBMIT_MSG_FAILURE: "SUBMIT_MSG_FAILURE",
		SUBMIT_MSG_SUCCESS: "SUBMIT_MSG_SUCCESS",
		SUBMIT_BATCH_MSG_REQUEST: "SUBMIT_BATCH_MSG_REQUEST",
		SUBMIT_BATCH_MSG_FAILURE: "SUBMIT_BATCH_MSG_FAILURE",
		SUBMIT_BATCH_MSG_SUCCESS: "SUBMIT_BATCH_MSG_SUCCESS",
		RESET_MSG_SUCCESS: "RESET_MSG_SUCCESS",

		FETCH_READ_MESSAGE: "FETCH_READ_MESSAGE",

		FETCH_MSG_REQUEST: "FETCH_MSG_REQUEST",
		FETCH_MSG_FAILURE: "FETCH_MSG_FAILURE",
		FETCH_MSG_SUCCESS: "FETCH_MSG_SUCCESS",

		FETCH_MSG_LIST_SUCCESS: "FETCH_MSG_LIST_SUCCESS",

		IMPORT_MSG_LIST: "IMPORT_MSG_LIST",

		UPDATE_MSG_REQUEST: "UPDATE_MSG_REQUEST",
		UPDATE_MSG_FAILURE: "UPDATE_MSG_FAILURE",
		UPDATE_MSG_SUCCESS: "UPDATE_MSG_SUCCESS",

		DELETE_MSG_REQUEST: "DELETE_MSG_REQUEST",
		DELETE_MSG_FAILURE: "DELETE_MSG_FAILURE",
		DELETE_MSG_SUCCESS: "DELETE_MSG_SUCCESS",

		SET_SELECTED_SCHOOL_INFO: "SET_SELECTED_SCHOOL_INFO",
		CLEAR_SELECTED_SCHOOL_INFO: "CLEAR_SELECTED_SCHOOL_INFO",
	},
	TABLE_COLUMN: [
		{ key: "status", value: "狀態", searchable: false, width: "6%" },
		{ key: "type", value: "訊息類別", searchable: false, width: "5%" },
		{ key: "targetAudience", value: "發送對象", searchable: false, width: "5%" },
		{ key: "infoType", value: "資訊分類", searchable: false, width: "5%" },
		{ key: "schoolName", value: "名稱", searchable: true, width: "20%" },
		{ key: "title", value: "訊息標題", searchable: true, width: "20%" },
		{ key: "postBy", value: "發送者", searchable: false, width: "10%" },
		{ key: "fired", value: "推送", searchable: false, width: "5%" },
		{ key: "scheduleTime", value: "發送時間", searchable: false, width: "10%" },
	],
	IMPORT_TABLE_COLUMN: [
		{ key: "num", value: "數據行號", width: "10%" },
		{ key: "type", value: "訊息類別", width: "10%" },
		{ key: "infoType", value: "資訊分類", width: "10%" },
		{ key: "schoolName", value: "名稱", width: "25%" },
		{ key: "title", value: "訊息標題", width: "30%" },
		{ key: "scheduleTime", value: "發送時間", width: "10%" },
	],
	SCHOOL_MSG_TABLE_COLUMN: [
		{ key: "status", value: "狀態", searchable: false, width: "8%" },
		{ key: "targetAudience", value: "發送對象", searchable: false, width: "8%" },
		{ key: "title", value: "訊息標題", searchable: true, width: "20%" },
		{ key: "postBy", value: "發送者", searchable: false, width: "15%" },
		{ key: "fired", value: "推送", searchable: false, width: "5%" },
		{ key: "scheduleTime", value: "發送時間", searchable: false, width: "15%" },
	],
	MSG_FILTER: {
		SCHOOL_NAME: "schoolName",
		TITLE: "title",
	},

	Translate: {
		type: {
			NEWS: "學校資訊",
			NOTIFICATION: "一般訊息",
		},
		targetAudience: {
			ALL: "全體用戶",
			VIP: "VIP用戶",
			NONVIP: "普通用戶",
		},
		infoType: {
			SCHOOL: "學校",
			EB: "教育局",
			LEARNING_CENTER: "補習社",
			OTHER: "其他",
		},
		category: {
			IMPORTANT: "重要日子",
			REGISTRATION: "入學申請",
			EVENT: "活動",
			NOTICE: "通告",
			NEWS: "最新消息",
			INFO: "資訊",
		},
		category_short: {
			IMPORTANT: "重要",
			REGISTRATION: "入學",
			EVENT: "活動",
			NOTICE: "通告",
			NEWS: "最新",
			INFO: "資訊",
		},
	},

	DATA: {
		type: { NEWS: "NEWS", NOTIFICATION: "NOTIFICATION" },
		infoType: { SCHOOL: "SCHOOL", EB: "EB", LEARNING_CENTER: "LEARNING_CENTER", OTHER: "OTHER" },
		schoolLevel: {
			幼稚園: "幼稚園",
			幼稚園暨幼兒中心: "幼稚園暨幼兒中心",
			小學: "小學",
		},
		scheduleType: {
			IMMEDIATELY: "IMMEDIATELY",
			SCHEDULED: "SCHEDULED",
		},
		category: {
			IMPORTANT: "IMPORTANT",
			REGISTRATION: "REGISTRATION",
			EVENT: "EVENT",
			NOTICE: "NOTICE",
			NEWS: "NEWS",
			INFO: "INFO",
		},
		eventType: {
			SINGLE: "SINGLE",
			PERIOD: "PERIOD",
		},
	},
	OPTION: {
		type: [
			{ key: "NEWS", value: "學校資訊" },
			{ key: "NOTIFICATION", value: "一般訊息" },
		],
		targetAudience: [
			{ key: "ALL", value: "全體用戶" },
			{ key: "VIP", value: "VIP用戶" },
			{ key: "NONVIP", value: "普通用戶" },
		],
		infoType: [
			{ key: "SCHOOL", value: "學校" },
			{ key: "EB", value: "教育局" },
		],

		scheduleType: [
			{ key: "IMMEDIATELY", value: "即時" },
			{ key: "SCHEDULED", value: "預定" },
		],
		category: [
			{ key: "IMPORTANT", value: "重要日子" },
			{ key: "REGISTRATION", value: "入學申請" },
			{ key: "EVENT", value: "活動" },
			{ key: "NOTICE", value: "通告" },
			{ key: "NEWS", value: "最新消息" },
			{ key: "INFO", value: "資訊" },
		],
	},
};
