import { missionConstants } from "_constants";
import { missionService } from "services";
import { toast } from "react-toastify";

export const missionAction = {
	createMission,
	updateMission,
	getMissionList,
	getMissionByKey,
	deleteMission,
};

function createMission(data) {
	return (dispatch) => {
		dispatch(request());
		missionService.createMission(data).then(
			() => {
				dispatch(success());
			},
			(error) => {
				toast.error(error.error);
				dispatch(failure());
			}
		);
	};

	function request() {
		return { type: missionConstants.type.CREATE_MISSION_REQUEST };
	}
	function failure() {
		return { type: missionConstants.type.CREATE_MISSION_FAILURE };
	}
	function success() {
		return { type: missionConstants.type.CREATE_MISSION_SUCCESS };
	}
}

function updateMission(data) {
	return (dispatch) => {
		dispatch(request());
		missionService.updateMission(data).then(
			() => {
				dispatch(success());
			},
			(error) => {
				toast.error(error.error);
				dispatch(failure());
			}
		);
	};

	function request() {
		return { type: missionConstants.type.UPDATE_MISSION_REQUEST };
	}
	function failure() {
		return { type: missionConstants.type.UPDATE_MISSION_FAILURE };
	}
	function success() {
		return { type: missionConstants.type.UPDATE_MISSION_SUCCESS };
	}
}

function getMissionList(data, navigationKey) {
	return (dispatch) => {
		dispatch(request());
		missionService.getMissionList(data).then(
			(response) => {
				dispatch(success(response, data));
			},
			(error) => {
				toast.error(error.error);
				dispatch(failure());
			}
		);
	};
	function request() {
		return { type: missionConstants.type.FETCH_MISSION_LIST_REQUEST };
	}
	function failure() {
		return { type: missionConstants.type.FETCH_MISSION_LIST_FAILURE };
	}
	function success(response, inputData) {
		const { totalItems, items } = response;
		let sortingKey = "lastUpdateTime",
			sortingDirection = "DESC";
		const sortingArray = inputData?.sort?.split(":");
		if (sortingArray && sortingArray.length === 2 && sortingArray[0] && sortingArray[1]) {
			sortingKey = sortingArray[0];
			sortingDirection = sortingArray[1] === "DESC" ? "DESC" : "ASC";
		}
		return {
			type: missionConstants.type.FETCH_MISSION_LIST_SUCCESS,
			navigationKey,
			totalNum: totalItems,
			totalPage: Math.ceil(totalItems / inputData.limit),
			currentPage: inputData.page,
			missionList: items,
			sortingDirection,
			sortingKey,
			searchWord: inputData.search || "",
		};
	}
}

function getMissionByKey(key) {
	return (dispatch) => {
		dispatch(request());
		missionService.getMissionByKey(key).then(
			(response) => {
				dispatch(success(response));
			},
			(error) => {
				toast.error(error.error);
				dispatch(failure());
			}
		);
	};
	function request() {
		return { type: missionConstants.type.FETCH_MISSION_REQUEST };
	}
	function failure() {
		return { type: missionConstants.type.FETCH_MISSION_FAILURE };
	}
	function success(mission) {
		return {
			type: missionConstants.type.FETCH_MISSION_SUCCESS,
			mission,
		};
	}
}

function deleteMission(data) {
	return (dispatch) => {
		dispatch(request());
		missionService.deleteMission(data).then(
			() => {
				dispatch(success());
			},
			(error) => {
				toast.error(error.error);
				dispatch(failure());
			}
		);
	};

	function request() {
		return { type: missionConstants.type.DELETE_MISSION_REQUEST };
	}
	function failure() {
		return { type: missionConstants.type.DELETE_MISSION_FAILURE };
	}
	function success() {
		return { type: missionConstants.type.DELETE_MISSION_SUCCESS };
	}
}
